import { AxiosInstance, AxiosRequestConfig } from "axios";
import { BenefitField } from "../../../../enums";
import { FilteredProjectApiModel } from "../filteredApi/FilteredProjectApiModel";
import http from "../Http";

export class BenefitApi extends FilteredProjectApiModel<FP.Entities.IBenefit> {
  controller: string = "benefits";

  /**
   *
   */
  constructor(http: AxiosInstance) {
    super(http, "benefits");
  }
  updateField = async (
    organisationId: number,
    projectId: number,
    benefitId: number,
    textField: string,
    idField: number,
    dateField: Date,
    numericField: number,
    benefitField: BenefitField,
    config?: AxiosRequestConfig
  ) => {
    let data = {
      TextField: textField,
      IdField: idField,
      DateField: dateField
    };
    let res = await this.http.put(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${benefitId}/${benefitField}`,
      data,
      config
    );
    return res.data;
  };

  deleteRange = async (organisationId: number, projectId: number, ids: number[], config?: AxiosRequestConfig) => {
    let res = await this.http.post(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/delete-range`,
      ids,
      config
    );
    return res.data;
  };
}

const instance = new BenefitApi(http);
export default instance;
