import { action, makeObservable, observable } from "mobx";
import StakeholdersApi, {
  StakeholdersApi as IStakeholdersApi
} from "../../../../../../services/api/v2/stakeholders/Stakeholders.api";

export class UploadFileModel {
  organisationId: number;
  stakeholdersProvider: IStakeholdersApi;
  @observable showFileTypeWarning: boolean = false;
  @observable showFileSizeWarning: boolean = false;
  @observable showSuccessMessage: boolean = false;
  @observable isUploading: boolean = false;

  constructor(organisationId: number) {
    makeObservable(this);
    this.organisationId = organisationId;
    this.stakeholdersProvider = StakeholdersApi;
  }

  @action
  onFileDrop = async (files: FileList, e: any) => {
    this.showFileSizeWarning = false;
    this.showFileTypeWarning = false;

    for (var f = 0; f < files.length; f++) {
      let isCorrectFileType: boolean = files[f].type === "text/csv";
      let fileHasContent: boolean = files[f].size > 0;

      if (!isCorrectFileType) this.showFileTypeWarning = true;

      if (!fileHasContent) this.showFileSizeWarning = true;

      if (isCorrectFileType && fileHasContent) {
        this.isUploading = true;
        var result = await this.stakeholdersProvider.upload(this.organisationId, files[f], files[f].name);

        if (result) {
          this.isUploading = false;
          this.showSuccessMessage = true;
        }
      }
    }
  };
}
