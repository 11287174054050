import * as React from "react";
import { Enums } from "../../../../enums";
import I18n from "../../../../core/localization/I18n";
import { Link } from "react-router-dom";
import { useCurrentOrganisationId } from "../../../../services/local/organisationContext/OrganisationContextModel";
import { Animations } from "../../../../core/util/Animations";
import { QUERY_STRING_PARAMS } from "../../../../services/local/queryStringService/QueryStringService";

export interface StakeholderDetailsProps {
  item: FP.Entities.IStakeholder;
}

const StakeholderDetails: React.FunctionComponent<StakeholderDetailsProps> = ({ item }) => {
  const organisationId = useCurrentOrganisationId();
  if (item.stakeholderType === Enums.StakeholderType.AUDIENCE) {
    return <AudienceDetails item={item} />;
  }

  return (
    <div className={`stakeholder-card ${Animations.FP_ZOOM_IN} speed-4 row`}>
      <div className="col-lg-4 mb-4 text-truncate">
        <h5 className="mb-1">{I18n.t("table.email")}</h5>
        <p className="mb-0">
          <a className="hover-underline body-bold text-link-style" href={`mailto:${item.email}`}>
            {item.email}
          </a>
        </p>
      </div>
      <div className="col-lg-4 mb-4">
        <h5 className="mb-1">{I18n.t("table.changeNetwork")}</h5>
        <p className="mb-0">{I18n.t(item.isChangeNetwork ? "phrases.yes" : "phrases.no")}</p>
      </div>
      <div className="col-lg-4 mb-4">
        <h5 className="mb-1">{I18n.t("table.relationshipOwner")}</h5>
        <Link
          to={`/organisations/${organisationId}/users/${item.owner?.sub}?${
            QUERY_STRING_PARAMS.RETURN_URL
          }=${encodeURIComponent(window.location.href.replace(appConfig.baseUrl, ""))}`}
        >
          <p className="mb-0 body-bold text-link-style">{`${item.owner?.firstName} ${item.owner?.lastName}`}</p>
        </Link>
      </div>
      <div className="col-lg-4 mb-4">
        <h5 className="mb-1">{I18n.t("table.businessArea")}</h5>
        <p className="mb-0">{item.businessArea?.name || I18n.t("phrases.na")}</p>
      </div>
      <div className="col-lg-4 mb-4">
        <h5 className="mb-1">{I18n.t("table.role")}</h5>
        <p className="mb-0">{item.role?.name || I18n.t("phrases.na")}</p>
      </div>
    </div>
  );
};

const AudienceDetails: React.FunctionComponent<StakeholderDetailsProps> = ({ item }) => {
  const organisationId = useCurrentOrganisationId();
  return (
    <div className={`stakeholder-card ${Animations.FP_ZOOM_IN} speed-4 row`}>
      <div className="col-lg-4 mb-4">
        <h5 className="mb-1">{I18n.t("table.audienceCount")}</h5>
        <p className="mb-0">{item.audienceCount}</p>
      </div>
      <div className="col-lg-4 mb-4">
        <h5 className="mb-1">{I18n.t("table.businessArea")}</h5>
        <p className="mb-0">{item.businessArea?.name || I18n.t("phrases.na")}</p>
      </div>
      <div className="col-lg-4 mb-5">
        <h5 className="mb-1">{I18n.t("table.relationshipOwner")}</h5>
        <Link
          to={`/organisations/${organisationId}/users/${item.owner.sub}?${
            QUERY_STRING_PARAMS.RETURN_URL
          }=${encodeURIComponent(window.location.href.replace(appConfig.baseUrl, ""))}`}
        >
          <p className="mb-0 text-link-style">{`${item.owner?.firstName} ${item.owner?.lastName}`}</p>
        </Link>
      </div>
    </div>
  );
};

export { StakeholderDetails, AudienceDetails };
