import { AxiosInstance, AxiosRequestConfig } from "axios";

import Config from "../../../config";
import { IFilteredOptions, IFilteredRequest } from "./filteredApi/FilteredApiModel";

export interface IBaseApiModel<T> {
  version: string;
  controller: string;
  http: AxiosInstance;
  url: string;

  getById: (id: number, config?: AxiosRequestConfig) => Promise<IFlightPathApiResponse<T>>;

  findAll: (config?: AxiosRequestConfig) => Promise<IFlightPathApiResponse<T[]>>;

  remove: (id: number, config?: AxiosRequestConfig) => Promise<IFlightPathApiResponse<void>>;

  create: (data: T, config?: AxiosRequestConfig) => Promise<IFlightPathApiResponse<T>>;

  update: (id: number, data: T, config?: AxiosRequestConfig) => Promise<IFlightPathApiResponse<T>>;

  UrlWithOrgId: (organistionId: number) => string;
}

export interface IFlightPathApiResponse<T> {
  code?: number;
  message?: string;
  payload?: T;
  sentDate?: Date;
  isError?: boolean;
}

export abstract class BaseApiModel<T> implements IBaseApiModel<T> {
  version: string = Config.API.VERSION.__LATEST__;
  abstract controller: string;
  http: AxiosInstance;
  url: string = "";

  constructor(http: AxiosInstance, controller: string) {
    this.http = http;
    this.url = `/api/${this.version}/${controller}/`;
  }

  getById = async (id: number, config?: AxiosRequestConfig) => {
    let res = await this.http.get(this.url + id, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<T>;
    }
    console.error("API error - Status Code: " + res.status + " Url: " + this.url);
    console.error("Status: " + res.statusText);
    return null;
  };

  findAll = async (config?: AxiosRequestConfig) => {
    let res = await this.http.get(this.url, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<T[]>;
    }
    console.error("API error - Status Code: " + res.status + " Url: " + this.url);
    console.error("Status: " + res.statusText);
    return null;
  };

  remove = async (id: number, config?: AxiosRequestConfig) => {
    let res = await this.http.delete(this.url + id, config);
    if (res && res.status === 200) {
      return res.data;
    }
  };

  create = async (data: T, config?: AxiosRequestConfig) => {
    let res = await this.http.post(this.url + "create", data, config);
    if (res && res.status === 200) {
      return res.data;
    }
  };

  update = async (id: number, data: T, config?: AxiosRequestConfig) => {
    let res = await this.http.put(this.url + id, data, config);
    if (res && res.status === 200) {
      return res.data;
    }
  };

  UrlWithOrgId = (organistionId: number) => {
    return `${this.url}organisations/${organistionId}/${this.controller}/`;
  };

  getRequestQuery = (filters: Partial<IFilteredOptions>): string => {
    let s: IFilteredRequest = {
      page: filters.page,
      pageSize: filters.pageSize,
      sorts: filters.sorts,
      filters: filters.filters
    };
    let params = "?";
    params += s.page ? `page=${s.page}&` : "";
    params += s.pageSize ? `pageSize=${s.pageSize}` : "";
    params += s.sorts ? "&sorts=" + s.sorts : "";
    params += s.filters ? "&filters=" + s.filters : "";
    return params.replace(/^\?&(.*)$/i, "?$1");
  };
}
