import _ from "lodash";
import { IconSymbols } from "../../../../../../../../components/ui/Icon";
import { AutocompleteOption } from "../../../../../../../../components/ui/_forms/Autocomplete/AutocompleteOption";
import { ProgressIndicatorModel as IProgressIndicatorModel } from "../../../../../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import { FORM_COL, REMOVE_UNSAFE_CHARACTERS } from "../../../../../../../../constants";
import { IMultiSelectorModel } from "../../../../../../../../core/forms/controls/multiSelector/IMultiSelectorModel";
import { INIT_MULTISELECTOR } from "../../../../../../../../core/forms/controls/multiSelector/MultiSelector_model";
import { generateFormFieldsFromJson } from "../../../../../../../../core/forms/helpers/FormFieldMappers";
import I18n from "../../../../../../../../core/localization/I18n";
import { ActionsApi } from "../../../../../../../../services/api/v2/actions/Actions.api";

export const getImpactActionFormFields = (
  actionsProvider: ActionsApi,
  organisationId: number,
  projectId: number,
  projectStakeholderId: number,
  actionsList: FP.Entities.IAction[],
  loadActions: (impactId) => Promise<any>,
  httpProgress: IProgressIndicatorModel
) => {
  const addAction = async (action: FP.Entities.IAction) => {
    httpProgress.showTopProgressBarVisible();
    if (action.id === 0) {
      action = await addNewAction(action.name);
    }
    let res = await actionsProvider.assignStakeholderOwners(
      organisationId,
      projectId,
      [action.id],
      [projectStakeholderId]
    );
    httpProgress.hideTopProgressBarVisible();
    if (!res) return;
    await loadActions(projectStakeholderId);
  };

  const addNewAction = async (actionName: string) => {
    const action: Partial<FP.Entities.IAction> = {
      id: 0,
      name: actionName,
      projectId: projectId,
      impacts: [],
      tags: []
    };

    let res = await actionsProvider.create(organisationId, projectId, action as FP.Entities.IAction);
    return res.payload;
  };

  const actions: Partial<IMultiSelectorModel> = {
    ...INIT_MULTISELECTOR,
    key: "actions",
    placeholder: I18n.t("placeholders.searchOrCreateActions"),
    optionElement: (
      <AutocompleteOption key={"e"} className={"autocomplete__chip"} label={(e: FP.Entities.IAction) => e.name} />
    ),
    onItemSelected: e => addAction(e as any),
    onFocus: async function () {
      const self: IMultiSelectorModel = this;
      const res = await actionsProvider.getUnassignedStakeholderOwnerActions(
        organisationId,
        projectId,
        projectStakeholderId
      );

      if (res?.payload) {
        let sortedActions = _.orderBy(res.payload, [action => action.name.toLowerCase()]);
        self.setOptions(sortedActions);
      }
    },
    componentProps: {
      icon: IconSymbols.TagFilled
    },
    searchAttribute: "name",
    fieldClassName: FORM_COL.FULL_WIDTH,
    extractValue: function () {
      return this.selectedItems.map(e => REMOVE_UNSAFE_CHARACTERS(e.text));
    },
    value: actionsList,
    isHidden: false,
    valueLabelFn: e => e?.name,
    saveOnEnterFn: e => addNewAction(e.target.value),
    isTagSelector: true,
    allowFreeText: true,
    shouldHidePills: true,
    isNewFn: e => e.id === 0,
    noResultsFoundLabel: I18n.t("forms.actionsResultsNotFound"),
    searchResultHint: <p className="mb-0 pl-3 pb-1 pt-2">{I18n.t("forms.actionsSearchResultHint")}</p>
  };

  const fields = [];

  fields.push(actions);
  const models = generateFormFieldsFromJson(fields);
  return models;
};
