import { IBaseColumnBuilder, ISdColDef, ISdFilterDef } from "./BaseColumnTypes";
import { ColumnFilterBuilder } from "./ColumnFilter_builder";
import { ColumnOptionsBuilder } from "./ColumnOptions_builder";

export abstract class BaseColumnBuilder implements IBaseColumnBuilder {
  types: string[];
  columnOptionsBuilder: ColumnOptionsBuilder;
  columnFilterBuilder: ColumnFilterBuilder;

  constructor(columnOptions: ISdColDef<any, any>) {
    this.columnOptionsBuilder = new ColumnOptionsBuilder(columnOptions);
    this.columnFilterBuilder = new ColumnFilterBuilder();
  }

  setColumnOptions = (columnOptions: ISdColDef<any, any>) => {
    this.columnOptionsBuilder.setColumnOptions(columnOptions);
    return this;
  };

  setFilterOptions = (filterOptions: ISdFilterDef) => {
    this.columnFilterBuilder.setFilterOptions(filterOptions);
    return this;
  };

  withOnDeleteModal = (editFn?: any) => {
    const suppressKeyboardEvent = e => {
      const isDeleteKey = e.event.key === "Backspace";
      const isBackspaceKey = e.event.key === "Delete";
      if (!isDeleteKey && !isBackspaceKey) return false;

      editFn(e.data);
      return true;
    };

    this.columnOptionsBuilder.setColumnOptions({ suppressKeyboardEvent });

    return this;
  };

  makeSelectable = (isSelectable = true) => {
    isSelectable
      ? this.columnOptionsBuilder.addType("selectableClass")
      : this.columnOptionsBuilder.removeType("selectableClass");

    return this;
  };

  makeReadOnly = (isReadOnly = true) => {
    isReadOnly
      ? this.columnOptionsBuilder.addType("readOnlyCell")
      : this.columnOptionsBuilder.removeType("readOnlyCell");
    return this;
  };

  makeEditable = (isEditable = true) => {
    this.columnOptionsBuilder.setColumnOptions({ editable: isEditable });
    return this;
  };

  makeDeletable = (isDeletable = true) => {
    isDeletable
      ? this.columnOptionsBuilder.addType("deletableCell")
      : this.columnOptionsBuilder.removeType("deletableCell");
    return this;
  };

  disableDelete = () => {
    return this.setColumnOptions({
      suppressKeyboardEvent: params => {
        const isDeleteKey = params.event.keyCode === 46;
        const isBackspaceKey = params.event.keyCode === 8;
        if (!isDeleteKey && !isBackspaceKey) return false;
        return true;
      }
    });
  };

  setCellDataType = (type: "text" | "number" | "boolean" | "date" | "dateString" | "object") => {
    this.columnOptionsBuilder.setColumnOptions({
      cellDataType: type ?? false
    });
    return this;
  };

  generateColumnOptions = (): ISdColDef<any, any> => {
    return {
      ...this.columnOptionsBuilder.columnOptions,
      ...this.columnFilterBuilder.filterOptions
    };
  };
}
