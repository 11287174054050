import { action, makeObservable, observable } from "mobx";
import AudiencesApi, { AudiencesApi as IAudiencesApi } from "../../../../../services/api/v2/audiences/Audiences.api";

export class AudienceMapModel {
  @observable audiences: any[] = [];
  @observable isLoading: boolean = true;
  organisationId: number;
  audiencesProvider: IAudiencesApi;
  projectId: number;

  constructor(organisationId: number, projectId: number) {
    makeObservable(this);
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.audiencesProvider = AudiencesApi;
  }

  onMount = async () => {
    await this.loadAudiences();
  };

  loadAudiences = async () => {
    let res = await this.audiencesProvider.getAudiences(this.organisationId, this.projectId);

    if (res.isError) {
      return;
    }

    this.setAudiences(res.payload);
    this.setIsLoading(false);
  };

  @action
  setIsLoading = (isLoading: boolean) => (this.isLoading = isLoading);

  @action
  setAudiences = (audiences: any[]) => (this.audiences = audiences);
}
