import { observer } from "mobx-react";
import React, { useState } from "react";
import { PositionedSpinner } from "../../../../../components/ui/PositionedSpinner";
import { useCurrentOrganisationId } from "../../../../../services/local/organisationContext/OrganisationContextModel";
import { useParams } from "react-router-dom";
import { SingleForm } from "../../../../../pages/change/forms/singleFormModel/SingleForm_view";
import { ImpactTypesSidebarModel } from "./ImpactTypesSidebar_model";

export interface ImpactTypesSidebarProps {
  onSubmit: (impactTypes: number[]) => void;
  currentIds: number[];
}

export const ImpactTypesSidebar: React.FC<ImpactTypesSidebarProps> = observer(({ onSubmit, currentIds }) => {
  const organisationId = useCurrentOrganisationId();
  const { projectId } = useParams();
  const [model] = useState(() => new ImpactTypesSidebarModel(organisationId, +projectId, onSubmit, currentIds));
  if (model.isLoading) return <PositionedSpinner />;

  return (
    <div className="container-fluid">
      <SingleForm model={model.formModel} />
    </div>
  );
});
