import React, { useState } from "react";
import { Button } from "../../../../../../../../components/ui/Button";
import I18n from "../../../../../../../localization/I18n";
import { useModalContext } from "../../../../../../../modalZ/context/ModalContext";
import ProjectStakeholderOwnersApi from "../../../../../../../../services/api/v2/projectStakeholderOwners/ProjectStakeholderOwners.api";
import ProjectTeamUserPermissionsApi from "../../../../../../../../services/api/v2/projectTeamUserPermissions/ProjectTeamUserPermissions.api";
import { getProjectStakeholderOwnerFormFields } from "./ProjectStakeholderOwnersSidebar_formFields";
import { SingleFormModel } from "../../../../../../../../pages/change/forms/singleFormModel/SingleForm_model";
import { SingleForm } from "../../../../../../../../pages/change/forms/singleFormModel/SingleForm_view";

interface ManageProjectStakeholderOwnerFormProps {
  organisationId: number;
  projectId: number;
  projectStakeholder: FP.Entities.IProjectStakeholder;
  onAddOwner: () => void;
  pills: any;
}

class ManageProjectStakeholderOwnerFormModel {
  formModel: SingleFormModel;

  constructor(
    organisationId: number,
    projectId: number,
    projectStakeholder: FP.Entities.IProjectStakeholder,
    onAddOwner: () => void
  ) {
    this.formModel = new SingleFormModel();

    const projectStakeholderOwnerProvider = ProjectStakeholderOwnersApi;
    const projectTeamUserPermissionsProvider = ProjectTeamUserPermissionsApi;
    let formFields = getProjectStakeholderOwnerFormFields(
      projectStakeholderOwnerProvider,
      projectTeamUserPermissionsProvider,
      organisationId,
      projectId,
      projectStakeholder,
      onAddOwner
    );
    this.formModel.formFields = formFields;
  }
}

export const ManageProjectStakeholderOwnerForm: React.FC<ManageProjectStakeholderOwnerFormProps> = props => {
  const { projectStakeholder, projectId, organisationId, onAddOwner } = props;
  const modalService = useModalContext();

  let [model] = useState(
    () => new ManageProjectStakeholderOwnerFormModel(organisationId, projectId, projectStakeholder, onAddOwner)
  );

  return (
    <div>
      <SingleForm model={model.formModel} />
      <div className="row mb-5">
        <div className="col">
          <div className="d-flex flex-column">
            <div className="row mb-3">{props.pills}</div>
            <Button
              className="ml-auto"
              onClick={() => {
                modalService.hide();
              }}
            >
              {I18n.t("phrases.done")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
