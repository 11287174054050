import { action, makeObservable, observable } from "mobx";

import PermissionsContext from "../../../../../../../../contexts/permissions/PermissionsContext";
import { PermissionFields } from "../../../../../../../../contexts/permissions/PermissionsTypes";
import I18n from "../../../../../../../localization/I18n";
import { UiActionRenderers } from "../../../../../../../uiAction/IUiAction";
import { BaseModel } from "../../../../../../../util/BaseModel";
import ImpactTypesApi, {
  ImpactTypesApi as IImpactTypesApi
} from "../../../../../../../../services/api/v2/impactTypes/ImpactTypes.api";
import ImpactsApi, { ImpactsApi as IImpactsApi } from "../../../../../../../../services/api/v2/impacts/Impacts.api";
import { getImpactTypesSidebarFormFields } from "./ImpactTypesSidebar_formFields";
import { SingleFormModel } from "../../../../../../../../pages/change/forms/singleFormModel/SingleForm_model";
import ModalContext from "../../../../../../../modalZ/context/ModalContext";

export class ImpactTypesSidebarModel extends BaseModel {
  impactTypesProvider: IImpactTypesApi;
  impactsProvider: IImpactsApi;
  @observable isLoading: boolean = true;
  @observable.ref impact: FP.Entities.IImpact;
  organisationId: number;
  formModel: SingleFormModel;
  projectId: number;

  constructor(organisationId: number, projectId: number, impactId: number) {
    super();
    makeObservable(this);
    this.impactTypesProvider = ImpactTypesApi;
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.impactsProvider = ImpactsApi;
    this.loadImpact(impactId);
    this.initForm();
  }

  initForm = () => {
    this.formModel = new SingleFormModel();
    this.formModel.setActions([
      {
        id: "action1",
        label: I18n.t("phrases.done"),
        onAction: ev => ModalContext.hide(),
        componentProps: {
          className: "ml-auto"
        },
        rendersIn: UiActionRenderers.BUTTON
      }
    ]);
  };
  @action
  setImpact = (impact: FP.Entities.IImpact) => {
    this.impact = impact;
    this.isLoading = false;
  };

  @action
  loadImpact = async (impactId: number) => {
    this.isLoading = true;
    let res = await this.impactsProvider.getDetailedById(this.organisationId, this.projectId, impactId);
    if (!res || res.isError) return;

    this.setImpact(res.payload);
    this.setFormModel();
  };

  @action
  setFormModel = () => {
    const canEdit = PermissionsContext.canEditField(PermissionFields.IMPACTS, this.organisationId, this.projectId);
    this.formModel.formFields = getImpactTypesSidebarFormFields(
      this.impactTypesProvider,
      canEdit,
      this.organisationId,
      this.projectId,
      this.impact
    );
  };
}
