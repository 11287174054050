import { action, makeObservable, observable } from "mobx";
import { IFlightPathApiResponse } from "../../../../../../../services/api/v2/BaseApiModel";

export class ImpactBarChartModel {
  projectId: number;
  organisationId: number;
  @observable isLoading = true;
  @observable data: any[];
  @observable dataConfidence: number;
  loadData: (organisationId: number, projectId: number) => Promise<IFlightPathApiResponse<any>>;
  onDataLoaded: (key: string, confidence: number) => void;
  key: string;

  constructor(
    organisationId: number,
    projectId: number,
    loadData: (organisationId: number, projectId: number) => Promise<IFlightPathApiResponse<any>>,
    onDataLoaded: (key: string, confidence: number) => void,
    chartKey: string
  ) {
    makeObservable(this);
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.loadData = loadData;
    this.onDataLoaded = onDataLoaded;
    this.key = chartKey;
  }

  onMount = async () => {
    await this.updateData();
  };

  updateData = async () => {
    let res = await this.loadData(this.organisationId, this.projectId);
    if (res.isError) return;

    this.setData(res.payload.data);
    this.setDataConfidence(res.payload.dataConfidence);
    this.onDataLoaded(this.key, res.payload.dataConfidence);
    this.setIsLoading(false);
  };

  @action
  setData = (data: any[]) => (this.data = data);

  @action
  setIsLoading = (isLoading: boolean) => (this.isLoading = isLoading);

  @action
  setDataConfidence = (dataConfidence: number) => (this.dataConfidence = dataConfidence);
}
