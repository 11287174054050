import { observer } from "mobx-react";
import * as React from "react";
import { Button } from "../../../../../../components/ui/Button";
import { CanEdit } from "../../../../../../contexts/permissions/PermissionHelpers";
import { PermissionFields } from "../../../../../../contexts/permissions/PermissionsTypes";
import { useFlightPathUser } from "../../../../../../core/auth_insight";
import I18n from "../../../../../../core/localization/I18n";
import { useModalContext } from "../../../../../../core/modalZ/context/ModalContext";
import { CommentListViewModel } from "../../../../../change/comments/commentListView/CommentListView_model";
import { CommentListView } from "../../../../../change/comments/commentListView/CommentListView_view";

export interface model {
  projectId: number;
  projectProcessId: number;
  organisationId: number;
}

export const ProjectProcessNotesSideBar: React.FunctionComponent<model> = observer(p => {
  const authUser = useFlightPathUser();
  const projectId = p.projectId;
  const modalService = useModalContext();
  const organisationId = p.organisationId;

  const listViewConfig = React.useMemo(() => {
    return {
      searchAttribute: "projectProcessId",
      id: p.projectProcessId,
      title: null,
      projectId: projectId,
      organisationId: organisationId,
      placeholderText: I18n.t("placeholders.actionNotePlaceholder")
    };
  }, [projectId, organisationId, p.projectProcessId]);

  const commentViewModel = React.useMemo(
    () => new CommentListViewModel(projectId, authUser, listViewConfig),
    [projectId, authUser, listViewConfig]
  );

  return (
    <div className="container-fluid impact-compact-view">
      <CanEdit projectId={projectId} field={PermissionFields.ACTIONS}>
        <div className="row mb-0">
          <div className="col">
            <h3 className="mb-0 pl-0 pb-0">{I18n.t("phrases.addANote")}</h3>
          </div>
        </div>
      </CanEdit>
      <CommentListView model={commentViewModel} field={PermissionFields.PROCESSES} />
      <div className="row mb-5">
        <div className="col">
          <div className="d-flex">
            <Button
              className="ml-auto"
              onClick={() => {
                modalService.hide();
              }}
            >
              {I18n.t("phrases.done")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
});
