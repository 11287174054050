import React from "react";
import { Button, ButtonTypes } from "../../../../../components/ui/Button";
import { useCurrentOrganisationId } from "../../../../../services/local/organisationContext/OrganisationContextModel";
import I18n from "../../../../../core/localization/I18n";
import { SingleFormModel } from "../../../forms/singleFormModel/SingleForm_model";
import { SingleForm } from "../../../forms/singleFormModel/SingleForm_view";
import { getUserCompactFields } from "./UserCompact_fields";

export interface IUserCompactViewProps {
  user: FP.Entities.IUser;
  onCancelClick: () => void;
  onSaveClick: (formResult: any) => void;
}

export const UserCompactView: React.FC<IUserCompactViewProps> = ({ user, onCancelClick, onSaveClick }) => {
  const organisationId = useCurrentOrganisationId();

  let formModel = new SingleFormModel();
  formModel.formFields = getUserCompactFields(organisationId, user);
  formModel.className = "mx-0";

  return (
    <div className="user-compact-view">
      <div className="row mx-0 mb-4">
        <div className="col-12">
          <h1 className="mb-0">{I18n.t("phrases.editUser")}</h1>
        </div>
        <div className="col-12">
          <p className="mb-0">{I18n.t("phrases.editUserDescription")}</p>
        </div>
      </div>
      <div className="row mx-0 mb-4">
        <div className="col-12">
          <h4 className="mb-0">{I18n.t("forms.firstName")}</h4>
        </div>
        <div className="col-12">
          <p className="mb-0">{user.firstName}</p>
        </div>
      </div>
      <div className="row mx-0 mb-4">
        <div className="col-12">
          <h4 className="mb-0">{I18n.t("forms.lastName")}</h4>
        </div>
        <div className="col-12">
          <p className="mb-0">{user.lastName}</p>
        </div>
      </div>
      <div className="row mx-0 mb-4">
        <div className="col-12">
          <h4 className="mb-0">{I18n.t("forms.email")}</h4>
        </div>
        <div className="col-12">
          <p className="mb-0">{user.email}</p>
        </div>
      </div>
      <div className="row mx-0 mb-4">
        <div className="col-12">
          <h4 className="mb-0">{I18n.t("table.invitedBy")}</h4>
        </div>
        <div className="col-12">
          <p className="mb-0">
            {user.createdBy.firstName} {user.createdBy.lastName}
          </p>
          <small>{user.createdBy.email}</small>
        </div>
      </div>

      <SingleForm model={formModel} />
      <div className="row mx-0">
        <div className="col">
          <Button
            onClick={async () => {
              let res = await formModel.submit();
              if (!res) return;
              onSaveClick(res);
            }}
            className="float-right"
          >
            {I18n.t("phrases.save")}
          </Button>
          <Button
            onClick={() => {
              onCancelClick();
            }}
            type={ButtonTypes.OUTLINE_PRIMARY}
            className="float-right mr-2"
          >
            {I18n.t("phrases.cancel")}
          </Button>
        </div>
      </div>
    </div>
  );
};
