import { GridApi, GridReadyEvent, SelectionChangedEvent } from "ag-grid-community";
import _ from "lodash";
import { action, makeObservable, observable } from "mobx";
import ProjectProcessRolesApi, {
  ProjectProcessRolesApi as IProjectProcessRolesApi
} from "../../../../../../../services/api/v2/projectProcessRoles/ProjectProcessReoles.api";
import ProjectJobRolesApi, {
  ProjectJobRolesApi as IProjectJobRolesApi
} from "../../../../../../../services/api/v2/projectJobRoles/ProjectJobRoles.api";

export class ProjectProcessRolesGridModel {
  projectId: number;
  organisationId: number;
  projectProcessRolesProvider: IProjectProcessRolesApi;
  projectJobRolesProvider: IProjectJobRolesApi;
  @observable isLoading: boolean = true;
  @observable gridData: FP.Entities.IImpactSummary[];
  authUser: FP.Entities.IUser;
  @observable.ref selectedProcessRoles: number[] = [];
  gridApi: GridApi;
  searchText: string;
  jobRoleIds: number[];

  /**
   *
   */
  constructor(projectId: number, organisationId: number, authUser: FP.Entities.IUser) {
    makeObservable(this);
    this.projectId = projectId;
    this.organisationId = organisationId;
    this.projectProcessRolesProvider = ProjectProcessRolesApi;
    this.projectJobRolesProvider = ProjectJobRolesApi;
    this.authUser = authUser;
  }

  onMount = async () => {
    this.loadProjectProcessRoles();
  };

  onUnmount = () => {};

  loadProjectProcessRoles = async () => {
    let res = await this.projectProcessRolesProvider.getProjectProcessRoles(this.organisationId, this.projectId);
    if (res.isError) return;

    this.setGridData(res.payload.map(e => ({ id: e.id, name: e.name, processes: e.processes })));
  };

  @action
  setGridData = processRoles => {
    this.gridData = processRoles;
    this.isLoading = false;
  };

  @action
  onGridReady = (gridReadyEvent: GridReadyEvent) => {
    this.gridApi = gridReadyEvent.api;
  };

  @action
  setSearchText = (ev: React.FormEvent<HTMLInputElement>) => {
    this.searchText = ev.currentTarget.value;

    if (this.gridApi !== undefined) {
      this.gridApi.setGridOption("quickFilterText", this.searchText);
    }
  };

  @action
  updateSelectedProcessRoles = (event: SelectionChangedEvent) => {
    this.gridApi = event.api;
    this.selectedProcessRoles = _.map(event.api.getSelectedNodes(), e => {
      return e.data.id;
    });
  };
}
