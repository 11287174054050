import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { PositionedSpinner } from "../../../../../components/ui/PositionedSpinner";
import I18n from "../../../../../core/localization/I18n";
import { useCurrentOrganisationId } from "../../../../../services/local/organisationContext/OrganisationContextModel";
import { StakeholderAccordion } from "../../view/accordionContent/StakeholderAccordion";
import { StakeholderProjectsModel } from "./StakeholderProjects_model";

export const StakeholderProjects: React.FC<{ stakeholderId: number }> = observer(({ stakeholderId }) => {
  const organisationId = useCurrentOrganisationId();
  const [model] = useState(() => new StakeholderProjectsModel(organisationId, stakeholderId));
  useEffect(() => {
    model.onMount();
  }, [model]);

  if (model.isLoading) {
    return <PositionedSpinner />;
  }
  return (
    <>
      <div className="container-fluid">
        <div className="row mt-5">
          <div className="col">
            <h1>{I18n.t("entities.projects")}</h1>
          </div>
        </div>
      </div>

      {model.projectDetails.map(e => {
        return <StakeholderAccordion key={e.id} projectStakeholder={e} stakeholderId={stakeholderId} />;
      })}
    </>
  );
});
