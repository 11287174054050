import { GridApi, GridReadyEvent } from "ag-grid-community";
import { action, makeObservable, observable } from "mobx";
import { BaseModel } from "../../../../../../core/util/BaseModel";
import { StakeholderType } from "../../../../../../enums";
import OrganisationReportsApi, {
  OrganisationReportsApi as IOrganisationReportsApi
} from "../../../../../../services/api/v2/organisationReports/OrganisationReports.api";

export class StakeholderCapacityModel extends BaseModel {
  @observable organisationId: number;
  isComponentMounted: boolean = false;
  @observable connectedUsers: any[] = [];
  @observable isLoading: boolean = true;
  authUser: FP.Entities.IUser;
  @observable stakeholderCapacityMetrics: FP.Entities.IStakeholderAssignmentMetric[];
  @observable stakeholderType: number;
  gridApi: GridApi;
  @observable searchText: string;
  organisationReportsProvider: IOrganisationReportsApi;

  constructor(organisationId: number, authUser: FP.Entities.IUser, stakeholderType: number) {
    super();
    makeObservable(this);
    this.organisationId = organisationId;
    this.organisationReportsProvider = OrganisationReportsApi;
    this.authUser = authUser;
    this.stakeholderType = stakeholderType;
  }

  onMount = async () => {
    await this.loadCapacityMetrics();
  };

  onUnmount = () => {};

  loadCapacityMetrics = async () => {
    let res = {} as any;
    if (this.stakeholderType === StakeholderType.INDIVIDUAL) {
      res = await this.organisationReportsProvider.getStakeholderCapacityMetricsByOrgId(this.organisationId);
    }
    if (this.stakeholderType === StakeholderType.AUDIENCE) {
      res = await this.organisationReportsProvider.getAudienceCapacityMetricsByOrgId(this.organisationId);
    }
    if (!res || res.isError) return false;
    this.setStakeholderCapacityMetricsData(res.payload);
  };

  @action
  setStakeholderCapacityMetricsData = data => {
    this.stakeholderCapacityMetrics = data;
    this.isLoading = false;
  };

  updateData = () => {};

  isRowSelectable = () => {
    return false;
  };

  @action
  onGridReady = (gridReadyEvent: GridReadyEvent): void => {
    this.gridApi = gridReadyEvent.api;
  };

  @action
  setSearchText = (ev: React.FormEvent<HTMLInputElement>) => {
    this.searchText = ev.currentTarget.value;

    if (this.gridApi !== undefined) {
      this.gridApi.setGridOption("quickFilterText", this.searchText);
    }
  };
}
