import { multiValueTextMatcher } from "../../../../../filters/TextMatcher";
import { ISdColDef, ISdFilterDef } from "../../baseColumn/BaseColumnTypes";
import { BASE_COLUMN_CONFIG, BASE_FILTER_CONFIG } from "../../baseColumn/BaseColumn_config";

export const PILLS_COLUMN_CONFIG = (columnOptions?: ISdColDef<any, any>): ISdColDef<any, any> => ({
  ...BASE_COLUMN_CONFIG,
  field: "pillsColumn",
  headerName: "Header Pills",
  editable: false,
  width: 500,
  ...columnOptions
});

export const PILLS_FILTER_CONFIG: ISdFilterDef = {
  ...BASE_FILTER_CONFIG,
  filter: "agTextColumnFilter",
  filterParams: {
    buttons: ["clear"],
    textMatcher: multiValueTextMatcher
  }
};
