import { action, makeObservable, observable } from "mobx";
import { IGridModel } from "../../../../../../../core/grids/IGridModel";
import { BaseModel } from "../../../../../../../core/util/BaseModel";
import { GridTypes } from "../../../../../../../enums";
import { GetProjectProcessRoleGridColumns } from "./ProjectProcessRolesGrid_columns";

export class ProjectProcessRolesGridViewModel extends BaseModel implements IGridModel {
  authUser: FP.Entities.IUser;
  @observable isLoading: boolean = true;
  type: GridTypes = GridTypes.PROCESS_ROLES_GRID;
  organisationId: number;
  projectId: number;

  constructor(organisationId: number, projectId: number, authUser: FP.Entities.IUser) {
    super();
    makeObservable(this);
    this.authUser = authUser;
    this.organisationId = organisationId;
    this.projectId = projectId;
  }

  onMount = async () => {
    this.setIsLoading(false);
  };

  @action
  setIsLoading = (isLoading: boolean) => (this.isLoading = isLoading);

  onUnmount = () => {};

  getColumnConfig = () => {
    return GetProjectProcessRoleGridColumns();
  };
}
