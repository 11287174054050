import { observer } from "mobx-react-lite";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IF } from "../../../../../components/hoc/If";
import { Panel } from "../../../../../components/ui/Panel";
import { ChartUsage, GapAnalysisChart } from "../../../../../components/widgets/gapAnalysisChart/gapAnalysisChart";
import {
  CommonColDefFieldNamesEnum,
  ImpactColDefFieldNamesEnum
} from "../../../../../core/grids/builder/enums/AgGridColDefFieldNameEnum";
import I18n from "../../../../../core/localization/I18n";
import { ReportFilterView } from "../../../../../core/reportFilter/ReportFilter_view";
import { ReportFilterAddonsKeys } from "../../../../../core/reportFilter/ReportFilterAddon";
import Pages from "../../../../../routes/InsightRoutes";
import { IFlightPathApiResponse } from "../../../../../services/api/v2/BaseApiModel";
import ImpactReportingApi from "../../../../../services/api/v2/impactReporting/ImpactReporting.api";
import { useCurrentOrganisationId } from "../../../../../services/local/organisationContext/OrganisationContextModel";
import { ReportingPie } from "../../Actions/ActionReportingView/TopActionsReportingBar/ReportingPie";
import { ImpactBarChart } from "./charts/ImpactBarChart/ImpactBarChart_view";
import { ImpactAssessmentModel } from "./ImpactAssessment_model";
import { UrlOperators } from "../../../../../core/grids/builder/directorBuilder/grids/base/addons/FilterAddon/FilterAddonContants";

export const ImpactAssesment: React.FC = observer(() => {
  const navigate = useNavigate();
  const impactsByTypeRef = useRef();
  const impactsByLocationRef = useRef();
  const impactsByBusinessAreaRef = useRef();
  const impactsByOwnerRef = useRef();
  const organisationId = useCurrentOrganisationId();
  const { projectId } = useParams<{ projectId: string; page: string }>();
  const [model] = useState(() => new ImpactAssessmentModel(organisationId, +projectId));

  useEffect(() => {
    model.onMount();
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <ReportFilterView
        addons={[
          ReportFilterAddonsKeys.ImpactGrid,
          ReportFilterAddonsKeys.BusinessArea,
          ReportFilterAddonsKeys.Location,
          ReportFilterAddonsKeys.ImpactType
        ]}
        filterState={model.filterJSON}
        onClearClicked={model.resetLocalStorage}
        onFilterChange={async k => {
          model.setFilterJSONFromList(k);
          model.confidenceList = [];

          return await Promise.all([
            (impactsByTypeRef as any)?.current?.loadData(),

            (impactsByLocationRef as any)?.current?.loadData(),

            (impactsByBusinessAreaRef as any)?.current?.loadData(),

            (impactsByOwnerRef as any)?.current?.loadData(),
            model.loadImpacts(),
            model.loadImpactLevelStatus()
          ]).then(async e => {
            return null;
          });
        }}
      />
      <hr className="m-0" />
      <div className="container-fluid">
        <div className="row pt-4 justify-content-between">
          <div className="col-4">
            {!!model.ragData && !!model.impactCount && (
              <ReportingPie
                data={model.ragData}
                metric={model.impactCount.toString()}
                colourArray={ragColourArray}
                title={I18n.t("visualisations.impactsLevel")}
                onClick={impactTypeOnClickFunction(organisationId, +projectId, navigate, model.hasFilters)}
              />
            )}
          </div>
          <div className="col-4">
            <IF condition={model.confidenceList.length === 4 && model.dataConfidence > 0}>
              <Panel.Panel
                hasBorder
                hasBorderRadius
                background={Panel.PanelBackgrounds.BG_LIGHT}
                className="p-3"
                style={{ height: 200 }}
              >
                <GapAnalysisChart
                  dataConfidence={model.dataConfidence}
                  heading="Gap Analysis"
                  chartUsage={ChartUsage.Chart}
                />
              </Panel.Panel>
            </IF>
          </div>
          <div className="col-4"></div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <ImpactBarChart
              ref={impactsByTypeRef}
              heading={I18n.t("visualisations.impactsByType")}
              gapAnalysisClick={GapAnalysisOnClickFunction(
                organisationId,
                +projectId,
                ImpactColDefFieldNamesEnum.ImpactTypes,
                model.hasFilters
              )}
              onDataLoaded={model.setConfidenceList}
              chartKey="impactsByType"
              drillThroughClick={DrillThroughOnClickFunction(
                organisationId,
                +projectId,
                ImpactColDefFieldNamesEnum.ImpactTypes,
                model.hasFilters
              )}
              noDataLabel={noDataLabel(I18n.t("visualisations.impactsByType"))}
              dataLoaderFn={async (organisationId: number, projectId: number): Promise<IFlightPathApiResponse<any>> => {
                return await ImpactReportingApi.getImpactsByTypeBreakdownReport(
                  organisationId,
                  projectId,
                  model.filterJSON
                );
              }}
            />
          </div>
          <div className="col-md-6">
            <ImpactBarChart
              heading={I18n.t("visualisations.impactsByLocation")}
              ref={impactsByLocationRef}
              gapAnalysisClick={GapAnalysisOnClickFunction(
                organisationId,
                +projectId,
                ImpactColDefFieldNamesEnum.Locations,
                model.hasFilters
              )}
              onDataLoaded={model.setConfidenceList}
              chartKey="impactsByLocation"
              drillThroughClick={DrillThroughOnClickFunction(
                organisationId,
                +projectId,
                ImpactColDefFieldNamesEnum.Locations,
                model.hasFilters
              )}
              noDataLabel={noDataLabel(I18n.t("visualisations.impactsByLocation"))}
              dataLoaderFn={function (organisationId: number, projectId: number): Promise<IFlightPathApiResponse<any>> {
                return ImpactReportingApi.getImpactsByLocationBreakdownReport(
                  organisationId,
                  projectId,
                  model.filterJSON
                );
              }}
            />
          </div>
        </div>
        <div className="row mb-4">
          <div className={"col-md-6"}>
            <ImpactBarChart
              heading={I18n.t("visualisations.impactsByBusinessArea")}
              ref={impactsByBusinessAreaRef}
              gapAnalysisClick={GapAnalysisOnClickFunction(
                organisationId,
                +projectId,
                ImpactColDefFieldNamesEnum.BusinessAreas,
                model.hasFilters
              )}
              onDataLoaded={model.setConfidenceList}
              chartKey="impactsByBusinessArea"
              drillThroughClick={DrillThroughOnClickFunction(
                organisationId,
                +projectId,
                ImpactColDefFieldNamesEnum.BusinessAreas,
                model.hasFilters
              )}
              noDataLabel={noDataLabel(I18n.t("visualisations.impactsByBusinessArea"))}
              dataLoaderFn={function (organisationId: number, projectId: number): Promise<IFlightPathApiResponse<any>> {
                return ImpactReportingApi.getImpactsByBusinessAreaBreakdownReport(
                  organisationId,
                  projectId,
                  model.filterJSON
                );
              }}
            />
          </div>
          <div className="col-md-6">
            <ImpactBarChart
              heading={I18n.t("visualisations.impactsByOwner")}
              ref={impactsByOwnerRef}
              onDataLoaded={model.setConfidenceList}
              chartKey="impactsByOwner"
              gapAnalysisClick={GapAnalysisOnClickFunction(
                organisationId,
                +projectId,
                CommonColDefFieldNamesEnum.Owner,
                model.hasFilters
              )}
              drillThroughClick={DrillThroughOnClickFunction(
                organisationId,
                +projectId,
                CommonColDefFieldNamesEnum.Owner,
                model.hasFilters
              )}
              noDataLabel={noDataLabel(I18n.t("visualisations.impactsByOwner"))}
              dataLoaderFn={function (organisationId: number, projectId: number): Promise<IFlightPathApiResponse<any>> {
                return ImpactReportingApi.getImpactsByOwnerBreakdownReport(organisationId, projectId, model.filterJSON);
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
});

const noDataLabel = (label: string) => {
  return `No ${label} Data`;
};

const GapAnalysisOnClickFunction =
  (
    organisationId: number,
    projectId: number,
    gapAnalysisType: string | CommonColDefFieldNamesEnum,
    hasFilters: boolean
  ) =>
  (node, event) => {
    if (!hasFilters) {
      const url = `${Pages.projects.impacts.listView.generateLink(organisationId, projectId)}?${gapAnalysisType}=-`;
      // this.navigate(url);
      window.location.href = appConfig.baseUrl + url;
    }
  };

const DrillThroughOnClickFunction =
  (
    organisationId: number,
    projectId: number,
    drillThroughType: string | CommonColDefFieldNamesEnum,
    hasFilters: boolean
  ) =>
  (data, event) => {
    if (!hasFilters) {
      const url = `${Pages.projects.impacts.listView.generateLink(organisationId, projectId)}?${
        ImpactColDefFieldNamesEnum.ImpactLevel
      }=${UrlOperators.contains.symbol}${data.id}&${drillThroughType}=${encodeURIComponent(data.indexValue)}`;
      // this.navigate(url);
      window.location.href = appConfig.baseUrl + url;
    }
  };

const impactTypeOnClickFunction = (organisationId, projectId, navigate, hasFilters: boolean) => (dataObject: any) => {
  if (!hasFilters) {
    let level = dataObject.id.charAt(0).toUpperCase() + dataObject.id.slice(1);

    let url = `${Pages.projects.impacts.listView.generateLink(organisationId, projectId)}?${
      ImpactColDefFieldNamesEnum.ImpactLevel
    }=${UrlOperators.contains.symbol}${level}`;
    navigate(url);
  }
};

const ragColourArray = ["#3D00C1", "#834FF1", "#BFA1FF", "#E5E5E5"];
