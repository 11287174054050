import React, { useEffect, useState } from "react";
import { StakeholderImpactsViewModel } from "./StakeholderImpactsView_model";
import { Button, ButtonIcon, ButtonTypes } from "../../../../components/ui/Button";
import { Icon, IconSymbols } from "../../../../components/ui/Icon";
import { ImpactCard } from "../../impacts/impactCard/ImpactCard_view";
import { observer } from "mobx-react";
import { PositionedSpinner } from "../../../../components/ui/PositionedSpinner";
import I18n from "../../../../core/localization/I18n";
import { Link, useParams } from "react-router-dom";
import { Enums } from "../../../../enums";
import { Panel } from "../../../../components/ui/Panel";
import AnimateHeight from "react-animate-height";
import { Input } from "../../../../components/ui/_forms/Input";
import { useCurrentOrganisationId } from "../../../../services/local/organisationContext/OrganisationContextModel";
import { Animations } from "../../../../core/util/Animations";
import { PanelTypes } from "@flightpath/coreui/dist/ui/Panel";
import { QUERY_STRING_PARAMS } from "../../../../services/local/queryStringService/QueryStringService";
import { PermissionFields } from "../../../../contexts/permissions/PermissionsTypes";
import { CanEdit, CanView } from "../../../../contexts/permissions/PermissionHelpers";
import { PermissionErrorMessage } from "../../../../contexts/permissions/PermissionMessages";

export interface StakeholderImpactsViewProps {
  isRouteView: boolean;
}

const StakeholderImpactsView: React.FunctionComponent<StakeholderImpactsViewProps> = observer(({ isRouteView }) => {
  const organisationId = useCurrentOrganisationId();
  const { projectId, stakeholderId } = useParams<{ projectId: string; stakeholderId: string }>();
  const [model, setModel] = useState(
    () => new StakeholderImpactsViewModel(organisationId, +projectId, +stakeholderId, isRouteView)
  );

  useEffect(() => {
    setModel(new StakeholderImpactsViewModel(organisationId, +projectId, +stakeholderId, isRouteView));
  }, [organisationId, projectId, stakeholderId, isRouteView]);

  useEffect(() => {
    model.onMount();
    return model.onUnmount;
  }, [model]);

  if (model.isLoading) return <PositionedSpinner />;

  return (
    <div className={`container-fluid pt-5 ${Animations.FADE_IN} speed-4`}>
      <CanView
        field={PermissionFields.IMPACTS}
        projectId={model.projectId}
        errorMessage={
          <div className="row mb-4">
            <div className="col">
              <PermissionErrorMessage>
                <p className="mb-0">{I18n.t("phrases.noImpactAccess")}</p>
              </PermissionErrorMessage>
            </div>
          </div>
        }
      >
        <div className="row mb-4">
          <div className="col">
            <CanEdit field={PermissionFields.IMPACTS} projectId={model.projectId}>
              <Button onClick={model.showAddImpactModal}>
                <Icon symbol={IconSymbols.Plus} className="mr-2" />
                {I18n.t("phrases.addImpact")}
              </Button>
            </CanEdit>

            <ButtonIcon
              className="float-right"
              symbol={model.isSearchMode ? IconSymbols.Close : IconSymbols.Search}
              iconSize={Enums.UiSizes.SM}
              type={ButtonTypes.OUTLINE_PRIMARY}
              onClick={model.isSearchMode ? model.resetSearch : model.showSearchMode}
              isDisabled={model.impacts?.length === 0}
            />

            <AnimateHeight height={model.isSearchMode ? "auto" : 0} animateOpacity={true}>
              <Input
                autoFocus={true}
                size={-1}
                onChange={model.updateSearchValue}
                borderStyle="underline"
                placeholder={I18n.t("placeholders.searchStakeholderImpacts")}
                icon="search"
                value={model.searchValue}
                onBlur={null}
                className="mt-3"
              />
            </AnimateHeight>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col">
            {model.filteredImpacts.length ? (
              model.filteredImpacts.map(impact => {
                const mainUrl = `/organisations/${organisationId}/projects/${impact.projectId}`;
                return (
                  <Link
                    key={impact.id}
                    to={`${mainUrl}/impacts/${impact.id}?${QUERY_STRING_PARAMS.RETURN_URL}=${encodeURIComponent(
                      `${mainUrl}/stakeholders/${model.stakeholderId}`
                    )}`}
                  >
                    <ImpactCard item={impact} />
                  </Link>
                );
              })
            ) : (
              <Panel.Panel hasBorderRadius={true} type={PanelTypes.OUTLINES} className="py-2 text-center">
                <div>{I18n.t("phrases.noStakeholderImpacts")}</div>
              </Panel.Panel>
            )}
          </div>
        </div>
      </CanView>
    </div>
  );
});

export { StakeholderImpactsView };
