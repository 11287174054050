import { FORM_COL } from "../../../../../../../../constants";
import {
  ISearchTreePickerModel,
  ITreeDataItem
} from "../../../../../../../forms/controls/searchTreePicker/ISearchTreePickerModel";
import { INIT_SEARCHTREEPICKER } from "../../../../../../../forms/controls/searchTreePicker/SearchTreePicker_model";
import { generateFormFieldsFromJson } from "../../../../../../../forms/helpers/FormFieldMappers";
import I18n from "../../../../../../../localization/I18n";
import { UiActionRenderers } from "../../../../../../../uiAction/IUiAction";

export const getProjectProcessLocationsSidebarFormActions = (
  closeModalFn: () => void,
  updateProjectProcessFn: () => void,
  canEdit: boolean
) => {
  let actions = [];
  if (canEdit)
    actions.push({
      id: "action1",
      label: I18n.t("phrases.done"),
      onAction: ev => {
        updateProjectProcessFn();
        closeModalFn();
      },
      componentProps: {
        className: "ml-auto"
      },
      rendersIn: UiActionRenderers.BUTTON
    });

  return actions;
};

export const getProjectProcessLocationsSidebarFormFields = (locationData: ITreeDataItem[]) => {
  const locations: Partial<ISearchTreePickerModel> = {
    ...INIT_SEARCHTREEPICKER,
    key: "locations",
    value: locationData.filter(e => e.isChecked === true),
    options: locationData,
    placeholder: I18n.t("placeholders.searchLocation"),
    fieldClassName: FORM_COL.FULL_WIDTH
  };

  let fields = [];
  fields.push(locations);

  return generateFormFieldsFromJson(fields);
};
