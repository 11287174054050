import { ISmartTableHeaderItem } from "@flightpath/coreui/dist/widgets/smartTable/ISmartTable";
import { IFilterModel } from "../../../core/filter/Filter_model";

export const AddSmartTableColumnSort = (
  header: ISmartTableHeaderItem,
  filterModel: IFilterModel<any>,
  sortKey?: string,
  resetData?: () => void
) => {
  resetData && resetData();
  const key = sortKey || header.id;
  if (filterModel.isSortEqualTo(key)) {
    filterModel.toggleSort();
  } else {
    filterModel.addSort({ key: key, isAsc: true });
  }

  filterModel.loadData();
};
