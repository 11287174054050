import { SelectionChangedEvent } from "ag-grid-community";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { IF } from "../../../../components/hoc/If";
import { ButtonTypes, LinkButton } from "../../../../components/ui/Button";
import { Panel } from "../../../../components/ui/Panel";
import { PositionedSpinner } from "../../../../components/ui/PositionedSpinner";
import { useFlightPathUser } from "../../../../core/auth_insight";
import { GridView } from "../../../../core/grids/GridView_view";
import I18n from "../../../../core/localization/I18n";
import Pages from "../../../../routes/InsightRoutes";
import { useCurrentOrganisationId } from "../../../../services/local/organisationContext/OrganisationContextModel";
import { ActionPies } from "./ActionPies";
import { ActionsModel } from "./Actions_model";
import { useUserId } from "../../../../core/auth/authorise";

export const Actions: React.FC<{ shouldShowSeeAllButton?: boolean }> = observer(({ shouldShowSeeAllButton }) => {
  const organisationId = useCurrentOrganisationId();
  const { projectId } = useParams();
  const authUser = useFlightPathUser();
  const userId = useUserId();
  const [model] = useState(() => new ActionsModel(organisationId, +projectId, authUser));

  useEffect(() => {
    model.onMount();
    return model.onUnmount;
  }, [model]);

  return (
    <div className="col-6 mb-4">
      <ActionPies
        isLoading={model.isLoading}
        pieData={model.pieData}
        progressData={model.progressData}
        count={model.gridActions.length}
        authUser={{ ...authUser, id: +userId }}
      />
      <Panel.Panel
        className={`p-4 `}
        hasBorder={true}
        background={Panel.PanelBackgrounds.BG_LIGHT}
        hasBorderRadius={true}
      >
        <div className={`report-card report-card--md ${!shouldShowSeeAllButton ? "container-fluid mt-5" : ""}`}>
          <div className="row">
            <div className="col">
              <h2>{I18n.t("phrases.myActions")}</h2>
            </div>
          </div>
          <IF condition={model.isLoading}>
            <PositionedSpinner />
          </IF>
          <IF condition={!model.isLoading}>
            <GridView
              context={{}}
              data={model.gridActions}
              users={[]}
              onCellClicked={() => {}}
              onCellEditModeChange={() => {}}
              model={model.gridModel}
              isLoading={model.gridModel.isLoading}
              onGridReady={model.onGridReady}
              onSelectionChanged={function (event: SelectionChangedEvent): void {}} // ref={filterRef}
              // filterHasChangedFn={setHasFilters}
              // isExternalFilterPresent={model.isExternalFilterPresent}
              // doesExternalFilterPass={model.doesExternalFilterPass}
            />
            <IF condition={shouldShowSeeAllButton}>
              <div className="row">
                <div className="col mt-2">
                  <LinkButton
                    type={ButtonTypes.OUTLINE_PRIMARY}
                    href={Pages.dashboards.actions.generateLink(organisationId)}
                    className="float-right"
                  >
                    {I18n.t("phrases.seeAll")}
                  </LinkButton>
                </div>
              </div>
            </IF>
          </IF>
        </div>
      </Panel.Panel>
    </div>
  );
});
