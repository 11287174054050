import { FORM_COL } from "../../../../../constants";
import {
  ISearchTreePickerModel,
  ITreeDataItem
} from "../../../../../core/forms/controls/searchTreePicker/ISearchTreePickerModel";
import { INIT_SEARCHTREEPICKER } from "../../../../../core/forms/controls/searchTreePicker/SearchTreePicker_model";
import { generateFormFieldsFromJson } from "../../../../../core/forms/helpers/FormFieldMappers";
import I18n from "../../../../../core/localization/I18n";
import ModalContext from "../../../../../core/modalZ/context/ModalContext";
import { UiActionRenderers } from "../../../../../core/uiAction/IUiAction";

export const getBusinessAreasSidebarFormActions = (updateImpactFn: () => void, canEdit: boolean) => {
  let actions = [];

  if (canEdit)
    actions.push({
      id: "action1",
      label: I18n.t("phrases.done"),
      onAction: ev => {
        ModalContext.hide();
        updateImpactFn();
      },
      componentProps: {
        className: "ml-auto"
      },
      rendersIn: UiActionRenderers.BUTTON
    });

  return actions;
};

export const getBusinessAreasSidebarFormFields = (busAreas: ITreeDataItem[], currentIds: number[]) => {
  if (currentIds) {
    busAreas.forEach(e => {
      if (currentIds.indexOf(e.id) >= 0) {
        e.isChecked = true;
      }
    });
  } else {
    busAreas.forEach(e => {
      if (e.parent === 0) {
        e.isChecked = true;
      }
    });
  }

  const businessAreas: Partial<ISearchTreePickerModel> = {
    ...INIT_SEARCHTREEPICKER,
    key: "businessAreas",
    value: busAreas.filter(e => e.isChecked),
    options: busAreas,
    isCascading: false,
    placeholder: I18n.t("placeholders.searchBusinessArea"),
    fieldClassName: FORM_COL.FULL_WIDTH
  };

  let fields = [];
  fields.push(businessAreas);

  return generateFormFieldsFromJson(fields);
};
