import { CommonColDefFieldNamesEnum } from "../../../../enums/AgGridColDefFieldNameEnum";
import { ISdColDef } from "../../baseColumn/BaseColumnTypes";
import { BASE_COLUMN_CONFIG } from "../../baseColumn/BaseColumn_config";

export const NOTE_COUNT_COLUMN_CONFIG = (columnOptions?: ISdColDef<any, any>): ISdColDef<any, any> => ({
  ...BASE_COLUMN_CONFIG,
  field: CommonColDefFieldNamesEnum.NoteCount,
  headerName: "Note Count",
  editable: false,
  ...columnOptions
});
