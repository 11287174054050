import { IconSymbols } from "@flightpath/coreui/dist/generated/IconSymbols";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { IF } from "../../../../../../../components/hoc/If";
import { ButtonIcon, ButtonTypes } from "../../../../../../../components/ui/Button";
import { PositionedSpinner } from "../../../../../../../components/ui/PositionedSpinner";
import { useCurrentOrganisationId } from "../../../../../../../services/local/organisationContext/OrganisationContextModel";
import "./_stakeholder-capacity-chart.scss";
import { Enums } from "../../../../../../../enums";
import { StakeholderCapacityChartModel } from "./StakeholderCapacityChart_model";
import { BarChart, BarChartProps } from "../../../../../../../core/charts/BarChart/BarChart";

export type StakeholderCapacityChartProps = {
  data: any;
  title?: string;
  transformer: (data: any[]) => BarChartProps;
  clearFilterFN?: () => any;
  filterModalFn?: any;
};

export const StakeholderCapacityChart: React.FC<StakeholderCapacityChartProps> = observer(
  ({ title, data, transformer, filterModalFn, clearFilterFN }) => {
    const organisationId = useCurrentOrganisationId();

    const [model] = useState(() => new StakeholderCapacityChartModel(organisationId, transformer));

    const [canBuildChart, setCanBuildChart] = useState(false);

    useEffect(() => {
      model.setData(data);
      model.onMount();
      setCanBuildChart(model.chartData.data.filter(e => e.actionCount > 0 || e.impactCount > 0).length > 0);
      // eslint-disable-next-line
    }, [data]);

    return (
      <div>
        {title && (
          <div className="col">
            <h2 className="mt-0">
              {title}
              <IF condition={filterModalFn}>
                <ButtonIcon
                  size={Enums.UiSizes.XS}
                  symbol={IconSymbols.ClearFilter}
                  type={ButtonTypes.OUTLINE_PRIMARY}
                  className="float-right"
                  onClick={() => {
                    clearFilterFN();
                  }}
                />
                <ButtonIcon
                  size={Enums.UiSizes.XS}
                  symbol={IconSymbols.Filter}
                  type={ButtonTypes.OUTLINE_PRIMARY}
                  className="float-right mr-2"
                  onClick={filterModalFn}
                />
              </IF>
            </h2>
          </div>
        )}
        <IF condition={model.isLoading}>
          <PositionedSpinner />
        </IF>

        <IF condition={!model.isLoading && !canBuildChart}>
          <div className="col">
            <p>There was no data to construct the chart</p>
          </div>
        </IF>
        <div className="stakeholder-capacity-chart__wrapper">
          <IF condition={!model.isLoading && canBuildChart}>
            <BarChart {...model.chartData} />
          </IF>
        </div>
      </div>
    );
  }
);
