import { observer } from "mobx-react";
import React, { useContext } from "react";
import { ModalView } from "../Modal_view";
import { IModalContextModel } from "./IModalContext";
import { ModalContextModel } from "./ModalContext_model";

let ModalContext = new ModalContextModel();

const ModalReactContext = React.createContext<IModalContextModel>(ModalContext);

export const ModalContextProvider: React.FC<{ modalContext?: IModalContextModel } & ReactProps> = observer(
  ({ modalContext, children }) => {
    const m = modalContext || ModalContext;
    return (
      <ModalReactContext.Provider value={m}>
        {m.isVisible && (
          <div className="modal__service-wrapper--inner">
            <ModalView model={m.model} />
          </div>
        )}
        {children}
      </ModalReactContext.Provider>
    );
  }
);

export function useModalContext() {
  return useContext(ModalReactContext);
}

export default ModalContext as IModalContextModel;
