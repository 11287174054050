import { observer } from "mobx-react";
import React, { useCallback } from "react";
import { Async } from "react-async";
import { useParams } from "react-router-dom";
import { ButtonTypes, LinkButton } from "../../../../../components/ui/Button";
import { Icon, IconSymbols } from "../../../../../components/ui/Icon";
import { PositionedSpinner } from "../../../../../components/ui/PositionedSpinner";
import { PaginationModel } from "../../../../../components/widgets/pagination/Pagination_model";
import { Pagination } from "../../../../../components/widgets/pagination/Pagination_view";
import { FilterModel, IFilterModel } from "../../../../../core/filter/Filter_model";
import I18n from "../../../../../core/localization/I18n";
import { TableView } from "../../../../../core/table/Table_view";
import { Animations } from "../../../../../core/util/Animations";
import { UiSizes } from "../../../../../enums";
import UserReportsApi from "../../../../../services/api/v2/userReports/UserReports.api";
import { useCurrentOrganisationId } from "../../../../../services/local/organisationContext/OrganisationContextModel";
import { PageTitle } from "../../../../../services/local/pageHeader/PageHeader";
import { SetUserActionFilter } from "../UserReports_model";
import { UserActionsModel } from "./UserActions_model";

export interface UserActionsProps {
  model: UserActionsModel;
}

const UserActions: React.FunctionComponent<UserActionsProps> = observer(({ model }) => {
  const currentPage = model.paginationModel.currentPage;
  const loadData = useCallback(async () => {
    await model.loadPageData(currentPage);
  }, [model, currentPage]);

  return (
    <Async promiseFn={loadData}>
      <Async.Resolved>
        <div className={`container-fluid pt-6 ${Animations.FP_ZOOM_IN} speed-4`}>
          <div className="row mb-5">
            <div className="col">
              <LinkButton type={ButtonTypes.LINK_BIG} size={UiSizes.MD} href="/" className="p-0">
                <Icon className="mr-2" symbol={IconSymbols.ChevronLeft} />
                {I18n.t("phrases.goBackDashboard")}
              </LinkButton>
            </div>
          </div>
          <div className="row mb-5">
            <div className="col">{model.title}</div>
          </div>
          <div className="row">
            <div className="col">
              <TableView model={model.tableModel} />
              <Pagination model={model.paginationModel} />
            </div>
          </div>
        </div>
      </Async.Resolved>
      <Async.Loading>
        <PositionedSpinner />
      </Async.Loading>
      <Async.Rejected>{err => err.message}</Async.Rejected>
    </Async>
  );
});

export const UserActionsPage: React.FC = () => {
  let userReportProvider = UserReportsApi;
  let paginationModel = new PaginationModel();
  const params = useParams();
  const organisationId = useCurrentOrganisationId();
  const config = {
    paginationModel,
    initOpts: {
      filterCb: async filterOptions => await userReportProvider.getUpcomingActions(organisationId, filterOptions)
    }
  };
  let filterModel: IFilterModel<FP.Entities.IAction> = new FilterModel(config);

  let userActionsModel = new UserActionsModel(filterModel, organisationId);
  userActionsModel.title = (
    <h1 className="d-inline-block mb-0 mt-1">
      {I18n.t("phrases.myActions")} | {I18n.t("phrases.upcomingActions")}
    </h1>
  );
  userActionsModel.paginationModel = paginationModel;
  SetUserActionFilter(organisationId, filterModel, userActionsModel.setActions, "upcomingActions");
  filterModel.setFilterValue("userRelation", params["type"]);
  paginationModel.setConfig({ onPageClick: userActionsModel.loadPageData });
  return (
    <>
      <PageTitle title={`${I18n.t("phrases.myDashboard")} | ${I18n.t("phrases.upcomingActions")}`} />
      <UserActions model={userActionsModel} />
    </>
  );
};
