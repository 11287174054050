import { PositionedSpinner } from "@flightpath/coreui/dist/ui/PositionedSpinner";
import { observer } from "mobx-react";
import React from "react";
import { SingleForm } from "../../../../../../../../pages/change/forms/singleFormModel/SingleForm_view";
import { ActionBulkEditSidebarModel } from "./ActionBulkEditSidebar_model";

interface ActionBulkEditSidebarProps {
  model: ActionBulkEditSidebarModel;
}

export const BulkEditActionsSidebar: React.FC<ActionBulkEditSidebarProps> = observer(({ model }) => {
  if (model.isLoading) return <PositionedSpinner />;

  return (
    <div className="container-fluid p-3">
      <div className="col">
        <SingleForm model={model.formModel} />
      </div>
    </div>
  );
});
