import * as React from "react";
import { Panel } from "../../../../components/ui/Panel";
import { IconSymbols } from "../../../../components/ui/Icon";
import { observer } from "mobx-react";
import { convertStakeholderToName } from "../../../../core/util/Helpers";
import { ButtonIcon, ButtonTypes } from "@flightpath/coreui/dist/ui/Button";
import { UiSizes } from "@flightpath/coreui/dist/enums";
import { QUERY_STRING_PARAMS } from "../../../../services/local/queryStringService/QueryStringService";
import { Link } from "react-router-dom";

export interface StakeholderCardProps {
  stakeholder: FP.Entities.IStakeholder;
  deleteFn?: (id: any) => void;
  projectId: number;
  onLinkClickFn?: () => void;
  pStakeholderId?: number;
}

const StakeholderCard: React.FunctionComponent<StakeholderCardProps> = observer(
  ({ projectId, pStakeholderId, stakeholder, deleteFn, onLinkClickFn }) => {
    const mainUrl = `/organisations/${stakeholder.organisationId}/projects/${projectId}`;

    return (
      <Panel.Panel
        className="stakeholder-card p-4 mb-2"
        background={Panel.PanelBackgrounds.BG_WHITE}
        type={Panel.PanelTypes.BORDER_LEFT_PRIMARY}
      >
        <div className="d-flex align-items-center justify-content-between">
          <Link
            key={stakeholder.id}
            to={`${mainUrl}/stakeholders/${stakeholder.id}?${QUERY_STRING_PARAMS.RETURN_URL}=${encodeURIComponent(
              `${mainUrl}/impacts`
            )}`}
            onClick={() => onLinkClickFn ? onLinkClickFn() : false}
          >
            <div className="strong flex-grow-1">{convertStakeholderToName(stakeholder)}</div>
          </Link>

          {deleteFn && (
            <ButtonIcon
              symbol={IconSymbols.Close}
              onClick={() => deleteFn(pStakeholderId)}
              size={UiSizes.SM}
              type={ButtonTypes.OUTLINE_PRIMARY}
            />
          )}
        </div>
      </Panel.Panel>
    );
  }
);

export { StakeholderCard };
