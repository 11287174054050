import { action, makeObservable, observable } from "mobx";
import { ConditionPartsJoinOperators, JoinOperators, UrlOperators, filterCellDataTypes } from "./FilterAddonContants";
import { stringToMomentDateForComparison } from "../../../../utils/helpers";
import { defaultDateTimeString } from "../../../../../../../../enums";
import { GridApi, IRowNode } from "ag-grid-community";

export class ExternalFilterStore {
  @observable externalFilter: string;
  @observable externalFilterObj: any;
  @observable urlSearchParams: URLSearchParams;
  @observable gridApi: GridApi;

  constructor() {
    makeObservable(this);
  }

  @action
  initialise = (gridApi: GridApi, urlSearchParams) => {
    this.gridApi = gridApi;
    this.urlSearchParams = urlSearchParams;
  };

  @action
  setExternalFilter = (externalFilter: string) => {
    this.externalFilter = externalFilter;
  };
  @action
  setExternalFilterObj = (externalFilterObj: any) => {
    this.externalFilterObj = externalFilterObj;
  };

  @action
  externalFilterChanged = (newValue: string) => {
    this.setExternalFilter(newValue);
    this.gridApi.onFilterChanged();
  };

  @action
  checkExternalFilterPresentFromUrlSearchParams = (): boolean => {
    this.setExternalFilter(this.urlSearchParams ? this.urlSearchParams.get("extFilter") : null);
    const isExternalFilterPresent = !!this.externalFilter && this.externalFilter.length > 0;
    if (isExternalFilterPresent) {
      const filterObj = this.parseExternalFilter(this.externalFilter);
      if (filterObj) {
        this.setExternalFilterObj(filterObj);
      }
    }
    return isExternalFilterPresent;
  };

  // TODO: Condition grouping (multiple ORs/ANDs etc)
  // example search query:
  // extFilter=startDate-date-<10/31/2024-AND-endDate-date->10/1/2024&startDate=.&endDate=.&progressStatus=In%20Progress|Not%20Started
  externalFilterPassChecker = (node: IRowNode<any>): boolean => {
    if (node.data && this.gridApi) {
      const { mainJoinOperator } = this.externalFilterObj;

      const res = this.externalFilterObj.filters.reduce((acc, filter) => {
        if (
          (mainJoinOperator === JoinOperators.and.symbol && !acc) ||
          (mainJoinOperator === JoinOperators.or.symbol && acc)
        ) {
          return acc;
        }

        let nodeValue = node.data[filter.column];
        if (filter.type === filterCellDataTypes.date) {
          nodeValue = stringToMomentDateForComparison(nodeValue);
        }

        let conditionResult;

        switch (filter.operator) {
          case UrlOperators.contains.gridOperator:
            conditionResult = nodeValue.includes(filter.values[0]);
            break;
          case UrlOperators.equals.gridOperator:
            conditionResult = nodeValue === filter.values[0];
            break;
          case UrlOperators.between.gridOperator:
            if (filter.type === filterCellDataTypes.date) {
              conditionResult = nodeValue >= filter.values[0] && nodeValue <= filter.values[1];
            } else {
              conditionResult = true;
            }
            break;
          case UrlOperators.lessThan.gridOperator:
            if (filter.type === filterCellDataTypes.date) {
              conditionResult = nodeValue <= filter.values[0] && !(nodeValue <= defaultDateTimeString);
            } else {
              conditionResult = nodeValue <= filter.values[0];
            }
            break;
          case UrlOperators.moreThan.gridOperator:
            conditionResult = nodeValue >= filter.values[0];
            break;
          default:
            conditionResult = true;
        }

        // Combine condition result based on the main join operator
        return mainJoinOperator === JoinOperators.and.symbol ? acc && conditionResult : acc || conditionResult;
      }, mainJoinOperator === JoinOperators.and.symbol);

      return res;
    }
    return true;
  };

  parseExternalFilter = filterString => {
    let mainJoinOperator;
    if (filterString.includes(`-${JoinOperators.or.symbol}-`)) {
      mainJoinOperator = JoinOperators.or.symbol;
    } else if (filterString.includes(`-${JoinOperators.and.symbol}-`)) {
      mainJoinOperator = JoinOperators.and.symbol;
    }

    const columnConditions = filterString.split(`-${mainJoinOperator}-`);

    // Parse each condition into structured filter objects
    const filters = columnConditions
      .map(condition => {
        const parts = condition.split("-");
        const dataTypeExists = Object.values(filterCellDataTypes).includes(parts[1]);
        const column = parts[0]; // e.g. "startDate"
        const type = dataTypeExists ? parts[1] : filterCellDataTypes.text; // e.g., "date"
        const valuesPart = dataTypeExists ? parts[2] : parts[1];

        const operatorSymbol = Object.keys(UrlOperators).find(key => valuesPart.includes(UrlOperators[key].symbol));
        const operatorEntry = operatorSymbol ? UrlOperators[operatorSymbol] : UrlOperators.equals;

        const operator = operatorEntry.gridOperator;

        // Extract values by removing the operator symbol
        const valuesString = valuesPart.replace(operatorEntry.symbol, "");

        const conditionPartSymbol =
          Object.values(ConditionPartsJoinOperators).find(op => valuesString.includes(op.symbol))?.symbol || false;

        // Split values based on the ConditionPartsJoinOperators symbol
        let values = !!conditionPartSymbol
          ? valuesString.split(conditionPartSymbol).map(val => {
              if (type === filterCellDataTypes.date) {
                return stringToMomentDateForComparison(val.trim());
              }
              return val.trim();
            })
          : [type === filterCellDataTypes.date ? stringToMomentDateForComparison(valuesString) : valuesString];

        return { column, type, operator, values, conditionJoin: conditionPartSymbol };
      })
      .filter(Boolean);

    return { filters, mainJoinOperator };
  };
}
