import { BaseModel } from "../../../../core/util/BaseModel";
import { SingleFormModel } from "../../forms/singleFormModel/SingleForm_model";
import { GetStakeholderBulkBusinessAreaFields } from "../../forms/stakeholder/BulkBusinessAreaUpdate_fields";
import { Enums } from "../../../../enums";
import LocalStorageService from "../../../../services/local/localStorageService/LocalStorageService";
import OrganisationsApi, {
  OrganisationsApi as IOrganisationsApi
} from "../../../../services/api/v2/organisations/Organisations.api";
import StakeholdersApi, {
  StakeholdersApi as IStakeholdersApi
} from "../../../../services/api/v2/stakeholders/Stakeholders.api";
import BusinessAreasApi, {
  BusinessAreasApi as IBusinessAreasApi
} from "../../../../services/api/v2/businessAreas/BusinessAreas.api";

export class BulkBusinessAreaUpdateModel extends BaseModel {
  businessArea: FP.Entities.IBusinessArea;
  stakeholders: FP.Entities.IStakeholder[];
  stakeholderProvider: IStakeholdersApi;
  businessAreaProvider: IBusinessAreasApi;
  organisationProvider: IOrganisationsApi;
  orgId: number;
  formModel: SingleFormModel;

  /**
   *
   */
  constructor(businessArea: FP.Entities.IBusinessArea, stakeholders: FP.Entities.IStakeholder[]) {
    super();
    this.businessArea = businessArea;
    this.stakeholders = stakeholders;

    this.stakeholderProvider = StakeholdersApi;
    this.businessAreaProvider = BusinessAreasApi;
    this.organisationProvider = OrganisationsApi;
    let localStorageService = LocalStorageService;
    this.orgId = parseInt(localStorageService.get(Enums.LocalCookies.ORGANISATION_ID));

    this.formModel = new SingleFormModel();
    this.formModel.formFields = GetStakeholderBulkBusinessAreaFields(
      this.stakeholders,
      this.businessAreaProvider,
      this.organisationProvider,
      this.orgId,
      businessArea
    );
  }

  updateStakeholders = async () => {
    let results: Dictionary<any> = await this.formModel.submit();
    if (!results) return;
    let keys = Object.keys(results);
    let updatedStakeholders = [];

    keys.forEach(e => {
      if (e.split("-")[0] !== "label") {
        const id = e.split("-")[1];
        let stakeholder = updatedStakeholders.find(e => e["stakeholderId"] === id);
        if (stakeholder) {
          stakeholder[e.split("-")[0]] = results[e] || 0;
        } else {
          let newStakeholder = { stakeholderId: +id };
          newStakeholder[e.split("-")[0]] = results[e] || 0;
          updatedStakeholders.push(newStakeholder);
        }
      }
    });

    this.stakeholderProvider.bulkUpload(this.orgId, updatedStakeholders);
  };
}
