import { action, makeObservable, observable, reaction } from "mobx";
import { ButtonTypes } from "../../../../components/ui/Button";
import {
  ReviewModalComponentProps,
  ReviewModalContent,
  ReviewModalTitle,
  ReviewToastContent
} from "../../../../components/ui/ReviewModal";
import ProgressIndicatorModel, {
  ProgressIndicatorModel as IProgressIndicatorModel
} from "../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import I18n from "../../../../core/localization/I18n";
import { IModalContextModel } from "../../../../core/modalZ/context/IModalContext";
import ModalContext from "../../../../core/modalZ/context/ModalContext";
import ToasterService, { IToasterService } from "../../../../core/toaster/ToasterService";
import { TOASTER_TOAST_TIME } from "../../../../core/toaster/Toaster_model";
import { DisposableModel } from "../../../../core/util/DisposableModel";
import ActionsApi, { ActionsApi as IActionsApi } from "../../../../services/api/v2/actions/Actions.api";
import CommentsApi, { CommentsApi as ICommentsApi } from "../../../../services/api/v2/comments/Comments.api";
import { IFilteredOptions, INIT_FILTER_OPTIONS } from "../../../../services/api/v2/filteredApi/FilteredApiModel";
import OrganisationsApi, {
  OrganisationsApi as IOrganisationsApi
} from "../../../../services/api/v2/organisations/Organisations.api";
import ProjectsApi, { ProjectsApi as IProjectsApi } from "../../../../services/api/v2/projects/Projects.api";
import { IAuthUserContextModel } from "../../../../services/local/authUserContext/AuthUserContextModel";
import { IQueryStringService } from "../../../../services/local/queryStringService/IQueryStringService";
import QueryStringService from "../../../../services/local/queryStringService/QueryStringService";
import { CommentListViewModel } from "../../comments/commentListView/CommentListView_model";
import { RevisionHistoryModel } from "../../revisionHistory/RevisionHistory_model";
import { ActionImpactsModel } from "../actionImpacts/ActionImpacts_model";

interface IActionExtendedViewModelOptions {
  projectId: number;
  organisationId: number;
  authUser: FP.Entities.IUser;
  actionId: number;
}

export class ActionExtendedViewModel extends DisposableModel {
  projectId: number;
  actionId: number;
  projectProvider: IProjectsApi;
  commentsProvider: ICommentsApi;
  confirmationService: IModalContextModel;
  actionProvider: IActionsApi;
  actionImpactsModel: ActionImpactsModel;
  organisationProvider: IOrganisationsApi;
  orgId: number;
  @observable hasBeenReviewed: boolean;
  @observable reviewCommentInput: string;
  authUserContext: IAuthUserContextModel;
  commentViewModel: CommentListViewModel;
  toasterService: IToasterService;
  revisionHistoryModel: RevisionHistoryModel;
  httpProgress: IProgressIndicatorModel;
  @observable filterOptions: IFilteredOptions = { ...INIT_FILTER_OPTIONS };
  @observable.ref action: FP.Entities.IAction;
  // returnUrl: string;
  queryStringService: IQueryStringService;
  /**
   *
   */
  constructor({ projectId, actionId, organisationId, authUser }: IActionExtendedViewModelOptions) {
    super();
    makeObservable(this);
    this.toasterService = ToasterService;
    this.projectProvider = ProjectsApi;
    this.commentsProvider = CommentsApi;
    this.actionProvider = ActionsApi;
    this.httpProgress = ProgressIndicatorModel;
    this.organisationProvider = OrganisationsApi;
    this.orgId = organisationId;
    this.projectId = projectId;
    this.actionId = actionId;
    this.actionImpactsModel = new ActionImpactsModel(projectId, this.orgId, false);
    this.commentViewModel = new CommentListViewModel(projectId, authUser, {
      placeholderText: I18n.t("placeholders.actionNotePlaceholder"),
      searchAttribute: "actionId",
      id: -1,
      title: null,
      projectId: this.projectId,
      organisationId: this.orgId
    });

    this.revisionHistoryModel = new RevisionHistoryModel({
      entityId: this.actionId,
      projectId: this.projectId,
      historyType: "actions",
      organisationId: this.orgId
    });

    this.queryStringService = QueryStringService;
    this.confirmationService = ModalContext;

    this.installReactions();
    this.hasBeenReviewed = false;
    this.reviewCommentInput = "";
  }

  onMount = () => {};

  onUnmount = () => {
    this.dispose();
  };

  @action
  loadAction = async () => {
    let res = await this.actionProvider.getDetailedByIdAsync(this.orgId, this.projectId, this.actionId);

    if (!res || res.isError) return;

    this.action = res.payload;

    this.commentViewModel.setConfig({
      id: this.action.id,
      description: <h4 className="mb-0">{I18n.t("phrases.addANote")}</h4>
    });
  };

  installReactions = () => {
    var d = reaction(
      () => {
        return this.action;
      },
      (e, reaction) => {
        this.actionImpactsModel.setAction(e);
      }
    );

    this.addDisposer(d);
  };

  reviewAction = async (actionId: number, comment) => {
    let data: FP.Entities.IComment = {
      content: comment,
      projectId: this.projectId,
      actionId: actionId,
      owner: null
    };
    let res = await this.commentsProvider.createAndReview(this.orgId, this.projectId, data);
    if (res) {
      this.toasterService
        .showReviewToast()
        .setContent(<ReviewToastContent itemName={this.action.name} />)
        .startTimer(TOASTER_TOAST_TIME.NORMAL);
    }

    if (!res || res.isError) return;

    this.hasBeenReviewed = true;
    this.reviewCommentInput = "";
    this.loadAction();
    this.confirmationService.hide();
  };

  @action
  handleInputChange = (value: string) => {
    this.reviewCommentInput = value;
  };

  showMarkReviewedModal = () => {
    return new Promise(resolve => {
      this.confirmationService.showConfirmDialog(
        <ReviewModalTitle />,
        <ReviewModalContent reviewCommentInput={this.reviewCommentInput} handler={this.handleInputChange} />,
        I18n.t("phrases.confirmReview"),
        I18n.t("phrases.cancelReview"),
        ReviewModalComponentProps,
        async () => {
          await this.reviewAction(this.actionId, this.reviewCommentInput);
          resolve(true);
        },
        () => {
          this.confirmationService.hide();
        },
        ButtonTypes.PRIMARY,
        ButtonTypes.OUTLINE_PRIMARY
      );
    });
  };
}
