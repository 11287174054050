import { FORM_COL } from "../../../../../../../../constants";
import { INIT_AUTOCOMPLETE } from "../../../../../../../forms/controls/autocomplete/Autocomplete_init";
import { IAutocompleteModel } from "../../../../../../../forms/controls/autocomplete/IAutocompleteModel";
import { generateFormFieldsFromJson } from "../../../../../../../forms/helpers/FormFieldMappers";
import I18n from "../../../../../../../localization/I18n";
import { Translator } from "../../../../../../../../enums";

export const getBulkUpdateProjectStakeholdersFormFields = () => {
  const isKeyStakeholder: Partial<IAutocompleteModel> = {
    ...INIT_AUTOCOMPLETE,
    key: "isKeyStakeholder",
    label: <label htmlFor={"isKeyStakeholder"}>{I18n.t("forms.isKeyStakeholder")}</label>,
    placeholder: I18n.t("placeholders.isKeyStakeholder"),
    options: [
      { key: "0", label: I18n.t("phrases.no") },
      { key: "1", label: I18n.t("phrases.yes") }
    ],
    isLoading: false,
    valueLabelFn: obj => obj.label,
    componentProps: {
      className: "form-control"
    },
    fieldClassName: FORM_COL.FULL_WIDTH,
    extractValue: function () {
      return parseInt(this.value?.key);
    }
  };

  const influence: Partial<IAutocompleteModel> = {
    ...INIT_AUTOCOMPLETE,
    key: "influence",
    label: <label htmlFor={"influence"}>{I18n.t("forms.influence")}</label>,
    placeholder: I18n.t("placeholders.influence"),
    options: Translator.InfluenceLevelMapped(),
    isLoading: false,
    valueLabelFn: obj => obj.label,
    componentProps: {
      className: "form-control"
    },
    fieldClassName: FORM_COL.FULL_WIDTH,
    extractValue: function () {
      return parseInt(this.value?.key);
    }
  };

  const impact: Partial<IAutocompleteModel> = {
    ...INIT_AUTOCOMPLETE,
    key: "impact",
    label: <label htmlFor={"impact"}>{I18n.t("forms.impact")}</label>,
    placeholder: I18n.t("placeholders.impact"),
    options: Translator.ImpactLevelMapped(),
    isLoading: false,
    valueLabelFn: obj => obj.label,
    componentProps: {
      className: "form-control"
    },
    fieldClassName: FORM_COL.FULL_WIDTH,
    extractValue: function () {
      return parseInt(this.value?.key);
    }
  };
  const sentiment: Partial<IAutocompleteModel> = {
    ...INIT_AUTOCOMPLETE,
    key: "sentiment",
    label: <label htmlFor={"sentiment"}>{I18n.t("forms.sentiment")}</label>,
    placeholder: I18n.t("placeholders.sentiment"),
    options: Translator.StakeholderProfilingMapped(),
    isLoading: false,
    valueLabelFn: obj => obj.label,
    componentProps: {
      className: "form-control"
    },
    fieldClassName: FORM_COL.FULL_WIDTH,
    extractValue: function () {
      return parseInt(this.value?.key);
    }
  };
  const commitment: Partial<IAutocompleteModel> = {
    ...INIT_AUTOCOMPLETE,
    key: "commitment",
    label: <label htmlFor={"commitment"}>{I18n.t("forms.commitment")}</label>,
    placeholder: I18n.t("placeholders.commitment"),
    options: Translator.StakeholderCommitmentOptions(),
    isLoading: false,
    valueLabelFn: obj => obj.label,
    componentProps: {
      className: "form-control"
    },
    fieldClassName: FORM_COL.FULL_WIDTH,
    extractValue: function () {
      return parseInt(this.value?.key);
    }
  };
  const receptiveness: Partial<IAutocompleteModel> = {
    ...INIT_AUTOCOMPLETE,
    key: "receptiveness",
    label: <label htmlFor={"receptiveness"}>{I18n.t("forms.receptiveness")}</label>,
    placeholder: I18n.t("placeholders.receptiveness"),
    options: Translator.StakeholderProfilingMapped(),
    isLoading: false,
    valueLabelFn: obj => obj.label,
    componentProps: {
      className: "form-control"
    },
    fieldClassName: FORM_COL.FULL_WIDTH,
    extractValue: function () {
      return parseInt(this.value?.key);
    }
  };

  const fields = [];
  fields.push(isKeyStakeholder);
  fields.push(influence);
  fields.push(impact);
  fields.push(sentiment);
  fields.push(commitment);
  fields.push(receptiveness);

  return generateFormFieldsFromJson(fields);
};
