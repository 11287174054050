import { makeObservable, observable } from "mobx";
import { IGridModel } from "../../../../../core/grids/IGridModel";
import { BaseModel } from "../../../../../core/util/BaseModel";
import { EntityTypes } from "../../../../../enums";
import ProjectProcessesApi, {
  ProjectProcessesApi as IProjectProcessesApi
} from "../../../../../services/api/v2/projectProcesses/ProjectProcesses.api";
import ProjectTeamUserPermissionsApi, {
  ProjectTeamUserPermissionsApi as IProjectTeamUserPermissionsApi
} from "../../../../../services/api/v2/projectTeamUserPermissions/ProjectTeamUserPermissions.api";
import { GetProcessGridViewColumns } from "./ProcessesGridView_columns";
import { getProcessGridModals } from "./ProcessesGridView_modals";

export class ProcessGridViewModel extends BaseModel implements IGridModel {
  projectProcessProvider: IProjectProcessesApi;
  // processHub: IProcessesHub;
  projectTeamUserPermissionsProvider: IProjectTeamUserPermissionsApi;
  authUser: FP.Entities.IUser;
  @observable isLoading: boolean = true;
  progressStatuses: FP.Generic.IKeyLabel[];
  type: EntityTypes = EntityTypes.PROCESSES;
  organisationId: number;
  projectId: number;
  columns: string[];

  constructor(authUser: FP.Entities.IUser, organisationId: number, projectId: number, columns: string[]) {
    super();
    makeObservable(this);
    this.projectProcessProvider = ProjectProcessesApi;
    this.projectTeamUserPermissionsProvider = ProjectTeamUserPermissionsApi;
    this.authUser = authUser;
    this.organisationId = organisationId;
    this.columns = columns;
    this.projectId = projectId;
  }

  onMount = async () => {};

  onUnmount = async () => {};

  getColumnConfig = () => {
    const s = GetProcessGridViewColumns({
      canEdit: true,
      modals: getProcessGridModals(this.organisationId, this.projectId),
      columns: this.columns
    });
    return s;
  };
}
