import React from "react";
export interface IAutocompleteOptions extends Omit<React.HTMLProps<HTMLElement>, "label"> {
  label: any;
  mouseEnter?: () => void;
  dataTestid?: string;
  dataTestValue?: string;
}

const AutocompleteOption: React.FunctionComponent<IAutocompleteOptions> = props => {
  return (
    <div
      data-testid={props.dataTestid}
      data-testvalue={props.dataTestValue}
      className={`autocomplete__option ${props.className || ""}`}
      onClick={props.onClick}
      onMouseEnter={props.mouseEnter}
    >
      {props.label}
    </div>
  );
};

export { AutocompleteOption };
