import { useEffect, useState } from "react";
import { ProcessesGrid } from "../../../../../core/grids/builder/directorBuilder/grids/ProcessesGrid/ProcessesGridView_view";
import { useCurrentOrganisationId } from "../../../../../services/local/organisationContext/OrganisationContextModel";
import { ProcessesSettingsModel } from "./ProcessesSettings_model";
import { observer } from "mobx-react-lite";
import { PositionedSpinner } from "../../../../../components/ui/PositionedSpinner";

export const ProcessesSettings: React.FC = observer(() => {
  let organisationId = useCurrentOrganisationId();
  const [model] = useState(() => new ProcessesSettingsModel(organisationId));

  useEffect(() => {
    model.onMount();
  }, [model]);

  if (model.isLoading) {
    return <PositionedSpinner />;
  }

  return (
    <div className="processes-settings insight-tab-content__space-filler" style={{ height: "100%" }}>
      <ProcessesGrid
        data={model.processData}
        gridToolbarType="base"
        onFieldUpdate={model.loadProcessData}
        refreshDataHandler={model.loadProcessData}
      />
    </div>
  );
});
