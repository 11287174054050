import { HubEvents } from "./HubEventsEnum";
import { ISignalRBaseModel, SignalRBaseModel } from "./SignalRBaseModel";

export interface IGridBaseModel extends ISignalRBaseModel {
  onLoadData: (callback) => void;
  invokeLoadData: (organisationId: number, projectId?: number) => Promise<void>;
  onUserCellSelected: (callback) => void;
  invokeUserJoined: (organisationId: number, projectId: number, userOktaId: string) => Promise<void>;
  invokeUserLeft: (organisationId: number, projectId: number, userOktaId: string) => Promise<void>;
  invokeUserClickedCell: (
    organisationId: number,
    projectId: number,
    cell: string,
    isEditMode: boolean
  ) => Promise<void>;
}
export abstract class GridBaseModel extends SignalRBaseModel implements IGridBaseModel {
  hubName: string;

  invokeUserJoined = async (organisationId: number, projectId: number, userOktaId: string) => {
    if (!this.isConnectionStarted) return;
    const connectionGroupName = this.getConnectionGroupName(organisationId, projectId, this.hubName);
    await this.connection.invoke(HubEvents.INVOKE_USER_JOIN, { userOktaId, groupName: connectionGroupName });
  };

  invokeUserLeft = async (organisationId: number, projectId: number, userOktaId: string) => {
    if (!this.isConnectionStarted) return;
    const connectionGroupName = this.getConnectionGroupName(organisationId, projectId, this.hubName);
    await this.connection.invoke(HubEvents.INVOKE_USER_LEFT, { userOktaId, groupName: connectionGroupName });
  };

  onUserCellSelected = callback => this.registerIncomingEvent(HubEvents.ON_USER_CELL_SELECTED, callback);

  invokeUserClickedCell = async (organisationId: number, projectId: number, cell: string, isEditMode: boolean) => {
    if (!this.isConnectionStarted) return;
    const connectionGroupName = this.getConnectionGroupName(organisationId, projectId, this.hubName);
    await this.connection.invoke(HubEvents.INVOKE_USER_CELL_SELECTED, {
      groupName: connectionGroupName,
      cell,
      isEditMode
    });
  };

  abstract onLoadData: (callback) => void;

  abstract invokeLoadData: (organisationId: number, projectId?: number) => Promise<void>;
}
