import { useNavigate } from "react-router-dom";
import { toRelativeUrl } from "../../core/auth_insight";
import { InsightProviders } from "./InsightProviders";
import OktaService from "../../services/external/okta/OktaService";
import { useState } from "react";
import { ChangeApp } from "../change/change";
import bootstrap from "../../bootstrap";
import { useAsync } from "react-use";
import { Loader } from "../../components/ui/PositionedSpinner";

export const InsightBoot: React.FC = () => {
  const navigate = useNavigate();
  const restoreOriginalUri = (_oktaAuth: any, originalUri: string) => {
    navigate(toRelativeUrl(originalUri.replace("/insight", "") || "/", window.location.origin));
  };

  const [appModule] = useState(() => new ChangeApp());
  const state = useAsync(async () => {
    let application = await bootstrap();
    await appModule.install();
    return application;
  }, [appModule]);

  return (
    <div className="insight-app">
      {state.loading && <Loader />}
      {state.error && <p>Error</p>}
      {!state.loading && !state.error && (
        <InsightProviders authService={OktaService} restoreOriginalUri={restoreOriginalUri}></InsightProviders>
      )}
    </div>
  );
};
