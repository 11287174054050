import React from "react";
import { ReportingPie } from "./ReportingPie";
import I18n from "../../../../../../core/localization/I18n";
import { UiPortal } from "../../../../../../components/ui/UiPortal";
import { observer } from "mobx-react";
import { Animations } from "../../../../../../core/util/Animations";

interface TopActionsReportingBarProps {
  ragData: any[];
  actionCount: number;
  totalConfidenceMetric: string;
  totalConfidenceData: any[];
}

export const PIE_UI_PORTAL = "pie_ui_portal";

export const TopActionsReportingBar: React.FC<TopActionsReportingBarProps> = observer(
  ({ ragData, totalConfidenceMetric, actionCount, totalConfidenceData }) => {
    const ragColourArray = ["#F91E1E", "#F9AB1E", "#1AC541", "#58C8D1", "#BEBEBE"];
    const totalConfidenceColourArray = [getConfidenceMetricColour(+totalConfidenceMetric), "#BEBEBE"];

    return (
      <div className={`row pt-4 justify-content-between ${Animations.FP_ZOOM_IN}`}>
        <div className="col-4">
          <ReportingPie
            data={ragData}
            metric={actionCount?.toString()}
            colourArray={ragColourArray}
            title={I18n.t("visualisations.actionsRag")}
          />
        </div>
        <div className="col-4">
          <ReportingPie
            data={totalConfidenceData}
            metric={getConfidenceMetric(+totalConfidenceMetric)}
            colourArray={totalConfidenceColourArray}
            title={I18n.t("visualisations.totalActionConfidence")}
          />
        </div>
        <div className="col-4">
          <UiPortal name={PIE_UI_PORTAL}></UiPortal>
        </div>
      </div>
    );
  }
);

export const getConfidenceMetricColour = (metric: number): string => {
  if (metric > 66) return "#1AC541";
  if (metric > 33) return "#F9AB1E";
  if (metric < 34) return "#f91e1e";
};

export const getConfidenceMetric = (metric: number) => (
  <>
    <span>{metric}</span>
    <span className="display-s">%</span>
  </>
);
