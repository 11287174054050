import { Animations } from "../../../../util/Animations";
import { gridSideModalAnimationOpts, gridSideModalComponentProps } from "../../../GridSideModals";

export const BASE_GRID_SIDE_MODAL = {
  showClose: true,
  componentProps: gridSideModalComponentProps,
  animationOptions: gridSideModalAnimationOpts
};

export const BASE_GRID_POPUP_MODAL = {
  showClose: false,
  title: null,
  componentProps: {
    ...gridSideModalComponentProps,
    wrapHeight: "default",
    wrapWidth: "large",
    position: "middle",
    className: "modal__grid-link"
  },
  animationOptions: {
    animateIn: Animations.SLIDE_IN_UP,
    animateOut: Animations.SLIDE_OUT_DOWN
  }
};
