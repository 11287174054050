import { action, makeObservable, observable } from "mobx";
import { BaseModel } from "../../../../core/util/BaseModel";
import ProgrammesApi, { ProgrammesApi as IProgrammesApi } from "../../../../services/api/v2/programmes/Programmes.api";

export class ProgrammeLocationsModel extends BaseModel {
  programmeProvider: IProgrammesApi;
  organisationId: number;
  @observable isLoading: boolean = true;
  @observable programmeDashboardData: FP.Entities.IProgrammeDashboard;

  constructor(organisationId: number) {
    super();
    makeObservable(this);
    this.programmeProvider = ProgrammesApi;
    this.organisationId = organisationId;
  }

  onMount = (programmeId: number) => {
    this.setIsLoading(true);
    Promise.all([this.loadProgrammeDashboard(programmeId)]).then(() => {
      this.setIsLoading(false);
    });
  };

  onUnmount = () => {};

  loadProgrammeDashboard = async (id: number) => {
    let res = await this.programmeProvider.getProgrammeDashboard(this.organisationId, id);
    if (!res || res.isError) return;
    this.setProgrammeDashboardData(res.payload);
  };

  @action
  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };

  @action
  setProgrammeDashboardData = (programmeDashboardData: FP.Entities.IProgrammeDashboard) => {
    this.programmeDashboardData = programmeDashboardData;
  };
}
