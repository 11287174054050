import { FORM_COL } from "../../../../../../constants";
import { IRTEditorModel } from "../../../../../forms/controls/rteditor/IRTEditorModel";
import { INIT_RTEDITOR } from "../../../../../forms/controls/rteditor/RTEditor_model";
import { generateFormFieldsFromJson } from "../../../../../forms/helpers/FormFieldMappers";

export const getEditRichTextFormFields = (item: any, field: string, label: string, placeholder: string) => {
  const summary: Partial<IRTEditorModel> = {
    ...INIT_RTEDITOR,
    key: field,
    label: <label htmlFor="description">{label}</label>,
    placeholder: placeholder,
    fieldClassName: FORM_COL.FULL_WIDTH,
    value: item[field]
  };

  const fields = [];
  fields.push(summary);

  const models = generateFormFieldsFromJson(fields);
  return models;
};
