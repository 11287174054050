import { observer } from "mobx-react";
import React from "react";
import { Icon, IconSymbols } from "../../../../../../components/ui/Icon";
import { Pill } from "../../../../../../components/ui/Pill";

interface HeatmapFilterPillsProps {
  tags: FP.Generic.IIdText[];
  removeFilter: (id) => void;
}

export const HeatmapFilterPills: React.FC<HeatmapFilterPillsProps> = observer(({ tags, removeFilter }) => {
  return (
    <div className="heatmap-filter-pills row mb-3">
      <div className="col text-right">
        {tags.map(e => {
          return (
            <Pill className="m-1" key={e.id}>
              <span
                onClick={ee => {
                  removeFilter(e.id);
                }}
              >
                <Icon symbol={IconSymbols.Close} className="mr-2" />
              </span>
              <p className="mb-0 d-inline-block">{e.text}</p>
            </Pill>
          );
        })}
      </div>
    </div>
  );
});
