import { ButtonTypes } from "@flightpath/coreui/dist/ui/Button";
import { UiActionRenderers } from "@flightpath/coreui/dist/uiAction/IUiAction";
import { action, makeObservable, observable } from "mobx";
import ProgressIndicatorModel, {
  ProgressIndicatorModel as IProgressIndicatorModel
} from "../../../../../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import I18n from "../../../../../../../localization/I18n";
import ModalContext from "../../../../../../../modalZ/context/ModalContext";
import ToasterService, { ToasterService as IToasterService } from "../../../../../../../toaster/ToasterService";
import { TOASTER_TOAST_TIME } from "../../../../../../../toaster/Toaster_model";
import { BaseModel } from "../../../../../../../util/BaseModel";
import { getBulkUpdateProjectStakeholdersFormFields } from "./ProjectStakeholderBulkUpdateSidebar_formFields";
import { SingleFormModel } from "../../../../../../../../pages/change/forms/singleFormModel/SingleForm_model";
import ProjectStakeholdersApi, {
  ProjectStakeholdersApi as IProjectStakeholdersApi
} from "../../../../../../../../services/api/v2/projectStakeholders/ProjectStakeholders.api";

export class ProjectStakeholderBulkUpdateSidebarModel extends BaseModel {
  projectStakeholdersProvider: IProjectStakeholdersApi;
  @observable isLoading: boolean = true;
  httpProgress: IProgressIndicatorModel;
  toasterService: IToasterService;
  projectId: number;
  organisationId: number;
  selectedItems: number[];
  closeModalFn: () => void;
  formModel: SingleFormModel;

  constructor(closeModalFn: () => void, projectId: number, organisationId: number, selectedItems: number[]) {
    super();
    makeObservable(this);

    this.organisationId = organisationId;
    this.projectId = projectId;
    this.selectedItems = selectedItems;
    this.projectStakeholdersProvider = ProjectStakeholdersApi;
    this.httpProgress = ProgressIndicatorModel;
    this.isLoading = true;
    this.toasterService = ToasterService;
    this.closeModalFn = closeModalFn;
    this.initForm();
  }

  initForm = async () => {
    this.formModel = new SingleFormModel();
    this.formModel.setActions([
      {
        id: "cancel",
        label: I18n.t("phrases.cancel"),
        onAction: ev => {
          ModalContext.hide();
        },
        componentProps: {
          className: "ml-auto"
        },
        rendersIn: UiActionRenderers.BUTTON
      },
      {
        id: "Save",
        label: I18n.t("phrases.save"),
        onAction: async () => {
          let formResult = await this.formModel.submit();

          let filteredFormRes = {};
          Object.entries(formResult).forEach(([key, val]) => {
            if ((!!val || val === 0) && val !== "") {
              filteredFormRes[key] = val;
            } else {
              filteredFormRes[key] = null;
            }
          });

          if (formResult) {
            this.httpProgress.showOverlay();
            let res = await this.projectStakeholdersProvider.bulkUpdateStakeholders(
              this.organisationId,
              this.projectId,
              this.selectedItems,
              filteredFormRes as any
            );
            this.httpProgress.hideOverlay();

            if (!res || res.isError) {
              this.toasterService
                .showErrorToast(TOASTER_TOAST_TIME.SLOW)
                .setContent(<p>{I18n.t("errors.updateStakeholders")}</p>);
              return;
            }

            ModalContext.hide();
            this.toasterService
              .showSuccessToast(TOASTER_TOAST_TIME.NORMAL)
              .setContent(<p>{`${I18n.t("phrases.stakeholdersUpdatedSuccessfully")}`}</p>);
          }
        },
        rendersIn: UiActionRenderers.BUTTON,
        componentProps: {
          type: ButtonTypes.PRIMARY,
          className: "ml-2"
        }
      }
    ]);

    this.setFormModel();
    this.setIsLoading(false);
  };

  @action
  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };

  @action
  setFormModel = () => {
    this.formModel.formFields = getBulkUpdateProjectStakeholdersFormFields();
  };
}
