import { gridSideModalAnimationOpts, gridSideModalComponentProps } from "../../../grids/GridSideModals";
import ModalContext from "../../context/ModalContext";
import { ImpactTypesSidebar } from "./ImpactTypesSidebar/ImpactTypesSidebar_view";

export const SHOW_IMPACT_TYPES_MODAL = (updateLocations: (businessAreas: number[]) => void, currentIds: number[]) => {
  ModalContext.show({
    showClose: true,
    title: (
      <div className="mt-6">
        <h3 className="mb-5">Select Impact Types</h3>
      </div>
    ),
    content: <ImpactTypesSidebar onSubmit={updateLocations} currentIds={currentIds} />,
    componentProps: gridSideModalComponentProps,
    animationOptions: gridSideModalAnimationOpts
  });
};
