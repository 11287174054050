import { ColDef } from "ag-grid-community";
import { IBaseColumnProps, baseTextColumn } from "../../../../../../core/grids/BaseColumns";
import I18n from "../../../../../../core/localization/I18n";
import { GridPills } from "../../../../../../components/widgets/gridPills/GridPills";
import { openModalOnDelete } from "../../../../../../core/grids/GridHelpers";

export const GetRo = () => {};

export const colBusinessArea = (): ColDef => {
  return {
    ...baseTextColumn({
      fieldName: "businessArea",
      heading: I18n.t("phrases.projectJobRoles_businessArea"),
      editable: false,
      clickEditable: false,
      width: 400
    })
  };
};

export const colProcessRoles = (canEdit: boolean, editFn?: any): ColDef => {
  let result: ColDef = {
    ...baseTextColumn({
      fieldName: "processRoles",
      heading: I18n.t("grids.processRoles"),
      width: 170,
      editable: false,
      autoHeight: true,
      clickEditable: true
    }),
    cellRenderer: e => {
      return <GridPills text={e.data.processRoles} />;
    }
  };

  const isEditable = canEdit && editFn !== undefined;
  if (isEditable && editFn) {
    result.onCellDoubleClicked = e => {
      editFn(e.data);
    };
    result.suppressKeyboardEvent = openModalOnDelete(result, editFn);
  }

  return result;
};

export const colName = (props?: IBaseColumnProps): ColDef => {
  let res: ColDef = {
    ...baseTextColumn({
      fieldName: "name",
      heading: I18n.t("grids.name"),
      width: 150,
      editable: false,
      ...props
    })
  };

  return res;
};
