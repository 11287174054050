import { BASE_ROUTE, RouteParams } from "../constants";
import { DASHBOARD_ROUTES } from "./DashboardRoutes";
import { ORGANISATION_ROUTES } from "./OrganisationRoutes";
import { PROGRAMME_ROUTES } from "./ProgrammeRoutes";
import { PROJECT_ROUTES } from "./ProjectRoutes";
import { USER_ROUTES } from "./UserRoutes";

const Pages = {
  baseRoute: {
    path: BASE_ROUTE,
    generateLink: (organisationId: string | number) =>
      BASE_ROUTE.replace(RouteParams.organisationId, organisationId + "")
  },
  projects: PROJECT_ROUTES,
  programmes: PROGRAMME_ROUTES,
  organisations: ORGANISATION_ROUTES,
  dashboards: DASHBOARD_ROUTES,
  users: USER_ROUTES
};

export default Pages;
