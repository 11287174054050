import I18n from "../../../../core/localization/I18n";
import { Enums } from "../../../../enums";

export const MapActionsToRagPie = (actions: any[]) => {
  return [
    {
      label: I18n.t("visualisations.Red"),
      value: actions.filter(
        e => e.ragStatus === Enums.RagStatus.RED && e.progressStatus !== Enums.ProgressStatus.COMPLETED
      ).length,
      id: "red",
      key: Enums.RagStatus.RED
    },
    {
      label: I18n.t("visualisations.Orange"),
      value: actions.filter(
        e => e.ragStatus === Enums.RagStatus.AMBER && e.progressStatus !== Enums.ProgressStatus.COMPLETED
      ).length,
      id: "orange",
      key: Enums.RagStatus.AMBER
    },
    {
      label: I18n.t("visualisations.Green"),
      value: actions.filter(
        e => e.ragStatus === Enums.RagStatus.GREEN && e.progressStatus !== Enums.ProgressStatus.COMPLETED
      ).length,
      id: "green",
      key: Enums.RagStatus.GREEN
    },
    {
      label: I18n.t("visualisations.Completed"),
      value: actions.filter(e => e.progressStatus === Enums.ProgressStatus.COMPLETED).length,
      id: "completed",
      key: Enums.ProgressStatus.COMPLETED
    },
    {
      label: I18n.t("visualisations.Unknown"),
      value: actions.filter(
        e => e.ragStatus === Enums.RagStatus.UNKNOWN && e.progressStatus !== Enums.ProgressStatus.COMPLETED
      ).length,
      id: "unknown",
      key: Enums.RagStatus.UNKNOWN
    }
  ];
};

export const MapActionsToProgressPie = (actions: any[]) => {
  return [
    {
      label: I18n.t("phrases.notStarted"),
      value: actions.filter(e => e.progressStatus === Enums.ProgressStatus.NOT_STARTED).length,
      id: "notStarted",
      key: Enums.ProgressStatus.NOT_STARTED
    },
    {
      label: I18n.t("phrases.inProgress"),
      value: actions.filter(e => e.progressStatus === Enums.ProgressStatus.IN_PROGRESS).length,
      id: "inProgress",
      key: Enums.ProgressStatus.IN_PROGRESS
    },
    {
      label: I18n.t("phrases.completed"),
      value: actions.filter(e => e.progressStatus === Enums.ProgressStatus.COMPLETED).length,
      id: "completed",
      key: Enums.ProgressStatus.COMPLETED
    }
  ];
};
