import { HubEvents } from "../common/HubEventsEnum";
import { SignalRBaseModel } from "../common/SignalRBaseModel";

export class ActionVisualisationsHub extends SignalRBaseModel {
  hubName: string = "action-visualisations";

  constructor() {
    super("action-visualisations");
  }

  onLoadGridData = callback => this.registerIncomingEvent(HubEvents.ON_LOAD_ACTION_DATA, callback);

  invokeLoadGridData = async (organisationId: number, projectId: number) => {
    if (!this.isConnectionStarted) return;
    await this.connection.invoke(HubEvents.INVOKE_LOAD_ACTION_DATA, {
      organisationId: organisationId,
      projectId: projectId
    });
  };
}

const instance = new ActionVisualisationsHub();
export default instance;
