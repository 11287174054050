import { action, makeAutoObservable, observable } from "mobx";
import { IGridBaseModel } from "../../../services/hubs/common/GridBaseModel";

export interface IGridHubStoreModel<T> {
  data: T[];
  isLoading: boolean;
  connectedUsers: FP.Entities.IUser[];
  columnDefs: FP.Entities.IColumnDef[];
  isCustomFieldEnabled: boolean;
  stopConnection: () => Promise<any>;
  updateUserSelectedCell: (
    organisationId: number,
    projectId: number
  ) => (cell: string, isEditMode?: boolean) => Promise<void>;
  invokeDataLoad: (
    organisationId: number,
    projectId: number,
    authUser: FP.Entities.IUser,
    shouldIgnoreUserConnection?: boolean
  ) => void;
  invokeUserLeft: (organisationId: number, projectId: number, authUser: FP.Entities.IUser) => void;
  canInvoke: () => boolean;
  registerSocketEvents: () => Promise<void>;
}

export class GridHubStoreModel<T, S extends IGridBaseModel> implements IGridHubStoreModel<T> {
  @observable.ref data: T[];
  @observable isLoading: boolean = true;
  @observable.ref columnDefs: FP.Entities.IColumnDef[] = [];
  hub: S;
  @observable.ref connectedUsers: FP.Entities.IUser[] = [];
  rootStore: any;
  dataSetterCallback: (data: any) => T[];
  isCustomFieldEnabled: boolean;

  constructor(rootStore, hub: S, registerOnInit: boolean = false, dataSetterCallback?: (data) => T[]) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
    this.dataSetterCallback = dataSetterCallback;
    this.hub = hub;
    if (registerOnInit) {
      this.registerSocketEvents();
    }
  }

  registerSocketEvents = async () => {
    if (this.hub.isConnectionStarted === true) {
      await this.hub.stopConnection();
    }
    await this.hub.startConnection();
    this.hub.onUserJoined(d => {
      this.setConnectedUsers(d);
    });

    this.hub.onLoadData(d => {
      this.isCustomFieldEnabled = d.isCustomFieldsEnabled;
      if (d.isCustomFieldsEnabled) {
        this.setColumnDefs(d.columnDefs);
        this.setData(d.data);
      } else {
        this.setData(d);
      }
      this.setIsLoading(false);
    });

    this.hub.onUserCellSelected(d => {
      this.setConnectedUsers(d);
    });
  };

  invokeDataLoad = async (
    organisationId: number,
    projectId: number,
    authUser: FP.Entities.IUser,
    shouldIgnoreUserConnection: boolean
  ) => {
    if (!this.hub.isConnectionStarted) {
      await this.registerSocketEvents();
    }
    if (!shouldIgnoreUserConnection) {
      await this.hub.invokeUserJoined(organisationId, projectId, authUser.sub);
    }
    await this.hub.invokeLoadData(organisationId, projectId);
  };

  invokeUserLeft = async (organisationId: number, projectId: number, authUser: FP.Entities.IUser) => {
    if (!this.hub.isConnectionStarted) {
      await this.hub.startConnection();
    }
    await this.hub.invokeUserLeft(organisationId, projectId, authUser.sub);
  };

  updateUserSelectedCell =
    (organisationId: number, projectId: number) => async (cell: string, isEditMode?: boolean) => {
      await this.hub.invokeUserClickedCell(organisationId, projectId, cell, isEditMode);
    };

  @action
  setIsLoading = (isLoading: boolean) => (this.isLoading = isLoading);

  @action
  setData = (data: T[]) => {
    if (this.dataSetterCallback) {
      data = this.dataSetterCallback(data);
    }
    this.data = data;
  };

  @action
  setConnectedUsers = users => {
    this.connectedUsers = [...users];
  };

  canInvoke = () => {
    return this.hub.canInvoke();
  };

  @action
  setColumnDefs = (columnDefs: FP.Entities.IColumnDef[]) => (this.columnDefs = columnDefs);

  stopConnection = async () => {
    this.setConnectedUsers([]);
    this.setData([]);
    this.setIsLoading(true);
    await this.hub.stopConnection();
  };
}
