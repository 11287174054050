import React, { ReactNode } from "react";

import { PanelBackgrounds } from "@flightpath/coreui/dist/ui/Panel";
import { Panel } from "../../../../../components/ui/Panel";
import { PieChart } from "../../../../../components/widgets/pie/pie";

interface ReportingCardProps {
  className?: string;
  style?: React.CSSProperties;
}

export const ReportingCard: React.FC<ReportingCardProps & ReactProps> = props => {
  const { className, style } = props;

  return (
    <Panel.Panel className={className || ""} background={Panel.PanelBackgrounds.BG_LIGHT} style={style}>
      {props.children}
    </Panel.Panel>
  );
};

interface ReportingTitleProps {
  title: string;
  className?: string;
}

export const ReportingTitle: React.FC<ReportingTitleProps> = ({ title, className }) => {
  return (
    <div className={`${className || ""} reporting-title`}>
      <h3 className="mb-0">{title}</h3>
    </div>
  );
};

interface ReportingPieProps {
  data: any[];
  colourArray: string[];
  onClick?: (dataObject: any) => void;
  title?: string;
  contentInside?: ReactNode;
  cls?: string;
}

export const PieTooltip = props => {
  return (
    <Panel.Panel background={PanelBackgrounds.BG_WHITE} hasBorderRadius={true} className="p-2">
      <span className="mb-0 body">
        {props.label}: {props.value}
      </span>
    </Panel.Panel>
  );
};

export const ReportingPie: React.FC<ReportingPieProps> = ({
  title,
  data,
  colourArray,
  onClick,
  contentInside,
  cls
}) => {
  if (!data) return null;
  const PieLegend = ({ colours, data }) => {
    return (
      <div style={{ maxWidth: "50%" }}>
        {data.map((e, i) => (
          <div className="d-flex align-items-center mb-2" key={e.id}>
            <div className="legend-circle mr-1" style={{ backgroundColor: colours[i] }}></div>
            <span className="body" style={{ flex: 1 }}>
              {e.label}
            </span>
          </div>
        ))}
      </div>
    );
  };

  return (
    <ReportingCard className={`${cls || "px-3"} d-flex h-100`}>
      {title && (
        <div className="d-flex action_reporting__Card-info mb-1">
          <ReportingTitle title={title} className="pt-3" />
        </div>
      )}
      <div className="action-reporting__metric-pie pb-2">
        <div className="px-2" style={{ width: "125px", height: "125px" }}>
          <PieChart
            data={data}
            colors={colourArray}
            hasLegend={false}
            translateLegendX={100}
            translateLegendY={0}
            tooltipFn={e => <PieTooltip {...e} />}
            margin={{ top: 5, right: 5, bottom: 5, left: 5 }}
            onClickFn={onClick}
          />
        </div>
        <PieLegend data={data} colours={colourArray} />
      </div>
    </ReportingCard>
  );
};
