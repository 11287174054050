import React, { forwardRef, useCallback, useImperativeHandle, useRef, useState } from "react";
import { B } from "../../../core/util/BootstrapHelper";
import { Enums } from "../../../enums";
import { Icon, IconSymbols } from "../../ui/Icon";
import { Avatar } from "../Avatar";

export interface BreadcrumbProps extends React.HTMLProps<HTMLDivElement> {
  className?: string;
  /*name - the name of the organisation/programme/project*/
  name?: string;
  /*title - organisation, programme or project */
  title: string;
  /*onClick - callback function */
  onClick?: () => void;
  /*onHover - callback function */
  onHover?: () => void;
  /*does the breadcrumb have an image */
  imageSource?: string;
  /**isDropdown - is the dropdown menu available to the user */
  isDropdown?: boolean;
  /*iseparator - has a left hand border separator */
  hasLeftBorder?: boolean;

  shouldHideOnClick?: boolean;

  iconSymbol: IconSymbols;
}

export const Breadcrumb: React.FC<BreadcrumbProps> = forwardRef(
  ({ className, onClick, onHover, imageSource, name, title, isDropdown, iconSymbol, hasLeftBorder, ...props }, ref) => {
    const cls: string = B()
      .bl("breadcrumb")
      .pmod(hasLeftBorder && "border-left").bem;

    let dropdownRef = useRef(null);
    let breadcrumbRef = useRef(null);

    let [isDropdownVisible, setIsDropdownVisible] = useState(false);
    let upperCaseTitle = title?.toUpperCase();
    let upperCaseName = name?.toUpperCase();

    useImperativeHandle<any, any>(ref, () => {
      return {
        breadcrumbRef,
        isDropdownVisible: isDropdownVisible,
        setIsDropdownVisible: s => {
          setIsDropdownVisible(s);
        }
      };
    });

    const clickHandler = useCallback(
      e => {
        setIsDropdownVisible(!isDropdownVisible);
        onClick();
      },
      [isDropdownVisible, onClick]
    );

    let k = { ...props };
    delete k.shouldHideOnClick;

    return (
      <div {...k} className={`${cls} ${className ?? ""}`}>
        <div className="breadcrumb__content" onClick={clickHandler} ref={breadcrumbRef}>
          {imageSource && <Avatar imgSrc={imageSource} size={Enums.UiSizes.MD} className="mr-2" />}
          <div className="breadcrumb__textContainer">
            <div className="breadcrumb__textContainer__title">
              <small>{upperCaseTitle}</small>
            </div>
            <div className="breadcrumb__textContainer__name d-flex flex-row">
              <p>{upperCaseName}</p>
              {isDropdown && (
                <div className="breadcrumb__chevron ml-5">
                  <Icon symbol={iconSymbol || IconSymbols.ChevronDown} />
                </div>
              )}
            </div>
          </div>
        </div>

        {!!props.children && isDropdownVisible && (
          <div className="breadcrumb__dropdown" ref={dropdownRef}>
            {props.children}
          </div>
        )}
      </div>
    );
  }
);
