import { PanelBackgrounds, PanelTypes } from "@flightpath/coreui/dist/ui/Panel";
import { observer } from "mobx-react";
import { Panel } from "../../../../../../components/ui/Panel";
import React from "react";
import I18n from "../../../../../../core/localization/I18n";
import { Icon, IconSymbols } from "../../../../../../components/ui/Icon";
import { UiSizes } from "@flightpath/coreui/dist/enums";

export interface IUploadFileCardProps {
  onClickFn?: (id: number) => void;
  selectedFileId: number;
}

export const NewFileCard: React.FC<IUploadFileCardProps> = observer((props: IUploadFileCardProps) => {
  const selectedClass = (): string => {
    return props.selectedFileId === 0 ? "selected" : "";
  };

  return (
    <Panel.Panel
      hasShadow={true}
      background={PanelBackgrounds.BG_PRIMARY_LIGHT}
      hasBorderRadius={true}
      type={PanelTypes.OUTLINES}
      className={`p-3 mb-2 organisation-stakeholders--upload--file-list--file-card ${selectedClass()}`}
      onClick={() => props.onClickFn(0)}
    >
      <div className="organisation-stakeholders--upload--file-list--file-card--content">
        <Icon symbol={IconSymbols.Upload} size={UiSizes.MD} className="mr-2" />
        <div className="organisation-stakeholders--upload--file-list--file-card--content--text">
          <div className="organisation-stakeholders--upload--file-list--file-card--content--text--heading">
            {I18n.t("phrases.uploadNewFile")}
          </div>
          <div className="organisation-stakeholders--upload--file-list--file-card--content--text--subheading">
            {I18n.t("phrases.uploadNewFile_subheading")}
          </div>
        </div>
      </div>
    </Panel.Panel>
  );
});
