import { PanelBackgrounds, PanelTypes } from "@flightpath/coreui/dist/ui/Panel";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import AnimateHeight from "react-animate-height";
import { Button, ButtonIcon, ButtonTypes } from "../../../../../../../../components/ui/Button";
import { IconSymbols } from "../../../../../../../../components/ui/Icon";
import { Panel } from "../../../../../../../../components/ui/Panel";
import { PositionedSpinner } from "../../../../../../../../components/ui/PositionedSpinner";
import { Input } from "../../../../../../../../components/ui/_forms/Input";
import { CanEdit, CanView } from "../../../../../../../../contexts/permissions/PermissionHelpers";
import { PermissionErrorMessage } from "../../../../../../../../contexts/permissions/PermissionMessages";
import { PermissionFields } from "../../../../../../../../contexts/permissions/PermissionsTypes";
import I18n from "../../../../../../../../core/localization/I18n";
import { IModalContextModel } from "../../../../../../../../core/modalZ/context/IModalContext";
import { Enums } from "../../../../../../../../enums";
import { useCurrentOrganisationId } from "../../../../../../../../services/local/organisationContext/OrganisationContextModel";
import { ImpactCard } from "../../../../../../../change/impacts/impactCard/ImpactCard_view";
import { AddImpactForm } from "./AddImpactForm";
import { StakeholderImpactsSidebarModel } from "./StakeholderImpactsSidebar_model";

export interface StakeholderImpactsProps {
  projectId: number;
  stakeholderId: number;
  modalService: IModalContextModel;
}

export const StakeholderImpactsSidebar: React.FunctionComponent<StakeholderImpactsProps> = observer(
  ({ projectId, stakeholderId, modalService }) => {
    const organisationId = useCurrentOrganisationId();
    const [model, setModel] = useState(
      () => new StakeholderImpactsSidebarModel(stakeholderId, organisationId, projectId)
    );
    const [showImpactForm, setShowImpactForm] = useState(false);

    useEffect(() => {
      if (model.projectId !== projectId) {
        setModel(new StakeholderImpactsSidebarModel(stakeholderId, organisationId, projectId));
      }
      model.onMount();
      return model.onUnmount;
    }, [model, stakeholderId, organisationId, projectId]);

    if (model.isLoading) return <PositionedSpinner />;

    return (
      <div className={`container-fluid pt-5`}>
        <CanView
          field={PermissionFields.IMPACTS}
          projectId={model.projectId}
          errorMessage={
            <div className="row mb-4">
              <div className="col">
                <PermissionErrorMessage>
                  <p className="mb-0">{I18n.t("phrases.noImpactAccess")}</p>
                </PermissionErrorMessage>
              </div>
            </div>
          }
        >
          <div className="row mb-4">
            <div className="col">
              <CanEdit field={PermissionFields.IMPACTS} projectId={model.projectId}>
                {!showImpactForm && (
                  <Button
                    onClick={() => {
                      setShowImpactForm(true);
                      model.resetSearch();
                    }}
                  >
                    {showImpactForm ? I18n.t("phrases.done") : I18n.t("phrases.addImpact")}
                  </Button>
                )}
                {showImpactForm && (
                  <Panel.Panel
                    hasBorderRadius={true}
                    background={PanelBackgrounds.BG_WHITE}
                    type={PanelTypes.OUTLINES}
                    className="py-4 text-left"
                  >
                    <AddImpactForm
                      setFormIsShown={setShowImpactForm}
                      reloadFn={s => {
                        modalService.hideAll();
                      }}
                      projectId={projectId}
                      projectStakeholderProvider={model.projectStakeholderProvider}
                      organisationId={model.organisationId}
                      projectStakeholder={model.stakeholder}
                    />
                  </Panel.Panel>
                )}
              </CanEdit>
              {!showImpactForm && (
                <>
                  <ButtonIcon
                    className="float-right"
                    symbol={model.isSearchMode ? IconSymbols.Close : IconSymbols.Search}
                    iconSize={Enums.UiSizes.SM}
                    type={ButtonTypes.OUTLINE_PRIMARY}
                    onClick={model.isSearchMode ? model.resetSearch : model.showSearchMode}
                  />

                  <AnimateHeight height={model.isSearchMode ? "auto" : 0} animateOpacity={true}>
                    <Input
                      autoFocus={true}
                      size={-1}
                      onChange={model.updateSearchValue}
                      borderStyle="underline"
                      placeholder={I18n.t("placeholders.searchImpactActions")}
                      icon="search"
                      value={model.searchValue}
                      onBlur={null}
                      className="mt-3"
                    />
                  </AnimateHeight>
                </>
              )}
            </div>
          </div>

          <div className="row mb-3">
            <div className="col">
              {model.filteredStakeholderImpacts?.length ? (
                model.filteredStakeholderImpacts.map(impact => {
                  return <ImpactCard key={impact.id} item={impact} />;
                })
              ) : (
                <Panel.Panel hasBorderRadius={true} type={PanelTypes.OUTLINES} className="py-2 text-center">
                  <div>{I18n.t("phrases.noActionImpacts")}</div>
                </Panel.Panel>
              )}
            </div>
          </div>
          <div className="row mb-5">
            <div className="col">
              <div className="d-flex">
                <Button
                  className="ml-auto"
                  onClick={() => {
                    modalService.hide();
                  }}
                >
                  {I18n.t("phrases.done")}
                </Button>
              </div>
            </div>
          </div>
        </CanView>
      </div>
    );
  }
);
