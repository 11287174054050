import { ButtonTypes } from "../../../../../components/ui/Button";
import { Icon, IconSymbols } from "../../../../../components/ui/Icon";
import { Panel } from "../../../../../components/ui/Panel";
import { gridSideModalComponentProps } from "../../../../../core/grids/GridSideModals";
import I18n from "../../../../../core/localization/I18n";
import ModalContext from "../../../../../core/modalZ/context/ModalContext";
import { Animations } from "../../../../../core/util/Animations";
import { JobRolesGrid } from "./modals/JobRolesGrid/JobRolesGrid_view";
import { ProjectProcessRolesGrid } from "./modals/ProjectProcessRolesGrid/ProjectProcessRolesGrid_view";

export const SHOW_JOB_ROLES_GRID = () => {
  ModalContext.show({
    showClose: false,
    title: null,
    content: (
      <JobRolesGrid
        onAssignSuccess={() => {
          ModalContext.hide();
        }}
      ></JobRolesGrid>
    ),
    componentProps: {
      wrapHeight: "default",
      wrapWidth: "large",
      position: "middle",
      className: "modal__process-template modal__grid-link"
    },
    animationOptions: {
      animateIn: Animations.SLIDE_IN_UP,
      animateOut: Animations.SLIDE_OUT_DOWN
    }
  });
};

export const SHOW_LINK_TO_PROCESS_ROLES = (assignProjectProcessRolesToJobRoles: (processRoleIds: number[]) => void) => {
  ModalContext.show({
    showClose: false,
    title: null,
    content: (
      <ProjectProcessRolesGrid
        onAssignClick={async roleIds => {
          await assignProjectProcessRolesToJobRoles(roleIds);
          ModalContext.hide();
        }}
      ></ProjectProcessRolesGrid>
    ),
    componentProps: {
      ...gridSideModalComponentProps,
      wrapHeight: "default",
      wrapWidth: "large",
      position: "middle",
      className: "modal__grid-link"
    },
    animationOptions: {
      animateIn: Animations.SLIDE_IN_UP,
      animateOut: Animations.SLIDE_OUT_DOWN
    }
  });
};

export const SHOW_PROJECT_PROCESS_JOB_ROLES_DELETE_CONFIRM_MODAL = (
  projectProcessJobRoleIds: number[],
  removeProjectProcessJobRoleRange: (projectProcessJobRoleIds: number[]) => Promise<any>
) => {
  return ModalContext.showConfirmDialog(
    <h1 className="mt-4">{I18n.t("phrases.confirm")}</h1>,
    <div className="container-fluid">
      <div className="row mb-3">
        <div className="col-12">
          <Icon symbol={IconSymbols.AlertCircle} className="mr-2" />
          {I18n.t("warnings.removeProcessJobRoleFromProject")}
        </div>
      </div>
      <div className="row">
        <div className="col">
          {I18n.t("phrases.confirmProjectProcessJobRoleRangeRemove", { name: projectProcessJobRoleIds.length + "" })}
        </div>
      </div>
    </div>,
    I18n.t("phrases.yes"),
    I18n.t("phrases.no"),
    {
      wrapWidth: "small",
      spacing: "small",
      position: "middle",
      panelProps: {
        background: Panel.PanelBackgrounds.BG_WHITE
      }
    },
    async () => {
      ModalContext.hide();
      await removeProjectProcessJobRoleRange(projectProcessJobRoleIds);
    },
    () => {
      ModalContext.hide();
    },
    ButtonTypes.DANGER
  );
};
