import moment from "moment";
import { stringToMomentDateForComparison } from "../../../../utils/helpers";
import { ConditionPartsJoinOperators, JoinOperators, UrlOperators } from "./FilterAddonContants";
import * as _ from "lodash";

export const getFilterModelFromSearchParams = (urlSearchParams: URLSearchParams) => {
  let params = {};

  urlSearchParams.forEach((value, key) => {
    if (key === "extFilter") {
      return;
    }
    const op = getOperator(value);
    const type = getFilterType(key);

    // Condition to handle logic operators for filters
    // url structure should be operatorSymbol=colName|value1|value2|...
    if (Object.keys(JoinOperators).includes(key.toLowerCase())) {
      const values = value.split(ConditionPartsJoinOperators.or.symbol);
      const extractedKey = values[0];
      values.shift();

      params = {
        ...params,
        [extractedKey]: {
          filterType: type,
          operator: key,
          conditions: values.map(val => {
            return {
              filterType: type,
              type: op,
              filter: val
            };
          })
        }
      };
    }
    params = {
      ...params,
      [key]: {
        filterType: type,
        type: op.gridOperator,
        ...getValue(value, op, type)
      }
    };
  });

  return params;
};

const getOperator = operator => {
  const s = _.filter(UrlOperators, item => {
    return operator.indexOf(item.symbol) === 0;
  })[0];

  if (s) {
    return s;
  }
  return { gridOperator: "equals" };
};

const getFilterType = (key: string) => {
  let dateFilters = ["startDate", "endDate"];
  if (dateFilters.indexOf(key) >= 0) {
    return "date";
  }

  return "text";
};

const getValue = (value: string, op, type) => {
  if (type === "date") {
    if (op.gridOperator === "inRange") {
      const val = value.replace(op.symbol, "");
      let s = val.split(ConditionPartsJoinOperators.between.symbol);
      return {
        dateFrom: stringToMomentDateForComparison(s[0]),
        dateTo: stringToMomentDateForComparison(s[1])
      };
    }

    return {
      dateFrom: moment(value).isValid() ? stringToMomentDateForComparison(value) : null,
      dateTo: moment(value).isValid() ? stringToMomentDateForComparison(value) : null
    };
  }

  if (op.gridOperator !== "equals") {
    value = value.slice(1);
    return { filter: value };
  }
  return { filter: value };
};
