import { Button as Bu } from "@flightpath/coreui";
import { UiSizes } from "@flightpath/coreui/dist/enums";
import { IconSymbols } from "@flightpath/coreui/dist/generated/IconSymbols";
import { ButtonTypes, ButtonIconShapes } from "@flightpath/coreui/dist/ui/Button";
import { Icon } from "@flightpath/coreui/dist/ui/Icon";
import React from "react";
import { Link } from "react-router-dom";
import { B } from "../../../core/util/BootstrapHelper";

export const Button = Bu.Button;
export const CoreLinkButton = Bu.LinkButton;
export const ButtonIcon = Bu.ButtonIcon;

const LinkButton: React.FunctionComponent<LinkButtonProps> = props => {
  let cls: any = B()
    .bl("btn")
    .pmod(props.type || ButtonTypes.PRIMARY)
    .pmod(props.size || "md")
    .add(props.isDisabled ? "disabled" : "").bem;
  let k: any = { ...props };
  delete k.isDisabled;
  return props.href ? (
    <Link
      {...k}
      target={props.target}
      title={props.title || ""}
      className={`${cls} ${props.className || ""}`}
      to={props.href || ""}
      onClick={props.onClick}
    >
      {props.children}
    </Link>
  ) : (
    <CoreLinkButton {...props}>{props.children}</CoreLinkButton>
  );
};

export interface LinkButtonIconProps extends LinkButtonProps {
  symbol: IconSymbols;
  shape?: ButtonIconShapes;
  iconSize?: UiSizes;
}

const LinkButtonIcon: React.FunctionComponent<ButtonIconProps> = props => {
  let cls: any = B()
    .bl("btn")
    .pmod("icon")
    .pmod((props.shape && "shape-" + props.shape) || "shape-rectangle").bem;

  return (
    <LinkButton {...props} className={`${cls} ${props.className || ""}`}>
      <Icon symbol={props.symbol} size={props.iconSize || UiSizes.MD} />
    </LinkButton>
  );
};

export { ButtonTypes, ButtonIconShapes, LinkButton, LinkButtonIcon };

export interface ButtonProps extends Bu.ButtonProps {}

export interface LinkButtonProps extends Bu.LinkButtonProps {}

export interface ButtonIconProps extends Bu.ButtonIconProps {}
