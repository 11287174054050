import _ from "lodash";
import { action, makeObservable, observable } from "mobx";
import ColorScale from "../../../../../core/colorScale/ColorScale";
import { BaseModel } from "../../../../../core/util/BaseModel";
import OrganisationsApi, {
  OrganisationsApi as IOrganisationsApi
} from "../../../../../services/api/v2/organisations/Organisations.api";

export class ProgrammeSunburstModel extends BaseModel {
  programmeProvider: IOrganisationsApi;
  @observable.ref organisationId: number;
  @observable.ref programmeId: number;
  @observable sunburst: any;
  @observable isLoading: boolean;
  @observable impactLevelCounts: any[];
  @observable actionRagCounts: any[];

  constructor(organisationId: number, programmeId: number) {
    super();
    makeObservable(this);
    this.organisationId = organisationId;
    this.programmeId = programmeId;
    this.programmeProvider = OrganisationsApi;
    this.load();
  }

  @action
  load = async () => {
    this.isLoading = true;
    await this.loadSunburst();
    this.isLoading = false;
  };

  @action
  loadSunburst = async () => {
    let res = await this.programmeProvider.getOrganisationProgrammeSunburst(this.organisationId, this.programmeId);
    if (!res || res.isError) return;
    this.sunburst = res.payload;
    this.loadSunburstProjectColours();
    this.sortProjectsBySize();
  };

  @action
  sortProjectsBySize = () => {
    this.sunburst.children.forEach(programme => {
      programme.children = programme.children.sort((a, b) => b.projectValue - a.projectValue);
    });
  };

  @action
  loadSunburstProjectColours = () => {
    let projectValues = this.sunburst.children
      .filter(p => p.id !== -1)
      .map(p => (p.projectValue = this.getProjectValue(p)));
    let maxVal: number = _.max(projectValues);
    const projectColourScheme = ["#BFA1FF", "#23006E"];

    let colourScale = new ColorScale(0, maxVal === 0 ? 1 : maxVal, projectColourScheme, 1);
    this.sunburst.children.forEach((c, i) => {
      if (c.id === -1) {
        return;
      }
      let rgbaColourObject = colourScale.getColor(projectValues[i]);
      let rgbaString = `rgba(${rgbaColourObject.r}, ${rgbaColourObject.g}, ${rgbaColourObject.b}, ${rgbaColourObject.a}, 1)`;
      c.colour = "#" + this.rgba2hex(rgbaString);
    });
  };

  rgba2hex = orig => {
    var a,
      rgb = orig.replace(/\s/g, "").match(/^rgba?\((\d+),(\d+),(\d+),?([^,\s)]+)?/i),
      alpha = ((rgb && rgb[4]) || "").trim(),
      hex = rgb
        ? (rgb[1] | (1 << 8)).toString(16).slice(1) +
          (rgb[2] | (1 << 8)).toString(16).slice(1) +
          (rgb[3] | (1 << 8)).toString(16).slice(1)
        : orig;

    if (alpha !== "") {
      a = alpha;
    } else {
      a = 0o1;
    }
    a = ((a * 255) | (1 << 8)).toString(16).slice(1);
    hex = hex + a;

    return hex;
  };

  getProjectValue = (project): number => {
    let projectEntities = project.children;
    if (!project.children.length) return project.value;

    return projectEntities.reduce((prev, curr) => {
      if (curr.value) return prev + curr.value;
      return prev + this.getChildrensValues(curr);
    }, 0);
  };

  getChildrensValues = (entity): number => {
    let val = entity.children.reduce((prev, curr) => {
      return prev + curr.value;
    }, 0);
    return val;
  };
}
