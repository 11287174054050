export enum GridLinkActionEnum {
  linkImpacts = "linkImpacts",
  linkImpactGroups = "linkImpactGroups",
  linkStakeholders = "linkStakeholders",
  linkActions = "linkActions",
  linkLocations = "linkLocations",
  linkBusinessAreas = "linkBusinessAreas",
  linkTags = "linkTags",
  linkProcesses = "linkProcesses"
}

export enum GridActionEnum {
  add = "add",
  assign = "assign",
  review = "review",
  email = "email",
  delete = "delete",
  duplicate = "duplicate",
  dropdown = "dropdown",
  link = "link",
  bulkEdit = "bulkEdit",
  import = "import",
  export = "export",
  upload = "upload",
  textWrap = "textWrap",
  clearFilters = "clearFilters",
  resetColumns = "resetColumns",
  search = "search",
  divider = "divider",
  people = "peoples"
}
