import { useHref } from "react-router-dom";
import { PositionedSpinner } from "../../../../../../components/ui/PositionedSpinner";
import { ComputedDatum, SunburstChart, useTheme } from "../../../../../../components/widgets/sunburst/sunburst";
import I18n from "../../../../../../core/localization/I18n";
import { ElementTypes } from "../../../../../../enums";
import { DashboardSunburstLegend } from "./DashboardSunburstLegend";
import { getSunburstDrillThroughUrl } from "./redirectToGrid";
import { getBasenameAsPrefix } from "../../../../../../core/util/Helpers";

const DashboardTooltip = ({ data }: ComputedDatum<any>) => {
  const theme = useTheme();
  let labelText = "";

  if (data.elementType === ElementTypes.PROJECT) {
    labelText = `${I18n.t("entities.project")}: ${data.label}`;
  } else if (data.id === -1) {
    labelText = data.label;
  } else if (data.elementType === ElementTypes.PROGRAMME) {
    labelText = `${I18n.t("entities.programme")}: ${data.label}`;
  } else if (data.elementType === ElementTypes.IMPACT || data.elementType === ElementTypes.ACTION) {
    let value = getChildrenSum(data.children);
    labelText = `${data.label}: ${value}`;
  } else {
    labelText = `${data.label}: ${data.value}`;
  }

  return <strong style={{ ...theme.tooltip.container }}>{labelText}</strong>;
};

const getChildrenSum = children => {
  return children.reduce((prev, curr) => prev + curr.value, 0);
};

export const DashboardSunburstCard = ({ sunburst, organisationId }) => {
  const basename = useHref("/");
  const redirectToGridFn = datum => {
    if (!datum.data.children.length) {
      const url = getBasenameAsPrefix(basename) + getSunburstDrillThroughUrl(datum, organisationId);
      window.location.href = url;
      return false;
    }
    return true;
  };

  return (
    <div className="col d-flex">
      <DashboardSunburstLegend />
      {!sunburst && <PositionedSpinner />}
      {sunburst && (
        <SunburstChart
          className="sunburst-dashboard"
          data={sunburst}
          tooltipFn={DashboardTooltip}
          onClickFn={redirectToGridFn}
        />
      )}
    </div>
  );
};
