import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { SingleForm } from "../../../../../../../../pages/change/forms/singleFormModel/SingleForm_view";
import { useCurrentOrganisationId } from "../../../../../../../../services/local/organisationContext/OrganisationContextModel";
import ModalContext from "../../../../../../../modalZ/context/ModalContext";
import { ImpactMilestonesSidebarModel } from "./ImpactMilestonesSidebar_model";
interface ImpactMilestonesSidebarProps {
  impact: FP.Entities.IImpact;
}

export const ImpactMilestonesSidebar: React.FC<ImpactMilestonesSidebarProps> = observer(({ impact }) => {
  const organisationId = useCurrentOrganisationId();
  const { projectId } = useParams();
  let [model, setModel] = useState(
    () => new ImpactMilestonesSidebarModel(ModalContext.hide, organisationId, +projectId, impact)
  );

  useEffect(() => {
    setModel(new ImpactMilestonesSidebarModel(ModalContext.hide, organisationId, +projectId, impact));
  }, [organisationId, projectId, impact]);
  return (
    <div className="container-fluid pt-3">
      <SingleForm model={model.formModel} />
    </div>
  );
});
