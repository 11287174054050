import { BASE_COLUMN_CONFIG } from "../../baseColumn/BaseColumn_config";
import { ISdColDef } from "../../baseColumn/BaseColumnTypes";

export const UI_ACTION_COLUMN_CONFIG = (columnOptions?: ISdColDef<any, any>): ISdColDef<any, any> => ({
  ...BASE_COLUMN_CONFIG,
  field: "startDate",
  headerName: "header Name",
  editable: true,
  ...columnOptions
});
