import React from "react";
import { useGetAllProgrammes } from "../../../../contexts/permissions/PermissionHooks";
import { useCurrentOrganisationId } from "../../../../services/local/organisationContext/OrganisationContextModel";

import * as _ from "lodash";
import { ProgrammeNavElement } from "../../../../components/widgets/programmeNavElement";

export const ProgrammeMegaMenu: React.FC<any> = ({ programmeId }) => {
  const organisationId = useCurrentOrganisationId();

  const programmes = useGetAllProgrammes(organisationId);

  return (
    <div className={`programme-mega-menu py-4`}>
      {_.map(programmes, e => {
        const url = `/organisations/${organisationId}/programmes/${e.id}`;
        return (
          <div
            key={e.id}
            className={`programme-mega-menu__item mb-1 ${
              e.id === programmeId ? "programme-mega-menu__item--active" : ""
            }`}
          >
            <ProgrammeNavElement isActive={e.id > 0} key={`project-${e.id}`} url={url} name={e.name} />
          </div>
        );
      })}
    </div>
  );
};
