import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { SingleFormModel } from "../../../../../../../../../pages/change/forms/singleFormModel/SingleForm_model";
import { SingleForm } from "../../../../../../../../../pages/change/forms/singleFormModel/SingleForm_view";
import { getEditDescriptionFormFields } from "./EditDescriptionSidebar_fields";
import I18n from "../../../../../../../../localization/I18n";
import { Button, ButtonTypes } from "../../../../../../../../../components/ui/Button";

export const EditDescriptionSidebar: React.FC<{
  hideModal: () => void;
  item: any;
  saveFn: (text: string) => Promise<any>;
}> = observer(({ hideModal, item, saveFn }) => {
  const [formModel] = useState(() => new SingleFormModel());
  useEffect(() => {
    formModel.onMount();
    formModel.formFields = getEditDescriptionFormFields(item);
  }, [formModel, item]);

  return (
    <div className="container-fluid">
      <SingleForm model={formModel} />
      <div className="row">
        <div className="col justify-content-end d-flex">
          <Button
            className="mr-2"
            type={ButtonTypes.OUTLINE_PRIMARY}
            onClick={async e => {
              hideModal();
            }}
          >
            {I18n.t("phrases.cancel")}
          </Button>
          <Button
            onClick={async e => {
              let res = await formModel.submit();
              await saveFn(res.description);
            }}
          >
            {I18n.t("phrases.save")}
          </Button>
        </div>
      </div>
    </div>
  );
});
