import { GridApi, StatusPanelDef } from "ag-grid-community";
import { IGridAddon } from "./IGridAddon";

interface IStatusBarAddonProps {
  statusBar: Dictionary<StatusPanelDef[]>;
}

export class StatusBarAddon implements IGridAddon<IStatusBarAddonProps> {
  onGridReady: (gridApi: GridApi<any>) => void;
  getProps = () => {
    return {
      statusBar: {
        statusPanels: [
          { statusPanel: "agTotalAndFilteredRowCountComponent" },
          { statusPanel: "agTotalRowCountComponent" },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" }
        ]
      }
    };
  };
}
