import { ISdColDef } from "../../baseColumn/BaseColumnTypes";
import { BaseTextColumnBuilder } from "../../baseColumn/BaseTextColumn_builder";
import { DESCRIPTION_COLUMN_CONFIG } from "./DescriptionColumn_config";
import { showEditDescriptionModal } from "./modals/editDescriptionModal/EditDescriptionModal_caller";

interface IDescriptionColumnBuilder {}

export class DescriptionColumnBuilder extends BaseTextColumnBuilder implements IDescriptionColumnBuilder {
  constructor(columnOptions?: ISdColDef<any, any>) {
    super({ ...DESCRIPTION_COLUMN_CONFIG(), ...columnOptions });
  }

  setEditableOnDoubleClick = (callback: (entityId: number, text: string) => Promise<any>) => {
    this.setColumnOptions({ onCellDoubleClicked: e => showEditDescriptionModal(e.data, callback) });
  };
}
