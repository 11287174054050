import { SmartTable } from "@flightpath/coreui/dist/widgets/smartTable/SmartTable";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { IconSymbols } from "../../../../../components/ui/Icon";
import { Placeholder } from "../../../../../components/ui/Placeholder";
import { Input } from "../../../../../components/ui/_forms/Input";
import { useCurrentOrganisationId } from "../../../../../services/local/organisationContext/OrganisationContextModel";
import { InfiniteLoader } from "../../../../../core/filter/InfiniteLoader_view";
import I18n from "../../../../../core/localization/I18n";
import { Animations } from "../../../../../core/util/Animations";
import { Enums } from "../../../../../enums";
import { GetTagsSmartTableConfig } from "./TagSettings_config";
import { TagSettingsModel } from "./TagSettings_model";
export interface TagSettingsProps {
  model?: TagSettingsModel;
}

export const TagSettings: React.FunctionComponent<TagSettingsProps> = observer(({ model: m }) => {
  const organisationId = useCurrentOrganisationId();

  const [model] = useState(() => m || new TagSettingsModel(organisationId));

  useEffect(() => {
    model.onMount();
    return model.onUnmount;
  }, [model]);

  const smartTableConfig = GetTagsSmartTableConfig(model);

  return (
    <div className="impacts-view pt-6">
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <h1>{I18n.t("phrases.manageTags")}</h1>
            <p className="mb-5">{I18n.t("phrases.manageTagsDescription")}</p>
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-6">
            <div className="d-flex align-items-center">
              <div style={{ flex: "1" }}>
                <Input
                  icon={IconSymbols.Search}
                  iconSize={Enums.UiSizes.SM}
                  placeholder={I18n.t("placeholders.searchTags")}
                  onChange={model.updateSearchValue}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`container-fluid ${Animations.FP_ZOOM_IN} speed-4 mt-4`}>
        {model.isLoading ? (
          <Placeholder
            componentProps={{
              numberOfRows: 4,
              numberOfColumns: 2
            }}
            showIf={true}
            type="Table"
          />
        ) : (
          <InfiniteLoader
            isLoading={model.infiniteLoaderModel.isLoading}
            hasNextPage={model.infiniteLoaderModel.hasNextPage}
            loadPageData={model.loadPageData}
            noMoreDataLabel={
              <p className="m-0 text-center">
                {model.infiniteLoaderModel.getTotal() === 0
                  ? I18n.t("phrases.noDataFound")
                  : I18n.t("phrases.noMoreTagsToLoad", { total: model.infiniteLoaderModel.getTotal() })}
              </p>
            }
            rootMargin="0px 0px 50px 0px"
          >
            <SmartTable
              colHeaders={smartTableConfig.colHeaders}
              actionsFn={smartTableConfig.actionsFn}
              onRowClick={smartTableConfig.onRowClick}
              data={model.tags || []}
            />
          </InfiniteLoader>
        )}
      </div>
    </div>
  );
});
