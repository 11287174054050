import I18n from "../../../../../../../localization/I18n";
import ModalContext from "../../../../../../../modalZ/context/ModalContext";
import { GridSideModalTitle } from "../../../../utils/GridModalUtils";
import { ActionTagsSidebarModel } from "./ActionTagsSidebar_model";
import { ActionTagsSidebar } from "./ActionTagsSidebar_view";

export const ACTION_TAGS_SIDEBAR_MODAL_CONFIG = (
  organisationId: number,
  projectId: number,
  action: FP.Entities.IAction
) => {
  return {
    showClose: true,
    title: <GridSideModalTitle name={action.name} field={I18n.t("phrases.tags")} />,
    content: (
      <ActionTagsSidebar model={new ActionTagsSidebarModel(ModalContext.hide, action.id, organisationId, projectId)} />
    )
  };
};
