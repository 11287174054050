import { AxiosInstance } from "axios";
import { FilteredProjectApiModel } from "../filteredApi/FilteredProjectApiModel";
import http from "../Http";

export class ConcernsApi extends FilteredProjectApiModel<FP.Entities.IConcern> {
  controller: string = "concerns";

  constructor(http: AxiosInstance) {
    super(http, "concerns");
  }
}

const instance = new ConcernsApi(http);
export default instance;
