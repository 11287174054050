import { TooltipPositions } from "@flightpath/coreui/dist/ui/Tooltip";
import React from "react";
import { UiSizes } from "../../../enums";
import { AvatarContent } from "../../ui/AvatarContent";
import { Panel } from "../../ui/Panel";
import { Tooltip } from "../../ui/Tooltip";

export interface UserAvatarContentProps {
  user: FP.Entities.IUser;
}

export const UserAvatarContent: React.FC<UserAvatarContentProps> = props => {
  return (
    <Tooltip
      triggeredOn="hover"
      position={TooltipPositions.LEFT}
      shownElement={
        <AvatarContent
          type="primary"
          avatarProps={{
            size: UiSizes.SM,
            imgSrc: props.user.createdBy.profileImageUrl
          }}
        ></AvatarContent>
      }
    >
      <Panel.Panel
        background={Panel.PanelBackgrounds.BG_WHITE}
        className="p-3 target-label impact-history__panel"
        hasBorderRadius={true}
        style={{ width: "auto" }}
        type={Panel.PanelTypes.OUTLINES}
      >
        <h3 className="mb-1">
          {props.user.createdBy.firstName} {props.user.createdBy.lastName}
        </h3>
        <h4 className="mb-0">{props.user.createdBy.email}</h4>
      </Panel.Panel>
    </Tooltip>
  );
};
