import { observer } from "mobx-react";
import React, { useEffect, useRef, useState } from "react";
import { IF } from "../../../../../components/hoc/If";
import { Button, ButtonTypes } from "../../../../../components/ui/Button";
import { ConnectedUserAvatars } from "../../../../../components/widgets/connectedUserAvatars/ConnectedUserAvatars";
import { GridView } from "../../../../../core/grids/GridView_view";
import I18n from "../../../../../core/localization/I18n";
import { UiSizes } from "../../../../../enums";
import { useCurrentOrganisationId } from "../../../../../services/local/organisationContext/OrganisationContextModel";
import { MicroForm } from "../../../../change/forms/microForm/MicroForm";
import { BenefitSettingsModel } from "./BenefitSettings_model";
import { BenefitsGridModel } from "./BenefitsGrid/BenefitsGrid_model";
import { useFlightPathUser } from "../../../../../core/auth_insight/UserContext";
import { useParams } from "react-router-dom";

interface BenefitSettingsProps {}

export const BenefitSettings: React.FC<BenefitSettingsProps> = observer(props => {
  const organisationId = useCurrentOrganisationId();
  const authUser = useFlightPathUser();
  const filterRef = useRef(null);
  const { projectId } = useParams();
  const [model, setModel] = useState(() => new BenefitSettingsModel(organisationId, +projectId, authUser));
  const [gridModel] = useState(() => new BenefitsGridModel(organisationId, +projectId, authUser));

  useEffect(() => {
    model.onMount();
    return model.onUnmount;
  }, [model]);

  useEffect(() => {
    if (+projectId !== model.projectId) {
      setModel(new BenefitSettingsModel(organisationId, +projectId, authUser));
    }
  }, [organisationId, projectId, authUser, model.projectId]);
  return (
    <div className="benefit-settings">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col d-flex mb-2">
            <MicroForm
              fieldName="name"
              className="float-left mt-2 mr-2"
              buttonPlaceholder={I18n.t("phrases.add")}
              formModel={model.formModel}
              onSubmit={model.createBenefit}
            />

            <Button
              type={ButtonTypes.OUTLINE_PRIMARY}
              size={UiSizes.SM}
              isDisabled={model.selectedBenefits.length === 0}
              onClick={model.selectedBenefits.length > 0 ? model.showBenefitConfirmDeleteModal : null}
              className="mt-2 align-self-start"
            >
              {I18n.t("phrases.delete")}
            </Button>
          </div>
          <div className="col">
            <ConnectedUserAvatars connectedUsers={model.connectedUsers} />
          </div>
        </div>
      </div>

      <IF condition={!model.isLoading}>
        <GridView
          context={
            {
              // deleteFn: model.deleteFieldData
            }
          }
          users={model.connectedUsers}
          data={model.gridData}
          onCellClicked={model.updateUserSelectedCell}
          onCellEditModeChange={model.updateUserSelectedCell}
          onSelectionChanged={model.updateSelectedBenefits}
          model={gridModel}
          isLoading={gridModel.isLoading}
          ref={filterRef}
          // filterHasChangedFn={setHasFilters}
          overlayNoRowsTemplate={null}
        />
      </IF>
    </div>
  );
});
