import { observer } from "mobx-react";
import { useEffect, useRef, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { IF } from "../../../../../../components/hoc/If";
import { PositionedSpinner } from "../../../../../../components/ui/PositionedSpinner";
import { useCurrentOrganisationId } from "../../../../../../services/local/organisationContext/OrganisationContextModel";
import { useFlightPathUser } from "../../../../../auth_insight";
import { AppGridToolbarType } from "../../types/AppGrid_types";
import { AppGridToolbar } from "../base/AppGridToolbar_view";
import { AppGrid } from "../base/AppGrid_view";
import { ModalGridToolbar } from "../base/ModalGridToolbar_view";
import { ProjectProcessGridModel } from "./ProjectProcessesGridView_model";
import { EntityTypes, GridTypes } from "../../../../../../enums";

interface ProjectProcessesGridProps {
  data: any[];
  gridToolbarType?: AppGridToolbarType;
  onAssignSuccess?: (selectedItems: number[]) => void;
  selectedLinkItems?: number[];
  refreshDataHandler?: any;
  connectedUsers?: FP.Entities.IUser[];
  onFieldUpdate?: () => void;
  onCellStateChange?: (organisationId: number, projectId: number) => void;
  preSelectedItemIds?: number[];
  gridType?: EntityTypes | GridTypes;
}

export const ProjectProcessesGrid: React.FunctionComponent<ProjectProcessesGridProps> = observer(
  ({
    data,
    gridToolbarType = "base",
    onAssignSuccess,
    connectedUsers,
    refreshDataHandler,
    onFieldUpdate,
    onCellStateChange,
    preSelectedItemIds,
    gridType
  }) => {
    const organisationId = useCurrentOrganisationId();
    const authUser = useFlightPathUser();
    const gridRef = useRef<AppGrid>();
    const [searchParams] = useSearchParams();
    const { projectId } = useParams<{ projectId: string }>();

    const [gridModel, setGridModel] = useState(
      () =>
        new ProjectProcessGridModel(
          organisationId,
          +projectId,
          authUser,
          refreshDataHandler,
          onFieldUpdate,
          gridToolbarType,
          preSelectedItemIds,
          gridType,
          searchParams
        )
    );

    useEffect(() => {
      if (
        (typeof projectId !== "undefined" && gridModel.projectId !== +projectId) ||
        gridModel.organisationId !== organisationId
      ) {
        setGridModel(
          new ProjectProcessGridModel(
            organisationId,
            +projectId,
            authUser,
            refreshDataHandler,
            onFieldUpdate,
            gridToolbarType,
            preSelectedItemIds,
            gridType,
            searchParams
          )
        );
      }
      // eslint-disable-next-line
    }, [organisationId, projectId]);

    useEffect(() => {
      gridModel.onMount();
      return gridModel.onUnmount;
      // eslint-disable-next-line
    }, []);

    return (
      <>
        <IF condition={!data}>
          <PositionedSpinner />
        </IF>
        <IF condition={!!data}>
          <IF condition={gridToolbarType === "all" || gridToolbarType === "link-modal"}>
            <ModalGridToolbar
              className="process-action-grid"
              onQuichSearchInputChange={e => {
                gridRef?.current?.setSearchText(e.currentTarget.value);
              }}
              selectedItems={gridModel.selectedItems}
              preSelectedItemIds={preSelectedItemIds}
              onAssignSuccess={onAssignSuccess}
            />
          </IF>
          <IF
            condition={
              gridModel.userCanEditImpacts &&
              (gridToolbarType === "all" || (gridToolbarType === "base" && !!gridModel.gridActions))
            }
          >
            <AppGridToolbar
              connectedUsers={connectedUsers}
              onQuichSearchInputChange={e => {
                gridRef.current.setSearchText(e.currentTarget.value);
              }}
              selectedItemCount={gridModel.selectedItems?.length}
              actions={gridModel.gridActions}
            />
          </IF>
          <AppGrid
            ref={gridRef}
            filterStoreAddon={gridModel.filterStoreAddon}
            columnStateAddon={gridModel.columnStateAddon}
            textWrapperAddon={gridModel.textWrapAddon}
            onFirstData={gridModel.onFirstDataRendered}
            onGridStateUpdate={gridModel.onGridStateUpdate}
            onGridReady={gridModel.onGridReady}
            rowData={data}
            columnDefs={gridModel.gridColumns}
            shouldTrackSelectedCell={true}
            connectedUsers={connectedUsers}
            onCellClicked={onCellStateChange && (onCellStateChange(organisationId, +projectId) as any)}
            onCellEditModeChange={onCellStateChange && (onCellStateChange(organisationId, +projectId) as any)}
            rowSelection="multiple" // Options - allows click selection of rows
          />
        </IF>
      </>
    );
  }
);
