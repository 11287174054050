import { action, makeObservable, observable } from "mobx";
import AudiencesApi, {
  AudiencesApi as IAudiencesApi
} from "../../../../../../../../services/api/v2/audiences/Audiences.api";
import _ from "lodash";

export class AudiencesPeopleModel {
  audiencesProvider: IAudiencesApi;
  organisationId: number;
  projectId: number;
  audienceIds: number[];
  @observable people: any[] = [];
  @observable isLoading: boolean = true;

  constructor(organisationId: number, projectId: number, audienceIds: number[]) {
    makeObservable(this);
    this.audiencesProvider = AudiencesApi;
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.audienceIds = audienceIds;
  }

  onMount = async () => {
    await this.loadPeople();
  };

  loadPeople = async () => {
    let res = await this.audiencesProvider.getStakeholdersFromAudiencesIds(
      this.organisationId,
      this.projectId,
      this.audienceIds
    );

    if (res.error) {
      return;
    }

    let k = _.uniqBy(res.payload, function (e: any) {
      return e.email;
    });

    this.setPeople(k);
    this.setIsLoading(false);
  };

  @action
  setIsLoading = (isLoading: boolean) => (this.isLoading = isLoading);

  @action
  setPeople = (people: any[]) => (this.people = people);
}
