import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
import { useCurrentOrganisationId } from "../../../../../../../../services/local/organisationContext/OrganisationContextModel";
import { useState } from "react";
import { AudiencesGridModalView } from "../../../AudiencesGrid/modals/AudiencesGridModal/AudiencesGridModal_view";
import { ImpactAudiencesLinkModalModel } from "./ImpactAudienceLinkModal_model";
interface ImpactAudiencesLinkModalViewProps {
  itemIds: number[];
  selectedLinkItemIds: number[];
}
const ImpactAudiencesLinkModalView: React.FunctionComponent<ImpactAudiencesLinkModalViewProps> = observer(
  ({ itemIds, selectedLinkItemIds }) => {
    const { projectId } = useParams<{ projectId: string }>();
    const organisationId = useCurrentOrganisationId();
    const [model] = useState(() => new ImpactAudiencesLinkModalModel(+organisationId, +projectId, itemIds));

    return (
      <AudiencesGridModalView
        itemIds={itemIds}
        selectedLinkItemIds={selectedLinkItemIds}
        onAssignSuccess={model.onAssignSuccess}
      ></AudiencesGridModalView>
    );
  }
);

export { ImpactAudiencesLinkModalView };
