import React, { useState } from "react";
import I18n from "../../../../../../../../core/localization/I18n";
import { IUiAction, UiActionRenderers } from "../../../../../../../../core/uiAction/IUiAction";
import { getImpactTagFormFields } from "./ImpactTagsSidebar_formFields";
import TagsApi from "../../../../../../../../services/api/v2/tags/Tags.api";
import { SingleFormModel } from "../../../../../../../../pages/change/forms/singleFormModel/SingleForm_model";
import { SingleForm } from "../../../../../../../../pages/change/forms/singleFormModel/SingleForm_view";

interface ManageImpactTagProps {
  organisationId: number;
  projectId: number;
  impact: FP.Entities.IImpact;
  closeFn: () => void;
}

class ManageImpactTagFormModel {
  formModel: SingleFormModel;

  constructor(organisationId: number, projectId: number, impact: FP.Entities.IImpact, closeFn) {
    this.formModel = new SingleFormModel();
    const tagsProvider = TagsApi;
    let formFields = getImpactTagFormFields(tagsProvider, organisationId, projectId, impact);
    let actions: IUiAction<any>[] = [
      {
        id: "action1",
        label: I18n.t("phrases.done"),
        onAction: ev => {
          closeFn();
        },
        componentProps: {
          className: "ml-auto"
        },
        rendersIn: UiActionRenderers.BUTTON
      }
    ];

    this.formModel.formFields = formFields;
    this.formModel.actions = actions;
  }
}

export const ManageImpactTagForm: React.FC<ManageImpactTagProps> = props => {
  const { impact, closeFn, projectId, organisationId } = props;

  const [model] = useState(() => new ManageImpactTagFormModel(organisationId, projectId, impact, closeFn));

  return (
    <div>
      <SingleForm model={model.formModel} />
    </div>
  );
};
