import { IFlightPathApiResponse } from "../BaseApiModel";
import { AxiosInstance, AxiosRequestConfig } from "axios";
import { BaseProjectApiModel } from "../BaseProjectApiModel";
import http from "../Http";

// TODO: To be removed
export class ReportsApi extends BaseProjectApiModel<any> {
  controller: string = "reports";
  minorController: string = "projects";
  constructor(http: AxiosInstance) {
    super(http, "reports");
  }

  //#region Stakeholder heat map

  getStHeatMapData = async (
    organisationId: number,
    projectId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<any[]>> => {
    const url = `${this.url}/${organisationId}/${this.minorController}/${projectId}/${this.controller}/stakeholderHeatMap`;
    let res = await this.http.get(url, config);
    return res.data;
  };

  //#endregion

  //#region impact level
  getImpactLevelData = async (
    organisationId: number,
    projectId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<any[]>> => {
    const url = `${this.url}/${organisationId}/${this.minorController}/${projectId}/${this.controller}/impactLevel`;
    let res = await this.http.get(url, config);
    return res.data;
  };

  //#endregion

  //#region action burndown

  getActionBurnDownData = async (
    organisationId: number,
    projectId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<any[]>> => {
    const url = `${this.url}/${organisationId}/${this.minorController}/${projectId}/${this.controller}/actionBurndown`;
    let res = await this.http.get(url, config);
    return res.data;
  };

  //#endregion

  //#region action over time

  getActionOverTimeData = async (
    organisationId: number,
    projectId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<any[]>> => {
    const url = `${this.url}/${organisationId}/${this.minorController}/${projectId}/${this.controller}/actionOverTime`;
    let res = await this.http.get(url, config);
    return res.data;
  };

  //#endregion

  //#region impact over time

  getImpactOverTimeData = async (
    organisationId: number,
    projectId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<any[]>> => {
    const url = `${this.url}/${organisationId}/${this.minorController}/${projectId}/${this.controller}/impactOverTime`;
    let res = await this.http.get(url, config);
    return res.data;
  };

  startProjectArtifactsReporting = async (
    organisationId: number,
    projectId,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<any>> => {
    const url = `${this.url}/${this.version}/hubs/organisations/${organisationId}/projects/${projectId}/start-project-artifacts-reporting`;
    let res = await this.http.get(url, config);
    return res.data;
  };

  //#endregion
}
const instance = new ReportsApi(http);
export default instance;
