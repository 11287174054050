import { action, makeObservable, observable } from "mobx";
import { GetGridFns, IGridFns } from "../../../../../../../core/grids/GridFunctions";
import { IGridModel } from "../../../../../../../core/grids/IGridModel";
import { GetUploadGridViewColumns } from "./UploadGrid_Grid_columns";
import StakeholdersApi, {
  StakeholdersApi as IStakeholdersApi
} from "../../../../../../../services/api/v2/stakeholders/Stakeholders.api";

export class UploadGrid_GridModel implements IGridModel {
  @observable isLoading: boolean = true;
  organisationId: number;
  projectId: number;
  gridFns: IGridFns;
  stakeholdersProvider: IStakeholdersApi;

  /**
   *
   */
  constructor(organisationId: number) {
    makeObservable(this);
    this.organisationId = organisationId;
    this.stakeholdersProvider = StakeholdersApi;
    this.gridFns = GetGridFns(this.stakeholdersProvider, this.organisationId, 0);
  }

  saveRowfn = async (
    id: number,
    value: string,
    fieldId: number,
    fileId: number,
    showUpdatedRows: boolean
  ): Promise<boolean> => {
    var res = await this.stakeholdersProvider.updateUploadField(
      this.organisationId,
      id,
      value,
      fieldId,
      fileId,
      showUpdatedRows
    );

    if (res.code === 200) {
      return res.payload;
    }

    return false;
  };

  getColumnConfig = () => {
    return GetUploadGridViewColumns(this.saveRowfn);
  };

  @action
  onMount = () => {
    this.isLoading = false;
  };

  onUnmount = () => {};
}
