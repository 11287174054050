import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import {
  InsightTabContent,
  InsightTabContentItem
} from "../../../../components/widgets/innerNavigation/InnerNavContent_view";
import { useIsVisualisationsEnabled } from "../../../../contexts/organisationSettings/OrganisationSettingsContext";
import I18n from "../../../../core/localization/I18n";
import Pages from "../../../../routes/InsightRoutes";
import { useCurrentOrganisationId } from "../../../../services/local/organisationContext/OrganisationContextModel";

export const ActionTabContent: React.FC = observer(() => {
  const organisationId = useCurrentOrganisationId();
  const { projectId } = useParams<{ projectId: string }>();
  const tabHeadingWithCount = (title: string, count?: number) => {
    var countText = count != null ? `(${count})` : "";
    return <h2 className="mb-0">{`${title} ${countText}`}</h2>;
  };
  const isVisualisationsEnabled = useIsVisualisationsEnabled();
  const items: InsightTabContentItem[] = [
    {
      label: tabHeadingWithCount(I18n.t("phrases.actionTracker")),
      url: Pages.projects.actions.listView.generateLink(organisationId, +projectId)
    }
  ];
  if (isVisualisationsEnabled) {
    items.push({
      label: tabHeadingWithCount("Action Report", null),
      url: Pages.projects.actions.visualisations.breakdown.generateLink(organisationId, +projectId)
    });
    items.push({
      label: tabHeadingWithCount(I18n.t("phrases.actionPlan"), null),
      url: Pages.projects.actions.visualisations.index.generateLink(organisationId, +projectId)
    });
  }
  const location = useLocation();
  const currentPath = location.pathname;
  const [currIndex, setCurrIndex] = useState(
    currentPath.endsWith("visualisations/breakdown") ? 1 : currentPath.endsWith("visualisations") ? 2 : 0
  );

  useEffect(() => {
    if (location.pathname.endsWith("actions")) {
      setCurrIndex(0);
    }
    if (location.pathname.endsWith("visualisations/breakdown")) {
      setCurrIndex(1);
    }
    if (location.pathname.endsWith("visualisations")) {
      setCurrIndex(2);
    }
  }, [location]);

  return <InsightTabContent items={items} currentIndex={currIndex} />;
});
