import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
import { GridHubStoreWrapper } from "../../../../../../apps/insight/stores/GridHubStoreWrapper";
import { useStores } from "../../../../../../apps/insight/stores/RootStore";
import { useCurrentOrganisationId } from "../../../../../../services/local/organisationContext/OrganisationContextModel";
import { useFlightPathUser } from "../../../../../auth_insight";
import { ImpactsGrid } from "./ImpactsGridView_view";
import { IF } from "../../../../../../components/hoc/If";
import { PositionedSpinner } from "@flightpath/coreui/dist/ui/PositionedSpinner";
import { useMemo } from "react";

export const WrapperImpactGridWithDataLoader: React.FC<{ selectedLinkItems: any[]; onAssignSuccess: any }> = observer(
  ({ onAssignSuccess, selectedLinkItems }) => {
    const { impactStore } = useStores();
    const organisationId = useCurrentOrganisationId();
    const { projectId } = useParams();
    const authUser = useFlightPathUser();
    const isLoading = useMemo(() => {
      let res = impactStore.isLoading;
      if (impactStore.isCustomFieldEnabled) {
        res = res && (!impactStore.columnDefs || impactStore.columnDefs?.length > 0);
      }
      return res;
    }, [impactStore.isLoading, impactStore.isCustomFieldEnabled, impactStore.columnDefs]);

    return (
      <GridHubStoreWrapper
        store={impactStore}
        projectId={+projectId}
        organisationId={organisationId}
        authUser={authUser}
      >
        <IF condition={isLoading}>
          <PositionedSpinner></PositionedSpinner>
        </IF>
        <IF condition={!isLoading}>
          <ImpactsGrid
            isLoading={impactStore.isLoading}
            gridToolbarType="link-modal"
            onAssignSuccess={onAssignSuccess}
            selectedLinkItems={selectedLinkItems}
            data={impactStore.data}
            columnDefs={impactStore.columnDefs}
          />
        </IF>
      </GridHubStoreWrapper>
    );
  }
);
