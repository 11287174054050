import { BASE_ROUTE, RouteParams } from "../constants";

export const PROGRAMME_ROUTES = {
  index: {
    path: `${BASE_ROUTE}/programmes/${RouteParams.programmeId}`,
    generateLink: (organisationId: string | number, programmeId: number | string) =>
      `${BASE_ROUTE.replace(RouteParams.organisationId, organisationId + "")}/programmes/${programmeId}`
  },
  listView: {
    path: "programmes",
    generateLink: (organisationId: number | string) =>
      `${BASE_ROUTE.replace(RouteParams.organisationId, organisationId + "")}/${PROGRAMME_ROUTES.listView.path}`
  },
  createView: {
    path: `${BASE_ROUTE}/programmes/create`,
    generateLink: () => PROGRAMME_ROUTES.createView.path
  },
  settings: {
    index: {
      path: `${BASE_ROUTE}/programmes/${RouteParams.programmeId}/settings/index`,
      generateLink: (organisationId: string | number, programmeId: number | string) =>
        `${BASE_ROUTE.replace(
          RouteParams.organisationId,
          organisationId + ""
        )}/programmes/${programmeId}/settings/index`
    },
    milestones: {
      path: `${BASE_ROUTE}/programmes/${RouteParams.programmeId}/settings/milestones`,
      generateLink: (organisationId: string | number, programmeId: number | string) =>
        `${BASE_ROUTE.replace(
          RouteParams.organisationId,
          organisationId + ""
        )}/programmes/${programmeId}/settings/milestones`
    }
  },
  settingsView: {
    index: {
      path: `${BASE_ROUTE}/programmes/${RouteParams.programmeId}/settings`,
      generateLink: (organisationId: string | number, programmeId: number | string) =>
        `${BASE_ROUTE.replace(RouteParams.organisationId, organisationId + "")}/programmes/${programmeId}/settings`
    },
    page: {
      path: `${BASE_ROUTE}/programmes/${RouteParams.programmeId}/settings/${RouteParams.page}`,
      generateLink: (organisationId: string | number, programmeId: number | string, page: string) =>
        `${BASE_ROUTE.replace(
          RouteParams.organisationId,
          organisationId + ""
        )}/programmes/${programmeId}/settings/${page}`
    }
  }
};
