import _ from "lodash";
import * as React from "react";
import { FORM_COL, ORGANISATION_PERMISSION_SCOPE_OPTIONS } from "../../../../../constants";
import { IFilterModel } from "../../../../../core/filter/Filter_model";
import { IMultiSelectorModel } from "../../../../../core/forms/controls/multiSelector/IMultiSelectorModel";
import { INIT_MULTISELECTOR } from "../../../../../core/forms/controls/multiSelector/MultiSelector_model";
import { generateFormFieldsFromJson } from "../../../../../core/forms/helpers/FormFieldMappers";
import I18n from "../../../../../core/localization/I18n";


export const GetUserSettingsFilters = (
    filterModel: IFilterModel<FP.Entities.IUser>,
    organisationId: number
) => {
    const permissionScopeFilter = filterModel.getFilter("permissionScope")


    let permissionVal: any = _.filter(ORGANISATION_PERMISSION_SCOPE_OPTIONS, e => permissionScopeFilter.value.indexOf(e.key as any) >= 0);
    const permissionLevel: Partial<IMultiSelectorModel> = {
        ...INIT_MULTISELECTOR,
        key: "permissionScope",
        label: <label htmlFor={"permissionLevel"}>{I18n.t("forms.permissionLevel")}</label>,
        fieldClassName: FORM_COL.FULL_WIDTH,
        placeholder: I18n.t("placeholders.selectPermissionLevel"),
        onFocus: async function (model: IMultiSelectorModel) {
            if (model.extractValue() !== null) {
                model.searchQuery = "";
            }
            model.setOptions(
                ORGANISATION_PERMISSION_SCOPE_OPTIONS.map(o => ({
                    ...o,
                    id: o.key,
                    label: o.label.startsWith("phrases") ? I18n.t(o.label) : o.label
                }))
            )
        },
        valueLabelFn: obj => obj?.label,
        componentProps: {
            className: "form-control"
        },
        onValueChange: value => {
            filterModel.setFilterValueList(
                permissionScopeFilter.key,
                value.map(e => e.key)
            )
        },
        value: permissionVal
    }


    const fields = [];
    fields.push(permissionLevel);


    const models = generateFormFieldsFromJson(fields);
    return models;
}