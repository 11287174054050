import { action, makeObservable, observable } from "mobx";

import { BaseModel } from "../../../../../../../../core/util/BaseModel";
import ActionsApi, { ActionsApi as IActionsApi } from "../../../../../../../../services/api/v2/actions/Actions.api";
import TagsApi, { TagsApi as ITagsApi } from "../../../../../../../../services/api/v2/tags/Tags.api";

export class ActionTagsSidebarModel extends BaseModel {
  actionsProvider: IActionsApi;
  tagsProvider: ITagsApi;

  @observable isLoading: boolean = true;
  @observable.ref action: FP.Entities.IAction;
  organisationId: number;
  projectId: number;
  closeModalFn: () => void;

  constructor(closeModalFn: () => void, actionId: number, organisationId: number, projectId: number) {
    super();
    makeObservable(this);
    this.actionsProvider = ActionsApi;
    this.tagsProvider = TagsApi;
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.loadAction(actionId);
    this.closeModalFn = closeModalFn;
  }

  @action
  setAction = (action: FP.Entities.IAction) => {
    this.action = action;
    this.isLoading = false;
  };

  @action
  loadAction = async (actionId: number) => {
    this.isLoading = true;
    let res = await this.actionsProvider.getDetailedByIdAsync(this.organisationId, this.projectId, actionId);
    if (!res || res.isError) return;

    this.setAction(res.payload);
  };

  @action
  removeTag = async (tagId: number) => {
    let res = await this.tagsProvider.removeActionTagAssoc(this.organisationId, this.projectId, tagId, this.action.id);
    if (!res || res.isError) return;
  };
}
