import { action, makeObservable, observable } from "mobx";

import { StakeholderProfilingType, StakeholderType } from "../../../../../enums";
import ProjectStakeholdersApi, {
  ProjectStakeholdersApi as IProjectStakeholdersApi
} from "../../../../../services/api/v2/projectStakeholders/ProjectStakeholders.api";
import ProjectAudienceVisualisationsHub from "../../../../../services/hubs/ProjectAudienceVisualisationsHub/ProjectAudienceVisualisations_hub";
import ProjectStakeholderVisualisationsHub, {
  ProjectStakeholderVisualisationsHub as IProjectStakeholderVisualisationsHub
} from "../../../../../services/hubs/ProjectStakeholderVisualisationsHub/ProjectStakeholderVisualisations_hub";
import GridToastService from "../../../../../services/local/gridToastService/GridToastService";
import Pages from "../../../../../routes/InsightRoutes";
export class StakeholderReportingModel {
  projectId: number;
  organisationId: number;
  @observable stakeholderCount: number;
  @observable audienceCount: number;
  @observable.ref stakeholders: FP.Entities.IProjectStakeholderSummary[] = [];
  projectStakeholderProvider: IProjectStakeholdersApi;
  navigate: any;
  gridToastService: any;
  @observable stakeholderType: StakeholderType;
  @observable profileType: StakeholderProfilingType;
  @observable isLoading: boolean = true;
  hub: IProjectStakeholderVisualisationsHub;

  constructor(
    organisationId: number,
    projectId: number,
    navigate: any,
    stakeholderType: StakeholderType,
    profileType: StakeholderProfilingType
  ) {
    makeObservable(this);
    this.projectId = projectId;
    this.organisationId = organisationId;
    this.gridToastService = GridToastService;
    this.projectStakeholderProvider = ProjectStakeholdersApi;

    this.navigate = navigate;
    this.stakeholderType = stakeholderType;
    this.profileType = profileType;

    this.hub =
      this.stakeholderType === StakeholderType.INDIVIDUAL
        ? ProjectStakeholderVisualisationsHub
        : ProjectAudienceVisualisationsHub;
  }

  registerSocketEvents = async () => {
    if (this.hub.isConnectionStarted === true) {
      await this.hub.stopConnection();
    }
    await this.hub.startConnection();

    this.hub.onData(d => {
      this.setData(d);
      // this.loadCharts();
    });

    await this.hub.invokeLoadData(this.organisationId, this.projectId);
  };

  onMount = async () => {
    await this.registerSocketEvents();
  };

  onUnmount = () => {
    this.hub.stopConnection();
  };

  @action
  setData = (data: FP.Entities.IProjectStakeholderSummary[]) => {
    this.isLoading = false;
    this.stakeholders = data;
  };

  changeCurrentView = (newTabIndex: number) => {
    if (newTabIndex === 2) {
      this.navigate(
        Pages.projects.stakeholders.visualisations.parameters.generateLink(this.organisationId, this.projectId, 1, 0)
      );
      return;
    }
    if (newTabIndex === 0) {
      this.navigate(Pages.projects.stakeholders.listView.generateLink(this.organisationId, this.projectId));
      return;
    }
    this.navigate(Pages.projects.audiences.listView.generateLink(this.organisationId, this.projectId));
  };

  loadStakeholderAndAudienceCounts = async () => {
    var res = await this.projectStakeholderProvider.getStakeholderAndAudienceCountsForProject(
      this.organisationId,
      this.projectId
    );
    if (!res || res.isError) return;
    if (res.payload) {
      this.stakeholderCount = res.payload.stakeholderCount;
      this.audienceCount = res.payload.audienceCount;
    }
  };
}
