import React, { useEffect, useRef, useState } from "react";
import { useCurrentOrganisationId } from "../../../../../../../services/local/organisationContext/OrganisationContextModel";
import { ProjectProcessRolesGridModel } from "./ProjectProcessRolesGrid_model";
import { GridView } from "../../../../../../../core/grids/GridView_view";
import { ProjectProcessRolesGridViewModel } from "./ProjectProcessRolesGridView_model";
import { Button, ButtonTypes } from "../../../../../../../components/ui/Button";
import { EntityTypes, Enums, UiSizes } from "../../../../../../../enums";
import { observer } from "mobx-react";
import { Input } from "../../../../../../../components/ui/_forms/Input";
import { IconSymbols } from "../../../../../../../components/ui/Icon";
import { useFlightPathUser } from "../../../../../../../core/auth_insight";
import { PositionedSpinner } from "@flightpath/coreui/dist/ui/PositionedSpinner";
import { ButtonIcon, ButtonIconShapes } from "@flightpath/coreui/dist/ui/Button";
import ModalContext from "../../../../../../../core/modalZ/context/ModalContext";
import { useParams } from "react-router-dom";
import I18n from "../../../../../../../core/localization/I18n";
import LocalStorageService from "../../../../../../../services/local/localStorageService/LocalStorageService";
import { Animations } from "../../../../../../../core/util/Animations";

export const ProjectProcessRolesGrid: React.FC<{
  onAssignClick: (projectProcessRoleIds: number[]) => void;
}> = observer(({ onAssignClick }) => {
  const { projectId } = useParams();
  const organisationId = useCurrentOrganisationId();
  const authUser = useFlightPathUser();
  const [model] = useState(() => new ProjectProcessRolesGridModel(+projectId, organisationId, authUser));
  const [gridModel] = useState(() => new ProjectProcessRolesGridViewModel(organisationId, +projectId, authUser));
  const filterRef = useRef(null);
  const wrapTextKey = `projects-${projectId}-${EntityTypes.PROJECT_JOB_ROLES_PROCESS_ROLES}-wrap-text`;
  const storageService = LocalStorageService;
  const [isTextWrapToggled, setIsTextWrapToggled] = useState(
    typeof storageService.get(wrapTextKey) !== "undefined" && storageService.get(wrapTextKey) === "1"
  );
  const [hasFilters, setHasFilters] = useState(false);
  const [hasColumnChanges, setHasColumnChanges] = useState(false);

  useEffect(() => {
    model.onMount();
    return model.onUnmount;
  }, [model]);

  if (model.isLoading) return <PositionedSpinner />;

  return (
    <div className={`project-process-roles-grid  ${Animations.FP_ZOOM_IN}`}>
      <div className="container-fluid py-3">
        <div className="row">
          <div className="col-10">
            <Button
              type={ButtonTypes.OUTLINE_PRIMARY}
              size={UiSizes.SM}
              isDisabled={model.selectedProcessRoles.length === 0}
              className="mt-2 mr-2 align-self-start"
              onClick={async () => {
                await onAssignClick(model.selectedProcessRoles);
              }}
            >
              Assign
            </Button>
            <Input
              autoFocus={true}
              className="mt-2 mb-2"
              size={UiSizes.XS}
              onChange={model.setSearchText}
              placeholder="Search"
              icon={IconSymbols.Search}
              iconSize={Enums.UiSizes.SM}
              style={{ height: 32 }}
            />
          </div>
          <div className="col-2 d-flex align-items-start px-0 justify-content-end">
            <ButtonIcon
              size={UiSizes.SM}
              onClick={ModalContext.hide}
              type={ButtonTypes.OUTLINE_PRIMARY}
              shape={ButtonIconShapes.RECTANGLE}
              symbol={IconSymbols.Close}
            />
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col d-flex">
            <ButtonIcon
              type={isTextWrapToggled ? ButtonTypes.PRIMARY : ButtonTypes.OUTLINE_PRIMARY}
              size={UiSizes.SM}
              onClick={() => {
                setIsTextWrapToggled(!isTextWrapToggled);
                filterRef.current.toggleTextWrapper();
              }}
              symbol={IconSymbols.TextWrap}
              className="mr-2 align-self-start"
            >
              {I18n.t("phrases.textWrap")}
            </ButtonIcon>
            <ButtonIcon
              type={ButtonTypes.LINK}
              size={UiSizes.SM}
              isDisabled={!hasFilters}
              onClick={() => filterRef.current.clearFilters()}
              className="mr-2 align-self-start"
              symbol={IconSymbols.ClearFilter}
            >
              {I18n.t("phrases.clearFilters")}
            </ButtonIcon>
            <ButtonIcon
              type={ButtonTypes.LINK}
              size={UiSizes.SM}
              isDisabled={!hasColumnChanges}
              onClick={() => filterRef.current.resetColumns()}
              className="mr-2 align-self-start"
              symbol={IconSymbols.MonitorBack}
            >
              {I18n.t("phrases.resetColumns")}
            </ButtonIcon>
          </div>
        </div>
      </div>
      {!model.isLoading && (
        <GridView
          context={{}}
          data={model.gridData}
          users={[]}
          onCellClicked={() => {}}
          onCellEditModeChange={() => {}}
          onSelectionChanged={model.updateSelectedProcessRoles}
          model={gridModel}
          isLoading={gridModel.isLoading}
          onGridReady={model.onGridReady}
          ref={filterRef}
          filterHasChangedFn={setHasFilters}
          columnOrderHasChangedFn={setHasColumnChanges}
          overlayNoRowsTemplate={null}
          gridOptions={{
            domLayout: 'autoHeight'
          }}
        />
      )}
    </div>
  );
});
