import React, { useState } from "react";
import I18n from "../../../../../../../core/localization/I18n";
import { IUiAction, UiActionRenderers } from "../../../../../../../core/uiAction/IUiAction";
import { SingleFormModel } from "../../../../../../change/forms/singleFormModel/SingleForm_model";
import TagsApi from "../../../../../../../services/api/v2/tags/Tags.api";
import { SingleForm } from "../../../../../../change/forms/singleFormModel/SingleForm_view";
import { getProjectProcessTagFormFields } from "./ProjectProcessTagsSidebar_formFields";

interface ManageProjectProcessTagProps {
  organisationId: number;
  projectId: number;
  projectProcessId: number;
  tags: FP.Entities.ITag[];
  closeFn: () => void;
}

class ManageProjectProcessTagFormModel {
  formModel: SingleFormModel;

  constructor(organisationId: number, projectId: number, projectProcessId: number, closeFn, tags: FP.Entities.ITag[]) {
    this.formModel = new SingleFormModel();
    const tagsProvider = TagsApi;
    let formFields = getProjectProcessTagFormFields(tagsProvider, organisationId, projectId, projectProcessId, tags);
    let actions: IUiAction<any>[] = [
      {
        id: "action1",
        label: I18n.t("phrases.done"),
        onAction: ev => {
          closeFn();
        },
        componentProps: {
          className: "ml-auto"
        },
        rendersIn: UiActionRenderers.BUTTON
      }
    ];

    this.formModel.formFields = formFields;
    this.formModel.actions = actions;
  }
}

export const ManageProjectProcessTagForm: React.FC<ManageProjectProcessTagProps> = props => {
  const { projectProcessId, closeFn, projectId, organisationId, tags } = props;

  const [model] = useState(
    () => new ManageProjectProcessTagFormModel(organisationId, projectId, projectProcessId, closeFn, tags)
  );

  return (
    <div>
      <SingleForm model={model.formModel} />
    </div>
  );
};
