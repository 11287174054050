import { action, makeObservable, observable } from "mobx";
import { BaseModel } from "../../../../../../../../core/util/BaseModel";
import ActionsApi, { ActionsApi as IActionsApi } from "../../../../../../../../services/api/v2/actions/Actions.api";

export class StakeholderOwnersSidebarModel extends BaseModel {
  actionsProvider: IActionsApi;
  @observable isLoading: boolean = true;
  @observable isSearchMode: boolean = false;
  @observable.ref projectStakeholders: FP.Entities.IProjectStakeholder[];
  @observable searchValue: string = "";
  organisationId: number;
  projectId: number;
  closeModalFn: () => void;
  actionId: number;

  constructor(closeModalFn: () => void, organisationId: number, projectId: number, actionId: number) {
    super();
    makeObservable(this);
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.actionsProvider = ActionsApi;
    this.isLoading = true;
    this.actionId = actionId;
    this.closeModalFn = closeModalFn;
  }

  onMount = () => {
    this.loadProjectStakeholders(this.actionId);
  };

  onUnmount = () => {
    this.resetSearch();
  };

  @action
  resetSearch = () => {
    this.searchValue = "";
    this.hideSearchMode();
  };

  @action
  hideSearchMode = () => {
    this.isSearchMode = false;
  };

  @action
  showSearchMode = () => {
    this.isSearchMode = true;
  };

  @action
  setProjectStakeholders = (stakeholders: FP.Entities.IProjectStakeholder[]) =>
    (this.projectStakeholders = stakeholders);

  @action
  loadProjectStakeholders = async (actionId: number) => {
    let res = await this.actionsProvider.getStakeholderOwnersByActionId(this.organisationId, this.projectId, actionId);
    if (!res || res.isError) return;

    this.setProjectStakeholders(res.payload);
    this.isLoading = false;
  };

  removeProjectStakeholder = async (stakeholderId: number) => {
    let res = await this.actionsProvider.removeActionStakeholderOwner(
      this.organisationId,
      this.projectId,
      this.actionId,
      stakeholderId
    );
    if (!res || res.isError) return;

    this.loadProjectStakeholders(this.actionId);
  };
}
