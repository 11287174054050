import { action, makeObservable, observable } from "mobx";
import { ButtonTypes } from "../../../../../../components/ui/Button";
import { Icon, IconSymbols } from "../../../../../../components/ui/Icon";
import { Panel } from "../../../../../../components/ui/Panel";
import I18n from "../../../../../../core/localization/I18n";
import ToasterService, { IToasterService } from "../../../../../../core/toaster/ToasterService";
import { TOASTER_TOAST_TIME } from "../../../../../../core/toaster/Toaster_model";
import { BaseModel } from "../../../../../../core/util/BaseModel";
import { EntityTypes } from "../../../../../../enums";
import TagsApi, { TagsApi as ITagsApi } from "../../../../../../services/api/v2/tags/Tags.api";
import { IQueryStringService } from "../../../../../../services/local/queryStringService/IQueryStringService";
import QueryStringService, {
  QUERY_STRING_PARAMS
} from "../../../../../../services/local/queryStringService/QueryStringService";
import { IModalContextModel } from "../../../../../../core/modalZ/context/IModalContext";
import ModalContext from "../../../../../../core/modalZ/context/ModalContext";

export class TagSettingsOverviewModalModel extends BaseModel {
  organisationId: number;
  tagProvider: ITagsApi;
  @observable tag: FP.Entities.ITag;
  tagId: number;
  isLoading: boolean = true;
  toasterService: IToasterService;
  modalService: IModalContextModel;
  queryStringService: IQueryStringService;
  prevUrl: string;

  constructor(organisationId: number, tagId: number) {
    super();
    makeObservable(this);
    this.organisationId = organisationId;
    this.tagId = tagId;

    this.toasterService = ToasterService;
    this.tagProvider = TagsApi;
    this.modalService = ModalContext;
    this.queryStringService = QueryStringService;
    this.prevUrl = this.queryStringService.getByKeyOrDefault(QUERY_STRING_PARAMS.PREV_RETURN_URL, "");
  }

  @action.bound
  async load() {
    await this.loadTag();
  }

  getUrl = (projectId: number, entityId: number, entityType: EntityTypes) =>
    this.queryStringService.getByKeyOrDefault(
      QUERY_STRING_PARAMS.RETURN_URL,
      `/organisations/${this.organisationId}/projects/${projectId}/${entityType}/${entityId}?${
        QUERY_STRING_PARAMS.RETURN_URL
      }=${encodeURIComponent(window.location.href.replace(appConfig.baseUrl, ""))}`
    );

  @action
  loadTag = async () => {
    this.isLoading = true;
    let res = await this.tagProvider.getDetailedAsync(this.organisationId, this.tagId);
    if (!res || res.isError) {
      this.toasterService.showErrorToast(TOASTER_TOAST_TIME.SLOW).setContent(I18n.t("errors.getTag"));
      return;
    }
    res.payload.actionTags.forEach(item => {
      item.url = this.getUrl(item.project.id, item.id, EntityTypes.ACTIONS);
    });

    res.payload.impactTags.forEach(item => {
      item.url = this.getUrl(item.project.id, item.id, EntityTypes.IMPACTS);
    });

    res.payload.projectStakeholderTags.forEach(item => {
      item.url = this.getUrl(item.project.id, item.stakeholderId, EntityTypes.STAKEHOLDERS);
    });

    this.setTag(res.payload);
    this.isLoading = false;
  };

  @action
  setTag = (tag: FP.Entities.ITag) => {
    this.tag = tag;
  };

  @action
  removeActionTag = async (actionId: number, projectId: number) => {
    let res = await this.tagProvider.removeActionTagAssoc(this.organisationId, projectId, this.tagId, actionId);
    if (!res || res.isError) return;

    this.loadTag();
  };

  @action
  removeImpactTag = async (impactId: number, projectId: number) => {
    let res = await this.tagProvider.removeImpactTagAssoc(this.organisationId, projectId, this.tagId, impactId);
    if (!res || res.isError) return;

    this.loadTag();
  };

  @action
  removeProjectStakeholderTag = async (pStakeholderId: number, projectId: number) => {
    let res = await this.tagProvider.removeProjectStakeholderTagAssoc(
      this.organisationId,
      projectId,
      this.tagId,
      pStakeholderId
    );
    if (!res || res.isError) return;

    this.loadTag();
  };

  showTagAssocConfirmDeleteModal = (tagAssoc: any, tagType: EntityTypes) => {
    return new Promise(resolve => {
      this.modalService.showConfirmDialog(
        <h1 className="mt-4">{I18n.t("phrases.confirm")}</h1>,
        <div className="container-fluid">
          <div className="row mb-3">
            <div className="col-12">
              <Icon symbol={IconSymbols.AlertCircle} className="mr-2" />
              {I18n.t("warnings.removeTagAssoc", { tagType: I18n.t(`entities.${tagType}`) })}
            </div>
          </div>
        </div>,
        I18n.t("phrases.yes"),
        I18n.t("phrases.no"),
        {
          wrapWidth: "small",
          spacing: "small",
          position: "middle",
          panelProps: {
            background: Panel.PanelBackgrounds.BG_WHITE
          }
        },
        async () => {
          if (tagType === "action") this.removeActionTag(tagAssoc.id, tagAssoc.project.id);
          if (tagType === "impact") this.removeImpactTag(tagAssoc.id, tagAssoc.project.id);
          if (tagType === "projectStakeholder") this.removeProjectStakeholderTag(tagAssoc.id, tagAssoc.project.id);
          this.modalService.hide();
          resolve(true);
        },
        () => {
          this.modalService.hide();
        },
        ButtonTypes.DANGER
      );
    });
  };
}
