import { IGridModel } from "../../../../../core/grids/IGridModel";
import { action, makeObservable, observable } from "mobx";
import I18n from "../../../../../core/localization/I18n";
import { GetStakeholderGridViewColumns } from "./UnassignedStakeholdersGrid_columns";

export class UnassignedStakeholdersGridModel implements IGridModel {
  projectId: number;
  @observable isLoading: boolean = true;
  organisationId: number;

  /**
   *
   */
  constructor(organisationId: number) {
    makeObservable(this);
    this.organisationId = organisationId;
  }

  getColumnConfig = () => {
    return GetStakeholderGridViewColumns({
      canEdit: false,
      organisationId: this.organisationId,
      projectId: this.projectId,
      isKeyStakeholdersOptions: [
        { key: "0", label: I18n.t("phrases.no") },
        { key: "1", label: I18n.t("phrases.yes") }
      ],
      impactLevels: [],
      pinned: null
    });
  };

  @action
  onMount = () => {
    this.isLoading = false;
  };
  onUnmount = () => {};
}
