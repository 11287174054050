import { Observer, observer } from "mobx-react";
import React, { useCallback, useState } from "react";
import { Async } from "react-async";
import { useParams } from "react-router-dom";
import { Button, ButtonTypes, LinkButton } from "../../../../components/ui/Button";
import { Icon, IconSymbols } from "../../../../components/ui/Icon";
import { Panel } from "../../../../components/ui/Panel";
import { PositionedSpinner } from "../../../../components/ui/PositionedSpinner";
import { CanEditOrg } from "../../../../contexts/permissions/PermissionHelpers";
import I18n from "../../../../core/localization/I18n";
import { Animations } from "../../../../core/util/Animations";
import { convertStakeholderToName } from "../../../../core/util/Helpers";
import { UiSizes } from "../../../../enums";
import { useCurrentOrganisationId } from "../../../../services/local/organisationContext/OrganisationContextModel";
import { SingleForm } from "../../forms/singleFormModel/SingleForm_view";
import { StakeholderDetails } from "../StakeholderDetails";
import { StakeholderViewModel } from "./View_model";
import { StakeholderAccordion } from "./accordionContent/StakeholderAccordion";

export interface StakeholderViewProps {
  model?: StakeholderViewModel;
}

export const StakeholderView: React.FunctionComponent<StakeholderViewProps> = observer(({ model: m }) => {
  const currentOrganisationId = useCurrentOrganisationId();
  const { stakeholderId } = useParams<{ stakeholderId: string }>();
  const [model] = useState(() => m || new StakeholderViewModel(currentOrganisationId));
  const loadStakeholder = useCallback(async () => {
    await Promise.all([model.loadStakeholder(+stakeholderId), model.loadProjectDetails(+stakeholderId)]);
  }, [model, stakeholderId]);
  const { stakeholder } = model;

  return (
    <Async promiseFn={loadStakeholder}>
      <Async.Loading>
        <PositionedSpinner />;
      </Async.Loading>
      <Async.Resolved>
        {() => (
          <Observer>
            {() => (
              <div className="data-extended-view pt-6">
                <div className="container-fluid data-extended-view__block data-extended-view__block--header mb-5">
                  <div className="row">
                    <div className="col">
                      <LinkButton size={UiSizes.MD} type={ButtonTypes.LINK} className="p-0" href={model.returnUrl}>
                        <Icon className="mr-2" symbol={IconSymbols.ChevronLeft} />
                        {I18n.t("phrases.back")}
                      </LinkButton>
                    </div>
                  </div>
                </div>
                <div className="data-extended-view__block data-extended-view__block--wrapper">
                  <div className={`data-extended-view__col container-fluid ${Animations.FADE_IN} speed-5`}>
                    <div className="row mb-4">
                      <div className="col">
                        <div className="d-flex">
                          <h2 className="mb-0">
                            {convertStakeholderToName(stakeholder)} | {I18n.t("phrases.stakeholderProfile")}
                          </h2>
                          {!model.isEditView && (
                            <CanEditOrg>
                              <Button
                                className="ml-auto"
                                type={ButtonTypes.OUTLINE_PRIMARY}
                                onClick={() => {
                                  model.setIsEditView(true);
                                }}
                              >
                                {" "}
                                <Icon symbol={IconSymbols.Pencil} size={UiSizes.SM} className="mr-2" />
                                {I18n.t("phrases.edit")}
                              </Button>
                            </CanEditOrg>
                          )}
                        </div>
                      </div>
                    </div>

                    {model.isEditView ? (
                      <div className="col-12">
                        <SingleForm model={model.formModel} />
                      </div>
                    ) : (
                      <StakeholderDetails item={stakeholder} />
                    )}
                  </div>
                  <Panel.Panel
                    background={Panel.PanelBackgrounds.BG_LIGHT}
                    className={`data-extended-view__col data-extended-view__col--tabs ${Animations.FADE_IN} speed-5`}
                  >
                    <div className="container-fluid">
                      <div className="row mt-5">
                        <div className="col">
                          <h1>{I18n.t("entities.projects")}</h1>
                        </div>
                      </div>
                    </div>

                    {model.projectDetails.map(e => {
                      return (
                        <StakeholderAccordion key={e.id} projectStakeholder={e} stakeholderId={model.stakeholderId} />
                      );
                    })}
                  </Panel.Panel>
                </div>
              </div>
            )}
          </Observer>
        )}
      </Async.Resolved>
    </Async>
  );
});
