import * as React from "react";
import { Tooltip } from "react-tippy";
import { B } from "../../../core/util/BootstrapHelper";

export interface ProgressBarWrapperProps {
  className?: string;
}

export const ProgressBarWrapper: React.FunctionComponent<ProgressBarWrapperProps & ReactProps> = props => {
  return <div className={`progress-bar-wrapper ${props.className || ""}`}>{props.children}</div>;
};

export interface ProgressBarProps {
  className?: string;
  type?: "primary" | "primary-warning" | "primary-danger" | "default";
  offset?: number;
  width: number;
}

export const ProgressBar: React.FunctionComponent<ProgressBarProps> = (props: any) => {
  let styles = {
    width: props.width < 0 ? "0%" : props.width > 100 ? "100%" : props.width + "%",
    left: props.offset < 0 ? "0%" : props.offset > 100 ? "100%" : props.offset + "%"
  };
  let cls: any = B()
    .bl("progress-bar")
    .pmod(props.type || "primary").bem;

  return <div className={cls} style={styles}></div>;
};
export interface ProgressBarPointProps {
  className?: string;
  type?: "primary" | "primary-warning" | "primary-danger" | "default";
  shape?: "line" | "empty-circle" | "circle";
  tooltipPosition?: "bottom" | "bottom-right" | "bottom-left";
  offset: number;
  tooltipContent?: React.ReactElement;
}

export const ProgressBarPoint: React.FunctionComponent<ProgressBarPointProps> = props => {
  let styles = {
    left: props.offset < 0 ? "0%" : props.offset > 100 ? "100%" : props.offset + "%"
  };
  let cls: any = B()
    .bl("progress-bar__point")
    .pmod(props.type || "primary")
    .pmod(props.shape || "line").bem;

  return (
    <div className={`${cls} ${props.className || ""}`} style={styles}>
      <Tooltip theme="light" html={props.tooltipContent}>
        <div className="progress-bar__point-placeholder"></div>
      </Tooltip>
    </div>
  );
};
