import { PositionedSpinner } from "@flightpath/coreui/dist/ui/PositionedSpinner";
import { observer } from "mobx-react";
import React from "react";
import { ActionBulkUpdateSidebarModel } from "./ActionBulkUpdateSidebar_model";
import { SingleForm } from "../../../../../../../change/forms/singleFormModel/SingleForm_view";

interface ActionBulkUpdateSidebarProps {
  model: ActionBulkUpdateSidebarModel;
}

export const BulkUpdateActionsSidebar: React.FC<ActionBulkUpdateSidebarProps> = observer(({ model }) => {
  if (model.isLoading) return <PositionedSpinner />;

  return (
    <div className="container-fluid p-3">
      <div className="col">
        <SingleForm model={model.formModel} />
      </div>
    </div>
  );
});
