import { AxiosInstance, AxiosRequestConfig } from "axios";
import Config from "../../../../config";
import http from "../Http";

export class AdminApi {
  version: string = Config.API.VERSION.__LATEST__;
  url: string = `/api/${this.version}/admin/`;
  http: AxiosInstance;
  constructor(http: AxiosInstance) {
    this.http = http;
  }

  updateActionRagStatus = async (config?: AxiosRequestConfig) => {
    let url = `${this.url}auto-action-rag-update`;

    let res = await this.http.put(url, config);
    return res.data;
  };
}

const instance = new AdminApi(http);
export default instance;
