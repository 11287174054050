import { ActionsUploader } from "../../../../../../../../pages/insight/projects/Actions/ActionsUploader/ActionsUploader_view";

export const ACTION_UPLOADER_MODAL_CONFIG = () => {
  return {
    showClose: true,
    title: <h1 className="mt-3 px-3">Upload Actions</h1>,
    content: (
      <>
        <img
          className="beta-ribbon-img"
          src="/images/assets/beta-ribbon.png"
          alt=""
          style={{
            height: 50,
            width: 50,
            position: "absolute",
            top: 0,
            left: 0
          }}
        />
        <ActionsUploader />
      </>
    )
  };
};
