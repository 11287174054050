import {
  HorizontalBarChart,
  HorizontalBarChartProps as HBCP,
  HorizontalBarChartDataItem as HBCDDI
} from "@flightpath/coreui/dist/widgets/horizontalBarChart";
import React from "react";
import I18n from "../../../core/localization/I18n";
import { Panel } from "../../ui/Panel";

export interface HorizontalBarChartProps extends HBCP {}

export interface HorizontalBarChartDataItem extends HBCDDI {}

export { HorizontalBarChart };

export const StakeholderHorizontalBarChartTooltip: React.FC<any> = props => {
  const { label, payload } = props;

  if (!payload[0]) return null;
  const item = payload[0].payload;

  return (
    <Panel.Panel
      background={Panel.PanelBackgrounds.BG_WHITE}
      hasShadow={true}
      style={{ width: "200px" }}
      hasBorderRadius={true}
      className="p-3"
    >
      <h3 className="mb-1">{label}</h3>
      <div>
        <small>{I18n.t("phrases.stakeholders")}</small>
        <small className="float-right">{item.entityOneValue}</small>
      </div>
      <div>
        <small>{I18n.t("phrases.audiences")}</small>
        <small className="float-right">{item.entityTwoValue}</small>
      </div>
    </Panel.Panel>
  );
};

export const RagStatusHorizontalBarChartTooltip: React.FC<any> = props => {
  const { label, payload } = props;

  if (!payload[0]) return null;
  const item = payload[0].payload;

  return (
    <Panel.Panel
      background={Panel.PanelBackgrounds.BG_WHITE}
      hasShadow={true}
      style={{ width: "200px" }}
      hasBorderRadius={true}
      className="p-3"
    >
      <h3 className="mb-0">
        {label} <small className="float-right">{item.entityOneValue}</small>
      </h3>
    </Panel.Panel>
  );
};
