import { action, makeObservable, observable } from "mobx";
import UserReportsApi, {
  UserReportsApi as IUserReportsApi
} from "../../../../services/api/v2/userReports/UserReports.api";

export class StakeholdersModel {
  @observable isLoading: boolean = true;
  @observable.ref stakeholders = [];
  organisationId: number;
  userProvider: IUserReportsApi;

  /**
   *
   */
  constructor(organisationId: number) {
    makeObservable(this);
    this.organisationId = organisationId;
    this.userProvider = UserReportsApi;
  }

  onMount = async () => {
    await this.loadStakeholders();
  };

  loadStakeholders = async () => {
    const res = await this.userProvider.getUserStakeholder(this.organisationId, {});
    if (!res) {
      return;
    }

    this.setStakeholders(res.payload);
  };

  @action
  setStakeholders = (stakeholders: any[]) => {
    this.stakeholders = stakeholders;
    this.isLoading = false;
  };
}
