import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { ProcessTemplateSelectionView } from "./ProcessTemplateSelectionView/ProcessTemplateSelectionView_view";
import { ProcessTemplateSelectionModel } from "./ProcessTemplateSelection_model";
import { L1ProcessSelectionView } from "./L1ProcessSelectionView/L1ProcessSelectionView_view";
import { L3ProcessSelectionGridView } from "./L3ProcessSelectionGridView/L3ProcessSelectionGridView_view";
import { useCurrentOrganisationId } from "../../../../../../services/local/organisationContext/OrganisationContextModel";
import { useFlightPathUser } from "../../../../../../core/auth_insight";
import { ProcessGridViewModel } from "../../../../../insight/projects/Processes/_grid/ProcessesGridView_model";

export interface IProcessTemplateSelectionProps {
  projectId: number;
  template: any;
  isCompactGrid: boolean;
  onAssignSuccess: any;
}

export const ProcessTemplateSelection: React.FC<ReactProps & IProcessTemplateSelectionProps> = observer(props => {
  const { projectId } = props;
  const organisationId = useCurrentOrganisationId();
  const authUser = useFlightPathUser();
  const [model, setModel] = useState(
    () => new ProcessTemplateSelectionModel(organisationId, projectId, authUser, null, props.onAssignSuccess)
  );
  const [gridModel] = useState(
    () =>
      new ProcessGridViewModel(authUser, organisationId, projectId, ["selector", "processL1", "processL2", "processL3"])
  );
  const templates = props.template;
  const [currStep, setCurrStep] = useState(0);
  const [templateId, setTemplateId] = useState(null);

  useEffect(() => {
    setModel(
      new ProcessTemplateSelectionModel(
        organisationId,
        projectId,
        authUser,
        templates[templateId],
        props.onAssignSuccess
      )
    );
    //eslint-disable-next-line
  }, [templateId]);

  const handleTemplateSelection = (selectedTemplateId: number) => {
    setTemplateId(selectedTemplateId);
    setCurrStep(currStep + 1);
  };

  const handleL1Complete = (selectedL1Processes: any) => {
    setCurrStep(currStep + 1);
    model.onMount(selectedL1Processes);

    model.onUnmount();
  };

  const handlePreviousStep = () => {
    if (currStep > 0) {
      setCurrStep(currStep - 1);
    }
  };

  return (
    <div className="container-fluid">
      {currStep === 0 && (
        <ProcessTemplateSelectionView
          handleNextStep={handleTemplateSelection}
          templates={templates}
        ></ProcessTemplateSelectionView>
      )}
      {currStep === 1 && (
        <L1ProcessSelectionView
          templateProcesses={templates[templateId].processes}
          handleNextStep={handleL1Complete}
          handlePreviousStep={handlePreviousStep}
        ></L1ProcessSelectionView>
      )}
      {currStep === 2 && (
        <L3ProcessSelectionGridView
          model={model}
          gridModel={gridModel}
          handlePreviousStep={handlePreviousStep}
          templateProcesses={templates[templateId].processes}
        ></L3ProcessSelectionGridView>
      )}
    </div>
  );
});
