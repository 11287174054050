import { action, observable } from "mobx";
import I18n from "../../../../../core/localization/I18n";
import { DisposableModel } from "../../../../../core/util/DisposableModel";

import ImpactGroupVisualisationsApi, {
  ImpactGroupVisualisationsApi as IImpactGroupVisualisationsApi
} from "../../../../../services/api/v2/impactGroupVisualisations/ImpactGroupVisualisations.api";
import ImpactVisualisationsApi, {
  ImpactVisualisationsApi as IImpactVisualisationsApi
} from "../../../../../services/api/v2/impactVisualisations/ImpactVisualisations.api";

export class ImpactSummaryModel extends DisposableModel {
  impactReportProvider: IImpactVisualisationsApi;
  @observable impactSummaryCardData: any;
  @observable mitigatedImpactSummaryCardData: any;
  @observable impactGroupSummaryCardData: any;
  organisationId: number;
  projectId: number;
  impactGroupVisualisationProvider: IImpactGroupVisualisationsApi;
  @observable impactWithoutActionsData: any;
  @observable impactWithoutDataData: any;
  @observable impactWithoutStakeholdersData: any;
  @observable impactPublishStatusData: any;

  /**
   *
   */
  constructor(organisationId: number, projectId: number) {
    super();
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.impactReportProvider = ImpactVisualisationsApi;
    this.impactGroupVisualisationProvider = ImpactGroupVisualisationsApi;
    this.loadData();
  }

  onMount = () => {};

  onUnmount = () => {
    this.dispose();
  };

  loadData = () => {};

  @action
  loadImpactSummary = async () => {
    let res = await this.impactReportProvider.getImpactSummary(this.organisationId, this.projectId);
    if (!res) return;
    let item = res.payload;
    item.title = I18n.t(item.title);
    item.items[0].label = I18n.t(item.items[0].label);
    item.items[1].label = I18n.t(item.items[1].label);
    item.items[2].label = I18n.t(item.items[2].label);
    this.impactSummaryCardData = res.payload;
  };

  @action
  loadMitigatedImpactSummary = async () => {
    let res = await this.impactReportProvider.getMitigatedImpactSummary(this.organisationId, this.projectId);
    if (!res) return;
    let item = res.payload;
    item.title = I18n.t(item.title);
    item.items[0].label = I18n.t(item.items[0].label);
    item.items[1].label = I18n.t(item.items[1].label);
    item.items[2].label = I18n.t(item.items[2].label);
    this.mitigatedImpactSummaryCardData = res.payload;
  };

  @action
  loadImpactGroupSummary = async () => {
    let res = await this.impactGroupVisualisationProvider.getImpactGroupSummary(this.organisationId, this.projectId);
    if (!res) return;
    let item = res.payload;
    item.title = I18n.t("table.highLevelImpacts");
    item.items[0].label = I18n.t(item.items[0].label);
    item.items[1].label = I18n.t(item.items[1].label);
    item.items[2].label = I18n.t(item.items[2].label);
    this.impactGroupSummaryCardData = res.payload;
  };

  @action
  loadImpactWithoutActionData = async () => {
    let res = await this.impactReportProvider.getImpactWithoutActionsSummary(this.organisationId, this.projectId);
    if (!res) return;
    this.impactWithoutActionsData = this.mapDataLabels(res.payload.data);
  };

  @action
  loadImpactWithoutDataData = async () => {
    let res = await this.impactReportProvider.getImpactMissingDataSummary(this.organisationId, this.projectId);
    if (!res) return;
    this.impactWithoutDataData = this.mapDataLabels(res.payload.data);
  };

  @action
  loadImpactWithStakeholdersData = async () => {
    let res = await this.impactReportProvider.getImpactMissingStaksholdersSummary(this.organisationId, this.projectId);
    if (!res) return;
    this.impactWithoutStakeholdersData = this.mapDataLabels(res.payload.data);
  };

  mapDataLabels = (data: any[]) => {
    let result = data.map(x => ({ name: I18n.t(x.key), value: x.value }));
    return result;
  };
}
