import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { Button, ButtonTypes } from "../../../../../../components/ui/Button";
import { SingleFormModel } from "../../../../../../pages/change/forms/singleFormModel/SingleForm_model";
import { SingleForm } from "../../../../../../pages/change/forms/singleFormModel/SingleForm_view";
import I18n from "../../../../../localization/I18n";
import { getEditRichTextFormFields } from "./EditRichTextSidebar_fields";

export const EditRichTextSidebar: React.FC<{
  hideModal: () => void;
  item: any;
  saveFn: (text: string) => Promise<any>;
  field: string;
  label: string;
  placeholder: string;
}> = observer(({ hideModal, item, saveFn, field, label, placeholder }) => {
  const [formModel] = useState(() => new SingleFormModel());
  useEffect(() => {
    formModel.onMount();
    formModel.formFields = getEditRichTextFormFields(item, field, label, placeholder);
  }, [formModel, item, field, label, placeholder]);

  return (
    <div className="container-fluid">
      <SingleForm model={formModel} />
      <div className="row">
        <div className="col justify-content-end d-flex">
          <Button
            className="mr-2"
            type={ButtonTypes.OUTLINE_PRIMARY}
            onClick={async e => {
              hideModal();
            }}
          >
            {I18n.t("phrases.cancel")}
          </Button>
          <Button
            onClick={async e => {
              let res = await formModel.submit();
              await saveFn(res[field]);
              hideModal();
            }}
          >
            {I18n.t("phrases.save")}
          </Button>
        </div>
      </div>
    </div>
  );
});
