import { action, makeObservable, observable } from "mobx";
import { ImpactGridModel } from "./grid/ImpactGrid_model";
import UserReportsApi, {
  UserReportsApi as IUserReportsApi
} from "../../../../services/api/v2/userReports/UserReports.api";
import { MapImpactLevelData, MapImpactProgressData } from "./Impacts_mapper";

export class ImpactModel {
  @observable isLoading: boolean = true;
  @observable.ref gridImpacts = [];
  organisationId: number;
  userReportProvider: IUserReportsApi;
  gridModel: ImpactGridModel;
  pieData: any;
  progressData: any[];
  /**
   *
   */
  constructor(organisationId: number, projectId: number, authUser: FP.Entities.IUser) {
    makeObservable(this);
    this.organisationId = organisationId;
    this.gridModel = new ImpactGridModel(this.organisationId, projectId, authUser);
    this.userReportProvider = UserReportsApi;
  }

  onMount = async () => {
    await this.loadImpacts();
  };

  onUnmount = () => {};

  onGridReady = () => {};

  loadImpacts = async () => {
    let res = await this.userReportProvider.getUserImpacts(this.organisationId);
    if (!res) return;

    this.setImpacts(res.payload);
  };

  @action
  setImpacts = (impacts: any[]) => {
    this.pieData = MapImpactLevelData(impacts);
    this.progressData = MapImpactProgressData(impacts);
    this.gridImpacts = impacts;
    this.isLoading = false;
  };
}
