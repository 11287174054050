import { LegendProps } from "@nivo/legends/dist/types/types";
import { ResponsivePie } from "@nivo/pie";
import React from "react";

interface IPieMargin {
  top: number;
  right: number;
  bottom: number;
  left: number;
}

interface IPieDataObject {
  id: string;
  label: string;
  value: number;
  color: string;
}

export interface PieChartProps {
  data: IPieDataObject[];
  margin?: IPieMargin;
  hasLegend?: boolean;
  translateLegendX?: number;
  translateLegendY?: number;
  hasLinkLabels?: boolean;
  colors?: string[];
  tooltipFn?: any;
  onClickFn?: (dataObject) => void;
  isAnimated?: boolean;
}

const getPieMargin = (margin: IPieMargin) => {
  const defaultMargin = { top: 0, right: 0, bottom: 0, left: 0 };
  if (!!margin) return margin;
  return defaultMargin;
};

const getTranslateX = translateX => {
  const defaultTranslateX = 0;
  if (translateX) return translateX;
  return defaultTranslateX;
};

const getTranslateY = translateY => {
  const defaultTranslateY = 0;
  if (translateY) return translateY;
  return defaultTranslateY;
};

const getLegend = (hasLegend, translateLegendX, translateLegendY) => {
  if (!hasLegend) return [];

  const legend = {} as LegendProps;

  legend.anchor = "center";
  legend.direction = "column";
  legend.justify = false;
  legend.translateX = getTranslateX(translateLegendX);
  legend.translateY = getTranslateY(translateLegendY);
  legend.itemsSpacing = 0;
  legend.itemWidth = 100;
  legend.itemHeight = 18;
  legend.itemTextColor = "#0c0c0c";
  legend.itemDirection = "left-to-right";
  legend.itemOpacity = 1;
  legend.symbolSize = 10;
  legend.symbolShape = "circle";

  return [legend];
};

const defaultColors = ["#f7820d", "#f9ab1e", "#1ac541", "#5df4ee", "#17a2b8"];

const getColors = (colors: string[]) => {
  if (colors) return colors;
  return defaultColors;
};

const animatedPie = (isAnimatedProp: boolean) => {
  let result = 4;
  if (isAnimatedProp !== undefined && !isAnimatedProp) result = 0;
  return result;
};

export const PieChart: React.FC<PieChartProps> = ({
  data,
  margin,
  hasLegend,
  translateLegendY,
  translateLegendX,
  hasLinkLabels,
  colors,
  tooltipFn,
  onClickFn,
  isAnimated
}) => {
  const isInteractive = !!tooltipFn || !!onClickFn;
  const showLinkLabels = !!hasLinkLabels;
  return (
    <div className="pie-container-core">
      <ResponsivePie
        onClick={(node, event) => {
          if (onClickFn) {
            onClickFn(node);
          }
        }}
        data={data}
        colors={getColors(colors)}
        margin={getPieMargin(margin)}
        innerRadius={0.6}
        enableArcLinkLabels={showLinkLabels}
        arcLinkLabel={"label"}
        arcLinkLabelsDiagonalLength={20}
        arcLinkLabelsStraightLength={0}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsThickness={0}
        enableArcLabels={false}
        arcLabelsSkipAngle={10}
        isInteractive={isInteractive}
        legends={getLegend(hasLegend, translateLegendX, translateLegendY)}
        tooltip={input => {
          if (tooltipFn) return tooltipFn(input.datum);
          return null;
        }}
        animate={true}
        activeOuterRadiusOffset={animatedPie(isAnimated)}
      />
    </div>
  );
};
