import { BaseModel } from "../../../../core/util/BaseModel";
import { Enums } from "../../../../enums";
import { observable, action, makeObservable } from "mobx";
import { SingleFormModel } from "../../forms/singleFormModel/SingleForm_model";
import { getStakeholderFormFields, getAudienceFormFields } from "../../forms/stakeholder/StakeholderFormSection_data";
import I18n from "../../../../core/localization/I18n";

import ProgressIndicatorModel, {
  ProgressIndicatorModel as IProgressIndicatorModel
} from "../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import { IToasterService } from "../../../../core/toaster/ToasterService";
import { ButtonTypes } from "../../../../components/ui/Button";
import { UiActionRenderers } from "../../../../core/uiAction/IUiAction";
import { IQueryStringService } from "../../../../services/local/queryStringService/IQueryStringService";
import QueryStringService, {
  QUERY_STRING_PARAMS
} from "../../../../services/local/queryStringService/QueryStringService";
import OrganisationsApi, {
  OrganisationsApi as IOrganisationsApi
} from "../../../../services/api/v2/organisations/Organisations.api";
import StakeholdersApi, {
  StakeholdersApi as IStakeholdersApi
} from "../../../../services/api/v2/stakeholders/Stakeholders.api";
import BusinessAreasApi, {
  BusinessAreasApi as IBusinessAreasApi
} from "../../../../services/api/v2/businessAreas/BusinessAreas.api";

export class StakeholderViewModel extends BaseModel {
  orgId: number;
  stakeholderId: number;
  stakeholdersProvider: IStakeholdersApi;
  formModel: SingleFormModel;
  organisationsProvider: IOrganisationsApi;
  businessAreaProvider: IBusinessAreasApi;
  httpProgress: IProgressIndicatorModel;
  toasterService: IToasterService;
  queryStringService: IQueryStringService;
  @observable isLoading: boolean = true;
  @observable isLoadingProjectDetails: boolean = true;
  @observable isEditView: boolean = false;
  @observable.ref stakeholder: FP.Entities.IStakeholder;
  @observable.ref projectDetails: FP.Entities.IProjectStakeholder[] = [];
  returnUrl: string;

  constructor(organisationId: number) {
    super();
    makeObservable(this);
    this.organisationsProvider = OrganisationsApi;
    this.businessAreaProvider = BusinessAreasApi;
    this.orgId = organisationId;
    this.httpProgress = ProgressIndicatorModel;
    this.stakeholdersProvider = StakeholdersApi;
    this.formModel = new SingleFormModel();
    this.queryStringService = QueryStringService;
    const prevUrl = this.queryStringService.getByKeyOrDefault(QUERY_STRING_PARAMS.PREV_RETURN_URL, "");
    this.returnUrl = this.queryStringService.getByKeyOrDefault(
      QUERY_STRING_PARAMS.RETURN_URL,
      `/organisations/${this.orgId}/settings/stakeholders${
        prevUrl ? "?" + QUERY_STRING_PARAMS.RETURN_URL + "=" + encodeURIComponent(prevUrl) : ""
      }`
    );
  }

  @action
  loadStakeholder = async (stakeholderId: number) => {
    this.stakeholderId = stakeholderId;
    const res = await this.stakeholdersProvider.getDetailedById(this.orgId, stakeholderId);

    if (!res || res.isError) return;

    this.setStakeholder(res.payload);
  };

  loadProjectDetails = async (stakeholderId: number) => {
    this.stakeholderId = stakeholderId;
    const res = await this.stakeholdersProvider.getDetailedProjectsById(this.orgId, stakeholderId);
    if (!res || res.isError) return;

    this.setProjectDetails(res.payload);
  };

  @action
  setProjectDetails = (projects: FP.Entities.IProjectStakeholder[]) => {
    this.projectDetails = projects;
  };

  @action
  setStakeholder = (st: FP.Entities.IStakeholder) => {
    this.setForm(st);
    this.stakeholder = st;
  };

  setForm = (st: FP.Entities.IStakeholder) => {
    let fields =
      st.stakeholderType === Enums.StakeholderType.INDIVIDUAL
        ? getStakeholderFormFields(
            this.orgId,
            this.organisationsProvider,
            this.businessAreaProvider,
            this.stakeholdersProvider,
            st
          )
        : getAudienceFormFields(this.orgId, this.organisationsProvider, st);

    this.formModel.formFields = fields;
    this.formModel.actions = [
      {
        id: "cancel",
        label: I18n.t("phrases.cancel"),
        onAction: async ev => {
          this.setIsEditView(false);
        },
        componentProps: {
          className: "ml-auto",
          type: ButtonTypes.LINK
        },
        rendersIn: UiActionRenderers.BUTTON
      },
      {
        id: "create",
        label: I18n.t("phrases.save"),
        onAction: async ev => {
          const formRes = await this.formModel.submit();
          if (!formRes) return;

          if (st.stakeholderType === Enums.StakeholderType.INDIVIDUAL) {
            formRes.audienceCount = -1;
          } else {
            formRes.lastName = formRes.firstName + "-last-name";
            formRes.email = Date.now() + "@example.com";
            formRes.isChange = false;
          }

          this.httpProgress.showOverlay();
          await this.stakeholdersProvider.update(this.orgId, st.id, formRes as FP.Entities.IStakeholder);
          this.httpProgress.hideOverlay();

          this.setIsEditView(false);
          this.loadStakeholder(this.stakeholderId);
        },
        componentProps: {
          className: "ml-2"
        },
        rendersIn: UiActionRenderers.BUTTON
      }
    ];
  };

  @action.bound
  setIsEditView(val: boolean) {
    this.isEditView = val;
    if (!val) {
      this.setForm(this.stakeholder);
    }
  }
}
