import _ from "lodash";
import React from "react";
import { IconSymbols } from "../../../../../components/ui/Icon";
import { AutocompleteOption } from "../../../../../components/ui/_forms/Autocomplete/AutocompleteOption";
import { FORM_COL, REMOVE_UNSAFE_CHARACTERS } from "../../../../../constants";
import { IMultiSelectorModel } from "../../../../../core/forms/controls/multiSelector/IMultiSelectorModel";
import { INIT_MULTISELECTOR } from "../../../../../core/forms/controls/multiSelector/MultiSelector_model";
import { generateFormFieldsFromJson } from "../../../../../core/forms/helpers/FormFieldMappers";
import { filterOutExistingStRolesVals } from "../../../../../core/grids/GridHelpers";
import I18n from "../../../../../core/localization/I18n";
import { ChangeRolesApi } from "../../../../../services/api/v2/changeRoles/ChangeRoles.api";

export const getChangeRoleFormFields = (
  changeRoleProvider: ChangeRolesApi,
  organisationId: number,
  projectId: number,
  impactId: number,
  projectStakeholder: FP.Entities.IProjectStakeholder,
  onRoleAdded
) => {
  const removeTag = async (tag: FP.Entities.IChangeRole) => {
    let res = await changeRoleProvider.removeImpactChangeRoleAssoc(
      organisationId,
      projectId,
      tag.id,
      impactId,
      (projectStakeholder as any).impactProjectStakeholderId
    );
    if (!res) return;
  };

  const addTag = async (changeRole: any) => {
    if (!changeRole.text) return;
    let res = await changeRoleProvider.addImpactChangeRoleAssoc(
      organisationId,
      projectId,
      impactId,
      projectStakeholder.id,
      {
        id: changeRole.id,
        slug: _.kebabCase(changeRole.text),
        name: changeRole.text
      }
    );
    if (!res) return;
    onRoleAdded();
  };

  const tags: Partial<IMultiSelectorModel> = {
    ...INIT_MULTISELECTOR,
    key: "changeRoles",
    placeholder: I18n.t("placeholders.searchChangeRole"),
    optionElement: <AutocompleteOption key={"e"} className={"autocomplete__chip"} label={(e: any) => e.text} />,
    onItemSelected: e => addTag(e as any),
    onFocus: async function () {
      const self: IMultiSelectorModel = this;
      const res = await changeRoleProvider.getAllAsync(organisationId);

      if (res?.payload) {
        const currentValues = self.extractValue();
        let sortedTags = _.orderBy(res.payload, [tag => tag.name.toLowerCase()]);
        let s = filterOutExistingStRolesVals(currentValues, sortedTags);
        self.setOptions(_.map(s, e => ({ id: e.id, text: e.name })));
      }
    },
    componentProps: {
      icon: IconSymbols.TagFilled,
      borderStyle: "underline"
    },
    searchAttribute: "text",
    fieldClassName: FORM_COL.FULL_WIDTH,
    extractValue: function () {
      return this.selectedItems.map(e => REMOVE_UNSAFE_CHARACTERS(e.text));
    },
    value: projectStakeholder.changeRoles.map(e => ({ id: e.id, text: e.name })),
    isHidden: false,
    valueLabelFn: e => e?.text,
    allowFreeText: true,
    removeItemFn: (item, idx) => {
      removeTag(item);
    },
    filterFn: (items, query) => {
      if (!query) return items;
      return _.filter(items, e => (e.text as string).toLowerCase().includes(query.toLowerCase()));
    },
    isTagSelector: true,
    isNewFn: e => e.id === 0,
    noResultsFoundLabel: I18n.t("forms.tagsResultsNotFound"),
    searchResultHint: <p className="mb-0 pl-3 pb-1 pt-2">{I18n.t("forms.tagsSearchResultHint")}</p>
  };

  const fields = [];

  fields.push(tags);
  const models = generateFormFieldsFromJson(fields);
  return models;
};
