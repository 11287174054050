import React, { useEffect } from "react";
import { ImpactGroupModalModel } from "./ImpactGroupModal_model";
import { observer } from "mobx-react";
import { ImpactGroupDisplay } from "../../../impactGroup/ImpactGroupDisplay";
import { ImpactCard } from "../../../impacts/impactCard/ImpactCard_view";
import I18n from "../../../../../core/localization/I18n";
import { Link } from "react-router-dom";
import { PositionedSpinner } from "../../../../../components/ui/PositionedSpinner";
import { useCurrentOrganisationId } from "../../../../../services/local/organisationContext/OrganisationContextModel";
import { Hr } from "../../../../../components/ui/Hr";
import { Async } from "react-async";
import { MicroCreateImpactView } from "./MicroGroupImpactForm_view";
import { QUERY_STRING_PARAMS } from "../../../../../services/local/queryStringService/QueryStringService";

export interface ImpactGroupModalProps {
  model: ImpactGroupModalModel;
}

const ImpactGroupModal: React.FunctionComponent<ImpactGroupModalProps> = observer(({ model }) => {
  const organisationId = useCurrentOrganisationId();

  useEffect(() => {
    model.onMount();
  }, [model]);

  if (model.isLoading) {
    return <PositionedSpinner />;
  }
  return (
    <div className="container-fluid impact-compact-view">
      <div className="row mb-5">
        <div className="col">
          <h1 className="mb-0">{model.impactGroup.name}</h1>
          <h3 className="text-uppercase mb-0">{model.impactGroup.refNumber}</h3>
        </div>
      </div>
      <ImpactGroupDisplay item={model.impactGroup} />
      <div className="row">
        <div className="col-12">
          <Hr className="mb-0 mt-1" />
        </div>
      </div>
      <div className="col-12">
        <div className="row pl-3 pt-5">
          <h5>{I18n.t("phrases.quickCreateImpact").toUpperCase()}</h5>
        </div>
        <div className="row pl-3"></div>
      </div>

      <div className="row mb-2">
        <div className="col mx-3">
          <MicroCreateImpactView
            projectId={model.projectId}
            onImpactCreated={model.onImpactCreated}
            impactGroupId={model.impactGroupId}
          />
        </div>
      </div>

      <div className="row">
        <div className="col">
          <Hr className="mt-3 mb-0" />
        </div>
      </div>

      <div className="row mt-5">
        <div className="col mx-1">
          <h5 className="text-uppercase mb-3">{I18n.t("phrases.associatedDetailedImpacts")}</h5>
          <Async promiseFn={model.loadImpactGroupImpacts}>
            <Async.Loading>
              <PositionedSpinner />
            </Async.Loading>
            <Async.Resolved>
              {model.impactGroupImpacts?.length ? (
                model.impactGroupImpacts.map(impact => (
                  <Link
                    key={impact.id}
                    to={`/organisations/${organisationId}/projects/${model.projectId}/impacts/${impact.id}?${
                      QUERY_STRING_PARAMS.RETURN_URL
                    }=${encodeURIComponent(window.location.href.replace(appConfig.baseUrl, ""))}`}
                    onClick={model.modalService.hide}
                  >
                    <ImpactCard item={impact} />
                  </Link>
                ))
              ) : (
                <p>{I18n.t("phrases.noImpacts")}</p>
              )}
            </Async.Resolved>
          </Async>
        </div>
      </div>
    </div>
  );
});

export { ImpactGroupModal };
