import { AxiosInstance, AxiosRequestConfig } from "axios";
import Config from "../../../config";
import { IFlightPathApiResponse } from "./BaseApiModel";

export interface IBaseProjectApiModel<T> {
  version: string;
  controller: string;
  http: AxiosInstance;
  url: string;

  getById: (
    organisationId: number,
    projectId: number,
    id: number,
    config?: AxiosRequestConfig
  ) => Promise<IFlightPathApiResponse<T>>;

  // findAll: (organisationId: number, config?: AxiosRequestConfig) => Promise<IFlightPathApiResponse<T[]>>;

  remove: (
    organisationId: number,
    projectId: number,
    id: number,
    config?: AxiosRequestConfig
  ) => Promise<IFlightPathApiResponse<void>>;

  create: (
    organisationId: number,
    projectId: number,
    data: T,
    config?: AxiosRequestConfig
  ) => Promise<IFlightPathApiResponse<T>>;

  update: (
    organisationId: number,
    projectId: number,
    id: number,
    data: T,
    config?: AxiosRequestConfig
  ) => Promise<IFlightPathApiResponse<T>>;
}

export abstract class BaseProjectApiModel<T> implements IBaseProjectApiModel<T> {
  version: string = Config.API.VERSION.__LATEST__;
  controller: string;
  http: AxiosInstance;
  url: string = `/api/${this.version}/organisations`;

  constructor(http: AxiosInstance, controller: string) {
    this.http = http;
    this.controller = controller;
  }

  getById = async (organisationId: number, projectId: number, id: number, config?: AxiosRequestConfig) => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${id}`;
    let res = await this.http.get(url, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<T>;
    }
  };

  remove = async (organisationId: number, projectId: number, id: number, config?: AxiosRequestConfig) => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${id}`;
    let res = await this.http.delete(url, config);
    if (res && res.status === 200) {
      return res.data;
    }
  };

  create = async (organisationId: number, projectId: number, data: T, config?: AxiosRequestConfig) => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}`;
    let res = await this.http.post(url, data, config);
    if (res && res.status === 200) {
      return res.data;
    }
  };

  update = async (organisationId: number, projectId: number, id: number, data: T, config?: AxiosRequestConfig) => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${id}`;
    let res = await this.http.put(url, data, config);
    if (res && res.status === 200) {
      return res.data;
    }
  };
}
