import * as React from "react";
import { ActionCompactViewModel } from "./ActionCompactView_model";
import { RagStatus } from "../../../../components/ui/RagStatus";
import { ActionDisplay } from "../ActionDisplay";
import I18n from "../../../../core/localization/I18n";
import { ImpactCard } from "../../impacts/impactCard/ImpactCard_view";
import { Link } from "react-router-dom";
import { PositionedSpinner } from "../../../../components/ui/PositionedSpinner";
import { observer } from "mobx-react";
import { useCurrentOrganisationId } from "../../../../services/local/organisationContext/OrganisationContextModel";
import { Enums, ProgressStatus as PS, Translator } from "../../../../enums";
import { Pill, PillTypes } from "../../../../components/ui/Pill";
import { PanelTypes } from "@flightpath/coreui/dist/ui/Panel";
import { Panel } from "../../../../components/ui/Panel";
import { Hr } from "../../../../components/ui/Hr";
import { ProgressStatus } from "../../../../components/ui/ProgressStatus";
import { QUERY_STRING_PARAMS } from "../../../../services/local/queryStringService/QueryStringService";

export interface ActionCompactViewProps {
  model: ActionCompactViewModel;
}

const ActionCompactView: React.FunctionComponent<ActionCompactViewProps> = observer(({ model }) => {
  const { actionObject } = model;
  const organisationId = useCurrentOrganisationId();
  React.useEffect(() => {
    model.onMount();
    return model.onUnmount;
  }, [model]);

  if (model.isLoading) {
    return <PositionedSpinner />;
  }
  let pillType = actionObject?.progressStatus === PS.NOT_STARTED ? PillTypes.LIGHT_GRAY : PillTypes.GRAY;
  const coreUrl = `/organisations/${organisationId}/projects/${model.projectId}`;
  return (
    <div className="action-compact-view container-fluid mt-4">
      <div className="row mb-5">
        <div className="col">
          <div className="d-flex justify-content-between align-items-center">
            <h1 className="mb-0">{actionObject.name}</h1>
            <Pill type={pillType} className="float-right">
              {Translator.ProgressStatus(actionObject.progressStatus)}
            </Pill>
          </div>
          <h3 className="text-uppercase mb-0 d-flex align-items-center">
            <span className="mr-2">{actionObject.refNumber}</span>
            {actionObject.progressStatus === Enums.ProgressStatus.COMPLETED ? (
              <ProgressStatus state={actionObject.progressStatus} />
            ) : (
              <RagStatus state={actionObject.ragStatus} />
            )}
          </h3>
        </div>
      </div>

      <ActionDisplay item={actionObject} parentModel={model} />

      <div className="row">
        <div className="col-12">
          <Hr className="mb-0 mt-0" />
        </div>
      </div>

      <div className="row pb-4 mt-5">
        <div className="col">
          <h3>{I18n.t("phrases.relatedImpacts")}</h3>
          {model.isLoading ? (
            <PositionedSpinner />
          ) : actionObject.impacts?.length ? (
            actionObject.impacts?.map((impact: FP.Entities.IImpact) => {
              return (
                <Link
                  key={impact.id}
                  to={`${coreUrl}/impacts/${impact.id}?${QUERY_STRING_PARAMS.RETURN_URL}=${encodeURIComponent(
                    `${coreUrl}/actions`
                  )}`}
                  onClick={model.modalService.hide}
                >
                  <ImpactCard key={impact.id} item={impact} />
                </Link>
              );
            })
          ) : (
            <Panel.Panel hasBorderRadius={true} type={PanelTypes.OUTLINES} className="py-2 text-center">
              <div>{I18n.t("phrases.noActionImpacts")}</div>
            </Panel.Panel>
          )}
        </div>
      </div>
    </div>
  );
});

export { ActionCompactView };
