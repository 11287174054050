import { Colours } from "@flightpath/coreui/dist/ui/hr";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { Hr } from "../../../../../../components/ui/Hr";
import { NewFileCard } from "./NewFileCard";
import { UploadFileCard } from "./UploadedFileCard";
import { UploadFileListModel } from "./UploadFileList_model";
import React from "react";
import { useFlightPathUser } from "../../../../../../core/auth_insight";

export interface IUploadFileListProps {
  organisationId: number;
  selectedFileId: number;
  onClickFn?: (id: number) => void;
}

export const UploadFileList: React.FC<IUploadFileListProps> = observer((props: IUploadFileListProps) => {
  const authUser = useFlightPathUser();
  const [model, setModel] = useState(() => new UploadFileListModel(props.organisationId, authUser));

  useEffect(() => {
    model.onMount();
  }, [model]);

  useEffect(() => {
    if (model.organisationId !== props.organisationId) {
      model.stopConnection().then(() => {
        setModel(new UploadFileListModel(props.organisationId, authUser));
      });
    }
  }, [authUser, model, model.organisationId, props.organisationId]);

  return (
    <div>
      <h2 className="mt-2">Uploaded Files</h2>
      <NewFileCard onClickFn={() => props.onClickFn(0)} selectedFileId={props.selectedFileId} key="NewFileUpload" />
      <Hr color={Colours.GRAY_400} className="mt-2 mb-2" />
      {!model.isLoading &&
        model.stakeholderUploadFiles.map((x: FP.Entities.IStakeholderUploadFile, i) => (
          <UploadFileCard
            stakeholderUploadFile={x}
            onClickFn={() => props.onClickFn(x.id)}
            selectedFileId={props.selectedFileId}
            key={i}
          />
        ))}
    </div>
  );
});
