import { PanelProps } from "@flightpath/coreui/dist/ui/Panel";
import * as React from "react";
import { Panel } from "../../components/ui/Panel";
import { Animations } from "../util/Animations";
import { B } from "../util/BootstrapHelper";

export interface ModalProps extends ReactProps {
  /**
   * Function to be called when modal is closed
   */
  close?: () => void;

  /**
   * Component parent element classname
   */
  className?: string;

  /**
   * Position of the modal box
   */
  position?: "top" | "bottom" | "left" | "right" | "middle";

  /**
   * Sets the padding between the modal box and the modal content
   */
  spacing?: "none" | "small" | "medium" | "large";

  /**
   * Height of the modal box
   */
  wrapHeight?: "default" | "full" | "small" | "medium" | "large";

  /**
   * Width of the modal box
   */
  wrapWidth?: "default" | "full" | "extra-small" | "small" | "medium" | "large";

  hasBorderRadius?: boolean;

  overflow?: "visible";

  disableBackdrop?: boolean;

  panelProps?: PanelProps;

  onClose?: () => void;
  wrapperAnimationClassName?: string;

  contentClassName?: string;
}

export interface ModalState {}

export class Modal extends React.Component<ModalProps, ModalState> {
  backdropEl: HTMLDivElement | any;
  contentWrapperEl: HTMLDivElement | any;

  static defaultProps: Partial<ModalProps> = {
    className: "",
    position: "middle",
    close: () => {},
    spacing: "none",
    hasBorderRadius: false,
    wrapHeight: "default",
    wrapWidth: "default",
    disableBackdrop: false,
    wrapperAnimationClassName: "fadeIn"
  };

  close = (ev?: React.SyntheticEvent<any>) => {
    this.props.onClose();
  };

  render() {
    let clz = this.props.className;
    let bem = B().bl("modal");
    let clas = bem.pmod(this.props.position).bem;
    let clsWrapper = bem
      .el("wrapper")
      .pmod(this.props.wrapHeight + "-height")
      .pmod(this.props.overflow ? `overflow-${this.props.overflow}` : ``)
      .pmod(this.props.wrapWidth + "-width")
      .pmod(this.props.hasBorderRadius ? "border-radius" : "")
      .pmod("spacing-" + this.props.spacing).bem;
    return (
      <div className={`${clz} ${clas}`}>
        <div
          className={`${bem.el("backdrop").bem} ${Animations.FADE_IN} speed-3`}
          onClick={!this.props.disableBackdrop ? this.close : undefined}
          id="ModalBackdrop"
          ref={e => (this.backdropEl = e)}
        />
        <div className={`${clsWrapper} ${this.props.wrapperAnimationClassName}`} ref={e => (this.contentWrapperEl = e)}>
          <Panel.Panel
            {...this.props.panelProps}
            className={`${(this.props.panelProps && this.props.panelProps.className) || ""} ${
              bem.el("wrapper-content").bem
            }`}
          >
            {this.props.children}
          </Panel.Panel>
        </div>
      </div>
    );
  }
}

const ModalHeader: React.FunctionComponent<React.HTMLProps<HTMLDivElement>> = props => {
  return (
    <div {...props} className={`modal__block modal__block--header container-fluid ${props.className || ""}`}>
      <div className="row">
        <div className="col">{props.children}</div>
      </div>
    </div>
  );
};

const ModalFooter: React.FunctionComponent<React.HTMLProps<HTMLDivElement>> = props => {
  return (
    <div {...props} className={`modal__block modal__block--footer ${props.className || ""}`}>
      {props.children}
    </div>
  );
};

const ModalContent: React.FunctionComponent<React.HTMLProps<HTMLDivElement>> = props => {
  return (
    <div {...props} className={`modal__block modal__block--content ${props.className || ""}`}>
      {props.children}
    </div>
  );
};

export { ModalHeader, ModalContent, ModalFooter };
