import { action, makeObservable, observable } from "mobx";
import { BarChartProps } from "../../../../../../../core/charts/BarChart/BarChart";

export class ActionProgressStatusRagStatusChartModel {
  organisationId: number;
  @observable isLoading: boolean = true;
  projectId: number;
  loadDataFn: (organisationId: number, projectId: number, selectedItemIds: number[]) => Promise<any>;
  @observable chartData: BarChartProps;
  transformer: (data: any[]) => BarChartProps;
  @observable selectedItemIds: number[];

  /**
   *
   */
  constructor(
    organisationId: number,
    projectId: number,
    loadDataFn: (organisationId: number, projectId: number, selectedItemIds: number[]) => Promise<any>,
    transformer: (data: any[]) => BarChartProps,
    selectedItemIds: number[]
  ) {
    makeObservable(this);
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.selectedItemIds = selectedItemIds;
    this.loadDataFn = loadDataFn;
    this.transformer = transformer;
  }

  onMount = () => {
    this.loadChartData();
  };

  loadChartData = async () => {
    let res = await this.loadDataFn(this.organisationId, this.projectId, this.selectedItemIds);
    this.setChartData(res.payload);
  };

  @action
  setChartData = (data: any[]) => {
    this.chartData = this.transformer(data);
    this.isLoading = false;
  };
}
