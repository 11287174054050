import { action, makeObservable, observable } from "mobx";
import PermissionsContext from "../../../../../../../contexts/permissions/PermissionsContext";
import { PermissionFields } from "../../../../../../../contexts/permissions/PermissionsTypes";
import { ITreeDataItem } from "../../../../../../../core/forms/controls/searchTreePicker/ISearchTreePickerModel";

import { BaseModel } from "../../../../../../../core/util/BaseModel";
import { SingleFormModel } from "../../../../../../change/forms/singleFormModel/SingleForm_model";
import {
  getProjectProcessesTreeSidebarFormActions,
  getProjectProcessesTreeSidebarFormFields
} from "./ProjectProcessesTreeSidebar_fields";
import ProjectProcessesApi, {
  ProjectProcessesApi as IProjectProcessesApi
} from "../../../../../../../services/api/v2/projectProcesses/ProjectProcesses.api";

export class ProjectProcessesTreeSidebarModel extends BaseModel {
  projectProcessesProvider: IProjectProcessesApi;
  @observable isLoading: boolean = true;
  organisationId: number;
  projectId: number;
  @observable.ref projectProcesses: ITreeDataItem[];
  formModel: SingleFormModel;
  canEdit: boolean;
  updateProjectProcessesFN: (projectProcesses: number[]) => void;
  @observable selectedItemIds: number[];

  constructor(
    organisationId: number,
    projectId: number,
    selectedItemIds: number[],
    updateProjectProcessesFN: (projectProcesses: number[]) => void
  ) {
    super();
    makeObservable(this);
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.projectProcessesProvider = ProjectProcessesApi;
    this.selectedItemIds = selectedItemIds;
    this.loadSidebar();
    this.updateProjectProcessesFN = updateProjectProcessesFN;
    this.canEdit = PermissionsContext.canEditField(PermissionFields.IMPACTS, this.organisationId, this.projectId);
  }

  @action
  getProjectProcesses = async () => {
    const res = await this.projectProcessesProvider.getProjectProcessesTree(this.organisationId, this.projectId);

    if (!!res.isError) return null;

    this.setProjectProcesses(res.payload);
  };

  @action
  setProjectProcesses = projectProcesses => {
    this.projectProcesses = projectProcesses.map(e => {
      return { ...e, label: e.name };
    });
  };

  @action
  loadSidebar = async () => {
    this.isLoading = true;
    await this.getProjectProcesses();
    this.loadForm();
    this.isLoading = false;
  };

  loadForm = () => {
    this.formModel = new SingleFormModel();
    this.formModel.formFields = getProjectProcessesTreeSidebarFormFields(this.projectProcesses, this.selectedItemIds);
    this.formModel.setActions(getProjectProcessesTreeSidebarFormActions(this.updateProjectProcesses, this.canEdit));
  };

  updateProjectProcesses = async () => {
    if (!this.canEdit) return;
    this.formModel.isSaving = true;
    let res = await this.formModel.submit();
    this.updateProjectProcessesFN(res.projectProcesses);
  };
}
