import React from 'react'
import I18n from '../../../core/localization/I18n';
import { ModalProps } from '../Modal';
import { Panel } from '../Panel';
import { RichTextEditor } from '../_forms/RichTextEditor';

export const ReviewModalTitle = () => {
    return <h1 className="mt-3">
        {I18n.t("phrases.review")}
    </h1>
}

export const SaveAndReviewModalTitle = () => {
    return <h1 className="mt-3">
        {I18n.t("phrases.saveAndReview")}
    </h1>
}

export const ReviewModalContent = (props) => {
    let { reviewCommentInput } = props;
    return <div className="container-fluid">
        <div className="row mb-2">
            <div className="col-12">
                <h4 className="mb-0">{I18n.t("phrases.reviewModalQuestion")}</h4></div>
        </div>
        <div className="row">
            <div className="col mark-reviewed__rte">
                <RichTextEditor
                    placeholderText={I18n.t("phrases.reviewPlaceholderText")}
                    value={reviewCommentInput}
                    onChange={props.handler}
                /></div>
        </div>
    </div>
}

export const ReviewModalComponentProps: ModalProps = {
    wrapWidth: "small",
    spacing: "small",
    position: "middle",
    className: "confirmation-modal",
    hasBorderRadius: true,
    panelProps: {
        background: Panel.PanelBackgrounds.BG_WHITE
    }
}

export const ReviewToastContent = (props) => {
    const { itemName } = props;

    return <>
        <span className="toaster__success-review-text">
            {I18n.t("phrases.reviewToasterText")}
            <span className="body-bold"> {itemName}</span>
        </span>
    </>

}
