import { action, makeObservable, observable } from "mobx";
import { observer } from "mobx-react-lite";
import { Button, ButtonTypes } from "../../../components/ui/Button";
import I18n from "../../localization/I18n";
import { SHOW_IMPACT_TYPES_MODAL } from "../../modalZ/common/ImpactTypesView/ImpactTypesView_modal";
import { IBaseReportFilterAddon, ReportFilterAddonsKeys } from "../ReportFilterAddon";
import { ReportFilterModel } from "../ReportFilter_model";

export class ImpactTypeReportFilterAddon implements IBaseReportFilterAddon {
  key: string = ReportFilterAddonsKeys.ImpactType;
  @observable selectedIds: number[] = [];
  reportFilterModel: ReportFilterModel;

  constructor(reportFilterModel: ReportFilterModel, currentFilter: any) {
    makeObservable(this);
    this.setSelectedIds(currentFilter || []);
    this.reportFilterModel = reportFilterModel;
  }

  clearSelectedIds = () => {
    this.setSelectedIds([]);
  };

  render = () => {
    return <ButtonArea key={this.key} model={this} />;
  };

  displayModal = () => {
    SHOW_IMPACT_TYPES_MODAL(this.getQueryJSON, this.selectedIds);
  };

  getQueryJSON = (impactTypeIds: number[]) => {
    this.setSelectedIds(impactTypeIds);
    this.reportFilterModel.generateQueryJson();
    return {
      [this.key]: this.selectedIds
    };
  };

  getSelectedIds = () => {
    return this.selectedIds;
  };

  @action
  setSelectedIds = (selectedIds: number[]) => (this.selectedIds = selectedIds);
}

const ButtonArea: React.FC<{ model: ImpactTypeReportFilterAddon }> = observer(({ model }) => {
  return (
    <Button key={model.key} className="px-2" type={ButtonTypes.LINK} onClick={model.displayModal}>
      {I18n.t("entities.impactTypes") + " "}
      {model.selectedIds.length > 0 ? (model.selectedIds.length < 10 ? `(${model.selectedIds.length})` : "9+") : ""}
    </Button>
  );
});
