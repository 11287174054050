import { ICellRendererParams, ValueGetterParams, ValueSetterParams } from "ag-grid-community";
import { RAG_STATUS_COLUMN_CONFIG } from "./RagStatusColumn_config";
import { BaseColumnBuilder } from "../../../../columns/baseColumn/BaseColumn_builder";
import { ISdColDef } from "../../../../columns/baseColumn/BaseColumnTypes";
import { RAGStatusEditor } from "../../../../../../editors/editorRAGStatus";

interface IRagStatusColumnBuilder {}

export class RagStatusColumnBuilder extends BaseColumnBuilder implements IRagStatusColumnBuilder {
  constructor(columnOptions?: ISdColDef<any, any>) {
    super({ ...RAG_STATUS_COLUMN_CONFIG(), ...columnOptions });
  }

  withCellEditor = () => {
    return this.setColumnOptions({
      cellEditorSelector: () => ({ component: RAGStatusEditor, popup: true, popupPosition: "under" })
    });
  };

  setValueSetter = (callback: (params: ValueSetterParams) => any) => {
    return this.setColumnOptions({
      valueSetter: callback
    });
  };
  setValueGetter = (callback: (params: ValueGetterParams) => any) => {
    return this.setColumnOptions({
      valueGetter: callback
    });
  };

  withCellRenderer = (callback: (params: ICellRendererParams) => any) => {
    return this.setColumnOptions({ cellRenderer: callback });
  };
}
