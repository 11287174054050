import { observable } from "mobx";
import * as React from "react";
import { ButtonTypes } from "../../../../components/ui/Button";
import { Icon, IconSymbols } from "../../../../components/ui/Icon";
import { Panel } from "../../../../components/ui/Panel";
import ProgressIndicatorModel, {
  ProgressIndicatorModel as IProgressIndicatorModel
} from "../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import I18n from "../../../../core/localization/I18n";
import ToasterService, { IToasterService } from "../../../../core/toaster/ToasterService";
import { TOASTER_TOAST_TIME } from "../../../../core/toaster/Toaster_model";
import { UiActionRenderers } from "../../../../core/uiAction/IUiAction";
import ImpactGroupsApi, {
  ImpactGroupsApi as IImpactGroupsApi
} from "../../../../services/api/v2/impactGroups/ImpactGroups.api";
import ImpactsApi, { ImpactsApi as IImpactsApi } from "../../../../services/api/v2/impacts/Impacts.api";
import OrganisationsApi, {
  OrganisationsApi as IOrganisationsApi
} from "../../../../services/api/v2/organisations/Organisations.api";
import ProjectsApi, { ProjectsApi as IProjectsApi } from "../../../../services/api/v2/projects/Projects.api";
import { ILocalStorageService } from "../../../../services/local/localStorageService/ILocalStorageService";
import LocalStorageService from "../../../../services/local/localStorageService/LocalStorageService";
import { getProjectFormField } from "../impact/ImpactFormSection_data";
import { SingleFormModel } from "../singleFormModel/SingleForm_model";
import { getImpactGroupForm } from "./ImpactGroupFormSection_data";
import { ImpactGroupFormSectionView } from "./ImpactGroupFormSection_view";
import { IModalContextModel } from "../../../../core/modalZ/context/IModalContext";
import ModalContext from "../../../../core/modalZ/context/ModalContext";
import { NavigateFunction } from "react-router-dom";
import Pages from "../../../../routes/InsightRoutes";

export class ImpactGroupFormSectionModel {
  id: string = "impactgroup-create";
  label: string;
  path: string = "/impactgroup/create";
  impactGroupsProvider: IImpactGroupsApi;
  projectProvider: IProjectsApi;
  formModel: SingleFormModel;
  localStorage: ILocalStorageService;
  orgId: number;
  httpProgress: IProgressIndicatorModel;
  organisationProvider: IOrganisationsApi;
  projectForm: SingleFormModel;
  toasterService: IToasterService;
  modalService: IModalContextModel;
  @observable projectId: number = 0;
  @observable.ref impactGroup: Partial<FP.Entities.IImpactGroup>;
  impactProvider: IImpactsApi;
  navigate: NavigateFunction;

  constructor({
    projectId,
    impactGroup,
    organisationId,
    navigate
  }: {
    projectId: number | null;
    impactGroup?: Partial<FP.Entities.IImpactGroup>;
    organisationId?: number;
    navigate: NavigateFunction;
  }) {
    this.impactGroup = impactGroup;
    this.navigate = navigate;
    this.projectId = projectId;
    this.httpProgress = ProgressIndicatorModel;
    this.projectProvider = ProjectsApi;
    this.impactGroupsProvider = ImpactGroupsApi;
    this.toasterService = ToasterService;
    this.organisationProvider = OrganisationsApi;
    this.modalService = ModalContext;
    this.localStorage = LocalStorageService;
    this.impactProvider = ImpactsApi;
    this.orgId = organisationId;
    this.formModel = new SingleFormModel();
    this.projectForm = new SingleFormModel();
    this.projectForm.formFields = getProjectFormField(
      this.organisationProvider,
      this.orgId,
      this.setForms,
      this.projectId
    );

    if (this.projectId) {
      this.setForms(this.projectId);
    }
  }

  setForms = projectId => {
    this.projectId = projectId;
    if (this.formModel) {
      let s = this.formModel.getFormKeyValueData();
      delete s.impacts;
      this.impactGroup = { ...this.impactGroup, ...s };
    }
    this.projectId = projectId;
    this.formModel.formFields = getImpactGroupForm(this.impactProvider, this.projectId, this.orgId, this.impactGroup);
    this.formModel.actions = this.impactGroup?.id
      ? [
          {
            id: "cancel",
            label: I18n.t("phrases.cancel"),
            rendersIn: UiActionRenderers.LINK_BUTTON,
            componentProps: {
              type: ButtonTypes.OUTLINE_PRIMARY,
              className: "ml-auto",
              href: `${Pages.projects.impactGroups.extendedView.generateLink(
                this.orgId,
                this.projectId,
                this.impactGroup.id
              )}`
            }
          },
          {
            id: "save",
            label: I18n.t("phrases.save"),
            onAction: this.updateImpactGroup,
            rendersIn: UiActionRenderers.BUTTON,
            componentProps: {
              type: ButtonTypes.PRIMARY,
              className: "ml-2"
            }
          }
        ]
      : [
          {
            id: "createAnother",
            label: I18n.t("phrases.createAnother"),
            onAction: async () => {
              let res = await this.createImpactGroup();
              if (res) {
                this.toasterService
                  .showSuccessToast()
                  .setContent(
                    <span>
                      {I18n.t("phrases.itemCreatedSuccessfully", { item: I18n.t("entities.highLevelImpact") })}
                    </span>
                  )
                  .startTimer(TOASTER_TOAST_TIME.NORMAL);
                this.formModel.resetFields();
              }
            },
            rendersIn: UiActionRenderers.BUTTON,
            componentProps: {
              type: ButtonTypes.OUTLINE_PRIMARY,
              className: "ml-auto"
            }
          },
          {
            id: "create",
            label: I18n.t("phrases.createHighLevelImpact"),
            onAction: async () => {
              const impactGroup = await this.createImpactGroup();
              if (impactGroup) {
                this.navigate(Pages.projects.impactGroups.listView.generateLink(this.orgId, this.projectId));
              }
            },
            rendersIn: UiActionRenderers.BUTTON,
            componentProps: {
              type: ButtonTypes.PRIMARY,
              className: "ml-2"
            }
          }
        ];
  };

  doesImpactGroupAlreadyExist = async (name: string): Promise<boolean> => {
    let impactGroupExists = await this.impactGroupsProvider.alreadyExists(this.orgId, this.projectId, name);

    if (impactGroupExists && !impactGroupExists.isError) {
      return impactGroupExists.payload;
    }

    return false;
  };

  createImpactGroup = async () => {
    this.formModel.isSaving = true;
    const projectFormRes = await this.projectForm.submit();
    const formRes = await this.formModel.submit();
    if (!projectFormRes || !formRes) {
      return;
    }

    if ((await this.doesImpactGroupAlreadyExist(formRes.name)) === true) {
      let confirmCreateImpactGroup = await this.confirmCreateImpactGroup(formRes.name);
      if (!confirmCreateImpactGroup) return;
    }

    const formResult = {
      ...projectFormRes,
      ...formRes
    };

    this.httpProgress.showOverlay();
    const res = await this.impactGroupsProvider.create(
      this.orgId,
      this.projectId,
      formResult as FP.Entities.IImpactGroup
    );
    this.httpProgress.hideOverlay();

    return res.payload;
  };

  confirmCreateImpactGroup = async (name: string): Promise<boolean> => {
    return new Promise(async resolve => {
      await this.modalService.showConfirmDialog(
        <h1 className="mt-4">{I18n.t("phrases.confirm")}</h1>,
        <div className="container-fluid">
          <div className="row mb-3">
            <div className="col-12">
              <Icon symbol={IconSymbols.AlertCircle} className="mr-2" />
              {I18n.t("warnings.createSameNameImpactGroup")}
            </div>
          </div>
          <div className="row">
            <div className="col">{I18n.t("warnings.confirmSameName", { name: name })}</div>
          </div>
        </div>,
        I18n.t("phrases.yes"),
        I18n.t("phrases.no"),
        {
          wrapWidth: "small",
          spacing: "small",
          position: "middle",
          panelProps: {
            background: Panel.PanelBackgrounds.BG_WHITE
          }
        },
        async () => {
          this.modalService.hide();
          resolve(true);
        },
        () => {
          this.modalService.hide();
          resolve(false);
        }
      );
    });
  };

  updateImpactGroup = async () => {
    const formRes = await this.formModel.submit();

    if (!formRes) {
      return;
    }

    this.httpProgress.showOverlay();
    const res = await this.impactGroupsProvider.update(
      this.orgId,
      this.projectId,
      this.impactGroup.id,
      formRes as FP.Entities.IImpactGroup
    );
    this.httpProgress.hideOverlay();

    if (!res || res.isError) {
      this.toasterService.showErrorToast(TOASTER_TOAST_TIME.SLOW).setContent(I18n.t("errors.updateImpactGroup"));
      return;
    }
    this.navigate(
      Pages.projects.impactGroups.extendedView.generateLink(this.orgId, this.projectId, this.impactGroup.id)
    );
  };

  onMount = () => {};

  renderComponent = (): React.ReactNode => {
    return <ImpactGroupFormSectionView model={this} />;
  };
}
