import { action, makeObservable, observable } from "mobx";
import { ButtonTypes } from "../../../../components/ui/Button";
import { Icon, IconSymbols } from "../../../../components/ui/Icon";
import { Panel } from "../../../../components/ui/Panel";
import ProgressIndicatorModel, {
  ProgressIndicatorModel as IProgressIndicatorModel
} from "../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import I18n from "../../../../core/localization/I18n";
import ToasterService, { IToasterService } from "../../../../core/toaster/ToasterService";
import { TOASTER_TOAST_TIME } from "../../../../core/toaster/Toaster_model";
import { UiActionRenderers } from "../../../../core/uiAction/IUiAction";
import { BaseModel } from "../../../../core/util/BaseModel";
import { Enums } from "../../../../enums";
import OrganisationsApi, {
  OrganisationsApi as IOrganisationsApi
} from "../../../../services/api/v2/organisations/Organisations.api";
import ProgrammesApi, { ProgrammesApi as IProgrammesApi } from "../../../../services/api/v2/programmes/Programmes.api";
import { ILocalStorageService } from "../../../../services/local/localStorageService/ILocalStorageService";
import LocalStorageService from "../../../../services/local/localStorageService/LocalStorageService";
import { getProgrammeFormFields } from "../../forms/programme/ProgrammeFormSection_data";
import { SingleFormModel } from "../../forms/singleFormModel/SingleForm_model";
import { IModalContextModel } from "../../../../core/modalZ/context/IModalContext";
import ModalContext from "../../../../core/modalZ/context/ModalContext";

export class ProgrammeSettingsModel extends BaseModel {
  modalService: IModalContextModel;
  localStorageService: ILocalStorageService;
  toasterService: IToasterService;
  programmesProvider: IProgrammesApi;
  orgProvider: IOrganisationsApi;
  orgId: number;
  formModel: SingleFormModel;
  httpProgress: IProgressIndicatorModel;
  @observable programmeId: number;
  @observable isLoading: boolean = true;
  @observable.ref programme: FP.Entities.IProgramme;
  authUser: FP.Entities.IUser;
  refreshClaims: any;

  constructor(programmeId: number, authUser: FP.Entities.IUser, refreshClaims: () => void) {
    super();
    makeObservable(this);
    this.authUser = authUser;
    this.refreshClaims = refreshClaims;
    this.modalService = ModalContext;
    this.localStorageService = LocalStorageService;
    this.toasterService = ToasterService;
    this.httpProgress = ProgressIndicatorModel;
    this.orgId = parseInt(this.localStorageService.get(Enums.LocalCookies.ORGANISATION_ID));
    this.programmeId = programmeId;
    this.programmesProvider = ProgrammesApi;
    this.orgProvider = OrganisationsApi;
    this.formModel = new SingleFormModel();
  }

  onMount = async () => {
    await this.loadProgramme();
    this.setForm();
  };

  onUnmount = () => {};

  loadProgramme = async () => {
    const res = await this.programmesProvider.getById(this.orgId, this.programmeId);
    if (!res || res.isError) return;

    this.setProgramme(res.payload);
    this.isLoading = false;
  };

  @action
  setProgramme = (programme: FP.Entities.IProgramme) => {
    this.programme = programme;
  };

  setForm = async () => {
    this.formModel.formFields = getProgrammeFormFields(this.orgId, this.orgProvider, this.programme);
    this.formModel.actions = [
      {
        id: "delete",
        label: I18n.t("phrases.deleteProgramme"),
        rendersIn: UiActionRenderers.BUTTON,
        componentProps: {
          type: ButtonTypes.OUTLINE_DANGER
        },
        onAction: this.showConfirmDeleteModal
      },
      {
        id: "cancel",
        label: I18n.t("phrases.cancel"),
        rendersIn: UiActionRenderers.LINK_BUTTON,
        componentProps: {
          type: ButtonTypes.LINK,
          href: `/organisations/${this.orgId}/programmes/${this.programme.id}`,
          className: "ml-auto"
        }
      },
      {
        id: "CreateProgrammeButton",
        label: I18n.t("phrases.save"),
        onAction: async () => {
          let formResult = await this.formModel.submit();
          if (formResult) {
            this.httpProgress.showOverlay();
            let res = await this.programmesProvider.update(
              this.orgId,
              this.programme.id,
              formResult as FP.Entities.IProgramme
            );
            this.refreshClaims();
            this.httpProgress.hideOverlay();

            if (!res || res.isError) {
              this.toasterService
                .showErrorToast(TOASTER_TOAST_TIME.SLOW)
                .setContent(<p>{I18n.t("errors.updateProgramme")}</p>);
              return;
            }

            // appHistory.push(`/organisations/${this.orgId}/programmes/${this.programme.id}`);
          }
        },
        rendersIn: UiActionRenderers.BUTTON,
        componentProps: {
          type: ButtonTypes.PRIMARY,
          className: "ml-2"
        }
      }
    ];
  };

  showConfirmDeleteModal = () => {
    this.modalService.show({
      title: <h1 className="my-4">{I18n.t("phrases.deleteProgramme")}</h1>,
      content: (
        <div className="container-fluid">
          <div className="row mb-3">
            <div className="col-12">
              <Icon symbol={IconSymbols.AlertCircle} className="mr-2" />
              {I18n.t("warnings.deleteProgramme")}
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12">{I18n.t("phrases.confirmDelete", { name: this.programme.name })}</div>
          </div>
        </div>
      ),
      showClose: true,
      componentProps: {
        wrapWidth: "small",
        spacing: "small",
        position: "middle",
        panelProps: {
          background: Panel.PanelBackgrounds.BG_WHITE
        }
      },
      actions: [
        {
          id: "delete",
          label: I18n.t("phrases.delete"),
          rendersIn: UiActionRenderers.BUTTON,
          componentProps: {
            type: ButtonTypes.DANGER,
            className: "float-right ml-2"
          },
          onAction: this.deleteProgramme
        },
        {
          id: "cancel",
          label: I18n.t("phrases.cancel"),
          rendersIn: UiActionRenderers.BUTTON,
          componentProps: {
            type: ButtonTypes.LINK,
            className: "float-right"
          },
          onAction: this.modalService.hide
        }
      ]
    });
  };

  deleteProgramme = async () => {
    this.modalService.hide();

    this.httpProgress.showOverlay();
    const res = await this.programmesProvider.remove(this.orgId, this.programme.id);
    this.refreshClaims();
    this.httpProgress.hideOverlay();

    if (!res || res.isError) {
      this.toasterService.showErrorToast(TOASTER_TOAST_TIME.SLOW).setContent(<p>{I18n.t("errors.deleteProgramme")}</p>);
      return;
    }
    this.refreshClaims();

    // appHistory.push(`/organisations/${this.orgId}`);
  };
}
