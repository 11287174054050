import React, { useState } from "react";
import { Link } from "react-router-dom";
import { IF } from "../../../../components/hoc/If";
import { AvatarContent } from "../../../../components/ui/AvatarContent";
import { Button, ButtonTypes } from "../../../../components/ui/Button";
import { USER_IMAGE_PLACEHOLDER } from "../../../../constants";
import { useCurrentOrganisationId } from "../../../../services/local/organisationContext/OrganisationContextModel";
import I18n from "../../../../core/localization/I18n";
import { convertStakeholderToName } from "../../../../core/util/Helpers";
import { Enums } from "../../../../enums";
import { QUERY_STRING_PARAMS } from "../../../../services/local/queryStringService/QueryStringService";

interface Props {
  projectId: number;
  users: FP.Entities.IStakeholder[];
  isOrgStakeholder?: boolean;
}

const TeamMemberPanel: React.FC<any> = ({ user, projectId, audience, isOrgStakeholder }) => {
  const organisationId = useCurrentOrganisationId();
  const userLinkAddress = () => {
    return `/organisations/${organisationId}/projects/${projectId}/stakeholders/${user.id}?${
      QUERY_STRING_PARAMS.RETURN_URL
    }=${encodeURIComponent(window.location.href.replace(appConfig.baseUrl, ""))}`;
  };

  const panelKey = () => {
    return "User_" + user.id;
  };

  const avatarProps = () => {
    return {
      size: Enums.UiSizes.MD,
      imgSrc: user.profileImageUrl || USER_IMAGE_PLACEHOLDER(user.firstName, user.lastName)
    };
  };

  return (
    <div className="mt-2" key={panelKey()}>
      <Link to={userLinkAddress()}>
        <AvatarContent type={ButtonTypes.PRIMARY} avatarProps={avatarProps()}>
          <h4 className="mb-0">{convertStakeholderToName(user)}</h4>
        </AvatarContent>
      </Link>
    </div>
  );
};

const Users: React.FC<Props> = ({ projectId, users }) => {
  const mapProjectTeamList = () => {
    return users.map((e, i) => {
      return <TeamMemberPanel key={i} user={e} projectId={projectId} />;
    });
  };

  return <div>{mapProjectTeamList()}</div>;
};

const EmptyUsersDisplay: React.FC<any> = () => {
  const noProjectTeamPanel = () => {
    return <div className="d-flex align-self-center">{I18n.t("phrases.noProjectTeam")}</div>;
  };

  return <>{noProjectTeamPanel()}</>;
};

const ResizeButton: React.FC<any> = ({ listState, setListState, teamSize }) => {
  const resizeButtonText = () => {
    if (listState) return I18n.t("phrases.showLess");
    return I18n.t("phrases.showMore", { num: teamSize - 2 });
  };

  return (
    <Button type={ButtonTypes.LINK} className={"mt-2 pb-0 pl-0"} onClick={() => setListState(!listState)}>
      {resizeButtonText()}
    </Button>
  );
};

const StakeholderCollapsableListView: React.FC<Props> = ({ projectId, users }) => {
  const [showAllUsers, setShowAllUsers] = useState(false);

  const projectTeamSize = users?.length || 0;

  const projectHasTeamMembers = projectTeamSize > 0;

  const projectTeamIsLargerThanTwo = projectTeamSize > 2;

  const setVisibleIndex = () => {
    if (projectTeamIsLargerThanTwo && !showAllUsers) return 2;
    else return;
  };

  const resizedTeamList = () => {
    return users.slice(0, setVisibleIndex());
  };

  return (
    <>
      <IF condition={projectHasTeamMembers}>
        <Users projectId={projectId} users={resizedTeamList()} />
      </IF>

      <IF condition={!projectHasTeamMembers}>
        <EmptyUsersDisplay />
      </IF>

      <IF condition={projectTeamIsLargerThanTwo}>
        <ResizeButton listState={showAllUsers} setListState={setShowAllUsers} teamSize={projectTeamSize} />
      </IF>
    </>
  );
};

export { StakeholderCollapsableListView };
