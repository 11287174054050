import * as React from "react";
import { RevisionCreateModel } from "./RevisionCreate_model";
import { Panel } from "../../../components/ui/Panel";
import moment from "moment";
import { observer } from "mobx-react";
import { RenderXssSafeString } from "../../../core/AntiXss/AntiXssHelper";

export interface RevisionCreateProps {
  model: RevisionCreateModel;
}

export const RevisionCreate: React.FunctionComponent<RevisionCreateProps> = observer(({ model }) => {
  let { revision, changePhrase } = model;
  return (
    <Panel.Panel
      className="activity-card__panel p-4"
      hasShadow={true}
      background={Panel.PanelBackgrounds.BG_WHITE}
      hasBorderRadius={true}
    >
      <div className="row">
        <div className="col">
          <div className="d-flex">
            <div>
              <h4 className="mb-1">{moment(revision.changedAt).format("L @ HH:mm")}</h4>
              <div dangerouslySetInnerHTML={{ __html: RenderXssSafeString(changePhrase) }}></div>
            </div>
          </div>
        </div>
      </div>
    </Panel.Panel>
  );
});
