import moment from "moment";
import { Enums, TableBadgeTypes } from "../../enums";
import I18n from "../localization/I18n";

export const getDaysBetween = (date: Date): number => {
    let today = moment().startOf('day');
    let startOfDate = moment(new Date(date)).startOf('day');
    return moment.duration(today.diff(startOfDate)).asDays();
}

export const isItemComplete = (progressStatus: any) => {
    return progressStatus === Enums.ProgressStatus.COMPLETED
}

export const determineTableBadgeType = (object): TableBadgeTypes => {
    let isComplete = object.progressStatus === Enums.ProgressStatus.COMPLETED;

    if (IsNew(object.createdAt)) return TableBadgeTypes.GREEN
    if (IsDueReview(object.reviewedOn, isComplete)) return TableBadgeTypes.AMBER
    if (IsOverdueReview(object.reviewedOn, isComplete)) return TableBadgeTypes.RED
    if (IsUpdated(object.updatedAt)) return TableBadgeTypes.BLUE
    return TableBadgeTypes.BLACK
}

const IsNew = (createdAt): boolean => getDaysBetween(createdAt) <= 3;
const IsDueReview = (reviewedOn, isComplete): boolean =>
(getDaysBetween(reviewedOn) >= 28
    && getDaysBetween(reviewedOn) <= 42
    && !isComplete);
const IsOverdueReview = (reviewedOn, isComplete): boolean => getDaysBetween(reviewedOn) > 42
    && !isComplete;
const IsUpdated = (updatedAt): boolean => getDaysBetween(updatedAt) <= 7

export const determineBadgeText = (badgeType: TableBadgeTypes): string => {
    if (badgeType === TableBadgeTypes.GREEN) return I18n.t("table.rowSubtextNew")
    if (badgeType === TableBadgeTypes.BLUE) return I18n.t("table.rowSubtextUpdated")
    if (badgeType === TableBadgeTypes.RED) return I18n.t("table.overdueReview")
    if (badgeType === TableBadgeTypes.AMBER) return I18n.t("table.dueReview")
    return ""
}

export const determineReviewTableBadgeType = (date: Date): TableBadgeTypes => {
    let lastReviewed = moment(date).startOf('day');
    let nextReviewDate = lastReviewed.add(42, 'days');
    let today = moment().startOf('day');
    let daysUntilReview = moment.duration(nextReviewDate.diff(today)).asDays();
    if (daysUntilReview < 0) return TableBadgeTypes.RED;
    if (daysUntilReview <= 14) return TableBadgeTypes.AMBER;
    if (daysUntilReview === 42) return TableBadgeTypes.GREEN;
    return TableBadgeTypes.BLACK;
}

export const getNextReviewDate = (date: Date) => {
    return moment(date).add(6, 'weeks').format("L")
}

export const getReviewedOn = (date: Date) => {
    return moment(date).format("L")
}