import React, { useEffect, useState } from "react";
import { IF } from "../../../components/hoc/If";
import { TopNav } from "../../../components/ui/TopNav";
import IntercomLiveChat from "../../../services/external/Intercom/IntercomLiveChat";
import { useCurrentOrganisation } from "../../../services/local/organisationContext/OrganisationContextModel";
import { AuthUserAvatar } from "../../change/navigation/config/AuthUserAvatar";
import HelpNavigation from "../../change/navigation/config/HelpNavigation";
import { NavBreadcrumbs } from "./NavBreadcrumbs";

export const NAV_UI_PORTAL = "nav_ui_portal";

export interface TopNavigationProviderProps extends React.HTMLProps<HTMLDivElement> {}

export const TopNavigationProvider: React.FC<TopNavigationProviderProps> = props => {
  const organisation = useCurrentOrganisation();
  const currentOrganisationId = organisation.id;
  const currentOrganisationName = organisation.name ?? "";
  const [domReady, setDomReady] = useState(false);

  useEffect(() => {
    setDomReady(true);
  }, []);
  return (
    <TopNav className="top-navigation">
      <div className="w-100 d-flex justify-content-between bg-dark-blue">
        <div className="d-flex align-items-center p-2 pl-4">
          <IF condition={domReady}>
            <NavBreadcrumbs />
          </IF>
        </div>
        <div className="d-flex align-items-center p-2 pr-4">
          {/* <SearchNav className="px-2 navigation-view__search" /> */}
          {/* <Notifications className="pl-2" /> */}
          <HelpNavigation
            className="pl-2"
            currentOrganisationName={currentOrganisationName}
            currentOrganisationId={currentOrganisationId}
          />
          <IntercomLiveChat
            currentOrganisationId={currentOrganisationId}
            currentOrganisationName={currentOrganisationName}
            showAsButton="icon"
            className="pl-2"
          />
          <AuthUserAvatar className="pl-2" />
        </div>
      </div>
      <div id={NAV_UI_PORTAL} className="top-navigation__mega-menu"></div>
    </TopNav>
  );
};
