import I18n from "../../../../../../../localization/I18n";
import { GridSideModalTitle } from "../../../../utils/GridModalUtils";
import { ImpactTypesSidebarModel } from "./ImpactTypesSidebar_model";
import { ImpactTypesSidebar } from "./ImpactTypesSidebar_view";

export const IMPACT_TYPES_SIDEBAR_MODAL_CONFIG = (
  organisationId: number,
  projectId: number,
  impact: FP.Entities.IImpact
) => {
  return {
    showClose: true,
    title: <GridSideModalTitle name={impact.name} field={I18n.t("phrases.impactType")} />,
    content: <ImpactTypesSidebar model={new ImpactTypesSidebarModel(organisationId, projectId, impact.id)} />
  };
};
