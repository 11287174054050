import { IFormFieldModel } from "./IFormField";

export const INIT_FORM_FIELD: Partial<IFormFieldModel<any, any>> = {
  key: "",
  label: "",
  errorMessage: null,
  hasChanges: false,
  disabled: false,
  saveDelegate: null,
  isHidden: false,
  isLabelHidden: false,
  defaultValue: null,
  reset: null,
  setValue: null,
  extractValue: null,
  validate: () => {
    return true;
  },
  isValid: true,
  validateOnChange: false,
  subscribeTo: null,
  tooltipLabel: null,
  manageLink: null,
};
