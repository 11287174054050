import { UiSizes } from "@flightpath/coreui/dist/enums";
import * as React from "react";
import { PillIndicator } from "../../../../components/ui/PillIndicator";
import { Panel } from "../../../../components/ui/Panel";
import { Pill } from "../../../../components/ui/Pill";
import I18n from "../../../../core/localization/I18n";
import { Animations } from "../../../../core/util/Animations";
import { Enums } from "../../../../enums";
import { getMitigationConfidencePhrase } from "../../impacts/impactDisplay";
import { Tooltip } from "../../../../components/ui/Tooltip";

export interface ImpactLevelCellProps {
  obj: FP.Entities.IImpact;
  className?: string;
}

const ImpactLevelCell: React.FunctionComponent<ImpactLevelCellProps> = props => {
  var obj = props.obj;
  var confidencePhrase = getMitigationConfidencePhrase(obj.mitigationConfidence);
  return (
    <div className={`${props.className || ""}`}>
      <Pill
        style={{ padding: "3px 11px", width: "36px" }}
        type={Enums.Translator.ImpactLevelToPill(obj.impactLevel)}
        className={"mr-1"}
      >
        {Enums.Translator.ImpactLevelShort(obj.impactLevel)}
      </Pill>
      <Tooltip
        triggeredOn="hover"
        shownElement={
          <PillIndicator
            type={Enums.Translator.MitigationConfidenceToPillIndicator(obj.mitigationConfidence)}
            className="my-1"
          />
        }
      >
        <Panel.Panel
          hasBorderRadius={true}
          background={Panel.PanelBackgrounds.BG_WHITE}
          hasShadow={true}
          className={`p-3 ml-3 mt-2 ${Animations.FADE_IN} speed-3 d-flex align-items-start flex-column`}
          style={{ width: "200px" }}
        >
          <PillIndicator
            type={Enums.Translator.MitigationConfidenceToPillIndicator(obj.mitigationConfidence)}
            size={UiSizes.MD}
          />
          <h4 className="mt-1 mb-1 text-nowrap">
            {I18n.t("table.mitigationConfidence")}: {obj.mitigationConfidence === -1 ? "-" : obj.mitigationConfidence}
          </h4>
          <span>{confidencePhrase}</span>
        </Panel.Panel>
      </Tooltip>
    </div>
  );
};

export { ImpactLevelCell };
