import { action, makeObservable, observable } from "mobx";
import { GetGridFns, IGridFns } from "../../GridFunctions";
import { IGridModel } from "../../IGridModel";
import I18n from "../../../localization/I18n";
import { BaseModel } from "../../../util/BaseModel";
import { GridTypes } from "../../../../enums";
import ProjectStakeholdersApi, {
  ProjectStakeholdersApi as IProjectStakeholdersApi
} from "../../../../services/api/v2/projectStakeholders/ProjectStakeholders.api";
import { GetStakeholderGridViewColumns } from "../../../../pages/insight/projects/Stakeholders/StakeholdersListView/StakeholderGridView/StakeholderGridView_columns";
import { IModalContextModel } from "../../../modalZ/context/IModalContext";
import ModalContext from "../../../modalZ/context/ModalContext";
import PermissionsContext from "../../../../contexts/permissions/PermissionsContext";
import { PermissionFields } from "../../../../contexts/permissions/PermissionsTypes";

export class StakeholderGridViewModel extends BaseModel implements IGridModel {
  authUser: FP.Entities.IUser;
  @observable isLoading: boolean = true;
  progressStatuses: FP.Generic.IKeyLabel[];
  impactLevels: FP.Generic.IKeyLabel[];
  organisationId: number;
  projectId: number;
  projectStakeholdersProvider: IProjectStakeholdersApi;
  gridFns: IGridFns;
  modalService: IModalContextModel;
  stakeholderId: number;
  type: GridTypes = GridTypes.IMPACT_STAKEHOLDER_GRID;
  userCanViewImpacts: boolean;
  userCanViewActions: boolean;

  /**
   *
   */
  constructor(organisationId: number, projectId: number, authUser: FP.Entities.IUser) {
    super();
    makeObservable(this);
    this.authUser = authUser;
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.modalService = ModalContext;
    this.projectStakeholdersProvider = ProjectStakeholdersApi;
    this.gridFns = GetGridFns(this.projectStakeholdersProvider as any, this.organisationId, this.projectId);
    this.userCanViewImpacts = PermissionsContext.canViewField(PermissionFields.IMPACTS, organisationId, projectId);
    this.userCanViewActions = PermissionsContext.canViewField(PermissionFields.ACTIONS, organisationId, projectId);
  }

  getColumnConfig = () => {
    return GetStakeholderGridViewColumns({
      canEdit: false,
      organisationId: this.organisationId,
      projectId: this.projectId,
      stakeholderId: this.stakeholderId,
      modalService: this.modalService,
      isKeyStakeholdersOptions: [
        { key: "0", label: I18n.t("phrases.no") },
        { key: "1", label: I18n.t("phrases.yes") }
      ],
      impactLevels: [],
      gridFns: this.gridFns,
      userCanViewImpacts: this.userCanViewImpacts,
      userCanViewActions: this.userCanViewActions,
      pinned: null
    });
  };

  @action
  onMount = () => {
    this.isLoading = false;
  };

  onUnmount = () => {};
}
