import React from "react";
import { FormFieldModel } from "../../formField/FormField_model";
import { IListingModel } from "./IListingModel";
import { observable, action, makeObservable } from "mobx";
import { FormFieldType } from "../../formField/FormFieldTypes";
import { ListingView } from "../../../../components/ui/_forms/Listing";
import { INIT_FORM_FIELD } from "../../formField/FormField_init";

export const INIT_LISTING_FIELD: Partial<IListingModel> = {
  ...INIT_FORM_FIELD,
  value: [],
  type: FormFieldType.Listing,
  validate: () => {
    return true;
  }
};

export class ListingModel extends FormFieldModel<any[], React.HTMLProps<HTMLInputElement>> implements IListingModel {
  readonly type: FormFieldType = FormFieldType.Listing;
  uniqueAttr: string = "id";
  selector: string | ((e: any) => React.ReactNode) = "name";
  placeholder?: string = "";
  @observable value: any[] = [];

  /**
   *
   */
  constructor(initOpts?: IListingModel) {
    super(initOpts);
    makeObservable(this);
    if (initOpts) {
      this.value = initOpts.value || this.value || [];
      this.validate = initOpts.validate || (() => true);
      this.selector = initOpts.selector || this.selector;
      this.uniqueAttr = initOpts.uniqueAttr || this.uniqueAttr;
      this.placeholder = initOpts.placeholder || this.placeholder;
      this.extractValue = initOpts.extractValue || this.extractValue;
    }
  }
  validate: (...args: any[]) => any;

  @action
  setValue = (val: any): void => {
    this.value = val;
    if (this.validateOnChange && this.validate) {
      this.validate(this.value);
    }
  };

  @action
  addItem = (item: any) => {
    if (item !== null) {
      let s = this.value.filter(e => e[this.uniqueAttr] === item[this.uniqueAttr]);
      if (s.length === 0) {
        const newValue = this.value.slice();
        newValue.push(item);
        this.setValue(newValue);
      }
    }
  };

  @action
  deleteItemByIdx = (idx: number) => {
    const newValue = this.value.slice();
    newValue.splice(idx, 1);
    this.setValue(newValue);
  };

  setFieldValue(val: any): void {
    throw new Error("Method not implemented.");
  }
  extractValue() {
    return this.value;
  }

  @action
  reset = (): void => {
    this.value = this.defaultValue || [];
    this.errorMessage = null;
  };

  renderComponent = () => {
    return (
      <ListingView
        data={this.value}
        selector={this.selector}
        placeholder={this.placeholder}
        removeFn={this.deleteItemByIdx}
      />
    );
  };
}
