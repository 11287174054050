import { UiSizes } from "@flightpath/coreui/dist/enums";
import { IF } from "../../../../components/hoc/If";
import { Button, ButtonTypes } from "../../../../components/ui/Button";
import { ButtonGroup } from "../../../../components/ui/ButtonGroup";
import { Panel } from "../../../../components/ui/Panel";
import { PositionedSpinner } from "../../../../components/ui/PositionedSpinner";
import I18n from "../../../../core/localization/I18n";
import { CountDisplay } from "../../projects/view/components/CountDisplay";
import { ReportingPie } from "../../projects/view/components/ReportingPie";
import { useState } from "react";
import {
  showImpactsPerProjectByLevelPieChartSidebar,
  showImpactsPerProjectByProgressStatusPieChartSidebar
} from "./Impacts_modals";

export const impactLevelColoursArray = ["#093D4C", "#095971", "#308096", "#BEBEBE"];
export const impactAndActionCompletionDataColoursArray = [
  "#D3D3D3",
  "#FFD700",
  "#1AC541",
  "#FFA07A",
  "#B0E0E6",
  "#C0C0C0",
  "#ADD8E6"
];

export const ImpactPies: React.FC<{
  isLoading: boolean;
  pieData: any[];
  count: number;
  progressData: any[];
  authUser: FP.Entities.IUser;
}> = ({ isLoading, count, pieData, progressData, authUser }) => {
  const [currentView, setCurrentView] = useState<"level" | "progress">("level");
  const percent = progressData ? Math.round(100 * (progressData[2].value / count)) : 0;
  return (
    <Panel.Panel hasBorder={true} background={Panel.PanelBackgrounds.BG_LIGHT} hasBorderRadius className="mb-4 p-3">
      <IF condition={isLoading}>
        <PositionedSpinner />
      </IF>
      <IF condition={!isLoading && count === 0}>
        <h3>No impacts assigned</h3>
      </IF>
      <IF condition={!isLoading && count > 0}>
        <ButtonGroup size={UiSizes.XXS} isToggle={true} isVertical={false}>
          <Button onClick={() => setCurrentView("level")} type={currentView !== "level" && ButtonTypes.OUTLINE_PRIMARY}>
            Level
          </Button>
          <Button
            onClick={() => setCurrentView("progress")}
            type={currentView !== "progress" && ButtonTypes.OUTLINE_PRIMARY}
          >
            Progress Status
          </Button>
        </ButtonGroup>
        <IF condition={currentView === "level"}>
          <div className="row">
            <div className="col-5 p-0">
              <div className="col p-0">
                <CountDisplay
                  count={count}
                  canView={true}
                  label={I18n.t("phrases.yourImpacts")}
                  permissionLabel={I18n.t("warnings.noImpactAccessPanel")}
                  drilldownDisabled={true}
                />
              </div>
            </div>
            <div className="col-7 p-0">
              <ReportingPie
                cls={"p-0"}
                data={pieData}
                colourArray={impactLevelColoursArray}
                onClick={pieData => showImpactsPerProjectByLevelPieChartSidebar(authUser, pieData)}
              />
            </div>
          </div>
        </IF>
        <IF condition={currentView === "progress"}>
          <div className="row">
            <div className="col-5 p-0">
              <div className="col p-0">
                <CountDisplay
                  count={`${percent}%`}
                  canView={true}
                  label={I18n.t("phrases.impactsProgress")}
                  permissionLabel={I18n.t("warnings.noImpactAccessPanel")}
                  subtitle={I18n.t("phrases.impactsComplete")}
                  drilldownDisabled={true}
                />
              </div>
            </div>
            <div className="col-7 p-0">
              <ReportingPie
                cls={"p-0"}
                data={progressData}
                colourArray={impactAndActionCompletionDataColoursArray}
                onClick={pieData => showImpactsPerProjectByProgressStatusPieChartSidebar(authUser, pieData)}
              />
            </div>
          </div>
        </IF>
      </IF>
    </Panel.Panel>
  );
};
