import { milestoneTypeTextMatcher } from "../../../../../../filters/TextMatcher";
import { ISdColDef, ISdFilterDef } from "../../../baseColumn/BaseColumnTypes";

export const MILESTONE_TYPE_COLUMN_CONFIG: (milestoneTypes: any) => ISdColDef<any, any> = milestoneTypes => {
  return {
    getQuickFilterText: params => {
      if (!params.data.milestoneType || !milestoneTypes) return "";

      const milestoneType = milestoneTypes.find(e => +e.key === params.value);

      if (!milestoneType) return "";

      return milestoneType.label;
    },
    cellEditorParams: {
      field: "milestoneType",
      getValueLabel: (ee: any) => {
        const milestoneType = milestoneTypes.find(e => e.key === ee + "");
        if (!milestoneType) return "";
        return milestoneType.label;
      },
      options: milestoneTypes ?? []
    }
  };
};
export const MILESTONE_TYPE_FILTER_CONFIG: ISdFilterDef = {
  filterParams: {
    textMatcher: milestoneTypeTextMatcher
  }
};
