import React from "react";
import { ResponsivePie } from "@nivo/pie";

export enum ChartUsage {
  Chart,
  Widget
}

export interface GapAnalysisChartProps {
  onClick?: any;
  dataConfidence: number;
  heading: string;
  chartUsage: ChartUsage;
  legendIncludedText?: string;
  legendExcludedText?: string;
}

const colours = () => {
  return ["#1AC541", "#E5E5E5"];
};

const buildDataConfidenceData = (
  dataConfidence: number,
  legendIncludedText?: string,
  legendExcludedText?: string
) => {
  let result = [];
  let excludedDataCount = 100 - dataConfidence;
  let includedText: string = legendIncludedText ?? "Included Data";
  let excludedText: string = legendExcludedText ?? "Excluded Data";

  result.push({
    id: "1",
    label: includedText,
    value: dataConfidence,
    color: colours()[0]
  });

  result.push({
    id: "0",
    label: excludedText,
    value: excludedDataCount,
    color: colours()[1]
  });

  return result;
};

const legendLine = (color: string, text: string, id: string) => {
  return (
    <div className="legend-line" key={id}>
      <div className="marker">
        <span style={{ backgroundColor: color }}></span>
      </div>
      <div className="label">{text}</div>
    </div>
  );
};

export const GapAnalysisChart: React.FC<GapAnalysisChartProps> = props => {
  let builtData = buildDataConfidenceData(
    props.dataConfidence,
    props.legendIncludedText,
    props.legendExcludedText
  );

  return (
    <>
      {props.chartUsage === ChartUsage.Widget && (
        <div className="gap-analysis-chart--widget">
          <div className="heading-container">
            <span className="heading">{props.heading}</span>
          </div>
          <div className="chart--small">
            <ResponsivePie
              data={buildDataConfidenceData(props.dataConfidence)}
              colors={colours()}
              margin={{ top: 0, right: 0, bottom: 20, left: 0 }}
              innerRadius={0.75}
              fit={true}
              activeOuterRadiusOffset={0}
              borderColor={{
                from: "color",
                modifiers: [["darker", 0]]
              }}
              enableArcLinkLabels={false}
              arcLinkLabelsSkipAngle={10}
              arcLinkLabelsTextColor="#333333"
              arcLinkLabelsThickness={2}
              arcLinkLabelsColor={{ from: "color" }}
              enableArcLabels={false}
              arcLabelsSkipAngle={10}
              arcLabelsTextColor={{
                from: "color",
                modifiers: [["darker", 0]]
              }}
              isInteractive={true}
              animate={false}
              onClick={props.onClick}
              tooltip={e => (
                <div className="tooltip">
                  <span className="label">{e.datum.label}</span>:{" "}
                  {e.datum.value}%
                </div>
              )}
            />
          </div>
          <div className="data-overlay" onClick={props.onClick}>
            <span>{props.dataConfidence}%</span>
            <span className="subtext">Complete</span>
          </div>
        </div>
      )}
      {props.chartUsage === ChartUsage.Chart && (
        <div className="gap-analysis-chart--chart">
          <div className="heading-container">
            <h3>{props.heading}</h3>
          </div>
          <div className="row">
            <div className="col-md-4 metric-container">
              <div>
                <span className="value display-l">{props.dataConfidence}</span>
                <span className="percent">%</span>
              </div>
              <div className="subtext">Complete</div>
            </div>
            <div className="col-md-8 d-flex">
              <div className="chart--large">
                <ResponsivePie
                  data={builtData}
                  colors={colours()}
                  margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                  innerRadius={0.6}
                  fit={true}
                  activeOuterRadiusOffset={0}
                  borderColor={{
                    from: "color",
                    modifiers: [["darker", 0]]
                  }}
                  enableArcLinkLabels={false}
                  arcLinkLabelsSkipAngle={10}
                  arcLinkLabelsTextColor="#333333"
                  arcLinkLabelsThickness={2}
                  arcLinkLabelsColor={{ from: "color" }}
                  enableArcLabels={false}
                  arcLabelsSkipAngle={10}
                  arcLabelsTextColor={{
                    from: "color",
                    modifiers: [["darker", 0]]
                  }}
                  isInteractive={true}
                  animate={false}
                  onClick={props.onClick}
                  tooltip={e => (
                    <div className="tooltip">
                      <span className="label">{e.datum.label}</span>:{" "}
                      {e.datum.value}%
                    </div>
                  )}
                />
              </div>
              <div>
                <div className="legend">
                  {builtData.map(e => legendLine(e.color, e.label, e.id))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
