import React from "react";
import { Panel } from "../../../../components/ui/Panel";
import { Link } from "react-router-dom";
import { AvatarContent } from "../../../../components/ui/AvatarContent";
import { USER_IMAGE_PLACEHOLDER } from "../../../../constants";
import { Enums } from "../../../../enums";

export interface IProgrammeSponsorProps {
    organisationId: number;
    programmeSponsor?: string;
}

export const ProgrammeSponsor: React.FunctionComponent<IProgrammeSponsorProps> = (props: IProgrammeSponsorProps) => {
    let sponsorId: number;
    let avatarUrl: string;
    let firstName: string;
    let lastName: string;
    
    const setVariables = () => {
        let arrayParts = props.programmeSponsor.split('::');

        if(arrayParts.length === 3){
            sponsorId = +arrayParts[0];
            avatarUrl = arrayParts[2];

            let nameParts = arrayParts[1].split(' ');

            if(nameParts.length === 2){
                firstName = nameParts[0];
                lastName = nameParts[1];
            }
        }
    }

    setVariables();

    return (
        <Panel.Panel hasShadow={true} hasBorderRadius={true} className="p-3">
            <Link to={`/organisations/${props.organisationId}/stakeholders/${sponsorId}`}>
                <AvatarContent
                type="primary"
                avatarProps={{
                    size: Enums.UiSizes.MD,
                    imgSrc: avatarUrl ||
                    USER_IMAGE_PLACEHOLDER(
                        firstName,
                        lastName
                    )
                }}
                >
                <h4 className="mb-0">
                    {firstName} {lastName}
                </h4>
                </AvatarContent>
            </Link>
            </Panel.Panel>
    );
}