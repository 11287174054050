import { IFilteredOptions } from "../filteredApi/FilteredApiModel";
import { FilteredOrganisationApiModel } from "../filteredApi/FilteredOrganisationApiModel";
import { AxiosInstance, AxiosRequestConfig } from "axios";
import http from "../Http";
import { IFlightPathApiResponse } from "../BaseApiModel";

export class ActionTypesApi extends FilteredOrganisationApiModel<FP.Entities.IActionType> {
  controller: string = "action-types";

  constructor(http: AxiosInstance) {
    super(http, "action-types");

    // this.url = `${this.http.url}/${this.version}/organisations`;
  }
  async getByOrganisationId(
    organisationId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IActionType[]>> {
    const url = `${this.url}/${organisationId}/${this.controller}`;
    let res = await this.http.get(url);
    return res.data;
  }

  getFilteredAsync = async (
    filterOptions: Partial<IFilteredOptions>,
    config?: AxiosRequestConfig,
    organisationId?: number
  ) => {
    const query = this.getRequestQuery(filterOptions);
    const res = await this.http.get(`${this.url}/${organisationId}/${this.controller}${query}`, config);
    return res.data;
  };
}

let instance = new ActionTypesApi(http);
export default instance;
