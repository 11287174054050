import React, { useMemo } from "react";
import { PositionedSpinner } from "../../../../../../components/ui/PositionedSpinner";
import I18n from "../../../../../../core/localization/I18n";
import { ReportingPie } from "../../../../projects/Actions/ActionReportingView/TopActionsReportingBar/ReportingPie";

export const ImpactLevelsCard = ({ impactLevelCounts, isLoading }) => {
  const impactLevelsTotal = useMemo(
    () => impactLevelCounts?.reduce((prev, curr) => prev + curr.value, 0),
    [impactLevelCounts]
  );
  const impactLevelColoursArray = ["#093D4C", "#095971", "#308096", "#BEBEBE"];

  return (
    <div className="col-4">
      {isLoading && <PositionedSpinner />}
      {!isLoading && (
        <ReportingPie
          data={impactLevelCounts}
          metric={impactLevelsTotal}
          colourArray={impactLevelColoursArray}
          title={I18n.t("visualisations.impactsLevel")}
        />
      )}
    </div>
  );
};
