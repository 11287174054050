import { SingleFormModel } from "../../../../../../change/forms/singleFormModel/SingleForm_model";
import { getHeatmapFilterFields } from "./HeatmapFilter_data";

export class HeatmapFilterModalModel {
  formModel: SingleFormModel;
  orgId: number;
  currentTags: any[];
  setCurrentTags: (s: any) => void;

  constructor(organisationId: number, currentTags: any[], setCurrentTags: (s) => void) {
    this.orgId = organisationId;
    this.formModel = new SingleFormModel();
    this.currentTags = currentTags;
    this.setCurrentTags = setCurrentTags;
    this.formModel.formFields = getHeatmapFilterFields(this.orgId, this.currentTags, this.setCurrentTags);
  }
}
