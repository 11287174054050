import { ColDef } from "ag-grid-community";
import { baseTextColumn } from "../../../../../../../core/grids/BaseColumns";
import { colName, colSelected } from "../../../../../../../core/grids/CommonGridColumns";
import I18n from "../../../../../../../core/localization/I18n";
import { JobRoleField } from "../../../../../../../enums";

export const GetJobRoleGridColumns = () => {
  let result: ColDef[] = [];
  result.push(colSelected());
  result.push(
    colName(false, null, JobRoleField.name, "", {
      width: 300,
      autoHeight: true
    })
  );
  result.push(colBusinessArea());

  return result;
};

const colBusinessArea = (): ColDef => {
  return {
    ...baseTextColumn({
      fieldName: "businessArea",
      heading: I18n.t("phrases.organisationStakeholders_businessAreas"),
      editable: false,
      clickEditable: false,
      width: 400
    })
  };
};
