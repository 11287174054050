import { action, makeObservable, observable } from "mobx";
import PermissionsContext from "../../../../../contexts/permissions/PermissionsContext";
import { PermissionFields } from "../../../../../contexts/permissions/PermissionsTypes";
import { ITreeDataItem } from "../../../../../core/forms/controls/searchTreePicker/ISearchTreePickerModel";

import { BaseModel } from "../../../../../core/util/BaseModel";
import ImpactTypesApi, {
  ImpactTypesApi as IImpactTypesApi
} from "../../../../../services/api/v2/impactTypes/ImpactTypes.api";
import ImpactsApi, { ImpactsApi as IImpactsApi } from "../../../../../services/api/v2/impacts/Impacts.api";
import { SingleFormModel } from "../../../../../pages/change/forms/singleFormModel/SingleForm_model";
import OrganisationsApi, {
  OrganisationsApi as IOrganisationsApi
} from "../../../../../services/api/v2/organisations/Organisations.api";
import { getBusinessAreasSidebarFormActions, getBusinessAreasSidebarFormFields } from "./BusinessAreasSidebar_fields";

export class BusinessAreasSidebarModel extends BaseModel {
  impactTypesProvider: IImpactTypesApi;
  impactsProvider: IImpactsApi;
  @observable isLoading: boolean = true;
  organisationId: number;
  projectId: number;
  @observable.ref businessAreas: ITreeDataItem[];
  formModel: SingleFormModel;
  canEdit: boolean;
  organisationProvider: IOrganisationsApi;
  updateBusinessAreasFN: (businessAreas: number[]) => void;
  currentIds: number[];

  constructor(
    organisationId: number,
    projectId: number,
    currentIds: number[],
    updateBusinessAreasFN: (businessAreas: number[]) => void
  ) {
    super();
    makeObservable(this);
    this.impactTypesProvider = ImpactTypesApi;
    this.organisationProvider = OrganisationsApi;
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.impactsProvider = ImpactsApi;
    this.currentIds = currentIds;
    this.loadSidebar();
    this.updateBusinessAreasFN = updateBusinessAreasFN;
    this.canEdit = PermissionsContext.canEditField(PermissionFields.IMPACTS, this.organisationId, this.projectId);
  }

  @action
  getBusinessAreas = async () => {
    const res = await this.organisationProvider.getBusinessAreas(this.organisationId);
    if (res.isError) return null;

    this.setBusinessAreas(res.payload);
  };

  @action
  setBusinessAreas = busAreas => {
    this.businessAreas = busAreas.map(e => {
      return { ...e, label: e.name };
    });
  };

  @action
  loadSidebar = async () => {
    this.setIsLoading(true);
    await this.getBusinessAreas();
    this.loadForm();
    this.setIsLoading(false);
  };

  loadForm = () => {
    this.formModel = new SingleFormModel();
    this.formModel.formFields = getBusinessAreasSidebarFormFields(this.businessAreas, this.currentIds);
    this.formModel.setActions(getBusinessAreasSidebarFormActions(this.updateBusinessAreas, this.canEdit));
  };

  updateBusinessAreas = async () => {
    if (!this.canEdit) return;
    this.formModel.isSaving = true;
    let res = await this.formModel.submit();
    this.updateBusinessAreasFN(res.businessAreas);
  };

  @action
  setIsLoading = (isLoading: boolean) => (this.isLoading = isLoading);
}
