import { Panel } from "@flightpath/coreui";
import { UiSizes } from "@flightpath/coreui/dist/enums";
import { TooltipPositions } from "@flightpath/coreui/dist/ui/Tooltip";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { FileDrop } from "react-file-drop";
import { IF } from "../../../../../../../components/hoc/If";
import { Button, ButtonTypes } from "../../../../../../../components/ui/Button";
import { Icon, IconSymbols } from "../../../../../../../components/ui/Icon";
import { PositionedSpinner } from "../../../../../../../components/ui/PositionedSpinner";
import { Tooltip } from "../../../../../../../components/ui/Tooltip";
import I18n from "../../../../../../../core/localization/I18n";
import { useCurrentOrganisationId } from "../../../../../../../services/local/organisationContext/OrganisationContextModel";
import { SystemProcessesUploaderModel, SystemProcessesUploaderViews } from "./SystemProcessUploader_model";
import "./_system-processes-uploader.scss";

export const SystemProcessUploader: React.FC<{ onProcessesCreated?: () => void }> = observer(
  ({ onProcessesCreated }) => {
    const organisationId = useCurrentOrganisationId();
    const [model] = useState(() => new SystemProcessesUploaderModel(organisationId, onProcessesCreated));

    return (
      <div className="system-process-uploader py-5 px-7">
        <div className="mb-3 d-flex align-items-center">
          <Button onClick={model.downloadTemplate} type={ButtonTypes.OUTLINE_PRIMARY}>
            <Icon symbol={IconSymbols.Download} /> <span className="ml-3">Download Template</span>
          </Button>
          <Tooltip
            position={TooltipPositions.RIGHT}
            shownElement={<Icon symbol={IconSymbols.Info} size={UiSizes.MD} style={{ marginLeft: "8px" }} />}
            triggeredOn="hover"
          >
            <Panel.Panel
              style={{ width: "400px" }}
              className="py-1 px-3"
              background={Panel.PanelBackgrounds.BG_PRIMARY_LIGHT}
              hasShadow={true}
              hasBorderRadius={true}
            >
              <ul style={{ listStyleType: "number" }}>
                <li className="p-1">{I18n.t("phrases.uploadTemplateInstruction_1")}</li>
                <li className="p-1">{I18n.t("phrases.uploadTemplateInstruction_2")}</li>
                <li className="p-1">{I18n.t("phrases.uploadTemplateInstruction_3")}</li>
                <li className="p-1">{I18n.t("phrases.uploadTemplateInstruction_4")}</li>
                <li className="p-1">{I18n.t("phrases.uploadTemplateInstruction_5")}</li>
                <li className="p-1">{I18n.t("phrases.uploadTemplateInstruction_6")}</li>
              </ul>
              <p className="p-1 mt-1">
                NOTE: <em>{I18n.t("phrases.uploadTemplateInstruction_7")}</em>
              </p>
            </Panel.Panel>
          </Tooltip>
        </div>
        <FileDrop onDrop={(files, event) => model.processFiles(files, event)}>
          <IF condition={model.viewState === SystemProcessesUploaderViews.IsUploading}>
            <div className="business-process-uploader__progress">
              <PositionedSpinner />
            </div>
          </IF>

          <IF condition={model.viewState === SystemProcessesUploaderViews.Initial}>
            <div className="business-process-uploader__drop-area">
              <h2>Upload business processes</h2>
              <p>Drop your csv file here to upload the business processes</p>
            </div>
          </IF>

          <IF condition={model.viewState === SystemProcessesUploaderViews.IsNotOneFileError}>
            <div className="business-process-uploader__drop-area">
              <h2>Upload Error</h2>
              <p>Please select only one csv file.</p>
              <Button
                onClick={() => model.setViewState(SystemProcessesUploaderViews.Initial)}
                type={ButtonTypes.OUTLINE_PRIMARY}
              >
                Try again
              </Button>
            </div>
          </IF>
        </FileDrop>
      </div>
    );
  }
);
