import * as React from "react";
import { observer } from "mobx-react";
import { ImpactGroupFormSectionModel } from "./ImpactGroupFormSection_model";
import { SingleForm } from "../singleFormModel/SingleForm_view";
import { Panel } from "../../../../components/ui/Panel";

export interface ImpactGroupFormSectionProps {
  model: ImpactGroupFormSectionModel;
}

@observer
export class ImpactGroupFormSectionView extends React.Component<ImpactGroupFormSectionProps> {
  componentDidMount() {
    this.props.model.onMount();
  }

  render() {
    const { model } = this.props;

    return (
      <div className="col-6">

        <Panel.Panel
          hasBorderRadius={true}
          type={Panel.PanelTypes.OUTLINES}
          className="px-3">
          <div className={`row py-5 bg-gray-200 ${model.impactGroup?.project ? "d-none" : ""}`}>
            <div className="col-12">
              <SingleForm
                model={model.projectForm} />
            </div>
          </div>
          {!!model.projectId && (
            <div className="row mb-4 mt-5">
              <div className="col-12">
                <SingleForm
                  model={model.formModel} />
              </div>
            </div>
          )}
        </Panel.Panel>
      </div>
    );
  }
}
