import { ColDef } from "ag-grid-community";
import _ from "lodash";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import { GridPills } from "../../components/widgets/gridPills/GridPills";
import { EntityTypes, Enums } from "../../enums";
import { baseColumn, baseDateColumn, baseSelectionColumn, baseTextColumn } from "./BaseColumns";
import { IBaseColumnProps } from "./BaseColumns";
import AgGridAutocomplete from "./editors/AgGridAutocomplete";
import {
  areDatesEqual,
  makeCellDeletable,
  makeClearableOnDelete,
  openModalOnDelete,
  stringOrMomentToDate,
  stringToDate
} from "./GridHelpers";
import { progressStatusTextMatcher } from "./filters/TextMatcher";
import I18n from "../localization/I18n";
import { StakeholderPauseCell } from "../../pages/insight/projects/Stakeholders/StakeholdersListView/StakeholderGridView/StakeholderGridView_ui";

export const colSelected = (): ColDef => {
  return baseSelectionColumn();
};

export const colRefNumber = (
  organisationId: number,
  projectId: number,
  entityType: EntityTypes,
  props?: IBaseColumnProps
): ColDef => {
  return {
    ...baseTextColumn({
      fieldName: "refNumber",
      heading: I18n.t("grids.refNo"),
      width: 100,
      pinned: "left",
      clickEditable: true,
      ...props
    }),
    cellRenderer: params => {
      const mainUrl = `/organisations/${organisationId}/projects/${
        !isNaN(projectId) ? projectId : params.data.projectId
      }`;
      const itemId = params.data.id;
      return (
        <Link key={itemId} to={`${mainUrl}/${entityType}/${itemId}`}>
          <span className="hover-link">{params.data.refNumber}</span>
        </Link>
      );
    }
  };
};

export const colName = (
  canEdit: boolean,
  saveFn: any,
  field: number,
  url?: string,
  props?: IBaseColumnProps
): ColDef => {
  let res: ColDef = {
    ...baseTextColumn({
      fieldName: "name",
      heading: I18n.t("grids.name"),
      width: 150,
      pinned: "left",
      autoHeight: true,
      editable: canEdit,
      clickEditable: true,
      ...props
    }),
    valueSetter: params => {
      if (!params.newValue || !saveFn) return false;

      saveFn(field, params.node.id, params.newValue);
      return true;
    }
  };

  if (url) {
    res = {
      ...res,
      cellRenderer: params => {
        const itemId = params.data.id;
        const mainUrl = `${url}/${params.data.stakeholderId}`;
        return (
          <Link key={itemId} to={`${mainUrl}`}>
            <span className="hover-link">{params.data.name}</span>
          </Link>
        );
      }
    };
  }

  return res;
};

export const colOwner = (
  canEdit: boolean,
  projectTeamMembers: any[],
  saveFn: any,
  field: number,
  props: IBaseColumnProps
): ColDef => {
  let res = {
    ...baseTextColumn({
      fieldName: "ownerName",
      heading: I18n.t("grids.owner"),
      editable: canEdit,
      ...props,
      clickEditable: true
    }),
    valueGetter: e => {
      if (!e.data.ownerName) return;
      return `${e.data.ownerName ?? ""}`;
    },
    valueSetter: params => {
      if (!params.newValue || !saveFn) return false;

      params.data.ownerId = params.newValue.id;
      params.data.ownerName = `${params.newValue.firstName} ${params.newValue.lastName}`;

      saveFn(field, params.node.id, params.newValue.id);
      return true;
    },
    cellEditorSelector: () => ({ component: AgGridAutocomplete, popup: true, cellEditorPopupPosition: "over" }),
    cellEditorPopup: true,
    cellEditorParams: {
      field: "owner",
      getValueLabel: (ee: any) => {
        if (!projectTeamMembers) return "";

        const user = projectTeamMembers.find(e => e.id === ee.id);
        if (!user) return "";
        return user.firstName + " " + user.lastName;
      },
      options: !!projectTeamMembers ? projectTeamMembers : [],
      others: projectTeamMembers,
      allowFreeText: true,
      isPerson: true,
      filterFn: (items, query) => {
        const lowerQuery = query.toLowerCase();
        return _.filter(items, (item: FP.Entities.IUser) => {
          const lowerName = `${item.firstName} ${item.lastName}`.toLowerCase();
          const lowerEmail = item.email.toLowerCase();
          return lowerName.indexOf(lowerQuery) > -1 || lowerEmail.indexOf(lowerQuery) > -1;
        });
      }
    }
  };

  res.type = canEdit && makeCellDeletable(res.type);
  return res;
};

export const colTags = (canEdit: boolean, editFn?: any): ColDef => {
  let result: ColDef = {
    ...baseTextColumn({
      fieldName: "tagNames",
      heading: I18n.t("grids.tags"),
      width: 500,
      editable: false,
      autoHeight: true,
      clickEditable: true
    }),
    cellRenderer: e => <GridPills text={e.data.tagNames} />
  };

  if (editFn && canEdit) {
    result.onCellDoubleClicked = e => editFn(e.data);
    result.suppressKeyboardEvent = openModalOnDelete(result, editFn);
  }

  return result;
};

export const colStartDate = (canEdit: boolean, saveFn: any, field: number, props: IBaseColumnProps): ColDef => {
  let res = {
    ...baseDateColumn({
      fieldName: "startDate",
      heading: I18n.t("grids.startDate"),
      editable: canEdit,
      ...props,
      clickEditable: true
    }),
    valueSetter: params => {
      if (!params.newValue || !saveFn) return false;

      let newDate = stringOrMomentToDate(params.newValue);
      let oldDate = stringToDate(params.oldValue);

      if (areDatesEqual(newDate, oldDate) === false) {
        saveFn(field, params.node.id, newDate);
        return true;
      }
      return false;
    }
  };
  res.type = canEdit && makeCellDeletable(res.type);

  return res;
};

export const colEndDate = (canEdit: boolean, saveFn: any, field: number, props: IBaseColumnProps): ColDef => {
  let res = {
    ...baseDateColumn({
      fieldName: "endDate",
      heading: I18n.t("grids.endDate"),
      editable: canEdit,
      clickEditable: true,
      context: "startDate",
      ...props
    }),
    cellRenderer: props => {
      const obj = props.data;

      if (!obj.endDate) return "";

      const today = moment(new Date());
      const isComplete = obj.progressStatus === Enums.ProgressStatus.COMPLETED;
      const isOverdue = today > moment(obj.endDate) && !isComplete;
      const shouldEndToday = today.format("L") === moment(obj.endDate).format("L") && !isComplete;

      let cls = "";
      if (isOverdue) cls = "text-danger";
      if (shouldEndToday) cls = "text-warning";

      return <span className={`${cls}`}>{moment(obj.endDate).format("L")}</span>;
    },
    valueSetter: params => {
      if (!params.newValue || !saveFn) return false;

      let newDate = stringOrMomentToDate(params.newValue);

      saveFn(field, params.node.id, newDate);
      return true;
    }
  };

  res.type = canEdit && makeCellDeletable(res.type);
  return res;
};

export const colProgressStatus = (
  canEdit: boolean,
  progressStatuses: any[],
  saveFn: any,
  field: number,
  props: IBaseColumnProps
): ColDef => {
  return {
    ...baseColumn({
      fieldName: "progressStatus",
      heading: I18n.t("grids.status"),
      editable: canEdit,
      clickEditable: true,
      ...props
    }),
    cellEditorSelector: () => ({ component: AgGridAutocomplete, popup: true, popupPosition: "over" }),
    cellEditorPopup: true,
    valueSetter: params => {
      if (!params.newValue || !saveFn) return false;

      params.data.progressStatus = +params.newValue.key;
      saveFn(field, params.node.id, params.newValue.key);

      return true;
    },
    filter: "agTextColumnFilter",
    cellDataType: "any",
    cellEditorParams: {
      field: "progressStatus",
      getValueLabel: (ee: any) => {
        const progress = progressStatuses.find(e => e.key === ee + "");
        return progress.label;
      },
      options: progressStatuses ?? []
    },
    filterParams: {
      textMatcher: progressStatusTextMatcher
    },
    cellRenderer: props => {
      if (!props.data.progressStatus || !progressStatuses) return null;
      const progress = progressStatuses.find(e => e.key === props.data.progressStatus + "");

      if (!progress) return "";
      return <>{progress.label}</>;
    },
    getQuickFilterText: params => {
      if (!params.data.progressStatus || !progressStatuses) return "";

      const progress = progressStatuses.find(e => +e.key === params.value);

      if (!progress) return "";

      return progress.label;
    }
  };
};

export const colCreatedByName = (): ColDef => {
  return baseTextColumn({ fieldName: "createdByName", heading: I18n.t("grids.createdBy") });
};

export const colCreatedOn = (): ColDef => {
  return baseDateColumn({ fieldName: "createdAt", heading: I18n.t("grids.createdOn") });
};

export const colModifiedByName = (): ColDef => {
  return baseTextColumn({ fieldName: "modifiedByName", heading: I18n.t("grids.lastModifiedBy") });
};

export const colUpdatedOn = (): ColDef => {
  return baseDateColumn({ fieldName: "updatedAt", heading: I18n.t("grids.lastModifiedOn") });
};

export const colReviewedBy = (): ColDef => {
  return baseTextColumn({ fieldName: "reviewedByName", heading: I18n.t("grids.reviewedBy") });
};

export const colLastReviewedOn = (): ColDef => {
  return baseDateColumn({ fieldName: "lastReviewedOn", heading: I18n.t("grids.lastReviewedOn") });
};

export const colNextReviewDue = (): ColDef => {
  return {
    ...baseDateColumn({ fieldName: "nextReviewDue", heading: I18n.t("grids.nextReviewDue") }),
    cellClass: ["ag-cell--not-editable"],
    cellRenderer: props => {
      const obj = props.data;
      if (!props.data.nextReviewDue) return null;

      let now = new Date();

      let cls =
        moment(now) > moment(obj.nextReviewDue) && obj.progressStatus !== Enums.ProgressStatus.COMPLETED
          ? "text-danger"
          : "";
      cls =
        moment(now.getDate() - 7).format("L") > moment(obj.nextReviewDue).format("L") &&
        obj.progressStatus !== Enums.ProgressStatus.COMPLETED
          ? "text-warning"
          : cls;

      let displayValue =
        obj.progressStatus !== Enums.ProgressStatus.COMPLETED ? moment(obj.nextReviewDue).format("L") : "";

      return <span className={cls}>{displayValue}</span>;
    }
  };
};

export const colNoteCount = (canEdit: boolean, editFn?): ColDef => {
  let result: ColDef = {
    ...baseColumn({
      fieldName: "noteCount",
      heading: I18n.t("grids.notes"),
      width: 100,
      editable: false,
      clickEditable: true
    }),
    cellRenderer: e => <div>{e.data.noteCount > 0 ? e.data.noteCount : ""}</div>
  };

  if (editFn && canEdit) {
    result.onCellDoubleClicked = e => editFn(e.data);
    result.suppressKeyboardEvent = openModalOnDelete(result, editFn);
  }
  return result;
};

export const colDescription = (canEdit: boolean, editFn?): ColDef => {
  let result: ColDef = {
    ...baseTextColumn({
      fieldName: "description",
      heading: I18n.t("grids.description"),
      width: 500,
      editable: false,
      clickEditable: true
    }),
    autoHeight: true
  };

  const isEditable = canEdit && editFn !== undefined;
  if (isEditable) {
    result.onCellDoubleClicked = e => {
      editFn(e.data);
    };
    result.suppressKeyboardEvent = openModalOnDelete(result, editFn);
  }

  return result;
};

export const colDynamicRichText = (fieldName: string, heading, canEdit: boolean, editFn?) => {
  let result: ColDef = {
    ...baseTextColumn({ fieldName, heading, width: 500, editable: false, clickEditable: true }),
    autoHeight: true
  };

  const isEditable = canEdit && editFn !== undefined;
  if (isEditable) {
    result.onCellDoubleClicked = e => {
      editFn(e.data);
    };
    result.suppressKeyboardEvent = openModalOnDelete(result, editFn);
  }

  return result;
};

export const colDynamicSimpleText = (fieldName, heading, canEdit: boolean, saveFn?): ColDef => {
  let res: ColDef = {
    ...baseTextColumn({
      fieldName: fieldName,
      heading: heading,
      width: 150,
      autoHeight: true,
      editable: canEdit,
      clickEditable: true
      // ...props
    }),
    valueSetter: params => {
      if (!params.newValue || !saveFn) return false;

      saveFn(params.node.id, params.newValue);
      return true;
    }
  };

  res.suppressKeyboardEvent =
    canEdit &&
    makeClearableOnDelete(res, data => {
      saveFn(data.id, "");
    });

  return res;
};

export const colDynamicMultipleChoice = (
  colName: string,
  header: string,
  canEdit: boolean,
  saveFn: any,
  field: number,
  options: any[],
  props?: IBaseColumnProps
) => {
  let res = {
    ...baseTextColumn({
      fieldName: colName,
      heading: header,
      width: 150,
      editable: canEdit,
      clickEditable: true,
      ...props
    }),
    cellEditorSelector: () => ({ component: AgGridAutocomplete, popup: true, cellEditorPopupPosition: "over" }),
    cellEditorPopup: true,
    valueSetter: params => {
      if (!params.newValue || !saveFn) return false;

      params.data.progressStatus = +params.newValue.key;
      saveFn(params.node.id, params.newValue.key);

      return true;
    },
    cellRenderer: StakeholderPauseCell,
    filter: "agTextColumnFilter",
    filterParams: {
      // textMatcher: sentimentReceptivenessTextMatcher
    },
    cellEditorParams: {
      field: colName,
      getValueLabel: (ee: any) => {
        const receptiveness = options.find(e => e.key === ee + "");
        return receptiveness ? receptiveness.label : "";
      },
      options: options ?? []
    },

    getQuickFilterText: params => {
      if (!params.data.receptiveness || !options) return "";

      const receptiveness = options.find(e => +e.key === params.value);

      if (!receptiveness) return "";

      return receptiveness.label;
    }
  };

  res.suppressKeyboardEvent =
    canEdit &&
    makeClearableOnDelete(res, data => {
      saveFn(data.id, null);
    });
  return res;
};

export const colImpacts = (canEdit: boolean, editFn?) => {
  let result: ColDef = {
    ...baseTextColumn({
      fieldName: "impacts",
      heading: I18n.t("grids.impacts"),
      width: 500,
      editable: false,
      autoHeight: true,
      clickEditable: true
    }),
    cellRenderer: e => {
      return <GridPills text={e.data.impacts} />;
    }
  };

  if (editFn && canEdit) {
    result.onCellDoubleClicked = e => {
      editFn(e.data);
    };
    result.suppressKeyboardEvent = openModalOnDelete(result, editFn);
  }

  return result;
};

export const colProjectName = (
  canEdit: boolean,
  saveFn: any,
  field: number,
  url?: string,
  props?: IBaseColumnProps
): ColDef => {
  let res: ColDef = {
    ...baseTextColumn({
      fieldName: "projectName",
      heading: I18n.t("grids.project"),
      width: 150,
      pinned: "left",
      autoHeight: true,
      editable: canEdit,
      clickEditable: canEdit || !!url,
      ...props
    }),
    valueSetter: params => {
      if (!params.newValue || !saveFn) return false;

      saveFn(field, params.node.id, params.newValue);
      return true;
    }
  };

  if (url) {
    res = {
      ...res,
      cellRenderer: params => {
        const itemId = params.data.id;
        const mainUrl = `${url}/projects/${params.data.projectId}`;
        return (
          <Link key={itemId} to={`${mainUrl}`} target="_blank">
            <span className="hover-link">{params.data.projectName}</span>
          </Link>
        );
      }
    };
  }
  return res;
};

export const colProgrammeName = (
  canEdit: boolean,
  saveFn: any,
  field: number,
  url?: string,
  props?: IBaseColumnProps
): ColDef => {
  let res: ColDef = {
    ...baseTextColumn({
      fieldName: "programmeName",
      heading: I18n.t("grids.programme"),
      width: 150,
      pinned: "left",
      autoHeight: true,
      editable: canEdit,
      clickEditable: canEdit || !!url,
      ...props
    }),
    valueSetter: params => {
      if (!params.newValue || !saveFn) return false;

      saveFn(field, params.node.id, params.newValue);
      return true;
    }
  };

  if (url) {
    res = {
      ...res,
      cellRenderer: params => {
        const itemId = params.data.id;
        const mainUrl = `${url}/programmes/${params.data.programmeId}`;
        if (params.data.programmeName) {
          return (
            <Link key={itemId} to={`${mainUrl}`} target="_blank">
              <span className="hover-link">{params.data.programmeName}</span>
            </Link>
          );
        } else {
          return <span>{I18n.get("phrases.standaloneProject")}</span>;
        }
      }
    };
  }
  return res;
};
