import { IconSymbols } from "@flightpath/coreui/dist/generated/IconSymbols";
import React, { useEffect, useRef, useState } from "react";
import AnimateHeight from "react-animate-height";
import { createPortal } from "react-dom";
import { useLocation } from "react-router-dom";
import { IF } from "../../../components/hoc/If";
import { Breadcrumb } from "../../../components/ui/Breadcrumb";
import { parseUrlForProgrammeId, parseUrlForProjectId } from "../../../constants";
import { useGetAllProjects, usePermissionUserOrganisations } from "../../../contexts/permissions/PermissionHooks";
import PermissionsContext from "../../../contexts/permissions/PermissionsContext";
import { useCurrentOrganisation } from "../../../services/local/organisationContext/OrganisationContextModel";
import { NAV_UI_PORTAL } from "./TopNavigationsProvider";
import { MegaMenu, MegaMenuSections } from "./menus/MegaMenu";
import { OrganisationNavList } from "./menus/OrganisationNavList";
import { ProjectNavList } from "./menus/ProjectNavList";
import { usePrevious } from "react-use";

export const NavBreadcrumbs: React.FC<any> = props => {
  const location = useLocation();
  const [, projectId] = parseUrlForProjectId(location.pathname);
  const [, programmeId] = parseUrlForProgrammeId(location.pathname);
  const myRef = useRef(null);
  const orgDropdownRef = useRef(null);
  const currentOrganisation = useCurrentOrganisation();
  const organisationId = currentOrganisation.id;
  const [section, setSection] = useState<MegaMenuSections>();
  let [isDropdownVisible, setIsDropdownVisible] = useState(false);
  let [isOrgDropdownVisible, setIsOrgDropdownVisible] = useState(false);
  let dropdownRef = useRef(null);
  let prevIsDropdownVisible = usePrevious(isDropdownVisible);
  let prevIsOrgDropdownVisible = usePrevious(isOrgDropdownVisible);

  const orgCount = Object.keys(usePermissionUserOrganisations()).length;

  const projectCount = Object.keys(useGetAllProjects(organisationId)).length;

  const orgBreadcrumbConfig = {
    ...GetOrganisationBreadcrumbConfig(currentOrganisation, orgCount),
    onClick: () => {
      if (orgDropdownRef.current.isDropdownVisible) {
        setIsOrgDropdownVisible(false);
        orgDropdownRef?.current.setIsDropdownVisible(false);
        return;
      }
      orgDropdownRef?.current.setIsDropdownVisible(true);
      setIsOrgDropdownVisible(true);
    }
  };

  const projectBreadcrumbConfig = {
    ...GetProjectBreadcrumbConfig(organisationId, projectId),
    onClick: () => {
      // if (Object.keys(PermissionsContext.getAllProjects(organisationId)).length < 15) return;
      if (section === MegaMenuSections.PROJECT && myRef.current.isDropdownVisible) {
        setIsDropdownVisible(false);
        myRef.current.setIsDropdownVisible(false);
        setSection(null);
        return;
      }
      setIsDropdownVisible(true);
      myRef?.current.setIsDropdownVisible(true);
      setSection(MegaMenuSections.PROJECT);
    }
  };

  function handleClickOutsideMyRef(event) {
    // TODO: tidy up the handler there must be a better way of doing this
    let isMyRefValid =
      myRef.current.isDropdownVisible &&
      myRef &&
      myRef.current &&
      !event.target.matches("#nav_ui_portal, #nav_ui_portal *") &&
      (myRef as any).current.breadcrumbRef &&
      !(myRef as any).current.breadcrumbRef.current.contains(event.target);

    if (dropdownRef && dropdownRef.current && isMyRefValid) {
      setIsDropdownVisible(false);
      myRef.current.setIsDropdownVisible(false);
    } else if (projectCount < 15 && isDropdownVisible && isMyRefValid) {
      setIsDropdownVisible(false);
      myRef.current.setIsDropdownVisible(false);
    }
  }

  function handleClickOutsideOrgDropdownRef(event) {
    let isOrgDropdownRefValid =
      (orgDropdownRef.current.isDropdownVisible || myRef.current.isDropdownVisible) &&
      orgDropdownRef &&
      orgDropdownRef.current &&
      (orgDropdownRef as any).current.breadcrumbRef &&
      !event.target.matches("#orgNavList, #orgNavList *") &&
      !(orgDropdownRef as any).current.breadcrumbRef.current.contains(event.target);

    if (isOrgDropdownRefValid) {
      setIsOrgDropdownVisible(false);
      orgDropdownRef.current.setIsDropdownVisible(false);
    }
  }

  useEffect(() => {
    if (isDropdownVisible && isDropdownVisible !== isOrgDropdownVisible) {
      document.addEventListener("click", handleClickOutsideMyRef);
    }
    if (isOrgDropdownVisible && isDropdownVisible !== isOrgDropdownVisible) {
      document.addEventListener("click", handleClickOutsideOrgDropdownRef);
    }
    if (!isDropdownVisible) {
      document.removeEventListener("click", handleClickOutsideMyRef);
    }
    if (!isOrgDropdownVisible) {
      document.removeEventListener("click", handleClickOutsideOrgDropdownRef);
    }

    if (prevIsDropdownVisible !== isDropdownVisible && !!isDropdownVisible && !!isOrgDropdownVisible) {
      setIsOrgDropdownVisible(false);
      orgDropdownRef.current.setIsDropdownVisible(false);
    }
    if (prevIsOrgDropdownVisible !== isOrgDropdownVisible && !!isOrgDropdownVisible && !!isDropdownVisible) {
      setIsDropdownVisible(false);
      myRef.current.setIsDropdownVisible(false);
    }

    return function removeListender() {
      document.removeEventListener("click", handleClickOutsideMyRef);
      document.removeEventListener("click", handleClickOutsideOrgDropdownRef);
    };
    // eslint-disable-next-line
  }, [isDropdownVisible, isOrgDropdownVisible]);

  useEffect(() => {
    setIsDropdownVisible(false);
    setIsOrgDropdownVisible(false);
    myRef.current.setIsDropdownVisible(false);
    orgDropdownRef.current.setIsDropdownVisible(false);
  }, [location.pathname]);

  return (
    <div className="nav-breadcrumps d-flex flex-row" tabIndex={0}>
      <IF condition={projectCount >= 15}>
        {createPortal(
          <AnimateHeight height={section && isDropdownVisible ? "auto" : 0}>
            <IF condition={isDropdownVisible}>
              <div ref={dropdownRef}>
                <MegaMenu section={section} projectId={projectId} programmeId={programmeId} />
              </div>
            </IF>
          </AnimateHeight>,
          document.getElementById(NAV_UI_PORTAL)
        )}
      </IF>

      <Breadcrumb
        {...orgBreadcrumbConfig}
        ref={orgDropdownRef}
        iconSymbol={isOrgDropdownVisible ? IconSymbols.ChevronUp : IconSymbols.ChevronDown}
        id="orgNavList"
      >
        <IF condition={isOrgDropdownVisible}>{orgCount > 1 && <OrganisationNavList />}</IF>
      </Breadcrumb>

      <Breadcrumb
        ref={myRef}
        {...projectBreadcrumbConfig}
        hasLeftBorder={true}
        iconSymbol={
          section === MegaMenuSections.PROJECT && isDropdownVisible ? IconSymbols.ChevronUp : IconSymbols.ChevronDown
        }
      >
        <IF condition={isDropdownVisible}>{projectCount < 15 && <ProjectNavList projectId={projectId} />}</IF>
      </Breadcrumb>
    </div>
  );
};

const GetOrganisationBreadcrumbConfig = (organisation: FP.Entities.IOrganisation, organisationCount: number) => {
  return {
    title: "Organisation",
    className: "mr-3",
    name: organisation.name,
    shouldHideOnClick: true,
    imageSource: organisation.profileImageUrl,
    isDropdown: organisationCount > 1
  };
};

const GetProjectBreadcrumbConfig = (organisationId: number, projectId: number) => {
  const project = PermissionsContext.getProject(organisationId, projectId);
  let res = {
    className: "pl-3",
    title: "Project",
    isDropdown: true,
    shouldHideOnClick: false
  };
  if (!project)
    return {
      ...res,
      name: "Select a project"
    };
  return { ...res, name: project.name };
};
