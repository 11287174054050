import { IconSymbols } from "@flightpath/coreui/dist/generated/IconSymbols";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { IF } from "../../../../../../components/hoc/If";
import { ButtonIcon, ButtonTypes } from "../../../../../../components/ui/Button";
import { Panel } from "../../../../../../components/ui/Panel";
import { PositionedSpinner } from "../../../../../../components/ui/PositionedSpinner";
import { useCurrentOrganisationId } from "../../../../../../services/local/organisationContext/OrganisationContextModel";
import { ActionSummaryChartModel } from "./ActionSummaryChart_model";
import "./_action-summary-chart.scss";
import { Enums } from "../../../../../../enums";
import { BarChart, BarChartProps } from "../../../../../../core/charts/BarChart/BarChart";

export type ActionSummaryChartProps = {
  loadDataFn: (organisationId: number, projectId: number) => Promise<any>;
  title: string;
  transformer: (data: any[]) => BarChartProps;
  clearFilterFN?: () => any;
  filterModalFn?: any;
};

export const ActionSummaryChart: React.FC<ActionSummaryChartProps> = observer(
  ({ title, loadDataFn, transformer, filterModalFn, clearFilterFN }) => {
    const organisationId = useCurrentOrganisationId();
    const { projectId } = useParams();

    const [model] = useState(() => new ActionSummaryChartModel(organisationId, +projectId, loadDataFn, transformer));

    useEffect(() => {
      model.onMount();
      // eslint-disable-next-line
    }, []);

    return (
      <Panel.Panel hasBorder hasBorderRadius className="action-summary-chart p-3">
        <div className="col">
          <h2 className="mt-0">
            {title}
            <IF condition={filterModalFn}>
              <ButtonIcon
                size={Enums.UiSizes.XS}
                symbol={IconSymbols.ClearFilter}
                type={ButtonTypes.OUTLINE_PRIMARY}
                className="float-right"
                onClick={() => {
                  clearFilterFN();
                }}
              />
              <ButtonIcon
                size={Enums.UiSizes.XS}
                symbol={IconSymbols.Filter}
                type={ButtonTypes.OUTLINE_PRIMARY}
                className="float-right mr-2"
                onClick={filterModalFn}
              />
            </IF>
          </h2>
        </div>
        <IF condition={model.isLoading}>
          <PositionedSpinner />
        </IF>

        <div className="action-summary-chart__wrapper">
          <IF condition={!model.isLoading && model.chartData.data.length === 0}>
            <div className="col">
              <p>There was no data found for this parameters</p>
            </div>
          </IF>
          <IF condition={!model.isLoading && model.chartData.data.length > 0}>
            <BarChart {...model.chartData} />
          </IF>
        </div>
      </Panel.Panel>
    );
  }
);
