import React from "react";
import { ActionRagsCard } from "./ActionRagsCard";
import { ImpactLevelsCard } from "./ImpactLevelsCard";
import { TotalsCard } from "./TotalsCard";

interface DashboardContentReportingBarProps {
  programmeCount: number;
  impactLevelCounts: any[];
  actionRagCounts: any[];
  isLoading: boolean;
}

export const ReportingBar: React.FC<DashboardContentReportingBarProps> = ({
  programmeCount,
  impactLevelCounts,
  actionRagCounts,
  isLoading
}) => {
  return (
    <div className="row pt-4 justify-content-between">
      <TotalsCard programmeCount={programmeCount} isLoading={isLoading} />
      <ImpactLevelsCard impactLevelCounts={impactLevelCounts} isLoading={isLoading} />
      <ActionRagsCard actionRagCounts={actionRagCounts} isLoading={isLoading} />
    </div>
  );
};
