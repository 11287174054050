import * as React from "react";
import { observer } from "mobx-react";
import { ITableModel } from "./ITableModel";
import Table from "../../components/ui/Table";
import DivTable from "../../components/ui/Table/DivTable";
import { TableRowView } from "./TableRow_view";
import { TableHeaderView } from "./TableHeader_view";
import { Placeholder } from "../../components/ui/Placeholder";
import I18n from "../localization/I18n";

export interface TableViewProps {
  model: ITableModel<any>;
}

@observer
export class TableView extends React.Component<TableViewProps, any> {
  getTableRows = () => {
    let { model } = this.props;
    return model.data.map((rowModel, i) => {
      return <TableRowView key={i} model={rowModel} />;
    });
  };

  getHeaders = () => {
    let { model } = this.props;
    return model.colHeaders.map((e, i) => {
      e.isDivTable = model.config.isDivTable;
      return (
        <TableHeaderView
          className={`${e.config.cellClassName || ""} ${e.config.key === "actions" ? "table-cell--action" : ""}`}
          onClick={() => e.action && e.action(e)}
          key={i}
          model={e}
        />
      );
    });
  };

  render() {
    let { model } = this.props;
    let hasHeaders = model.config.colHeaders?.length > 0;
    let hasBody = model.data?.length > 0;
    let T = model.config.isDivTable ? DivTable : Table;

    if (model.isLoading) {
      return (
        <Placeholder
          componentProps={{
            numberOfRows: 4,
            numberOfColumns: model.colHeaders?.length
          }}
          showIf={true}
          type="Table"
        />
      );
    }
    let cls = model.config.renderExpandContent ? "table--ex" : "";
    cls = cls + `${(model.config.tableProps && model.config.tableProps.className) || ""}`;
    const headerProps = model.config?.tableProps?.headerProps;
    const tableProps = model.config?.tableProps;
    if (tableProps) delete tableProps.headerProps;
    return (
      <T.Table {...tableProps} className={`${cls}`}>
        {hasHeaders && <T.Header {...headerProps}>{this.getHeaders()}</T.Header>}

        <T.Body style={{ maxHeight: model.config.height || "auto" }}>
          {hasHeaders && hasBody && this.getTableRows()}
        </T.Body>

        {model.data?.length === 0 && !model.isLoading && (
          <T.Footer>
            <T.Row className="d-flex justify-content-center col-12">
              <T.Col className="d-flex justify-content-center" colSpan={model.colHeaders?.length}>
                <p className="mb-0">{I18n.t("table.noData")}</p>
              </T.Col>
            </T.Row>
          </T.Footer>
        )}
      </T.Table>
    );
  }
}
