import { observer } from "mobx-react";
import React from "react";
import { PositionedSpinner } from "../../../../../../../../components/ui/PositionedSpinner";
import { ProjectProcessLocationsSidebarModel } from "./ProjectProcessLocationsSidebar_model";
import { SingleForm } from "../../../../../../../../pages/change/forms/singleFormModel/SingleForm_view";

export interface ProjectProcessLocationsSidebarProps {
  model: ProjectProcessLocationsSidebarModel;
}

export const ProjectProcessLocationsSidebar: React.FC<ProjectProcessLocationsSidebarProps> = observer(({ model }) => {
  if (model.isLoading) return <PositionedSpinner />;

  return (
    <div className="container-fluid">
      <SingleForm model={model.formModel} />
    </div>
  );
});
