import { observer } from "mobx-react";
import React, { useState } from "react";
import { PositionedSpinner } from "../../../../../components/ui/PositionedSpinner";
import { useCurrentOrganisationId } from "../../../../../services/local/organisationContext/OrganisationContextModel";
import { useParams } from "react-router-dom";
import { LocationsSidebarModel } from "./LocationsSidebar_model";
import { SingleForm } from "../../../../../pages/change/forms/singleFormModel/SingleForm_view";

export interface LocationsSidebarProps {
  onSubmit: (locationIds: number[]) => void;
  currentIds: number[];
}

export const LocationsSidebar: React.FC<LocationsSidebarProps> = observer(({ onSubmit, currentIds }) => {
  const organisationId = useCurrentOrganisationId();
  const { projectId } = useParams();
  const [model] = useState(() => new LocationsSidebarModel(organisationId, +projectId, onSubmit, currentIds));
  if (model.isLoading) return <PositionedSpinner />;

  return (
    <div className="container-fluid">
      <SingleForm model={model.formModel} />
    </div>
  );
});
