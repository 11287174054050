import { Authentication } from "../../core/auth_insight/Authenticate";
import { Outlet } from "react-router-dom";
import OktaAuth from "@okta/okta-auth-js";
import { ClaimsContextProvider } from "../../core/auth/authorise";
import { Navigation } from "../../pages/insight/navigation/Navigation";
import {
  OrganisationContextModel,
  OrganisationContextProvider
} from "../../services/local/organisationContext/OrganisationContextModel";
import { useState } from "react";
import { ModalContextProvider } from "../../core/modalZ/context/ModalContext";

export const InsightApp: React.FC<{ restoreOriginalUri: any; authService: OktaAuth }> = ({
  restoreOriginalUri,
  authService
}) => {
  const [organisationContext] = useState(() => new OrganisationContextModel());
  return (
    <Authentication restoreOriginalUri={restoreOriginalUri} authService={authService}>
      <ClaimsContextProvider>
        <OrganisationContextProvider model={organisationContext}>
          <ModalContextProvider>
            <Navigation>
              <Outlet />
            </Navigation>
          </ModalContextProvider>
        </OrganisationContextProvider>
      </ClaimsContextProvider>
    </Authentication>
  );
};
