import { observer } from "mobx-react";
import React from "react";
import { PositionedSpinner } from "../../../../../../../../components/ui/PositionedSpinner";
import { ImpactGroupsSidebarModel } from "./impactGroupsSidebar_model";
import { SingleForm } from "../../../../../../../../pages/change/forms/singleFormModel/SingleForm_view";

interface ImpactGroupsSidebarProps {
  model: ImpactGroupsSidebarModel;
}

export const ImpactGroupsSidebar: React.FC<ImpactGroupsSidebarProps> = observer(({ model }) => {
  if (model.isLoading) return <PositionedSpinner />;

  return (
    <div className="container-fluid">
      <SingleForm model={model.formModel} />
    </div>
  );
});
