import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
import { GridHubStoreWrapper } from "../../../../../apps/insight/stores/GridHubStoreWrapper";
import { useStores } from "../../../../../apps/insight/stores/RootStore";
import { useFlightPathUser } from "../../../../../core/auth_insight";
import { ProjectProcessesGrid } from "../../../../../core/grids/builder/directorBuilder/grids/ProjectProcessesGrid/ProjectProcessesGridView_view";
import { useCurrentOrganisationId } from "../../../../../services/local/organisationContext/OrganisationContextModel";
import "./_processes.scss";
export interface IProcessesViewProps {}

const ProcessesView: React.FunctionComponent<IProcessesViewProps> = observer(() => {
  const { projectId } = useParams<{ projectId: string }>();
  const organisationId = useCurrentOrganisationId();

  const authUser = useFlightPathUser();

  const { projectProcessStore } = useStores();

  return (
    <GridHubStoreWrapper
      store={projectProcessStore}
      projectId={+projectId}
      organisationId={organisationId}
      authUser={authUser}
    >
      <div className="processes-view insight-tab-content__space-filler">
        <ProjectProcessesGrid
          data={projectProcessStore.data}
          connectedUsers={projectProcessStore.connectedUsers}
          onCellStateChange={projectProcessStore.updateUserSelectedCell}
        />
      </div>
    </GridHubStoreWrapper>
  );
});

export { ProcessesView };
