import { PanelBackgrounds } from "@flightpath/coreui/dist/ui/Panel";
import React, { useState } from "react";
import { Icon, IconSymbols } from "../../ui/Icon";
import { Panel } from "../../ui/Panel";

interface DropdownListProps extends ReactProps {
  title: string;
  className?: string;
  styles?: React.CSSProperties;
  hasData?: boolean;
}

export const DropdownList: React.FC<DropdownListProps> = props => {
  const { title, className, hasData } = props;
  const [isClicked, setIsClicked] = useState(false);

  return (
    <div className="my-1">
      <Panel.Panel
        type={Panel.PanelTypes.OUTLINES}
        hasBorderRadius={true}
        background={PanelBackgrounds.BG_WHITE}
        onClick={() => (hasData ? setIsClicked(!isClicked) : setIsClicked(isClicked))}
        className={`d-flex p-3 align-items-center justify-content-between ${className ? className : ""}`}
      >
        <h3 className="mb-0">{title}</h3>
        <Icon className="float-right" symbol={isClicked ? IconSymbols.ChevronUp : IconSymbols.ChevronDown} />
      </Panel.Panel>
      {isClicked && hasData && props.children}
    </div>
  );
};
