import React, { useEffect, useState } from "react";
import { MilestoneSettingsModel } from "./MilestoneSettings_model";
import { TableView } from "../../../../../core/table/Table_view";
import { observer } from "mobx-react";
import { Button, ButtonTypes, LinkButton } from "../../../../../components/ui/Button";
import I18n from "../../../../../core/localization/I18n";
import { SearchButton } from "../../../../../components/ui/SearchButton";
import { useCurrentOrganisationId } from "../../../../../services/local/organisationContext/OrganisationContextModel";
import { Animations } from "../../../../../core/util/Animations";
import { useParams } from "react-router-dom";
import { PositionedSpinner } from "../../../../../components/ui/PositionedSpinner";

const MilestoneSettingsView: React.FunctionComponent = observer(() => {
  const { programmeId } = useParams();
  const organisationId = useCurrentOrganisationId();
  const [model] = useState(() => new MilestoneSettingsModel(organisationId, +programmeId));
  useEffect(() => {
    model.onMount();
  }, [model]);

  if (model.isLoading) return <PositionedSpinner />;

  return (
    <div className={`container-fluid milestone-settings-view pt-6 ${Animations.FP_ZOOM_IN} speed-4`}>
      <div className="row">
        <div className="col">
          <h1 className="d-inline-block">
            {model.programme?.name} | {I18n.t("phrases.manageMilestones")}
          </h1>
          <LinkButton
            id="CloseProgrammeSettingsButton"
            type={ButtonTypes.OUTLINE_PRIMARY}
            href={`/organisations/${organisationId}/programmes/${model.programme?.id}`}
            className="float-right"
          >
            {I18n.t("phrases.closeSettings")}
          </LinkButton>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-lg-7">
          <p>{I18n.t("phrases.manageProgrammeMilestonesDescription")}</p>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col">
          <Button
            id="ShowMilestoneFormButton"
            onClick={() => {
              model.showMilestoneModal();
            }}
          >
            {I18n.t("phrases.createMilestone")}
          </Button>

          <SearchButton className={"float-right"} value={""} onChange={() => {}} />
        </div>
      </div>

      <div className="row">
        <div className="col">
          <TableView model={model.tableModel} />
        </div>
      </div>
    </div>
  );
});

export { MilestoneSettingsView };
