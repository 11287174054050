import { AxiosInstance } from "axios";
import { FilteredOrganisationApiModel } from "../filteredApi/FilteredOrganisationApiModel";
import http from "../Http";

export class LocationsApi extends FilteredOrganisationApiModel<FP.Entities.ILocation> {
  controller: string = "locations";

  constructor(http: AxiosInstance) {
    super(http, "locations");
  }
}
const instance = new LocationsApi(http);
export default instance;
