import { useParams } from "react-router-dom";
import { GridHubStoreWrapper } from "../../../../../apps/insight/stores/GridHubStoreWrapper";
import { useFlightPathUser } from "../../../../../core/auth_insight";
import { useCurrentOrganisationId } from "../../../../../services/local/organisationContext/OrganisationContextModel";
import { useStores } from "../../../../../apps/insight/stores/RootStore";
import { useEffect } from "react";
import { AudiencesGrid } from "../../../../../core/grids/builder/directorBuilder/grids/AudiencesGrid/AudiencesGridView_view";
import { observer } from "mobx-react-lite";
import { IF } from "../../../../../components/hoc/If";
import { PositionedSpinner } from "../../../../../components/ui/PositionedSpinner";

export const AudienceView: React.FC = observer(() => {
  const { projectId } = useParams();
  const authUser = useFlightPathUser();
  const organisationId = useCurrentOrganisationId();
  const { audienceStore } = useStores();

  useEffect(() => {
    audienceStore.canInvoke() && audienceStore.invokeDataLoad(organisationId, +projectId, authUser);
  }, [audienceStore, organisationId, projectId, authUser]);

  return (
    <GridHubStoreWrapper
      store={audienceStore}
      projectId={+projectId}
      organisationId={organisationId}
      authUser={authUser}
    >
      <div className="audiences-view insight-tab-content__space-filler">
        <IF condition={audienceStore.isLoading}>
          <PositionedSpinner />
        </IF>
        <IF condition={!audienceStore.isLoading}>
          <AudiencesGrid
            isLoading={audienceStore.isLoading}
            data={audienceStore.data}
            columnDefs={audienceStore.columnDefs}
            connectedUsers={audienceStore.connectedUsers}
            onCellStateChange={audienceStore.updateUserSelectedCell}
          />
        </IF>
      </div>
    </GridHubStoreWrapper>
  );
});
