import { FORM_COL } from "../../../../../../../constants";
import { ISearchTreePickerModel } from "../../../../../../../core/forms/controls/searchTreePicker/ISearchTreePickerModel";
import { INIT_SEARCHTREEPICKER } from "../../../../../../../core/forms/controls/searchTreePicker/SearchTreePicker_model";
import { generateFormFieldsFromJson } from "../../../../../../../core/forms/helpers/FormFieldMappers";
import I18n from "../../../../../../../core/localization/I18n";
import ModalContext from "../../../../../../../core/modalZ/context/ModalContext";
import { UiActionRenderers } from "../../../../../../../core/uiAction/IUiAction";

export const getProjectProcessesTreeSidebarFormActions = (updateFn: () => void, canEdit: boolean) => {
  let actions = [];

  if (canEdit)
    actions.push({
      id: "action1",
      label: I18n.t("phrases.done"),
      onAction: ev => {
        updateFn();
        ModalContext.hide();
      },
      componentProps: {
        className: "ml-auto"
      },
      rendersIn: UiActionRenderers.BUTTON
    });

  return actions;
};

export const getProjectProcessesTreeSidebarFormFields = (projectProcesses: any, selectedItemIds: number[]) => {
  if (selectedItemIds) {
    projectProcesses.forEach(e => {
      if (selectedItemIds.indexOf(e.id) >= 0) {
        e.isChecked = true;
      }
    });
  } else {
    projectProcesses.forEach(e => {
      if (e.parent === 0) {
        e.isChecked = true;
      }
    });
  }

  const projectProcessesField: Partial<ISearchTreePickerModel> = {
    ...INIT_SEARCHTREEPICKER,
    key: "projectProcesses",
    value: projectProcesses.filter(e => e.isChecked),
    options: projectProcesses,
    placeholder: I18n.t("placeholders.searchProjectProcesses"),
    fieldClassName: FORM_COL.FULL_WIDTH,
    isCascading: false
  };

  let fields = [];
  fields.push(projectProcessesField);

  return generateFormFieldsFromJson(fields);
};
