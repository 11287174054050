import { AxiosInstance, AxiosRequestConfig } from "axios";
import { IHeatmapQuery } from "../../../../pages/insight/organisations/dashboard/Heatmap/Heatmap_model";
import { BaseApiModel, IFlightPathApiResponse } from "../BaseApiModel";
import {
  FullHeatmapData,
  IDrillThroughQuery
} from "../../../../pages/insight/organisations/dashboard/Heatmap/Heatmap_utils";
import {
  DrillThroughImpactTable,
  IBusinessAreaImpactsTableQuery
} from "../../../../pages/insight/organisations/organisationDashboard/models/dashboard_interfaces";
import { HeatmapSupportObject } from "../../../../pages/insight/organisations/dashboard/Heatmap/SupportingHeatmapData/SupportingHeatmap_model";
import Http from "../Http";

export class HeatmapsApi extends BaseApiModel<any> {
  controller: string = "organisations";

  constructor(http: AxiosInstance) {
    super(http, "organisations");
  }

  getBusinessAreasHeatmap = async (
    organisationId: number,
    query: IHeatmapQuery,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<any>> => {
    const parentId = query.id;
    const startMonth = query.startDateMonth;
    const startYear = query.startDateYear;
    const tagIds = query.tagIds;
    const url = `${this.url}${organisationId}/heatmaps/business-areas/${parentId}/${startMonth}/${startYear}/${tagIds}`;

    const result = await this.http.get(url, config);
    return result.data;
  };

  getBusinessAreasHeatmapSupportingData = async (
    organisationId: number,
    tagIds: string,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<any>> => {
    const url = `${this.url}${organisationId}/heatmaps/business-areas/supporting-data/${tagIds}`;

    const result = await this.http.get(url, config);
    return result.data;
  };

  getBusinessAreaDrillThroughPieData = async (
    organisationId: number,
    query: IDrillThroughQuery,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<any>> => {
    const id = query.id;
    const month = query.month;
    const year = query.year;
    const tagIds = query.tagIds;
    const url = `${this.url}${organisationId}/heatmaps/business-areas/drill-through-pie/${id}/${month}/${year}/${tagIds}`;

    const result = await this.http.get(url, config);

    return result.data;
  };

  getBusinessAreasImpactTabelData = async (
    organisationId: number,
    query: IBusinessAreaImpactsTableQuery,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<DrillThroughImpactTable>> => {
    const projectId = query.projectId;
    const month = query.month;
    const year = query.year;
    const businessAreaId = query.businessAreaId;

    const url = `${this.url}${organisationId}/
      heatmaps/
      business-areas/
      drill-through-table/
      ${projectId}/${month}/${year}/${businessAreaId}`;

    const result = await this.http.get(url, config);
    return result.data;
  };

  getLocationsHeatmap = async (
    organisationId: number,
    query: IHeatmapQuery,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FullHeatmapData>> => {
    const parentId = query.id;
    const startMonth = query.startDateMonth;
    const startYear = query.startDateYear;
    const tagIds = query.tagIds;
    const url = `${this.url}${organisationId}/heatmaps/locations/${parentId}/${startMonth}/${startYear}/${tagIds}`;

    const result = await this.http.get(url, config);
    return result.data;
  };

  getLocationsHeatmapSupportingData = async (
    organisationId: number,
    tagIds: string,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<HeatmapSupportObject>> => {
    const url = `${this.url}${organisationId}/heatmaps/locations/supporting-data/${tagIds}`;

    const result = await this.http.get(url, config);
    return result.data;
  };

  getLocationsDrillThroughPieData = async (
    organisationId: number,
    query: IDrillThroughQuery,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<any>> => {
    const id = query.id;
    const month = query.month;
    const year = query.year;
    const tagIds = query.tagIds;
    const url = `${this.url}${organisationId}/heatmaps/locations/drill-through-pie/${id}/${month}/${year}/${tagIds}`;

    const result = await this.http.get(url, config);
    return result.data;
  };

  getProjectDrillThroughPieData = async (
    organisationId: number,
    query: IDrillThroughQuery,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<any>> => {
    const id = query.id;
    const month = query.month;
    const year = query.year;
    const tagIds = query.tagIds;
    const url = `${this.url}${organisationId}/heatmaps/projects/drill-through-pie/${id}/${month}/${year}/${tagIds}`;

    const result = await this.http.get(url, config);
    return result.data;
  };

  getProjectsHeatmap = async (
    organisationId: number,
    query: IHeatmapQuery,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<any>> => {
    const parentId = query.id;
    const startMonth = query.startDateMonth;
    const startYear = query.startDateYear;
    const tagIds = query.tagIds;
    const url = `${this.url}${organisationId}/heatmaps/projects/${parentId}/${startMonth}/${startYear}/${tagIds}`;

    const result = await this.http.get(url, config);
    return result.data;
  };

  getProjectsHeatmapSupportingData = async (
    organisationId: number,
    tagIds: string,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<any>> => {
    const url = `${this.url}${organisationId}/heatmaps/projects/supporting-data/${tagIds}`;

    const result = await this.http.get(url, config);
    return result.data;
  };
}

let instance = new HeatmapsApi(Http);

export default instance;
