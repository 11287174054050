import { PermissionScope } from "../../enums";
import { usePermissionContext } from "./PermissionsContext";
import { PermissionFields } from "./PermissionsTypes";

export function usePermissionOrganisation(organisationId: number) {
  return usePermissionContext().getOrganisation(organisationId);
}

export function usePermissionProject(organisationId: number, projectId: number) {
  return usePermissionContext().getProject(organisationId, projectId);
}

export function useUserBelongsToOrgs() {
  return usePermissionContext().hasOrganisations();
}

export function usePermissionUserOrganisations() {
  return usePermissionContext().getAllOrganisations();
}

export function useGetAllProjects(organisationId: number) {
  return usePermissionContext().getAllProjects(organisationId);
}

export function useGetAllProgrammes(organisationId: number) {
  return usePermissionContext().getAllProgrammes(organisationId);
}
export function useGetProgrammeByProjectId(organisationId: number, projectId: number) {
  return usePermissionContext().getProgrammeByProjectId(organisationId, projectId);
}

export function useIsOrganisationAdmin(organisationId: number) {
  return usePermissionContext().isOrganisationAdmin(organisationId);
}

export function useIsOrganisationOwnerOrAdmin(organisationId: number) {
  return usePermissionContext().isOranisationOwnerOrAdmin(organisationId);
}

export function useCanEditOrganisation(organisationId: number) {
  return usePermissionContext().canEditOrganisation(organisationId);
}

export function useCanManagePermissions(organisationId: number, projectId: number) {
  return usePermissionContext().canManagePermissions(organisationId, projectId);
}

export function useCanEditField(field: PermissionFields, organisationId: number, projectId: number) {
  return usePermissionContext().canEditField(field, organisationId, projectId);
}

export function useCanViewField(field: PermissionFields, organisationId: number, projectId: number) {
  return usePermissionContext().canViewField(field, organisationId, projectId);
}

export function useOrganisationScope(organisationId: number):PermissionScope {
  return usePermissionOrganisation(organisationId)?.permissionScope ?? PermissionScope.NOACCESS;
}

export function useCanContributeOrg(organisationId: number) {
  return usePermissionContext().canContributeOrg(organisationId);
}
