import React from "react";
import { usePermissionUserOrganisations } from "../../../../contexts/permissions/PermissionHooks";
import * as _ from "lodash";
import { AvatarContent } from "@flightpath/coreui/dist/ui/AvatarContent";
import { Link } from "react-router-dom";
import { useOrganisationContext } from "../../../../services/local/organisationContext/OrganisationContextModel";

export const OrganisationMegaMenu: React.FC<any> = () => {
  const organisations = usePermissionUserOrganisations();

  const currentOrganisationContext = useOrganisationContext();
  return (
    <div className={`organisation-mega-menu py-4`}>
      {_.map(organisations, e => {
        const url = `/organisations/${e.id}`;
        return (
          <div key={e.id} className="organisation-mega-menu__item mb-1 ml-5">
            <Link to={url} onClick={() => currentOrganisationContext.setCurrentOrganisationId(e.id)}>
              <AvatarContent
                avatarProps={{
                  imgSrc: e.profileImageUrl
                }}
              >
                <p className="mb-0">{e.name}</p>
              </AvatarContent>
            </Link>
          </div>
        );
      })}
    </div>
  );
};
