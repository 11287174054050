import React from "react";
import I18n from "../../../core/localization/I18n";
import { percentageChange } from "../../../core/util/Helpers";
import { UiSizes } from "../../../enums";
import { Icon, IconSymbols } from "../../ui/Icon";

export interface PercentageChangeHeadtailProps {
    oldTotal: number;
    newTotal: number;
    suffixText?: string;
}

const GetArrowIcon = (percentageChange: number) => {
    if (percentageChange > 0) {
        return CreateArrowIcon(IconSymbols.ArrowUp);
    }

    if (percentageChange === 0) {
        return CreateArrowIcon(IconSymbols.ArrowRight);
    }

    return CreateArrowIcon(IconSymbols.ArrowDown);
};

const CreateArrowIcon = (iconSymbol: IconSymbols) => {
    return (
        <Icon
            symbol={iconSymbol}
            className="text-info metric-card__percentage-change__icon"
            size={UiSizes.MD}
        />
    );
};

function getSuffixText(value: number) {
    if (value > 0) {
        return I18n.t("visualisations.increaseSinceLastWeek")
    }
    else if (value < 0) {
        return I18n.t("visualisations.decreaseSinceLastWeek")
    }
    else {
        return I18n.t("visualisations.sameAsLastWeek")
    }
}

export const HeadtailPercentageChange: React.FC<PercentageChangeHeadtailProps> = props => {
    const change = percentageChange(props.oldTotal, props.newTotal);
    const changeValue = parseFloat(change.value.toPrecision(2));
    const suffixText = getSuffixText(changeValue);
    const icon = GetArrowIcon(changeValue);

    return (
        <>
            <div className="ml-2 metric-card__percentage-change__container">
                {icon}{" "}
                <span className="metric-card__percentage-change__value">
                    {changeValue}% {suffixText}
                </span>
            </div>
        </>
    );
};
