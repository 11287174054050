import { ISdColDef } from "../../baseColumn/BaseColumnTypes";
import { BaseTextColumnBuilder } from "../../baseColumn/BaseTextColumn_builder";
import { NOTE_COUNT_COLUMN_CONFIG } from "./NoteCountColumn_config";

interface INoteCountColumnBuilder {}

export class NoteCountColumnBuilder extends BaseTextColumnBuilder implements INoteCountColumnBuilder {
  constructor(columnOptions?: ISdColDef<any, any>) {
    super({ ...NOTE_COUNT_COLUMN_CONFIG(), ...columnOptions });
  }

  setEditableOnDoubleClick = (callback: (item: any) => void) => {
    this.setColumnOptions({ onCellDoubleClicked: e => callback(e.data) });
  };
}
