import { FormFieldModel } from "../formField/FormField_model";
import { IFormFieldModel } from "../formField/IFormField";
import * as _ from "lodash";
import { FormFieldType } from "../formField/FormFieldTypes";
import { TextFieldModel } from "../controls/textField/TextField_model";
import { CheckboxModel } from "../controls/checkbox/Checkbox_model";
import { AutocompleteModel } from "../controls/autocomplete/Autocomplete_model";
import { IAutocompleteModel } from "../controls/autocomplete/IAutocompleteModel";
import { ITextFieldModel } from "../controls/textField/ITextFieldModel";
import { ICheckboxModel } from "../controls/checkbox/ICheckboxModel";
import { DropdownModel } from "../controls/dropdown/Dropdown_model";
import { IDropdownModel } from "../controls/dropdown/IDropdownModel";
import { ImageCropperModel } from "../controls/imageCropper/ImageCropper_model";
import { IImageCropperModel } from "../controls/imageCropper/IImageCropperModel";
import { ICheckboxListModel } from "../controls/checkboxList/ICheckboxListModel";
import { CheckboxListModel } from "../controls/checkboxList/CheckboxList_model";
import { RadioButtonListModel } from "../controls/radioButtons/RadioButtons_model";
import { IRadioButtonListModel } from "../controls/radioButtons/IRadioButtonsModel";
import { DatePickerModel } from "../controls/datePicker/DatePicker_model";
import { IDatePickerModel } from "../controls/datePicker/IDatePickerModel";
import { MultiSelectorModel } from "../controls/multiSelector/MultiSelector_model";
import { ListingModel } from "../controls/listing/Listing_model";
import { SliderModel } from "../controls/slider/Slider_model";
import { RTEditorModel } from "../controls/rteditor/RTEditor_model";
import { MultiInputModel } from "../controls/multiInput/MultiInput_model";
import { CheckboxSliderModel } from "../controls/checkboxslider/CheckboxSlider_model";
import { SearchTreePickerModel } from "../controls/searchTreePicker/SearchTreePicker_model";

/**
 * Convert a list of JSON object that should show in a form into a list
 * of FormFieldModel instances and registers subscribers
 * @param data a list of all the form controls that should show in a form
 * @returns a list of FormField instances
 */
export const generateFormFieldsFromJson = (data: Partial<IFormFieldModel<any, any>>[]): IFormFieldModel<any, any>[] => {
  let list: IFormFieldModel<any, any>[] = _.map(data, (item: IFormFieldModel<any, any>) => {
    let res = createFormFieldModel(item);
    return res;
  });
  list = registerSubscribers(list);
  return list;
};

/**
 * Creates an instance of a FormModel based on a JSON object passed
 * @param item an object that will be converted to a FormModel instance
 * @returns a new FormFieldModel instance
 */
const createFormFieldModel = (item: IFormFieldModel<any, any>): FormFieldModel<any, any> => {
  switch (item.type) {
    case FormFieldType.Text:
      return new TextFieldModel(item as ITextFieldModel);
    case FormFieldType.Autocomplete:
      return new AutocompleteModel(item as IAutocompleteModel);
    case FormFieldType.Checkbox:
      return new CheckboxModel(item as ICheckboxModel);
    case FormFieldType.Dropdown:
      return new DropdownModel(item as IDropdownModel);
    case FormFieldType.ImageCropper:
      return new ImageCropperModel(item as IImageCropperModel);
    case FormFieldType.CheckboxList:
      return new CheckboxListModel(item as ICheckboxListModel);
    case FormFieldType.RadioButtonList:
      return new RadioButtonListModel(item as IRadioButtonListModel);
    case FormFieldType.DatePicker:
      return new DatePickerModel(item as IDatePickerModel);
    case FormFieldType.Listing:
      return new ListingModel(item as ListingModel);
    case FormFieldType.Slider:
      return new SliderModel(item as SliderModel);
    case FormFieldType.RTEditor:
      return new RTEditorModel(item as RTEditorModel);
    case FormFieldType.Multiselector:
      return new MultiSelectorModel(item as MultiSelectorModel);
    case FormFieldType.MultiInput:
      return new MultiInputModel(item as MultiInputModel);
    case FormFieldType.CheckboxSlider:
      // return new SliderModel(item as SliderModel);
      return new CheckboxSliderModel(item as CheckboxSliderModel);
    case FormFieldType.SearchTreePicker:
      return new SearchTreePickerModel(item as SearchTreePickerModel);
    default:
      break;
  }
};

/**
 * Registers subscribers to the elements based on the subscriptTo attribute
 *
 * TODO: Refactor this function for more efficient approach
 * @param fields a list of FormField instances
 */
const registerSubscribers = (fields: IFormFieldModel<any, any>[]): IFormFieldModel<any, any>[] => {
  // create a Dictionary based on a key attribute
  let objFields: Dictionary<IFormFieldModel<any, any>> = _.mapKeys(fields, (e: IFormFieldModel<any, any>) => {
    return e.key;
  });

  // map the subscriberTo values to the keys
  let mappings: Dictionary<string[]> = _.mapValues(objFields, (e: IFormFieldModel<any, any>) => {
    return e.subscribeTo;
  });

  // loop through elements with subscribers and register them
  Object.keys(mappings).forEach(key => {
    let subscribers = mappings[key];
    if (subscribers && subscribers.length > 0) {
      _.forEach(subscribers, subscriber => {
        if (!objFields[subscriber]) {
          console.error('Subscriber: "' + subscriber + '" was not found in the provided FormField array');
          return;
        }
        objFields[subscriber].registerSubscribers(objFields[key]);
      });
    }
  });

  objFields = assignChannels(objFields);

  return _.map(objFields, e => {
    return e;
  });
};

/**
 * Loops through the dictionary checks if there are subscriptions
 * and adds FormFieldModels as channels
 * @param fields
 */
export const assignChannels = (
  fields: Dictionary<IFormFieldModel<any, any>>
): Dictionary<IFormFieldModel<any, any>> => {
  _.forEach(fields, e => {
    if (e.subscribeTo && e.subscribeTo.length > 0) {
      e.subscribeTo.forEach(channelKey => {
        e.channels[channelKey] = fields[channelKey];
      });
    }
  });
  return fields;
};
