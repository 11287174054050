import { InsightInnerNavContent } from "../../../../components/widgets/innerNavigation/InnerNavContent_view";
import { useIsOrganisationAdmin } from "../../../../core/auth/authorise";
import I18n from "../../../../core/localization/I18n";
import Pages from "../../../../routes/InsightRoutes";
import { useCurrentOrganisationId } from "../../../../services/local/organisationContext/OrganisationContextModel";

export const OrganisationSettingsWrapper: React.FC = () => {
  const organisationId = useCurrentOrganisationId();

  const items = [
    {
      label: I18n.t("entities.organisation"),
      url: Pages.organisations.settings.index.generateLink(organisationId)
    },
    {
      label: I18n.t("entities.users"),
      url: Pages.organisations.settings.users.generateLink(organisationId)
    }
  ];

  const isAdmin = useIsOrganisationAdmin(organisationId);

  if (isAdmin) {
    items.push({
      label: I18n.t("phrases.admin"),
      url: Pages.organisations.settings.admin.generateLink(organisationId)
    });
  }
  return <InsightInnerNavContent title={I18n.t("phrases.settings")} items={items} />;
};
