import React from "react";
import { IGridHubStoreModel } from "./GridHubStore";

interface GridHubStoreWrapperProps extends ReactProps {
  store: IGridHubStoreModel<any>;
  organisationId: number;
  projectId: number;
  authUser: FP.Entities.IUser;
}

export class GridHubStoreWrapper extends React.Component<GridHubStoreWrapperProps> {
  async componentDidUpdate(prevProps: Readonly<GridHubStoreWrapperProps>, prevState: Readonly<{}>, snapshot?: any) {
    if (prevProps.organisationId !== this.props.organisationId || prevProps.projectId !== this.props.projectId) {
      this.props.store.isLoading = true;
    }
  }

  async componentDidMount() {
    await this.props.store.invokeDataLoad(this.props.organisationId, this.props.projectId, this.props.authUser);
  }

  async componentWillUnmount() {
    await this.props.store.invokeUserLeft(this.props.organisationId, this.props.projectId, this.props.authUser);
    await this.props.store.stopConnection();
  }

  render(): React.ReactNode {
    return (
      <React.Fragment key={this.props.projectId + "-" + this.props.organisationId}>
        {this.props.children}
      </React.Fragment>
    );
  }
}
