import { observable, action, makeObservable } from "mobx";
import { FormFieldModel } from "../../formField/FormField_model";
import { FormFieldType } from "../../formField/FormFieldTypes";
import * as React from "react";
import { ICheckboxModel } from "./ICheckboxModel";
import { Checkbox } from "../../../../components/ui/Checkbox";

export class CheckboxModel
  extends FormFieldModel<boolean, React.HTMLProps<HTMLInputElement>>
  implements ICheckboxModel
{
  @observable value: boolean = false;
  readonly type: FormFieldType = FormFieldType.Checkbox;

  constructor(initOpts?: ICheckboxModel) {
    super(initOpts);
    makeObservable(this);
    if (initOpts) {
      this.value = initOpts.value || this.value;
      this.validate = initOpts.validate || (this.validate as any);
    }
  }

  @action.bound
  setValue(val: boolean): void {
    this.hasChanges = true;
    this.value = val;

    if (this.validateOnChange && this.validate) {
      this.validate(this.value);
    }
  }

  setFieldValue = (e: React.SyntheticEvent<any>): void => {
    const val = e.currentTarget as HTMLInputElement;
    this.setValue(val.checked);
  };

  extractValue = () => {
    return this.value;
  };

  validate: any | ((...args: any[]) => any);

  @action.bound
  reset(): void {
    this.hasChanges = true;
    this.errorMessage = null;
    this.value = this.defaultValue || false;
  }

  renderComponent = () => {
    let props = {
      className: `form-control ${this.className || ""}`,
      id: this.key,
      onChange: this.setFieldValue,
      disabled: this.disabled,
      checked: this.value
    };
    return <Checkbox {...props} />;
  };
}
