import { action, makeObservable, observable } from "mobx";
import AudiencesApi, {
  AudiencesApi as IAudiencesApi
} from "../../../../../../../../services/api/v2/audiences/Audiences.api";
import ModalContext from "../../../../../../../modalZ/context/ModalContext";

export class ActionAudiencesLinkModalModel {
  organisationId: number;
  projectId: number;
  audiencesProvider: IAudiencesApi;
  @observable audiences: any[];
  itemIds: number[];

  constructor(organisationId: number, projectId: number, itemIds: number[]) {
    makeObservable(this);
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.audiencesProvider = AudiencesApi;
    this.itemIds = itemIds;
  }

  @action
  onAssignSuccess = async (selectedItemIds: number[]) => {
    await this.audiencesProvider.addBulkAudiencesToActions(
      this.organisationId,
      this.projectId,
      selectedItemIds,
      this.itemIds
    );
    ModalContext.hide();
  };
}
