import { observer } from "mobx-react";
import React, { useState } from "react";
import { PositionedSpinner } from "../../../../../../../../components/ui/PositionedSpinner";
import { useCurrentOrganisationId } from "../../../../../../../../services/local/organisationContext/OrganisationContextModel";
import { useParams } from "react-router-dom";
import { ProjectProcessLocationsPopupModel } from "./ProjectProcessLocationsPopup_model";
import { SingleForm } from "../../../../../../../../pages/change/forms/singleFormModel/SingleForm_view";

export interface ProjectProcessLocationsPopupProps {
  onSubmit: (locationIds: number[]) => void;
}

export const ProjectProcessLocationsPopup: React.FC<ProjectProcessLocationsPopupProps> = observer(({ onSubmit }) => {
  const organisationId = useCurrentOrganisationId();
  const { projectId } = useParams();
  const [model] = useState(() => new ProjectProcessLocationsPopupModel(organisationId, +projectId, onSubmit));
  if (model.isLoading) return <PositionedSpinner />;

  return (
    <div className="container-fluid">
      <SingleForm model={model.formModel} />
    </div>
  );
});
