import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { MilestoneModalModel } from "./MilestoneModal_model";
import { SingleForm } from "../../../forms/singleFormModel/SingleForm_view";
import I18n from "../../../../../core/localization/I18n";

export interface MilestoneModalProps {
  model: MilestoneModalModel;
}

const MilestoneModal: React.FunctionComponent<MilestoneModalProps> = observer(props => {
  const { model } = props;

  useEffect(() => {
    model.onMount();
    return model.onUnmount;
  }, [model]);

  return (
    <div className="create-milestone-view container-fluid pt-5">
      <div className="row mb-4">
        <div className="col-12">
          <h1>{I18n.t(model.milestone ? "phrases.editMilestone" : "phrases.addMilestone")}</h1>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-12">
          <SingleForm model={model.formModel} />
        </div>
      </div>
    </div>
  );
});

export { MilestoneModal };
