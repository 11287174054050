import { ColDef } from "ag-grid-community";
import { colSelected } from "../../../../../../core/grids/CommonGridColumns";

import { IGridFns } from "../../../../../../core/grids/GridFunctions";
import { colBusinessArea, colName, colProcessRoles } from "./ProjectJobRoleGridView_BaseColumns";
import { IProjectJobRoleGridModals } from "./ProjectJobRoleGridView_modals";

export interface IGetProjectJobRoleGridViewColumns {
  canEdit: boolean;
  organisationId: number;
  projectId: number;
  gridFns: IGridFns;
  modals: IProjectJobRoleGridModals;
}

export const GetProjectJobRoleGridViewColumns = (modelProps: IGetProjectJobRoleGridViewColumns): ColDef[] => {
  let result: ColDef[] = [];
  const modals = modelProps.modals;

  result.push(colSelected());
  result.push(colName());
  result.push(colBusinessArea());
  result.push(colProcessRoles(modelProps.canEdit, modals.showProcessRolesModalFn));

  return result;
};
