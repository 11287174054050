import * as React from "react";
import { Panel } from "../../../../components/ui/Panel";
import { IconSymbols } from "../../../../components/ui/Icon";
import { observer } from "mobx-react";
import { convertStakeholderToName } from "../../../../core/util/Helpers";
import { ButtonIcon, ButtonTypes } from "@flightpath/coreui/dist/ui/Button";
import { UiSizes } from "@flightpath/coreui/dist/enums";
import { QUERY_STRING_PARAMS } from "../../../../services/local/queryStringService/QueryStringService";
import { Link } from "react-router-dom";
import { ImpactStakeholderChangeRoleModel } from "./impactStakeholderChangeRole/ImpactStakeholderChangeRole_model";
import { SingleForm } from "../../forms/singleFormModel/SingleForm_view";
import { useCurrentOrganisationId } from "../../../../services/local/organisationContext/OrganisationContextModel";

export interface ImpactStakeholderCardProps {
  stakeholder: FP.Entities.IStakeholder;
  deleteFn?: (id: any) => void;
  projectId: number;
  impactId: number;
  onLinkClickFn?: () => void;
  pStakeholderId?: number;
  projectStakeholder: FP.Entities.IProjectStakeholder;
  onRoleAdded: any;
}

const ImpactStakeholderCard: React.FunctionComponent<ImpactStakeholderCardProps> = observer(
  ({ projectId, pStakeholderId, stakeholder, deleteFn, onLinkClickFn, impactId, projectStakeholder, onRoleAdded }) => {
    const organisationId = useCurrentOrganisationId();
    const mainUrl = `/organisations/${organisationId}/projects/${projectId}`;
    const [model] = React.useState(
      () => new ImpactStakeholderChangeRoleModel(organisationId, projectId, impactId, projectStakeholder, onRoleAdded)
    );

    React.useEffect(() => {
      model.projectStakeholder = projectStakeholder;
      model.initForm();
    }, [projectStakeholder.changeRoles.length, model, projectStakeholder]);

    React.useEffect(() => {
      model.onMount();
      return model.onUnmount;
    }, [model]);

    return (
      <Panel.Panel
        className="stakeholder-card p-4 mb-2"
        background={Panel.PanelBackgrounds.BG_WHITE}
        type={Panel.PanelTypes.BORDER_LEFT_PRIMARY}
      >
        <div className="d-flex flex-column justify-content-between">
          <Link
            key={stakeholder.id}
            to={`${mainUrl}/stakeholders/${stakeholder.id}?${QUERY_STRING_PARAMS.RETURN_URL}=${encodeURIComponent(
              `${mainUrl}/impacts`
            )}`}
            onClick={() => onLinkClickFn()}
          >
            <div className="strong mb-4 flex-grow-1">{convertStakeholderToName(stakeholder)}</div>
          </Link>

          <SingleForm model={model.formModel} />

          {deleteFn && (
            <ButtonIcon
              style={{ position: "absolute", right: "40px" }}
              symbol={IconSymbols.Close}
              onClick={() => deleteFn(pStakeholderId)}
              size={UiSizes.SM}
              type={ButtonTypes.OUTLINE_PRIMARY}
            />
          )}
        </div>
      </Panel.Panel>
    );
  }
);

export { ImpactStakeholderCard };
