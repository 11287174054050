import { makeObservable, observable } from "mobx";
import * as React from "react";
import { ButtonTypes } from "../../../../../components/ui/Button";
import { Icon, IconSymbols } from "../../../../../components/ui/Icon";
import { Panel } from "../../../../../components/ui/Panel";
import { TreeEditorModel } from "../../../../../components/widgets/treeEditor/TreeEditor_model";
import PermissionsContext from "../../../../../contexts/permissions/PermissionsContext";
import ProgressIndicatorModel, {
  ProgressIndicatorModel as IProgressIndicatorModel
} from "../../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import I18n from "../../../../../core/localization/I18n";
import ToasterService, { IToasterService } from "../../../../../core/toaster/ToasterService";
import { TOASTER_TOAST_TIME } from "../../../../../core/toaster/Toaster_model";
import { BaseModel } from "../../../../../core/util/BaseModel";
import { buildTree } from "../../../../../core/util/Helpers";
import LocationsApi, { LocationsApi as ILocationsApi } from "../../../../../services/api/v2/locations/Locations.api";
import OrganisationsApi, {
  OrganisationsApi as IOrganisationsApi
} from "../../../../../services/api/v2/organisations/Organisations.api";
import { IModalContextModel } from "../../../../../core/modalZ/context/IModalContext";
import ModalContext from "../../../../../core/modalZ/context/ModalContext";

export class LocationSettingsModel extends BaseModel {
  organisationProvider: IOrganisationsApi;
  modalService: IModalContextModel;
  locationProvider: ILocationsApi;
  httpProgess: IProgressIndicatorModel;
  toasterService: IToasterService;
  @observable treeEditorModel: TreeEditorModel;
  @observable currentOrganisationId: number = 0;
  id: string;
  constructor(organisationId: number) {
    super();
    makeObservable(this);
    this.id = "Locations";
    this.currentOrganisationId = organisationId;
    this.modalService = ModalContext;
    this.locationProvider = LocationsApi;
    this.organisationProvider = OrganisationsApi;
    this.httpProgess = ProgressIndicatorModel;
    this.toasterService = ToasterService;
  }

  onMount = canEditOrganisation => {
    this.treeEditorModel = new TreeEditorModel(this.id);
    const canEditOrg = PermissionsContext.canContributeOrg(this.currentOrganisationId);
    if (canEditOrg) {
      this.treeEditorModel.onDelete = this.showDeleteConfirmationModal;
      this.treeEditorModel.onAdd = this.createNewLocation;
      this.treeEditorModel.onUpdate = this.updateLocation;
      this.treeEditorModel.onHierarchyChanged = this.updateLocation;
    }
  };

  onUnmount = () => {
    this.treeEditorModel = null;
  };

  loadLocations = async (currentOrganisationId: number) => {
    this.currentOrganisationId = currentOrganisationId;
    const res = await this.organisationProvider.getLocations(this.currentOrganisationId);

    if (!res || res.isError) {
      this.toasterService.showErrorToast(TOASTER_TOAST_TIME.SLOW).setContent(I18n.t("errors.loadLocations"));
      return;
    }

    const mappedResults = res.payload.map(e => {
      return {
        ...e,
        title: e.name,
        parent: e.parent === 0 ? "" : e.parent
      };
    });

    const treeData = buildTree(mappedResults);

    this.treeEditorModel.treeData = treeData;
  };

  expandAll = () => {
    this.treeEditorModel.setExpand(true);
  };

  collapseAll = () => {
    this.treeEditorModel.setExpand(false);
  };

  performTreeSearch = (ev: React.FormEvent<HTMLInputElement>) => {
    this.treeEditorModel.searchValue = ev.currentTarget.value;
  };

  updateLocation = (node: FP.Entities.ILocation, path: string[] | number[]): Promise<any> => {
    return new Promise(async resolve => {
      node.parentLocationId = node.parent || 0;
      node.organisationId = this.currentOrganisationId;
      this.httpProgess.showOverlay();
      const res = await this.locationProvider.update(this.currentOrganisationId, node.id, node);
      this.httpProgess.hideOverlay();

      if (!res || res.isError) {
        this.toasterService.showErrorToast(TOASTER_TOAST_TIME.SLOW).setContent(I18n.t("errors.updateLocation"));
        return;
      }
      this.treeEditorModel.updateNode(
        {
          ...node,
          ...res.payload,
          title: res.payload.name
        },
        path
      );
      resolve(true);
    });
  };

  showDeleteConfirmationModal = (
    node: FP.Entities.ILocation & {
      children: FP.Entities.ILocation[];
      title: string;
    },
    path: string[] | number[]
  ) => {
    if (!node.id) {
      this.treeEditorModel.removeNode(node, path);
      return;
    }

    return new Promise(resolve => {
      this.modalService.showConfirmDialog(
        <h1 className="mt-4">{I18n.t("phrases.confirm")}</h1>,
        <div className="container-fluid">
          <div className="row mb-3">
            <div className="col-12">
              <Icon symbol={IconSymbols.AlertCircle} className="mr-2" />
              {I18n.t("warnings.removeLocation")}
            </div>
          </div>
          <div className="row">
            <div className="col">{I18n.t("phrases.confirmDelete", { name: node.title })}</div>
          </div>
        </div>,
        I18n.t("phrases.yes"),
        I18n.t("phrases.no"),
        {
          wrapWidth: "small",
          spacing: "small",
          position: "middle",
          panelProps: {
            background: Panel.PanelBackgrounds.BG_WHITE
          }
        },
        async () => {
          if (node.id) {
            this.modalService.hide();
            this.httpProgess.showOverlay();
            const res = await this.locationProvider.remove(this.currentOrganisationId, node.id);
            this.httpProgess.hideOverlay();

            if (!res || res.isError) {
              this.toasterService.showErrorToast(TOASTER_TOAST_TIME.SLOW).setContent(I18n.t("errors.deleteLocation"));
              return;
            }
          }
          this.treeEditorModel.removeNode(node, path);
          resolve({ node, path });
        },
        () => {
          this.modalService.hide();
        },
        ButtonTypes.DANGER
      );
    });
  };

  addParentTreeNode = () => {
    this.collapseAll();
    this.treeEditorModel.addNode();
  };

  createNewLocation = async (
    node: FP.Entities.ILocation & {
      children: FP.Entities.ILocation[];
      title: string;
    },
    path: string[] | number[],
    treeIndex?: number | string
  ): Promise<any> => {
    return new Promise(async (resolve, rej) => {
      node.organisationId = this.currentOrganisationId;
      node.parentLocationId = node.parent;
      this.httpProgess.showOverlay();
      const res = await this.locationProvider.create(this.currentOrganisationId, node);
      this.httpProgess.hideOverlay();

      if (!res || res.isError) {
        this.toasterService.showErrorToast(TOASTER_TOAST_TIME.SLOW).setContent(I18n.t("errors.createLocation"));
        resolve(false);
        return;
      }
      this.treeEditorModel.updateNode(
        {
          ...res.payload,
          title: res.payload.name
        },
        path ? [...path, treeIndex as any] : [treeIndex]
      );
      resolve(true);
    });
  };

  showEditFormModal = (location: FP.Entities.ILocation) => {};
}
