import { action, makeObservable, observable } from "mobx";
import ProjectProcessesApi, {
  ProjectProcessesApi as IProjectProcessesApi
} from "../../../../../../../services/api/v2/projectProcesses/ProjectProcesses.api";

export class ProjectProcessesGridModalModel {
  organisationId: number;
  projectId: number;
  projectProcessesProvider: IProjectProcessesApi;
  @observable isLoading: boolean = true;
  @observable projectProcesses: any[];

  constructor(organisationId: number, projectId: number) {
    makeObservable(this);
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.projectProcessesProvider = ProjectProcessesApi;
  }

  onMount = async () => {
    await this.loadProjectProcesses();
    this.setIsLoading(false);
  };

  onUnmount = () => {};

  loadProjectProcesses = async () => {
    const res = await this.projectProcessesProvider.getGridData(this.organisationId, this.projectId);

    if (!res.payload || res.isError) return;
    this.setProjectProcesses(res.payload);
  };

  @action
  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };
  @action
  setProjectProcesses = (projectProcesses: any[]) => {
    this.projectProcesses = projectProcesses;
  };
}
