import { HubEvents } from "../common/HubEventsEnum";
import { SignalRBaseModel } from "../common/SignalRBaseModel";

export class ProjectStakeholderVisualisationsHub extends SignalRBaseModel {
  hubName: string = "project-stakeholders-visualisations";

  constructor() {
    super("project-stakeholders-visualisations");
  }

  onData = callback => this.registerIncomingEvent(HubEvents.ON_DATA, callback);

  invokeLoadData = async (organisationId: number, projectId: number) => {
    if (!this.isConnectionStarted) return;
    await this.connection.invoke(HubEvents.INVOKE_LOAD_DATA, {
      organisationId: organisationId,
      projectId: projectId
    });
  };
}

const instance = new ProjectStakeholderVisualisationsHub();

export default instance;
