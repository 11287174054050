import { GridApi, GridReadyEvent, SelectionChangedEvent } from "ag-grid-community";
import _ from "lodash";
import { action, makeObservable, observable } from "mobx";
import { StakeholderType } from "../../../../../enums";
import ProjectStakeholdersApi, {
  ProjectStakeholdersApi as IProjectStakeholdersApi
} from "../../../../../services/api/v2/projectStakeholders/ProjectStakeholders.api";
export interface IStakeholderSummary {
  id: number;
  name: string;
  email: string;
  role: string;
  businessArea: string;
  ownerName: string;
}

export class UnassignedStakeholdersModel {
  organisationId: number;
  projectId: number;
  stakeholderProvider: IProjectStakeholdersApi;
  @observable.ref stakeholders: IStakeholderSummary[];
  @observable.ref selectedStakeholders: any[] = [];
  @observable isLoading: boolean = true;
  gridApi: GridApi;
  stakeholderType: StakeholderType;
  @observable searchText: string;
  /**
   *
   */
  constructor(organisationId: number, projectId: number, stakeholderType: StakeholderType) {
    makeObservable(this);
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.stakeholderType = stakeholderType;
    this.stakeholderProvider = ProjectStakeholdersApi;
  }

  onMount = async () => {
    await this.loadStakeholders();
  };

  onUnmount = () => {};

  @action
  onGridReady = (gridReadyEvent: GridReadyEvent): void => {
    this.gridApi = gridReadyEvent.api;
    this.gridApi.setFilterModel({});
  };

  loadStakeholders = async () => {
    let res = await this.stakeholderProvider.getUnassignedProjectStakeholders(this.organisationId, this.projectId);

    if (!res || res.isError) return;
    this.setStakeholders(res.payload);
    this.isLoading = false;
  };

  updateSelectedStakeholders = (event: SelectionChangedEvent) => {
    this.gridApi = event.api;
    this.setSelectedStakeholders(
      _.map(event.api.getSelectedNodes(), e => {
        return e.data.id;
      })
    );
  };

  @action
  setSearchText = (ev: React.FormEvent<HTMLInputElement>) => {
    this.searchText = ev.currentTarget.value;

    if (this.gridApi !== undefined) {
      this.gridApi.setGridOption("quickFilterText", this.searchText);
    }
  };
  assignStakeholders = async () => {
    let res = await this.stakeholderProvider.addStakeholderRange(
      this.organisationId,
      this.projectId,
      this.selectedStakeholders
    );

    return res.payload;
  };

  @action
  setStakeholders = (stakeholders: FP.Entities.IStakeholder[]) => {
    this.stakeholders = this.mapStakeholder(stakeholders);
  };

  @action
  setSelectedStakeholders = (selectedStakeholders: FP.Entities.IStakeholder[]) => {
    this.selectedStakeholders = selectedStakeholders;
  };

  mapStakeholder = (stakeholders: FP.Entities.IStakeholder[]) => {
    let res = [];

    _.forEach(stakeholders, e => {
      if (e.stakeholderType === this.stakeholderType) {
        res.push({
          id: e.id,
          name: e.stakeholderType === StakeholderType.INDIVIDUAL ? `${e.firstName} ${e.lastName}` : `${e.firstName}`,
          email: e.email.toLowerCase(),
          role: e.role ? e.role.name : "",
          businessArea: e.businessArea ? e.businessArea.name : "",
          ownerName: e.owner ? `${e.owner.firstName} ${e.owner.lastName}` : ""
        });
      }
    });
    return res;
  };
}
