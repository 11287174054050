import { action, makeObservable, observable } from "mobx";
import { PROGRESS_STATUS_OPTIONS } from "../../../../constants";
import { IGridModel } from "../../../../core/grids/IGridModel";
import I18n from "../../../../core/localization/I18n";
import { EntityTypes } from "../../../../enums";
import ActionsApi, { ActionsApi as IActionsApi } from "../../../../services/api/v2/actions/Actions.api";
import { GetActionGridViewColumns } from "./grid/GridColumns";

export class ActionGridModel implements IGridModel {
  actionsProvider: IActionsApi;

  authUser: FP.Entities.IUser;
  @observable isLoading: boolean = true;
  progressStatuses: FP.Generic.IKeyLabel[];
  impactLevels: FP.Generic.IKeyLabel[];
  type: EntityTypes = EntityTypes.ACTIONS;
  organisationId: number;
  projectId: number;

  constructor(organisationId: number, projectId: number, authUser: FP.Entities.IUser) {
    makeObservable(this);
    this.actionsProvider = ActionsApi;
    this.authUser = authUser;
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.mapProgressStatuses();
  }

  onMount = async () => {
    this.setIsLoading(false);
    // Promise.all([this.loadImpactTypes(), this.loadProjectTeamMembers()]).then(v => {
    //     this.isLoading = false;
    // })
  };

  onUnmount = () => {};

  getColumnConfig = () => {
    return GetActionGridViewColumns({
      canEdit: false,
      organisationId: this.organisationId,
      projectId: this.projectId,
      projectTeamMembers: [],
      progressStatuses: this.progressStatuses
    });
  };

  mapProgressStatuses = () => {
    this.progressStatuses = PROGRESS_STATUS_OPTIONS.map(e => {
      return {
        label: I18n.t(e.label),
        key: e.key + ""
      } as FP.Generic.IKeyLabel;
    });
  };

  @action
  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };
}
