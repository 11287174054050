import { action, makeObservable, observable } from "mobx";
import PermissionsContext from "../../../../../../../../contexts/permissions/PermissionsContext";
import { BaseModel } from "../../../../../../../../core/util/BaseModel";
import {
  getImpactLocationsSidebarFormActions,
  getImpactLocationsSidebarFormFields
} from "./ImpactLocationsSidebar_fields";
import { PermissionFields } from "../../../../../../../../contexts/permissions/PermissionsTypes";
import OrganisationsApi, {
  OrganisationsApi as IOrganisationsApi
} from "../../../../../../../../services/api/v2/organisations/Organisations.api";
import ImpactTypesApi, {
  ImpactTypesApi as IImpactTypesApi
} from "../../../../../../../../services/api/v2/impactTypes/ImpactTypes.api";
import ImpactsApi, { ImpactsApi as IImpactsApi } from "../../../../../../../../services/api/v2/impacts/Impacts.api";
import LocationsApi, {
  LocationsApi as ILocationsApi
} from "../../../../../../../../services/api/v2/locations/Locations.api";
import { SingleFormModel } from "../../../../../../../../pages/change/forms/singleFormModel/SingleForm_model";

export class ImpactLocationsSidebarModel extends BaseModel {
  impactTypesProvider: IImpactTypesApi;
  impactsProvider: IImpactsApi;
  locationsProvider: ILocationsApi;
  organisationsProvider: IOrganisationsApi;
  @observable isLoading: boolean = true;
  @observable.ref impact: FP.Entities.IImpact;
  organisationId: number;
  projectId: number;
  @observable locationTreeData: FP.Entities.ITreeDataItem[];
  closeModalFn: () => void;
  canEdit: boolean;
  @observable.ref formModel: SingleFormModel;

  constructor(closeModalFn: () => void, organisationId: number, projectId: number, impactId: number) {
    super();
    makeObservable(this);
    this.impactTypesProvider = ImpactTypesApi;
    this.locationsProvider = LocationsApi;
    this.organisationsProvider = OrganisationsApi;
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.impactsProvider = ImpactsApi;
    this.loadSidebar(impactId);
    this.closeModalFn = closeModalFn;
    this.canEdit = PermissionsContext.canEditField(PermissionFields.IMPACTS, this.organisationId, this.projectId);
  }

  @action
  setImpact = (impact: FP.Entities.IImpact) => {
    this.impact = impact;
  };

  @action
  getOrgLocations = async (impactId: number) => {
    let res = await this.impactsProvider.getLocationSelection(this.organisationId, this.projectId, impactId);
    if (!res || res.isError) return;
    this.locationTreeData = res.payload;
  };

  @action
  getImpact = async (impactId: number) => {
    let res = await this.impactsProvider.getDetailedById(this.organisationId, this.projectId, impactId);
    if (!res || res.isError) return;
    this.setImpact(res.payload);
  };

  @action
  updateImpact = async () => {
    if (!this.canEdit) return;
    this.formModel.isSaving = true;
    let res: any = await this.formModel.submit();
    if (!res) return;
    await this.impactsProvider.updateLocations(this.organisationId, this.projectId, this.impact.id, res.locations);
  };

  @action
  loadSidebar = async (impactId: number) => {
    this.setIsLoading(true);
    await this.getOrgLocations(impactId);
    await this.getImpact(impactId);
    this.formModel = new SingleFormModel();
    this.formModel.formFields = getImpactLocationsSidebarFormFields(this.locationTreeData);
    this.formModel.actions = getImpactLocationsSidebarFormActions(this.closeModalFn, this.updateImpact, this.canEdit);
    this.setIsLoading(false);
  };

  @action
  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };
}
