import { observer } from "mobx-react";
import { IconSymbols } from "../../../../../../components/ui/Icon";
import { Input } from "../../../../../../components/ui/_forms/Input";
import { ConnectedUserAvatars } from "../../../../../../components/widgets/connectedUserAvatars/ConnectedUserAvatars";
import { Enums, UiSizes } from "../../../../../../enums";
import { AppGridToolbarActions, IGridUiAction } from "./AppGridToolbarActions_view";

interface AppGridToolbarProps {
  connectedUsers?: any[];
  onQuichSearchInputChange: (ev) => void;
  actions: IGridUiAction[];
  selectedItemCount?: number;
}

export const AppGridToolbar: React.FC<AppGridToolbarProps> = observer(
  ({ connectedUsers, onQuichSearchInputChange, actions, selectedItemCount }) => {
    return (
      <div className="app-grid-toolbar container-fluid py-2">
        <div className="row justify-content-end">
          <div className={`${connectedUsers ? "col-7" : "col-10"}`}>
            <AppGridToolbarActions actions={actions} selectedItemCount={selectedItemCount} />
          </div>
          {connectedUsers && (
            <div className="col-3">
              <ConnectedUserAvatars connectedUsers={connectedUsers} />
            </div>
          )}
          <div className="col-2 d-flex align-items-center">
            <Input
              autoFocus={true}
              size={UiSizes.XS}
              onChange={onQuichSearchInputChange}
              placeholder="Search"
              icon={IconSymbols.Search}
              iconSize={Enums.UiSizes.SM}
              style={{ height: 32 }}
            />
          </div>
        </div>
      </div>
    );
  }
);
