import { ISmartTableAction } from "@flightpath/coreui/dist/widgets/smartTable/ISmartTable";
import { SmartTableRowProps } from "@flightpath/coreui/dist/widgets/smartTable/SmartTableRow";
import { IconSymbols } from "../../../../../components/ui/Icon";
import PermissionsContext from "../../../../../contexts/permissions/PermissionsContext";
import I18n from "../../../../../core/localization/I18n";
import { UiActionRenderers } from "../../../../../core/uiAction/IUiAction";
import { TagSettingsModel } from "./TagSettings_model";


export const GetTagTableActions =
    (parentModel: TagSettingsModel) =>
        (entry: SmartTableRowProps): ISmartTableAction[] => {
            const canEditOrg = PermissionsContext.canEditOrganisation(parentModel.organisationId);
            let actions = [];
            actions.push({
                id: "delete",
                label: I18n.t("phrases.delete"),
                onAction: (ev, row: FP.Entities.ITag) => {
                    parentModel.showTagConfirmDeleteModal(row);
                },
                componentProps: {
                    className: "px-2",
                    type: "link",
                    symbol: IconSymbols.Trash
                },
                rendersIn: UiActionRenderers.BUTTON_ICON
            });
            if (canEditOrg) {
                actions.push(
                    {
                        id: "edit",
                        label: I18n.t("phrases.edit"),
                        onAction: (ev, row) => {
                            parentModel.showTagModal(row, "edit");
                        },
                        componentProps: {
                            className: "px-2",
                            type: "link",
                            symbol: IconSymbols.Pencil,
                        },
                        rendersIn: UiActionRenderers.BUTTON_ICON,

                    });
            }
            return actions;
        };
