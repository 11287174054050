import { observer } from "mobx-react";
import React, { useState } from "react";
import { useCurrentOrganisationId } from "../../../../../services/local/organisationContext/OrganisationContextModel";
import { SunburstModel } from "./Sunburst_model";
import { ReportingBar } from "./cards/ReportingBar";
import { DashboardSunburstChart } from "./dashboardSunburst/DashboardSunburstChart";

export const DashboardSunburstContent: React.FC<any> = observer(() => {
  const organisationId = useCurrentOrganisationId();
  const [model] = useState(() => new SunburstModel(organisationId));

  const programmeCount = model.sunburst?.children.filter(e => !!e.elementId && e.elementId !== -1).length;

  return (
    <div className="container-fluid pb-4">
      <ReportingBar
        actionRagCounts={model.actionRagCounts}
        impactLevelCounts={model.impactLevelCounts}
        programmeCount={programmeCount}
        isLoading={model.isLoading}
      />

      <div className="row pt-4">
        <div className="col">
          <DashboardSunburstChart sunburst={model.sunburst} organisationId={organisationId} />
        </div>
      </div>
    </div>
  );
});
