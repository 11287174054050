import {
  GridSideModalTitle,
  gridSideModalAnimationOpts,
  gridSideModalComponentProps
} from "../../../../core/grids/GridSideModals";
import I18n from "../../../../core/localization/I18n";
import ModalContext from "../../../../core/modalZ/context/ModalContext";
import { ActionsPerProjectByProgressStatusSidebar } from "./modals/ActionsPerProjectByProgressStatusSidebar/ActionsPerProjectByProgressStatusSidebar_view";
import { ActionsPerProjectbyRagStatusSidebar } from "./modals/ActionsPerProjectbyRagStatusSidebar/ActionsPerProjectbyRagStatusSidebar_view";

export const showActionsPerProjectByProgressStatusPieChartSidebar = (data, pieData) => {
  ModalContext.show({
    title: (
      <GridSideModalTitle name={`${data.firstName} ${data.lastName}`} field={I18n.get("phrases.actionsByProject")} />
    ),
    showClose: true,
    content: (
      <ActionsPerProjectByProgressStatusSidebar
        userId={data.id}
        progressStatus={pieData.data.key}
      ></ActionsPerProjectByProgressStatusSidebar>
    ),
    componentProps: { ...gridSideModalComponentProps, wrapWidth: "medium" },
    animationOptions: gridSideModalAnimationOpts
  });
};

export const showActionsPerProjectByRagStatusPieChartSidebar = (data, pieData) => {
  ModalContext.show({
    title: (
      <GridSideModalTitle name={`${data.firstName} ${data.lastName}`} field={I18n.get("phrases.actionsByProject")} />
    ),
    showClose: true,
    content: (
      <ActionsPerProjectbyRagStatusSidebar
        userId={data.id}
        ragStatus={pieData.data.key}
      ></ActionsPerProjectbyRagStatusSidebar>
    ),
    componentProps: { ...gridSideModalComponentProps, wrapWidth: "medium" },
    animationOptions: gridSideModalAnimationOpts
  });
};
