import { action, makeObservable, observable } from "mobx";
import * as React from "react";
import { ButtonTypes } from "../../../../components/ui/Button";
import { Panel } from "../../../../components/ui/Panel";
import PermissionsContext from "../../../../contexts/permissions/PermissionsContext";
import { PermissionFields } from "../../../../contexts/permissions/PermissionsTypes";

import ProgressIndicatorModel, {
  ProgressIndicatorModel as IProgressIndicatorModel
} from "../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import I18n from "../../../../core/localization/I18n";
import { IModalContextModel } from "../../../../core/modalZ/context/IModalContext";
import ModalContext from "../../../../core/modalZ/context/ModalContext";
import { IUiAction, UiActionRenderers } from "../../../../core/uiAction/IUiAction";
import { Animations } from "../../../../core/util/Animations";
import { BaseModel } from "../../../../core/util/BaseModel";
import ImpactsApi, { ImpactsApi as IImpactsApi } from "../../../../services/api/v2/impacts/Impacts.api";
import ProjectStakeholdersApi, {
  ProjectStakeholdersApi as IProjectStakeholdersApi
} from "../../../../services/api/v2/projectStakeholders/ProjectStakeholders.api";
import ProjectsApi, { ProjectsApi as IProjectsApi } from "../../../../services/api/v2/projects/Projects.api";
import { ILocalStorageService } from "../../../../services/local/localStorageService/ILocalStorageService";
import LocalStorageService from "../../../../services/local/localStorageService/LocalStorageService";
import { SingleFormModel } from "../../forms/singleFormModel/SingleForm_model";
import { getProjectStakeholderImpactsFormFields } from "./StakeholderImpactsView_data";

export class StakeholderImpactsViewModel extends BaseModel {
  isRouteView: boolean;
  stakeholderId: number;
  projectId: number;
  modalService: IModalContextModel;
  projectProvider: IProjectsApi;
  impactProvider: IImpactsApi;
  httpProgress: IProgressIndicatorModel;
  @observable isLoading: boolean = false;
  @observable isSearchMode: boolean = false;
  @observable searchValue: string = "";
  @observable projectStakeholder: FP.Entities.IProjectStakeholder;
  @observable impacts: FP.Entities.IImpact[] = [];
  @observable filteredImpacts: FP.Entities.IImpact[] = [];
  projectStakeholderProvider: IProjectStakeholdersApi;
  localStorageService: ILocalStorageService;
  organisationId: number;

  /**
   *
   */
  constructor(organisationId: number, projectId: number, stakeholderId: number, isRouteView: boolean = true) {
    super();
    makeObservable(this);
    this.isRouteView = isRouteView;
    this.modalService = ModalContext;
    this.projectStakeholderProvider = ProjectStakeholdersApi;
    this.projectProvider = ProjectsApi;
    this.impactProvider = ImpactsApi;
    this.localStorageService = LocalStorageService;
    this.httpProgress = ProgressIndicatorModel;
    this.organisationId = organisationId;
    this.stakeholderId = stakeholderId;
    this.projectId = projectId;
  }

  onMount = () => {
    if (PermissionsContext.canViewField(PermissionFields.IMPACTS, this.organisationId, this.projectId))
      this.loadProjectStakeholder(this.projectId, this.stakeholderId);
  };

  onUnmount = () => {
    this.resetSearch();
  };

  loadProjectStakeholder = async (projectId: number, stakeholderId: number) => {
    this.isLoading = true;
    let res = await this.projectStakeholderProvider.getStakeholderById(this.organisationId, projectId, stakeholderId);
    if (!res || res.isError) return;
    this.setProjectStakeholder(res.payload);
  };

  @action
  setProjectStakeholder = (projectStakeholder: FP.Entities.IProjectStakeholder) => {
    if (!this.projectId || !this.stakeholderId) {
      this.projectId = projectStakeholder.project.id;
      this.stakeholderId = projectStakeholder.stakeholder.id;
    }
    this.projectStakeholder = projectStakeholder;
    this.loadStakeholderImpacts(this.projectStakeholder.id);
  };

  @action
  loadStakeholderImpacts = async (projectStakeholderId: number) => {
    let res = await this.projectStakeholderProvider.getProjectStakeholderImpacts(
      this.organisationId,
      this.projectId,
      projectStakeholderId
    );
    if (!res || res.isError) return;
    this.impacts = res.payload;
    this.filterImpacts();
    this.isLoading = false;
  };

  removeImpact = async (projectStakeholderId, impactId) => {
    this.httpProgress.showOverlay();
    const res = await this.impactProvider.removeStakeholder(
      this.organisationId,
      this.projectId,
      impactId,
      projectStakeholderId
    );
    this.httpProgress.hideOverlay();

    if (!res || res.isError) return;

    return res.payload;
  };

  @action
  updateSearchValue = (e: React.FormEvent<HTMLInputElement>) => {
    this.searchValue = e.currentTarget.value;
    this.filterImpacts();
  };

  @action
  showSearchMode = () => {
    this.isSearchMode = true;
  };

  @action
  hideSearchMode = () => {
    this.isSearchMode = false;
  };

  @action
  resetSearch = () => {
    this.searchValue = "";
    this.filterImpacts();
    this.hideSearchMode();
  };

  @action
  filterImpacts = () => {
    if (this.searchValue) {
      const lowerSearch = this.searchValue.toLowerCase();
      this.filteredImpacts = this.impacts.filter(impact => {
        const lowerName = impact.name.toLowerCase();
        const lowerRefNo = impact.refNumber.toLowerCase();

        return lowerName.includes(lowerSearch) || lowerRefNo.includes(lowerSearch);
      });
    } else {
      this.filteredImpacts = this.impacts;
    }
  };

  showAddImpactModal = () => {
    let formFields = getProjectStakeholderImpactsFormFields(
      this.projectStakeholderProvider,
      this.projectStakeholder,
      this.organisationId,
      this.projectId
    );
    let formModel = new SingleFormModel();
    let actions: IUiAction<any>[] = [
      {
        id: "action1",
        label: I18n.t("phrases.cancel"),
        onAction: ev => {
          this.modalService.hide();
        },
        componentProps: {
          type: ButtonTypes.LINK,
          className: "ml-auto"
        },
        rendersIn: UiActionRenderers.BUTTON
      },
      {
        id: "action2",
        label: I18n.t("phrases.save"),
        onAction: async ev => {
          let res = await formModel.submit();
          if (!res) return;
          await this.projectProvider.addProjectStakeholderImpacts(
            this.organisationId,
            this.projectId,
            this.projectStakeholder.id,
            res.impacts
          );

          this.modalService.hide();
          this.loadProjectStakeholder(this.projectId, this.stakeholderId);
        },
        componentProps: {
          className: "ml-2"
        },
        rendersIn: UiActionRenderers.BUTTON
      }
    ];
    formModel.formFields = formFields;
    formModel.actions = actions;

    return new Promise(resolve => {
      this.modalService.show({
        showClose: true,
        title: <h1 className="mt-6">{I18n.t("phrases.addImpacts")}</h1>,
        content: <div className="container-fluid">{formModel.renderComponent()}</div>,
        componentProps: {
          wrapHeight: "full",
          wrapWidth: "small",
          position: "right",
          panelProps: {
            background: Panel.PanelBackgrounds.BG_WHITE
          }
        },
        animationOptions: {
          animateIn: Animations.SLIDE_IN_RIGHT,
          animateOut: Animations.SLIDE_OUT_RIGHT,
          speed: 5
        },
        actions: []
      });
    });
  };

  showImpactConfirmDeleteModal = (impact: FP.Entities.IImpact) => {
    return new Promise(resolve => {
      this.modalService.showConfirmDialog(
        <h1 className="mt-4">{I18n.t("phrases.confirm")}</h1>,
        <div className="container-fluid">
          <div className="row">
            <div className="col">{I18n.t("phrases.confirmRemove", { name: impact.name })}</div>
          </div>
        </div>,
        I18n.t("phrases.yes"),
        I18n.t("phrases.no"),
        {
          wrapWidth: "small",
          spacing: "small",
          position: "middle",
          panelProps: {
            background: Panel.PanelBackgrounds.BG_WHITE
          }
        },
        async () => {
          this.modalService.hide();
          await this.removeImpact(this.projectStakeholder.id, impact.id);
          this.loadProjectStakeholder(this.projectId, this.stakeholderId);
          resolve(true);
        },
        () => {
          this.modalService.hide();
        },
        ButtonTypes.DANGER
      );
    });
  };
}
