import { action, makeObservable, observable } from "mobx";
import PermissionsContext from "../../../../../../../../contexts/permissions/PermissionsContext";
import { BaseModel } from "../../../../../../../util/BaseModel";

import { PermissionFields } from "../../../../../../../../contexts/permissions/PermissionsTypes";
import OrganisationsApi, {
  OrganisationsApi as IOrganisationsApi
} from "../../../../../../../../services/api/v2/organisations/Organisations.api";
import LocationsApi, {
  LocationsApi as ILocationsApi
} from "../../../../../../../../services/api/v2/locations/Locations.api";
import { SingleFormModel } from "../../../../../../../../pages/change/forms/singleFormModel/SingleForm_model";
import ProjectProcessesApi, {
  ProjectProcessesApi as IProjectProcessesApi
} from "../../../../../../../../services/api/v2/projectProcesses/ProjectProcesses.api";
import {
  getProjectProcessLocationsSidebarFormActions,
  getProjectProcessLocationsSidebarFormFields
} from "./ProjectProcessLocationsSidebar_fields";

export class ProjectProcessLocationsSidebarModel extends BaseModel {
  projectProcessProvider: IProjectProcessesApi;
  locationsProvider: ILocationsApi;
  organisationsProvider: IOrganisationsApi;
  @observable isLoading: boolean = true;
  @observable.ref projectProcess: FP.Entities.IProjectProcess;
  organisationId: number;
  projectId: number;
  @observable locationTreeData: FP.Entities.ITreeDataItem[];
  closeModalFn: () => void;
  canEdit: boolean;
  @observable.ref formModel: SingleFormModel;

  constructor(closeModalFn: () => void, organisationId: number, projectId: number, projectProcessId: number) {
    super();
    makeObservable(this);
    this.projectProcessProvider = ProjectProcessesApi;
    this.locationsProvider = LocationsApi;
    this.organisationsProvider = OrganisationsApi;
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.loadSidebar(projectProcessId);
    this.closeModalFn = closeModalFn;
    this.canEdit = PermissionsContext.canEditField(PermissionFields.IMPACTS, this.organisationId, this.projectId);
  }

  @action
  setProjectProcess = (projectProcess: FP.Entities.IProjectProcess) => {
    this.projectProcess = projectProcess;
  };

  @action
  getOrgLocations = async (itemId: number) => {
    let res = await this.projectProcessProvider.getLocationSelection(this.organisationId, this.projectId, itemId);
    if (!res || res.isError) return;
    this.locationTreeData = res.payload;
  };

  @action
  getItem = async (itemId: number) => {
    let res = await this.projectProcessProvider.getDetailedById(this.organisationId, this.projectId, itemId);
    if (!res || res.isError) return;
    this.setProjectProcess(res.payload);
  };

  @action
  updateItem = async () => {
    if (!this.canEdit) return;
    this.formModel.isSaving = true;
    let res: any = await this.formModel.submit();
    if (!res) return;
    await this.projectProcessProvider.updateLocations(
      this.organisationId,
      this.projectId,
      this.projectProcess.id,
      res.locations
    );
  };

  @action
  loadSidebar = async (itemId: number) => {
    this.setIsLoading(true);
    await this.getOrgLocations(itemId);
    await this.getItem(itemId);
    this.formModel = new SingleFormModel();
    this.formModel.formFields = getProjectProcessLocationsSidebarFormFields(this.locationTreeData);
    this.formModel.actions = getProjectProcessLocationsSidebarFormActions(
      this.closeModalFn,
      this.updateItem,
      this.canEdit
    );
    this.setIsLoading(false);
  };

  @action
  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };
}
