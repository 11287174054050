import { AxiosRequestConfig } from "axios";
import { action, makeObservable, observable } from "mobx";
import I18n from "../../../../../../core/localization/I18n";
import { IFlightPathApiResponse } from "../../../../../../services/api/v2/BaseApiModel";

interface PieDataObject {
  id: string;
  label: string;
  value: number;
  color: string;
}
export interface HeatmapSupportObject {
  impactLevelTotals: ImpactLevelTotals;
  impactDataConfidence: ImpactDataConfidence;
}

export interface ImpactDataConfidence {
  totalOrganisationImpacts: number;
  totalDefinableOrganisationImpacts: number;
  dataConfidence: number;
}

export interface ImpactLevelTotals {
  high: number;
  medium: number;
  low: number;
  unknown: number;
}

export interface SupportingDataConfig {
  getSupportingData?: (
    organisationId: number,
    tagIds: string,
    config?: AxiosRequestConfig
  ) => Promise<IFlightPathApiResponse<HeatmapSupportObject>>;
  getTagFilterIdsAsString: () => string;
  tags: any[];
}

export class SupportingHeatmapModel {
  @observable.ref supportingData: HeatmapSupportObject;
  @observable.ref impactLevelDataArray: PieDataObject[];
  @observable.ref dataConfidenceDataArray: PieDataObject[];
  @observable isLoading: boolean = true;
  config: SupportingDataConfig;
  organisationId: number;
  /**
   *
   */
  constructor(organisationId: number, config: SupportingDataConfig) {
    makeObservable(this);
    this.config = config;
    this.organisationId = organisationId;
  }

  onMount = async (signal?: AbortSignal) => {
    await this.loadSupportingData(signal);
  };

  mapToPieDataObject = (label: string, value: number, color: string) => {
    const result = {} as PieDataObject;
    result.id = label;
    result.label = label;
    result.value = value;
    result.color = color;
    return result;
  };

  getImpactLevelDataArray = async () => {
    const result = [];
    const data = await this.supportingData.impactLevelTotals;
    const lowLevel = this.mapToPieDataObject(I18n.t("phrases.low"), data.low, "#FDD886");
    const mediumLevel = this.mapToPieDataObject(I18n.t("phrases.medium"), data.medium, "#FFC036");
    const highLevel = this.mapToPieDataObject(I18n.t("phrases.high"), data.high, "#DC9700");
    const unknownLevel = this.mapToPieDataObject(I18n.t("phrases.unknown"), data.unknown, "#966700");
    result.push(lowLevel);
    result.push(mediumLevel);
    result.push(highLevel);
    result.push(unknownLevel);

    this.impactLevelDataArray = result;
    return result;
  };

  getDataConfidenceArray = async () => {
    const result = [];
    const data = await this.supportingData.impactDataConfidence;
    const missingImpactCount = data.totalOrganisationImpacts - data.totalDefinableOrganisationImpacts;

    const includedImpacts = this.mapToPieDataObject(
      I18n.t("phrases.includedImpacts"),
      data.totalDefinableOrganisationImpacts,
      "#58C8D1"
    );
    const missingImpacts = this.mapToPieDataObject(I18n.t("phrases.missingImpacts"), missingImpactCount, "#E5E5E5");

    result.push(includedImpacts);
    result.push(missingImpacts);

    this.dataConfidenceDataArray = result;
    return result;
  };

  setSupportingDataArrays = async () => {
    this.getImpactLevelDataArray();
    this.getDataConfidenceArray();
  };

  @action
  setSupportData = (data: HeatmapSupportObject) => {
    this.supportingData = data;
    this.setSupportingDataArrays();
    this.isLoading = false;
  };

  loadSupportingData = async (signal: AbortSignal) => {
    try {
      const result = await this.config.getSupportingData(this.organisationId, this.config.getTagFilterIdsAsString(), {
        signal
      });

      this.setSupportData(result.payload);
    } catch (err) {}
  };
}
