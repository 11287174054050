import * as _ from "lodash";
import { action, observable } from "mobx";
import moment from "moment";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  Legend,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis
} from "recharts";
import { Panel } from "../../../../components/ui/Panel";
import { ReportCardConfig, ReportCardModel } from "../../../../components/widgets/reportCard/ReportCard_model";
import PermissionsContext from "../../../../contexts/permissions/PermissionsContext";
import { PermissionFields } from "../../../../contexts/permissions/PermissionsTypes";
import I18n from "../../../../core/localization/I18n";
import { BaseModel } from "../../../../core/util/BaseModel";
import { convertStakeholderToName } from "../../../../core/util/Helpers";
import { StakeholderType } from "../../../../enums";
import ProjectsApi, { ProjectsApi as IProjectsApi } from "../../../../services/api/v2/projects/Projects.api";
import ReportsApi, { ReportsApi as IReportsApi } from "../../../../services/api/v2/reports/reports.api";

// Type for data in Stakeholder Heat Map chart
type StakeholderHeatMap = {
  impact: number;
  influence: number;
  stakeholders: {
    firstName: string;
    lastName: string;
    stakeholderId: number;
    projectStakeholderId: number;
    stakeholderType: StakeholderType;
  }[];
};

// Type for data in Impact Level chart
type ImpactLevel = {
  name: string;
  label: string;
  withActions: number;
  withoutActions: number;
};

// Type for data in Impact Over Time chart
type ImpactOverTime = {
  count: number;
  date: moment.Moment;
  ds: number;
};

// Type for data in Action Over Time chart
export type ActionOverTime = {
  initTotalCount: number;
  actualTotalCount: number;
  date: moment.Moment;
  ds: number;
};

// Height of the reports in pixels
export const REPORT_HEIGHT = 280;

// Size of the icons in reports
export const REPORT_ICON_SIZE = 7;

// Size of the Bar Chart bars
export const REPORT_BAR_SIZE = 7;

// Size of the points on graph
export const CHART_POINT_SIZE = REPORT_ICON_SIZE;

// Chart colours
export const CHART_COLOUR_PRIMARY = "#3454D1";
export const CHART_COLOUR_SECONDARY = "#FDB025";
export const CHART_COLOUR_TODAY_LINE = "#01C1A6";
export const CHART_COLOUR_ARRAY = ["#00135D", "#0022A4", "#3454D1", "#5F77D5", "#8C9BD3"];

// Chart line width
export const CHART_LINE_WIDTH = 2;

// Axis Labels translations
export const Y_AXIS_LABEL_DX = -5;
export const X_AXIS_LABEL_DY = 15;

// Chart margin
export const CHART_MARGIN = { left: -15, right: 5, top: 5, bottom: 5 };

// Reference line label position
export const REFERENCE_LINE_LABEL_DY = 20;
export const REFERENCE_LINE_LABEL_DX = 5;

// TODO: To be removed
export class ReportsViewModel extends BaseModel {
  projectId: number;
  projectsProvider: IProjectsApi;
  reportsProvider: IReportsApi;
  @observable.ref project: FP.Entities.IProject;
  @observable.ref reports: ReportCardModel[] = [];
  organisationId: number;

  /**
   *
   */
  constructor(projectId: number, organisationId: number) {
    super();

    this.projectId = projectId;
    this.organisationId = organisationId;
    this.projectsProvider = ProjectsApi;
    this.reportsProvider = ReportsApi;
    this.loadProject().then(() => {
      this.installReports();
    });
  }

  onMount = () => {};

  onUnmount = () => {};

  @action
  loadProject = async () => {
    const res = await this.projectsProvider.getById(this.organisationId, this.projectId);

    if (res?.payload) {
      this.setProject(res.payload);
    }
  };

  @action
  setProject = (project: FP.Entities.IProject) => {
    this.project = project;
  };

  installReports = () => {
    const stHeatMap: ReportCardConfig = {
      key: "st-heat-map",
      title: <h2 className="mb-0">{I18n.t("phrases.stakeholderHeatMap")}</h2>,
      loadData: async cardModel => {
        let res = await this.reportsProvider.getStHeatMapData(this.organisationId, this.projectId);
        if (!res || res.isError) return;
        cardModel.setData(res.payload);
      },
      render: (data: StakeholderHeatMap[]) => {
        if (!data || data.length === 0) {
          return <div>{I18n.t("phrases.noStakeholderHeatMapReportData")}</div>;
        }

        return (
          <ResponsiveContainer height={REPORT_HEIGHT} width="100%">
            <ScatterChart margin={CHART_MARGIN}>
              <CartesianGrid fill="#fff" />
              <XAxis
                dataKey="impact"
                name={I18n.t("table.impact")}
                type="number"
                domain={[0, 10]}
                ticks={[0, 5, 10]}
                tickLine={false}
                axisLine={false}
              >
                <Label dy={X_AXIS_LABEL_DY}>{I18n.t("table.impact")}</Label>
              </XAxis>
              <YAxis
                dataKey="influence"
                name={I18n.t("table.influence")}
                type="number"
                domain={[0, 10]}
                ticks={[0, 5, 10]}
                tickLine={false}
                axisLine={false}
              >
                <Label angle={-90} dx={Y_AXIS_LABEL_DX}>
                  {I18n.t("table.influence")}
                </Label>
              </YAxis>
              <Tooltip
                cursor={false}
                content={(props: TooltipProps) => {
                  const item: StakeholderHeatMap = props.payload[0]?.payload;
                  if (item) {
                    return (
                      <Panel.Panel hasShadow background={Panel.PanelBackgrounds.BG_WHITE} className="p-3">
                        <small className="d-block">
                          {I18n.t("table.impact")}: {item.impact}
                        </small>
                        <small className="d-block">
                          {I18n.t("table.influence")}: {item.influence}
                        </small>
                        <small className="d-block">{I18n.t("phrases.stakeholders")}:</small>
                        {item.stakeholders.slice(0, 5).map(stakeholder => (
                          <small key={stakeholder.stakeholderId} className="d-block">
                            {" "}
                            - {convertStakeholderToName(stakeholder)}
                          </small>
                        ))}
                        {item.stakeholders.length > 5 ? (
                          <small className="d-block">
                            {" "}
                            - {I18n.t("phrases.andNumMore", { num: item.stakeholders.length - 5 })}
                          </small>
                        ) : null}
                      </Panel.Panel>
                    );
                  }
                }}
              />
              <Scatter
                name={I18n.t("phrases.stakeholder")}
                data={data}
                fill={CHART_COLOUR_PRIMARY}
                r={CHART_POINT_SIZE}
                onClick={(data, index) => {
                  // appHistory.push(
                  //   `/organisations/${this.organisationId}/projects/${this.projectId}/stakeholders?influence=${data.influence}&impact=${data.impact}`
                  // );
                }}
              />
              <Legend
                align="right"
                verticalAlign="top"
                iconSize={REPORT_ICON_SIZE}
                formatter={value => <small>{value}</small>}
              />
            </ScatterChart>
          </ResponsiveContainer>
        );
      }
    };

    const impactLevel: ReportCardConfig = {
      key: "impact-action",
      title: <h2 className="mb-0">{I18n.t("table.impactLevel")}</h2>,
      loadData: async cardModel => {
        let res = await this.reportsProvider.getImpactLevelData(this.organisationId, this.projectId);
        if (!res || res.isError) return;
        const formattedImpactLevels: ImpactLevel[] = res.payload.map(impactLevel => {
          return {
            ...impactLevel,
            label: I18n.t(`phrases.${impactLevel.name}Abbr`)
          };
        });
        cardModel.setData(formattedImpactLevels);
      },
      render: (data: ImpactLevel[]) => {
        if (!data || data.every(e => e.withActions + e.withoutActions === 0)) {
          return <div>{I18n.t("phrases.noImpactLevelReportData")}</div>;
        }

        return (
          <ResponsiveContainer height={REPORT_HEIGHT} width="100%">
            <BarChart
              barSize={REPORT_BAR_SIZE}
              data={data}
              layout="vertical"
              margin={{
                left: CHART_MARGIN.left + 20,
                right: CHART_MARGIN.right,
                top: CHART_MARGIN.top,
                bottom: CHART_MARGIN.bottom
              }}
            >
              <CartesianGrid fill="#fff" horizontal={false} />
              <XAxis type="number" axisLine={false} tickLine={false}>
                <Label dy={X_AXIS_LABEL_DY}>{I18n.t("phrases.noOfImpacts")}</Label>
              </XAxis>
              <YAxis type="category" dataKey="label" axisLine={false} tickLine={false}>
                <Label angle={-90} dx={Y_AXIS_LABEL_DX - 20}>
                  {I18n.t("table.impactLevel")}
                </Label>
              </YAxis>
              <Bar
                dataKey="withActions"
                stackId="s"
                fill={CHART_COLOUR_SECONDARY}
                onClick={(data, index) => {
                  // const impactLevel = Enums.ImpactLevel[(data.name as string).toUpperCase()];
                  // appHistory.push(
                  //   `/organisations/${this.organisationId}/projects/${this.projectId}/impacts?impactLevelGen=${impactLevel}&withActions=true`
                  // );
                }}
              />
              <Bar
                dataKey="withoutActions"
                stackId="s"
                fill={CHART_COLOUR_PRIMARY}
                onClick={(data, index) => {
                  // const impactLevel = Enums.ImpactLevel[(data.name as string).toUpperCase()];
                  // appHistory.push(
                  //   `/organisations/${this.organisationId}/projects/${this.projectId}/impacts?impactLevelGen=${impactLevel}&withActions=false`
                  // );
                }}
              />
              <Tooltip
                cursor={false}
                content={(props: TooltipProps) => {
                  const item = props.payload[0]?.payload;
                  if (item) {
                    return (
                      <Panel.Panel hasShadow background={Panel.PanelBackgrounds.BG_WHITE} className="p-3">
                        <small className="d-block">{item.name}</small>
                        <small className="d-block">
                          {I18n.t("phrases.withActions")}: {item.withActions}
                        </small>
                        <small className="d-block">
                          {I18n.t("phrases.withoutActions")}: {item.withoutActions}
                        </small>
                      </Panel.Panel>
                    );
                  }
                }}
              />
              <Legend
                iconType="circle"
                iconSize={REPORT_ICON_SIZE}
                align="right"
                verticalAlign="top"
                formatter={value => <small>{I18n.t(`phrases.${value}`)}</small>}
              />
            </BarChart>
          </ResponsiveContainer>
        );
      }
    };

    const actionOverTime: ReportCardConfig = {
      key: "action-overtime",
      title: <h2 className="mb-0">{I18n.t("phrases.actionOverTime")}</h2>,
      loadData: async cardModel => {
        const res = await this.reportsProvider.getActionOverTimeData(this.organisationId, this.projectId);

        if (!res || res.isError) return;

        const formattedData: ActionOverTime[] = res.payload.map(e => ({
          initTotalCount: e.initTotalCount,
          actualTotalCount: e.actualTotalCount,
          date: moment(e.date),
          ds: moment(e.date).valueOf()
        }));

        cardModel.setData(formattedData);
      },
      render: (data: ActionOverTime[]) => {
        if (!data || data.length === 0) {
          return <div>{I18n.t("phrases.noActionOverTimeReportData")}</div>;
        }

        data = data.map(e => {
          e.date = moment(e.date).valueOf() as any;
          return e;
        });

        const earliestAction = _.minBy(data, e => e.ds);
        const latestAction = _.maxBy(data, e => e.ds);
        const minDate: moment.Moment =
          earliestAction?.ds < moment(this.project.startDate).valueOf()
            ? moment(earliestAction.date)
            : moment(this.project.startDate);
        const maxDate: moment.Moment =
          latestAction?.ds > moment(this.project.actualEndDate).valueOf()
            ? moment(latestAction.date)
            : moment(this.project.actualEndDate);

        return (
          <ResponsiveContainer height={REPORT_HEIGHT} width={"100%"}>
            <LineChart className={"reports__with-today"} data={data} margin={CHART_MARGIN}>
              <CartesianGrid fill="#fff" />
              <XAxis
                name="date"
                scale="time"
                type="number"
                domain={[minDate.subtract(1, "week").valueOf(), maxDate.add(1, "week").valueOf()]}
                dataKey="ds"
                axisLine={false}
                tickLine={false}
                tick={false}
              >
                <Label dy={X_AXIS_LABEL_DY}>{I18n.t("phrases.time")}</Label>
              </XAxis>
              <YAxis axisLine={false} tickLine={false} allowDecimals={false}>
                <Label angle={-90} dx={Y_AXIS_LABEL_DX}>
                  {I18n.t("phrases.actions")}
                </Label>
              </YAxis>
              <Tooltip
                cursor={false}
                content={(props: TooltipProps) => {
                  const item = props.payload[0]?.payload;
                  if (item) {
                    return (
                      <Panel.Panel hasShadow background={Panel.PanelBackgrounds.BG_WHITE} className="p-3">
                        <small className="d-block">{moment(item.date).format("L")}</small>
                        <small className="d-block">
                          {I18n.t("phrases.planned")}: {item.initTotalCount}
                        </small>
                        <small className="d-block">
                          {I18n.t("phrases.actual")}: {item.actualTotalCount}
                        </small>
                      </Panel.Panel>
                    );
                  }
                }}
              />
              <Legend
                verticalAlign="top"
                align="right"
                formatter={value => <small>{value}</small>}
                payload={[
                  { id: "initTotalCount", value: I18n.t("phrases.planned"), type: "line", color: CHART_COLOUR_PRIMARY },
                  {
                    id: "actualTotalCount",
                    value: I18n.t("phrases.actual"),
                    type: "line",
                    color: CHART_COLOUR_SECONDARY
                  },
                  { id: "today", value: I18n.t("phrases.today"), type: "circle", color: CHART_COLOUR_TODAY_LINE }
                ]}
              />
              <Line
                dataKey="initTotalCount"
                name={I18n.t("phrases.planned")}
                fill={CHART_COLOUR_PRIMARY}
                stroke={CHART_COLOUR_PRIMARY}
                strokeWidth={CHART_LINE_WIDTH}
                activeDot={{ r: CHART_POINT_SIZE }}
              />
              <Line
                dataKey="actualTotalCount"
                name={I18n.t("phrases.actual")}
                fill={CHART_COLOUR_SECONDARY}
                stroke={CHART_COLOUR_SECONDARY}
                strokeWidth={CHART_LINE_WIDTH}
                activeDot={{ r: CHART_POINT_SIZE }}
              />
              <ReferenceLine x={moment().valueOf()} stroke={CHART_COLOUR_TODAY_LINE} strokeWidth={CHART_LINE_WIDTH} />
              <ReferenceLine x={moment(this.project.startDate).valueOf()} strokeDasharray="3 3">
                <Label position="insideBottomLeft" dy={REFERENCE_LINE_LABEL_DY} dx={-REFERENCE_LINE_LABEL_DX}>
                  {I18n.t("phrases.projectStart")}
                </Label>
              </ReferenceLine>
              <ReferenceLine x={moment(this.project.actualEndDate).valueOf()} strokeDasharray="3 3">
                <Label position="insideBottomRight" dy={REFERENCE_LINE_LABEL_DY} dx={REFERENCE_LINE_LABEL_DX}>
                  {I18n.t("phrases.projectEnd")}
                </Label>
              </ReferenceLine>
            </LineChart>
          </ResponsiveContainer>
        );
      }
    };

    const impactOverTime: ReportCardConfig = {
      key: "impact-overtime",
      title: <h2 className="mb-0">{I18n.t("phrases.impactOverTime")}</h2>,
      loadData: async cardModel => {
        const res = await this.reportsProvider.getImpactOverTimeData(this.organisationId, this.projectId);

        if (!res || res.isError) return;

        const formattedData: ImpactOverTime[] = res.payload.map(e => {
          return {
            count: e.count,
            date: moment(e.date),
            ds: moment(e.date).valueOf()
          };
        });

        cardModel.setData(formattedData);
      },
      render: (data: ImpactOverTime[]) => {
        if (!data || data.length === 0) {
          return <div>{I18n.t("phrases.noImpactOverTimeReportData")}</div>;
        }

        data = data.map(e => {
          e.date = moment(e.date).valueOf() as any;
          return e;
        });

        const earliestImpact: ImpactOverTime = _.minBy(data, e => e.ds);
        const latestImpact: ImpactOverTime = _.maxBy(data, e => e.ds);
        const minDate: moment.Moment =
          earliestImpact?.ds < moment(this.project.startDate).valueOf()
            ? moment(earliestImpact.date)
            : moment(this.project.startDate);
        const maxDate: moment.Moment =
          latestImpact?.ds > moment(this.project.actualEndDate).valueOf()
            ? moment(latestImpact.date)
            : moment(this.project.actualEndDate);

        return (
          <ResponsiveContainer height={REPORT_HEIGHT} width="100%">
            <LineChart className={"reports__with-today"} data={data} margin={CHART_MARGIN}>
              <CartesianGrid fill="#fff" />
              <XAxis
                name="date"
                axisLine={false}
                tickLine={false}
                scale="time"
                type="number"
                domain={[minDate.subtract(1, "week").valueOf(), maxDate.add(1, "week").valueOf()]}
                dataKey="ds"
                tick={false}
              >
                <Label dy={X_AXIS_LABEL_DY}>{I18n.t("phrases.time")}</Label>
              </XAxis>
              <YAxis axisLine={false} tickLine={false} allowDecimals={false}>
                <Label angle={-90} dx={Y_AXIS_LABEL_DX}>
                  {I18n.t("phrases.impacts")}
                </Label>
              </YAxis>
              <Tooltip
                cursor={false}
                content={(props: TooltipProps) => {
                  const item = props.payload[0]?.payload;
                  if (item) {
                    return (
                      <Panel.Panel hasShadow background={Panel.PanelBackgrounds.BG_WHITE} className="p-3">
                        <small className="d-block">{moment(item.date).format("L")}</small>
                        <small className="d-block">
                          {I18n.t("phrases.impacts")}: {item.count}
                        </small>
                      </Panel.Panel>
                    );
                  }
                }}
              />
              <Legend
                verticalAlign="top"
                align="right"
                formatter={value => <small>{value}</small>}
                payload={[
                  { id: "count", value: I18n.t("phrases.actual"), type: "line", color: CHART_COLOUR_PRIMARY },
                  { id: "today", value: I18n.t("phrases.today"), type: "circle", color: CHART_COLOUR_TODAY_LINE }
                ]}
              />
              <Line
                dataKey="count"
                name={I18n.t("phrases.actual")}
                fill={CHART_COLOUR_PRIMARY}
                stroke={CHART_COLOUR_PRIMARY}
                strokeWidth={CHART_LINE_WIDTH}
                activeDot={{ r: CHART_POINT_SIZE }}
              />
              <ReferenceLine x={moment().valueOf()} stroke={CHART_COLOUR_TODAY_LINE} strokeWidth={CHART_LINE_WIDTH} />
              <ReferenceLine x={moment(this.project.startDate).valueOf()} strokeDasharray="3 3">
                <Label position="insideBottomLeft" dy={REFERENCE_LINE_LABEL_DY} dx={-REFERENCE_LINE_LABEL_DX}>
                  {I18n.t("phrases.projectStart")}
                </Label>
              </ReferenceLine>

              <ReferenceLine x={moment(this.project.actualEndDate).valueOf()} strokeDasharray="3 3">
                <Label position="insideBottomRight" dy={REFERENCE_LINE_LABEL_DY} dx={REFERENCE_LINE_LABEL_DX}>
                  {I18n.t("phrases.projectEnd")}
                </Label>
              </ReferenceLine>
            </LineChart>
          </ResponsiveContainer>
        );
      }
    };

    const canViewImpacts = PermissionsContext.canViewField(
      PermissionFields.IMPACTS,
      this.organisationId,
      this.projectId
    );

    const canViewStakeholders = PermissionsContext.canViewField(
      PermissionFields.STAKEHOLDERS,
      this.organisationId,
      this.projectId
    );

    const canViewActions = PermissionsContext.canViewField(
      PermissionFields.ACTIONS,
      this.organisationId,
      this.projectId
    );

    if (canViewActions) {
      let actionOverTimeModel = new ReportCardModel(actionOverTime);
      this.addReport(actionOverTimeModel);
    }
    if (canViewImpacts) {
      let impactOverTimeModel = new ReportCardModel(impactOverTime);
      let impactLabelModel = new ReportCardModel(impactLevel);
      this.addReport(impactOverTimeModel);
      this.addReport(impactLabelModel);
    }
    if (canViewStakeholders) {
      let stHeatMapModel = new ReportCardModel(stHeatMap);
      this.addReport(stHeatMapModel);
    }
  };

  @action
  addReport = (report: ReportCardModel) => {
    let k = this.reports.slice();
    k.push(report);
    this.reports = k;
  };
}
