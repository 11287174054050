import { observer } from "mobx-react";
import React from "react";
import { PositionedSpinner } from "../../../../../../../../components/ui/PositionedSpinner";
import { ImpactProcessSidebarModel } from "./ImpactProcessSidebar_model";
import { SingleForm } from "../../../../../../../../pages/change/forms/singleFormModel/SingleForm_view";

interface ImpactProcessSidebarProps {
  model: ImpactProcessSidebarModel;
}

export const ImpactProcessSidebar: React.FC<ImpactProcessSidebarProps> = observer(({ model }) => {
  if (model.isLoading) return <PositionedSpinner />;

  return (
    <div className="impact-project-process-sidebar container-fluid pt-3">
      <SingleForm model={model.formModel} />
    </div>
  );
});
