import { Icon } from "@flightpath/coreui/dist/ui/Icon";
import I18n from "../../../../../localization/I18n";
import ModalContext from "../../../../../modalZ/context/ModalContext";
import { LabelGrid } from "../../../../commonGrids/LabelGrid/LabelGrid_view";
import { StakeholderGrid } from "../../../../commonGrids/StakeholderGrid/StakeholderGrid_view";
import { WrapperImpactGridWithDataLoader } from "../ImpactsGrid/WrapperImpactsGridWithDataLoader_view";
import { IconSymbols } from "@flightpath/coreui/dist/generated/IconSymbols";
import { ButtonTypes } from "@flightpath/coreui/dist/ui/Button";
import { IToasterService } from "../../../../../toaster/ToasterService";
import {
  ReviewModalComponentProps,
  ReviewModalContent,
  ReviewModalTitle
} from "../../../../../../components/ui/ReviewModal";
import { TOASTER_TOAST_TIME } from "../../../../../toaster/Toaster_model";
import { Panel } from "../../../../../../components/ui/Panel";
import { ActionAudiencesLinkModalView } from "./modals/actionAudienceLinkModal/ActionAudienceLinkModal_view";

export const SHOW_CONFIRM_CREATION_MODAL = (name): Promise<boolean> => {
  return new Promise(async resolve => {
    await ModalContext.showConfirmDialog(
      <h1 className="mt-4">{I18n.t("phrases.confirm")}</h1>,
      <div className="container-fluid">
        <div className="row mb-3">
          <div className="col-12">
            <Icon symbol={IconSymbols.AlertCircle} className="mr-2" />
            {I18n.t("warnings.createSameNameRow")}
          </div>
        </div>
        <div className="row">
          <div className="col">{I18n.t("warnings.confirmSameName", { name: name })}</div>
        </div>
      </div>,
      I18n.t("phrases.yes"),
      I18n.t("phrases.no"),
      {
        wrapWidth: "small",
        spacing: "small",
        position: "middle",
        panelProps: {
          background: Panel.PanelBackgrounds.BG_WHITE
        }
      },
      async () => {
        ModalContext.hide();
        resolve(true);
      },
      () => {
        ModalContext.hide();
        resolve(false);
      }
    );
  });
};

export const SHOW_ACTION_DELETE_CONFIRM_MODAL = (
  actionIds: number[],
  removeActionRange: (actionIds: number[]) => Promise<any>
) => {
  return new Promise(resolve => {
    ModalContext.showConfirmDialog(
      <h1 className="mt-4">{I18n.t("phrases.confirm")}</h1>,
      <div className="container-fluid">
        <div className="row mb-3">
          <div className="col-12">
            <Icon symbol={IconSymbols.AlertCircle} className="mr-2" />
            {I18n.t("warnings.removeActionFromProject")}
          </div>
        </div>
        <div className="row">
          <div className="col">{I18n.t("phrases.confirmActionRangeRemove", { name: actionIds.length + "" })}</div>
        </div>
      </div>,
      I18n.t("phrases.yes"),
      I18n.t("phrases.no"),
      {
        wrapWidth: "small",
        spacing: "small",
        position: "middle",
        panelProps: {
          background: Panel.PanelBackgrounds.BG_WHITE
        }
      },
      async () => {
        ModalContext.hide();
        await removeActionRange(actionIds);
        resolve(true);
      },
      () => {
        ModalContext.hide();
      },
      ButtonTypes.DANGER
    );
  });
};

export const SHOW_ACTION_REVIEW_CONFIRM_MODAL = (
  actionIds: number[],
  reviewActionRange: (actionIds: number[], comment: string) => Promise<any>,
  toastService: IToasterService,
  onCloseFn: () => void
) => {
  return new Promise(resolve => {
    let comment = "";
    const handleInputChange = ss => {
      comment = ss;
    };
    ModalContext.showConfirmDialog(
      <ReviewModalTitle />,
      <ReviewModalContent reviewCommentInput={comment} handler={handleInputChange} />,
      I18n.t("phrases.confirmReview"),
      I18n.t("phrases.cancelReview"),
      ReviewModalComponentProps,
      async () => {
        await reviewActionRange(actionIds, comment);
        ModalContext.hide();
        toastService
          .showSuccessToast()
          .setContent(<span>{I18n.t("phrases.reviewCompleted")}</span>)
          .startTimer(TOASTER_TOAST_TIME.NORMAL);
        onCloseFn();
        resolve(true);
      },
      () => {
        ModalContext.hide();
      },
      ButtonTypes.PRIMARY,
      ButtonTypes.OUTLINE_PRIMARY
    );
  });
};

export const ACTIONS_LINK_TO_STAKEHOLDERS_MODAL_CONFIG = (
  projectId: number,
  onAssignSuccess: (itemIds: number[]) => Promise<any>
) => {
  return {
    showClose: false,
    title: null,
    content: <StakeholderGrid projectId={projectId} onAssignSuccess={onAssignSuccess} />
  };
};

export const ACTIONS_LINK_TO_IMPACTS_MODAL_CONFIG = (
  selectedLinkItems: number[],
  onAssignSuccess: (itemIds: number[]) => Promise<any>
) => {
  return {
    showClose: false,
    title: null,
    className: "d-flex flex-column",
    panelProps: {
      className: "d-flex flex-column h-100"
    },
    content: <WrapperImpactGridWithDataLoader onAssignSuccess={onAssignSuccess} selectedLinkItems={selectedLinkItems} />
  };
};

export const ACTIONS_LINK_TO_LABELS_MODAL_CONFIG = (
  projectId: number,
  onAssignSuccess: (itemIds: number[]) => Promise<any>
) => {
  return {
    showClose: false,
    title: null,
    content: <LabelGrid projectId={projectId} onAssignSuccess={onAssignSuccess} />
  };
};

export const ACTIONS_LINK_TO_AUDIENCES_MODAL_CONFIG = (organisationId: number, projectId: number, item: any) => {
  return {
    showClose: false,
    title: null,
    className: "d-flex flex-column",
    panelProps: {
      className: "d-flex flex-column h-100"
    },
    content: (
      <ActionAudiencesLinkModalView
        itemIds={[item.id]}
        selectedLinkItemIds={item.audiences.map(e => e.id)}
      ></ActionAudiencesLinkModalView>
    )
  };
};
