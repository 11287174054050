import * as React from "react";
import { IToasterModel } from "./Toaster_model";
import { Toaster } from "../../components/ui/Toaster";
import { observer } from "mobx-react";

export interface ToasterViewProps {
  model: IToasterModel;
}

const ToasterView: React.FunctionComponent<ToasterViewProps> = observer(props => {
  let { model } = props;
  return (
    <Toaster
      panelProps={model.panelProps}
      animationsClassName={model.animationClass}
      onMouseEnter={model.cancelAutoDestroy}
      actions={model.actions}
      actionAlignedTitle={model.actionAlignedTitle}
    >
      {model.content}
    </Toaster>
  );
});

export { ToasterView };
