import { Link } from "react-router-dom";
import { ISdColDef } from "../../baseColumn/BaseColumnTypes";
import { BaseTextColumnBuilder } from "../../baseColumn/BaseTextColumn_builder";
import { SIMPLE_TEXT_COLUMN_CONFIG } from "./SimpleTextColumn_config";
import ModalContext from "../../../../../../modalZ/context/ModalContext";

export class SimpleTextColumnBuilder extends BaseTextColumnBuilder {
  constructor(columnOptions?: ISdColDef<any, any>) {
    super({ ...SIMPLE_TEXT_COLUMN_CONFIG(), ...columnOptions });
  }

  withLinkCell = (urlCb: (id: number | string) => string) => {
    this.setColumnOptions({
      cellRenderer: params => {
        const itemId = params.data.id;
        const mainUrl = urlCb(itemId);
        return (
          <Link key={itemId} to={`${mainUrl}`} onClick={() => ModalContext.hide()}>
            <span className="hover-link">{params.data.name}</span>
          </Link>
        );
      }
    });
    return this;
  };
}
