import ProgressIndicatorModel from "../../../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import GridToastService from "../../../../../../services/local/gridToastService/GridToastService";
import { valueOrNoInput } from "../../utils/helpers";
import { BaseColumnBuilder } from "./BaseColumn_builder";
export abstract class BaseTextColumnBuilder extends BaseColumnBuilder {
  gridToastService = GridToastService;
  httpProgress = ProgressIndicatorModel;

  createValueGetter = () => {
    this.setColumnOptions({
      valueGetter: getterProps => valueOrNoInput(getterProps.data[this.columnOptionsBuilder.columnOptions.field])
    });
  };
  createValueSetter = (updateField: (entityId, text) => Promise<any>) => {
    this.setColumnOptions({
      valueSetter: params => {
        if (!params.newValue) return false;
        updateField(params.data.id, params.newValue);
        return true;
      }
    });
  };
}
