import axios, { InternalAxiosRequestConfig } from "axios";

import authService from "../../external/okta/OktaService";
import { getAppToken } from "../../../core/auth_insight";
import ProgressIndicator from "../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import PermissionsContext from "../../../contexts/permissions/PermissionsContext";
import OrganisationSettingsContext from "../../../contexts/organisationSettings/OrganisationSettingsContext";

const qs = require("qs");

const http = axios.create({
  baseURL: appConfig.flightPathCoreApiUrl,
  timeout: 300000,
  headers: {},
  paramsSerializer: function (params) {
    return qs.stringify(params, {
      encode: false
    });
  },
  withCredentials: true
});

http.interceptors.request.use(
  async (config: InternalAxiosRequestConfig<any>) => {
    if (config.method !== "get" || config?.params?.forceTopProgressBar) {
      ProgressIndicator.showTopProgressBarVisible();
    }
    const token = await getAppToken(authService);
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  response => {
    ProgressIndicator.hideTopProgressBarVisible();
    onRequestReceived(response);
    return response;
  },
  error => {
    ProgressIndicator.hideTopProgressBarVisible();
    if (
      !!error.response &&
      !!error.response.data.error &&
      !!error.response.data.error.message &&
      error.response.data.error.details
    ) {
    } else if (!!error.response && !!error.response.data.error && !!error.response.data.error.message) {
    } else if (!error.response) {
    }

    setTimeout(() => {}, 1000);

    return Promise.reject(error);
  }
);

const onRequestReceived = result => {
  updatePermissions(result);
  updateOrganisationSettings(result);
};

const updatePermissions = result => {
  PermissionsContext.updateFromHeaders(result.headers["seren-insight-perv"], result.headers["seren-insight-per"]);
};

const updateOrganisationSettings = result => {
  OrganisationSettingsContext.updateFromHeaders(
    result.headers["seren-insight-osv"],
    result.headers["seren-insight-os"]
  );
};

export default http;
