import { PositionedSpinner } from "@flightpath/coreui/dist/ui/PositionedSpinner";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useCurrentOrganisationId } from "../../../../../services/local/organisationContext/OrganisationContextModel";
import { ActionReportingViewModel } from "./ActionReportingView_model";
import { ActionsReportingTimeline } from "./ActionsReportingTimeline/ActionsReportingTimeline_view";
import { TopActionsReportingBar } from "./TopActionsReportingBar/TopActionsReportingBar_view";

export interface ActionReportingViewProps {
  model?: ActionReportingViewModel;
}

export const ActionReportingView: React.FC<ActionReportingViewProps> = observer(({ model: m }) => {
  const navigate = useNavigate();
  const organisationId = useCurrentOrganisationId();
  const { projectId, page } = useParams<{ projectId: string; page: string }>();
  const [model, setModel] = useState(
    () => m || new ActionReportingViewModel(organisationId, +projectId, navigate, page)
  );
  const modelProjectId = model.projectId;

  useEffect(() => {
    model.onMount();
    return model.onUnmount;
  }, [model]);

  useEffect(() => {
    if (`${modelProjectId}` !== projectId) {
      setModel(new ActionReportingViewModel(organisationId, +projectId, navigate, page));
    }
  }, [projectId, modelProjectId, organisationId, model, navigate, page]);

  if (model.isLoading) return <PositionedSpinner />;
  return (
    <div>
      <div className="container-fluid">
        <TopActionsReportingBar
          ragData={model.ragData}
          actionCount={model.actionCount}
          totalConfidenceMetric={model.totalConfidenceMetric}
          totalConfidenceData={model.totalConfidenceData}
        />
        {model.actions && model.project && <ActionsReportingTimeline actions={model.actions} project={model.project} />}
      </div>
    </div>
  );
});
