import React from "react";
import { GridHubStoreModel, IGridHubStoreModel } from "./GridHubStore";
import ActionsHub, { ActionsHub as IActionsHub } from "../../../services/hubs/ActionsHub/Actions_hub";
import ImpactsHub, { ImpactsHub as IImpactsHub } from "../../../services/hubs/ImpactsHub/Impacts_hub";
import ProjectStakeholdersHub, {
  ProjectStakeholdersHub as IProjectStakeholdersHub
} from "../../../services/hubs/ProjectStakeholdersHub/ProjectStakeholders_hub";
import ProjectAudiencesHub, {
  ProjectAudiencesHub as IProjectAudiencesHub
} from "../../../services/hubs/ProjectAudiencesHub/ProjectAudiences_hub";
import ProjectJobRoleHub, {
  ProjectJobRoleHub as IProjectJobRoleHub
} from "../../../services/hubs/ProjectJobRoleHub/ProjectJobRole_hub";
import ImpactGroupsHub, {
  ImpactGroupsHub as IImpactGroupsHub
} from "../../../services/hubs/ImpactGroupsHub/ImpactGroups_hub";
import ProjectProcessesHub, {
  ProjectProcessesHub as IProjectProcessesHub
} from "../../../services/hubs/ProjectProcessesHub/ProjectProcesses_hub";
import AudiencesHub, { AudiencesHub as IAudienceHub } from "../../../services/hubs/AudiencesHub/Audiences_hub";

class RootStore {
  actionStore: IGridHubStoreModel<FP.Entities.IActionSummary>;
  impactStore: IGridHubStoreModel<FP.Entities.IImpactSummary>;
  projectStakeholderStore: IGridHubStoreModel<FP.Entities.IProjectStakeholderSummary>;
  projectAudienceStore: IGridHubStoreModel<FP.Entities.IProjectAudienceSummary>;
  projectProcessStore: IGridHubStoreModel<FP.Entities.IProcessSummary>;
  projectJobRoleStore: IGridHubStoreModel<FP.Entities.IProjectJobRoleSummary>;
  impactGroupStore: IGridHubStoreModel<FP.Entities.IImpactGroupSummary>;
  audienceStore: IGridHubStoreModel<FP.Entities.IAudienceSummary>;

  constructor() {
    this.actionStore = new GridHubStoreModel<FP.Entities.IActionSummary, IActionsHub>(
      this,
      ActionsHub,
      false,
      (data: FP.Entities.IActionSummary[]) => {
        data.forEach(e => {
          e.audiences = JSON.parse(e.audiences);
          e.actionAudiences = JSON.parse(e.actionAudiences);
        });
        return data;
      }
    );
    this.impactStore = new GridHubStoreModel<FP.Entities.IImpactSummary, IImpactsHub>(
      this,
      ImpactsHub,
      false,
      (data: FP.Entities.IImpactSummary[]) => {
        data.forEach(e => {
          e.audiences = JSON.parse(e.audiences);
        });
        return data;
      }
    );
    this.projectStakeholderStore = new GridHubStoreModel<
      FP.Entities.IProjectStakeholderSummary,
      IProjectStakeholdersHub
    >(this, ProjectStakeholdersHub);
    this.projectAudienceStore = new GridHubStoreModel<FP.Entities.IProjectAudienceSummary, IProjectAudiencesHub>(
      this,
      ProjectAudiencesHub
    );
    this.projectProcessStore = new GridHubStoreModel<FP.Entities.IProcessSummary, IProjectProcessesHub>(
      this,
      ProjectProcessesHub
    );
    this.projectJobRoleStore = new GridHubStoreModel<FP.Entities.IProjectJobRoleSummary, IProjectJobRoleHub>(
      this,
      ProjectJobRoleHub
    );
    this.impactGroupStore = new GridHubStoreModel<FP.Entities.IImpactGroupSummary, IImpactGroupsHub>(
      this,
      ImpactGroupsHub
    );
    this.audienceStore = new GridHubStoreModel<FP.Entities.IAudienceSummary, IAudienceHub>(
      this,
      AudiencesHub,
      false,
      (data: FP.Entities.IAudienceSummary[]) => {
        data.forEach(e => {
          e.impacts = JSON.parse(e.impacts);
          e.actions = JSON.parse(e.actions);
          e.impactActions = JSON.parse(e.impactActions);
        });
        return data;
      }
    );
  }
}

const StoresContext = React.createContext(new RootStore());

// this will be the function available for the app to connect to the stores
export const useStores = () => React.useContext(StoresContext);
