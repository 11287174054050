import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { IF } from "../../../../../../components/hoc/If";
import { useCurrentOrganisationId } from "../../../../../../services/local/organisationContext/OrganisationContextModel";
import "./_programme-dashboard-capacity-chart.scss";
import { ProgrammeChangeTeamCapacityChartModel } from "./ProgrammeChangeTeamCapacityChart_model";
import { BarChart, BarChartProps } from "../../../../../../core/charts/BarChart/BarChart";

export type ProgrammeChangeTeamCapacityChartProps = {
  data: any;
  title?: string;
  transformer: (data: any[]) => BarChartProps;
  clearFilterFN?: () => any;
  filterModalFn?: any;
};

export const ProgrammeChangeTeamCapacityChart: React.FC<ProgrammeChangeTeamCapacityChartProps> = observer(
  ({ title, data, transformer, filterModalFn, clearFilterFN }) => {
    const organisationId = useCurrentOrganisationId();

    const [model] = useState(() => new ProgrammeChangeTeamCapacityChartModel(organisationId, transformer));

    useEffect(() => {
      model.setData(data);
      model.onMount();
      // eslint-disable-next-line
    }, [data]);

    return (
      <div className="col">
        <IF condition={model.chartData?.data?.filter(e => e.actionCount > 0 || e.impactCount > 0).length === 0}>
          <div className="col">
            <p>There was no data to construct the chart</p>
          </div>
        </IF>
        <IF condition={model.chartData?.data?.filter(e => e.actionCount > 0 || e.impactCount > 0).length > 0}>
          <div className="programme-change-team-capacity-chart__wrapper">
            <BarChart {...model.chartData} />
          </div>
        </IF>
      </div>
    );
  }
);
