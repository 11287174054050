import { FilteredApiModel, IFilteredOptions } from "../filteredApi/FilteredApiModel";
import { AxiosInstance, AxiosRequestConfig } from "axios";
import { IFlightPathApiResponse } from "../BaseApiModel";
import http from "../Http";

export class CommentsApi extends FilteredApiModel<FP.Entities.IComment> {
  controller: string = "organisations";

  constructor(http: AxiosInstance) {
    super(http, "organisations");
  }

  async removeFN(
    organisationId: number,
    projectId: number,
    id: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<void>> {
    let res = await this.http.delete(`${this.url}${organisationId}/projects/${projectId}/comments/${id}`, config);
    if (res && res.status === 200) {
      return res.data;
    }
  }

  getFiltered = async (
    organisationId: number,
    projectId: number,
    filterOptions: Partial<IFilteredOptions>,
    config?: AxiosRequestConfig
  ) => {
    const query = this.getRequestQuery(filterOptions);

    const res = await this.http.get(`${this.url}${organisationId}/projects/${projectId}/comments${query}`, config);
    if (res && res.status === 200) {
      return res.data;
    }
  };

  async createFN(
    organisationId: number,
    projectId: number,
    data: FP.Entities.IComment,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IComment>> {
    let res = await this.http.post(`${this.url}${organisationId}/projects/${projectId}/comments`, data, config);
    if (res && res.status === 200) {
      return res.data;
    }
  }

  async createAndReview(
    organisationId: number,
    projectId: number,
    data: FP.Entities.IComment,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IComment>> {
    let res = await this.http.post(`${this.url}${organisationId}/projects/${projectId}/comments/reviews`, data, config);
    if (res && res.status === 200) {
      return res.data;
    }
  }

  updateFN = async (
    organisationId: number,
    projectId: number,
    id: number,
    data: FP.Entities.IComment,
    config?: AxiosRequestConfig
  ) => {
    let res = await this.http.put(`${this.url}${organisationId}/projects/${projectId}/comments/${id}`, data, config);
    if (res && res.status === 200) {
      return res.data;
    }
  };
}

const instance = new CommentsApi(http);
export default instance;
