import {
  Table,
  TableCol,
  TableRow,
  TableBody,
  TableHeaderGroup,
  TableHeader,
  TableFooter,
  TableProps as TP,
  TableHeaderGroupProps as THGP,
  TableRowProps as TRP
} from "@flightpath/coreui/dist/ui/Table";

export interface TableProps extends TP {
  isSticky?: boolean;
}
export interface TableHeaderGroupProps extends THGP {}
export interface TableRowProps extends TRP {}

let s = {
  Table: Table,
  Col: TableCol,
  Row: TableRow,
  Body: TableBody,
  Header: TableHeaderGroup,
  HeaderCol: TableHeader,
  Footer: TableFooter
};

export default s;
