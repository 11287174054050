import React, { useEffect, useState, useCallback } from "react";
import { StakeholdersSettingsModel } from "./StakeholdersSettings_model";
import { Button, ButtonTypes, LinkButton } from "../../../../../components/ui/Button";
import { SearchButton } from "../../../../../components/ui/SearchButton";
import { PositionedSpinner } from "../../../../../components/ui/PositionedSpinner";
import { observer } from "mobx-react";
import { TableView } from "../../../../../core/table/Table_view";
import { Tabs, Tab } from "../../../../../components/ui/Tabs";
import I18n from "../../../../../core/localization/I18n";
import { Pagination } from "../../../../../components/widgets/pagination/Pagination_view";
import { Icon, IconSymbols } from "../../../../../components/ui/Icon";
import { useCurrentOrganisation } from "../../../../../services/local/organisationContext/OrganisationContextModel";
import { Async } from "react-async";
import { Animations } from "../../../../../core/util/Animations";
import { UploadFile } from "./uploadFile/UploadFile_view";
import { UploadGrid } from "./uploadGrid/UploadGrid_view";
import { UploadFileList } from "./uploadFileList/UploadFileList_view";
import Pages from "../../../../../routes/InsightRoutes";

export interface StakeholdersProps {
  model?: StakeholdersSettingsModel;
}

const StakeholdersSettings: React.FunctionComponent<StakeholdersProps> = observer(({ model: m }) => {
  const organisation = useCurrentOrganisation();
  const [model, setModel] = useState(() => m || new StakeholdersSettingsModel(organisation.id));

  const load = useCallback(async () => {
    await model.loadStakeholders();
  }, [model]);

  useEffect(() => {
    model.onMount();
    return model.onUnmount;
  }, [model]);

  useEffect(() => {
    setModel(new StakeholdersSettingsModel(organisation.id));
  }, [organisation]);

  return (
    <Async promiseFn={load}>
      <Async.Loading>
        <PositionedSpinner />
      </Async.Loading>
      <Async.Resolved>
        <StakeholdersSettingsContent model={model} organisation={organisation} />
      </Async.Resolved>
    </Async>
  );
});

const StakeholdersSettingsContent: React.FC<any> = observer(({ model, organisation }) => {
  const setSelectFileId = (id: number) => {
    model.selectedFileId = id;
  };

  let indSearchValue = model.filterIndModel.getFilter("FirstName|LastName|Email")?.value;
  return (
    <div className="stakeholder-settings">
      <div className={`container-fluid pt-6 ${Animations.FP_ZOOM_IN} speed-4`}>
        <div className="row mb-4">
          <div className="col-lg-8">
            <h1>
              {organisation.name} | {I18n.t("phrases.manageStakeholders")}
            </h1>
            <p>{I18n.t("phrases.manageStakeholdersDescription")}</p>
          </div>
          <div className="col-lg-4">
            <div className="d-flex justify-content-end">
              <LinkButton
                type={ButtonTypes.OUTLINE_PRIMARY}
                href={`${Pages.organisations.visualisations.heatmap.generateLink(organisation.id, "business-areas")}`}
                className="float-right"
              >
                {I18n.t("phrases.closeSettings")}
              </LinkButton>
            </div>
          </div>
        </div>
      </div>
      <Tabs className="tabs--primary mb-5" ulClassName="tabs__menu--primary" liClassName="tabs__item--primary">
        <Tab
          key="individual-tab-item"
          data-testid="individual"
          title={<h2 className="mb-0">{I18n.t("entities.people")}</h2>}
        >
          <div className={`container-fluid ${Animations.FP_ZOOM_IN} speed-4`}>
            <div className="row mb-4 mt-5">
              <div className="col">
                <Button id="ShowStakeholderFormButton" onClick={() => model.showStakeholderFormModal()}>
                  {I18n.t("phrases.addPerson")}
                </Button>
                <Button
                  className={"float-right ml-2"}
                  type={ButtonTypes.OUTLINE_PRIMARY}
                  onClick={model.exportIndService.showSelectionModal}
                >
                  <Icon symbol={IconSymbols.Download} className="mr-2" />
                  {I18n.t("phrases.export")}
                </Button>
                <SearchButton
                  key="ind-search"
                  id="IndividualStakeholder"
                  className={"float-right"}
                  value={indSearchValue.length > 0 ? indSearchValue[0] : ""}
                  onChange={model.onIndSearchChange}
                  onClose={model.resetIndNameFilter}
                />
              </div>
            </div>
            <div className="row mb-4">
              <div className="col">
                <TableView model={model.tableModel} />
                <Pagination model={model.paginationIndModel} />
              </div>
            </div>
          </div>
        </Tab>
        {/* <Tab
          key="audience"
          data-testid="audience-tab-item"
          title={
            <h2 className="mb-0" data-testid="audience-tab">
              {I18n.t("entities.audiences")}
            </h2>
          }
        >
          <div className={`container-fluid ${Animations.FP_ZOOM_IN} speed-4`}>
            <div className="row mb-4 mt-5">
              <div className="col">
                <Button id="ShowAudienceFormButton" onClick={() => model.showAudienceFormModal()}>
                  {I18n.t("phrases.createAudience")}
                </Button>
                <Button
                  className={"float-right ml-2"}
                  type={ButtonTypes.OUTLINE_PRIMARY}
                  onClick={model.exportAudService.showSelectionModal}
                >
                  <Icon symbol={IconSymbols.Download} className="mr-2" />
                  {I18n.t("phrases.export")}
                </Button>
                <SearchButton
                  key="aud-search"
                  className={"float-right"}
                  id="SearchAudience"
                  value={audSearchValue.length > 0 ? audSearchValue[0] : ""}
                  onChange={model.onSearchAudChange}
                  onClose={model.resetAudNameFilter}
                />
              </div>
            </div>
            <div className="row mb-4">
              <div className="col">
                <TableView model={model.tableAudienceModel} />
                <Pagination model={model.paginationAudModel} />
              </div>
            </div>
          </div>
        </Tab> */}
        <Tab title={<h2 className="mb-0">{I18n.t("phrases.uploadPeople")}</h2>} key={2}>
          <div className="organisation-stakeholders--upload row mr-0 ml-0">
            <div className="col-9 pl-0 pr-0">
              {model.selectedFileId === 0 && <UploadFile organisationId={organisation.id} />}
              {model.selectedFileId > 0 && (
                <UploadGrid organisationId={organisation.id} fileId={model.selectedFileId} key={model.selectedFileId} />
              )}
            </div>
            <div className="col-3 organisation-stakeholders--upload--file-list">
              <UploadFileList
                selectedFileId={model.selectedFileId}
                onClickFn={setSelectFileId}
                organisationId={organisation.id}
              />
            </div>
          </div>
        </Tab>
      </Tabs>
    </div>
  );
});

export { StakeholdersSettings };
