import _ from "lodash";
import React from "react";
import { IconSymbols } from "../../../../../../../components/ui/Icon";
import { AutocompleteOption } from "../../../../../../../components/ui/_forms/Autocomplete/AutocompleteOption";
import { FORM_COL } from "../../../../../../../constants";
import { IMultiSelectorModel } from "../../../../../../../core/forms/controls/multiSelector/IMultiSelectorModel";
import { INIT_MULTISELECTOR } from "../../../../../../../core/forms/controls/multiSelector/MultiSelector_model";
import { generateFormFieldsFromJson } from "../../../../../../../core/forms/helpers/FormFieldMappers";
import { filterOutExistingIds } from "../../../../../../../core/grids/GridHelpers";
import I18n from "../../../../../../../core/localization/I18n";
import { Enums } from "../../../../../../../enums";
import { ProjectProcessesApi } from "../../../../../../../services/api/v2/projectProcesses/ProjectProcesses.api";
import { ImpactGroupsApi } from "../../../../../../../services/api/v2/impactGroups/ImpactGroups.api";

export const getProcessImpactGroupFormFields = (
  projectProcessProvider: ProjectProcessesApi,
  impactGroupsProvider: ImpactGroupsApi,
  organisationId: number,
  projectId: number,
  projectProcessId: number,
  impactGroups: FP.Entities.IImpactGroup[]
) => {
  const removeImpactGroup = async (impactGroupId: number) => {
    const res = await projectProcessProvider.removeImpactGroup(
      organisationId,
      projectId,
      projectProcessId,
      impactGroupId
    );
    if (!res) return;

    return res;
  };

  const addImpactGroup = async (impactGroupId: number) => {
    const res = await projectProcessProvider.addImpactGroup(organisationId, projectId, projectProcessId, impactGroupId);
    if (!res || res.payload) return;

    return res.payload;
  };

  const impactGroup: Partial<IMultiSelectorModel> = {
    ...INIT_MULTISELECTOR,
    key: "impactGroups",
    label: <label htmlFor={"impactGroups"}>{I18n.t("forms.highLevelImpacts")}</label>,
    placeholder: I18n.t("placeholders.searchHighLevelImpact"),
    optionElement: (
      <AutocompleteOption
        key={"e"}
        className={"autocomplete__chip"}
        label={(e: FP.Entities.IImpactGroup) => `${e.refNumber} - ${e.name}`}
      />
    ),
    manageLink: `/organisations/${organisationId}/projects/${projectId}/impactGroups`,
    onItemSelected: ig => addImpactGroup(ig.id),
    onFocus: async function () {
      const self: IMultiSelectorModel = this;
      const res = await impactGroupsProvider.getFiltered(organisationId, projectId, {
        filters: `lifecycleStatus==${Enums.LifecycleStatus.Active}`
      });

      if (res.payload) {
        const existingVals = impactGroups.map(e => e.id);
        let sortedImpactGroups = _.orderBy(res.payload, [impactGroup => impactGroup.name.toLowerCase()]);
        sortedImpactGroups = filterOutExistingIds(existingVals, sortedImpactGroups);
        self.setOptions(sortedImpactGroups);
      }
    },
    componentProps: {
      icon: IconSymbols.Search
    },
    searchAttribute: "name",
    fieldClassName: FORM_COL.FULL_WIDTH,
    extractValue: function () {
      return this.isHidden ? null : this.selectedItems.map(e => e.id);
    },
    value: impactGroups,
    removeItemFn: (ig, idx) => removeImpactGroup(ig.id)
  };

  const fields = [];
  fields.push(impactGroup);

  return generateFormFieldsFromJson(fields);
};
