import React from "react";
import I18n from "../../../../core/localization/I18n";
import { Pill, PillTypes } from "../../../../components/ui/Pill";
import { UiSizes } from "@flightpath/coreui/dist/enums";
import { Panel } from "../../../../components/ui/Panel";
import { Icon, IconSymbols } from "../../../../components/ui/Icon";

export interface IProgrammeLocationsModalContentProps {
  programmeLocations?: FP.Entities.IProgrammeLocation[];
  organisationId: number;
}

export const ProgrammeLocationsModalContent: React.FunctionComponent<IProgrammeLocationsModalContentProps> = (
  props: IProgrammeLocationsModalContentProps
) => {
  // const locationSuffix = (location: FP.Entities.IProgrammeLocation) => {
  //   if (location.isProgrammeLocation && location.isProjectLocation)
  //     return 'from Programme & Project';

  //   if (location.isProgrammeLocation)
  //     return 'from Programme';

  //   if (location.isProjectLocation)
  //     return 'from Project';
  // }

  const getUsageCount = (projects: string): number => {
    if (projects.length === 0) return 0;

    return projects.split("|").length;
  };

  const getPillColour = (projectCount: number): PillTypes => {
    if (projectCount === 0) return PillTypes.LIGHT_GRAY;

    return PillTypes.PRIMARY;
  };

  return (
    <div className="programme-locations">
      {props.programmeLocations && props.programmeLocations.length > 0 ? (
        props.programmeLocations.map((x, i) => (
          <Panel.Panel
            type={Panel.PanelTypes.BORDER_LEFT_PRIMARY}
            background={Panel.PanelBackgrounds.BG_WHITE}
            hasShadow={true}
            className="p-2 mb-2"
          >
            <div className="programme-locations--programme-location mr-2 mb-1">
              <span className="mb-1 d-flex align-items-center">
                <Icon symbol={IconSymbols.Location} size={UiSizes.SM} className="mr-1" />
                <h5 className="mb-0">{x.name}</h5>
              </span>

              {x.projects && x.projects.length > 0 && (
                <ul className="programme-locations--programme-location--project-list">
                  {x.projects.split("|").map((y, i) => (
                    <li className="programme-locations--programme-location--project-list--item" key={i}>
                      <a href={`/organisations/${props.organisationId}/projects/${y.split("::")[0]}/`}>
                        <Pill type={getPillColour(getUsageCount(x.projects))} size={UiSizes.XS} key={i}>
                          {y.split("::")[1]}
                        </Pill>
                      </a>
                    </li>
                  ))}
                </ul>
              )}

              {x.projects.length === 0 && (
                <span className="programme-locations--programme-location--no-projects">
                  {I18n.t("phrases.programmeLocation_OnlyAssignedToProgramme")}
                </span>
              )}
            </div>
          </Panel.Panel>
        ))
      ) : (
        <span>{I18n.t("phrases.na")}</span>
      )}
    </div>
  );
};
