import I18n from "../../../../../../../localization/I18n";
import { GridSideModalTitle } from "../../../../utils/GridModalUtils";
import { ActionNotesSidebar } from "./actionNotesSidebar";

export const ACTION_NOTES_SIDEBAR_MODAL_CONFIG = (
  organisationId: number,
  projectId: number,
  action: FP.Entities.IAction
) => {
  return {
    showClose: true,
    title: <GridSideModalTitle name={action.name} field={I18n.t("phrases.tags")} />,
    content: <ActionNotesSidebar projectId={projectId} actionId={action.id} organisationId={organisationId} />
  };
};
