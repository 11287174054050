import { minimumDate } from "../../../../../../../enums";
import { ISdColDef, ISdFilterDef } from "../../baseColumn/BaseColumnTypes";
import { BASE_COLUMN_CONFIG, BASE_FILTER_CONFIG } from "../../baseColumn/BaseColumn_config";
import moment from "moment";
import { defaultDateTimeString } from "../../../../../../../enums";

export const DATE_COLUMN_CONFIG = (columnOptions?: ISdColDef<any, any>): ISdColDef<any, any> => ({
  ...BASE_COLUMN_CONFIG,
  field: "startDate",
  headerName: "header Name",
  editable: true,
  cellDataType: "date",
  ...columnOptions
});

export const DATE_FILTER_CONFIG: ISdFilterDef = {
  ...BASE_FILTER_CONFIG,
  filter: "agDateColumnFilter",
  filterValueGetter: params => {
    if (params.data[params.column.getColId()] < minimumDate) {
      return "";
    }

    return params.data[params.column.getColId()];
  },
  filterParams: {
    inRangeInclusive: true,
    comparator: (filterLocalDateAtMidnight, cellValue) => {
      var dateAsString = moment(cellValue).format("DD/MM/YYYY");
      var dateParts = dateAsString.split("/");
      var cellDate = new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0]));

      if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
        return 0;
      }

      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      }

      if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
    },
    buttons: ["clear"]
  }
};
