import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { PositionedSpinner } from "../../../../components/ui/PositionedSpinner";
import { useCurrentOrganisationId } from "../../../../services/local/organisationContext/OrganisationContextModel";
import { ProjectViewModel } from "./ProjectView_model";
import { ProjectMainPanel } from "./panels/ProjectMainPanel";

export interface IProjectView {
  model?: ProjectViewModel;
}

export const ProjectView: React.FunctionComponent<IProjectView> = observer(({ model: m }) => {
  const organisationId = useCurrentOrganisationId();
  const { projectId } = useParams<{ projectId: string; organisationId: string }>();
  const [model] = useState(() => m || new ProjectViewModel(+projectId, organisationId));

  useEffect(() => {
    model.onMount();
    return () => {
      (async () => await model.onUnmount())();
    };
  }, [model]);

  useEffect(() => {
    if (model.id !== +projectId || organisationId !== model.organisationId) {
      model.setProjectId(+projectId);
      model.setOrganisationId(organisationId);
      const remount = async () =>
        await model.onUnmount().then(async () => {
          model.setIsLoading(true);
          model.load();
          model.onMount();
        });

      remount();
    }
  }, [model, projectId, organisationId]);

  if (model.isLoading) {
    return <PositionedSpinner />;
  }
  return <ProjectMainPanel parentModel={model} />;
});
