import { BaseModel } from "../../../../../core/util/BaseModel";
import { observable, action, makeObservable } from "mobx";
import { IFilterModel, FilterOperator } from "../../../../../core/filter/Filter_model";
import { IPaginationModel } from "../../../../../components/widgets/pagination/Pagination_model";
import I18n from "../../../../../core/localization/I18n";

export class UserStakeholdersModel extends BaseModel {
  @observable isLoading: boolean = true;
  filterModel: IFilterModel<FP.Entities.IProjectStakeholder[]>;
  paginationModel: IPaginationModel;
  @observable.ref stakeholders: FP.Entities.IProjectStakeholder[][] = [];
  navigate: any;
  constructor(
    navigate: any,
    filterModel: IFilterModel<FP.Entities.IProjectStakeholder[]>,
    paginationModel: IPaginationModel
  ) {
    super();
    makeObservable(this);
    this.navigate = navigate;
    this.filterModel = filterModel;
    this.filterModel.addFilter({
      key: "name",
      label: I18n.t("filters.name"),
      value: [],
      operator: FilterOperator.CONTAINS
    });

    this.filterModel.addSort({ key: "firstName", isAsc: true });
    this.filterModel.addSort({ key: "lastName", isAsc: true });
    this.paginationModel = paginationModel;
    paginationModel.setConfig({ onPageClick: this.loadPageData });
  }

  onMount = () => {
    this.loadData();
  };

  onUnmount = () => {};

  loadData = async () => {
    await this.filterModel.loadData();
    this.setStakeholders(this.filterModel.data);
  };

  loadPageData = async (index: number) => {
    this.filterModel.setConfig({ page: index });
    await this.loadData();
  };

  onSearchChange = (ev: React.SyntheticEvent) => {
    let e = ev.currentTarget as HTMLInputElement;
    this.filterModel.setFilterValue("name", e.value);
  };

  @action
  resetNameFilter = () => {
    this.filterModel.setFilterValue("name", "");
  };

  @action
  setStakeholders = (stakeholders: FP.Entities.IProjectStakeholder[][]) => {
    this.stakeholders = stakeholders.slice();
    this.isLoading = false;
  };
}
