import { GridApi, GridReadyEvent } from "ag-grid-community";
import { action, makeObservable, observable } from "mobx";
import { BaseModel } from "../../../../../core/util/BaseModel";
import { Enums, StakeholderType } from "../../../../../enums";
import ProgrammesApi, {
  ProgrammesApi as IProgrammesApi
} from "../../../../../services/api/v2/programmes/Programmes.api";

export class ProgrammeStakeholderCapacityModel extends BaseModel {
  @observable organisationId: number;
  programmeProvider: IProgrammesApi;
  isComponentMounted: boolean = false;
  @observable connectedUsers: any[] = [];
  @observable isLoading: boolean = true;
  authUser: FP.Entities.IUser;
  @observable stakeholderAssignmentMetrics: FP.Entities.IStakeholderAssignmentMetric[];
  @observable stakeholderType: number;
  gridApi: GridApi;
  @observable searchText: string;
  @observable programmeId: number;

  constructor(
    organisationId: number,
    authUser: FP.Entities.IUser,
    stakeholderType: StakeholderType,
    programmeId: number
  ) {
    super();
    makeObservable(this);
    this.organisationId = organisationId;
    this.programmeProvider = ProgrammesApi;
    this.authUser = authUser;
    this.stakeholderType = stakeholderType;
    this.programmeId = programmeId;
  }

  onMount = async () => {
    this.setIsLoading(true);
    await this.loadData();
  };

  onUnmount = () => {};

  @action
  setStakeholderType = (stakeholderType: StakeholderType) => {
    this.stakeholderType = stakeholderType;
  };

  @action
  loadData = async () => {
    this.isComponentMounted = false;
    if (this.stakeholderType === Enums.StakeholderType.INDIVIDUAL) {
      await this.programmeProvider
        .getStakeholderCapacityChartData(this.organisationId, this.programmeId, this.stakeholderType)
        .then(res => {
          this.setStakeholderAssignmentMetricsData(res.payload);
        });
    }

    if (this.stakeholderType === Enums.StakeholderType.AUDIENCE) {
      await this.programmeProvider.getAudienceCapacityChartData(this.organisationId, this.programmeId).then(res => {
        this.setStakeholderAssignmentMetricsData(res.payload);
      });
    }

    this.isComponentMounted = true;
  };

  @action
  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };

  @action
  setStakeholderAssignmentMetricsData = data => {
    this.stakeholderAssignmentMetrics = data;
    this.setIsLoading(false);
  };

  isRowSelectable = () => {
    return false;
  };

  @action
  onGridReady = (gridReadyEvent: GridReadyEvent): void => {
    this.gridApi = gridReadyEvent.api;
  };

  @action
  setSearchText = (ev: React.FormEvent<HTMLInputElement>) => {
    this.searchText = ev.currentTarget.value;

    if (this.gridApi !== undefined) {
      this.gridApi.setGridOption("quickFilterText", this.searchText);
    }
  };
}
