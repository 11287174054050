import I18n from "../../../../../../../localization/I18n";
import { GridSideModalTitle } from "../../../../utils/GridModalUtils";
import { ProjectStakeholderImpactsSidebarModel } from "./ProjectStakeholderImpactsSidebar_model";
import { ProjectStakeholderImpactsSidebar } from "./ProjectStakeholderImpactsSidebar_view";

export const PROJECT_STAKEHOLDER_IMPACTS_SIDEBAR_MODAL_CONFIG = (
  organisationId: number,
  projectId: number,
  projectStakeholder: FP.Entities.IProjectStakeholderSummary
) => {
  return {
    showClose: true,
    title: <GridSideModalTitle name={projectStakeholder.name} field={I18n.t("phrases.associatedDetailedImpacts")} />,
    content: (
      <ProjectStakeholderImpactsSidebar
        model={new ProjectStakeholderImpactsSidebarModel(projectStakeholder.id, organisationId, projectId)}
      />
    )
  };
};
