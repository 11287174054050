import { Panel } from "@flightpath/coreui";
import I18n from "../../../../localization/I18n";

export const CustomGridToolTip = ({ value }) => {
  let content;

  if (value === "impact" || value === "influence") {
    content = (
      <>
        <h5>High</h5>
        <p>{I18n.t(`tooltips.${value}LevelHigh`)}</p>
        <hr />
        <h5>Medium</h5>
        <p>{I18n.t(`tooltips.${value}LevelMed`)}</p>
        <hr />
        <h5>Low</h5>
        <p>{I18n.t(`tooltips.${value}LevelLow`)}</p>
      </>
    );
  }
  if (value === "receptiveness" || value === "sentiment" || value === "commitment") {
    content = (
      <>
        <h5>{I18n.t(`tooltips.${value}DefinitionHeader`)}</h5>
        <p>{I18n.t(`tooltips.${value}Definition`)}</p>
        <hr />
        <h5>{I18n.t(`tooltips.HowAssessedHeader`)}</h5>
        <p>{I18n.t(`tooltips.${value}HowAssessed`)}</p>
        <hr />
        <h5>{I18n.t(`tooltips.${value}RemediationActivitiesHeader`)}</h5>
        <p>{I18n.t(`tooltips.${value}RemediationActivities`)}</p>
      </>
    );
  }
  return (
    <>
      {content && (
        <Panel.Panel
          background={Panel.PanelBackgrounds.BG_LIGHT}
          hasShadow
          hasBorderRadius
          className="p-2"
          style={{ width: "400px" }}
        >
          {content}
        </Panel.Panel>
      )}
    </>
  );
};
