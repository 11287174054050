import { IFilteredOptions } from "../filteredApi/FilteredApiModel";

import { IFlightPathApiResponse } from "../BaseApiModel";
import { AxiosInstance, AxiosRequestConfig } from "axios";
import { ExportableApi } from "../exportableApi/ExportableApiModel";
import { IRecentProjectType } from "../../../local/recentProjectsService/IRecentProjectsService";
import http from "../Http";

export class UsersApi extends ExportableApi<FP.Entities.IUser> {
  readonly controller: string = "users";

  constructor(http: AxiosInstance) {
    super(http, "users");
    this.url = "/api/v1/";
  }

  getByIdAndOrgaisationIdAsync = async (organisationId: number, id: string, config?: AxiosRequestConfig) => {
    let res = await this.http.get(`${this.url}organisations/${organisationId}/users/${id}`, config);
    return res.data;
  };

  updateWithOrganisationIdAsync = async (
    organisationId: number,
    id: string,
    data: any,
    config?: AxiosRequestConfig
  ) => {
    let res = await this.http.put(`${this.url}organisations/${organisationId}/users/${id}`, data, config);
    return res.data;
  };

  getSelf = async (
    organisationId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IUser>> => {
    let res = await this.http.get(`${this.url}organisations/${organisationId}/users/me`, config);
    return res.data;
  };

  getOrgs = async (
    organisationId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IOrganisation[]>> => {
    let res = await this.http.get(
      `${this.url}organisations/${organisationId}/users/me/associated-organisations`,
      config
    );
    return res.data;
  };

  getProjects = async (
    organisationId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IProject[]>> => {
    let res = await this.http.get(`${this.url}organisations/${organisationId}/users/me/projects`, config);
    return res.data;
  };

  getProjectsWithImpacts = async (organisationId: number, userId: number, config?: AxiosRequestConfig) => {
    const url = `${this.url}organisations/${organisationId}/users/${userId}/assigned-projects-with-impacts`;
    let res = await this.http.get(url, config);
    return res.data;
  };
  getProjectsWithActions = async (organisationId: number, userId: number, config?: AxiosRequestConfig) => {
    const url = `${this.url}organisations/${organisationId}/users/${userId}/assigned-projects-with-actions`;
    let res = await this.http.get(url, config);
    return res.data;
  };

  getProjectsWithActionsByProgressStatusAndUserId = async (
    organisationId: number,
    userId: number,
    progressStatus: number,
    config?: AxiosRequestConfig
  ) => {
    const url = `${this.url}organisations/${organisationId}/users/${userId}/assigned-projects-with-actions-by-progress-status/${progressStatus}`;
    let res = await this.http.get(url, config);
    return res.data;
  };

  getProjectsWithActionsByRagStatusAndUserId = async (
    organisationId: number,
    userId: number,
    ragStatus: number,
    config?: AxiosRequestConfig
  ) => {
    const url = `${this.url}organisations/${organisationId}/users/${userId}/assigned-projects-with-actions-by-rag-status/${ragStatus}`;
    let res = await this.http.get(url, config);
    return res.data;
  };

  getProjectsWithImpactsByProgressStatusAndUserId = async (
    organisationId: number,
    userId: number,
    progressStatus: number,
    config?: AxiosRequestConfig
  ) => {
    const url = `${this.url}organisations/${organisationId}/users/${userId}/assigned-projects-with-impacts-by-progress-status/${progressStatus}`;
    let res = await this.http.get(url, config);
    return res.data;
  };

  getProjectsWithImpactsByLevelAndUserId = async (
    organisationId: number,
    userId: number,
    impactLevel: number,
    config?: AxiosRequestConfig
  ) => {
    const url = `${this.url}organisations/${organisationId}/users/${userId}/assigned-projects-with-impacts-by-level/${impactLevel}`;
    let res = await this.http.get(url, config);
    return res.data;
  };

  getProjectsWithStakeholders = async (organisationId: number, userId: number, config?: AxiosRequestConfig) => {
    const url = `${this.url}organisations/${organisationId}/users/${userId}/assigned-projects-with-stakeholders`;
    let res = await this.http.get(url, config);
    return res.data;
  };
  getProjectsWithAudiences = async (organisationId: number, userId: number, config?: AxiosRequestConfig) => {
    const url = `${this.url}organisations/${organisationId}/users/${userId}/assigned-projects-with-audiences`;
    let res = await this.http.get(url, config);
    return res.data;
  };
  getTeamProjectsByUserId = async (organisationId: number, userId: number, config?: AxiosRequestConfig) => {
    const url = `${this.url}organisations/${organisationId}/users/${userId}/team-projects`;
    let res = await this.http.get(url, config);
    return res.data;
  };

  getNotifications = async (
    organisationId: number,
    filteredOptions: Partial<IFilteredOptions>,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.INotification[]>> => {
    let query = this.getRequestQuery(filteredOptions);
    let res = await this.http.get(`${this.url}organisations/${organisationId}/users/me/notifications` + query, config);
    return res.data;
  };

  setPassword = async (
    organisationId: number,
    oldPassword: string,
    newPassword: string,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<boolean>> => {
    var data = {
      oldPassword,
      newPassword
    };
    let res = await this.http.post(`${this.url}organisations/${organisationId}/users/changePassword`, data, config);
    return res.data;
  };

  addRecentProject = async (organisationId: number, projectId: number): Promise<IFlightPathApiResponse<boolean>> => {
    let res = await this.http.post(`${this.url}organisations/${organisationId}/users/me/recent-projects`, {
      projectId
    });
    return res.data;
  };

  getRecentProjects = async (organisationId: number): Promise<IFlightPathApiResponse<IRecentProjectType[]>> => {
    let res = await this.http.get(`${this.url}organisations/${organisationId}/users/me/recent-projects`);
    return res.data;
  };

  getUserByEmail = async (
    organisationId: number,
    formData: Dictionary<any>,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<any>> => {
    let res = await this.http.post(
      `${this.url}organisations/${organisationId}/users/search-by-email`,
      formData,
      config
    );
    return res.data;
  };

  getOrganisationUserPermissions = async (
    organisationId: number,
    oktaUserId: string,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<string>> => {
    const url = `${this.url}organisations/${organisationId}/users/${oktaUserId}/organisation-user-permissions/`;
    let res = await this.http.get(url, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<string>;
    }
    return res.data;
  };
}

const instance = new UsersApi(http);
export default instance;
