import I18n from "../../core/localization/I18n";
import { FirebaseService } from "../../services/external/firebase/FirebaseService";

export class ChangeApp {
  private _called: boolean = false;

  install = async () => {
    if (this._called) {
      return;
    }
    this._called = true;
    await this.installLocale();
  };

  installLocale = async () => {
    let firebaseService = new FirebaseService("en-gb");
    I18n.setLocale("en-gb");
    let res = await firebaseService.load();
    I18n.setData(res);
  };
}
