import _ from "lodash";
import React, { useMemo } from "react";
import { PositionedSpinner } from "../../../../../../../components/ui/PositionedSpinner";
import I18n from "../../../../../../../core/localization/I18n";
import { ReportingPie } from "../../../../Actions/ActionReportingView/TopActionsReportingBar/ReportingPie";
import {
  getConfidenceMetric,
  getConfidenceMetricColour
} from "../../../../Actions/ActionReportingView/TopActionsReportingBar/TopActionsReportingBar_view";

interface StakeholderGapAnalysisProps {
  isLoading: boolean;
  data: FP.Entities.IProjectStakeholderSummary[];
  profilingType: "0" | "commitment" | "sentiment" | "receptiveness";
}

export const StakeholderGapAnalysis: React.FC<StakeholderGapAnalysisProps> = ({ data, isLoading, profilingType }) => {
  const included = useMemo(
    () =>
      data.filter(e => {
        return (profilingType === "0" || e[profilingType] > 0) && e.influence > 0 && e.impact > 0;
      }).length,
    [data, profilingType]
  );
  const notIncluded = useMemo(
    () =>
      data.filter(e => (profilingType === "0" && e[profilingType] <= 0) || e.influence <= 0 || e.impact <= 0).length,
    [data, profilingType]
  );

  const pieData = [
    {
      label: I18n.t("visualisations.included"),
      value: included,
      id: "included"
    },
    {
      label: I18n.t("visualisations.notIncluded"),
      value: notIncluded,
      id: "notIncluded"
    }
  ];

  if (isLoading) {
    return <PositionedSpinner />;
  }

  const min = pieData[0].value;
  const sum = _.sumBy(pieData, e => e.value);
  let percent = Math.round((min / sum) * 100);
  percent = isNaN(percent) ? 0 : percent;
  const colors = [getConfidenceMetricColour(percent), "#DEDEDE"];

  return (
    <div className="stakeholder-gap">
      <ReportingPie
        data={pieData}
        metric={getConfidenceMetric(percent)}
        colourArray={colors}
        title={I18n.t("visualisations.StakeholderGapAnalysiss")}
      />
    </div>
  );
};

// 12 / 1
