import { ISdColDef } from "../../baseColumn/BaseColumnTypes";
import { BaseColumnBuilder } from "../../baseColumn/BaseColumn_builder";
import { ICellRendererParams, ValueGetterParams, ValueSetterParams } from "ag-grid-community";
import { AUTOCOMPLETE_COLUMN_CONFIG } from "./AutocompleteColumn_config";
import AgGridAutocomplete from "../../../../../editors/AgGridAutocomplete";

interface IAutocompleteColumnBuilder {}

export class AutocompleteColumnBuilder extends BaseColumnBuilder implements IAutocompleteColumnBuilder {
  constructor(columnOptions?: ISdColDef<any, any>) {
    super({ ...AUTOCOMPLETE_COLUMN_CONFIG(), ...columnOptions });
  }

  withCellEditor = () => {
    return this.setColumnOptions({
      cellEditorSelector: () => ({ component: AgGridAutocomplete, popup: true, popupPosition: "over" }),
      cellEditorPopup: true
    });
  };

  setValueSetter = (callback: (params: ValueSetterParams) => any) => {
    return this.setColumnOptions({
      valueSetter: callback
    });
  };
  setValueGetter = (callback: (params: ValueGetterParams) => any) => {
    return this.setColumnOptions({
      valueGetter: callback
    });
  };

  withCellRenderer = (callback: (params: ICellRendererParams) => any) => {
    return this.setColumnOptions({ cellRenderer: callback });
  };
}
