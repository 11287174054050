import { action, makeObservable, observable } from "mobx";
import StakeholderUploadFileHub, {
  StakeholderUploadFileHub as IStakeholderUploadFileHub
} from "../../../../../../services/hubs/StakeholderUploadFileHub/StakeholderUploadFile_hub";

export class UploadFileListModel {
  organisationId: number;
  @observable stakeholderUploadFiles: FP.Entities.IStakeholderUploadFile[];
  @observable isLoading: boolean = true;
  stakeholderUploadFileHub: IStakeholderUploadFileHub;
  @observable connectedUsers: any[] = [];
  @observable selectedFileId: number = 0;
  authUser: FP.Entities.IUser;
  @observable isComponentMounted: boolean = false;

  constructor(organisationId: number, authUser: FP.Entities.IUser) {
    makeObservable(this);
    this.organisationId = organisationId;
    this.stakeholderUploadFileHub = StakeholderUploadFileHub;
    this.authUser = authUser;
  }

  onMount = async () => {
    await this.registerSocketEvents();
  };

  onUnmount = () => {
    this.stopConnection();
  };

  registerSocketEvents = async () => {
    if (this.stakeholderUploadFileHub.isConnectionStarted === true) {
      await this.stakeholderUploadFileHub.stopConnection();
    }

    await this.stakeholderUploadFileHub.startConnection();

    this.stakeholderUploadFileHub.onUserJoined(d => {
      this.setConnectedUsers(d);
    });

    this.stakeholderUploadFileHub.onLoadData(d => {
      this.setStakeholderUploadFileData(d);
    });

    this.stakeholderUploadFileHub.onUserCellSelected(d => {
      this.setConnectedUsers(d);
    });

    await this.stakeholderUploadFileHub.invokeUserJoined(this.organisationId, 0, this.authUser.sub);
    await this.stakeholderUploadFileHub.invokeLoadData(this.organisationId);
    this.isComponentMounted = true;
  };

  stopConnection = async () => {
    await this.stakeholderUploadFileHub.stopConnection();
  };

  @action
  setConnectedUsers = users => {
    this.connectedUsers = [...users];
  };

  @action
  setStakeholderUploadFileData = data => {
    this.stakeholderUploadFiles = data;
    this.isLoading = false;
  };
}
