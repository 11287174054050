import { action, observable } from "mobx";
import { GetGridFns, IGridFns } from "../../../../../../../core/grids/GridFunctions";
import { IGridModel } from "../../../../../../../core/grids/IGridModel";
import { GetChangeTeamCapacityGridViewColumns } from "./ChangeTeamCapacity_Grid_Columns";

export class ChangeTeamCapacityGrid_GridModel implements IGridModel {
  @observable isLoading: boolean = true;
  organisationId: number;
  projectId: number;
  gridFns: IGridFns;

  /**
   *
   */
  constructor(organisationId: number) {
    this.organisationId = organisationId;
    this.gridFns = GetGridFns(null, this.organisationId, 0);
  }

  getColumnConfig = () => {
    return GetChangeTeamCapacityGridViewColumns();
  };

  @action
  onMount = () => {
    this.isLoading = false;
  };

  onUnmount = () => {};
}
