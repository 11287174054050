import { ColDef } from "ag-grid-community";
import {
  colFirstName,
  colLastName,
  colImpactCount,
  colActionCount,
  colStakeholderCount,
  colProjectCount,
} from "./ChangeTeamCapacity_Grid_Config";

export const GetChangeTeamCapacityGridViewColumns = (): ColDef[] => {
  let result: ColDef[] = [];

  result.push(colFirstName());
  result.push(colLastName());
  result.push(colImpactCount());
  result.push(colActionCount());
  result.push(colStakeholderCount());
  result.push(colProjectCount());

  return result;
};