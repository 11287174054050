import React from "react";
import I18n from "../../../../../../core/localization/I18n";
import { GroupUsersPermissionsModel } from "./GroupUsersPermissions_model";
import { Enums } from "../../../../../../enums";
import { GroupUsersPermissionsToggle } from "./GroupUserPermissionsToggle";

export const GetGroupUserPermissionsTableConfig = (parentModel: GroupUsersPermissionsModel, projectId: number) => {
  let tableConfig = {
    actions: [],
    colHeaders: [
      {
        id: "permissionLevel",
        content: <h5 className="mb-0">{I18n.t("table.permissionScope")}</h5>,
        selector: obj => {
          return <p className="mb-0">{obj.permissionLabel}</p>;
        },
        showSortIcons: false,
        productTourText: I18n.t("tours.permissionsGroupPermissionLevel")
      },
      {
        id: "permissionActivities",
        content: <h5 className="mb-0">{I18n.t("table.permissionActivities")}</h5>,
        selector: obj => {
          return <p className="mb-0">{obj.activities}</p>;
        },
        showSortIcons: false
      },
      {
        id: "project",
        content: <h5 className="mb-0">{I18n.t("table.project")}</h5>,
        selector: obj => {
          return (
            <GroupUsersPermissionsToggle
              disabled={obj.permissionLevel === Enums.PermissionScope.OWNER ? true : false}
              isChecked={obj.hasProjectAccess}
              onChange={isChecked => {
                let item = { ...obj };
                item.hasProjectAccess = isChecked;
                parentModel.updatePermissions(item);
              }}
            />
          );
        },
        showSortIcons: false,
        productTourText: I18n.t("tours.permissionsGroupProjectPermissions")
      },
      {
        id: "stakeholders",
        content: <h5 className="mb-0">{I18n.t("table.pluralStakeholders")}</h5>,
        selector: obj => {
          return (
            <GroupUsersPermissionsToggle
              disabled={obj.permissionLevel === Enums.PermissionScope.OWNER ? true : false}
              isChecked={obj.hasStakeholderAccess}
              onChange={isChecked => {
                let item = { ...obj };
                item.hasStakeholderAccess = isChecked;
                parentModel.updatePermissions(item);
              }}
            />
          );
        },
        showSortIcons: false,
        productTourText: I18n.t("tours.permissionsGroupStakeholderPermissions")
      },
      {
        id: "impacts",
        content: <h5 className="mb-0">{I18n.t("table.pluralImpacts")}</h5>,
        selector: obj => {
          return (
            <GroupUsersPermissionsToggle
              disabled={obj.permissionLevel === Enums.PermissionScope.OWNER ? true : false}
              isChecked={obj.hasImpactAccess}
              onChange={isChecked => {
                let item = { ...obj };
                item.hasImpactAccess = isChecked;
                parentModel.updatePermissions(item);
              }}
            />
          );
        },
        showSortIcons: false,
        productTourText: I18n.t("tours.permissionsGroupImpactPermissions")
      },
      {
        id: "mitigations",
        content: <h5 className="mb-0">{I18n.t("table.actions")}</h5>,
        selector: obj => {
          return (
            <GroupUsersPermissionsToggle
              disabled={obj.permissionLevel === Enums.PermissionScope.OWNER ? true : false}
              isChecked={obj.hasActionAccess}
              onChange={isChecked => {
                let item = { ...obj };
                item.hasActionAccess = isChecked;
                parentModel.updatePermissions(item);
              }}
            />
          );
        },
        showSortIcons: false,
        productTourText: I18n.t("tours.permissionsGroupActionPermissions")
      }
    ] as any[],
    initData: null
  };
  return tableConfig;
};
