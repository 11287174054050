import _ from "lodash";
import { action, makeObservable, observable } from "mobx";
import moment from "moment";
import { Tooltip } from "react-tippy";

import {
  ITimelineGroup,
  ITimelineItem,
  ITimelineMarker,
  TimeLineModel
} from "../../../../../components/widgets/TimelineWidget/Timeline_model";
import I18n from "../../../../../core/localization/I18n";
import ProgrammesApi, {
  ProgrammesApi as IProgrammesApi
} from "../../../../../services/api/v2/programmes/Programmes.api";
import ProjectsApi, { ProjectsApi as IProjectsApi } from "../../../../../services/api/v2/projects/Projects.api";
import { TimelineItem } from "../../organisationDashboard/dashboardContentComponent/DashboardTimeline/components/TimelineItem";
import { Enums } from "../../../../../enums";

export class DashboardTimelineModel {
  timelineModel: TimeLineModel;
  organisationId: number;
  projectsProvider: IProjectsApi;
  programmesProvider: IProgrammesApi;
  @observable.ref projects = [];
  @observable.ref timelineData = [];

  /**
   *
   */
  constructor(organisationId: number) {
    makeObservable(this);
    this.organisationId = organisationId;
    this.projectsProvider = ProjectsApi;
    this.programmesProvider = ProgrammesApi;
    this.timelineModel = new TimeLineModel();
  }

  onMount = async () => {
    await this.loadOrganisation();
  };

  loadOrganisation = async () => {
    const result = await this.projectsProvider.getFiltered(this.organisationId, { page: 1, pageSize: 1000000 });
    const programmesResult = await this.programmesProvider.getFiltered(this.organisationId, {
      page: 1,
      pageSize: 1000000
    });

    if (!result || result.isError) return;
    this.setTimelineData([...programmesResult.payload, ...result.payload]);
  };

  @action
  setTimelineData = (timelineData: FP.Entities.IProgramme[] | FP.Entities.IProject[]) => {
    this.timelineData = timelineData;
    this.setTimelineDefaultDates(this.timelineData);
    this.setTimeLineItems(this.timelineData);
  };

  setTimelineDefaultDates = (timelineData: FP.Entities.IProgramme[] | FP.Entities.IProject[]) => {
    const proj = timelineData.map(e => {
      return {
        ...e,
        mStartDate: moment(e.startDate),
        mEndDate: moment(e.actualEndDate)
      };
    });
    const minDate = _.minBy(proj, e => e.mStartDate);
    const maxDate = _.maxBy(proj, e => e.mEndDate);
    const startDate = minDate.mStartDate;
    const endDate = maxDate.mEndDate;
    this.timelineModel.setDefaultDates(startDate, endDate);
    this.timelineModel.setVisibleDates(startDate.subtract(10, "days"), endDate.add(10, "days"));
  };

  setTimeLineItems = (timelineData: FP.Entities.IProgramme[] | FP.Entities.IProject[]) => {
    const items: ITimelineItem[] = timelineData.map(timelineItem => {
      const id =
        !timelineItem.programme && !timelineItem.projects
          ? `-1_${timelineItem.id}`
          : timelineItem.programme
          ? `${timelineItem.programme.id}_${timelineItem.id}`
          : `${timelineItem.id}`;
      return {
        id,
        group: id,
        title: timelineItem.name,
        start_time: moment(timelineItem.startDate),
        end_time: moment(timelineItem.actualEndDate),
        data: timelineItem,
        isParent: id.toString().split("_").length === 1
      };
    });

    let groups: ITimelineGroup[] = timelineData.map(timelineItem => {
      const id =
        !timelineItem.programme && !timelineItem.projects
          ? `-1_${timelineItem.id}`
          : timelineItem.programme
          ? `${timelineItem.programme.id}_${timelineItem.id}`
          : `${timelineItem.id}`;

      return {
        id,
        start_time_v: moment(timelineItem.startDate).valueOf(),
        isParent: id.toString().split("_").length === 1,
        title:
          timelineItem.name.length > 16 ? (
            <Tooltip theme="light" followCursor html={<small className="d-block">{timelineItem.name}</small>}>
              {timelineItem.projects ? (
                <div style={{ paddingLeft: "25px" }}>
                  <strong>{timelineItem.name}</strong>
                </div>
              ) : (
                <div style={{ paddingLeft: "35px" }}>{timelineItem.name}</div>
              )}
            </Tooltip>
          ) : timelineItem.projects ? (
            <div style={{ paddingLeft: "25px" }}>
              <strong>{timelineItem.name}</strong>
            </div>
          ) : (
            <div style={{ paddingLeft: "35px" }}>{timelineItem.name}</div>
          )
      };
    });

    groups.push({
      id: -1,
      isParent: true,
      title: (
        <div style={{ paddingLeft: "25px" }}>
          <strong>{I18n.get("phrases.standaloneProjects")}</strong>
        </div>
      )
    } as any);

    groups = _.sortBy(groups, (e: any) => [e.id, e.start_time_v]);
    const sortedItems = _.sortBy(items, (e: any) => [e.group, e.start_time]);

    const markers: ITimelineMarker[] = sortedItems
      .map((project, index) => {
        return project.data.milestones.map(milestone => {
          const cs = milestone.milestoneType
            ? `timeline-marker__icon--${Enums.Translator.MilestoneTypeKey(milestone.milestoneType)}`
            : "";
          return {
            id: milestone.id,
            date: moment(milestone.deadline),
            project: (index + 1) * 40 + 10,
            typeClass: cs,
            title: milestone.name,
            description: milestone.description
          };
        });
      })
      .flat();

    this.timelineModel.setItems(items);
    this.timelineModel.setGroups(groups);
    this.timelineModel.setSidebarWidth(350);
    this.timelineModel.setSideheaderTitle(
      <strong className="pl-3 pb-1">{I18n.t("phrases.programmesProjects")}</strong>
    );

    this.timelineModel.setMarkers(markers);
    // this.timelineModel.onMarkerSelect = this.handleTimelineMarkerSelect;

    this.timelineModel.itemRenderer = TimelineItem(this.organisationId);
    this.timelineModel.groupRenderer = ({ group }) => {
      const className = !group.isParent ? "" : "highlighted";
      return <div className={className}>{group.title}</div>;
    };
    this.timelineModel.isLoading = false;
  };
}
