import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { InsightBoot } from "./apps/insight/InsightBoot";
import reportWebVitals from "./reportWebVitals";
import "./sass/change.scss";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

const baseUser = appConfig.appEnvironment === "local" ? "/" : "/insight";
root.render(
  <Router basename={baseUser}>
    <InsightBoot />
  </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
