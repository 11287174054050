import { makeObservable, observable } from "mobx";
import { ButtonTypes } from "../../../../../components/ui/Button";
import PermissionsContext from "../../../../../contexts/permissions/PermissionsContext";
import ProgressIndicatorModel, {
  ProgressIndicatorModel as IProgressIndicatorModel
} from "../../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import I18n from "../../../../../core/localization/I18n";
import { UiActionRenderers } from "../../../../../core/uiAction/IUiAction";
import { BaseModel } from "../../../../../core/util/BaseModel";
import OrganisationsApi, {
  OrganisationsApi as IOrganisationsApi
} from "../../../../../services/api/v2/organisations/Organisations.api";
import UsersApi, { UsersApi as IUsersApi } from "../../../../../services/api/v2/users/Users.api";
import { getOrganisationFormFields } from "../../../forms/organisation/OrganisationFormSection_data";
import { SingleFormModel } from "../../../forms/singleFormModel/SingleForm_model";
import { IModalContextModel } from "../../../../../core/modalZ/context/IModalContext";
import ModalContext from "../../../../../core/modalZ/context/ModalContext";

export class OrganisationSettingsModel extends BaseModel {
  orgProvider: IOrganisationsApi;
  formModel: SingleFormModel;
  organisationId: number;
  httpProgress: IProgressIndicatorModel;
  @observable isLoading: boolean = true;
  @observable.ref organisation: FP.Entities.IOrganisation;
  modalService: IModalContextModel;
  usersProvider: IUsersApi;
  refreshClaims: () => void;
  navigate: any;

  constructor(
    navigate: any,
    refreshClaims: () => void,
    organisationId: number,
    organisation: FP.Entities.IOrganisation
  ) {
    super();
    makeObservable(this);
    this.navigate = navigate;
    this.organisationId = organisationId;
    this.refreshClaims = refreshClaims;
    this.orgProvider = OrganisationsApi;
    this.httpProgress = ProgressIndicatorModel;
    this.usersProvider = UsersApi;
    this.organisation = organisation;
    this.modalService = ModalContext;
  }

  onMount = () => {
    this.loadForm();
  };
  loadForm = async () => {
    this.formModel = new SingleFormModel();
    const organisation = (await this.orgProvider.getById(+this.organisationId)).payload;

    this.formModel.formFields = getOrganisationFormFields(this.orgProvider, this.modalService, organisation);
    const canEditOrg = PermissionsContext.canEditOrganisation(this.organisationId);
    this.formModel.actions = canEditOrg
      ? [
          {
            id: "cancel",
            label: I18n.t("phrases.cancel"),
            rendersIn: UiActionRenderers.LINK_BUTTON,
            componentProps: {
              type: ButtonTypes.LINK,
              className: "ml-auto",
              href: `/organisations/${this.organisationId}`
            }
          },
          {
            id: "save",
            label: I18n.t("phrases.save"),
            onAction: async () => {
              let res = await this.formModel.submit();
              if (res) {
                res.isImageChanged = res.profileImage ? true : false;
                let formData = new FormData();
                Object.keys(res).forEach(e => {
                  if (e === "profileImage" && res[e].blob) {
                    formData.append(e, res[e].blob, new Date().getTime() + ".jpg");
                    return;
                  }
                  formData.append(e, res[e]);
                });

                this.httpProgress.showOverlay();
                const result = await this.orgProvider.update(res.id, formData as any);
                this.refreshClaims();
                this.httpProgress.hideOverlay();

                if (!result || result.isError) {
                  return;
                }

                this.navigate(`/organisations/${res.id}`);
              }
            },
            rendersIn: UiActionRenderers.BUTTON,
            componentProps: {
              type: ButtonTypes.PRIMARY,
              className: "ml-2"
            }
          }
        ]
      : [];
    this.isLoading = false;
  };
}
