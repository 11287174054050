import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { PositionedSpinner } from "../../../../../components/ui/PositionedSpinner";
import { ImpactsGrid } from "../../../../grids/builder/directorBuilder/grids/ImpactsGrid/ImpactsGridView_view";
import { useCurrentOrganisationId } from "../../../../../services/local/organisationContext/OrganisationContextModel";
import { ImpactsGridSidebarModel } from "./ImpactsGridSidebar_model";

export const ImpactsGridSidebar: React.FC<{
  onAssignSuccess: (itemIds: number[]) => any;
  selectedImpactIds: number[];
}> = observer(({ selectedImpactIds, onAssignSuccess }) => {
  const { projectId } = useParams<{ projectId: string }>();
  const organisationId = useCurrentOrganisationId();
  const [model] = useState(() => new ImpactsGridSidebarModel(organisationId, +projectId, selectedImpactIds));

  useEffect(() => {
    model.onMount();

    //eslint-disable-next-line
  }, []);
  if (model.isLoading) return <PositionedSpinner />;
  return (
    <div className="processes-modal-view insight-tab-content__space-filler" style={{ height: "100%" }}>
      <ImpactsGrid
        data={model.data}
        gridToolbarType="link-modal"
        isLoading={model.isLoading}
        preSelectedItemIds={selectedImpactIds}
        onAssignSuccess={onAssignSuccess}
        columnDefs={[]}
      />
    </div>
  );
});
