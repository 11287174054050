import { IFlightPathApiResponse } from "../BaseApiModel";
import { AxiosInstance, AxiosRequestConfig } from "axios";
import http from "../Http";
import { FilteredOrganisationApiModel } from "../filteredApi/FilteredOrganisationApiModel";
import { MilestoneField } from "../../../../enums";

export class MilestonesApi extends FilteredOrganisationApiModel<FP.Entities.IMilestone> {
  controller: string = "milestones";
  url: string;

  constructor(http: AxiosInstance) {
    super(http, "milestones");
    this.url = `/api/${this.version}/organisations`;
  }

  async deleteGridField(
    organisationId: number,
    projectId: number,
    milestoneId: number,
    milestoneField: MilestoneField,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<boolean>> {
    let res = await this.http.delete(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${milestoneId}/${milestoneField}`,
      config
    );
    return res.data;
  }

  //#region programme

  async createProgrammeMilestone(
    organisationId: number,
    programmeId: number,
    milestone: FP.Entities.IMilestone
  ): Promise<IFlightPathApiResponse<FP.Entities.IMilestone>> {
    const { data } = await this.http.post(
      `${this.url}/${organisationId}/programmes/${programmeId}/milestones`,
      milestone
    );
    return data;
  }

  async createProjectMilestone(
    organisationId: number,
    projectId: number,
    milestone: FP.Entities.IMilestone
  ): Promise<IFlightPathApiResponse<FP.Entities.IMilestone>> {
    const { data } = await this.http.post(`${this.url}/${organisationId}/projects/${projectId}/milestones`, milestone);
    return data;
  }

  async updateProgrammeMilestone(
    organisationId: number,
    programmeId: number,
    milestoneId: number,
    milestone: FP.Entities.IMilestone
  ): Promise<IFlightPathApiResponse<FP.Entities.IMilestone>> {
    const { data } = await this.http.put(
      `${this.url}/${organisationId}/programmes/${programmeId}/milestones/${milestoneId}`,
      milestone
    );
    return data;
  }

  async updateProjectMilestone(
    organisationId: number,
    projectId: number,
    milestoneId: number,
    milestone: FP.Entities.IMilestone
  ): Promise<IFlightPathApiResponse<FP.Entities.IMilestone>> {
    const { data } = await this.http.put(
      `${this.url}/${organisationId}/projects/${projectId}/milestones/${milestoneId}`,
      milestone
    );
    return data;
  }

  async deleteProgrammeMilestone(
    organisationId: number,
    programmeId: number,
    milestoneId: number
  ): Promise<IFlightPathApiResponse<boolean>> {
    const { data } = await this.http.delete(
      `${this.url}/${organisationId}/programmes/${programmeId}/milestones/${milestoneId}`
    );
    return data;
  }

  async deleteProjectMilestone(
    organisationId: number,
    projectId: number,
    milestoneId: number
  ): Promise<IFlightPathApiResponse<boolean>> {
    const { data } = await this.http.delete(
      `${this.url}/${organisationId}/projects/${projectId}/milestones/${milestoneId}`
    );
    return data;
  }
  getProgrammeMilestones = async (organisationId: number, programmeId: number, config?: AxiosRequestConfig) => {
    const url = `${this.url}/${organisationId}/programmes/${programmeId}/milestones`;
    let res = await this.http.get(url, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.IMilestone[]>;
    }
    return null;
  };

  updateProjectMilestoneField = async (
    organisationId: number,
    projectId: number,
    milestoneId: number,
    textField: string,
    idField: number,
    dateField: Date,
    numericField: number,
    milestoneField: MilestoneField,
    config?: AxiosRequestConfig
  ) => {
    let data = {
      TextField: textField,
      IdField: idField,
      DateField: dateField,
      NumericField: numericField
    };
    let res = await this.http.put(
      `${this.url}/${organisationId}/projects/${projectId}/milestones/${milestoneId}/${milestoneField}`,
      data,
      config
    );
    return res.data;
  };

  deleteProjectMilestonesRange = async (
    organisationId: number,
    projectId: number,
    milestoneIds: number[]
  ): Promise<IFlightPathApiResponse<boolean>> => {
    const { data } = await this.http.post(
      `${this.url}/${organisationId}/projects/${projectId}/milestones/delete-range`,
      { milestoneIds }
    );
    return data;
  };
  //#endregion programme
}

const instance = new MilestonesApi(http);
export default instance;
