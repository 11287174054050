import { PanelBackgrounds, PanelTypes } from "@flightpath/coreui/dist/ui/Panel";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import AnimateHeight from "react-animate-height";
import { Button, ButtonIcon, ButtonTypes } from "../../../../../../../../components/ui/Button";
import { IconSymbols } from "../../../../../../../../components/ui/Icon";
import { Panel } from "../../../../../../../../components/ui/Panel";
import { PositionedSpinner } from "../../../../../../../../components/ui/PositionedSpinner";
import { Input } from "../../../../../../../../components/ui/_forms/Input";
import { CanEdit, CanView } from "../../../../../../../../contexts/permissions/PermissionHelpers";
import { PermissionErrorMessage } from "../../../../../../../../contexts/permissions/PermissionMessages";
import { PermissionFields } from "../../../../../../../../contexts/permissions/PermissionsTypes";
import I18n from "../../../../../../../localization/I18n";
import { Enums } from "../../../../../../../../enums";
import { AddImpactForm } from "./AddImpactForm";
import { useModalContext } from "../../../../../../../modalZ/context/ModalContext";
import { ImpactCard } from "../../../../../../../../pages/change/impacts/impactCard/ImpactCard_view";
import { ProjectStakeholderImpactsSidebarModel } from "./ProjectStakeholderImpactsSidebar_model";

export interface ProjectStakeholderImpactsProps {
  model: ProjectStakeholderImpactsSidebarModel;
}

export const ProjectStakeholderImpactsSidebar: React.FunctionComponent<ProjectStakeholderImpactsProps> = observer(
  props => {
    const { model } = props;
    const [showImpactForm, setShowImpactForm] = useState(false);
    const modalService = useModalContext();

    useEffect(() => {
      model.onMount();
      return model.onUnmount;
    }, [model]);

    if (model.isLoading) return <PositionedSpinner />;

    return (
      <div className={`container-fluid pt-5`}>
        <CanView
          field={PermissionFields.IMPACTS}
          projectId={model.projectId}
          errorMessage={
            <div className="row mb-4">
              <div className="col">
                <PermissionErrorMessage>
                  <p className="mb-0">{I18n.t("phrases.noImpactAccess")}</p>
                </PermissionErrorMessage>
              </div>
            </div>
          }
        >
          <div className="row mb-4">
            <div className="col">
              <CanEdit field={PermissionFields.IMPACTS} projectId={model.projectId}>
                {!showImpactForm && (
                  <Button
                    onClick={() => {
                      setShowImpactForm(true);
                      model.resetSearch();
                    }}
                  >
                    {showImpactForm ? I18n.t("phrases.done") : I18n.t("phrases.addImpact")}
                  </Button>
                )}
                {showImpactForm && (
                  <Panel.Panel
                    hasBorderRadius={true}
                    background={PanelBackgrounds.BG_WHITE}
                    type={PanelTypes.OUTLINES}
                    className="py-4 text-left"
                  >
                    <AddImpactForm
                      setFormIsShown={setShowImpactForm}
                      reloadFn={model.loadProjectStakeholder}
                      projectStakeholderProvider={model.projectStakeholderProvider}
                      organisationId={model.organisationId}
                      projectId={model.projectId}
                      projectStakeholderId={model.projectStakeholderId}
                    />
                  </Panel.Panel>
                )}
              </CanEdit>
              {!showImpactForm && (
                <>
                  <ButtonIcon
                    className="float-right"
                    symbol={model.isSearchMode ? IconSymbols.Close : IconSymbols.Search}
                    iconSize={Enums.UiSizes.SM}
                    type={ButtonTypes.OUTLINE_PRIMARY}
                    onClick={model.isSearchMode ? model.resetSearch : model.showSearchMode}
                  />

                  <AnimateHeight height={model.isSearchMode ? "auto" : 0} animateOpacity={true}>
                    <Input
                      autoFocus={true}
                      size={-1}
                      onChange={model.updateSearchValue}
                      borderStyle="underline"
                      placeholder={I18n.t("placeholders.searchImpactStakeholders")}
                      icon="search"
                      value={model.searchValue}
                      onBlur={null}
                      className="mt-3"
                    />
                  </AnimateHeight>
                </>
              )}
            </div>
          </div>

          <div className="row mb-3">
            <div className="col">
              {model.filteredProjectStakeholderImpacts?.length ? (
                model.filteredProjectStakeholderImpacts.map(impact => {
                  return <ImpactCard item={impact} />;
                })
              ) : (
                <Panel.Panel hasBorderRadius={true} type={PanelTypes.OUTLINES} className="py-2 text-center">
                  <div>{I18n.t("phrases.noStakeholderImpacts")}</div>
                </Panel.Panel>
              )}
            </div>
          </div>
        </CanView>
        <div className="row mb-5">
          <div className="col">
            <div className="d-flex">
              <Button
                className="ml-auto"
                onClick={() => {
                  modalService.hide();
                }}
              >
                {I18n.t("phrases.done")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
);
