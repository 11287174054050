import React from "react";
import { PositionedSpinner } from "../../components/ui/PositionedSpinner";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { Panel } from "../../components/ui/Panel";

export interface InfiniteLoaderProps extends ReactProps {
  // extends React.HTMLProps<HTMLDivElement> {
  isLoading: boolean;
  loadPageData: () => void;
  hasNextPage: boolean;
  isDisabled?: boolean;
  rootMargin?: string;
  noMoreDataLabel: React.ReactNode;
}

export const InfiniteLoader: React.FC<InfiniteLoaderProps> = props => {
  const [infiniteRef] = useInfiniteScroll({
    loading: props.isLoading,
    hasNextPage: props.hasNextPage,
    onLoadMore: props.loadPageData,
    // When there is an error, we stop infinite loading.
    // It can be reactivated by setting "error" state as undefined.
    disabled: props.isDisabled,
    // `rootMargin` is passed to `IntersectionObserver`.
    // We can use it to trigger 'onLoadMore' when the sentry comes near to become
    // visible, instead of becoming fully visible on the screen.
    rootMargin: props.rootMargin
  });

  return (
    <>
      {props.children}
      {props.hasNextPage ? (
        <div ref={infiniteRef} className={"p-3 mb-5"}>
          <PositionedSpinner />
        </div>
      ) : (
        <Panel.Panel background={Panel.PanelBackgrounds.BG_WHITE} className={"p-3 mb-5"}>
          {props.noMoreDataLabel}
        </Panel.Panel>
      )}
    </>
  );
};
