import { GridApi, GridReadyEvent, SelectionChangedEvent } from "ag-grid-community";
import _ from "lodash";
import { action, makeObservable, observable } from "mobx";
import ProjectStakeholdersApi, {
  ProjectStakeholdersApi as IProjectStakeholdersApi
} from "../../../../../services/api/v2/projectStakeholders/ProjectStakeholders.api";
import ImpactsHub, { ImpactsHub as IImpactsHub } from "../../../../../services/hubs/ImpactsHub/Impacts_hub";

export class ImpactGridModel {
  projectId: number;
  impactsHub: IImpactsHub;
  organisationId: number;
  @observable isLoading: boolean = true;
  @observable gridImpacts: FP.Entities.IImpactSummary[];
  authUser: FP.Entities.IUser;
  @observable.ref selectedImpacts: number[] = [];
  gridApi: GridApi;
  projectStakeholderProvider: IProjectStakeholdersApi;
  stakeholderIds: number[];
  searchText: string;

  /**
   *
   */
  constructor(projectId: number, organisationId: number, authUser: FP.Entities.IUser, stakeholderIds: number[]) {
    makeObservable(this);
    this.projectId = projectId;
    this.impactsHub = ImpactsHub;
    this.projectStakeholderProvider = ProjectStakeholdersApi;
    this.organisationId = organisationId;
    this.stakeholderIds = stakeholderIds;
    this.authUser = authUser;
  }

  onMount = async () => {
    await this.registerSocketEvents();
  };

  onUnmount = () => {
    this.impactsHub.stopConnection();
  };

  registerSocketEvents = async () => {
    if (this.impactsHub.isConnectionStarted === true) {
      await this.impactsHub.stopConnection();
    }
    await this.impactsHub.startConnection();

    this.impactsHub.onLoadData(d => {
      this.setImpactRowData(d.data);
    });

    await this.impactsHub.invokeLoadData(this.organisationId, this.projectId);
  };

  @action
  setImpactRowData = impacts => {
    this.setGridImpacts(impacts);
    this.isLoading = false;
  };

  @action
  setGridImpacts = impacts => {
    this.gridImpacts = impacts;
  };

  @action
  onGridReady = (gridReadyEvent: GridReadyEvent) => {
    this.gridApi = gridReadyEvent.api;
  };

  @action
  setSearchText = (ev: React.FormEvent<HTMLInputElement>) => {
    this.searchText = ev.currentTarget.value;

    if (this.gridApi !== undefined) {
      this.gridApi.setGridOption("quickFilterText", this.searchText);
    }
  };

  @action
  updateSelectedImpacts = (event: SelectionChangedEvent) => {
    this.gridApi = event.api;
    this.selectedImpacts = _.map(event.api.getSelectedNodes(), e => {
      return e.data.id;
    });
  };

  assignImpacts = async () => {
    return await this.projectStakeholderProvider.assignMultipleImpacts(
      this.organisationId,
      this.projectId,
      this.stakeholderIds,
      this.selectedImpacts
    );
  };
}
