import { ComputedDatum, ResponsiveSunburst } from "@nivo/sunburst";
import { useTheme } from "@nivo/core";
import React, { Dispatch, SetStateAction, useState } from "react";
import { ElementTypes } from "@flightpath/coreui/dist/enums";

export { useTheme };
export type { ComputedDatum };

export interface SunburstChartProps {
  data: any;
  colourPalette?: string[];
  legendDataSetter?: Dispatch<SetStateAction<any>>;
  onClickFn?: (dataItem: ComputedDatum<any>) => boolean;
  tooltipFn?: (props: ComputedDatum<any>) => JSX.Element;
  className?: string;
}

export interface SunburstDataProps {
  parentKey: string;
  key: string;
  label: string;
  value?: number;
  children?: any;
  elementType?: ElementTypes;
}

const flatten = data =>
  data.reduce((acc, item) => {
    if (item.children) {
      return [...acc, item, ...flatten(item.children)];
    }
    return [...acc, item];
  }, []);

const findObject = (data, key) => data.find(searchedName => searchedName.key === key);

const setLegend = (data, legendDataSetter) => {
  if (!legendDataSetter) return;
  legendDataSetter(data);
};

export const SunburstChart: React.FC<SunburstChartProps> = ({
  data,
  tooltipFn,
  className,
  onClickFn,
  legendDataSetter
}) => {
  const [chartData, setChartData] = useState(data);
  const [parentKey, setParentKey] = useState(data.key);
  const [nodeLabel, setNodeLabel] = useState(data.label);
  const [nodeParentLabel, setNodeParentLabel] = useState("");

  const updateSunburst = foundObject => {
    setChartData(foundObject);
    setLegend(foundObject, legendDataSetter);
    setParentKey(foundObject.parentKey);
    setNodeLabel(foundObject.label);
    setNodeParentLabel(foundObject.parentLabel);
  };

  const updateCentreLabel = () => {
    if (parentKey === data.key) {
      setNodeLabel(data.label);
      setNodeParentLabel("");
      setLegend(data, legendDataSetter);
      setChartData(data);
      return;
    }
    const foundObject = findObject(flatten(data.children), parentKey);
    updateSunburst(foundObject);
  };

  const GoBack = ({ centerX, centerY }) => {
    return (
      <text
        className="sunburst__centre-label"
        y={centerY}
        x={centerX}
        textAnchor="middle"
        dominantBaseline="central"
        onClick={updateCentreLabel}
        style={{ fontSize: "25px", fontWeight: 600 }}
      >
        {nodeLabel}
      </text>
    );
  };

  const ParentHeader = ({ centerX, centerY }) => {
    return (
      <text
        className="sunburst__centre-label"
        y={centerY - 40}
        x={centerX}
        textAnchor="middle"
        dominantBaseline="central"
        onClick={updateCentreLabel}
        style={{ fontSize: "16px", fontWeight: 600 }}
      >
        {nodeParentLabel}
      </text>
    );
  };

  const BackButton = ({ centerX, centerY }) => {
    return (
      <text
        className="sunburst__centre-label"
        y={centerY + 40}
        x={centerX}
        textAnchor="middle"
        dominantBaseline="central"
        onClick={updateCentreLabel}
        style={{ fontSize: "12px", fontWeight: 800, textTransform: "uppercase" }}
      >
        {nodeParentLabel ? "back" : ""}
      </text>
    );
  };

  return (
    <div className={`sunburst ${className ? className : ""}`}>
      <ResponsiveSunburst
        data={chartData}
        margin={{ top: 4, right: 4, bottom: 4, left: 4 }}
        id="key"
        value="value"
        enableArcLabels={false}
        arcLabel={d => d.data.label}
        theme={{ text: { fontSize: 16, fontFamily: "Poppins" } }}
        borderWidth={2}
        animate={true}
        colors={e => {
          return e.data.colour ? e.data.colour : "black";
        }}
        childColor={(parent, child) => (child.data.colour ? child.data.colour : parent.color)}
        transitionMode="pushIn"
        onClick={clickedData => {
          const isContinue = onClickFn ? onClickFn(clickedData) : true;
          if (!isContinue) return;
          const foundObject = findObject(flatten(data.children), clickedData.id);
          if (foundObject && foundObject.children) {
            updateSunburst(foundObject);
          }
        }}
        layers={["arcs", "arcLabels", GoBack, ParentHeader, BackButton]}
        tooltip={tooltipFn}
      />
    </div>
  );
};
