import I18n from "../../../../../../../localization/I18n";
import ModalContext from "../../../../../../../modalZ/context/ModalContext";
import { GridSideModalTitle } from "../../../../utils/GridModalUtils";
import { ImpactGroupsSidebarModel } from "./impactGroupsSidebar_model";
import { ImpactGroupsSidebar } from "./impactGroupsSidebar_view";

export const IMPACT_IMPACT_GROUPS_SIDEBAR_MODAL_CONFIG = (
  organisationId: number,
  projectId: number,
  impact: FP.Entities.IImpact
) => {
  return {
    showClose: true,
    title: <GridSideModalTitle name={impact.name} field={I18n.t("phrases.impactGroups")} />,
    content: (
      <ImpactGroupsSidebar
        model={new ImpactGroupsSidebarModel(ModalContext.hide, organisationId, projectId, impact.id)}
      />
    )
  };
};
