import _ from "lodash";
import { IconSymbols } from "../../../../../../../../components/ui/Icon";
import { AutocompleteOption } from "../../../../../../../../components/ui/_forms/Autocomplete/AutocompleteOption";
import { FORM_COL } from "../../../../../../../../constants";
import { IMultiSelectorModel } from "../../../../../../../forms/controls/multiSelector/IMultiSelectorModel";
import { INIT_MULTISELECTOR } from "../../../../../../../forms/controls/multiSelector/MultiSelector_model";
import { generateFormFieldsFromJson } from "../../../../../../../forms/helpers/FormFieldMappers";
import I18n from "../../../../../../../localization/I18n";
import { ImpactTypesApi } from "../../../../../../../../services/api/v2/impactTypes/ImpactTypes.api";

export const getImpactTypesSidebarFormFields = (
  impactTypeProvider: ImpactTypesApi,
  canEdit: boolean,
  organisationId: number,
  projectId: number,
  impact: FP.Entities.IImpact
) => {
  const addType = async (oImpactTypeId: number) => {
    let iit = {
      impactId: impact.id,
      organisationImpactTypeId: oImpactTypeId
    };
    let res = await impactTypeProvider.addTypeToImpact(organisationId, projectId, iit);
    if (!res || res.isError) return null;

    return res.payload;
  };

  const removeType = async (impactTypeId: number) => {
    let res = await impactTypeProvider.removeTypeFromImpact(organisationId, projectId, impact.id, impactTypeId);
    if (!res || res.isError) return null;

    return res.payload;
  };

  const impactTypes: Partial<IMultiSelectorModel> = {
    ...INIT_MULTISELECTOR,
    key: "impactTypes",
    placeholder: I18n.t("placeholders.searchImpactTypes"),
    optionElement: (
      <AutocompleteOption
        key={"e"}
        className={"autocomplete__chip"}
        label={(e: FP.Entities.IImpactType) => {
          return e.name;
        }}
      />
    ),
    disabled: !canEdit,
    manageLink: `/organisations/${organisationId}/settings/impactTypes`,
    label: <label htmlFor="impactTypes">{I18n.t("forms.impactTypes")}</label>,
    onItemSelected: item => {
      addType(item.id);
    },
    onFocus: async function () {
      const self: IMultiSelectorModel = this;
      let values = this.extractValue();

      const res = await impactTypeProvider.getUnselectedByOrganisation(organisationId, values);

      if (res?.payload) {
        const sortedImpactTypes = _.orderBy(res.payload, [impactType => impactType.name.toLowerCase()]);
        self.setOptions(sortedImpactTypes);
      }
    },
    componentProps: {
      icon: IconSymbols.Search
    },
    searchAttribute: "name",
    fieldClassName: FORM_COL.FULL_WIDTH,
    extractValue: function () {
      return this.selectedItems.map(e => e.id);
    },
    removeItemFn: item => removeType(item.id),
    value: impact?.impactTypes,
    valueLabelFn: e => e?.name
  };

  let fields = [];
  fields.push(impactTypes);

  return generateFormFieldsFromJson(fields);
};
