import { AudienceImpactsModal } from "./AudienceImpactsModal_view";

export const AUDIENCE_IMPACTS_SIDEBAR_MODAL_CONFIG = (organisationId: number, projectId: number, item: any) => {
  return {
    showClose: false,
    className: "d-flex flex-column",
    panelProps: {
      className: "d-flex flex-column h-100"
    },
    content: <AudienceImpactsModal selectedAudienceIds={[item.id]} selectedImpactIds={item.impacts.map(e => e.id)} />
  };
};
