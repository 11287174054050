import I18n from "../../../../../../../localization/I18n";
import ModalContext from "../../../../../../../modalZ/context/ModalContext";
import { GridSideModalTitle } from "../../../../utils/GridModalUtils";
import { ProjectProcessLocationsSidebarModel } from "./ProjectProcessLocationsSidebar_model";
import { ProjectProcessLocationsSidebar } from "./ProjectProcessLocationsSidebar_view";

export const PROJECT_PROCESS_LOCATIONS_SIDEBAR_MODAL_CONFIG = (
  organisationId: number,
  projectId: number,
  projectProcess: FP.Entities.IProjectProcess
) => {
  return {
    showClose: true,
    title: <GridSideModalTitle name={projectProcess.name} field={I18n.t("phrases.locations")} />,
    content: (
      <ProjectProcessLocationsSidebar
        model={new ProjectProcessLocationsSidebarModel(ModalContext.hide, organisationId, projectId, projectProcess.id)}
      />
    )
  };
};
