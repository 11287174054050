import React from "react";
import I18n from "../../../../core/localization/I18n";
import { Pill, PillTypes } from "../../../../components/ui/Pill";
import { UiSizes } from "@flightpath/coreui/dist/enums";

export interface IProgrammeLocationProps {
  programmeLocations?: FP.Entities.IProgrammeLocation[];
  organisationId: number;
}

export const ProgrammeLocations: React.FunctionComponent<IProgrammeLocationProps> = (props: IProgrammeLocationProps) => {
  const getUsageCount = (projects: string): number => {
    if(projects.length === 0)
      return 0;

    return projects.split('|').length;
  }

  const getPillColour = (projectCount: number):PillTypes => {
    if(projectCount === 0)
      return PillTypes.LIGHT_GRAY;

    return PillTypes.PRIMARY;
  }

  return (
    <div className="programme-locations">
      {(props.programmeLocations && props.programmeLocations.length > 0) ? (
        props.programmeLocations.map((x,i) => (
          <div className="programme-locations--programme-location-pill float-left mr-2" key={i}>
            <Pill type={getPillColour(getUsageCount(x.projects))} size={UiSizes.XS}>{x.name} ({getUsageCount(x.projects)})</Pill>
          </div>
        ))
      ) : <span>{I18n.t("phrases.na")}</span>}
    </div>
  );
}