import { AxiosRequestConfig } from "axios";
import { IFilteredOptions, IFilteredResponse, IFilteredRequest } from "./FilteredApiModel";
import { BaseProjectApiModel, IBaseProjectApiModel } from "../BaseProjectApiModel";

export interface IFilteredProjectApiModel<T> extends IBaseProjectApiModel<T> {
  getFiltered: (
    organisationId: number,
    projectId: number,
    filterOptions: Partial<IFilteredOptions>,
    config?: AxiosRequestConfig
  ) => Promise<IFilteredResponse<T>>;
}

export abstract class FilteredProjectApiModel<T> extends BaseProjectApiModel<T> implements IFilteredProjectApiModel<T> {
  abstract controller: string;
  getFiltered = async (
    organisationId: number,
    projectId: number,
    filterOptions: Partial<IFilteredOptions>,
    config?: AxiosRequestConfig
  ): Promise<IFilteredResponse<T>> => {
    let query = this.getRequestQuery(filterOptions);
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}`;
    let res = await this.http.get(url + query, config);
    if (res && res.status === 200) {
      return res.data;
    }
  };

  getRequestQuery = (filters: Partial<IFilteredOptions>): string => {
    let s: IFilteredRequest = {
      page: filters.page,
      pageSize: filters.pageSize,
      sorts: filters.sorts,
      filters: filters.filters
    };
    let params = "?";
    params += s.page ? `page=${s.page}&` : "";
    params += s.pageSize ? `pageSize=${s.pageSize}` : "";
    params += s.sorts ? "&sorts=" + s.sorts : "";
    params += s.filters ? "&filters=" + s.filters : "";
    return params.replace(/^\?&(.*)$/i, "?$1");
  };
}
