import { observer } from "mobx-react";
import * as React from "react";
import { Async } from "react-async";
import { Button } from "../../../../../../../../components/ui/Button";
import { PositionedSpinner } from "../../../../../../../../components/ui/PositionedSpinner";
import { CanEdit } from "../../../../../../../../contexts/permissions/PermissionHelpers";
import { PermissionFields } from "../../../../../../../../contexts/permissions/PermissionsTypes";
import { useFlightPathUser } from "../../../../../../../auth_insight/UserContext";
import I18n from "../../../../../../../localization/I18n";
import { useModalContext } from "../../../../../../../modalZ/context/ModalContext";
import ProjectStakeholdersApi from "../../../../../../../../services/api/v2/projectStakeholders/ProjectStakeholders.api";
import { useCurrentOrganisationId } from "../../../../../../../../services/local/organisationContext/OrganisationContextModel";
import { CommentListView } from "../../../../../../../../pages/change/comments/commentListView/CommentListView_view";
import { CommentListViewModel } from "../../../../../../../../pages/change/comments/commentListView/CommentListView_model";

export interface ProjectStakeholderNotesSidebarModel {
  projectId: number;
  itemId: number;
}

export const ProjectStakeholderNotesSidebar: React.FunctionComponent<ProjectStakeholderNotesSidebarModel> = observer(
  p => {
    let [, setStakeholder] = React.useState(null);
    const authUser = useFlightPathUser();
    const projectId = p.projectId;
    const itemId = p.itemId;
    const modalService = useModalContext();
    const organisationId = useCurrentOrganisationId();
    const projectStakeholdersProvider = ProjectStakeholdersApi;

    const listViewConfig = React.useMemo(() => {
      return {
        searchAttribute: "projectStakeholderId",
        id: -1,
        title: null,
        projectId: projectId,
        organisationId: organisationId,
        placeholderText: I18n.t("placeholders.stakeholderNotePlaceholder")
      };
    }, [projectId, organisationId]);

    const [commentViewModel] = React.useState(() => new CommentListViewModel(projectId, authUser, listViewConfig));

    const load = React.useCallback(async () => {
      const res = await projectStakeholdersProvider.getDetailedStakeholderByProjectStakeholderId(
        organisationId,
        projectId,
        itemId
      );

      if (!res || res.isError) {
        return;
      }

      setStakeholder(res.payload);
      commentViewModel.setConfig({
        id: p.itemId
      });
      // eslint-disable-next-line
    }, [itemId, projectId, organisationId, commentViewModel, projectStakeholdersProvider]);
    return (
      <Async promiseFn={load}>
        <Async.Loading>
          <PositionedSpinner />
        </Async.Loading>
        <Async.Resolved>
          {() => (
            <div className="container-fluid stakeholder-compact-view">
              <CanEdit projectId={projectId} field={PermissionFields.STAKEHOLDERS}>
                <div className="row mb-0">
                  <div className="col">
                    <h3 className="mb-0 pl-0 pb-0">{I18n.t("phrases.addANote")}</h3>
                  </div>
                </div>
              </CanEdit>
              <CommentListView model={commentViewModel} field={PermissionFields.STAKEHOLDERS} />
              <div className="row mb-5">
                <div className="col">
                  <div className="d-flex">
                    <Button
                      className="ml-auto"
                      onClick={() => {
                        modalService.hide();
                      }}
                    >
                      {I18n.t("phrases.done")}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Async.Resolved>
      </Async>
    );
  }
);
