import { action, makeObservable, observable } from "mobx";
import * as React from "react";

import ProgressIndicatorModel, {
  ProgressIndicatorModel as IProgressIndicatorModel
} from "../../../../../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import { BaseModel } from "../../../../../../../util/BaseModel";
import { IModalContextModel } from "../../../../../../../modalZ/context/IModalContext";
import ModalContext from "../../../../../../../modalZ/context/ModalContext";
import ProjectStakeholdersApi, {
  ProjectStakeholdersApi as IProjectStakeholdersApi
} from "../../../../../../../../services/api/v2/projectStakeholders/ProjectStakeholders.api";

export class ProjectStakeholderImpactsSidebarModel extends BaseModel {
  projectStakeholderProvider: IProjectStakeholdersApi;
  modalService: IModalContextModel;
  httpProgress: IProgressIndicatorModel;
  projectId: number;
  @observable isLoading: boolean = true;
  @observable isSearchMode: boolean = false;
  @observable searchValue: string = "";
  @observable.ref projectStakeholder: FP.Entities.IProjectStakeholder;
  @observable.ref projectStakeholderImpacts: FP.Entities.IImpact[] = [];
  @observable.ref filteredProjectStakeholderImpacts: FP.Entities.IImpact[] = [];
  organisationId: number;
  projectStakeholderId: number;

  constructor(projectStakeholderId: number, organisationId: number, projectId: number) {
    super();
    makeObservable(this);
    this.projectId = projectId;
    this.projectStakeholderProvider = ProjectStakeholdersApi;
    this.modalService = ModalContext;
    this.httpProgress = ProgressIndicatorModel;
    this.organisationId = organisationId;
    this.projectStakeholderId = projectStakeholderId;
    this.loadProjectStakeholder(projectStakeholderId);
  }

  onMount = () => {};

  onUnmount = () => {
    this.resetSearch();
  };

  @action
  setProjectStakeholder = (projectStakeholder: FP.Entities.IProjectStakeholder) => {
    this.projectStakeholder = projectStakeholder;
    this.isLoading = false;
  };

  @action
  setProjectStakeholderImpacts = (impacts: FP.Entities.IImpact[]) => {
    this.projectStakeholderImpacts = impacts as FP.Entities.IImpact[];
    this.filteredProjectStakeholderImpacts = impacts as FP.Entities.IImpact[];
  };

  @action
  loadProjectStakeholder = async (projectStakeholderId: number) => {
    this.isLoading = true;
    let stakeholderRes = await this.projectStakeholderProvider.getDetailedStakeholderByProjectStakeholderId(
      this.organisationId,
      this.projectId,
      projectStakeholderId
    );
    let stakeholderImpactsRes = await this.projectStakeholderProvider.getProjectStakeholderImpacts(
      this.organisationId,
      this.projectId,
      projectStakeholderId
    );
    if (!stakeholderRes || stakeholderRes.isError || !stakeholderImpactsRes || stakeholderImpactsRes.isError) return;

    this.setProjectStakeholderImpacts(stakeholderImpactsRes.payload);
    this.setProjectStakeholder(stakeholderRes.payload);
  };

  @action
  updateSearchValue = (e: React.FormEvent<HTMLInputElement>) => {
    this.searchValue = e.currentTarget.value;
    this.filterProjectStakeholderImpacts();
  };

  @action
  showSearchMode = () => {
    this.isSearchMode = true;
  };

  @action
  hideSearchMode = () => {
    this.isSearchMode = false;
  };

  @action
  resetSearch = () => {
    this.searchValue = "";
    this.filterProjectStakeholderImpacts();
    this.hideSearchMode();
  };

  @action
  filterProjectStakeholderImpacts = () => {
    if (this.searchValue) {
      const lowerSearch = this.searchValue.toLowerCase();
      this.filteredProjectStakeholderImpacts = this.projectStakeholderImpacts.filter(impact => {
        const lowerName = impact.name.toLowerCase();
        const lowerRefNo = impact.refNumber.toLowerCase();

        return lowerName.includes(lowerSearch) || lowerRefNo.includes(lowerSearch);
      });
    } else {
      this.filteredProjectStakeholderImpacts = this.projectStakeholderImpacts;
    }
  };
}
