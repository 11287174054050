import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import { ButtonIcon, ButtonTypes } from "../../../../components/ui/Button";
import { IconSymbols } from "../../../../components/ui/Icon";
import { Panel } from "../../../../components/ui/Panel";
import { ProgressStatus } from "../../../../components/ui/ProgressStatus";
import { RagStatus } from "../../../../components/ui/RagStatus";
import { RenderXssSafeString } from "../../../../core/AntiXss/AntiXssHelper";
import I18n from "../../../../core/localization/I18n";
import { IUiAction } from "../../../../core/uiAction/IUiAction";
import { Enums } from "../../../../enums";
import { useCurrentOrganisationId } from "../../../../services/local/organisationContext/OrganisationContextModel";
import { QUERY_STRING_PARAMS } from "../../../../services/local/queryStringService/QueryStringService";

export interface ActionCardProps {
  item: FP.Entities.IAction;
  returnUrl: string;
  actions?: IUiAction<FP.Entities.IAction>[];
  className?: string;
}

export const ActionCard: React.FunctionComponent<ActionCardProps> = props => {
  const organisationId = useCurrentOrganisationId();
  let { item, actions, returnUrl } = props;
  const ownerSub = item?.owner?.sub ?? "";
  return (
    <Panel.Panel
      className={"action-card p-4 " + (props.className || "")}
      background={Panel.PanelBackgrounds.BG_WHITE}
      hasBorderRadius={true}
    >
      <div className="row mb-4">
        <div className="col">
          <div>
            <h4 className="mb-1 strong d-inline-block">
              <Link to={returnUrl}>
                {item.refNumber} - {item.name}
              </Link>
            </h4>

            <ButtonIcon
              className="float-right"
              symbol={IconSymbols.Close}
              type={ButtonTypes.LINK_BIG}
              onClick={ev => actions[1].onAction(ev, item)}
            />
          </div>
          <div dangerouslySetInnerHTML={{ __html: RenderXssSafeString(item.description) }}></div>
        </div>
      </div>

      <div className="row">
        <div className="col-1">
          <div className="mb-1">
            <small>{I18n.t("phrases.rag")}</small>
          </div>
          <div>
            {item.progressStatus === Enums.ProgressStatus.COMPLETED ? (
              <ProgressStatus state={item.progressStatus} className="mt-1" />
            ) : (
              <RagStatus state={item.ragStatus} className="mt-1" />
            )}
          </div>
        </div>
        <div className="col-4">
          <div className="mb-2">
            <small>{I18n.t("phrases.owner")}</small>
          </div>
          {ownerSub !== "" ? (
            <Link
              to={`/organisations/${organisationId}/users/${item.owner.sub}?${
                QUERY_STRING_PARAMS.RETURN_URL
              }=${encodeURIComponent(window.location.href.replace(appConfig.baseUrl, ""))}`}
            >
              <p>
                {item.owner.firstName} {item.owner.lastName}
              </p>
            </Link>
          ) : (
            I18n.t("table.noInput")
          )}
        </div>
        <div className="col-2">
          <div className="mb-2">
            <small>{I18n.t("phrases.startDate")}</small>
          </div>
          <p>{item.startDate !== null ? moment(item.startDate).format("L") : I18n.t("table.noInput")}</p>
        </div>
        <div className="col-2">
          <div className="mb-2">
            <small>{I18n.t("phrases.endDate")}</small>
          </div>
          <p>{item.actualEndDate !== null ? moment(item.actualEndDate).format("L") : I18n.t("table.noInput")}</p>
        </div>
        <div className="col-3">
          <div className="mb-2">
            <small>{I18n.t("phrases.status")}</small>
          </div>
          <p>{Enums.Translator.ProgressStatus(item.progressStatus)}</p>
        </div>
      </div>
    </Panel.Panel>
  );
};
