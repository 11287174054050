import { GridApi, GridReadyEvent, SelectionChangedEvent } from "ag-grid-community";
import _ from "lodash";
import { action, makeObservable, observable } from "mobx";
import React from "react";
import ProgressIndicatorModel, {
  ProgressIndicatorModel as IProgressIndicatorModel
} from "../../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import { IModalContextModel } from "../../../../../core/modalZ/context/IModalContext";
import ModalContext from "../../../../../core/modalZ/context/ModalContext";
import ToasterService, { IToasterService } from "../../../../../core/toaster/ToasterService";
import { BaseModel } from "../../../../../core/util/BaseModel";
import ProjectsApi, { ProjectsApi as IProjectsApi } from "../../../../../services/api/v2/projects/Projects.api";
import GridToastService from "../../../../../services/local/gridToastService/GridToastService";
import { IGridToastService } from "../../../../../services/local/gridToastService/IGridToastService";
import { ILocalStorageService } from "../../../../../services/local/localStorageService/ILocalStorageService";
import LocalStorageService from "../../../../../services/local/localStorageService/LocalStorageService";
import { getProjectJobRoleGridFilters } from "./gridView/ProjectJobRoleGridView_apiFilter";
import { ProjectJobRoleGridViewModel } from "./gridView/ProjectJobRoleGridView_model";
import {
  SHOW_JOB_ROLES_GRID,
  SHOW_LINK_TO_PROCESS_ROLES,
  SHOW_PROJECT_PROCESS_JOB_ROLES_DELETE_CONFIRM_MODAL
} from "./ProjectJobRoleView_modals";
import I18n from "../../../../../core/localization/I18n";
import { ButtonTypes } from "@flightpath/coreui/dist/ui/Button";
import { UiActionRenderers } from "@flightpath/coreui/dist/uiAction/IUiAction";
import ProjectJobRolesApi, {
  ProjectJobRolesApi as IProjectJobRolesApi
} from "../../../../../services/api/v2/projectJobRoles/ProjectJobRoles.api";

export class ProjectJobRoleViewModel extends BaseModel {
  navigate: any;
  organisationId: number;
  toasterService: IToasterService;
  modalService: IModalContextModel;
  gridToastService: IGridToastService;
  projectProvider: IProjectsApi;
  httpProgress: IProgressIndicatorModel;
  projectId: number;
  @observable.ref project: FP.Entities.IProject;
  @observable.ref selectedProjectJobRoles: number[] = [];
  hasBeenReviewed: boolean;
  reviewCommentInput: string;
  authUser: FP.Entities.IUser;
  gridApi: GridApi;
  filterModel: any;
  storageService: ILocalStorageService;
  @observable gridModel: ProjectJobRoleGridViewModel;
  projectJobRolesProvider: IProjectJobRolesApi;
  @observable searchText: string;
  @observable isLoading: boolean = true;
  actions = [
    {
      id: "action1",
      label: I18n.t("entities.processRoles"),
      onAction: ev => {
        this.showLinkToProcessRoles();
      },
      componentProps: {
        type: ButtonTypes.LINK,
        className: ""
      },
      rendersIn: UiActionRenderers.BUTTON
    }
  ];

  constructor(navigate: any, organisationId: number, projectId: number, authUser: FP.Entities.IUser) {
    super();
    makeObservable(this);
    this.navigate = navigate;
    this.organisationId = organisationId;
    this.toasterService = ToasterService;
    this.modalService = ModalContext;
    this.projectProvider = ProjectsApi;
    this.httpProgress = ProgressIndicatorModel;
    this.gridToastService = GridToastService;
    this.authUser = authUser;
    this.storageService = LocalStorageService;
    this.projectJobRolesProvider = ProjectJobRolesApi;
    this.gridModel = new ProjectJobRoleGridViewModel(organisationId, projectId, authUser, []);

    this.projectId = projectId;

    this.hasBeenReviewed = false;
    this.reviewCommentInput = "";
  }

  onMount = async () => {
    this.setGridModel(new ProjectJobRoleGridViewModel(this.organisationId, this.projectId, this.authUser, []));
    await this.gridModel.onMount();
    this.setIsLoading(false);
  };

  onUnmount = () => {
    this.setIsLoading(true);
  };

  @action
  setGridModel = (gridModel: ProjectJobRoleGridViewModel) => {
    this.gridModel = gridModel;
  };

  @action
  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };
  @action
  updateSelectedProjectJobRoless = (event: SelectionChangedEvent) => {
    this.gridApi = event.api;
    this.selectedProjectJobRoles = _.map(event.api.getSelectedNodes(), e => {
      return e.data.id;
    });
  };

  @action
  deselectRows = () => {
    if (this.gridApi !== undefined) this.gridApi.deselectAll();
  };

  @action
  onGridReady = (gridReadyEvent: GridReadyEvent): void => {
    this.gridApi = gridReadyEvent.api;
    this.filterModel = getProjectJobRoleGridFilters();
    this.gridApi.setFilterModel({});

    setTimeout(() => {
      this.gridApi.setFilterModel(this.filterModel);
    });
  };

  @action
  setProject = (project: FP.Entities.IProject) => {
    this.project = project;
  };

  removeProjectJobRoles = async (projectProcessJobRoleIds: number[]) => {
    this.httpProgress.showOverlay();
    let res = await this.projectJobRolesProvider.deleteRange(
      this.organisationId,
      this.projectId,
      projectProcessJobRoleIds
    );
    this.httpProgress.hideOverlay();
    if (!res || res.isError) return;

    return res.payload;
  };

  exportParams = () => {
    return {
      onlySelected: true,
      fileName: "insight-project-job-roles-export.csv"
    };
  };

  @action
  exportRows = () => {
    if (this.selectedProjectJobRoles && this.selectedProjectJobRoles.length > 0) {
      if (this.gridApi !== undefined) this.gridApi.exportDataAsCsv(this.exportParams());
    }
  };

  @action
  resetReviewAndSidebar = () => {
    this.hasBeenReviewed = false;
    this.modalService.hide();
  };

  @action
  setSearchText = (ev: React.FormEvent<HTMLInputElement>) => {
    this.searchText = ev.currentTarget.value;

    if (this.gridApi !== undefined) {
      this.gridApi.setGridOption("quickFilterText", this.searchText);
    }
  };

  showJobRoleGridModal = () => {
    SHOW_JOB_ROLES_GRID();
  };

  assignProcessRolesToJobRoles = async (processRoleIds: number[]) => {
    return await this.projectJobRolesProvider.assignProjectProcessRoles(
      this.organisationId,
      this.projectId,
      this.selectedProjectJobRoles,
      processRoleIds
    );
  };

  showLinkToProcessRoles = () => {
    return SHOW_LINK_TO_PROCESS_ROLES(this.assignProcessRolesToJobRoles);
  };

  showProjectJobRoleConfirmDeleteModal = () =>
    SHOW_PROJECT_PROCESS_JOB_ROLES_DELETE_CONFIRM_MODAL(this.selectedProjectJobRoles, this.removeProjectJobRoles);
}
