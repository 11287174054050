import I18n from "../../../../../core/localization/I18n";

export const MapProgressStatusesFromActionStackBarChart = () => {
  return [
    {
      label: I18n.t("phrases.notStarted"),
      id: "notStarted"
    },
    {
      label: I18n.t("phrases.inProgress"),
      id: "inProgress"
    },
    {
      label: I18n.t("phrases.completed"),
      id: "completed"
    }
  ];
};

export const MapRagStatusesFromActionStackBarChart = () => {
  return [
    {
      label: I18n.t("visualisations.Red"),
      id: "red"
    },
    {
      label: I18n.t("visualisations.Orange"),
      id: "amber"
    },
    {
      label: I18n.t("visualisations.Green"),
      id: "green"
    },
    {
      label: I18n.t("visualisations.Completed"),
      id: "completed"
    },
    {
      label: I18n.t("visualisations.Unknown"),
      id: "unknown"
    }
  ];
};
