import React, { useState } from "react";
import I18n from "../../../core/localization/I18n";
import { ActionTypes } from "./actionTypes/ActionTypes_view";
import { Actions } from "./actions/Actions_view";
import { Impacts } from "./impacts/Impacts_view";
import { Stakeholders } from "./stakeholders/Stakeholders_view";
import { useCurrentOrganisationId } from "../../../services/local/organisationContext/OrganisationContextModel";
import { useParams } from "react-router-dom";
import UserReportsApi from "../../../services/api/v2/userReports/UserReports.api";
import { PaginationModel } from "../../../components/widgets/pagination/Pagination_model";
import { FilterModel, IFilterModel } from "../../../core/filter/Filter_model";
import { UserActionsModel } from "../../change/dashboard/userReports/userActions/UserActions_model";
import { SetUserActionFilter } from "../../change/dashboard/userReports/UserReports_model";
import { PageTitle } from "../../../services/local/pageHeader/PageHeader";
import { UserActionsPage } from "../../change/dashboard/userReports/userActions/UserActions_view";

export const Dashboard: React.FC = () => {
  return (
    <div className="dashboard mt-6">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <h1>{I18n.t("phrases.myDashboard")}</h1>
          </div>
        </div>
        <div className="row">
          <Actions shouldShowSeeAllButton={true} />

          <Impacts shouldShowSeeAllButton={true} />
        </div>
        <div className="row">
          <div className="col-6">
            <Stakeholders />
          </div>
          <div className="col-6">
            <ActionTypes />
          </div>
        </div>
      </div>
    </div>
  );
};

export const OverdueActionsContent: React.FC<{}> = () => {
  const organisationId = useCurrentOrganisationId();
  const { type } = useParams() as any;
  let userReportProvider = UserReportsApi;
  const [paginationModel] = useState(() => new PaginationModel());
  const config = {
    paginationModel,
    initOpts: {
      filterCb: async filterOptions => await userReportProvider.getOverdueActions(organisationId, filterOptions)
    }
  };
  let filterModel: IFilterModel<FP.Entities.IAction> = new FilterModel(config);

  let userActionsModel = new UserActionsModel(filterModel, organisationId);
  userActionsModel.title = (
    <h1 className="d-inline-block mb-0 mt-1">
      {I18n.t("phrases.myActions")} | {I18n.t("phrases.overdueActions")}
    </h1>
  );
  userActionsModel.paginationModel = paginationModel;
  SetUserActionFilter(organisationId, filterModel, userActionsModel.setActions, "overdueActions");
  filterModel.setFilterValue("userRelation", type);
  paginationModel.setConfig({ onPageClick: userActionsModel.loadPageData });
  return (
    <>
      <PageTitle title={`${I18n.t("phrases.myDashboard")} | ${I18n.t("phrases.overdueActions")}`} />
      <UserActionsPage />
    </>
  );
};
