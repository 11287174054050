import React from "react";
import { Link } from "react-router-dom";
import { AvatarContent } from "../../../../components/ui/AvatarContent";
import { usePermissionUserOrganisations } from "../../../../contexts/permissions/PermissionHooks";
import * as _ from "lodash";
import { Panel } from "../../../../components/ui/Panel";
import { useOrganisationContext } from "../../../../services/local/organisationContext/OrganisationContextModel";

export const OrganisationNavList: React.FC<any> = props => {
  const organisations = usePermissionUserOrganisations();

  const currentOrganisationContext = useOrganisationContext();
  return (
    <div className={`org-nav-list`}>
      <Panel.Panel style={{ width: "350px", backgroundColor: "black" }} className="mt-1 py-2">
        {_.map(organisations, e => {
          const url = `/organisations/${e.id}`;
          return (
            <div key={e.id} className="organisation-mega-menu__item mb-1 ml-5">
              <Link to={url} onClick={() => currentOrganisationContext.setCurrentOrganisationId(e.id)}>
                <AvatarContent
                  avatarProps={{
                    imgSrc: e.profileImageUrl
                  }}
                >
                  <p className="mb-0">{e.name}</p>
                </AvatarContent>
              </Link>
            </div>
          );
        })}
      </Panel.Panel>
    </div>
  );
};
