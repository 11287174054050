import { ImpactGroupModalModel } from "../../../../../../../../pages/change/projects/impactsView/ImpactGroupModal/ImpactGroupModal_model";
import { ImpactGroupModal } from "../../../../../../../../pages/change/projects/impactsView/ImpactGroupModal/ImpactGroupModal_view";
import I18n from "../../../../../../../localization/I18n";
import { GridSideModalTitle } from "../../../../utils/GridModalUtils";

export const IMPACTS_SIDEBAR_MODAL_CONFIG = (
  organisationId: number,
  projectId: number,
  impact: FP.Entities.IImpactGroup
) => {
  return {
    showClose: true,
    title: <GridSideModalTitle name={impact.name} field={I18n.t("phrases.locations")} />,
    content: <ImpactGroupModal model={new ImpactGroupModalModel(projectId, impact.id, organisationId)} />
  };
};
