import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import {
  InsightTabContent,
  InsightTabContentItem
} from "../../../../components/widgets/innerNavigation/InnerNavContent_view";
import I18n from "../../../../core/localization/I18n";
import { useCurrentOrganisationId } from "../../../../services/local/organisationContext/OrganisationContextModel";

export const OrganisationDashboardTabsContent: React.FC = observer(() => {
  const organisationId = useCurrentOrganisationId();
  const { projectId } = useParams<{ projectId: string }>();
  const location = useLocation();
  const startUrl = projectId
    ? `/organisations/${organisationId}/projects/${projectId}/organisations/visualisations`
    : `/organisations/${organisationId}/visualisations`;

  const getCurrentIndex = () => {
    if (
      location.pathname.endsWith("visualisations/heatmap/projects") ||
      location.pathname.endsWith("visualisations/heatmap/locations") ||
      location.pathname.endsWith("visualisations/heatmap/business-areas")
    ) {
      return 0;
    }
    if (location.pathname.endsWith("visualisations/sunburst")) {
      return 1;
    }
    if (location.pathname.endsWith("visualisations/timeline")) {
      return 2;
    }
    if (location.pathname.endsWith("visualisations/capacity")) {
      return 3;
    }
  };

  const items: InsightTabContentItem[] = [
    {
      label: <h2 className="mb-0">{I18n.t("phrases.heatmap")}</h2>,
      url: `${startUrl}/heatmap/projects`
    },
    {
      label: <h2 className="mb-0">{I18n.t("phrases.sunburst")}</h2>,
      url: `${startUrl}/sunburst`
    },
    {
      label: <h2 className="mb-0">{I18n.t("phrases.timeline")}</h2>,
      url: `${startUrl}/timeline`
    },
    {
      label: <h2 className="mb-0">{I18n.t("phrases.capacity")}</h2>,
      url: `${startUrl}/capacity`
    }
  ];

  const [currIndex, setCurrIndex] = useState(() => getCurrentIndex());

  useEffect(() => {
    setCurrIndex(getCurrentIndex());
    // eslint-disable-next-line
  }, [location]);

  return <InsightTabContent items={items} currentIndex={currIndex} className="h-auto" />;
});
