import { useState } from "react";
import { ReportFilterModel } from "./ReportFilter_model";
import { ReportFilterAddonsKeys } from "./ReportFilterAddon";
import { Button, ButtonTypes } from "../../components/ui/Button";
import { IconSymbols } from "@flightpath/coreui/dist/generated/IconSymbols";
import { Icon } from "../../components/ui/Icon";
import I18n from "../localization/I18n";
import { IF } from "../../components/hoc/If";
import { Hr } from "../../components/ui/Hr";

export const ReportFilterView: React.FC<{
  addons: ReportFilterAddonsKeys[];
  filterState: any;
  onFilterChange: (json: any) => Promise<any>;
  onClearClicked: () => void;
}> = ({ addons, onFilterChange, filterState, onClearClicked }) => {
  const [model] = useState(() => new ReportFilterModel(addons, onFilterChange, filterState));
  const [isFilterVisible, setIsFilterVisible] = useState(false);

  if (model.isLoading) {
    return null;
  }

  return (
    <div className="container-fluid">
      <div className="row ">
        <div className="col px-0 text-right">
          <Button className="px-2" type={ButtonTypes.LINK} onClick={() => setIsFilterVisible(!isFilterVisible)}>
            <Icon symbol={IconSymbols.Filter} className="mr-2" />
            {I18n.t("phrases.filter")}
          </Button>
          <Button
            className="px-2"
            type={ButtonTypes.LINK}
            onClick={() => {
              model.clearFilters();
              onClearClicked();
            }}
          >
            <Icon symbol={IconSymbols.ClearFilter} className="mr-2" />
            {I18n.t("phrases.clearFilters")}
          </Button>
        </div>
      </div>
      <IF condition={isFilterVisible}>
        <Hr />
        <div className="row">
          <div className="col px-0 text-right">{model.addons.map(e => e.render())}</div>
        </div>
      </IF>
    </div>
  );
};
