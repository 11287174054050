import { action, makeObservable, observable } from "mobx";
import { ButtonTypes } from "../../../../../../components/ui/Button";
import I18n from "../../../../../../core/localization/I18n";
import ToasterService, { IToasterService } from "../../../../../../core/toaster/ToasterService";
import { TOASTER_TOAST_TIME } from "../../../../../../core/toaster/Toaster_model";
import { IUiAction, UiActionRenderers } from "../../../../../../core/uiAction/IUiAction";
import OrganisationSettingsApi, {
  OrganisationSettingsApi as IOrganisationSettingsApi
} from "../../../../../../services/api/v2/organisationSettings/OrganisationSettings.api";
import OrganisationsApi, {
  OrganisationsApi as IOrganisationsApi
} from "../../../../../../services/api/v2/organisations/Organisations.api";
import UsersApi, { UsersApi as IUsersApi } from "../../../../../../services/api/v2/users/Users.api";
import { SingleFormModel } from "../../../../forms/singleFormModel/SingleForm_model";
import { GetEditUserFormFields, GetEmailFormFields, GetInviteUserFormFields } from "./UserInviteForm_fields";
import { IModalContextModel } from "../../../../../../core/modalZ/context/IModalContext";
import ModalContext from "../../../../../../core/modalZ/context/ModalContext";

export enum USER_INVITE_SECTION {
  EDIT_USER,
  NEW_USER,
  SEARCH_USER
}

export class UserInviteFormModel {
  emailFormModel: SingleFormModel;
  inviteUserFormModel: SingleFormModel;
  editUserFormModel: SingleFormModel;
  organisationsProvider: IOrganisationsApi;
  organisationSettingsProvider: IOrganisationSettingsApi;
  organisationId: number;
  organisation: FP.Entities.IOrganisation;
  usersProvider: IUsersApi;
  user: any;
  @observable section: USER_INVITE_SECTION = USER_INVITE_SECTION.SEARCH_USER;
  modalService: IModalContextModel;
  loadUsers: () => void;
  @observable permittedDomains: string[];
  toasterService: IToasterService;

  constructor(organisation: FP.Entities.IOrganisation, loadUsers: () => void) {
    makeObservable(this);
    this.organisationId = organisation.id;
    this.organisation = organisation;
    this.modalService = ModalContext;
    this.usersProvider = UsersApi;
    this.organisationsProvider = OrganisationsApi;
    this.organisationSettingsProvider = OrganisationSettingsApi;
    this.toasterService = ToasterService;
    this.loadUsers = loadUsers;
    this.installEmailFormModel();
    this.permittedDomains = [];
    this.loadPermittedDomains();
  }

  @action
  setSection = (section: USER_INVITE_SECTION) => {
    this.section = section;
  };

  calculateSection = user => {
    this.user = user;
    if (user.isFound) {
      this.installEditUserFormModel(user);
      this.setSection(USER_INVITE_SECTION.EDIT_USER);
    } else if (!user?.isFound && user.email.length > 0) {
      this.installInviteFormModel(user.email);
      this.setSection(USER_INVITE_SECTION.NEW_USER);
    }
  };

  searchUsersByEmail = async res => {
    return await this.usersProvider.getUserByEmail(this.organisationId, res as FP.Entities.IUser);
  };

  installEditUserFormModel = (user: FP.Entities.IUser) => {
    let formFields = GetEditUserFormFields(this.organisation, user);
    this.editUserFormModel = new SingleFormModel();
    let actions: IUiAction<any>[] = [
      {
        id: "cancel",
        label: I18n.t("phrases.cancel"),
        onAction: async ev => {
          this.setSection(USER_INVITE_SECTION.SEARCH_USER);
        },
        componentProps: {
          type: ButtonTypes.OUTLINE_PRIMARY,
          className: "ml-auto"
        },
        rendersIn: UiActionRenderers.BUTTON
      },
      {
        id: "save",
        label: I18n.t("phrases.inviteUser"),
        onAction: async ev => {
          let res = await this.editUserFormModel.submit();
          if (res) {
            res = { ...res, user };
            let permRes = await this.organisationsProvider.editUserPermission(
              this.organisation.id,
              user.sub,
              res.permissionScope
            );

            if (permRes.code === 201) {
              this.toasterService
                .showSuccessToast(TOASTER_TOAST_TIME.SLOW, "right", true)
                .setActionAlignedTitle(
                  <h3 className="toaster__success-review-title">{I18n.t("phrases.userCreatedHeading")}</h3>
                )
                .setContent(<span>{I18n.t("phrases.userCreatedSuccessfully", { item: user.email })}</span>)
                .startTimer(TOASTER_TOAST_TIME.SLOW);
            }

            this.installEmailFormModel();
            this.setSection(USER_INVITE_SECTION.SEARCH_USER);
            this.loadUsers();
          }
        },
        componentProps: {
          type: ButtonTypes.PRIMARY,
          className: "ml-2"
        },
        rendersIn: UiActionRenderers.BUTTON
      }
    ];

    this.editUserFormModel.formFields = formFields;
    this.editUserFormModel.actions = actions;
  };

  installEmailFormModel = () => {
    let formFields = GetEmailFormFields(this.organisation);
    this.emailFormModel = new SingleFormModel();
    let actions: IUiAction<any>[] = [
      {
        id: "emailSearch",
        label: I18n.t("phrases.search"),
        onAction: async ev => {
          let res = await this.emailFormModel.submit();
          if (res) {
            res.EmailAddress = res.EmailAddress.toLowerCase();
            let getByEmail = await this.searchUsersByEmail(res);
            this.calculateSection(getByEmail.payload);
          }
        },
        componentProps: {
          type: ButtonTypes.PRIMARY,
          className: "ml-auto"
        },
        rendersIn: UiActionRenderers.BUTTON
      }
    ];
    this.emailFormModel.formFields = formFields;
    this.emailFormModel.actions = actions;
  };

  installInviteFormModel = (email: string) => {
    let formFields = GetInviteUserFormFields(this.organisation);
    this.inviteUserFormModel = new SingleFormModel();
    let actions: IUiAction<any>[] = [
      {
        id: "cancel",
        label: I18n.t("phrases.cancel"),
        onAction: ev => {
          this.modalService.hide();
        },
        componentProps: {
          type: ButtonTypes.OUTLINE_PRIMARY,
          className: "ml-auto"
        },
        rendersIn: UiActionRenderers.BUTTON
      },
      {
        id: "inviteAnother",
        label: I18n.t("phrases.inviteUser"),
        onAction: async ev => {
          let res = await this.inviteUserFormModel.submit();
          if (res) {
            res = { ...res, email };
            let orgRes = await this.organisationsProvider.addUser(this.organisationId, res as FP.Entities.IUser);
            if (orgRes.code === 201) {
              this.toasterService
                .showSuccessToast(TOASTER_TOAST_TIME.SLOW, "right", true)
                .setActionAlignedTitle(
                  <h3 className="toaster__success-review-title">{I18n.t("phrases.userCreatedHeading")}</h3>
                )
                .setContent(<span>{I18n.t("phrases.userCreatedSuccessfully", { item: email })}</span>)
                .startTimer(TOASTER_TOAST_TIME.SLOW);
            }
            this.installEmailFormModel();
            this.setSection(USER_INVITE_SECTION.SEARCH_USER);
            this.loadUsers();
          }
        },
        componentProps: {
          type: ButtonTypes.PRIMARY,
          className: "ml-2"
        },
        rendersIn: UiActionRenderers.BUTTON
      }
    ];

    this.inviteUserFormModel.formFields = formFields;
    this.inviteUserFormModel.actions = actions;
  };

  @action
  loadPermittedDomains = async () => {
    let result: string[] = [];

    var res = await this.organisationSettingsProvider.getOrganisationSetting(this.organisationId, 4);
    if (res.code === 200) {
      var t: string = res.payload.value;
      t.split("|").map(x => (x.length > 0 ? result.push(x) : null));
    }
    this.permittedDomains = result;
  };
}
