import React from "react";
import { Animations } from "../../../../core/util/Animations";
import { ProjectViewModel } from "./ProjectView_model";
import { ProjectSidePanel } from "./panels/ProjectSidePanel";
import { IModalConfig } from "../../../../core/modal/IModalService";

export const INFO_MODAL_CONFIG = (model: ProjectViewModel): IModalConfig => {
  return {
    showClose: true,
    title: <h1 className="mt-6">{model.project.name}</h1>,
    content: (
      <div className="container-fluid mt-2">
        <ProjectSidePanel parentModel={model} />
      </div>
    ),
    componentProps: {
      wrapWidth: "small",
      wrapHeight: "full",
      spacing: "small",
      position: "right",
      hasBorderRadius: true,
      panelProps: {}
    },
    animationOptions: {
      animateIn: Animations.SLIDE_IN_RIGHT,
      animateOut: Animations.SLIDE_OUT_RIGHT
    }
  };
};
