import { Link } from "react-router-dom";
import { ISdColDef } from "../../baseColumn/BaseColumnTypes";
import { BaseTextColumnBuilder } from "../../baseColumn/BaseTextColumn_builder";
import { REF_NUMBER_COLUMN_CONFIG } from "./RefNumberColumn_config";
import ModalContext from "../../../../../../modalZ/context/ModalContext";

interface IRefNumberColumnBuilder {}

export class RefNumberColumnBuilder extends BaseTextColumnBuilder implements IRefNumberColumnBuilder {
  constructor(columnOptions?: ISdColDef<any, any>) {
    super({ ...REF_NUMBER_COLUMN_CONFIG(), ...columnOptions });
  }

  withLinkCell = (urlCb: (id: number | string) => string, field: string, idField: string = "id") => {
    this.setColumnOptions({
      cellRenderer: params => {
        const itemId = params.data[idField];
        const mainUrl = urlCb(itemId);
        return (
          <Link key={itemId} to={`${mainUrl}`} onClick={() => ModalContext.hide()}>
            <span className="hover-link">{params.data[field]}</span>
          </Link>
        );
      }
    });
    return this;
  };
}
