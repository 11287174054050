import { AxiosInstance, AxiosRequestConfig } from "axios";
import http from "../Http";
import { ExportableProjectApi } from "../exportableApi/ExportableProjectApiModel";

export class ProjectProcessRolesApi extends ExportableProjectApi<FP.Entities.IAction> {
  controller: string = "project-process-roles";

  constructor(http: AxiosInstance) {
    super(http, "project-process-roles");
  }

  getProjectProcessRoles = async (organisationId: number, projectId: number, config?: AxiosRequestConfig) => {
    let res = await this.http.get(`${this.url}/${organisationId}/projects/${projectId}/${this.controller}`, config);
    return res.data;
  };

  getDetailedById = async (
    organisationId: number,
    projectId: number,
    projectProcessJobRoleId: number,
    config?: AxiosRequestConfig
  ) => {
    let res = await this.http.get(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/detailed/${projectProcessJobRoleId}`,
      config
    );
    return res.data;
  };
}

const instance = new ProjectProcessRolesApi(http);
export default instance;
