import { FORM_COL } from "../../../../../constants";
import {
  ISearchTreePickerModel,
  ITreeDataItem
} from "../../../../forms/controls/searchTreePicker/ISearchTreePickerModel";
import { INIT_SEARCHTREEPICKER } from "../../../../forms/controls/searchTreePicker/SearchTreePicker_model";
import { generateFormFieldsFromJson } from "../../../../forms/helpers/FormFieldMappers";
import I18n from "../../../../localization/I18n";

export const getLocationsSidebarFormFields = (locationData: ITreeDataItem[], currentIds: number[]) => {
  if (currentIds) {
    locationData.forEach(e => {
      if (currentIds.indexOf(e.id) >= 0) {
        e.isChecked = true;
      }
    });
  } else {
    locationData.forEach(e => {
      if (e.parent === 0) {
        e.isChecked = true;
      }
    });
  }
  const locations: Partial<ISearchTreePickerModel> = {
    ...INIT_SEARCHTREEPICKER,
    key: "locations",
    value: locationData.filter(e => e.isChecked === true),
    options: locationData,
    isCascading: false,
    placeholder: I18n.t("placeholders.searchLocation"),
    fieldClassName: FORM_COL.FULL_WIDTH
  };

  let fields = [];
  fields.push(locations);

  return generateFormFieldsFromJson(fields);
};
