import { ButtonTypes } from "../../../../../../components/ui/Button";
import { Icon, IconSymbols } from "../../../../../../components/ui/Icon";
import { Panel } from "../../../../../../components/ui/Panel";
import I18n from "../../../../../localization/I18n";
import ModalContext from "../../../../../modalZ/context/ModalContext";
import { Animations } from "../../../../../util/Animations";
import { AudiencesPeople } from "./modals/AudiencesPeople/AudiencesPeople_view";
import { UnassignedStakeholdersGrid } from "./modals/UnassignedStakeholdersGrid/UnassignedStakeholdersGrid_view";

export const SHOW_AUDIENCE_DELETE_CONFIRM_MODAL = (
  audienceIds: number[],
  removeAudienceRange: (audienceIds: number[]) => Promise<any>
) => {
  return new Promise(resolve => {
    ModalContext.showConfirmDialog(
      <h1 className="mt-4">{I18n.t("phrases.confirm")}</h1>,
      <div className="container-fluid">
        <div className="row mb-3">
          <div className="col-12">
            <Icon symbol={IconSymbols.AlertCircle} className="mr-2" />
            {I18n.t("warnings.removeAudienceFromProject")}
          </div>
        </div>
        <div className="row">
          <div className="col">{I18n.t("phrases.confirmAudienceRangeRemove", { name: audienceIds.length + "" })}</div>
        </div>
      </div>,
      I18n.t("phrases.yes"),
      I18n.t("phrases.no"),
      {
        wrapWidth: "small",
        spacing: "small",
        position: "middle",
        panelProps: {
          background: Panel.PanelBackgrounds.BG_WHITE
        }
      },
      async () => {
        ModalContext.hide();
        await removeAudienceRange(audienceIds);
        resolve(true);
      },
      () => {
        ModalContext.hide();
      },
      ButtonTypes.DANGER
    );
  });
};

export const SHOW_ADD_PEOPLE_MODAL = (k, c) => {
  return {
    showClose: false,
    title: null,
    content: <UnassignedStakeholdersGrid audienceId={c.data.id} onAssignSuccess={ModalContext.hide} />
  };
};

export const SHOW_AUDIENCES_PEOPLE = (audienceIds: number[]) => {
  ModalContext.show({
    showClose: true,
    title: null,
    content: <AudiencesPeople audienceIds={audienceIds} />,
    componentProps: {
      wrapHeight: "default",
      wrapWidth: "large",
      position: "middle",
      className: "modal__process-template"
    },
    animationOptions: {
      animateIn: Animations.SLIDE_IN_UP,
      animateOut: Animations.SLIDE_OUT_DOWN
    }
  });
};
