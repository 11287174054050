import React from "react";
import { observable, action, makeObservable } from "mobx";
import { FormFieldModel } from "../../formField/FormField_model";
import { FormFieldType } from "../../formField/FormFieldTypes";
import { INIT_TEXT_FIELD } from "./TextField_init";
import { ITextFieldModel } from "./ITextFieldModel";
import { Input, InputProps } from "../../../../components/ui/_forms/Input";

export class TextFieldModel extends FormFieldModel<string, InputProps> implements ITextFieldModel {
  @observable value: string = "";
  placeholder: string = "";
  readonly type: FormFieldType = FormFieldType.Text;
  inputType: "text" | "password" | "file" | "textarea" | "hidden" = "text";
  borderStyle: "underline" = null;
  autoFocus: boolean = false;
  onKeyUp: (e: React.KeyboardEvent<any>) => void;
  onBlur: (e) => void;

  constructor(initOpts?: ITextFieldModel) {
    super(initOpts);
    makeObservable(this);
    if (initOpts) {
      this.value = initOpts.value || "";
      this.validate = initOpts.validate || INIT_TEXT_FIELD.validate;
      this.placeholder = initOpts.placeholder || INIT_TEXT_FIELD.placeholder;
      this.inputType = initOpts.inputType || INIT_TEXT_FIELD.inputType;
      this.borderStyle = initOpts.borderStyle || this.borderStyle;
      this.extractValue = initOpts.extractValue || this.extractValue;
      this.autoFocus = initOpts.autoFocus || INIT_TEXT_FIELD.autoFocus;
      this.onKeyUp = initOpts.onKeyUp || this.onKeyUp;
      this.onBlur = initOpts.onBlur || this.onBlur;
    }
  }

  @action.bound
  setValue(val: string): void {
    this.hasChanges = true;
    this.value = val;
    if (this.validateOnChange && this.validate) {
      this.validate(this.value);
    }
  }

  extractValue() {
    return this.value.trim ? this.value.trim() : this.value;
  }

  @action.bound
  setFieldValue(val: string): void {
    this.hasChanges = false;
    this.value = val;
  }

  validate: (...args: any[]) => any;

  @action.bound
  reset(): void {
    this.hasChanges = true;
    this.errorMessage = null;
    this.value = this.defaultValue || "";
  }

  renderComponent = () => {
    let props = {
      className: `form-control ${this.className || ""}`,
      id: this.key,
      onChange: (e: React.SyntheticEvent<any>) => {
        const val = e.currentTarget.value;
        this.setValue(val);
      },
      onKeyUp: this.onKeyUp,
      placeholder: this.placeholder,
      disabled: this.disabled,
      value: this.value,
      borderStyle: this.borderStyle,
      autoFocus: this.autoFocus,
      onBlur: this.onBlur,
      ref: this.ref
    };
    if (this.inputType === "textarea") {
      delete props.borderStyle;
      return <textarea {...props} className={`input ${props.className || ""}`} />;
    }
    return <Input type={this.inputType} {...props} />;
  };
}
