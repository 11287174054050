import React from "react";
import { makeArrayFromString } from "../../../core/util/Helpers";
import { UiSizes } from "../../../enums";
import { Pill, PillTypes } from "../../ui/Pill";

export interface GridPillsProps {
  text: string;
}

export const GridPills: React.FC<GridPillsProps> = ({ text }) => {
  const actionArray = makeArrayFromString(text, "|");
  return (
    <>
      {actionArray
        ? actionArray.map((i, e) => (
            <Pill className="mr-1 px-2" key={i + e} type={PillTypes.OUTLINE_PRIMARY} size={UiSizes.SM}>
              <span className="body-small">{i}</span>
            </Pill>
          ))
        : null}
    </>
  );
};
