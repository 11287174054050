import { action, makeObservable, observable } from "mobx";
import CsvHelper, { PARSE_CSV_FILE } from "../../../../services/local/csvHelper/CsvHelper";
import ImpactsApi, { ImpactsApi as IImpactsApi } from "../../../../services/api/v2/impacts/Impacts.api";
import ModalContext from "../../../../core/modalZ/context/ModalContext";
import { IModalContextModel } from "../../../../core/modalZ/context/IModalContext";

export enum ImpactsUploaderViews {
  Initial,
  IsUploading,
  IsNotOneFileError,
  IsUploadSuccessfull
}

export class ImpactsUploaderModel {
  organisationId: number;
  projectId: number;
  @observable viewState: ImpactsUploaderViews = ImpactsUploaderViews.Initial;
  @observable isUploading: boolean = false;
  @observable isOneFile: boolean = true;
  impactsProvider: IImpactsApi;
  modalService: IModalContextModel;

  constructor(organisationId: number, projectId: number) {
    makeObservable(this);
    this.organisationId = organisationId;
    this.impactsProvider = ImpactsApi;
    this.modalService = ModalContext;
    this.projectId = projectId;
  }

  @action
  processFiles = (files: FileList, event: React.DragEvent<HTMLDivElement>) => {
    if (files.length > 1) {
      this.viewState = ImpactsUploaderViews.IsNotOneFileError;
      this.isOneFile = false;
      return;
    }
    this.viewState = ImpactsUploaderViews.IsUploading;

    PARSE_CSV_FILE<FP.Entities.IImpact>(files[0], this.uploadImpacts);
  };

  uploadImpacts = async (impacts: FP.Entities.IImpact[]) => {
    await this.impactsProvider.addMultiple(this.organisationId, this.projectId, impacts);
    this.modalService.hide();
  };

  @action
  setViewState = (viewState: ImpactsUploaderViews) => (this.viewState = viewState);

  downloadTemplate = () => {
    CsvHelper.exportToCsv("impact-upload-template", [
      ["name", "description", "progressStatus", "startDate", "endDate", "impactLevel", "totalNoImpacted"],
      [
        "Example",
        "Delete this row before uploading",
        "Not Started | Completed | In Progress",
        "2024-12-31",
        "2024-01-01",
        "1 to 10",
        "9"
      ]
    ]);
  };
}
