import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { Async } from "react-async";
import { useNavigate, useParams } from "react-router-dom";
import { Button, ButtonTypes } from "../../../../components/ui/Button";
import { Icon, IconSymbols } from "../../../../components/ui/Icon";
import { Panel } from "../../../../components/ui/Panel";
import { PositionedSpinner } from "../../../../components/ui/PositionedSpinner";
import { ReviewButton } from "../../../../components/ui/ReviewButton";
import { TabGroup } from "../../../../components/ui/TabGroup";
import { CanEdit } from "../../../../contexts/permissions/PermissionHelpers";
import { PermissionFields } from "../../../../contexts/permissions/PermissionsTypes";
import { useFlightPathUser } from "../../../../core/auth_insight";
import I18n from "../../../../core/localization/I18n";
import { Animations } from "../../../../core/util/Animations";
import { isItemComplete } from "../../../../core/util/ReviewHelpers";
import { Enums } from "../../../../enums";
import { useCurrentOrganisationId } from "../../../../services/local/organisationContext/OrganisationContextModel";
import { CommentListView } from "../../comments/commentListView/CommentListView_view";
import { RevisionHistoryView } from "../../revisionHistory/RevisionHistory_view";
import { ImpactStakeholders } from "../ImpactStakeholders/ImpactStakeholders_view";
import { ImpactActions } from "../impactActions/ImpactActions_view";
import { ImpactDisplay } from "../impactDisplay";
import { ImpactExtendedViewModel } from "./ImpactExtendedView_model";

export interface ImpactExtendedViewProps {
  model?: ImpactExtendedViewModel;
}

const ImpactExtendedView: React.FunctionComponent<ImpactExtendedViewProps> = observer(({ model: m }) => {
  const authUser = useFlightPathUser();
  const { projectId, impactId } = useParams<{ projectId: string; impactId: string }>();
  const organisationId = useCurrentOrganisationId();
  const navigate = useNavigate();
  const [model] = useState(
    () => m || new ImpactExtendedViewModel({ authUser, projectId: +projectId, impactId: +impactId, organisationId })
  );
  const { impact } = model;

  useEffect(() => {
    return model.onUnmount;
  }, [model]);

  return (
    <Async promiseFn={model.load}>
      <Async.Loading>
        <PositionedSpinner />
      </Async.Loading>
      <Async.Resolved>
        {() => (
          <div className="data-extended-view pt-6">
            <div className="container-fluid data-extended-view__block data-extended-view__block--header mb-5">
              <div className="row">
                <div className="col p-2">
                  <Button size={Enums.UiSizes.MD} type={ButtonTypes.LINK} className="p-0" onClick={() => navigate(-1)}>
                    <Icon className="mr-2" symbol={IconSymbols.ChevronLeft} />
                    {I18n.t("phrases.back")}
                  </Button>
                </div>
              </div>
            </div>
            <div className="data-extended-view__block data-extended-view__block--wrapper">
              <div className={`data-extended-view__col container-fluid ${Animations.FADE_IN} speed-5`}>
                <CanEdit field={PermissionFields.IMPACTS} projectId={model.projectId}>
                  <div className="row mb-5">
                    <div className="col">
                      <ReviewButton
                        hasBeenReviewed={model.hasBeenReviewed}
                        isOutOfAction={isItemComplete(impact.progressStatus)}
                        onClick={model.showMarkReviewedModal}
                      />
                    </div>
                  </div>
                </CanEdit>

                <div className="row mb-4">
                  <div className="col">
                    <div className="d-flex">
                      <div>
                        <h1 className="mb-0" id="ImpactNameLabel">
                          {impact.name} | {I18n.t("phrases.details")}
                        </h1>
                        <h3 className="mb-0">{impact.refNumber}</h3>
                      </div>
                    </div>
                  </div>
                </div>
                <ImpactDisplay item={impact} />
              </div>
              <Panel.Panel
                background={Panel.PanelBackgrounds.BG_LIGHT}
                className={`data-extended-view__col data-extended-view__col--tabs ${Animations.FADE_IN} speed-5`}
              >
                <TabGroup
                  className="data-extended-view__tabs"
                  tabs={[
                    {
                      title: <h3 className="mb-0 text-center">{I18n.t("phrases.stakeholders")}</h3>,
                      children: <ImpactStakeholders model={model.impactStakeholderModel} />
                    },
                    {
                      title: <h3 className="mb-0 text-center">{I18n.t("phrases.actions")}</h3>,
                      children: <ImpactActions model={model.impactActionsModel} />
                    },
                    {
                      title: <h3 className="mb-0 text-center">{I18n.t("phrases.notes")}</h3>,
                      children: (
                        <div className="col">
                          <CommentListView model={model.commentViewModel} field={PermissionFields.IMPACTS} />
                        </div>
                      )
                    },
                    {
                      title: <h3 className="mb-0 text-center">{I18n.t("phrases.impactHistory")}</h3>,
                      children: <RevisionHistoryView model={model.revisionHistoryModel} />
                    }
                  ]}
                ></TabGroup>
              </Panel.Panel>
            </div>
          </div>
        )}
      </Async.Resolved>
    </Async>
  );
});

export { ImpactExtendedView };
