export enum Animations {
  FADE_IN = `animate__animated animate__fadeIn`,
  FADE_OUT = `animate__animated animate__fadeOut`,
  SLIDE_IN_RIGHT = `animate__animated animate__slideInRight`,
  SLIDE_OUT_RIGHT = `animate__animated animate__slideOutRight`,
  SLIDE_IN_LEFT = `animate__animated animate__slideInLeft`,
  SLIDE_OUT_LEFT = `animate__animated animate__slideOutLeft`,
  SLIDE_IN_UP = `animate__animated animate__slideInUp`,
  SLIDE_IN_DOWN = `animate__animated animate__slideInDown`,
  SLIDE_OUT_DOWN = `animate__animated animate__slideOutDown`,
  ZOOM_IN = `animate__animated animate__zoomIn`,
  FP_ZOOM_IN = `animate__animated fpZoomIn`
}
