import { AxiosInstance, AxiosRequestConfig } from "axios";
import { ActionField } from "../../../../enums";
import { IFlightPathApiResponse } from "../BaseApiModel";
import http from "../Http";
import { ExportableProjectApi } from "../exportableApi/ExportableProjectApiModel";

export class ProjectJobRolesApi extends ExportableProjectApi<FP.Entities.IAction> {
  controller: string = "project-job-roles";

  constructor(http: AxiosInstance) {
    super(http, "project-job-roles");
  }

  updateField = async (
    organisationId: number,
    projectId: number,
    actionId: number,
    textField: string,
    idField: number,
    dateField: Date,
    numericField: number,
    actionField: ActionField,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IAction>> => {
    let data = {
      TextField: textField,
      IdField: idField,
      DateField: dateField,
      NumericField: numericField
    };
    let res = await this.http.put(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${actionId}/${actionField}`,
      data,
      config
    );
    return res.data;
  };

  assignJobRoles = async (
    organisationId: number,
    projectId: number,
    jobRoleIds: number[],
    config?: AxiosRequestConfig
  ) => {
    let res = await this.http.post(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/assign-job-roles`,
      { jobRoleIds },
      config
    );
    return res.data;
  };

  assignProjectProcessRoles = async (
    organisationId: number,
    projectId: number,
    jobRoleIds: number[],
    projectProcessRoleIds: number[],
    config?: AxiosRequestConfig
  ) => {
    let res = await this.http.post(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/assign-process-roles`,
      { projectJobRoleIds: jobRoleIds, processRoleIds: projectProcessRoleIds },
      config
    );
    return res.data;
  };

  removeProjectProcessRole = async (
    organisationId: number,
    projectId: number,
    projectProcessRoleid: number,
    jobRoleId: number,
    config?: AxiosRequestConfig
  ) => {
    let res = await this.http.post(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/remove-process-role`,
      { jobRoleId: jobRoleId, processRoleId: projectProcessRoleid },
      config
    );
    return res.data;
  };

  deleteRange = async (
    organisationId: number,
    projectId: number,
    projectProcessJobRoleIds: number[],
    config?: AxiosRequestConfig
  ) => {
    let res = await this.http.post(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/delete-job-roles`,
      { projectJobRoleIds: projectProcessJobRoleIds },
      config
    );
    return res.data;
  };

  getProjectProcessRoles = async (
    organisationId: number,
    projectId: number,
    projectJobRoleId: number,
    config?: AxiosRequestConfig
  ) => {
    let res = await this.http.get(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${projectJobRoleId}/process-roles`,
      config
    );
    return res.data;
  };

  getUnselectedByOrgIdAndProcessJobRoleId = async (
    organisationId: number,
    projectId: number,
    projectProcessJobRoleId: number,
    config?: AxiosRequestConfig
  ) => {
    let res = await this.http.get(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${projectProcessJobRoleId}/process-roles/unselected`,
      config
    );
    return res.data;
  };
}

const instance = new ProjectJobRolesApi(http);
export default instance;
