import * as _ from "lodash";
import { action, makeObservable, observable } from "mobx";
import moment from "moment";
import * as React from "react";
import { ButtonTypes } from "../../../../../components/ui/Button";
import { Icon, IconSymbols } from "../../../../../components/ui/Icon";
import { Panel } from "../../../../../components/ui/Panel";
import { IPaginationModel, PaginationModel } from "../../../../../components/widgets/pagination/Pagination_model";
import { FilterModel, FilterOperator, IFilterAttribute, IFilterModel } from "../../../../../core/filter/Filter_model";
import ProgressIndicatorModel, {
  ProgressIndicatorModel as IProgressIndicatorModel
} from "../../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import I18n from "../../../../../core/localization/I18n";
import { ITableModel } from "../../../../../core/table/ITableModel";
import { TableModel } from "../../../../../core/table/Table_model";
import { IUiAction, UiActionRenderers } from "../../../../../core/uiAction/IUiAction";
import { Animations } from "../../../../../core/util/Animations";
import { BaseModel } from "../../../../../core/util/BaseModel";
import { convertStakeholderToName } from "../../../../../core/util/Helpers";
import { Enums } from "../../../../../enums";
import BusinessAreasApi, {
  BusinessAreasApi as IBusinessAreasApi
} from "../../../../../services/api/v2/businessAreas/BusinessAreas.api";
import OrganisationsApi, {
  OrganisationsApi as IOrganisationsApi
} from "../../../../../services/api/v2/organisations/Organisations.api";
import StakeholdersApi, {
  StakeholdersApi as IStakeholdersApi
} from "../../../../../services/api/v2/stakeholders/Stakeholders.api";
import { ExportService, IExportService } from "../../../../../services/local/export/ExportService";
import { SingleFormModel } from "../../../forms/singleFormModel/SingleForm_model";
import { SingleForm } from "../../../forms/singleFormModel/SingleForm_view";
import {
  getAudienceFormFields,
  getStakeholderFormFields
} from "../../../forms/stakeholder/StakeholderFormSection_data";
import { GetAudienceTableConfig, GetStakeholderTableConfig } from "./StakeholderSettings_config";
import { IFilteredOptions, INIT_FILTER_OPTIONS } from "../../../../../services/api/v2/filteredApi/FilteredApiModel";
import { IModalContextModel } from "../../../../../core/modalZ/context/IModalContext";
import ModalContext from "../../../../../core/modalZ/context/ModalContext";

export class StakeholdersSettingsModel extends BaseModel {
  filterOptions: IFilteredOptions = { ...INIT_FILTER_OPTIONS };
  tableModel: ITableModel<FP.Entities.IStakeholder>;
  tableAudienceModel: ITableModel<FP.Entities.IStakeholder>;
  modalService: IModalContextModel;
  organisationsProvider: IOrganisationsApi;
  stakeholdersProvider: IStakeholdersApi;
  businessAreaProvider: IBusinessAreasApi;
  exportIndService: IExportService<FP.Entities.IStakeholder>;
  exportAudService: IExportService<FP.Entities.IStakeholder>;
  httpProgress: IProgressIndicatorModel;
  paginationAudModel: IPaginationModel;
  filterAudModel: IFilterModel<FP.Entities.IStakeholder>;
  paginationIndModel: IPaginationModel;
  filterIndModel: IFilterModel<FP.Entities.IStakeholder>;
  filterExportModel: IFilterModel<FP.Entities.IStakeholder>;
  orgId: number;
  @observable searchQuery: string = "";
  @observable.ref audStakeholders: FP.Entities.IStakeholder[];
  @observable.ref indStakeholders: FP.Entities.IStakeholder[];
  @observable selectedFileId: number = 0;
  /**
   *
   */
  constructor(organisationId: number) {
    super();
    makeObservable(this);
    this.orgId = organisationId;
    this.organisationsProvider = OrganisationsApi;
    this.stakeholdersProvider = StakeholdersApi;
    this.modalService = ModalContext;
    this.businessAreaProvider = BusinessAreasApi;
    this.httpProgress = ProgressIndicatorModel;
    this.tableModel = new TableModel();
    this.tableAudienceModel = new TableModel();
    this.filterOptions.pageSize = 1000;

    this.installAudPagination();
    this.installAudFilter();

    this.installIndPagination();
    this.installIndFilter();

    this.installExportServices();
  }

  installExportFilter = () => {
    // this.filterExportModel = new FilterModel()
  };

  installExportServices = () => {
    this.exportIndService = new ExportService<FP.Entities.IStakeholder>(this.filterIndModel, {
      filename: `${_.kebabCase(I18n.t("entities.stakeholder"))}-${I18n.t(
        "phrases.export"
      ).toLowerCase()}-${moment().format("L")}.csv`,
      exportCb: async (columns: string[], filterOptions: Partial<IFilteredOptions>) => {
        return await this.stakeholdersProvider.exportData(this.orgId, columns, filterOptions);
      },
      fields: [
        {
          key: "name",
          label: I18n.t("table.name")
        },
        {
          key: "email",
          label: I18n.t("table.email")
        },
        {
          key: "businessArea",
          label: I18n.t("table.businessArea"),
          selector: (i: any) => i.businessArea
        },
        {
          key: "relationshipOwner",
          label: I18n.t("table.relationshipOwner"),
          selector: (i: any) => i.owner
        },
        {
          key: "projects",
          label: I18n.t("table.projects")
        }
      ]
    });

    this.exportAudService = new ExportService<FP.Entities.IStakeholder>(this.filterAudModel, {
      filename: `${_.kebabCase(I18n.t("entities.stakeholder"))}-${I18n.t(
        "phrases.export"
      ).toLowerCase()}-${moment().format("L")}.csv`,
      exportCb: async (columns: string[], filterOptions: Partial<IFilteredOptions>) => {
        return await this.stakeholdersProvider.exportData(this.orgId, columns, filterOptions, null);
      },
      fields: [
        {
          key: "name",
          label: I18n.t("table.name"),
          selector: i => convertStakeholderToName(i)
        },
        {
          key: "email",
          label: I18n.t("table.email"),
          selector: i => (i.stakeholderType === Enums.StakeholderType.INDIVIDUAL && i.email) || ""
        },
        {
          key: "businessArea",
          label: I18n.t("table.businessArea"),
          selector: i => i.businessArea?.name
        },
        {
          key: "relationshipOwner",
          label: I18n.t("table.relationshipOwner"),
          selector: i => i.owner.firstName + " " + i.owner.lastName
        },
        {
          key: "projects",
          label: I18n.t("table.projects"),
          selector: i => i.projectStakeholders.map(e => e.project.name).join("|")
        }
      ]
    });
  };

  installAudPagination = () => {
    this.paginationAudModel = new PaginationModel();
    this.paginationAudModel.setConfig({ onPageClick: this.loadAudPageData });
  };

  installAudFilter = () => {
    const config = {
      paginationModel: this.paginationAudModel,
      initOpts: {
        filterCb: async filterOptions => this.stakeholdersProvider.getFiltered(this.orgId, filterOptions, null)
      }
    };
    this.filterAudModel = new FilterModel(config);

    const organisationFilter: IFilterAttribute = {
      key: "organisationId",
      value: [this.orgId + ""],
      isHidden: true,
      operator: FilterOperator.EQUALS
    };

    const lifeCycleFilter: IFilterAttribute = {
      key: "lifecycleStatus",
      value: [Enums.LifecycleStatus.Active + ""],
      isHidden: true,
      operator: FilterOperator.EQUALS
    };
    const nameFilter: IFilterAttribute = {
      key: "FirstName",
      value: [],
      operator: FilterOperator.CONTAINS
    };
    const typeFilter: IFilterAttribute = {
      key: "StakeholderType",
      value: [Enums.StakeholderType.AUDIENCE.toString()],
      operator: FilterOperator.EQUALS,
      isHidden: true
    };

    this.filterAudModel.addFilter(nameFilter);
    this.filterAudModel.addFilter(organisationFilter);
    this.filterAudModel.addFilter(typeFilter);
    this.filterAudModel.addFilter(lifeCycleFilter);
    this.filterAudModel.addSort({
      key: "firstName",
      isAsc: true
    });
    this.filterAudModel.setConfig({
      onDataLoaded: d => this.setAudStakeholders(d)
    });
  };

  loadAudPageData = (index: number) => {
    this.filterAudModel.setConfig({ page: index });
    this.filterAudModel.loadData();
  };

  onSearchAudChange = (ev: React.SyntheticEvent) => {
    let e = ev.currentTarget as HTMLInputElement;
    this.filterAudModel.setFilterValue("FirstName", e.value);
  };

  resetAudNameFilter = () => {
    this.filterAudModel.setFilterValue("FirstName", "");
  };

  installIndPagination = () => {
    this.paginationIndModel = new PaginationModel();
    this.paginationIndModel.setConfig({ onPageClick: this.loadIndPageData });
  };

  installIndFilter = () => {
    const config = {
      paginationModel: this.paginationIndModel,
      initOpts: {
        filterCb: async filterOptions => this.stakeholdersProvider.getFiltered(this.orgId, filterOptions, null)
      }
    };
    this.filterIndModel = new FilterModel(config);

    const organisationFilter: IFilterAttribute = {
      key: "organisationId",
      value: [this.orgId + ""],
      isHidden: true,
      operator: FilterOperator.EQUALS
    };
    const nameFilter: IFilterAttribute = {
      key: "FirstName|LastName|Email",
      value: [],
      operator: FilterOperator.CONTAINS
    };
    const typeFilter: IFilterAttribute = {
      key: "StakeholderType",
      value: [Enums.StakeholderType.INDIVIDUAL.toString()],
      operator: FilterOperator.EQUALS,
      isHidden: true
    };

    const lifeCycleFilter: IFilterAttribute = {
      key: "lifecycleStatus",
      value: [Enums.LifecycleStatus.Active + ""],
      isHidden: true,
      operator: FilterOperator.EQUALS
    };

    this.filterIndModel.addFilter(organisationFilter);
    this.filterIndModel.addFilter(nameFilter);
    this.filterIndModel.addFilter(typeFilter);
    this.filterIndModel.addFilter(lifeCycleFilter);
    this.filterIndModel.addSort({
      key: "firstName,lastName",
      isAsc: true
    });
    this.filterIndModel.setConfig({
      formFields: null,
      onDataLoaded: d => this.setIndStakeholders(d)
    });
  };

  onIndSearchChange = (ev: React.SyntheticEvent) => {
    let e = ev.currentTarget as HTMLInputElement;
    this.filterIndModel.setFilterValue("FirstName|LastName|Email", e.value);
  };

  resetIndNameFilter = () => {
    this.filterIndModel.setFilterValue("FirstName|LastName|Email", "");
  };
  loadIndPageData = (index: number) => {
    this.filterIndModel.setConfig({ page: index });
    this.filterIndModel.loadData();
  };

  onMount = () => {
    let tableConfig = GetStakeholderTableConfig(this);
    this.tableModel.set(tableConfig);

    let audienceTableConfig = GetAudienceTableConfig(this);
    this.tableAudienceModel.set(audienceTableConfig);
  };

  onUnmount = () => {};

  loadStakeholders = async () => {
    await this.filterIndModel.loadData();
    this.setIndStakeholders(this.filterIndModel.data);
    await this.filterAudModel.loadData();
    this.setAudStakeholders(this.filterAudModel.data);
  };

  @action
  setAudStakeholders = (data: FP.Entities.IStakeholder[]) => {
    this.audStakeholders = data;
    this.tableAudienceModel.setData(data);
  };
  @action
  setIndStakeholders = (data: FP.Entities.IStakeholder[]) => {
    this.indStakeholders = data;
    this.tableModel.setData(data);
  };

  getStakeholderFormModel = (stakeholder: FP.Entities.IStakeholder) => {
    let formFields = getStakeholderFormFields(
      this.orgId,
      this.organisationsProvider,
      this.businessAreaProvider,
      this.stakeholdersProvider,
      stakeholder
    );
    let stakeholderFormModel = new SingleFormModel();

    let actions: IUiAction<any>[] = [
      {
        id: "action1",
        label: I18n.t("phrases.cancel"),
        onAction: ev => {
          this.modalService.hide();
        },
        componentProps: {
          type: ButtonTypes.LINK,
          className: "ml-auto"
        },
        rendersIn: UiActionRenderers.BUTTON
      },
      {
        id: "CreateStakeholderButton",
        label: I18n.t("phrases.save"),
        onAction: async ev => {
          let res = await stakeholderFormModel.submit();
          if (!res) return;
          this.modalService.hide();

          res.audienceCount = -1;

          this.httpProgress.showOverlay();
          if (stakeholder) {
            await this.stakeholdersProvider.update(this.orgId, stakeholder.id, res as FP.Entities.IStakeholder);
          } else {
            await this.stakeholdersProvider.create(this.orgId, res as FP.Entities.IStakeholder);
          }
          this.httpProgress.hideOverlay();

          this.loadStakeholders();
        },
        componentProps: {
          className: "ml-2"
        },
        rendersIn: UiActionRenderers.BUTTON
      }
    ];
    stakeholderFormModel.formFields = formFields;
    stakeholderFormModel.actions = actions;
    return stakeholderFormModel;
  };

  getAudienceFormModel = (audience: FP.Entities.IStakeholder) => {
    let formFields = getAudienceFormFields(this.orgId, this.organisationsProvider, audience);
    let audienceFormModel = new SingleFormModel();

    let actions: IUiAction<any>[] = [
      {
        id: "action1",
        label: I18n.t("phrases.cancel"),
        onAction: ev => {
          this.modalService.hide();
        },
        componentProps: {
          type: ButtonTypes.LINK,
          className: "ml-auto"
        },
        rendersIn: UiActionRenderers.BUTTON
      },
      {
        id: "CreateAudienceButton",
        label: I18n.t("phrases.save"),
        onAction: async ev => {
          let res = await audienceFormModel.submit();
          if (!res) return;

          res.lastName = res.firstName + "-last-name";
          res.email = Date.now() + "@example.com";
          res.isChange = false;
          if (audience) {
            await this.stakeholdersProvider.update(this.orgId, audience.id, res as FP.Entities.IStakeholder);
          } else {
            await this.stakeholdersProvider.create(this.orgId, res as FP.Entities.IStakeholder);
          }
          this.loadStakeholders();
          this.modalService.hide();
        },
        componentProps: {
          className: "ml-2"
        },
        rendersIn: UiActionRenderers.BUTTON
      }
    ];
    audienceFormModel.formFields = formFields;
    audienceFormModel.actions = actions;
    return audienceFormModel;
  };

  showStakeholderFormModal = (stakeholder?: FP.Entities.IStakeholder) => {
    const stakeholderFormModel = this.getStakeholderFormModel(stakeholder);
    return new Promise(resolve => {
      this.modalService.show({
        showClose: false,
        title: (
          <h4 className="mt-6 mb-5 d-inline-block c-pointer" onClick={() => this.modalService.hide()}>
            <Icon symbol={IconSymbols.ChevronLeft} size={Enums.UiSizes.SM} /> {I18n.t("phrases.closeWindow")}
          </h4>
        ),
        content: (
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col">
                <h1>{I18n.t(stakeholder ? "phrases.editPerson" : "phrases.addPerson")}</h1>
              </div>
            </div>

            <Panel.Panel hasBorderRadius={true} className="p-3 mb-4">
              <div className="row">
                <div className="col">
                  <SingleForm model={stakeholderFormModel} />
                </div>
              </div>
            </Panel.Panel>
          </div>
        ),
        componentProps: {
          wrapHeight: "full",
          wrapWidth: "small",
          position: "right"
        },
        animationOptions: {
          animateIn: Animations.SLIDE_IN_RIGHT,
          animateOut: Animations.SLIDE_OUT_RIGHT,
          speed: 5
        },
        actions: []
      });
    });
  };

  showAudienceFormModal = (audience?: FP.Entities.IStakeholder) => {
    const audienceFormModel = this.getAudienceFormModel(audience);

    return new Promise(resolve => {
      this.modalService.show({
        showClose: false,
        title: (
          <h4 className="mt-6 mb-5 d-inline-block c-pointer" onClick={() => this.modalService.hide()}>
            <Icon symbol={IconSymbols.ChevronLeft} size={Enums.UiSizes.SM} /> {I18n.t("phrases.closeWindow")}
          </h4>
        ),
        content: (
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col">
                <h1>{I18n.t(audience ? "phrases.editAudience" : "phrases.createAudience")}</h1>
                <p>
                  {I18n.t("phrases.stakAudExp")} {I18n.t("phrases.stakAudExample")}
                </p>
              </div>
            </div>
            <Panel.Panel hasBorderRadius={true} className="p-3 mb-4">
              <div className="row ">
                <div className="col">
                  <SingleForm model={audienceFormModel} />
                </div>
              </div>
            </Panel.Panel>
          </div>
        ),
        componentProps: {
          wrapHeight: "full",
          wrapWidth: "small",
          position: "right"
        },
        animationOptions: {
          animateIn: Animations.SLIDE_IN_RIGHT,
          animateOut: Animations.SLIDE_OUT_RIGHT,
          speed: 5
        },
        actions: []
      });
    });
  };

  showStakeholderConfirmDeleteModal = (stakeholder: FP.Entities.IStakeholder) => {
    return new Promise(resolve => {
      this.modalService.showConfirmDialog(
        <h1 className="mt-4">{I18n.t("phrases.confirm")}</h1>,
        <div className="container-fluid">
          <div className="row mb-3">
            <div className="col">
              <Icon symbol={IconSymbols.AlertCircle} className="mr-2" />
              {I18n.t("warnings.deleteStakeholder")}
            </div>
          </div>
          <div className="row">
            <div className="col">
              {I18n.t("phrases.confirmDelete", { name: convertStakeholderToName(stakeholder) })}
            </div>
          </div>
        </div>,
        I18n.t("phrases.yes"),
        I18n.t("phrases.no"),
        {
          wrapWidth: "small",
          spacing: "small",
          position: "middle",
          panelProps: {
            background: Panel.PanelBackgrounds.BG_WHITE
          }
        },
        async () => {
          this.modalService.hide();

          this.httpProgress.showOverlay();
          const res = await this.stakeholdersProvider.remove(this.orgId, stakeholder.id);
          this.httpProgress.hideOverlay();

          if (!res || res.isError) {
            return;
          }

          this.loadStakeholders();
          resolve(true);
        },
        () => {
          this.modalService.hide();
        },
        ButtonTypes.DANGER
      );
    });
  };
}
