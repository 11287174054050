import { SelectionChangedEvent } from "ag-grid-community";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { IF } from "../../../../components/hoc/If";
import { ButtonTypes, LinkButton } from "../../../../components/ui/Button";
import { Panel } from "../../../../components/ui/Panel";
import { PositionedSpinner } from "../../../../components/ui/PositionedSpinner";
import { useFlightPathUser } from "../../../../core/auth_insight";
import { GridView } from "../../../../core/grids/GridView_view";
import I18n from "../../../../core/localization/I18n";
import Pages from "../../../../routes/InsightRoutes";
import { useCurrentOrganisationId } from "../../../../services/local/organisationContext/OrganisationContextModel";
import { CountDisplay } from "../../projects/view/components/CountDisplay";
import { ReportingPie } from "../../projects/view/components/ReportingPie";
import { impactAndActionCompletionDataColoursArray } from "../impacts/ImpactPies";
import { ragColourArray } from "./ActionPies";
import { ActionsModel } from "./Actions_model";
import { Icon, IconSymbols } from "../../../../components/ui/Icon";

export const ActionsPage: React.FC<{ shouldShowSeeAllButton?: boolean }> = observer(({ shouldShowSeeAllButton }) => {
  const organisationId = useCurrentOrganisationId();
  const { projectId } = useParams();
  const authUser = useFlightPathUser();
  const [model] = useState(() => new ActionsModel(organisationId, +projectId, authUser));
  const percent = model.progressData ? Math.round(100 * (model.progressData[2].value / model.gridActions.length)) : 0;

  useEffect(() => {
    model.onMount();
    return model.onUnmount;
  }, [model]);

  return (
    <div className="container-fluid mt-5">
      <div className="row">
        <div className="col-12">
          <LinkButton type={ButtonTypes.LINK_BIG} href={Pages.dashboards.index.generateLink(organisationId)}>
            <Icon className="mr-2" symbol={IconSymbols.ChevronLeft} /> {I18n.t("phrases.goBack")}
          </LinkButton>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-6">
          <Panel.Panel hasBorder={true} background={Panel.PanelBackgrounds.BG_LIGHT} hasBorderRadius className=" p-3">
            <IF condition={model.isLoading}>
              <PositionedSpinner />
            </IF>
            <IF condition={!model.isLoading && model.gridActions.length === 0}>
              <h3>No actions assigned</h3>
            </IF>
            <IF condition={!model.isLoading}>
              <div className="row">
                <div className="col-5 p-0">
                  <div className="col p-0">
                    <CountDisplay
                      canView={true}
                      count={model.gridActions.length}
                      label={I18n.t("phrases.yourActions")}
                      permissionLabel={I18n.t("warnings.noActionAccessPanel")}
                    />
                  </div>
                </div>
                <div className="col-7 p-0">
                  <ReportingPie cls={"p-0"} data={model.pieData} colourArray={ragColourArray} />
                </div>
              </div>
            </IF>
          </Panel.Panel>
        </div>
        <div className="col-6">
          <Panel.Panel hasBorder={true} background={Panel.PanelBackgrounds.BG_LIGHT} hasBorderRadius className=" p-3">
            <IF condition={model.isLoading}>
              <PositionedSpinner />
            </IF>
            <IF condition={!model.isLoading && model.gridActions.length === 0}>
              <h3>No actions assigned</h3>
            </IF>
            <IF condition={!model.isLoading}>
              <div className="row">
                <div className="col-5 p-0">
                  <div className="col p-0">
                    <CountDisplay
                      canView={true}
                      count={`${percent}%`}
                      label={I18n.t("phrases.actionsProgress")}
                      permissionLabel={I18n.t("warnings.noActionAccessPanel")}
                      subtitle={I18n.t("phrases.actionsComplete")}
                    />
                  </div>
                </div>
                <div className="col-7 p-0">
                  <ReportingPie
                    cls={"p-0"}
                    data={model.progressData}
                    colourArray={impactAndActionCompletionDataColoursArray}
                  />
                </div>
              </div>
            </IF>
          </Panel.Panel>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-12 mb-4">
          <Panel.Panel
            className={`p-4 `}
            hasBorder={true}
            background={Panel.PanelBackgrounds.BG_LIGHT}
            hasBorderRadius={true}
          >
            <div className={`report-card report-card--md ${!shouldShowSeeAllButton ? "container-fluid mt-5" : ""}`}>
              <div className="row">
                <div className="col">
                  <h2>{I18n.t("phrases.yourActions")}</h2>
                </div>
              </div>
              <IF condition={model.isLoading}>
                <PositionedSpinner />
              </IF>
              <IF condition={!model.isLoading}>
                <GridView
                  context={{}}
                  data={model.gridActions}
                  users={[]}
                  onCellClicked={() => {}}
                  onCellEditModeChange={() => {}}
                  model={model.gridModel}
                  isLoading={model.gridModel.isLoading}
                  onGridReady={model.onGridReady}
                  onSelectionChanged={function (event: SelectionChangedEvent): void {}} // ref={filterRef}
                  // filterHasChangedFn={setHasFilters}
                  // isExternalFilterPresent={model.isExternalFilterPresent}
                  // doesExternalFilterPass={model.doesExternalFilterPass}
                />
                <IF condition={shouldShowSeeAllButton}>
                  <div className="row">
                    <div className="col mt-2">
                      <LinkButton
                        type={ButtonTypes.OUTLINE_PRIMARY}
                        href={Pages.dashboards.actions.generateLink(organisationId)}
                        className="float-right"
                      >
                        {I18n.t("phrases.seeAll")}
                      </LinkButton>
                    </div>
                  </div>
                </IF>
              </IF>
            </div>
          </Panel.Panel>
        </div>
      </div>
    </div>
  );
});
