import { action, makeObservable, observable } from "mobx";
import * as React from "react";
import { PercentCrop, PixelCrop } from "react-image-crop";
import { AvatarContent } from "../../../../components/ui/AvatarContent";
import { FileUploadWrapper } from "../../../../components/ui/FileUploadWrapper";
import { Enums } from "../../../../enums";
import { IModalContextModel } from "../../../modalZ/context/IModalContext";
import { FormFieldType } from "../../formField/FormFieldTypes";
import { INIT_FORM_FIELD } from "../../formField/FormField_init";
import { FormFieldModel } from "../../formField/FormField_model";
import { HTMLInputEvent, IImageCropperModel, IImageCropperResult } from "./IImageCropperModel";
import { ImageCropperFC } from "./ImageCropper_view";

export const INIT_IMAGE_CROPPER_FIELD: Partial<IImageCropperModel> = {
  ...INIT_FORM_FIELD,
  type: FormFieldType.ImageCropper
};

export class ImageCropperModel extends FormFieldModel<IImageCropperResult, any> implements IImageCropperModel {
  initialCrop: PixelCrop = {
    unit: "px",
    x: 0,
    y: 0,
    width: 0,
    height: 0
  };
  modalService: IModalContextModel;
  avatarLabel: string = "";
  orignalImageSource: string = "";
  imageSnap: IImageCropperResult;
  @observable inputValue: string = "";
  @observable value: IImageCropperResult = null;
  @observable crop: PixelCrop = this.initialCrop;
  @observable croppedImageUrl: string = "";
  onImageCropped: (value: IImageCropperResult) => void;

  constructor(initOpts: Partial<IImageCropperModel>) {
    super(initOpts as IImageCropperModel);
    makeObservable(this);
    if (initOpts) {
      if (!initOpts.modalService) {
        console.error("ImageCropperModel expects an object of IModalService ");
      }
      this.modalService = initOpts.modalService;
      this.onImageCropped = initOpts.onImageCropped || null;
      this.value = initOpts.value || this.value;
      this.imageSnap = initOpts.value || this.value;
      this.orignalImageSource = initOpts.orignalImageSource || this.orignalImageSource;
      this.avatarLabel = initOpts.avatarLabel || this.avatarLabel;
    }
  }

  loadFile = async (e: HTMLInputEvent) => {
    return new Promise(res => {
      if (e.target.files && e.target.files.length > 0) {
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          res(reader.result);
        });
        reader.readAsDataURL(e.target.files[0]);
      }
    });
  };

  @action
  setCrop = (crop: PixelCrop, percent: PercentCrop) => {
    this.crop = crop;
  };

  @action
  setCroppedImageResult = (imgResult: IImageCropperResult) => {
    this.croppedImageUrl = imgResult.url;
    this.imageSnap = imgResult;
  };

  showImageCropperModal = async (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setInputValue("");
    this.croppedImageUrl = "";
    this.crop = this.initialCrop;
    let image = await this.loadFile(e as any);
    this.orignalImageSource = image as string;
    let res = await this.modalService.showConfirmDialog(
      <h2 className="mt-5">Crop Image</h2>,
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <ImageCropperFC model={this} />
          </div>
        </div>
      </div>,
      "Update",
      "Cancel",
    );

    if (res) {
      this.onImageCropped && this.onImageCropped(this.value);
      this.setValue(this.imageSnap);
    }
  };

  validate: (...args: any[]) => any;

  @action
  setValue(val: Partial<IImageCropperResult>): void {
    this.value = { ...val } as IImageCropperResult;
  }

  setFieldValue(val: any): void {
    throw new Error("Method not implemented.");
  }

  extractValue() {
    return this.value;
  }

  @action
  setInputValue = value => {
    this.inputValue = value;
  };

  reset(): void {
    this.value = this.defaultValue || null;
  }

  renderComponent = (): React.ReactNode => {
    return (
      <FileUploadWrapper
        componentProps={{
          accept: ".jpg,.png,.jpeg",
          value: this.inputValue,
          onChange: this.showImageCropperModal
        }}
      >
        <AvatarContent
          avatarProps={{
            imgSrc: this.value.url,
            size: Enums.UiSizes.LG
          }}
        >
          <h5 className="mb-0 text-uppercase image-cropper--link-button">{this.avatarLabel}</h5>
        </AvatarContent>
      </FileUploadWrapper>
    );
  };
}
