import React, { useMemo } from "react";
import { PositionedSpinner } from "../../../../../../components/ui/PositionedSpinner";
import I18n from "../../../../../../core/localization/I18n";
import { ReportingPie } from "../../../../projects/Actions/ActionReportingView/TopActionsReportingBar/ReportingPie";

export const ActionRagsCard = ({ actionRagCounts, isLoading }) => {
  const ragColourArray = ["#F91E1E", "#F9AB1E", "#1AC541", "#58C8D1", "#BEBEBE"];
  const actionRagsTotal = useMemo(
    () => actionRagCounts?.reduce((prev, curr) => prev + curr.value, 0),
    [actionRagCounts]
  );

  return (
    <div className="col-4">
      {isLoading && <PositionedSpinner />}
      {!isLoading && (
        <ReportingPie
          data={actionRagCounts}
          metric={actionRagsTotal}
          colourArray={ragColourArray}
          title={I18n.t("visualisations.actionsRag")}
        />
      )}
    </div>
  );
};
