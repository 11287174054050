import { ISdColDef } from "./BaseColumnTypes";

export class ColumnOptionsBuilder {
  columnOptions: ISdColDef<any, any>;

  /**
   *
   */
  constructor(columnOptions: ISdColDef<any, any>) {
    this.columnOptions = columnOptions;
    this.init();
  }

  init = () => {
    if (!this.columnOptions.type) {
      this.columnOptions.type = [];
    }
  };

  setColumnOptions = (columnOptions: ISdColDef<any, any>) => {
    this.columnOptions = { ...this.columnOptions, ...columnOptions };
  };

  addType = (type: string) => {
    if (this.columnOptions.type.indexOf(type) >= 0) {
      return;
    } else if (this.columnOptions.type.indexOf(type) === -1) {
      (this.columnOptions.type as string[]).push(type);
    }
  };

  removeType = (type: string) => {
    if (this.columnOptions.type.indexOf(type) >= 0) {
      (this.columnOptions.type as string[]).splice(this.columnOptions.type.indexOf(type), 1);
      return;
    }
  };
}
