import { ISdColDef, ISdFilterDef } from "../../baseColumn/BaseColumnTypes";
import { BASE_COLUMN_CONFIG, BASE_FILTER_CONFIG } from "../../baseColumn/BaseColumn_config";

export const REF_NUMBER_COLUMN_CONFIG = (columnOptions?: ISdColDef<any, any>): ISdColDef<any, any> => ({
  ...BASE_COLUMN_CONFIG,
  field: "refNumber",
  headerName: "Header Ref Number",
  pinned: "left",
  editable: false,
  ...columnOptions
});

export const REF_NUMBER_FILTER_CONFIG: ISdFilterDef = {
  ...BASE_FILTER_CONFIG,
  filter: "agTextColumnFilter",
  filterParams: {
    buttons: ["clear"]
  }
};
