import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useCurrentOrganisationId } from "../../../../../../../../services/local/organisationContext/OrganisationContextModel";

import { observer } from "mobx-react-lite";
import { PositionedSpinner } from "../../../../../../../../components/ui/PositionedSpinner";
import { ImpactsGrid } from "../../../ImpactsGrid/ImpactsGridView_view";
import { AudienceImpactsModalModel } from "./AudienceImpactsModal_model";

export const AudienceImpactsModal: React.FC<{ selectedImpactIds: number[]; selectedAudienceIds: number[] }> = observer(
  ({ selectedImpactIds, selectedAudienceIds }) => {
    const { projectId } = useParams<{ projectId: string }>();
    const organisationId = useCurrentOrganisationId();
    const [model] = useState(
      () => new AudienceImpactsModalModel(organisationId, +projectId, selectedImpactIds, selectedAudienceIds)
    );

    useEffect(() => {
      model.onMount();

      //eslint-disable-next-line
    }, []);
    if (model.isLoading) return <PositionedSpinner />;
    return (
      <div className="processes-modal-view insight-tab-content__space-filler" style={{ height: "100%" }}>
        <ImpactsGrid
          data={model.data}
          gridToolbarType="link-modal"
          isLoading={model.isLoading}
          preSelectedItemIds={selectedImpactIds}
          onAssignSuccess={model.onAssignSuccess}
          columnDefs={[]}
        />
      </div>
    );
  }
);
