import {
  gridSideModalAnimationOpts,
  gridSideModalComponentProps,
  GridSideModalTitle
} from "../../../../../core/grids/GridSideModals";
import I18n from "../../../../../core/localization/I18n";
import ModalContext from "../../../../../core/modalZ/context/ModalContext";
import { ProcessAppsSidebar } from "../ProcessesGridView/modals/ProcessAppsSidebar/ProcessAppsSidebar_view";
import { ProcessRolesSidebar } from "../ProcessesGridView/modals/ProcessRolesSidebar/ProcessRolesSidebar_view";
import { ProjectProcessNotesSideBar } from "./modals/ProjectProcessNotesSidebar";
import { ProcessImpactGroupsSidebar } from "../ProcessListView/modals/ProcessImpactGroupsSidebar/ProcessImpactGroupsSidebar_view";
import { ProjectProcessTagsSidebar } from "./modals/projectProcessTagsSidebar/ProjectProcessTagsSidebar_view";
import { ProjectProcessTagsSidebarModel } from "./modals/projectProcessTagsSidebar/ProjectProcessTagsSidebar_model";

export interface IProcessGridModals {
  showProcessImpactGroupsModalFn?: (process: FP.Entities.IProjectProcess) => void;
  showProcessAppModalFn: (process: FP.Entities.IProjectProcess) => void;
  showProcessRoleModalFn: (process: FP.Entities.IProjectProcess) => void;
  showNotesModalFN?: (action: FP.Entities.IProcessSummary) => void;
  showTagsModalFn: (projectProcess: FP.Entities.IProjectProcess) => void;
}

export const getProcessGridModals = (organisationId: number, projectId: number): IProcessGridModals => {
  const modalService = ModalContext;

  const processImpactGroupsModal = (process: FP.Entities.IProjectProcess) => {
    modalService.show({
      showClose: true,
      title: <GridSideModalTitle name={process.name} field={I18n.t("phrases.impactGroups")} />,
      content: <ProcessImpactGroupsSidebar projectProcessId={process.id} />,
      componentProps: gridSideModalComponentProps,
      animationOptions: gridSideModalAnimationOpts
    });
  };
  const processAppModal = (process: FP.Entities.IProjectProcess) => {
    modalService.show({
      showClose: true,
      title: <GridSideModalTitle name={process.name} field={I18n.t("phrases.processApps")} />,
      content: <ProcessAppsSidebar process={process} />,
      componentProps: gridSideModalComponentProps,
      animationOptions: gridSideModalAnimationOpts
    });
  };

  const processRoleModal = (process: FP.Entities.IProjectProcess) => {
    modalService.show({
      showClose: true,
      title: <GridSideModalTitle name={process.name} field={I18n.t("phrases.processRoles")} />,
      content: <ProcessRolesSidebar process={process} />,
      componentProps: gridSideModalComponentProps,
      animationOptions: gridSideModalAnimationOpts
    });
  };

  const showNotesModal = (projectBusiness: FP.Entities.IProcessSummary) => {
    modalService.show({
      showClose: true,
      title: <GridSideModalTitle name={projectBusiness.processL3} field={I18n.t("phrases.notes")} />,
      content: (
        <ProjectProcessNotesSideBar
          projectId={projectId}
          organisationId={organisationId}
          projectProcessId={projectBusiness.id}
        />
      ),
      componentProps: gridSideModalComponentProps,
      animationOptions: gridSideModalAnimationOpts
    });
  };

  const showTagsModal = (projectProcess: FP.Entities.IProjectProcess) => {
    modalService.show({
      showClose: true,
      title: <GridSideModalTitle name={projectProcess.name} field={I18n.t("phrases.tags")} />,
      content: (
        <ProjectProcessTagsSidebar
          model={new ProjectProcessTagsSidebarModel(modalService.hide, organisationId, projectId, projectProcess.id)}
        />
      ),
      componentProps: gridSideModalComponentProps,
      animationOptions: gridSideModalAnimationOpts
    });
  };

  const ProcessGridModals: IProcessGridModals = {
    showProcessImpactGroupsModalFn: processImpactGroupsModal,
    showProcessAppModalFn: processAppModal,
    showProcessRoleModalFn: processRoleModal,
    showNotesModalFN: showNotesModal,
    showTagsModalFn: showTagsModal
  };

  return ProcessGridModals;
};
