import { observer } from "mobx-react";
import React, { useEffect, useRef, useState } from "react";
import { ProgrammeChangeTeamCapacityModel } from "./ProgrammeChangeTeamCapacity_model";
import { useCurrentOrganisationId } from "../../../../../services/local/organisationContext/OrganisationContextModel";
import { useFlightPathUser } from "../../../../../core/auth_insight/UserContext";
import I18n from "../../../../../core/localization/I18n";
import { Panel } from "../../../../../components/ui/Panel";
import "./_programme-change-team-capacity.scss";
import { PositionedSpinner } from "@flightpath/coreui/dist/ui/PositionedSpinner";
import { ProgrammeChangeTeamCapacityChart } from "./ChangeTeamCapacityChart/ProgrammeChangeTeamCapacityChart_view";

export interface Props {
  programmeId: number;
}

export const ProgrammeChangeTeamCapacity: React.FC<Props> = observer(props => {
  const authUser = useFlightPathUser();
  const isFirstRender = useRef(true);
  const organisationId = useCurrentOrganisationId();
  const [model] = useState(() => new ProgrammeChangeTeamCapacityModel(organisationId, authUser, props.programmeId));

  useEffect(() => {
    if (isFirstRender.current) {
      model.onMount();
      isFirstRender.current = false;
    }
    return () => {
      if (model.isComponentMounted) {
        model.onUnmount();
      }
    };
    //eslint-disable-next-line
  }, [model]);

  return (
    <div className="programme-capacity-view-wrapper">
      <Panel.Panel
        className={`py-4 px-4 my-4 `}
        hasBorder={true}
        background={Panel.PanelBackgrounds.BG_LIGHT}
        hasBorderRadius={true}
      >
        <div className="col">
          <div className="row">
            <div className="col-6">
              <h1>{I18n.get("phrases.changeTeamCapacity")}</h1>
            </div>
          </div>
        </div>
        {model.isLoading ? (
          <PositionedSpinner></PositionedSpinner>
        ) : (
          <ProgrammeChangeTeamCapacityChart
            data={[...model.changeTeamMetrics]}
            transformer={data => ({
              data,
              tooltipLabelFn: item => {
                return `${item.indexValue.split("-").slice(1).join("")}`;
              },
              indexBy: e => {
                return `${e.id} - ${e.firstName} - ${e.lastName}`;
              },
              keys: ["impactCount", "actionCount", "stakeholderCount", "projectCount"],
              colors: ["#19d3e0", "#faa082", "#ffc036", "#61a1fa"],
              legendLabel: e => {
                return I18n.t(`phrases.${e.id}`);
              },
              leftAxisLabel: "Total count",
              groupType: "grouped",
              chartMargin: { bottom: 80 },
              bottomAxisValueFormat: (val: string) => ({
                value: val.split("-").splice(1).join(" "),
                maxLineLength: 18,
                maxLines: 3,
                truncateAtChar: 15
              }),
              bottomAxisProps: { legendOffset: 70 },
              enableGridX: true
            })}
          ></ProgrammeChangeTeamCapacityChart>
        )}
      </Panel.Panel>
    </div>
  );
});
