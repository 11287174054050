import ModalContext from "../../../../../../../../modalZ/context/ModalContext";
import I18n from "../../../../../../../../localization/I18n";
import {
  GridSideModalAnimationOpts,
  GridSideModalComponentProps,
  GridSideModalTitle
} from "../../../../../utils/GridModalUtils";
import { EditDescriptionSidebar } from "./EditDescriptionSidebar_view";

export const showEditDescriptionModal = async (item: any, saveFn: (entityId: number, text: string) => Promise<any>) => {
  ModalContext.show({
    showClose: true,
    title: <GridSideModalTitle name={item.name} field={I18n.t("phrases.description")} />,
    content: (
      <EditDescriptionSidebar
        hideModal={() => {
          ModalContext.hide();
        }}
        saveFn={async (text: string) => {
          await saveFn(item.id, JSON.parse('"' + text + '"'));
          ModalContext.hide();
        }}
        item={item}
      />
    ),
    componentProps: GridSideModalComponentProps,
    animationOptions: GridSideModalAnimationOpts
  });
};
