import { AxiosInstance, AxiosRequestConfig } from "axios";
import { IFlightPathApiResponse } from "../BaseApiModel";
import { FilteredApiModel } from "../filteredApi/FilteredApiModel";
import http from "../Http";

export class OrganisationSettingsApi extends FilteredApiModel<FP.Entities.IOrganisationSetting> {
  controller: string = "organisations";

  constructor(http: AxiosInstance) {
    super(http, "organisations");
  }

  getOrganisationSetting = async (
    organisationId: number,
    settingId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IOrganisationSetting>> => {
    const url = `${this.url}${organisationId}/setting/${settingId}`;
    let res = await this.http.get(url);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.ICustomPropertyType[]>;
    }
  };

  getAllOrganisationSettings = async (
    organisationId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IOrganisationSetting[]>> => {
    const url = `${this.url}${organisationId}/settings`;
    let res = await this.http.get(url);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.IOrganisationSetting[]>;
    }
  };
}

const instance = new OrganisationSettingsApi(http);
export default instance;
