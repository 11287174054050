import I18n from "../../../../../../../localization/I18n";
import { GridSideModalTitle } from "../../../../utils/GridModalUtils";
import { StakeholderOnwersSidebar } from "./StakeholdersSidebar_view";

export const ACTION_STAKEHOLDER_OWNERS_MODAL_CONFIG = (
  organisationId: number,
  projectId: number,
  action: FP.Entities.IAction
) => {
  return {
    showClose: true,
    title: <GridSideModalTitle name={action.name} field={I18n.t("phrases.ownerNames")} />,
    content: <StakeholderOnwersSidebar actionId={action.id} />
  };
};
