import React from "react";
import { Tooltip } from "react-tippy";
import { Avatar } from "../../ui/Avatar";

interface ConnectedUserAvatarsProps {
  connectedUsers: FP.Entities.IUser[];
}

export const ConnectedUserAvatars: React.FC<ConnectedUserAvatarsProps> = ({ connectedUsers }) => {
  return (
    <div className="action-list-view__users d-flex justify-content-end">
      {connectedUsers.map((e, i) => {
        return (
          <Tooltip
            className="d-flex align-items-center"
            theme="light"
            key={"user" + i}
            followCursor
            html={<p className="mb-0">{`${e.firstName} ${e.lastName}`}</p>}
          >
            <Avatar className={"ml-1 avatar--border-" + (i + 1)} imgSrc={e.profileImageUrl} />
          </Tooltip>
        );
      })}
    </div>
  );
};
