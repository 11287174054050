import _ from "lodash";
import { action, makeObservable, observable } from "mobx";
import ImpactGroupsApi from "../../../../../services/api/v2/impactGroups/ImpactGroups.api";

export class ImpactGroupsReportingModel {
  organisationId: number;
  projectId: number;
  isEmpty: boolean;
  @observable isLoading: boolean = true;
  @observable barData: any[];
  impactGroupsProvider = ImpactGroupsApi;
  constructor(organisationId: number, projectId: number) {
    makeObservable(this);
    this.organisationId = organisationId;
    this.projectId = projectId;
  }

  onMount = async () => {
    await this.loadData();
  };

  loadData = async () => {
    let res = await this.impactGroupsProvider.getProjectGridData(this.organisationId, this.projectId);
    if (res.isError) return;

    this.setIsEmpty(res.payload);
    this.setBarData(res.payload);
    this.setIsLoading(false);
  };

  @action
  setIsLoading = (isLoading: boolean) => (this.isLoading = isLoading);

  @action
  setBarData = (data: any[]) => {
    this.barData = _.orderBy(
      data?.map(e => {
        return {
          id: e.id,
          name: e.name,
          index: e.id + "::" + e.name,
          impactCount: e.impacts ? e.impacts.split("|").length : 0
        };
      }),
      ["impactCount", "name"],
      ["asc"]
    );
  };

  @action
  setIsEmpty = (data: any[]) =>
    (this.isEmpty = data.length === 0 || data.filter(e => e.impacts === null).length === data.length);
}
