import { observable } from "mobx";
import PermissionsContext from "../../../../../../contexts/permissions/PermissionsContext";
import { GetGridFns } from "../../../../../../core/grids/GridFunctions";
import { IGridModel } from "../../../../../../core/grids/IGridModel";
import { IModalContextModel } from "../../../../../../core/modalZ/context/IModalContext";
import ModalContext from "../../../../../../core/modalZ/context/ModalContext";
import { EntityTypes } from "../../../../../../enums";
import BenefitApi, { BenefitApi as IBenefitApi } from "../../../../../../services/api/v2/benefits/Benefits.api";
import BenefitsHub, { BenefitsHub as IBenefitsHub } from "../../../../../../services/hubs/BenefitsHub/Benefits_hub";
import GridToastService from "../../../../../../services/local/gridToastService/GridToastService";
import { IGridToastService } from "../../../../../../services/local/gridToastService/IGridToastService";
import { GetBenefitsGridColumns } from "./BenefitsGrid_columns";

export class BenefitsGridModel implements IGridModel {
  modalService: IModalContextModel;
  organisationId: number;
  projectId: number;
  @observable isLoading: boolean = false;
  authUser: FP.Entities.IUser;
  benefitsHub: IBenefitsHub;
  gridToastService: IGridToastService;
  type: EntityTypes;
  benefitsProvider: IBenefitApi;

  constructor(organisationId: number, projectId: number, authUser: FP.Entities.IUser) {
    this.modalService = ModalContext;
    this.benefitsHub = BenefitsHub;
    this.gridToastService = GridToastService;
    this.benefitsProvider = BenefitApi;
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.authUser = authUser;
    this.type = EntityTypes.BENEFITS;
  }
  onUnmount: () => void;

  onMount = async () => {};

  getColumnConfig = () => {
    const canEditOrg = PermissionsContext.canEditOrganisation(this.organisationId);

    return GetBenefitsGridColumns({
      canEdit: canEditOrg,
      organisationId: this.organisationId,
      projectId: this.projectId,
      modalService: this.modalService,
      gridFns: GetGridFns(this.benefitsProvider as any, this.organisationId, this.projectId)
    });
  };
}
