import { AxiosInstance } from "axios";
import { FilteredProjectApiModel } from "../filteredApi/FilteredProjectApiModel";
import http from "../Http";

export class ProjectGroupPermissionsApi extends FilteredProjectApiModel<FP.Entities.IPermissions> {
  controller: string = "project-group-authorisation";
  urlWithOrg: string;
  /**
   *
   */
  constructor(http: AxiosInstance) {
    super(http, "project-group-authorisation");
  }
}

const instance = new ProjectGroupPermissionsApi(http);
export default instance;
