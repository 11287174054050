import { DisposableModel } from "../../../../../core/util/DisposableModel";

export class ImpactVisualisationsPlaceholderPageModel extends DisposableModel {
  organisationId: number;
  projectId: number;
  title: string;

  constructor(routeProps: any, projectId: number, organisationId: number, title: string) {
    super();
    this.projectId = projectId;
    this.organisationId = organisationId;
    this.title = title;
  }

  onMount = async () => {};

  onUnmount = () => {};
}
