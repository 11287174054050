import { BaseModel } from "../../../../core/util/BaseModel";
import { observable, action } from "mobx";
import { ImpactGroupFormSectionModel } from "../../forms/impactGroups/ImpactGroupFormSection_model";
import ProgressIndicatorModel, {
  ProgressIndicatorModel as IProgressIndicatorModel
} from "../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import ProjectsApi, { ProjectsApi as IProjectsApi } from "../../../../services/api/v2/projects/Projects.api";
import ImpactGroupsApi, {
  ImpactGroupsApi as IImpactGroupsApi
} from "../../../../services/api/v2/impactGroups/ImpactGroups.api";

export class AddImpactGroupModel extends BaseModel {
  impactGroupFormModel: ImpactGroupFormSectionModel;
  projectProvider: IProjectsApi;
  impactGroupProvider: IImpactGroupsApi;
  httpProgress: IProgressIndicatorModel;
  projectId: number;
  @observable.ref impactGroup: Partial<FP.Entities.IImpactGroup>;
  organisationId: number;
  navigate: any;
  /**
   *
   */
  constructor(projectId: number, organisationId: number, navigate) {
    super();
    this.projectId = projectId;
    this.navigate = navigate;
    this.projectProvider = ProjectsApi;
    this.impactGroupProvider = ImpactGroupsApi;
    this.httpProgress = ProgressIndicatorModel;

    this.impactGroup = {
      project: {
        id: this.projectId,
        name: "",
        slug: ""
      }
    };

    this.impactGroupFormModel = new ImpactGroupFormSectionModel({
      projectId,
      impactGroup: this.impactGroup,
      organisationId,
      navigate
    });
  }

  onMount = (orgId: number) => {
    this.organisationId = orgId;
  };

  onUnmount = () => {};

  @action
  createImpactGroup = async () => {
    const form = await this.impactGroupFormModel.formModel.submit();
    if (!form) return;
    form.projectId = this.projectId;

    this.httpProgress.showOverlay();
    const res = await this.impactGroupProvider.create(
      this.organisationId,
      this.projectId,
      form as FP.Entities.IImpactGroup
    );
    this.httpProgress.hideOverlay();

    if (!res || res.isError) {
      return;
    }

    this.navigate(`/organisations/${this.organisationId}/projects/${this.projectId}/impactGroups/${res.payload.id}`);
  };
}
