import React from "react";

export interface SideBarLayoutProps extends React.HTMLProps<HTMLDivElement> {
  direction?: "vertical" | "horizontal";
}

const SideBarLayout: React.FunctionComponent<SideBarLayoutProps> = props => {
  const direction = props.direction || "vertical";
  let sProps = { ...props };
  delete sProps.direction;
  return (
    <div {...sProps} className={`side-bar-layout side-bar-layout--${direction}`}>
      {React.Children.map(props.children, child => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, {
            ...child.props,
            className: `side-bar-layout__block ${child.props.className || ""}`
          });
        }
      })}
    </div>
  );
};

export { SideBarLayout };
