// export { ProjectNavElement } from "@flightpath/coreui/dist/ui/ProjectNavElement";
import React from "react";
import { Link } from "react-router-dom";
import { B } from "../../../core/util/BootstrapHelper";
import { UiSizes } from "../../../enums";
import { Icon, IconSymbols } from "../../ui/Icon";

export interface ProjectNavElementProps {
  name: string;
  url: string;
  hasImpactsAccess?: boolean;
  hasActionsAccess?: boolean;
  hasStakeholdersAccess?: boolean;
  hasProcessAccess?: boolean;
}

const ActiveIcon: React.FC<any> = ({ className, url, icon }) => {
  return (
    <Link className={className} to={url}>
      {icon}
    </Link>
  );
};

const InactiveIcon: React.FC<any> = ({ className, icon }) => {
  return <div className={`${className}`}>{icon}</div>;
};

export const NavIcon: React.FC<any> = ({ hasAccess, className, url, icon }) => {
  if (hasAccess) return <ActiveIcon className={className} url={url} icon={icon} />;
  return <InactiveIcon className={className} icon={icon} />;
};

export const ProjectNavElement: React.FC<ProjectNavElementProps> = ({
  name,
  hasImpactsAccess,
  hasActionsAccess,
  hasStakeholdersAccess,
  hasProcessAccess,
  url
}) => {
  const cls: string = B().bl("project-nav-element").bem;
  return (
    <div className={`${cls}`}>
      <Link to={url} style={{ flex: 1 }}>
        <div className="project-nav-element__name">
          <p className="mb-0">{name}</p>
        </div>
      </Link>
      <div className="project-nav-element__icons">
        <NavIcon
          className="project-nav-element__icon mr-1"
          hasAccess={true}
          url={url}
          icon={<Icon symbol={IconSymbols.Projects} size={UiSizes.SM} />}
        />
        <NavIcon
          className="project-nav-element__icon mr-1"
          hasAccess={true}
          url={`${url}/processes`}
          icon={<Icon symbol={IconSymbols.CurlyNodes} size={UiSizes.SM} />}
        />
        <NavIcon
          className="project-nav-element__icon mr-1"
          hasAccess={hasStakeholdersAccess}
          url={`${url}/stakeholders`}
          icon={<Icon symbol={IconSymbols.User} size={UiSizes.SM} />}
        />
        <NavIcon
          className="project-nav-element__icon mr-1"
          hasAccess={hasStakeholdersAccess}
          url={`${url}/audiences`}
          icon={<Icon symbol={IconSymbols.Stakeholders} size={UiSizes.SM} />}
        />
        <NavIcon
          className="project-nav-element__icon mr-1"
          hasAccess={hasImpactsAccess}
          url={`${url}/impacts`}
          icon={<Icon symbol={IconSymbols.Impacts} size={UiSizes.SM} />}
        />
        <NavIcon
          className="project-nav-element__icon mr-1"
          hasAccess={hasActionsAccess}
          url={`${url}/actions`}
          icon={<Icon symbol={IconSymbols.Actions} size={UiSizes.SM} />}
        />
      </div>
    </div>
  );
};
