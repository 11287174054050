import { ButtonTypes, LinkButton } from "../../../../../components/ui/Button";
import { ErrorMessage } from "../../../../../components/ui/ErrorMessage";
import { Icon, IconSymbols } from "../../../../../components/ui/Icon";
import { Panel } from "../../../../../components/ui/Panel";
import I18n from "../../../../../core/localization/I18n";
import ModalContext from "../../../../../core/modalZ/context/ModalContext";
import { Animations } from "../../../../../core/util/Animations";
import { UiSizes } from "../../../../../enums";

export const USER_NOT_FOUND_MODAL_CONFIG = (returnUrl: string) => {
  return {
    showClose: false,
    title: (
      <ErrorMessage>
        <div className="d-flex flex-row justify-content-center align-items-center my-2">
          <Icon symbol={IconSymbols.Warning} />
          <h1 className="text-center m-0">{I18n.t("errors.genericError")}</h1>
        </div>
      </ErrorMessage>
    ),
    content: (
      <div className="col d-flex flex-column justify-content-center align-items-center">
        <span className="row text-center">{I18n.t("errors.orgUserNotFound")}</span>
        <LinkButton
          size={UiSizes.MD}
          type={ButtonTypes.LINK}
          className="row p-0 my-2"
          href={returnUrl}
          onClick={() => ModalContext.hide()}
        >
          <Icon className="mr-2" symbol={IconSymbols.ChevronLeft} />
          {I18n.t("phrases.back")}
        </LinkButton>
      </div>
    ),
    componentProps: {
      wrapWidth: "small",
      spacing: "small",
      position: "middle",
      hasBorderRadius: true,
      panelProps: {
        background: Panel.PanelBackgrounds.BG_WHITE
      }
    },
    animationOptions: {
      animateIn: Animations.SLIDE_IN_UP,
      animateOut: Animations.SLIDE_OUT_DOWN
    }
  };
};
