import { observer } from "mobx-react";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { PositionedSpinner } from "../../../../../../../components/ui/PositionedSpinner";
import { useCurrentOrganisationId } from "../../../../../../../services/local/organisationContext/OrganisationContextModel";
import { SingleForm } from "../../../../../../change/forms/singleFormModel/SingleForm_view";
import { ProjectProcessesTreeSidebarModel } from "./ProjectProcessesTreeSidebar_model";

export interface ProjectProcessesTreeSidebarProps {
  onAssignSuccess: (itemIds: number[]) => void;
  selectedItemIds: number[];
}

export const ProjectProcessesTreeSidebar: React.FC<ProjectProcessesTreeSidebarProps> = observer(
  ({ onAssignSuccess, selectedItemIds }) => {
    const organisationId = useCurrentOrganisationId();
    const { projectId } = useParams();
    const [model] = useState(
      () => new ProjectProcessesTreeSidebarModel(organisationId, +projectId, selectedItemIds, onAssignSuccess)
    );
    if (model.isLoading) return <PositionedSpinner />;

    return (
      <div className="container-fluid">
        <SingleForm model={model.formModel} />
      </div>
    );
  }
);
