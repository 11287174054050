import * as React from "react";
import I18n from "../../../../core/localization/I18n";
import { Tooltip, TooltipIcon } from "../../../../components/ui/Tooltip";
import { Panel } from "../../../../components/ui/Panel";
import { RenderXssSafeString } from "../../../../core/AntiXss/AntiXssHelper";
import { List } from "../../../../components/ui/List";
import { Hr } from "../../../../components/ui/Hr";
import { TooltipPositions } from "@flightpath/coreui/dist/ui/Tooltip";
import { Enums } from "../../../../enums";

export interface ImpactGroupDisplayProps {
  item: FP.Entities.IImpactGroup;
}

const ImpactGroupDisplay: React.FunctionComponent<ImpactGroupDisplayProps> = props => {
  const { item } = props;
  const impactLevelAverage = Math.round(parseFloat(item.impactLevels));

  return (
    <>
      <div className="row mb-3">
        <div className="col-12">
          <h5 className="mb-1">{I18n.t("table.description")}</h5>
          <div
            dangerouslySetInnerHTML={{
              __html: RenderXssSafeString(item.changeBenefitText) || I18n.t("phrases.noChangeBenefit")
            }}
          ></div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <Hr className="mb-0 mt-0" />
        </div>
      </div>
      <div className="row mb-5 mt-5">
        <div className="col-lg-4">
          <div className="d-flex align-items-start">
            <h5 className="mb-1 d-inline-block">{I18n.t("table.impactLevels")}</h5>
            <Tooltip
              shownElement={<TooltipIcon className="ml-2 mb-1" />}
              triggeredOn="hover"
              position={TooltipPositions.TOP_RIGHT}
              className="impact-group-display__tooltip"
              style={{ marginTop: "-4px" }}
            >
              <Panel.Panel
                background={Panel.PanelBackgrounds.BG_WHITE}
                className="p-3 ml-1 mb-3"
                hasBorderRadius={true}
                type={Panel.PanelTypes.OUTLINES}
              >
                <p className="mb-0">
                  <em>{I18n.t("phrases.highLevelImpactLevelCalcDescription")}</em>
                </p>
              </Panel.Panel>
            </Tooltip>
          </div>

          <p className="mb-0">
            {impactLevelAverage !== 0 ?
              `${Enums.Translator.ImpactLevel(impactLevelAverage)} (${impactLevelAverage})`
              : I18n.t("table.noInput")}
          </p>
        </div>
        <div className="col-lg-4">
          <h5 className="mb-1">{I18n.t("table.impactTypes")}</h5>
          <p className="mb-0">
            {item.nImpactTypes?.length ? (
              <List
                showMoreLabel={I18n.t("phrases.showMore", { num: item.nImpactTypes.length - 2 })}
                showLessLabel={I18n.t("phrases.showLess")}
                className="pl-0"
              >
                {item.nImpactTypes.map(e => (
                  <li key={e.id}>
                    <p className="mb-0">{e.name}</p>
                  </li>
                ))}
              </List>
            ) : (
              <p className="mb-0">{I18n.t("phrases.na")}</p>
            )}
          </p>
        </div>
        <div className="col-lg-4">
          <h5 className="mb-1">{I18n.t("table.locations")}</h5>
          {item.locations?.length ? (
            <List
              showMoreLabel={I18n.t("phrases.showMore", { num: item.locations.length - 2 })}
              showLessLabel={I18n.t("phrases.showLess")}
              className="pl-0"
            >
              {item.locations.map(e => (
                <li key={e.id}>
                  <p className="mb-0">{e.name}</p>
                </li>
              ))}
            </List>
          ) : (
            <p className="mb-0">{I18n.t("table.noInput")}</p>
          )}
        </div>
      </div>
    </>
  );
};

export { ImpactGroupDisplay };
