import { observer } from "mobx-react";
import React, { ForwardRefRenderFunction, useEffect, useImperativeHandle, useState } from "react";
import { useParams } from "react-router-dom";
import { useCurrentOrganisationId } from "../../../../../../../services/local/organisationContext/OrganisationContextModel";
import { IF } from "../../../../../../../components/hoc/If";
import { ImpactAssessmentChart } from "../../../../../../../components/ui/ImpactAssessmentChart";
import { Panel } from "../../../../../../../components/ui/Panel";
import { PositionedSpinner } from "../../../../../../../components/ui/PositionedSpinner";
import { IFlightPathApiResponse } from "../../../../../../../services/api/v2/BaseApiModel";
import { ImpactBarChartModel } from "./ImpactBarChart_model";

interface ImpactBarChartProps {
  dataLoaderFn: (organisationId: number, projectId: number) => Promise<IFlightPathApiResponse<any>>;
  heading: string;
  gapAnalysisClick?: any;
  drillThroughClick?: any;
  noDataLabel: string;
  onDataLoaded: (key: string, confidence: number) => void;
  chartKey: string;
}

const ImpactBarChartView: ForwardRefRenderFunction<unknown, ImpactBarChartProps> = (
  { heading, gapAnalysisClick, drillThroughClick, noDataLabel, dataLoaderFn, onDataLoaded, chartKey },
  ref
) => {
  const organisationId = useCurrentOrganisationId();
  const { projectId } = useParams<{ projectId: string; page: string }>();
  const [model, setModel] = useState(
    () => new ImpactBarChartModel(organisationId, +projectId, dataLoaderFn, onDataLoaded, chartKey)
  );
  const modelProjectId = model.projectId;

  useEffect(() => {
    if (`${modelProjectId}` !== projectId) {
      setModel(new ImpactBarChartModel(+organisationId, +projectId, dataLoaderFn, onDataLoaded, chartKey));
    }
  }, [projectId, organisationId, model, modelProjectId, dataLoaderFn, onDataLoaded, chartKey]);

  useEffect(() => {
    model.onMount();
    // eslint-disable-next-line
  }, []);

  useImperativeHandle(ref, () => ({
    loadData: () => model.updateData()
  }));

  const chartMargin = { top: 10, right: 0, bottom: 100, left: 30 };

  const keys = ["Unknown", "High", "Medium", "Low"];

  return (
    <Panel.Panel hasBorder hasBorderRadius background={Panel.PanelBackgrounds.BG_LIGHT} className="p-3 mt-4">
      <div style={{ width: "100%", height: 400, paddingBottom: 100 }}>
        <IF condition={model.isLoading}>
          <PositionedSpinner />
        </IF>
        <IF condition={!model.isLoading}>
          <ImpactAssessmentChart
            data={model.data}
            dataConfidence={model.dataConfidence}
            keys={keys}
            heading={heading}
            containerWidth={400}
            margin={chartMargin}
            onClick={drillThroughClick}
            gapAnalysisOnClick={gapAnalysisClick}
            noDataLabel={noDataLabel}
            bottomAxis={{ truncateTickAt: 10, tickRotation: 45 }}
          ></ImpactAssessmentChart>
        </IF>
      </div>
    </Panel.Panel>
  );
};
export const ImpactBarChart = observer(React.forwardRef(ImpactBarChartView));
