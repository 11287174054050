import React from "react";
import { observable, action, makeAutoObservable } from "mobx";
import { observer } from "mobx-react";

type Key = string;

type PortalDictionary = any;

const PortalContext = React.createContext<PortalDictionary>(null as any);

class UiPortalModel {
  @observable.ref portals: any = {};

  /**
   *
   */
  constructor() {
    makeAutoObservable(this);
  }

  get = (attr: string) => {
    return this.portals[attr];
  };

  @action
  set = (attr: string, children: React.ReactNode) => {
    this.portals[attr] = children;
  };
}

export const UiPortalsProvider: React.FC<ReactProps> = ({ children }) => {
  const [portals] = React.useState(() => new UiPortalModel());
  return <PortalContext.Provider value={portals}>{children}</PortalContext.Provider>;
};

export const UiPortal: React.FC<{ name: string } & ReactProps> = observer(
  ({ name, children }: { name: Key; children?: any }) => {
    const portalModel = React.useContext(PortalContext);
    return portalModel.get(name) || children || null;
  }
);

export const UiPortalContent: React.FC<{ name: string } & ReactProps> = ({ name, children }) => {
  const portalModel = React.useContext(PortalContext);

  React.useEffect(() => {
    if (portalModel.get(name)) {
      throw new Error(`Content for portal with name ${name} is already set`);
    }
    portalModel.set(name, children);

    return () => {
      portalModel.set(name, null);
    };
    // eslint-disable-next-line
  }, [name]);
  return null;
};
