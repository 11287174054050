import { observer } from "mobx-react";
import { useRef, useState } from "react";
import { Crop } from "react-image-crop";
import { useDebounceEffect } from "../../../../components/hooks/useDebounce";
import ImageCropper from "../../../../components/ui/ImageCropper";
import { canvasPreview } from "./CanvasPreview";
import { IImageCropperModel, IImageCropperResult } from "./IImageCropperModel";

var blobToBase64 = function (blob, callback) {
  var reader = new FileReader();
  reader.onload = function () {
    var dataUrl: any = reader.result;
    var base64 = dataUrl.split(",")[1];
    callback(base64);
  };
  reader.readAsDataURL(blob);
};

const initialCrop: Crop = {
  unit: "%",
  x: 0,
  y: 0,
  width: 100,
  height: 100
};

export const ImageCropperFC: React.FC<{ model: IImageCropperModel }> = observer(({ model }) => {
  const imgRef = useRef<HTMLImageElement>(null);
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<Crop>(initialCrop);
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const blobUrlRef = useRef("");

  useDebounceEffect(
    async () => {
      if (completedCrop?.width && completedCrop?.height && imgRef.current && previewCanvasRef.current) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop);
        makeClientCrop(completedCrop);
      }
    },
    100,
    [completedCrop]
  );

  function getImageUrl() {
    if (!previewCanvasRef.current) {
      throw new Error("Crop canvas does not exist");
    }

    return new Promise((resolve, reject) => {
      previewCanvasRef.current.toBlob(
        (blob: any) => {
          if (!blob) {
            //reject(new Error('Canvas is empty'));
            console.error("Canvas is empty");
            return;
          }
          blob.name = "newFile.jpeg";
          window.URL.revokeObjectURL(blobUrlRef.current);
          blobUrlRef.current = window.URL.createObjectURL(blob);
          blobToBase64(blob, base64 => {
            resolve({ url: blobUrlRef.current, base64, blob });
          });
        },
        "image/jpeg",
        1
      );
    });
  }

  const makeClientCrop = async crop => {
    if (imgRef.current && previewCanvasRef.current) {
      const croppedImageInfo = await getImageUrl();
      model.setCroppedImageResult(croppedImageInfo as IImageCropperResult);
    }
  };

  return (
    <div className="image-cropper-view">
      <ImageCropper
        crop={crop}
        ruleOfThirds
        aspect={1}
        onChange={(_, percentCrop) => {
          model.setCrop(_, percentCrop);
          setCrop(percentCrop);
        }}
        onComplete={c => {
          setCompletedCrop(c);
        }}
      >
        <img src={model.orignalImageSource} ref={imgRef} alt="" style={{ maxWidth: 700, maxHeight: 700 }} />
      </ImageCropper>

      <div className="mt-3">
        <div>Preview: </div>
        <div style={{ borderRadius: "50%", overflow: "hidden", width: "60px", height: "60px" }}>
          {!!completedCrop && (
            <canvas
              ref={previewCanvasRef}
              style={{
                objectFit: "contain",
                width: 60, //completedCrop.width,
                height: 60 //completedCrop.height
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
});
