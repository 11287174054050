import { ISmartTableHeaderItem } from "@flightpath/coreui/dist/widgets/smartTable/ISmartTable";
import React from "react";
import { IconSymbols } from "../../../../../../components/ui/Icon";
import { Dropdown, DropdownOption } from "../../../../../../components/ui/_forms/Dropdown";
import { GetI18nParsedList, PROJECT_PERMISSION_SCOPE_OPTIONS } from "../../../../../../constants";
import I18n from "../../../../../../core/localization/I18n";
import { UiActionRenderers } from "../../../../../../core/uiAction/IUiAction";
import { Enums, UiSizes } from "../../../../../../enums";
import { AddSmartTableColumnSort } from "../../../../../../services/local/smartTableViewManager/utils";
import { UserPermissionsToggle } from "../UserPermissionsToggle";
import { IndividualUserPermissionsModel } from "./IndividualUsersPermissions_model";

export const GetIndividualUserPermissionsColumns = (
  organisationId: number,
  parentModel: IndividualUserPermissionsModel
) => {
  return {
    actions: [
      {
        id: "action2",
        label: I18n.t("phrases.delete"),
        onAction: async (ev, row) => {
          await parentModel.showConfirmDeleteModal(row);
        },
        componentProps: {
          className: "px-2",
          type: "link",
          symbol: IconSymbols.Trash
        },
        rendersIn: UiActionRenderers.BUTTON_ICON
      }
    ],
    onRowClick: () => { },
    colHeaders: [
      {
        id: "systemUser.firstName",
        content: <h5 className="mb-0">{I18n.t("table.user")}</h5>,
        selector: (obj: FP.Entities.IUser) => {
          return <p className="mb-0">{obj.firstName + " " + obj.lastName}</p>;
        },
        columnWidthSize: UiSizes.XS,
        action: function (header: ISmartTableHeaderItem) {
          AddSmartTableColumnSort(header, parentModel.filterModel);
        },
        showSortIcons: true
      },
      {
        id: "systemUser.email",
        content: <h5 className="mb-0">{I18n.t("table.email")}</h5>,
        selector: (obj: FP.Entities.IUser) => {
          return <p className="mb-0 text-break">{obj.email}</p>;
        },
        action: function (header: ISmartTableHeaderItem) {
          AddSmartTableColumnSort(header, parentModel.filterModel);
        },
        columnWidthSize: UiSizes.XS,
        showSortIcons: true
      },
      {
        id: "permissionScope",
        content: <h5 className="mb-0">{I18n.t("table.permissionScope")}</h5>,
        selector: (obj: FP.Entities.IUserPermission) => {
          return (
            <Dropdown
              items={GetI18nParsedList(I18n, PROJECT_PERMISSION_SCOPE_OPTIONS)}
              value={{ label: I18n.t(Enums.Translator.PermissionScope(obj.permissionLevel)) }}
              placeholder={I18n.t("placeholders.selectPermissionLevel")}
              onItemSelected={e => {
                let item = { ...obj };
                item.permissionLevel = e.key;
                parentModel.updateUserPermission(item);
              }}
              selector="label"
            >
              <DropdownOption label="" />
            </Dropdown>
          );
        },
        action: function (header: ISmartTableHeaderItem) {
          AddSmartTableColumnSort(header, parentModel.filterModel);
        },
        showSortIcons: true,
        productTourText: I18n.t("tours.permissionsProjectUsersPermissionLevel")
      },
      {
        id: "projectPermissionScope",
        content: <h5 className="mb-0">{I18n.t("table.project")}</h5>,
        selector: (obj: any) => {
          return (
            <UserPermissionsToggle
              key={`pr-${obj.id}`}
              onChange={(isChecked: boolean) => {
                let item = { ...obj };
                item.hasProjectAccess = isChecked;

                // If the project access is unchecked then uncheck all the toggles
                if (isChecked === false) {
                  item.hasActionAccess = false;
                  item.hasImpactAccess = false;
                  item.hasStakeholderAccess = false;
                }

                parentModel.updateUserPermission(item);
              }}
              isChecked={obj.hasProjectAccess}
            />
          );
        },
        productTourText: I18n.t("tours.permissionsProjectPermissions")
      },
      {
        id: "stakeholderPermissionScope",
        content: <h5 className="mb-0">{I18n.t("table.stakeholders")}</h5>,
        selector: (obj: any) => {
          return (
            <UserPermissionsToggle
              key={`s-${obj.id}`}
              onChange={(isChecked: boolean) => {
                let item = { ...obj };
                item.hasStakeholderAccess = obj.hasProjectAccess ? isChecked : false;
                parentModel.updateUserPermission(item);
              }}
              isChecked={obj.hasStakeholderAccess}
              disabled={!obj.hasProjectAccess}
            />
          );
        },
        productTourText: I18n.t("tours.permissionsStakeholderPermissions")
      },
      {
        id: "impactPermissionScope",
        content: <h5 className="mb-0">{I18n.t("table.impacts")}</h5>,
        selector: (obj: any) => {
          return (
            <UserPermissionsToggle
              key={`i-${obj.id}`}
              onChange={(isChecked: boolean) => {
                let item = { ...obj };
                item.hasImpactAccess = obj.hasProjectAccess ? isChecked : false;
                parentModel.updateUserPermission(item);
              }}
              isChecked={obj.hasImpactAccess}
              disabled={!obj.hasProjectAccess}
            />
          );
        },
        productTourText: I18n.t("tours.permissionsImpactPermissions")
      },
      {
        id: "actionPermissionScope",
        content: <h5 className="mb-0">{I18n.t("table.actions")}</h5>,
        selector: (obj: any) => {
          return (
            <UserPermissionsToggle
              key={`m-${obj.id}`}
              onChange={(isChecked: boolean) => {
                let item = { ...obj };
                item.hasActionAccess = obj.hasProjectAccess ? isChecked : false;
                parentModel.updateUserPermission(item);
              }}
              isChecked={obj.hasActionAccess}
              disabled={!obj.hasProjectAccess}
            />
          );
        },
        productTourText: I18n.t("tours.permissionsActionPermissions")
      }
    ]
  };
};
