import "./_button-dropdown.scss";
import React from "react";
import { Button, ButtonProps } from "../Button";
import { Tooltip } from "../Tooltip";

import { IF } from "../../hoc/If";
import { Icon, IconSymbols } from "../../ui/Icon";
import { Tooltip as Tippy } from "react-tippy";
import { TooltipPositions } from "@flightpath/coreui/dist/ui/Tooltip";
import { Panel } from "../Panel";
import { UiActionComponent } from "../../../core/uiAction/UiAction";
import { UiSizes } from "../../../enums";
import { ReactNode } from "react";

// Temporary fix for children prop incompatibility issue with react-tippy
declare module "react-tippy" {
  export interface TooltipProps {
    children?: ReactNode;
  }
}

export interface ButtonIconDropdownProps extends ReactProps {
  iconSymbol: IconSymbols;
  buttonProps: ButtonProps;
  tooltipWidth?: number;
  actions: any;
}
export const ButtonIconDropdown: React.FC<ButtonIconDropdownProps> = props => {
  return (
    <div className="button-dropdown" style={{ zIndex: 12 }}>
      <Tooltip
        triggeredOn="click"
        position={TooltipPositions.BOTTOM_LEFT}
        shownElement={
          props.children ? (
            <Tippy
              position={"bottom"}
              html={
                <Panel.Panel background={Panel.PanelBackgrounds.BG_WHITE} hasShadow hasBorderRadius className="p-2">
                  {props.children}
                </Panel.Panel>
              }
            >
              <Button {...props.buttonProps}>
                <Icon symbol={props.iconSymbol} size={UiSizes.MD} className="button-dropdown__icon" />
                <Icon symbol={IconSymbols.ChevronDown} size={UiSizes.SM} style={{ marginTop: "8px" }} />
              </Button>
            </Tippy>
          ) : (
            <Button {...props.buttonProps}>
              <Icon symbol={props.iconSymbol} className="button-dropdown__icon" />
              <Icon symbol={IconSymbols.ChevronDown} style={{ marginTop: "8px" }} />
            </Button>
          )
        }
      >
        <IF condition={!props.buttonProps.isDisabled}>
          <Panel.Panel
            background={Panel.PanelBackgrounds.BG_WHITE}
            hasBorderRadius
            hasShadow
            className="p-2"
            style={{ width: props?.tooltipWidth > 0 ? `${props.tooltipWidth}px` : "160px" }}
          >
            <IF condition={props.actions.length === 0}>
              <p className="mb-0">No actions</p>
            </IF>
            {props.actions.map(e => {
              return <UiActionComponent key={e.id} action={e} />;
            })}
          </Panel.Panel>
        </IF>
      </Tooltip>
    </div>
  );
};
