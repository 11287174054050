import { BaseModel } from "../../../../../core/util/BaseModel";
import { observable, action } from "mobx";
import { Enums } from "../../../../../enums";
import ImpactGroupsApi, {
  ImpactGroupsApi as IImpactGroupsApi
} from "../../../../../services/api/v2/impactGroups/ImpactGroups.api";

export class ImpactStakeholderListViewModel extends BaseModel {
  impactGroupProvider: IImpactGroupsApi;
  @observable isSearchMode: boolean = false;
  @observable searchValue: string = "";
  @observable.ref stakeholders: FP.Entities.IStakeholder[] = [];
  @observable impactGroupStakeholders;
  @observable.ref filteredStakeholders: FP.Entities.IStakeholder[] = [];
  projectId: number;
  impactGroupId: number;

  constructor(projectId: number, impactGroupId: number) {
    super();
    this.impactGroupId = impactGroupId;
    this.projectId = projectId;
    this.impactGroupProvider = ImpactGroupsApi;
  }

  onMount = () => {};

  onUnmount = () => {
    this.resetSearch();
  };

  @action
  updateSearchValue = (e: React.FormEvent<HTMLInputElement>) => {
    this.searchValue = e.currentTarget.value;
    this.filterStakeholders();
  };

  @action
  showSearchMode = () => {
    this.isSearchMode = true;
  };

  @action
  hideSearchMode = () => {
    this.isSearchMode = false;
  };

  @action
  resetSearch = () => {
    this.searchValue = "";
    this.filterStakeholders();
    this.hideSearchMode();
  };

  @action
  filterStakeholders = () => {
    if (this.searchValue) {
      const lowerSearch = this.searchValue.toLowerCase();
      this.filteredStakeholders = this.stakeholders.filter(stakeholder => {
        const name =
          stakeholder.stakeholderType === Enums.StakeholderType.INDIVIDUAL
            ? `${stakeholder.firstName} ${stakeholder.lastName}`
            : stakeholder.firstName;
        const lowerName = name.toLowerCase();

        return lowerName.includes(lowerSearch);
      });
    } else {
      this.filteredStakeholders = this.stakeholders;
    }
  };

  @action
  loadImpactGroupStakeholders = organisationId => async () => {
    let res = await this.impactGroupProvider.getImpactGroupStakeholders(
      organisationId,
      this.projectId,
      this.impactGroupId
    );
    if (!res) return;
    this.stakeholders = res.payload;
    this.filteredStakeholders = res.payload;
  };
}
