import * as React from "react";
import moment from "moment";
import { Timeline } from "../../../../components/widgets/TimelineWidget";
import I18n from "../../../../core/localization/I18n";
import { Link } from "react-router-dom";
import { ActionCompactViewModel } from "../actionCompactView";
import { useCurrentOrganisationId } from "../../../../services/local/organisationContext/OrganisationContextModel";
import { RenderXssSafeString } from "../../../../core/AntiXss/AntiXssHelper";
import { Hr } from "../../../../components/ui/Hr/index";
import { Tag } from "../../../../components/ui/Tag";
import { QUERY_STRING_PARAMS } from "../../../../services/local/queryStringService/QueryStringService";
import { ReviewBar } from "../../../../components/layouts/ReviewBar";
import { isItemComplete } from "../../../../core/util/ReviewHelpers";
import { IF } from "../../../../components/hoc/If";

export interface ActionDisplayProps {
  item: FP.Entities.IAction;
  parentModel?: ActionCompactViewModel;
}

const formatDate = date => {
  if (date) return new Date(date);
};

export const ActionDisplay: React.FunctionComponent<ActionDisplayProps> = props => {
  const { item, parentModel } = props;
  const organisationId = useCurrentOrganisationId();

  return (
    <>
      <div className="row mb-3">
        <div className="col">
          <h5 className="mb-1">{I18n.t("forms.description")}</h5>
          {item.description ? (
            <div dangerouslySetInnerHTML={{ __html: RenderXssSafeString(item.description) }}></div>
          ) : (
            <p>{I18n.t("table.noInput")}</p>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <Hr className="mb-0 mt-0" />
        </div>
      </div>
      <div className="row mb-4 mt-5">
        <div className="col">
          <h5 className="mb-1">{I18n.t("forms.actionOwner")}</h5>
          {item.owner !== null ? (
            <p className="mb-0">
              <Link
                to={`/organisations/${organisationId}/users/${item.owner.sub}?${
                  QUERY_STRING_PARAMS.RETURN_URL
                }=${encodeURIComponent(window.location.href.replace(appConfig.baseUrl, ""))}`}
                onClick={parentModel?.modalService.hide}
                className="body-bold text-link-style"
              >
                {item.owner.firstName + " " + item.owner.lastName}
              </Link>
            </p>
          ) : (
            <p className="mb-0">{I18n.t("table.noInput")}</p>
          )}
        </div>
        <div className="col">
          <h5 className="mb-1">{I18n.t("phrases.assignedTo")}</h5>
          {item.assignee !== null ? (
            <p className="mb-0">
              <Link
                to={`/organisations/${organisationId}/users/${item.assignee.sub}?${
                  QUERY_STRING_PARAMS.RETURN_URL
                }=${encodeURIComponent(window.location.href.replace(appConfig.baseUrl, ""))}`}
                onClick={parentModel?.modalService.hide}
                className="body-bold text-link-style"
              >
                {item.assignee.firstName} {item.assignee.lastName}
              </Link>
            </p>
          ) : (
            <p className="mb-0">{I18n.t("table.noInput")}</p>
          )}
        </div>

        <div className="col">
          <h5 className="mb-1">{I18n.t("forms.actionType")}</h5>
          <IF condition={!!item.actionType}>
            <p className="mb-0">{item.actionType?.name}</p>
          </IF>
          <IF condition={!item.actionType}>
            <p className="mb-0">{I18n.t("table.noInput")}</p>
          </IF>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-12">
          <h5 className="mb-3">{I18n.t("phrases.timeline")}</h5>

          <Timeline
            startDate={formatDate(item.startDate)}
            initialEndDate={formatDate(item.initialEndDate)}
            actualEndDate={formatDate(item.actualEndDate)}
          />
        </div>
        <div className="col-6 mt-2">
          <IF condition={!!item.startDate}>
            <p>{moment(item.startDate).format("L")}</p>
          </IF>
        </div>
        <div className="col-6 mt-2">
          <IF condition={!!item.actualEndDate}>
            <p className="text-right">{moment(item.actualEndDate).format("L")}</p>
          </IF>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <Hr className="mb-0 mt-0" />
        </div>
      </div>

      {item.reviewedBy && (
        <ReviewBar
          isOutOfAction={isItemComplete(item.progressStatus)}
          onwardLink={`/organisations/${organisationId}/users/${item.reviewedBy.sub}?${
            QUERY_STRING_PARAMS.RETURN_URL
          }=${encodeURIComponent(window.location.href.replace(appConfig.baseUrl, ""))}`}
          item={item}
        />
      )}

      <div className="row">
        <div className="col-12">
          <Hr className="mb-0 mt-0" />
        </div>
      </div>

      <div className="row pb-4 mt-5">
        <div className="col">
          <h5 className="mb-1">{I18n.t("phrases.tags")}</h5>
          {item.tags.length === 0 && <p className="mb-0">{I18n.t("table.noInput")}</p>}
          {item.tags.map(e => (
            <Tag tagText={e.text} isEditable={false} testId={e.id + ""} isNew={false} />
          ))}
        </div>
      </div>
    </>
  );
};
