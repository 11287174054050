import { GridApi, GridReadyEvent, SelectionChangedEvent } from "ag-grid-community";
import * as _ from "lodash";
import { action, makeObservable, observable } from "mobx";
import I18n from "../../../../../../core/localization/I18n";
import ModalContext from "../../../../../../core/modalZ/context/ModalContext";
import ToasterService, { IToasterService } from "../../../../../../core/toaster/ToasterService";
import { TOASTER_TOAST_TIME } from "../../../../../../core/toaster/Toaster_model";
import { BaseModel } from "../../../../../../core/util/BaseModel";
import { deepFilterTree } from "../../../../../../core/util/Helpers";
import ProcessesHub, {
  ProcessesHub as IProcessesHub
} from "../../../../../../services/hubs/ProcessesHub/Processes_hub";

export class ProcessTemplateSelectionModel extends BaseModel {
  projectId: number;
  processHub: IProcessesHub;
  organisationId: number;
  @observable isLoading: boolean = true;
  @observable processesRowData: FP.Entities.IProcessSummary[];
  authUser: FP.Entities.IUser;
  @observable.ref selectedProcesses: number[] = [];
  gridApi: GridApi;
  onAssignSuccess: any;
  searchText: string;
  @observable currStep: number;
  toasterService: IToasterService;
  @observable template: any;
  @observable assignmentInProgress: boolean = false;

  constructor(
    organisationId: number,
    projectId: number,
    authUser: FP.Entities.IUser,
    template: any,
    onAssignSuccess: any
  ) {
    super();
    makeObservable(this);
    this.projectId = projectId;
    this.processHub = ProcessesHub;
    this.onAssignSuccess = onAssignSuccess;
    this.organisationId = organisationId;
    this.authUser = authUser;
    this.toasterService = ToasterService;
    this.template = template;
  }

  @action
  onMount = selectedL1Processes => {
    this.setGridData(selectedL1Processes);
  };

  onUnmount = () => {};

  @action
  setGridData = (selectedL1Processes: any) => {
    const filteredL1Processes = deepFilterTree(this.template.processes, 0).filter(e =>
      selectedL1Processes.includes(e.id)
    );

    const res = [];
    filteredL1Processes.forEach(e => {
      const l1Process = e;
      if (l1Process.children?.length > 0) {
        l1Process.children.forEach(pp => {
          if (pp.children?.length > 0) {
            pp.children.forEach(p => {
              const l3Obj = {};
              l3Obj["id"] = +p.id;
              l3Obj["processL1"] = l1Process.name;
              l3Obj["processL2"] = pp.name;
              l3Obj["processL3"] = p.name;
              l3Obj["scopeItemNames"] = p.code;
              l3Obj["processRoles"] = p.processRoles?.join("|");
              l3Obj["processApps"] = p.processApps?.join("|");
              res.push(l3Obj);
            });
          }
        });
      }
    });

    this.setProcessesRowData(res);
    this.setIsLoading(false);
  };

  @action
  setIsLoading = (isLoading: any) => {
    this.isLoading = isLoading;
  };

  @action
  setProcessesRowData = processes => {
    this.processesRowData = processes;
  };

  @action
  onGridReady = (gridReadyEvent: GridReadyEvent) => {
    this.gridApi = gridReadyEvent.api;
  };

  @action
  setSearchText = (ev: React.FormEvent<HTMLInputElement>) => {
    this.searchText = ev.currentTarget.value;

    if (this.gridApi !== undefined) {
      this.gridApi.setGridOption("quickFilterText", this.searchText);
    }
  };

  @action
  setSelectedProcesses = (selectedProcesses: number[]) => {
    this.selectedProcesses = selectedProcesses;
  };

  @action
  updateSelectedProcesses = (event: SelectionChangedEvent) => {
    this.setSelectedProcesses(
      _.map(event.api.getSelectedNodes(), e => {
        return e.data.id;
      })
    );
  };

  @action
  assignProcesses = async (template1: any) => {
    const selectedTree = [];
    this.template.processes.forEach(l1 => {
      const l1Process = l1;
      const l2Processes = l1.children?.filter(chld =>
        chld?.children?.some(chld3 => this.selectedProcesses.indexOf(+chld3.id) > -1)
      );

      l2Processes?.forEach((l2, i) => {
        l2Processes[i].children = l2Processes[i].children.filter(l3 => this.selectedProcesses.indexOf(+l3.id) > -1);
      });
      l1Process.children = l2Processes;

      if (l1Process.children?.length > 0) {
        selectedTree.push(l1Process);
      }
    });

    this.assignmentInProgress = true;
    let result = null;
    this.onAssignSuccess(selectedTree);

    ModalContext.hide();
    if (!result || result.isError) return;

    const newAndExistingProcessesCounts: { existingProcesses: number; newProcessesCreated: number } = result.payload;
    this.setSelectedProcesses([]);
    if (newAndExistingProcessesCounts.newProcessesCreated > 0) {
      this.toasterService
        .showSuccessToast()
        .setContent(
          <span>
            {I18n.t("phrases.itemCreatedSuccessfully", {
              item: `${newAndExistingProcessesCounts.newProcessesCreated} ${I18n.t("entities.processes")}`
            })}
          </span>
        )
        .startTimer(TOASTER_TOAST_TIME.NORMAL);
    }
    if (newAndExistingProcessesCounts.newProcessesCreated <= 0) {
      this.toasterService
        .showSuccessToast()
        .setContent(<span>{I18n.t("phrases.noItemsCreated")}</span>)
        .startTimer(TOASTER_TOAST_TIME.NORMAL);
    }

    if (newAndExistingProcessesCounts.existingProcesses > 0) {
      this.toasterService
        .showSuccessToast()
        .setContent(
          <span>
            There were {newAndExistingProcessesCounts.existingProcesses} processes that already exist in this project.
          </span>
        )
        .startTimer(TOASTER_TOAST_TIME.NORMAL);
    }

    return newAndExistingProcessesCounts;
  };
}
