import { Panel } from "../../../../../../components/ui/Panel";
import React from "react";

interface ReportingCardProps extends ReactProps {
  className?: string;
  style?: React.CSSProperties;
}

export const ReportingCard: React.FC<ReportingCardProps> = props => {
  const { className, style } = props;

  return (
    <Panel.Panel
      hasBorder
      hasBorderRadius
      className={className || ""}
      background={Panel.PanelBackgrounds.BG_LIGHT}
      style={style}
    >
      {props.children}
    </Panel.Panel>
  );
};
