import React, { useState } from "react";
import { ToggleSwitch } from "../../../../../../components/ui/_forms/ToggleSwitch";

export interface GroupUsersPermissionsToggleProps {
  disabled: boolean;
  isChecked: boolean;
  onChange: (isChecked: boolean) => void;
}

export const GroupUsersPermissionsToggle: React.FC<GroupUsersPermissionsToggleProps> = props => {
  const [isChecked, setIsChecked] = useState(props.isChecked);

  return (
    <ToggleSwitch
      disabled={props.disabled}
      isChecked={isChecked}
      onChange={e => {
        setIsChecked(!isChecked);
        props.onChange(!isChecked);
      }}
    />
  );
};
