import { Enums, Translator } from "../../../../../../../../enums";
import I18n from "../../../../../../../localization/I18n";
import { zeroToTenTextMatcher } from "../../../../../../filters/TextMatcher";
import { ImpactColDefFieldNamesEnum } from "../../../../../enums/AgGridColDefFieldNameEnum";
import { ISdColDef, ISdFilterDef } from "../../../../columns/baseColumn/BaseColumnTypes";

export const IMPACT_LEVEL_COLUMN_CONFIG: (impactLevels: any) => ISdColDef<any, any> = impactLevels => {
  return {
    field: ImpactColDefFieldNamesEnum.ImpactLevel,
    headerName: I18n.t("grids.impactLevel"),
    cellDataType: "any",
    cellEditorParams: {
      field: ImpactColDefFieldNamesEnum.ImpactLevel,
      getValueLabel: (ee: number) => {
        const shortVal = Enums.Translator.ImpactLevelLongToShort(ee);
        return shortVal > 0 ? impactLevels.find(e => e.key === shortVal.toString()).label : "";
      },
      options: impactLevels ?? []
    },
    getQuickFilterText: params => {
      if (params.value === -1) return "";

      return Translator.ImpactLevel(params.value);
    }
  };
};
export const IMPACT_LEVEL_FILTER_CONFIG: ISdFilterDef = {
  filterParams: {
    textMatcher: zeroToTenTextMatcher
  }
};
