import React from 'react'
import { Link } from 'react-router-dom'
import I18n from '../../core/localization/I18n'
import { determineReviewTableBadgeType, getNextReviewDate, getReviewedOn } from '../../core/util/ReviewHelpers'
import { TableBadgeTypes, UiSizes } from '../../enums'
import { TableBadge } from '../ui/TableBadge'

export interface ReviewBarProps {
    onwardLink: string
    item: any;
    className?: string;
    style?: React.CSSProperties
    isOutOfAction?: boolean;
}

export const ReviewBar: React.FC<ReviewBarProps> = props => {
    const { onwardLink, item } = props;

    return <div className={`row pb-4 mt-5 ${props.className ? props.className : ""}`}>
        <div className="col-4">
            <h5 className="mb-1">{I18n.t("phrases.lastReviewed")}</h5>
            <TableBadge
                type={props.isOutOfAction ? TableBadgeTypes.BLACK : determineReviewTableBadgeType(item.reviewedOn)}
                badgeText={getReviewedOn(item.reviewedOn)}
                size={UiSizes.MD} />
        </div>
        <div className="col-4">
            <h5 className="mb-1">{I18n.t("phrases.reviewedBy")}</h5>
            <Link to={onwardLink} className="text-link-style body-bold">{item.reviewedBy.firstName} {item.reviewedBy.lastName}</Link>
        </div>
        <div className="col-4">
            {!props.isOutOfAction &&
                <>
                    <h5 className="mb-1">{I18n.t("phrases.nextReviewDue")}</h5>
                    <TableBadge
                        badgeText={getNextReviewDate(item.reviewedOn)}
                        type={TableBadgeTypes.BLACK}
                        size={UiSizes.MD} />
                </>}

        </div>
    </div>
}