import { action, makeObservable, observable } from "mobx";

import ProgressIndicatorModel, {
  ProgressIndicatorModel as IProgressIndicatorModel
} from "../../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import { IModalContextModel } from "../../../../../core/modalZ/context/IModalContext";
import ModalContext from "../../../../../core/modalZ/context/ModalContext";
import { BaseModel } from "../../../../../core/util/BaseModel";
import UsersApi, { UsersApi as IUsersApi } from "../../../../../services/api/v2/users/Users.api";
import { SingleFormModel } from "../../../forms/singleFormModel/SingleForm_model";
import { GetGeneralSettingsFields } from "./General_fields";

export class GeneralSettingsModel extends BaseModel {
  formModel: SingleFormModel;
  modalService: IModalContextModel;
  usersProvider: IUsersApi;
  httpProgress: IProgressIndicatorModel;
  @observable user: FP.Entities.IUser;
  @observable isLoading: boolean = true;
  authUser: FP.Entities.IUser;
  organisationId: number;
  refreshClaims: () => void;
  /**
   *
   */
  constructor(authUser: FP.Entities.IUser, organisationId: number, refreshClaims: () => void) {
    super();
    makeObservable(this);
    this.authUser = authUser;
    this.modalService = ModalContext;
    this.usersProvider = UsersApi;
    this.httpProgress = ProgressIndicatorModel;
    this.refreshClaims = refreshClaims;
    this.formModel = new SingleFormModel();

    this.organisationId = organisationId;
  }

  loadUser = async () => {
    let res = await this.usersProvider.getSelf(this.organisationId);
    if (!res || res.isError) return;
    this.setUser(res.payload);
  };

  @action
  setUser = (user: FP.Entities.IUser) => {
    if (!user) return;
    this.user = user;
    this.formModel.formFields = GetGeneralSettingsFields(this.modalService, this.user);
    this.isLoading = false;
  };

  updateUser = async () => {
    let formResult = await this.formModel.submit();

    if (formResult) {
      formResult.isImageChanged = formResult.profileImage ? true : false;
      let formData = new FormData();
      Object.keys(formResult).forEach(e => {
        if (e === "profileImage" && formResult[e].blob) {
          formData.append(e, formResult[e].blob, new Date().getTime() + ".jpg");
          return;
        }
        formData.append(e, formResult[e]);
      });
      this.httpProgress.showOverlay();

      let res = await this.usersProvider.updateWithOrganisationIdAsync(
        this.organisationId,
        this.user.sub,
        formData as any
      );
      this.refreshClaims();
      (window as any).location.reload();
      this.httpProgress.hideOverlay();
      if (!res || res.isError) return;
    }
  };

  onMount = () => {
    this.loadUser();
  };

  onUnmount = () => {};
}
