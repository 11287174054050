import { AxiosInstance, AxiosRequestConfig } from "axios";
import { IFlightPathApiResponse } from "../BaseApiModel";
import { BaseProjectApiModel } from "../BaseProjectApiModel";
import http from "../Http";

export class ImpactGroupVisualisationsApi extends BaseProjectApiModel<any> {
  controller: string = "impact-group-visualisations";

  /**
   *
   */
  constructor(http: AxiosInstance) {
    super(http, "impact-group-visualisations");
  }

  getImpactGroupSummary = async (organisationId: number, projectId: number, config?: AxiosRequestConfig) => {
    const url = `${this.url}${organisationId}/projects/${projectId}/impact-group-visualisations/summary`;

    const res = await this.http.get(url, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<any>;
    }
  };
}
const instance = new ImpactGroupVisualisationsApi(http);
export default instance;
