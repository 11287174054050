import * as React from "react";

import { SettingsViewModel } from "./SettingsView_model";
import { InnerNavContent } from "../../../../components/widgets/innerNavigation/InnerNavContent_view";
import { PositionedSpinner } from "../../../../components/ui/PositionedSpinner";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
import { useRefreshClaims } from "../../../../core/auth/authorise";
import { useCurrentOrganisationId } from "../../../../services/local/organisationContext/OrganisationContextModel";
import { useIsOrganisationAdmin } from "../../../../contexts/permissions/PermissionHooks";
import { useFlightPathUser } from "../../../../core/auth_insight";

export interface SettingsViewProps {}

export const SettingsView: React.FunctionComponent<SettingsViewProps> = observer(() => {
  const { programmeId: proId, page } = useParams();
  const refreshClaims = useRefreshClaims();
  const user = useFlightPathUser();
  const programmeId = +proId;
  const organisationId = useCurrentOrganisationId();
  const isOrganisationAdmin = useIsOrganisationAdmin(organisationId);
  const [model, setModel] = React.useState(
    () => new SettingsViewModel(programmeId, user, refreshClaims, isOrganisationAdmin, page)
  );

  React.useEffect(() => {
    setModel(new SettingsViewModel(programmeId, user, refreshClaims, isOrganisationAdmin, page));
  }, [page, programmeId, isOrganisationAdmin, refreshClaims, user]);

  if (model.isLoading) {
    return <PositionedSpinner />;
  }
  return (
    <div className="settings-view">
      <InnerNavContent model={model.innerNavContentModel} />
    </div>
  );
});
