export const Template3 = [
  {
    id: 2,
    name: "Application Platform and Infrastructure",
    parent: "",
    code: null,
    level: 1,
    depth: 0,
    children: [
      {
        id: 15,
        name: "Process Management and Integration",
        parent: 2,
        code: null,
        level: 2,
        depth: 1,
        children: [
          {
            id: 59,
            name: "Analytics - Purchase Order Visibility and Procurement Spend",
            parent: 15,
            code: "2QU",
            level: 3,
            depth: 2
          },
          {
            id: 60,
            name: "Analytics for Production Unit - Plan/Actual Production Cost",
            parent: 15,
            code: "2QW",
            processApps: ["2QW app name 1", "2QW app name 2"],
            processRoles: ["2QW process role 1", "2QW process roles 2"],
            level: 3,
            depth: 2
          },
          { id: 61, name: "Business Event Handling", parent: 15, code: "1NN", level: 3, depth: 2 },
          {
            id: 62,
            name: "Predictive Analytics Model Training - Finance",
            parent: 15,
            code: "30K",
            processApps: ["30K app name 1", "30K app name 2"],
            processRoles: ["30K process role 1", "30K process roles 2"],
            level: 3,
            depth: 2
          },
          { id: 63, name: "Responsibility Management", parent: 15, code: "1NJ", level: 3, depth: 2 },
          { id: 64, name: "Situation Handling", parent: 15, code: "31N", level: 3, depth: 2 },
          { id: 110, name: "Responsibility Management", parent: 15, code: "1NJ", level: 3, depth: 2 },
          {
            id: 238,
            name: "Predictive Analytics Model Training - Finance",
            parent: 15,
            code: "30K",
            level: 3,
            depth: 2
          },
          {
            id: 411,
            name: "Analytics for Production Unit - Plan/Actual Production Cost",
            parent: 15,
            code: "2QW",
            level: 3,
            depth: 2
          },
          {
            id: 766,
            name: "Analytics - Purchase Order Visibility and Procurement Spend",
            parent: 15,
            code: "2QU",
            level: 3,
            depth: 2
          }
        ]
      }
    ]
  },
  {
    id: 3,
    name: "Asset Management",
    parent: "",
    code: null,
    level: 1,
    depth: 0,
    children: [
      {
        id: 16,
        name: "Maintenance Management",
        parent: 3,
        code: null,
        level: 2,
        depth: 1,
        children: [
          { id: 65, name: "Corrective Maintenance", parent: 16, code: "BH1", level: 3, depth: 2 },
          { id: 66, name: "Emergency Maintenance", parent: 16, code: "BH2", level: 3, depth: 2 },
          { id: 67, name: "Improvement Maintenance", parent: 16, code: "4VT", level: 3, depth: 2 },
          { id: 68, name: "Integration with Asset Central Foundation", parent: 16, code: "3YE", level: 3, depth: 2 },
          { id: 69, name: "Maintenance Resource Scheduling", parent: 16, code: "43R", level: 3, depth: 2 },
          { id: 70, name: "Operational and Overhead Maintenance", parent: 16, code: "4WM", level: 3, depth: 2 },
          { id: 71, name: "Preventive Maintenance", parent: 16, code: "BJ2", level: 3, depth: 2 },
          { id: 72, name: "Proactive Maintenance", parent: 16, code: "4HI", level: 3, depth: 2 },
          { id: 73, name: "Reactive Maintenance", parent: 16, code: "4HH", level: 3, depth: 2 },
          { id: 74, name: "SAP Maintenance Assistant", parent: 16, code: "4RV", level: 3, depth: 2 }
        ]
      }
    ]
  },
  {
    id: 4,
    name: "Database and Data Management",
    parent: "",
    code: null,
    level: 1,
    depth: 0,
    children: [
      {
        id: 17,
        name: "Enterprise Information Management",
        parent: 4,
        code: null,
        level: 2,
        depth: 1,
        children: [
          {
            id: 75,
            name: "ABAP core data services extraction for SAP Data Intelligence",
            parent: 17,
            code: "53M",
            level: 3,
            depth: 2
          },
          {
            id: 76,
            name: "ABAP core data services extraction for SAP Data Warehouse Cloud",
            parent: 17,
            code: "53L",
            level: 3,
            depth: 2
          },
          {
            id: 77,
            name: "Automotive Supply To Customer - Inventory Management",
            parent: 17,
            code: "55F",
            level: 3,
            depth: 2
          },
          {
            id: 78,
            name: "Collaborative Manufacturing with SAP Digital Manufacturing Cloud",
            parent: 17,
            code: "1NX",
            level: 3,
            depth: 2
          },
          {
            id: 79,
            name: "Core Data Services-Based Extraction with SAP S/4HANA Cloud",
            parent: 17,
            code: "35D",
            level: 3,
            depth: 2
          },
          {
            id: 80,
            name: "Core HR with SAP SuccessFactors Employee Central",
            parent: 17,
            code: "JB1",
            level: 3,
            depth: 2
          },
          { id: 81, name: "Customer Data Return", parent: 17, code: "5I9", level: 3, depth: 2 },
          { id: 82, name: "Data Migration to SAP S/4HANA from SAP", parent: 17, code: "BH3", level: 3, depth: 2 },
          { id: 83, name: "Data Migration to SAP S/4HANA from Staging", parent: 17, code: "2Q2", level: 3, depth: 2 },
          { id: 84, name: "Data Protection and Privacy", parent: 17, code: "5LE", level: 3, depth: 2 },
          { id: 85, name: "Data Quality Management for Business Partner", parent: 17, code: "4AP", level: 3, depth: 2 },
          { id: 86, name: "Data Quality Management for Product", parent: 17, code: "4AQ", level: 3, depth: 2 },
          { id: 87, name: "Digital Payments Foundation", parent: 17, code: "53V", level: 3, depth: 2 },
          { id: 88, name: "Electronic Movement Control System", parent: 17, code: "4O0", level: 3, depth: 2 },
          {
            id: 89,
            name: "Employee Integration - SAP S/4HANA Enablement",
            parent: 17,
            code: "1FD",
            level: 3,
            depth: 2
          },
          { id: 90, name: "Enablement of SAP Excise Tax Management", parent: 17, code: "4LO", level: 3, depth: 2 },
          { id: 91, name: "Excise Tax - Consumer Products", parent: 17, code: "62R", level: 3, depth: 2 },
          {
            id: 92,
            name: "Financial Master Data for SAP SuccessFactors Employee Central",
            parent: 17,
            code: "4AJ",
            level: 3,
            depth: 2
          },
          {
            id: 93,
            name: "Import Connection setup with SAP Analytics Cloud",
            parent: 17,
            code: "1YB",
            level: 3,
            depth: 2
          },
          { id: 94, name: "Information Lifecycle Management", parent: 17, code: "1KA", level: 3, depth: 2 },
          {
            id: 95,
            name: "Integration to SAP Enterprise Contract Assembly",
            parent: 17,
            code: "2OQ",
            level: 3,
            depth: 2
          },
          { id: 96, name: "Integration with Data Quality Management", parent: 17, code: "2UZ", level: 3, depth: 2 },
          {
            id: 97,
            name: "Lot Size of One with Advanced Variant Configuration",
            parent: 17,
            code: "55E",
            level: 3,
            depth: 2
          },
          {
            id: 98,
            name: "Mass Load and Mass Maintenance for Business Partner",
            parent: 17,
            code: "1RK",
            level: 3,
            depth: 2
          },
          { id: 99, name: "Mass Load and Mass Maintenance for Product", parent: 17, code: "1RM", level: 3, depth: 2 },
          { id: 100, name: "Mass Processing for Business Partner", parent: 17, code: "1N7", level: 3, depth: 2 },
          { id: 101, name: "Mass Processing for Product", parent: 17, code: "1N5", level: 3, depth: 2 },
          { id: 102, name: "Master Data Catalog for SAP S/4HANA Cloud", parent: 17, code: "1I5", level: 3, depth: 2 },
          {
            id: 103,
            name: "Master Data Consolidation for Business Partner",
            parent: 17,
            code: "1N3",
            level: 3,
            depth: 2
          },
          { id: 104, name: "Master Data Consolidation for Product", parent: 17, code: "1N1", level: 3, depth: 2 },
          { id: 105, name: "Master Data Integration", parent: 17, code: "1RO", level: 3, depth: 2 },
          { id: 106, name: "Payroll Processing with SAP ERP HCM Payroll", parent: 17, code: "3UP", level: 3, depth: 2 },
          {
            id: 107,
            name: "Payroll Processing with SAP SuccessFactors Employee Central Payroll",
            parent: 17,
            code: "1NL",
            level: 3,
            depth: 2
          },
          {
            id: 108,
            name: "Produce and Sell Standard Products - Inventory Management",
            parent: 17,
            code: "54U",
            level: 3,
            depth: 2
          },
          {
            id: 109,
            name: "Produce and Sell Standard Products - Warehouse Managed",
            parent: 17,
            code: "54V",
            level: 3,
            depth: 2
          },
          { id: 111, name: "SAP Enable Now Integration", parent: 17, code: "3N5", level: 3, depth: 2 },
          { id: 112, name: "SAP Mobile Cards", parent: 17, code: "2TU", level: 3, depth: 2 },
          {
            id: 113,
            name: "SAP S/4HANA Cloud Integration with SAP Marketing Cloud",
            parent: 17,
            code: "1UG",
            level: 3,
            depth: 2
          },
          {
            id: 114,
            name: "SAP S/4HANA for Enterprise Contract Management",
            parent: 17,
            code: "1XV",
            level: 3,
            depth: 2
          },
          {
            id: 115,
            name: "Subsidiary Integration of SAP S/4HANA Cloud to Central Finance",
            parent: 17,
            code: "1W4",
            level: 3,
            depth: 2
          },
          { id: 116, name: "UI Theme Designer", parent: 17, code: "2TV", level: 3, depth: 2 },
          {
            id: 117,
            name: "Workforce Management with SAP Master Data Integration",
            parent: 17,
            code: "4AF",
            level: 3,
            depth: 2
          },
          {
            id: 176,
            name: "Subsidiary Integration of SAP S/4HANA Cloud to Central Finance",
            parent: 17,
            code: "1W4",
            level: 3,
            depth: 2
          },
          {
            id: 246,
            name: "Subsidiary Integration of SAP S/4HANA Cloud to Central Finance",
            parent: 17,
            code: "1W4",
            level: 3,
            depth: 2
          },
          {
            id: 272,
            name: "Automotive Supply To Customer - Inventory Management",
            parent: 17,
            code: "55F",
            level: 3,
            depth: 2
          },
          {
            id: 281,
            name: "Lot Size of One with Advanced Variant Configuration",
            parent: 17,
            code: "55E",
            level: 3,
            depth: 2
          },
          {
            id: 282,
            name: "Produce and Sell Standard Products - Inventory Management",
            parent: 17,
            code: "54U",
            level: 3,
            depth: 2
          },
          {
            id: 283,
            name: "Produce and Sell Standard Products - Warehouse Managed",
            parent: 17,
            code: "54V",
            level: 3,
            depth: 2
          },
          {
            id: 287,
            name: "Subsidiary Integration of SAP S/4HANA Cloud to Central Finance",
            parent: 17,
            code: "1W4",
            level: 3,
            depth: 2
          },
          {
            id: 396,
            name: "Core HR with SAP SuccessFactors Employee Central",
            parent: 17,
            code: "JB1",
            level: 3,
            depth: 2
          },
          {
            id: 397,
            name: "Employee Integration - SAP S/4HANA Enablement",
            parent: 17,
            code: "1FD",
            level: 3,
            depth: 2
          },
          { id: 398, name: "Payroll Processing with SAP ERP HCM Payroll", parent: 17, code: "3UP", level: 3, depth: 2 },
          {
            id: 399,
            name: "Payroll Processing with SAP SuccessFactors Employee Central Payroll",
            parent: 17,
            code: "1NL",
            level: 3,
            depth: 2
          },
          {
            id: 402,
            name: "Workforce Management with SAP Master Data Integration",
            parent: 17,
            code: "4AF",
            level: 3,
            depth: 2
          },
          {
            id: 412,
            name: "Automotive Supply To Customer - Inventory Management",
            parent: 17,
            code: "55F",
            level: 3,
            depth: 2
          },
          {
            id: 416,
            name: "Lot Size of One with Advanced Variant Configuration",
            parent: 17,
            code: "55E",
            level: 3,
            depth: 2
          },
          {
            id: 431,
            name: "Produce and Sell Standard Products - Inventory Management",
            parent: 17,
            code: "54U",
            level: 3,
            depth: 2
          },
          {
            id: 432,
            name: "Produce and Sell Standard Products - Warehouse Managed",
            parent: 17,
            code: "54V",
            level: 3,
            depth: 2
          },
          {
            id: 446,
            name: "Automotive Supply To Customer - Inventory Management",
            parent: 17,
            code: "55F",
            level: 3,
            depth: 2
          },
          {
            id: 450,
            name: "Lot Size of One with Advanced Variant Configuration",
            parent: 17,
            code: "55E",
            level: 3,
            depth: 2
          },
          {
            id: 454,
            name: "Produce and Sell Standard Products - Inventory Management",
            parent: 17,
            code: "54U",
            level: 3,
            depth: 2
          },
          {
            id: 455,
            name: "Produce and Sell Standard Products - Warehouse Managed",
            parent: 17,
            code: "54V",
            level: 3,
            depth: 2
          },
          {
            id: 466,
            name: "Automotive Supply To Customer - Inventory Management",
            parent: 17,
            code: "55F",
            level: 3,
            depth: 2
          },
          {
            id: 470,
            name: "Lot Size of One with Advanced Variant Configuration",
            parent: 17,
            code: "55E",
            level: 3,
            depth: 2
          },
          {
            id: 482,
            name: "Produce and Sell Standard Products - Inventory Management",
            parent: 17,
            code: "54U",
            level: 3,
            depth: 2
          },
          {
            id: 483,
            name: "Produce and Sell Standard Products - Warehouse Managed",
            parent: 17,
            code: "54V",
            level: 3,
            depth: 2
          },
          {
            id: 492,
            name: "Lot Size of One with Advanced Variant Configuration",
            parent: 17,
            code: "55E",
            level: 3,
            depth: 2
          },
          {
            id: 494,
            name: "Produce and Sell Standard Products - Inventory Management",
            parent: 17,
            code: "54U",
            level: 3,
            depth: 2
          },
          {
            id: 535,
            name: "Lot Size of One with Advanced Variant Configuration",
            parent: 17,
            code: "55E",
            level: 3,
            depth: 2
          },
          {
            id: 552,
            name: "Automotive Supply To Customer - Inventory Management",
            parent: 17,
            code: "55F",
            level: 3,
            depth: 2
          },
          {
            id: 593,
            name: "Produce and Sell Standard Products - Inventory Management",
            parent: 17,
            code: "54U",
            level: 3,
            depth: 2
          },
          {
            id: 594,
            name: "Produce and Sell Standard Products - Warehouse Managed",
            parent: 17,
            code: "54V",
            level: 3,
            depth: 2
          },
          {
            id: 703,
            name: "Automotive Supply To Customer - Inventory Management",
            parent: 17,
            code: "55F",
            level: 3,
            depth: 2
          },
          {
            id: 713,
            name: "Lot Size of One with Advanced Variant Configuration",
            parent: 17,
            code: "55E",
            level: 3,
            depth: 2
          },
          {
            id: 742,
            name: "Lot Size of One with Advanced Variant Configuration",
            parent: 17,
            code: "55E",
            level: 3,
            depth: 2
          },
          {
            id: 752,
            name: "Produce and Sell Standard Products - Inventory Management",
            parent: 17,
            code: "54U",
            level: 3,
            depth: 2
          },
          {
            id: 778,
            name: "SAP S/4HANA for Enterprise Contract Management",
            parent: 17,
            code: "1XV",
            level: 3,
            depth: 2
          },
          {
            id: 809,
            name: "Automotive Supply To Customer - Inventory Management",
            parent: 17,
            code: "55F",
            level: 3,
            depth: 2
          },
          { id: 816, name: "Electronic Movement Control System", parent: 17, code: "4O0", level: 3, depth: 2 },
          { id: 817, name: "Enablement of SAP Excise Tax Management", parent: 17, code: "4LO", level: 3, depth: 2 },
          { id: 818, name: "Excise Tax - Consumer Products", parent: 17, code: "62R", level: 3, depth: 2 },
          {
            id: 822,
            name: "Lot Size of One with Advanced Variant Configuration",
            parent: 17,
            code: "55E",
            level: 3,
            depth: 2
          },
          {
            id: 827,
            name: "Produce and Sell Standard Products - Inventory Management",
            parent: 17,
            code: "54U",
            level: 3,
            depth: 2
          },
          {
            id: 843,
            name: "Automotive Supply To Customer - Inventory Management",
            parent: 17,
            code: "55F",
            level: 3,
            depth: 2
          },
          {
            id: 845,
            name: "Lot Size of One with Advanced Variant Configuration",
            parent: 17,
            code: "55E",
            level: 3,
            depth: 2
          },
          {
            id: 846,
            name: "Produce and Sell Standard Products - Inventory Management",
            parent: 17,
            code: "54U",
            level: 3,
            depth: 2
          },
          {
            id: 847,
            name: "Produce and Sell Standard Products - Warehouse Managed",
            parent: 17,
            code: "54V",
            level: 3,
            depth: 2
          },
          {
            id: 852,
            name: "Produce and Sell Standard Products - Warehouse Managed",
            parent: 17,
            code: "54V",
            level: 3,
            depth: 2
          }
        ]
      }
    ]
  },
  {
    id: 5,
    name: "Finance",
    parent: "",
    code: null,
    level: 1,
    depth: 0,
    children: [
      {
        id: 18,
        name: "Accounting and Financial Close",
        parent: 5,
        code: null,
        level: 2,
        depth: 1,
        children: [
          { id: 118, name: "Accounting and Financial Close", parent: 18, code: "J58", level: 3, depth: 2 },
          {
            id: 119,
            name: "Accounting and Financial Close - Group Ledger IFRS",
            parent: 18,
            code: "1GA",
            level: 3,
            depth: 2
          },
          {
            id: 120,
            name: "Accounting and Financial Close - Group Ledger US GAAP",
            parent: 18,
            code: "2VA",
            level: 3,
            depth: 2
          },
          { id: 121, name: "Accounting for Incoming Sales Orders", parent: 18, code: "2FD", level: 3, depth: 2 },
          { id: 122, name: "Actual Costing", parent: 18, code: "33Q", level: 3, depth: 2 },
          { id: 123, name: "Asset Accounting", parent: 18, code: "J62", level: 3, depth: 2 },
          {
            id: 124,
            name: "Asset Accounting – Additional Depreciation Area",
            parent: 18,
            code: "5HG",
            level: 3,
            depth: 2
          },
          { id: 125, name: "Asset Accounting - Group Ledger IFRS", parent: 18, code: "1GB", level: 3, depth: 2 },
          { id: 126, name: "Asset Accounting - Group Ledger US GAAP", parent: 18, code: "33F", level: 3, depth: 2 },
          { id: 127, name: "Asset Retirement Obligation - IFRS", parent: 18, code: "5IT", level: 3, depth: 2 },
          { id: 128, name: "Asset Retirement Obligation - US GAAP", parent: 18, code: "56E", level: 3, depth: 2 },
          { id: 129, name: "Asset Under Construction", parent: 18, code: "BFH", level: 3, depth: 2 },
          {
            id: 130,
            name: "Asset Under Construction – Additional Depreciation Area",
            parent: 18,
            code: "5KF",
            level: 3,
            depth: 2
          },
          {
            id: 131,
            name: "Asset Under Construction - Group Ledger IFRS",
            parent: 18,
            code: "1GF",
            level: 3,
            depth: 2
          },
          {
            id: 132,
            name: "Asset Under Construction - Group Ledger US GAAP",
            parent: 18,
            code: "33G",
            level: 3,
            depth: 2
          },
          { id: 133, name: "Cash Journal", parent: 18, code: "1GO", level: 3, depth: 2 },
          { id: 134, name: "Contract-Based Revenue Recognition - IFRS", parent: 18, code: "3KK", level: 3, depth: 2 },
          {
            id: 135,
            name: "Contract-Based Revenue Recognition - US GAAP",
            parent: 18,
            code: "3VS",
            level: 3,
            depth: 2
          },
          {
            id: 136,
            name: "Event-Based Revenue Recognition - Project-Based Sales",
            parent: 18,
            code: "4GQ",
            level: 3,
            depth: 2
          },
          {
            id: 137,
            name: "Event-Based Revenue Recognition - Project-Based Sales - IFRS",
            parent: 18,
            code: "4GR",
            level: 3,
            depth: 2
          },
          {
            id: 138,
            name: "Event-Based Revenue Recognition - Project-Based Sales - US GAAP",
            parent: 18,
            code: "4GS",
            level: 3,
            depth: 2
          },
          {
            id: 139,
            name: "Event-Based Revenue Recognition - Project-Based Services",
            parent: 18,
            code: "1IL",
            level: 3,
            depth: 2
          },
          {
            id: 140,
            name: "Event-Based Revenue Recognition - Project-Based Services - IFRS",
            parent: 18,
            code: "1P0",
            level: 3,
            depth: 2
          },
          {
            id: 141,
            name: "Event-Based Revenue Recognition - Project-Based Services - US GAAP",
            parent: 18,
            code: "33O",
            level: 3,
            depth: 2
          },
          {
            id: 142,
            name: "Event-Based Revenue Recognition - Sell from Stock",
            parent: 18,
            code: "1K2",
            level: 3,
            depth: 2
          },
          {
            id: 143,
            name: "Event-Based Revenue Recognition - Sell from Stock - IFRS",
            parent: 18,
            code: "33M",
            level: 3,
            depth: 2
          },
          {
            id: 144,
            name: "Event-Based Revenue Recognition - Sell from Stock - US GAAP",
            parent: 18,
            code: "33J",
            level: 3,
            depth: 2
          },
          {
            id: 145,
            name: "Event-Based Revenue Recognition - Service Documents",
            parent: 18,
            code: "3M3",
            level: 3,
            depth: 2
          },
          {
            id: 146,
            name: "Event-Based Revenue Recognition - Service Documents - IFRS",
            parent: 18,
            code: "3M4",
            level: 3,
            depth: 2
          },
          {
            id: 147,
            name: "Event-Based Revenue Recognition - Service Documents - US GAAP",
            parent: 18,
            code: "3M5",
            level: 3,
            depth: 2
          },
          {
            id: 148,
            name: "Event-Based Revenue Recognition - Subscription Billing",
            parent: 18,
            code: "5DQ",
            level: 3,
            depth: 2
          },
          {
            id: 149,
            name: "Event-Based Revenue Recognition - Subscription Billing - IFRS",
            parent: 18,
            code: "5DR",
            level: 3,
            depth: 2
          },
          {
            id: 150,
            name: "Event-Based Revenue Recognition - Subscription Billing - US GAAP",
            parent: 18,
            code: "5DS",
            level: 3,
            depth: 2
          },
          { id: 151, name: "External Tax Audit", parent: 18, code: "2OO", level: 3, depth: 2 },
          { id: 152, name: "External Tax Calculation", parent: 18, code: "4Q2", level: 3, depth: 2 },
          { id: 153, name: "Financial Planning and Analysis", parent: 18, code: "2FM", level: 3, depth: 2 },
          { id: 154, name: "General Ledger Allocation Cycle", parent: 18, code: "1GI", level: 3, depth: 2 },
          { id: 155, name: "Group Valuation", parent: 18, code: "5W2", level: 3, depth: 2 },
          { id: 156, name: "Integrated Financial Planning", parent: 18, code: "4RC", level: 3, depth: 2 },
          { id: 157, name: "Intelligent Intercompany Reconciliation", parent: 18, code: "4LG", level: 3, depth: 2 },
          {
            id: 158,
            name: "Intercompany Asset Transfer Within Same Country",
            parent: 18,
            code: "1IH",
            level: 3,
            depth: 2
          },
          { id: 159, name: "Intercompany Financial Posting", parent: 18, code: "1GP", level: 3, depth: 2 },
          { id: 160, name: "Intercompany Reconciliation Process", parent: 18, code: "40Y", level: 3, depth: 2 },
          { id: 161, name: "Inventory Valuation for Year-End Closing", parent: 18, code: "BEJ", level: 3, depth: 2 },
          {
            id: 162,
            name: "Machine Learning for Monitoring of Goods and Invoice Receipts",
            parent: 18,
            code: "2ZS",
            level: 3,
            depth: 2
          },
          {
            id: 163,
            name: "Managing Material Price Changes and Inventory Values",
            parent: 18,
            code: "1ZT",
            level: 3,
            depth: 2
          },
          { id: 164, name: "Monitoring of Goods and Invoice Receipts", parent: 18, code: "2V7", level: 3, depth: 2 },
          {
            id: 165,
            name: "Organizational Flexibility in Financial Accounting",
            parent: 18,
            code: "4PG",
            level: 3,
            depth: 2
          },
          { id: 166, name: "Period-End Closing - Maintenance Orders", parent: 18, code: "BF7", level: 3, depth: 2 },
          { id: 167, name: "Period-End Closing - Plant", parent: 18, code: "BEI", level: 3, depth: 2 },
          { id: 168, name: "Period-End Closing - Projects", parent: 18, code: "BNA", level: 3, depth: 2 },
          {
            id: 169,
            name: "Predictive Accounting for Service Contract Management",
            parent: 18,
            code: "4V7",
            level: 3,
            depth: 2
          },
          {
            id: 170,
            name: "Predictive Accounting for Travel and Expense Management",
            parent: 18,
            code: "4Q0",
            level: 3,
            depth: 2
          },
          {
            id: 171,
            name: "Product Cost Related Master Data Changes - Consumer Products",
            parent: 18,
            code: "61W",
            level: 3,
            depth: 2
          },
          { id: 172, name: "Purchase Order Accruals", parent: 18, code: "2VB", level: 3, depth: 2 },
          {
            id: 173,
            name: "SAP Fiori Analytical Apps for Asset Accounting in Finance",
            parent: 18,
            code: "2QY",
            level: 3,
            depth: 2
          },
          {
            id: 174,
            name: "SAP Fiori Analytical Apps for G/L Accounting in Finance",
            parent: 18,
            code: "BGC",
            level: 3,
            depth: 2
          },
          { id: 175, name: "Service Entry Sheet Based Accruals", parent: 18, code: "63L", level: 3, depth: 2 },
          { id: 216, name: "Accounting and Financial Close", parent: 18, code: "J58", level: 3, depth: 2 },
          { id: 222, name: "External Tax Calculation", parent: 18, code: "4Q2", level: 3, depth: 2 },
          { id: 229, name: "Actual Costing", parent: 18, code: "33Q", level: 3, depth: 2 },
          { id: 232, name: "Financial Planning and Analysis", parent: 18, code: "2FM", level: 3, depth: 2 },
          { id: 233, name: "Group Valuation", parent: 18, code: "5W2", level: 3, depth: 2 },
          { id: 234, name: "Integrated Financial Planning", parent: 18, code: "4RC", level: 3, depth: 2 },
          {
            id: 237,
            name: "Predictive Accounting for Travel and Expense Management",
            parent: 18,
            code: "4Q0",
            level: 3,
            depth: 2
          },
          {
            id: 240,
            name: "Product Cost Related Master Data Changes - Consumer Products",
            parent: 18,
            code: "61W",
            level: 3,
            depth: 2
          },
          {
            id: 463,
            name: "Product Cost Related Master Data Changes - Consumer Products",
            parent: 18,
            code: "61W",
            level: 3,
            depth: 2
          },
          {
            id: 651,
            name: "Event-Based Revenue Recognition - Project-Based Services",
            parent: 18,
            code: "1IL",
            level: 3,
            depth: 2
          },
          {
            id: 652,
            name: "Event-Based Revenue Recognition - Project-Based Services - IFRS",
            parent: 18,
            code: "1P0",
            level: 3,
            depth: 2
          },
          {
            id: 653,
            name: "Event-Based Revenue Recognition - Project-Based Services - US GAAP",
            parent: 18,
            code: "33O",
            level: 3,
            depth: 2
          }
        ]
      },
      {
        id: 19,
        name: "Advanced Accounting and Financial Close",
        parent: 5,
        code: null,
        level: 2,
        depth: 1,
        children: [
          { id: 177, name: "Advanced Financial Closing", parent: 19, code: "2V8", level: 3, depth: 2 },
          { id: 178, name: "Advanced Financial Closing Integration", parent: 19, code: "4HG", level: 3, depth: 2 },
          { id: 179, name: "Compliance Formats - Support Preparation", parent: 19, code: "1J2", level: 3, depth: 2 },
          {
            id: 180,
            name: "Compliance Formats - Support Preparation for Belgium",
            parent: 19,
            code: "5P9",
            level: 3,
            depth: 2
          },
          {
            id: 181,
            name: "Compliance Formats - Support Preparation for Czech Republic",
            parent: 19,
            code: "5Z7",
            level: 3,
            depth: 2
          },
          {
            id: 182,
            name: "Compliance Formats - Support Preparation for South Africa",
            parent: 19,
            code: "5Z5",
            level: 3,
            depth: 2
          },
          {
            id: 183,
            name: "Compliance Formats - Support Preparation for Sweden",
            parent: 19,
            code: "5Z6",
            level: 3,
            depth: 2
          },
          { id: 184, name: "Document and Reporting Compliance", parent: 19, code: "5XU", level: 3, depth: 2 },
          { id: 185, name: "Enable for Use and Contract Management", parent: 19, code: "5VX", level: 3, depth: 2 },
          {
            id: 186,
            name: "Enable for Use and Contract Management - Group Ledger IFRS",
            parent: 19,
            code: "5YU",
            level: 3,
            depth: 2
          },
          {
            id: 187,
            name: "Enable for Use and Contract Management - Group Ledger US GAAP",
            parent: 19,
            code: "63Q",
            level: 3,
            depth: 2
          },
          { id: 188, name: "External Occupancy and Contract Management", parent: 19, code: "5VY", level: 3, depth: 2 },
          {
            id: 189,
            name: "External Occupancy and Contract Management - Group Ledger IFRS",
            parent: 19,
            code: "5YV",
            level: 3,
            depth: 2
          },
          {
            id: 190,
            name: "External Occupancy and Contract Management - Group Ledger US GAAP",
            parent: 19,
            code: "63R",
            level: 3,
            depth: 2
          },
          {
            id: 191,
            name: "Group Account Preparation for Financial Consolidation",
            parent: 19,
            code: "3AF",
            level: 3,
            depth: 2
          },
          { id: 192, name: "Group Financial Planning", parent: 19, code: "5PU", level: 3, depth: 2 },
          {
            id: 193,
            name: "Group Reporting - Consolidation with Multiple Group Currencies",
            parent: 19,
            code: "4VB",
            level: 3,
            depth: 2
          },
          {
            id: 194,
            name: "Group Reporting - Data from External Systems",
            parent: 19,
            code: "2U6",
            level: 3,
            depth: 2
          },
          {
            id: 195,
            name: "Group Reporting - Data from SAP Group Reporting Data Collection",
            parent: 19,
            code: "287",
            level: 3,
            depth: 2
          },
          { id: 196, name: "Group Reporting - Financial Consolidation", parent: 19, code: "1SG", level: 3, depth: 2 },
          { id: 197, name: "Group Reporting - Matrix Consolidation", parent: 19, code: "3LX", level: 3, depth: 2 },
          { id: 198, name: "Group Reporting - Plan Consolidation", parent: 19, code: "28B", level: 3, depth: 2 },
          { id: 199, name: "Group Reporting - Predictive Consolidation", parent: 19, code: "3JP", level: 3, depth: 2 },
          {
            id: 200,
            name: "Integration of SAP S/4HANA and SAP Cloud for Real Estate",
            parent: 19,
            code: "4X3",
            level: 3,
            depth: 2
          },
          {
            id: 201,
            name: "Intercompany Occupancy and Contract Management",
            parent: 19,
            code: "5W0",
            level: 3,
            depth: 2
          },
          {
            id: 202,
            name: "Intercompany Occupancy and Contract Management - Group Ledger IFRS",
            parent: 19,
            code: "5YW",
            level: 3,
            depth: 2
          },
          {
            id: 203,
            name: "Intercompany Occupancy and Contract Mgmt - Group Ledger US GAAP",
            parent: 19,
            code: "63S",
            level: 3,
            depth: 2
          },
          {
            id: 204,
            name: "Intercompany Process for Sublease Accounting",
            parent: 19,
            code: "2O9",
            level: 3,
            depth: 2
          },
          { id: 205, name: "Internal Occupancy and Cost Allocation", parent: 19, code: "5VZ", level: 3, depth: 2 },
          { id: 206, name: "Joint Venture Accounting", parent: 19, code: "3F7", level: 3, depth: 2 },
          { id: 207, name: "Lease-In Accounting", parent: 19, code: "1T6", level: 3, depth: 2 },
          { id: 208, name: "Lease-In Accounting - Group Ledger IFRS", parent: 19, code: "21P", level: 3, depth: 2 },
          { id: 209, name: "Lease-In Accounting - Group Ledger US GAAP", parent: 19, code: "34N", level: 3, depth: 2 },
          { id: 210, name: "Lease-Out Accounting", parent: 19, code: "21Q", level: 3, depth: 2 },
          { id: 211, name: "Lease-Out for Sublease Accounting", parent: 19, code: "2SB", level: 3, depth: 2 },
          {
            id: 212,
            name: "Lease-Out for Sublease Accounting - Group Ledger IFRS",
            parent: 19,
            code: "2SA",
            level: 3,
            depth: 2
          },
          {
            id: 213,
            name: "Lease-Out for Sublease Accounting - Group Ledger US GAAP",
            parent: 19,
            code: "34O",
            level: 3,
            depth: 2
          },
          { id: 214, name: "Sales-Based Rent", parent: 19, code: "3F4", level: 3, depth: 2 },
          { id: 215, name: "Service Contract", parent: 19, code: "21R", level: 3, depth: 2 },
          { id: 290, name: "Enable for Use and Contract Management", parent: 19, code: "5VX", level: 3, depth: 2 },
          {
            id: 291,
            name: "Enable for Use and Contract Management - Group Ledger IFRS",
            parent: 19,
            code: "5YU",
            level: 3,
            depth: 2
          },
          {
            id: 292,
            name: "Enable for Use and Contract Management - Group Ledger US GAAP",
            parent: 19,
            code: "63Q",
            level: 3,
            depth: 2
          },
          { id: 293, name: "External Occupancy and Contract Management", parent: 19, code: "5VY", level: 3, depth: 2 },
          {
            id: 294,
            name: "External Occupancy and Contract Management - Group Ledger IFRS",
            parent: 19,
            code: "5YV",
            level: 3,
            depth: 2
          },
          {
            id: 295,
            name: "External Occupancy and Contract Management - Group Ledger US GAAP",
            parent: 19,
            code: "63R",
            level: 3,
            depth: 2
          },
          {
            id: 296,
            name: "Integration of SAP S/4HANA and SAP Cloud for Real Estate",
            parent: 19,
            code: "4X3",
            level: 3,
            depth: 2
          },
          {
            id: 297,
            name: "Intercompany Occupancy and Contract Management",
            parent: 19,
            code: "5W0",
            level: 3,
            depth: 2
          },
          {
            id: 298,
            name: "Intercompany Occupancy and Contract Management - Group Ledger IFRS",
            parent: 19,
            code: "5YW",
            level: 3,
            depth: 2
          },
          {
            id: 299,
            name: "Intercompany Occupancy and Contract Mgmt - Group Ledger US GAAP",
            parent: 19,
            code: "63S",
            level: 3,
            depth: 2
          },
          {
            id: 300,
            name: "Intercompany Process for Sublease Accounting",
            parent: 19,
            code: "2O9",
            level: 3,
            depth: 2
          },
          { id: 301, name: "Internal Occupancy and Cost Allocation", parent: 19, code: "5VZ", level: 3, depth: 2 },
          { id: 302, name: "Lease-In Accounting", parent: 19, code: "1T6", level: 3, depth: 2 },
          { id: 303, name: "Lease-In Accounting - Group Ledger IFRS", parent: 19, code: "21P", level: 3, depth: 2 },
          { id: 304, name: "Lease-In Accounting - Group Ledger US GAAP", parent: 19, code: "34N", level: 3, depth: 2 },
          { id: 305, name: "Lease-Out Accounting", parent: 19, code: "21Q", level: 3, depth: 2 },
          { id: 306, name: "Lease-Out for Sublease Accounting", parent: 19, code: "2SB", level: 3, depth: 2 },
          {
            id: 307,
            name: "Lease-Out for Sublease Accounting - Group Ledger IFRS",
            parent: 19,
            code: "2SA",
            level: 3,
            depth: 2
          },
          {
            id: 308,
            name: "Lease-Out for Sublease Accounting - Group Ledger US GAAP",
            parent: 19,
            code: "34O",
            level: 3,
            depth: 2
          },
          { id: 309, name: "Sales-Based Rent", parent: 19, code: "3F4", level: 3, depth: 2 },
          { id: 310, name: "Service Contract", parent: 19, code: "21R", level: 3, depth: 2 }
        ]
      },
      {
        id: 20,
        name: "Advanced Financial Operations",
        parent: 5,
        code: null,
        level: 2,
        depth: 1,
        children: [
          { id: 217, name: "Advanced Credit Management", parent: 20, code: "1QM", level: 3, depth: 2 },
          { id: 218, name: "Basic Credit Management", parent: 20, code: "BD6", level: 3, depth: 2 },
          { id: 219, name: "Collections and Dispute Management", parent: 20, code: "BFC", level: 3, depth: 2 },
          {
            id: 220,
            name: "Convergent Invoicing and Contract Accounting for Utilities",
            parent: 20,
            code: "3L1",
            level: 3,
            depth: 2
          },
          { id: 221, name: "Credit Agency Integration", parent: 20, code: "1RY", level: 3, depth: 2 },
          { id: 223, name: "Tax Posting Proposal", parent: 20, code: "5Z8", level: 3, depth: 2 },
          {
            id: 224,
            name: "VAT Tax Calculation and Reporting for Belgium",
            parent: 20,
            code: "5IS",
            level: 3,
            depth: 2
          },
          {
            id: 225,
            name: "VAT Tax Calculation and Reporting for Czech Republic",
            parent: 20,
            code: "5Z2",
            level: 3,
            depth: 2
          },
          {
            id: 226,
            name: "VAT Tax Calculation and Reporting for Germany",
            parent: 20,
            code: "55U",
            level: 3,
            depth: 2
          },
          {
            id: 227,
            name: "VAT Tax Calculation and Reporting for South Africa",
            parent: 20,
            code: "5Z0",
            level: 3,
            depth: 2
          },
          {
            id: 228,
            name: "VAT Tax Calculation and Reporting for Sweden",
            parent: 20,
            code: "5Z1",
            level: 3,
            depth: 2
          },
          {
            id: 328,
            name: "Convergent Invoicing and Contract Accounting for Utilities",
            parent: 20,
            code: "3L1",
            level: 3,
            depth: 2
          }
        ]
      },
      {
        id: 21,
        name: "Cost Management and Profitability Analysis",
        parent: 5,
        code: null,
        level: 2,
        depth: 1,
        children: [
          { id: 230, name: "Event-Based Production Cost Posting", parent: 21, code: "3F0", level: 3, depth: 2 },
          { id: 231, name: "Financial Plan Data Upload from File", parent: 21, code: "1HB", level: 3, depth: 2 },
          { id: 235, name: "Margin Analysis", parent: 21, code: "J55", level: 3, depth: 2 },
          { id: 236, name: "Overhead Cost Accounting", parent: 21, code: "J54", level: 3, depth: 2 },
          { id: 239, name: "Predictive Commitments Management", parent: 21, code: "2I3", level: 3, depth: 2 },
          {
            id: 241,
            name: "Production Capacity Evaluation and Leveling - Consumer Products",
            parent: 21,
            code: "5OI",
            level: 3,
            depth: 2
          },
          { id: 242, name: "Project Control - Finance", parent: 21, code: "1NT", level: 3, depth: 2 },
          { id: 243, name: "Project Control – Sales", parent: 21, code: "4I9", level: 3, depth: 2 },
          { id: 244, name: "Standard Cost Calculation", parent: 21, code: "BEG", level: 3, depth: 2 },
          { id: 245, name: "Statistical Sales Conditions", parent: 21, code: "34B", level: 3, depth: 2 },
          { id: 247, name: "Universal Allocation", parent: 21, code: "2QL", level: 3, depth: 2 },
          {
            id: 433,
            name: "Production Capacity Evaluation and Leveling - Consumer Products",
            parent: 21,
            code: "5OI",
            level: 3,
            depth: 2
          },
          {
            id: 485,
            name: "Production Capacity Evaluation and Leveling - Consumer Products",
            parent: 21,
            code: "5OI",
            level: 3,
            depth: 2
          },
          { id: 507, name: "Project Control - Finance", parent: 21, code: "1NT", level: 3, depth: 2 },
          { id: 511, name: "Project Control – Sales", parent: 21, code: "4I9", level: 3, depth: 2 }
        ]
      },
      {
        id: 22,
        name: "Enterprise Risk and Compliance",
        parent: 5,
        code: null,
        level: 2,
        depth: 1,
        children: [
          {
            id: 248,
            name: "Automated Provisioning via SAP Cloud Identity Access Governance",
            parent: 22,
            code: "3AB",
            level: 3,
            depth: 2
          },
          {
            id: 249,
            name: "Classification for Customs Tariff Number and Commodity Code",
            parent: 22,
            code: "2U3",
            level: 3,
            depth: 2
          },
          {
            id: 250,
            name: "Compliance Management with SAP Global Trade Services",
            parent: 22,
            code: "24J",
            level: 3,
            depth: 2
          },
          {
            id: 251,
            name: "Continuous Control Monitoring with SAP Process Control",
            parent: 22,
            code: "2OH",
            level: 3,
            depth: 2
          },
          {
            id: 252,
            name: "Customs Management with SAP Global Trade Services",
            parent: 22,
            code: "2U1",
            level: 3,
            depth: 2
          },
          { id: 253, name: "Embargo Control", parent: 22, code: "1WC", level: 3, depth: 2 },
          {
            id: 254,
            name: "Financial Operation Monitoring with SAP Financial Compliance",
            parent: 22,
            code: "3KY",
            level: 3,
            depth: 2
          },
          { id: 255, name: "Intrastat Processing", parent: 22, code: "BDT", level: 3, depth: 2 },
          { id: 256, name: "Intrastat Processing for Belgium", parent: 22, code: "5PA", level: 3, depth: 2 },
          { id: 257, name: "Intrastat Processing for Czech Republic", parent: 22, code: "5Z4", level: 3, depth: 2 },
          { id: 258, name: "Intrastat Processing for Sweden", parent: 22, code: "5Z3", level: 3, depth: 2 },
          {
            id: 259,
            name: "Key Risk Indicator Monitoring with SAP Risk Management",
            parent: 22,
            code: "2U2",
            level: 3,
            depth: 2
          },
          { id: 260, name: "Legal Control", parent: 22, code: "1W8", level: 3, depth: 2 },
          {
            id: 261,
            name: "Preference Management with SAP Global Trade Services",
            parent: 22,
            code: "3JX",
            level: 3,
            depth: 2
          },
          {
            id: 262,
            name: "Privacy Risk Detection with SAP Privacy Governance",
            parent: 22,
            code: "3KX",
            level: 3,
            depth: 2
          },
          {
            id: 263,
            name: "Restricted Party Screening with SAP Watch List Screening",
            parent: 22,
            code: "1WE",
            level: 3,
            depth: 2
          },
          {
            id: 264,
            name: "Transfer of Bill of Materials for SAP Global Trade Services",
            parent: 22,
            code: "24H",
            level: 3,
            depth: 2
          },
          {
            id: 265,
            name: "Transfer of Contact Person for SAP Global Trade Services",
            parent: 22,
            code: "24F",
            level: 3,
            depth: 2
          },
          {
            id: 266,
            name: "Transfer of Primary Master Data for SAP Global Trade Services",
            parent: 22,
            code: "1WA",
            level: 3,
            depth: 2
          }
        ]
      },
      {
        id: 23,
        name: "Environmental Footprint Management",
        parent: 5,
        code: null,
        level: 2,
        depth: 1,
        children: [{ id: 267, name: "Product Footprint Management", parent: 23, code: "5IM", level: 3, depth: 2 }]
      },
      {
        id: 24,
        name: "Financial Operations",
        parent: 5,
        code: null,
        level: 2,
        depth: 1,
        children: [
          { id: 268, name: "Accounts Payable", parent: 24, code: "J60", level: 3, depth: 2 },
          { id: 269, name: "Accounts Receivable", parent: 24, code: "J59", level: 3, depth: 2 },
          { id: 270, name: "Advanced Bank Statement Automation", parent: 24, code: "4X8", level: 3, depth: 2 },
          { id: 271, name: "Automated Invoice Settlement", parent: 24, code: "2LH", level: 3, depth: 2 },
          { id: 273, name: "Cash Application Integration", parent: 24, code: "1MV", level: 3, depth: 2 },
          { id: 274, name: "Customer Payments", parent: 24, code: "1S0", level: 3, depth: 2 },
          { id: 275, name: "Digital Payments", parent: 24, code: "1S2", level: 3, depth: 2 },
          { id: 276, name: "Direct Debit", parent: 24, code: "19M", level: 3, depth: 2 },
          { id: 277, name: "External Customer Payments", parent: 24, code: "2M0", level: 3, depth: 2 },
          { id: 278, name: "External Digital Payments", parent: 24, code: "2LZ", level: 3, depth: 2 },
          {
            id: 279,
            name: "Integration of Payment Advice with Buyers and Suppliers",
            parent: 24,
            code: "2MB",
            level: 3,
            depth: 2
          },
          { id: 280, name: "Lockbox", parent: 24, code: "1JT", level: 3, depth: 2 },
          { id: 284, name: "Provisioning of Overdue Receivables", parent: 24, code: "2PD", level: 3, depth: 2 },
          { id: 285, name: "Royalties Settlement for Sales", parent: 24, code: "55I", level: 3, depth: 2 },
          {
            id: 286,
            name: "SAP Fiori Analytical Apps for Financial Accounting",
            parent: 24,
            code: "2JB",
            level: 3,
            depth: 2
          },
          { id: 288, name: "Supplier Financing", parent: 24, code: "4AV", level: 3, depth: 2 },
          {
            id: 289,
            name: "Travel Expense Processing with SAP Concur Expense Professional",
            parent: 24,
            code: "1M1",
            level: 3,
            depth: 2
          },
          { id: 333, name: "Advanced Bank Statement Automation", parent: 24, code: "4X8", level: 3, depth: 2 },
          { id: 700, name: "Automated Invoice Settlement", parent: 24, code: "2LH", level: 3, depth: 2 },
          { id: 727, name: "Automated Invoice Settlement", parent: 24, code: "2LH", level: 3, depth: 2 },
          { id: 807, name: "Automated Invoice Settlement", parent: 24, code: "2LH", level: 3, depth: 2 }
        ]
      },
      { id: 25, name: "Real Estate Management", parent: 5, code: null, level: 2, depth: 1 },
      {
        id: 26,
        name: "Subscription Billing and Revenue Management",
        parent: 5,
        code: null,
        level: 2,
        depth: 1,
        children: [
          { id: 311, name: "Contract Accounting - Cash Application", parent: 26, code: "47I", level: 3, depth: 2 },
          {
            id: 312,
            name: "Contract Accounting - Company Initiated Payments",
            parent: 26,
            code: "2DP",
            level: 3,
            depth: 2
          },
          {
            id: 313,
            name: "Contract Accounting - Contract-Based Revenue Recognition",
            parent: 26,
            code: "3L3",
            level: 3,
            depth: 2
          },
          {
            id: 314,
            name: "Contract Accounting - Customer Initiated Payments",
            parent: 26,
            code: "2BI",
            level: 3,
            depth: 2
          },
          {
            id: 315,
            name: "Contract Accounting - Daily and Monthly Closing",
            parent: 26,
            code: "2BK",
            level: 3,
            depth: 2
          },
          {
            id: 316,
            name: "Contract Accounting - Enabling of Products with Variant Configuration",
            parent: 26,
            code: "3L8",
            level: 3,
            depth: 2
          },
          {
            id: 317,
            name: "Contract Accounting - External Tax Audit and Compliance Reporting",
            parent: 26,
            code: "2SJ",
            level: 3,
            depth: 2
          },
          { id: 318, name: "Contract Accounting - Group Ledger IFRS", parent: 26, code: "33X", level: 3, depth: 2 },
          { id: 319, name: "Contract Accounting - Group Ledger US GAAP", parent: 26, code: "33V", level: 3, depth: 2 },
          {
            id: 320,
            name: "Contract Accounting - Integration with Sales Documents",
            parent: 26,
            code: "43Y",
            level: 3,
            depth: 2
          },
          {
            id: 321,
            name: "Contract Accounting - Master Data and Basic Functions",
            parent: 26,
            code: "2AR",
            level: 3,
            depth: 2
          },
          { id: 322, name: "Contract Accounting - Open Item Management", parent: 26, code: "2UJ", level: 3, depth: 2 },
          {
            id: 323,
            name: "Convergent Invoicing - Invoice Correction Processing",
            parent: 26,
            code: "2BE",
            level: 3,
            depth: 2
          },
          {
            id: 324,
            name: "Convergent Invoicing - Invoice Creation for Usage and Service",
            parent: 26,
            code: "2BG",
            level: 3,
            depth: 2
          },
          {
            id: 325,
            name: "Convergent Invoicing - Management and Rating of Consumption Items",
            parent: 26,
            code: "3DX",
            level: 3,
            depth: 2
          },
          {
            id: 326,
            name: "Convergent Invoicing - Recurring and One-Off Items",
            parent: 26,
            code: "2T3",
            level: 3,
            depth: 2
          },
          {
            id: 327,
            name: "Convergent Invoicing - Revenue Sharing and Partner Settlement",
            parent: 26,
            code: "42L",
            level: 3,
            depth: 2
          },
          {
            id: 329,
            name: "SAP Fiori Analytical Apps for Contract Accounting Manager",
            parent: 26,
            code: "2KH",
            level: 3,
            depth: 2
          },
          {
            id: 330,
            name: "SAP Fiori Analytical Apps for Convergent Invoicing Specialist",
            parent: 26,
            code: "2KF",
            level: 3,
            depth: 2
          },
          {
            id: 331,
            name: "Subscription Management with Convergent Invoicing",
            parent: 26,
            code: "5IK",
            level: 3,
            depth: 2
          }
        ]
      },
      {
        id: 27,
        name: "Treasury Management",
        parent: 5,
        code: null,
        level: 2,
        depth: 1,
        children: [
          { id: 332, name: "Advanced Bank Account Management", parent: 27, code: "J77", level: 3, depth: 2 },
          { id: 334, name: "Advanced Cash Operations", parent: 27, code: "J78", level: 3, depth: 2 },
          { id: 335, name: "Advanced Payment Management", parent: 27, code: "4MT", level: 3, depth: 2 },
          {
            id: 336,
            name: "Application Interface to Retrieve Payment Files",
            parent: 27,
            code: "2YM",
            level: 3,
            depth: 2
          },
          { id: 337, name: "Asset-Backed Securities Management", parent: 27, code: "6B5", level: 3, depth: 2 },
          {
            id: 338,
            name: "Asset-Backed Securities Management - Group Ledger IFRS",
            parent: 27,
            code: "6B6",
            level: 3,
            depth: 2
          },
          {
            id: 339,
            name: "Asset-Backed Securities Management - Group Ledger US GAAP",
            parent: 27,
            code: "6B7",
            level: 3,
            depth: 2
          },
          { id: 340, name: "Automatic Market Rates Management", parent: 27, code: "1S4", level: 3, depth: 2 },
          { id: 341, name: "Bank Fee Management", parent: 27, code: "2O0", level: 3, depth: 2 },
          { id: 342, name: "Bank Guarantee Management", parent: 27, code: "2NZ", level: 3, depth: 2 },
          {
            id: 343,
            name: "Bank Guarantee Management - Group Ledger IFRS",
            parent: 27,
            code: "2O2",
            level: 3,
            depth: 2
          },
          {
            id: 344,
            name: "Bank Guarantee Management - Group Ledger US GAAP",
            parent: 27,
            code: "34F",
            level: 3,
            depth: 2
          },
          { id: 345, name: "Bank Integration with File Interface", parent: 27, code: "1EG", level: 3, depth: 2 },
          {
            id: 346,
            name: "Bank Integration with SAP Multi-Bank Connectivity",
            parent: 27,
            code: "16R",
            level: 3,
            depth: 2
          },
          { id: 347, name: "Basic Bank Account Management", parent: 27, code: "BFA", level: 3, depth: 2 },
          { id: 348, name: "Basic Cash Operations", parent: 27, code: "BFB", level: 3, depth: 2 },
          { id: 349, name: "Bond Management", parent: 27, code: "3WY", level: 3, depth: 2 },
          { id: 350, name: "Bond Management - Group Ledger IFRS", parent: 27, code: "3WZ", level: 3, depth: 2 },
          { id: 351, name: "Bond Management - Group Ledger US GAAP", parent: 27, code: "3X0", level: 3, depth: 2 },
          { id: 352, name: "Debt and Investment Management", parent: 27, code: "1WV", level: 3, depth: 2 },
          {
            id: 353,
            name: "Debt and Investment Management - Group Ledger IFRS",
            parent: 27,
            code: "1X7",
            level: 3,
            depth: 2
          },
          {
            id: 354,
            name: "Debt and Investment Management - Group Ledger US GAAP",
            parent: 27,
            code: "34C",
            level: 3,
            depth: 2
          },
          { id: 355, name: "Foreign Currency Risk Management", parent: 27, code: "1X1", level: 3, depth: 2 },
          {
            id: 356,
            name: "Foreign Currency Risk Management - Group Ledger IFRS",
            parent: 27,
            code: "1X9",
            level: 3,
            depth: 2
          },
          {
            id: 357,
            name: "Foreign Currency Risk Management - Group Ledger US GAAP",
            parent: 27,
            code: "34D",
            level: 3,
            depth: 2
          },
          {
            id: 358,
            name: "Hedge Accounting for FX Forward - Group Ledger IFRS",
            parent: 27,
            code: "1XD",
            level: 3,
            depth: 2
          },
          {
            id: 359,
            name: "Hedge Accounting for FX Forward - Group Ledger US GAAP",
            parent: 27,
            code: "3X3",
            level: 3,
            depth: 2
          },
          {
            id: 360,
            name: "Hedge Accounting for FX Forward - Local GAAP",
            parent: 27,
            code: "3X1",
            level: 3,
            depth: 2
          },
          {
            id: 361,
            name: "Hedge Accounting for FX Option - Group Ledger IFRS",
            parent: 27,
            code: "2RW",
            level: 3,
            depth: 2
          },
          {
            id: 362,
            name: "Hedge Accounting for FX Option - Group Ledger US GAAP",
            parent: 27,
            code: "3X4",
            level: 3,
            depth: 2
          },
          { id: 363, name: "Hedge Accounting for FX Option - Local GAAP", parent: 27, code: "3X2", level: 3, depth: 2 },
          {
            id: 364,
            name: "Hedge Accounting for FX Swap - Group Ledger IFRS",
            parent: 27,
            code: "2UF",
            level: 3,
            depth: 2
          },
          { id: 365, name: "In-House Banking", parent: 27, code: "6BP", level: 3, depth: 2 },
          {
            id: 366,
            name: "Intercompany Debt and Investment Mgmt - Group Ledger IFRS",
            parent: 27,
            code: "2OI",
            level: 3,
            depth: 2
          },
          {
            id: 367,
            name: "Intercompany Debt and Investment Mgmt - Group Ledger US GAAP",
            parent: 27,
            code: "34L",
            level: 3,
            depth: 2
          },
          {
            id: 368,
            name: "Intercompany Foreign Exchange - Group Ledger IFRS",
            parent: 27,
            code: "2HU",
            level: 3,
            depth: 2
          },
          {
            id: 369,
            name: "Intercompany Foreign Exchange - Group Ledger US GAAP",
            parent: 27,
            code: "34M",
            level: 3,
            depth: 2
          },
          { id: 370, name: "Intercompany Foreign Exchange Management", parent: 27, code: "2F2", level: 3, depth: 2 },
          { id: 371, name: "Intercompany Process for Cash Concentration", parent: 27, code: "2U8", level: 3, depth: 2 },
          {
            id: 372,
            name: "Intercompany Process for Debt and Investment Mgmt",
            parent: 27,
            code: "1YI",
            level: 3,
            depth: 2
          },
          { id: 373, name: "Interest Rate Derivatives Management", parent: 27, code: "1X3", level: 3, depth: 2 },
          {
            id: 374,
            name: "Interest Rate Derivatives Management - Group Ledger IFRS",
            parent: 27,
            code: "1XB",
            level: 3,
            depth: 2
          },
          {
            id: 375,
            name: "Interest Rate Derivatives Management - Group Ledger US GAAP",
            parent: 27,
            code: "34E",
            level: 3,
            depth: 2
          },
          { id: 376, name: "Letter of Credit Management", parent: 27, code: "49D", level: 3, depth: 2 },
          {
            id: 377,
            name: "Letter of Credit Management - Group Ledger IFRS",
            parent: 27,
            code: "49E",
            level: 3,
            depth: 2
          },
          {
            id: 378,
            name: "Letter of Credit Management - Group Ledger US GAAP",
            parent: 27,
            code: "49F",
            level: 3,
            depth: 2
          },
          { id: 379, name: "Liquidity Planning", parent: 27, code: "3L5", level: 3, depth: 2 },
          {
            id: 380,
            name: "Market Rates Management - Manually via Upload",
            parent: 27,
            code: "1XN",
            level: 3,
            depth: 2
          },
          { id: 381, name: "Money Market Mutual Fund Management", parent: 27, code: "2UN", level: 3, depth: 2 },
          {
            id: 382,
            name: "Money Market Mutual Fund Management - Group Ledger IFRS",
            parent: 27,
            code: "2UO",
            level: 3,
            depth: 2
          },
          {
            id: 383,
            name: "Money Market Mutual Fund Management - Group Ledger US GAAP",
            parent: 27,
            code: "34G",
            level: 3,
            depth: 2
          },
          {
            id: 384,
            name: "SAP Fiori Analytical Apps for Treasury and Risk Management",
            parent: 27,
            code: "1MN",
            level: 3,
            depth: 2
          },
          { id: 385, name: "Shareholding Management", parent: 27, code: "5WF", level: 3, depth: 2 },
          { id: 386, name: "Shareholding Management - Group Ledger IFRS", parent: 27, code: "5WG", level: 3, depth: 2 },
          {
            id: 387,
            name: "Shareholding Management - Group Ledger US GAAP",
            parent: 27,
            code: "5WH",
            level: 3,
            depth: 2
          },
          { id: 388, name: "Stock Management", parent: 27, code: "5OD", level: 3, depth: 2 },
          { id: 389, name: "Stock Management - Group Ledger IFRS", parent: 27, code: "5OE", level: 3, depth: 2 },
          { id: 390, name: "Stock Management - Group Ledger US GAAP", parent: 27, code: "5OF", level: 3, depth: 2 },
          {
            id: 391,
            name: "Treasury Correspondence Integration with SWIFT Network",
            parent: 27,
            code: "3QM",
            level: 3,
            depth: 2
          },
          { id: 392, name: "Treasury Trading via Trading Platforms", parent: 27, code: "2F5", level: 3, depth: 2 },
          { id: 393, name: "Treasury Workstation Accounting Integration", parent: 27, code: "33E", level: 3, depth: 2 },
          { id: 394, name: "Treasury Workstation Cash Integration", parent: 27, code: "34P", level: 3, depth: 2 },
          { id: 395, name: "Treasury Workstation Payment Integration", parent: 27, code: "3NA", level: 3, depth: 2 }
        ]
      }
    ]
  },
  {
    id: 6,
    name: "Human Resources",
    parent: "",
    code: null,
    level: 1,
    depth: 0,
    children: [
      {
        id: 28,
        name: "Core HR and Time Recording",
        parent: 6,
        code: null,
        level: 2,
        depth: 1,
        children: [
          { id: 400, name: "Time Recording", parent: 28, code: "1Q4", level: 3, depth: 2 },
          { id: 401, name: "Time Recording - Project-Based Services", parent: 28, code: "J12", level: 3, depth: 2 }
        ]
      }
    ]
  },
  {
    id: 7,
    name: "IT Management",
    parent: "",
    code: null,
    level: 1,
    depth: 0,
    children: [
      {
        id: 29,
        name: "Administration and Usability",
        parent: 7,
        code: null,
        level: 2,
        depth: 1,
        children: [{ id: 403, name: "Output Management", parent: 29, code: "1LQ", level: 3, depth: 2 }]
      }
    ]
  },
  {
    id: 8,
    name: "Manufacturing",
    parent: "",
    code: null,
    level: 1,
    depth: 0,
    children: [
      {
        id: 30,
        name: "Environment, Health, and Safety",
        parent: 8,
        code: null,
        level: 2,
        depth: 1,
        children: [
          {
            id: 404,
            name: "Compliance Management for Environment, Health and Safety",
            parent: 30,
            code: "4C8",
            level: 3,
            depth: 2
          },
          { id: 405, name: "Emissions Management", parent: 30, code: "4XD", level: 3, depth: 2 },
          { id: 406, name: "Health and Safety Management", parent: 30, code: "54A", level: 3, depth: 2 },
          { id: 407, name: "Lean Incident Management Reporting", parent: 30, code: "3FP", level: 3, depth: 2 },
          { id: 408, name: "Lean Management of Change Process", parent: 30, code: "4YX", level: 3, depth: 2 },
          { id: 409, name: "Waste Management", parent: 30, code: "60H", level: 3, depth: 2 }
        ]
      },
      {
        id: 31,
        name: "Manufacturing Insights",
        parent: 8,
        code: null,
        level: 2,
        depth: 1,
        children: [
          {
            id: 410,
            name: "From Planning to Fulfillment – Consumer Products",
            parent: 31,
            code: "6AA",
            level: 3,
            depth: 2
          },
          {
            id: 447,
            name: "From Planning to Fulfillment – Consumer Products",
            parent: 31,
            code: "6AA",
            level: 3,
            depth: 2
          },
          {
            id: 491,
            name: "From Planning to Fulfillment – Consumer Products",
            parent: 31,
            code: "6AA",
            level: 3,
            depth: 2
          },
          {
            id: 578,
            name: "From Planning to Fulfillment – Consumer Products",
            parent: 31,
            code: "6AA",
            level: 3,
            depth: 2
          },
          {
            id: 692,
            name: "From Planning to Fulfillment – Consumer Products",
            parent: 31,
            code: "6AA",
            level: 3,
            depth: 2
          },
          {
            id: 773,
            name: "From Planning to Fulfillment – Consumer Products",
            parent: 31,
            code: "6AA",
            level: 3,
            depth: 2
          }
        ]
      },
      {
        id: 32,
        name: "Manufacturing Operations",
        parent: 8,
        code: null,
        level: 2,
        depth: 1,
        children: [
          { id: 413, name: "Batch Management", parent: 32, code: "BLF", level: 3, depth: 2 },
          {
            id: 414,
            name: "Enable Manufacturing Subsidiary to Handle Assembly for Headquarter",
            parent: 32,
            code: "2WL",
            level: 3,
            depth: 2
          },
          {
            id: 415,
            name: "Inbound Storage Tank Management - Process Manufacturing",
            parent: 32,
            code: "3UK",
            level: 3,
            depth: 2
          },
          {
            id: 417,
            name: "Make-to-Order Production - Finished Goods Sales and Final Assembly",
            parent: 32,
            code: "BJE",
            level: 3,
            depth: 2
          },
          {
            id: 418,
            name: "Make-to-Order Production - Process Manufacturing",
            parent: 32,
            code: "3OK",
            level: 3,
            depth: 2
          },
          {
            id: 419,
            name: "Make-to-Order Production - Semifinished Goods Planning and Assembly",
            parent: 32,
            code: "1BM",
            level: 3,
            depth: 2
          },
          {
            id: 420,
            name: "Make-to-Order Production for Sales Kits with Variant Configuration",
            parent: 32,
            code: "4OC",
            level: 3,
            depth: 2
          },
          {
            id: 421,
            name: "Make-to-Order Production with Variant Configuration",
            parent: 32,
            code: "1YT",
            level: 3,
            depth: 2
          },
          {
            id: 422,
            name: "Make-to-Stock - Process Manufacturing Based on Process Order",
            parent: 32,
            code: "BJ8",
            level: 3,
            depth: 2
          },
          {
            id: 423,
            name: "Make-to-Stock - Process Manufacturing Based on Production Order",
            parent: 32,
            code: "2UG",
            level: 3,
            depth: 2
          },
          {
            id: 424,
            name: "Make-to-Stock Production - Discrete Manufacturing",
            parent: 32,
            code: "BJ5",
            level: 3,
            depth: 2
          },
          {
            id: 425,
            name: "Make-to-Stock Production - Repetitive Manufacturing",
            parent: 32,
            code: "BJH",
            level: 3,
            depth: 2
          },
          {
            id: 426,
            name: "Make-to-Stock Production with Variant Configuration",
            parent: 32,
            code: "21D",
            level: 3,
            depth: 2
          },
          {
            id: 427,
            name: "Make-to-Stock with Co- and By-Products - Process Manufacturing",
            parent: 32,
            code: "3L7",
            level: 3,
            depth: 2
          },
          {
            id: 428,
            name: "Make-to-Stock with Silo Material - Process Manufacturing",
            parent: 32,
            code: "3UL",
            level: 3,
            depth: 2
          },
          {
            id: 429,
            name: "Predictive Analytics Model Training - Supply Chain",
            parent: 32,
            code: "20N",
            level: 3,
            depth: 2
          },
          {
            id: 430,
            name: "Process Manufacturing Execution - SAP Digital Manufacturing Cloud",
            parent: 32,
            code: "4DN",
            level: 3,
            depth: 2
          },
          {
            id: 434,
            name: "Production Operations with Manufacturing Execution System",
            parent: 32,
            code: "1Y5",
            level: 3,
            depth: 2
          },
          {
            id: 435,
            name: "Production Operations with SAP Manufacturing Execution",
            parent: 32,
            code: "2JN",
            level: 3,
            depth: 2
          },
          {
            id: 436,
            name: "Production Order Processing with Third-Party Warehouse Management",
            parent: 32,
            code: "2RU",
            level: 3,
            depth: 2
          },
          {
            id: 437,
            name: "Production Subcontracting - External Processing",
            parent: 32,
            code: "BJK",
            level: 3,
            depth: 2
          },
          {
            id: 438,
            name: "Quality Management in Discrete Manufacturing - Consumer Products",
            parent: 32,
            code: "5HK",
            level: 3,
            depth: 2
          },
          {
            id: 439,
            name: "Rework Processing - Stock-Manufactured Material",
            parent: 32,
            code: "BJN",
            level: 3,
            depth: 2
          },
          { id: 440, name: "Rework Processing - Work-in-Process", parent: 32, code: "BJQ", level: 3, depth: 2 },
          { id: 441, name: "Serial Number Management", parent: 32, code: "BLL", level: 3, depth: 2 },
          {
            id: 442,
            name: "Subsidiary as Production Unit and Internal Supplier to Headquarters",
            parent: 32,
            code: "21T",
            level: 3,
            depth: 2
          },
          { id: 443, name: "Tank Trailer Filling with Residuals", parent: 32, code: "42N", level: 3, depth: 2 },
          {
            id: 444,
            name: "Third-Party Shop Floor Execution - Discrete Industry",
            parent: 32,
            code: "3W4",
            level: 3,
            depth: 2
          },
          {
            id: 445,
            name: "Third-Party Shop Floor Execution - Process Industry",
            parent: 32,
            code: "3W3",
            level: 3,
            depth: 2
          },
          {
            id: 456,
            name: "Production Subcontracting - External Processing",
            parent: 32,
            code: "BJK",
            level: 3,
            depth: 2
          },
          { id: 457, name: "Serial Number Management", parent: 32, code: "BLL", level: 3, depth: 2 },
          {
            id: 464,
            name: "Quality Management in Discrete Manufacturing - Consumer Products",
            parent: 32,
            code: "5HK",
            level: 3,
            depth: 2
          },
          {
            id: 471,
            name: "Make-to-Order Production - Finished Goods Sales and Final Assembly",
            parent: 32,
            code: "BJE",
            level: 3,
            depth: 2
          },
          {
            id: 472,
            name: "Make-to-Order Production - Semifinished Goods Planning and Assembly",
            parent: 32,
            code: "1BM",
            level: 3,
            depth: 2
          },
          {
            id: 473,
            name: "Make-to-Order Production with Variant Configuration",
            parent: 32,
            code: "1YT",
            level: 3,
            depth: 2
          },
          {
            id: 474,
            name: "Make-to-Stock - Process Manufacturing Based on Process Order",
            parent: 32,
            code: "BJ8",
            level: 3,
            depth: 2
          },
          {
            id: 475,
            name: "Make-to-Stock - Process Manufacturing Based on Production Order",
            parent: 32,
            code: "2UG",
            level: 3,
            depth: 2
          },
          {
            id: 476,
            name: "Make-to-Stock Production - Discrete Manufacturing",
            parent: 32,
            code: "BJ5",
            level: 3,
            depth: 2
          },
          {
            id: 477,
            name: "Make-to-Stock Production - Repetitive Manufacturing",
            parent: 32,
            code: "BJH",
            level: 3,
            depth: 2
          },
          {
            id: 478,
            name: "Make-to-Stock Production with Variant Configuration",
            parent: 32,
            code: "21D",
            level: 3,
            depth: 2
          },
          {
            id: 487,
            name: "Quality Management in Discrete Manufacturing - Consumer Products",
            parent: 32,
            code: "5HK",
            level: 3,
            depth: 2
          },
          { id: 490, name: "Batch Management", parent: 32, code: "BLF", level: 3, depth: 2 },
          {
            id: 498,
            name: "Quality Management in Discrete Manufacturing - Consumer Products",
            parent: 32,
            code: "5HK",
            level: 3,
            depth: 2
          },
          {
            id: 536,
            name: "Make-to-Order Production with Variant Configuration",
            parent: 32,
            code: "1YT",
            level: 3,
            depth: 2
          },
          {
            id: 537,
            name: "Make-to-Stock Production with Variant Configuration",
            parent: 32,
            code: "21D",
            level: 3,
            depth: 2
          },
          { id: 683, name: "Batch Management", parent: 32, code: "BLF", level: 3, depth: 2 },
          {
            id: 695,
            name: "Quality Management in Discrete Manufacturing - Consumer Products",
            parent: 32,
            code: "5HK",
            level: 3,
            depth: 2
          },
          {
            id: 718,
            name: "Quality Management in Discrete Manufacturing - Consumer Products",
            parent: 32,
            code: "5HK",
            level: 3,
            depth: 2
          },
          { id: 760, name: "Serial Number Management", parent: 32, code: "BLL", level: 3, depth: 2 },
          { id: 810, name: "Batch Management", parent: 32, code: "BLF", level: 3, depth: 2 },
          {
            id: 828,
            name: "Production Order Processing with Third-Party Warehouse Management",
            parent: 32,
            code: "2RU",
            level: 3,
            depth: 2
          },
          { id: 840, name: "Batch Management", parent: 32, code: "BLF", level: 3, depth: 2 },
          { id: 842, name: "Serial Number Management", parent: 32, code: "BLL", level: 3, depth: 2 }
        ]
      },
      {
        id: 33,
        name: "Manufacturing Options",
        parent: 8,
        code: null,
        level: 2,
        depth: 1,
        children: [
          { id: 448, name: "Just-In-Time Supply to Customer", parent: 33, code: "2EM", level: 3, depth: 2 },
          {
            id: 449,
            name: "Just-In-Time Supply to Customer with Delivery Confirmation",
            parent: 33,
            code: "53X",
            level: 3,
            depth: 2
          },
          {
            id: 451,
            name: "Material Replenishment with Kanban - External Procurement",
            parent: 33,
            code: "1.00E+03",
            level: 3,
            depth: 2
          },
          {
            id: 452,
            name: "Material Replenishment with Kanban - In-House Production",
            parent: 33,
            code: "4B3",
            level: 3,
            depth: 2
          },
          {
            id: 453,
            name: "Material Replenishment with Kanban - Stock Transfer",
            parent: 33,
            code: "4B4",
            level: 3,
            depth: 2
          },
          { id: 458, name: "Subcontracting", parent: 33, code: "BMY", level: 3, depth: 2 },
          { id: 459, name: "Subcontracting - Consumer Products", parent: 33, code: "5W4", level: 3, depth: 2 },
          { id: 460, name: "Subcontracting with Ariba Network", parent: 33, code: "5I2", level: 3, depth: 2 },
          { id: 465, name: "Subcontracting - Consumer Products", parent: 33, code: "5W4", level: 3, depth: 2 },
          { id: 489, name: "Subcontracting - Consumer Products", parent: 33, code: "5W4", level: 3, depth: 2 },
          { id: 504, name: "Subcontracting - Consumer Products", parent: 33, code: "5W4", level: 3, depth: 2 },
          { id: 697, name: "Subcontracting - Consumer Products", parent: 33, code: "5W4", level: 3, depth: 2 },
          { id: 722, name: "Subcontracting", parent: 33, code: "BMY", level: 3, depth: 2 },
          { id: 723, name: "Subcontracting - Consumer Products", parent: 33, code: "5W4", level: 3, depth: 2 },
          { id: 724, name: "Subcontracting with Ariba Network", parent: 33, code: "5I2", level: 3, depth: 2 },
          { id: 762, name: "Subcontracting", parent: 33, code: "BMY", level: 3, depth: 2 },
          { id: 763, name: "Subcontracting - Consumer Products", parent: 33, code: "5W4", level: 3, depth: 2 },
          { id: 764, name: "Subcontracting with Ariba Network", parent: 33, code: "5I2", level: 3, depth: 2 },
          { id: 783, name: "Subcontracting - Consumer Products", parent: 33, code: "5W4", level: 3, depth: 2 },
          { id: 838, name: "Subcontracting", parent: 33, code: "BMY", level: 3, depth: 2 },
          { id: 848, name: "Subcontracting - Consumer Products", parent: 33, code: "5W4", level: 3, depth: 2 }
        ]
      },
      {
        id: 34,
        name: "Production Engineering",
        parent: 8,
        code: null,
        level: 2,
        depth: 1,
        children: [
          {
            id: 461,
            name: "Change Manufacturing Bill of Material for Production",
            parent: 34,
            code: "3LO",
            level: 3,
            depth: 2
          },
          {
            id: 462,
            name: "Mass Change Manufacturing Bill of Material for Production",
            parent: 34,
            code: "3LP",
            level: 3,
            depth: 2
          }
        ]
      },
      {
        id: 35,
        name: "Production Planning",
        parent: 8,
        code: null,
        level: 2,
        depth: 1,
        children: [
          { id: 467, name: "Demand-Driven Buffer Level Management", parent: 35, code: "1Y2", level: 3, depth: 2 },
          {
            id: 468,
            name: "Demand-Driven Replenishment Planning and Execution",
            parent: 35,
            code: "2QI",
            level: 3,
            depth: 2
          },
          {
            id: 469,
            name: "Engineer-to-Order Production with Variant Configuration",
            parent: 35,
            code: "4R8",
            level: 3,
            depth: 2
          },
          { id: 479, name: "Material Requirements Planning", parent: 35, code: "J44", level: 3, depth: 2 },
          {
            id: 480,
            name: "MRP Change Request Integration with Ariba Network",
            parent: 35,
            code: "65D",
            level: 3,
            depth: 2
          },
          { id: 481, name: "Predictive Material and Resource Planning", parent: 35, code: "4B5", level: 3, depth: 2 },
          { id: 484, name: "Production Capacity Evaluation", parent: 35, code: "31L", level: 3, depth: 2 },
          { id: 486, name: "Production Capacity Leveling", parent: 35, code: "3LQ", level: 3, depth: 2 },
          {
            id: 488,
            name: "SAP Fiori Analytical Apps for Production Planning",
            parent: 35,
            code: "2JD",
            level: 3,
            depth: 2
          },
          {
            id: 531,
            name: "Engineer-to-Order Production with Variant Configuration",
            parent: 35,
            code: "4R8",
            level: 3,
            depth: 2
          },
          {
            id: 573,
            name: "Engineer-to-Order Production with Variant Configuration",
            parent: 35,
            code: "4R8",
            level: 3,
            depth: 2
          },
          {
            id: 743,
            name: "MRP Change Request Integration with Ariba Network",
            parent: 35,
            code: "65D",
            level: 3,
            depth: 2
          }
        ]
      },
      {
        id: 36,
        name: "Quality Management",
        parent: 8,
        code: null,
        level: 2,
        depth: 1,
        children: [
          { id: 493, name: "Nonconformance Management", parent: 36, code: "2QN", level: 3, depth: 2 },
          {
            id: 495,
            name: "Quality Management for Complaints against Suppliers",
            parent: 36,
            code: "2F9",
            level: 3,
            depth: 2
          },
          {
            id: 496,
            name: "Quality Management for Complaints from Customers",
            parent: 36,
            code: "2FA",
            level: 3,
            depth: 2
          },
          {
            id: 497,
            name: "Quality Management in Discrete Manufacturing",
            parent: 36,
            code: "1.00E+01",
            level: 3,
            depth: 2
          },
          { id: 499, name: "Quality Management in Procurement", parent: 36, code: "1FM", level: 3, depth: 2 },
          { id: 500, name: "Quality Management in Sales", parent: 36, code: "1MP", level: 3, depth: 2 },
          { id: 501, name: "Quality Management in Stock Handling", parent: 36, code: "1MR", level: 3, depth: 2 },
          { id: 502, name: "Quality Management of Internal Problems", parent: 36, code: "2QP", level: 3, depth: 2 },
          {
            id: 503,
            name: "SAP Fiori Analytical Apps for Quality Management",
            parent: 36,
            code: "2V0",
            level: 3,
            depth: 2
          },
          {
            id: 829,
            name: "Quality Management for Complaints against Suppliers",
            parent: 36,
            code: "2F9",
            level: 3,
            depth: 2
          },
          {
            id: 830,
            name: "Quality Management for Complaints from Customers",
            parent: 36,
            code: "2FA",
            level: 3,
            depth: 2
          },
          { id: 831, name: "Quality Management in Procurement", parent: 36, code: "1FM", level: 3, depth: 2 },
          { id: 832, name: "Quality Management in Sales", parent: 36, code: "1MP", level: 3, depth: 2 },
          { id: 833, name: "Quality Management in Stock Handling", parent: 36, code: "1MR", level: 3, depth: 2 }
        ]
      }
    ]
  },
  {
    id: 9,
    name: "R&D/Engineering",
    parent: "",
    code: null,
    level: 1,
    depth: 0,
    children: [
      {
        id: 37,
        name: "Enterprise Portfolio and Project Management",
        parent: 9,
        code: null,
        level: 2,
        depth: 1,
        children: [
          {
            id: 505,
            name: "Predictive Analytics Model Training - R&D/Engineering",
            parent: 37,
            code: "2Y7",
            level: 3,
            depth: 2
          },
          { id: 506, name: "Project Control – Capital Projects", parent: 37, code: "35F", level: 3, depth: 2 },
          { id: 508, name: "Project Control – Intercompany", parent: 37, code: "4AU", level: 3, depth: 2 },
          {
            id: 509,
            name: "Project Control – Research and Development Projects",
            parent: 37,
            code: "35E",
            level: 3,
            depth: 2
          },
          {
            id: 510,
            name: "Project Control - Resourcing and Procurement",
            parent: 37,
            code: "4RF",
            level: 3,
            depth: 2
          },
          { id: 512, name: "Project Data Replication to Central Finance", parent: 37, code: "3W1", level: 3, depth: 2 },
          { id: 513, name: "Project Review", parent: 37, code: "1YF", level: 3, depth: 2 }
        ]
      },
      {
        id: 38,
        name: "Product Compliance",
        parent: 9,
        code: null,
        level: 2,
        depth: 1,
        children: [
          { id: 514, name: "Assess Dangerous Goods for a Product", parent: 38, code: "3FC", level: 3, depth: 2 },
          { id: 515, name: "Assess Marketability of a Product", parent: 38, code: "31H", level: 3, depth: 2 },
          {
            id: 516,
            name: "Chemical Compliance Approval for Purchased Material and Supplier",
            parent: 38,
            code: "31G",
            level: 3,
            depth: 2
          },
          { id: 517, name: "Chemical Compliance in the Value Chain", parent: 38, code: "31J", level: 3, depth: 2 },
          { id: 518, name: "Dangerous Goods in the Value Chain", parent: 38, code: "3G8", level: 3, depth: 2 },
          { id: 519, name: "Manage Safety Data Sheets for Products", parent: 38, code: "3VR", level: 3, depth: 2 },
          {
            id: 520,
            name: "Request Regulatory Documentation from Service Provider",
            parent: 38,
            code: "6AV",
            level: 3,
            depth: 2
          },
          { id: 521, name: "Safety Data Sheets in the Value Chain", parent: 38, code: "3VQ", level: 3, depth: 2 },
          { id: 522, name: "Substance Volume Tracking", parent: 38, code: "4OL", level: 3, depth: 2 },
          { id: 523, name: "Supplier Compliance in the Value Chain", parent: 38, code: "5OJ", level: 3, depth: 2 },
          { id: 557, name: "Chemical Compliance in the Value Chain", parent: 38, code: "31J", level: 3, depth: 2 },
          { id: 568, name: "Dangerous Goods in the Value Chain", parent: 38, code: "3G8", level: 3, depth: 2 },
          { id: 596, name: "Safety Data Sheets in the Value Chain", parent: 38, code: "3VQ", level: 3, depth: 2 },
          { id: 780, name: "Supplier Compliance in the Value Chain", parent: 38, code: "5OJ", level: 3, depth: 2 }
        ]
      },
      {
        id: 39,
        name: "Product Lifecycle Management",
        parent: 9,
        code: null,
        level: 2,
        depth: 1,
        children: [
          { id: 524, name: "Advanced BOM Model", parent: 39, code: "64F", level: 3, depth: 2 },
          { id: 525, name: "Advanced Ingredient Replacement for Recipes", parent: 39, code: "22R", level: 3, depth: 2 },
          { id: 526, name: "Basic Handover of Bill of Material", parent: 39, code: "1ZI", level: 3, depth: 2 },
          { id: 527, name: "Embedded Software Management", parent: 39, code: "2G6", level: 3, depth: 2 },
          {
            id: 528,
            name: "Engineering Bill of Material - Change Master Management",
            parent: 39,
            code: "1R3",
            level: 3,
            depth: 2
          },
          { id: 529, name: "Engineering Bill of Material - Mass Change", parent: 39, code: "1R5", level: 3, depth: 2 },
          {
            id: 530,
            name: "Engineering Bill of Material - Versions Management",
            parent: 39,
            code: "1NR",
            level: 3,
            depth: 2
          },
          { id: 532, name: "Formulation - Recipe Development", parent: 39, code: "1QC", level: 3, depth: 2 },
          {
            id: 533,
            name: "Handover of Product Information to SAP Asset Intelligence Network",
            parent: 39,
            code: "2WK",
            level: 3,
            depth: 2
          },
          {
            id: 534,
            name: "Intelligent Content Processing for Document Classification",
            parent: 39,
            code: "2YC",
            level: 3,
            depth: 2
          },
          { id: 538, name: "Manage Documents", parent: 39, code: "22P", level: 3, depth: 2 },
          {
            id: 539,
            name: "Procurement of Materials with Variant Configuration",
            parent: 39,
            code: "2XU",
            level: 3,
            depth: 2
          },
          {
            id: 540,
            name: "Product Change Management with Change Records - Process Industries",
            parent: 39,
            code: "5QC",
            level: 3,
            depth: 2
          },
          {
            id: 541,
            name: "Product Change Mgmt with Change Records - Discrete Industries",
            parent: 39,
            code: "64E",
            level: 3,
            depth: 2
          },
          { id: 542, name: "Product Structure Management", parent: 39, code: "3ND", level: 3, depth: 2 },
          { id: 543, name: "Product Structure Template Management", parent: 39, code: "35Q", level: 3, depth: 2 },
          {
            id: 544,
            name: "Recipe Handover to Production - Bill of Material",
            parent: 39,
            code: "1QG",
            level: 3,
            depth: 2
          },
          { id: 545, name: "Requirements Driven Development", parent: 39, code: "2G4", level: 3, depth: 2 },
          {
            id: 546,
            name: "Set up Configurable Model using Variant Configuration",
            parent: 39,
            code: "22T",
            level: 3,
            depth: 2
          },
          { id: 547, name: "Software Compatibility Management", parent: 39, code: "2G5", level: 3, depth: 2 },
          { id: 548, name: "Specification Management for Recipes", parent: 39, code: "1QA", level: 3, depth: 2 },
          {
            id: 748,
            name: "Procurement of Materials with Variant Configuration",
            parent: 39,
            code: "2XU",
            level: 3,
            depth: 2
          }
        ]
      }
    ]
  },
  {
    id: 10,
    name: "Sales",
    parent: "",
    code: null,
    level: 1,
    depth: 0,
    children: [
      {
        id: 40,
        name: "Order and Contract Management",
        parent: 10,
        code: null,
        level: 2,
        depth: 1,
        children: [
          { id: 549, name: "Advanced Intercompany Sales", parent: 40, code: "5D2", level: 3, depth: 2 },
          {
            id: 550,
            name: "Analytics for Sales - Central Stock with Returns",
            parent: 40,
            code: "2QS",
            level: 3,
            depth: 2
          },
          {
            id: 551,
            name: "Automation of Order-to-Invoice with Ariba Network",
            parent: 40,
            code: "4A1",
            level: 3,
            depth: 2
          },
          {
            id: 553,
            name: "B2B Order Fulfillment with SAP Commerce Cloud",
            parent: 40,
            code: "2TY",
            level: 3,
            depth: 2
          },
          { id: 554, name: "Basic Business Process Scheduling", parent: 40, code: "6JM", level: 3, depth: 2 },
          {
            id: 555,
            name: "Billing of Costs Between Affiliated Companies",
            parent: 40,
            code: "3ZB",
            level: 3,
            depth: 2
          },
          { id: 556, name: "Business Partners for Retail", parent: 40, code: "5FH", level: 3, depth: 2 },
          { id: 558, name: "Create Sales Orders from Unstructured Data", parent: 40, code: "4X9", level: 3, depth: 2 },
          { id: 559, name: "Credit Memo Processing", parent: 40, code: "1EZ", level: 3, depth: 2 },
          { id: 560, name: "Customer Consignment", parent: 40, code: "1IU", level: 3, depth: 2 },
          {
            id: 561,
            name: "Customer Consignment with Intra-Community Simplification Rule",
            parent: 40,
            code: "5YA",
            level: 3,
            depth: 2
          },
          { id: 562, name: "Customer Returns", parent: 40, code: "BKP", level: 3, depth: 2 },
          {
            id: 563,
            name: "Customer Returns for Non-Stock and Service Materials",
            parent: 40,
            code: "3TE",
            level: 3,
            depth: 2
          },
          { id: 564, name: "Customer Returns for Sales Kits", parent: 40, code: "5CX", level: 3, depth: 2 },
          {
            id: 565,
            name: "Customer Returns Involving Supplier by Message Exchange",
            parent: 40,
            code: "3QD",
            level: 3,
            depth: 2
          },
          {
            id: 566,
            name: "Customer Returns Management - Consumer Products",
            parent: 40,
            code: "61V",
            level: 3,
            depth: 2
          },
          {
            id: 567,
            name: "Customer Returns with Direct Shipment from Customer to Supplier",
            parent: 40,
            code: "1Z3",
            level: 3,
            depth: 2
          },
          { id: 569, name: "Debit Memo Processing", parent: 40, code: "1F1", level: 3, depth: 2 },
          { id: 570, name: "Delivery Insights Enabled by IoT", parent: 40, code: "4IH", level: 3, depth: 2 },
          { id: 571, name: "Delivery Processing without Order Reference", parent: 40, code: "1MI", level: 3, depth: 2 },
          { id: 572, name: "Digital Payments - Sales", parent: 40, code: "1Z1", level: 3, depth: 2 },
          { id: 574, name: "Extended Intercompany Processes for Sales", parent: 40, code: "1WO", level: 3, depth: 2 },
          { id: 575, name: "External Billing", parent: 40, code: "1Z6", level: 3, depth: 2 },
          { id: 576, name: "Free Goods Processing", parent: 40, code: "BKA", level: 3, depth: 2 },
          { id: 577, name: "Free of Charge Delivery", parent: 40, code: "BDA", level: 3, depth: 2 },
          { id: 579, name: "Integration of Sales with External Buyers", parent: 40, code: "2EL", level: 3, depth: 2 },
          { id: 580, name: "Intercompany Process for Customer Returns", parent: 40, code: "2F4", level: 3, depth: 2 },
          {
            id: 581,
            name: "Intercompany Sales Order Processing - Domestic",
            parent: 40,
            code: "1HO",
            level: 3,
            depth: 2
          },
          {
            id: 582,
            name: "Intercompany Sales Order Processing - International",
            parent: 40,
            code: "1MX",
            level: 3,
            depth: 2
          },
          { id: 583, name: "Intercompany Stock Transfer", parent: 40, code: "1P9", level: 3, depth: 2 },
          {
            id: 584,
            name: "Internal Commissions Settlement with SAP Sales Cloud",
            parent: 40,
            code: "3TD",
            level: 3,
            depth: 2
          },
          { id: 585, name: "Invoice Correction Process with Credit Memo", parent: 40, code: "BKL", level: 3, depth: 2 },
          { id: 586, name: "Invoice Correction Process with Debit Memo", parent: 40, code: "BDQ", level: 3, depth: 2 },
          { id: 587, name: "Lean Customer Returns", parent: 40, code: "BDD", level: 3, depth: 2 },
          {
            id: 588,
            name: "Logistics with Third-Party Warehouse Management",
            parent: 40,
            code: "1ZQ",
            level: 3,
            depth: 2
          },
          { id: 589, name: "Omnichannel Convergent Billing", parent: 40, code: "1MC", level: 3, depth: 2 },
          {
            id: 590,
            name: "Opportunity-to-Order with SAP Cloud for Customer",
            parent: 40,
            code: "1VP",
            level: 3,
            depth: 2
          },
          { id: 591, name: "Planning Apps for Sales", parent: 40, code: "1O0", level: 3, depth: 2 },
          { id: 592, name: "Predictive Analytics Model Training - Sales", parent: 40, code: "2YJ", level: 3, depth: 2 },
          { id: 595, name: "Returnables Processing", parent: 40, code: "BDW", level: 3, depth: 2 },
          { id: 597, name: "Sale of Services", parent: 40, code: "2EQ", level: 3, depth: 2 },
          {
            id: 598,
            name: "Sales Commissions - External Sales Representative",
            parent: 40,
            code: "2TT",
            level: 3,
            depth: 2
          },
          { id: 599, name: "Sales Contract Management", parent: 40, code: "I9I", level: 3, depth: 2 },
          { id: 600, name: "Sales from Distribution Center", parent: 40, code: "3HS", level: 3, depth: 2 },
          { id: 601, name: "Sales Inquiry", parent: 40, code: "1IQ", level: 3, depth: 2 },
          {
            id: 602,
            name: "Sales of Non-Stock Item with Order-Specific Procurement",
            parent: 40,
            code: "BDN",
            level: 3,
            depth: 2
          },
          { id: 603, name: "Sales Order Entry with One-Time Customer", parent: 40, code: "BDH", level: 3, depth: 2 },
          {
            id: 604,
            name: "Sales Order Fulfillment Monitoring and Operations",
            parent: 40,
            code: "BKK",
            level: 3,
            depth: 2
          },
          {
            id: 605,
            name: "Sales Order Processing - SEPA Direct Debit Handling",
            parent: 40,
            code: "BKX",
            level: 3,
            depth: 2
          },
          {
            id: 606,
            name: "Sales Order Processing for Non-Stock Material",
            parent: 40,
            code: "2ET",
            level: 3,
            depth: 2
          },
          { id: 607, name: "Sales Order Processing for Sales Kits", parent: 40, code: "31Q", level: 3, depth: 2 },
          {
            id: 608,
            name: "Sales Order Processing with Customer Down Payment",
            parent: 40,
            code: "BKJ",
            level: 3,
            depth: 2
          },
          {
            id: 609,
            name: "Sales Order Processing with Invoice List and Collective Billing",
            parent: 40,
            code: "BKZ",
            level: 3,
            depth: 2
          },
          {
            id: 610,
            name: "Sales Processing using Third-Party with Shipping Notification",
            parent: 40,
            code: "BD3",
            level: 3,
            depth: 2
          },
          {
            id: 611,
            name: "Sales Processing using Third-Party with Variant Configuration",
            parent: 40,
            code: "4R6",
            level: 3,
            depth: 2
          },
          {
            id: 612,
            name: "Sales Processing Using Third-Party Without Shipping Notification",
            parent: 40,
            code: "BDK",
            level: 3,
            depth: 2
          },
          { id: 613, name: "Sales Quotation", parent: 40, code: "BDG", level: 3, depth: 2 },
          { id: 614, name: "Sales Rebate Processing", parent: 40, code: "1B6", level: 3, depth: 2 },
          { id: 615, name: "Sales Scheduling Agreement with Consignment", parent: 40, code: "4LZ", level: 3, depth: 2 },
          { id: 616, name: "Sales Scheduling Agreements", parent: 40, code: "3NR", level: 3, depth: 2 },
          { id: 617, name: "SAP Fiori Analytical Apps for Sales", parent: 40, code: "1BS", level: 3, depth: 2 },
          { id: 618, name: "Self-Billing", parent: 40, code: "4H2", level: 3, depth: 2 },
          { id: 619, name: "Sell from Stock", parent: 40, code: "BD9", level: 3, depth: 2 },
          { id: 620, name: "Sell from Stock - Consumer Products", parent: 40, code: "5HL", level: 3, depth: 2 },
          {
            id: 621,
            name: "Sell from stock with valuated stock in transit",
            parent: 40,
            code: "5MQ",
            level: 3,
            depth: 2
          },
          { id: 622, name: "Solution Order Management", parent: 40, code: "4GT", level: 3, depth: 2 },
          {
            id: 623,
            name: "Solution Order Management with Advanced Variant Configuration",
            parent: 40,
            code: "6HY",
            level: 3,
            depth: 2
          },
          { id: 624, name: "Subscription Management with Sales Billing", parent: 40, code: "57Z", level: 3, depth: 2 },
          { id: 709, name: "Intercompany Stock Transfer", parent: 40, code: "1P9", level: 3, depth: 2 },
          {
            id: 730,
            name: "Billing of Costs Between Affiliated Companies",
            parent: 40,
            code: "3ZB",
            level: 3,
            depth: 2
          },
          { id: 734, name: "Delivery Insights Enabled by IoT", parent: 40, code: "4IH", level: 3, depth: 2 },
          { id: 739, name: "Intercompany Stock Transfer", parent: 40, code: "1P9", level: 3, depth: 2 },
          {
            id: 741,
            name: "Logistics with Third-Party Warehouse Management",
            parent: 40,
            code: "1ZQ",
            level: 3,
            depth: 2
          },
          { id: 788, name: "Basic Business Process Scheduling", parent: 40, code: "6JM", level: 3, depth: 2 },
          { id: 790, name: "Intercompany Stock Transfer", parent: 40, code: "1P9", level: 3, depth: 2 },
          { id: 814, name: "Delivery Insights Enabled by IoT", parent: 40, code: "4IH", level: 3, depth: 2 },
          {
            id: 821,
            name: "Logistics with Third-Party Warehouse Management",
            parent: 40,
            code: "1ZQ",
            level: 3,
            depth: 2
          }
        ]
      }
    ]
  },
  {
    id: 11,
    name: "Service",
    parent: "",
    code: null,
    level: 1,
    depth: 0,
    children: [
      {
        id: 41,
        name: "Service Master Data & Agreement Management",
        parent: 11,
        code: null,
        level: 2,
        depth: 1,
        children: [
          { id: 625, name: "Service Contract Management", parent: 41, code: "3MO", level: 3, depth: 2 },
          {
            id: 626,
            name: "Service Contract Management with Advanced Variant Configuration",
            parent: 41,
            code: "6GU",
            level: 3,
            depth: 2
          },
          { id: 627, name: "Service Monitoring and Analytics", parent: 41, code: "43B", level: 3, depth: 2 },
          {
            id: 628,
            name: "Warranty Claim Management - Customer Claim Processing",
            parent: 41,
            code: "63Y",
            level: 3,
            depth: 2
          },
          {
            id: 629,
            name: "Warranty Claim Management - Supplier Recovery Processing",
            parent: 41,
            code: "5HR",
            level: 3,
            depth: 2
          }
        ]
      },
      {
        id: 42,
        name: "Service Operations & Processes",
        parent: 11,
        code: null,
        level: 2,
        depth: 1,
        children: [
          {
            id: 630,
            name: "Credit Memo Processing for Service Management",
            parent: 42,
            code: "4GG",
            level: 3,
            depth: 2
          },
          { id: 631, name: "In-house Repair", parent: 42, code: "3XK", level: 3, depth: 2 },
          { id: 632, name: "Intercompany Billing for Service Documents", parent: 42, code: "53Y", level: 3, depth: 2 },
          { id: 633, name: "Procurement for Service Management", parent: 42, code: "3NI", level: 3, depth: 2 },
          { id: 634, name: "Recurring Services", parent: 42, code: "4X5", level: 3, depth: 2 },
          {
            id: 635,
            name: "Service Management with Advanced Variant Configuration",
            parent: 42,
            code: "6GS",
            level: 3,
            depth: 2
          },
          { id: 636, name: "Service Order Management and Monitoring", parent: 42, code: "3D2", level: 3, depth: 2 },
          {
            id: 637,
            name: "Service Order processing with SAP Field Service Management",
            parent: 42,
            code: "49X",
            level: 3,
            depth: 2
          },
          { id: 638, name: "Service Quotation", parent: 42, code: "4GA", level: 3, depth: 2 }
        ]
      }
    ]
  },
  {
    id: 12,
    name: "Solutions for Specific Industries",
    parent: "",
    code: null,
    level: 1,
    depth: 0,
    children: [
      {
        id: 43,
        name: "Automotive",
        parent: 12,
        code: null,
        level: 2,
        depth: 1,
        children: [{ id: 639, name: "Digital Vehicle", parent: 43, code: "5ON", level: 3, depth: 2 }]
      },
      {
        id: 44,
        name: "Oil and Gas",
        parent: 12,
        code: null,
        level: 2,
        depth: 1,
        children: [
          { id: 640, name: "Field Logistics Planning and Execution", parent: 44, code: "40G", level: 3, depth: 2 },
          {
            id: 641,
            name: "Field Logistics Planning and Execution - Containers and Voyages",
            parent: 44,
            code: "6BA",
            level: 3,
            depth: 2
          },
          {
            id: 642,
            name: "Field Logistics Planning and Execution – Direct Procurement",
            parent: 44,
            code: "5OM",
            level: 3,
            depth: 2
          },
          {
            id: 643,
            name: "Field Logistics Planning and Execution – Supplier Items",
            parent: 44,
            code: "4AH",
            level: 3,
            depth: 2
          },
          { id: 644, name: "Mass Processing of Assets", parent: 44, code: "4ON", level: 3, depth: 2 },
          { id: 645, name: "Production Volume Capture", parent: 44, code: "4AG", level: 3, depth: 2 },
          { id: 646, name: "Revenue Accounting - Oil and Gas Production", parent: 44, code: "5NM", level: 3, depth: 2 }
        ]
      },
      {
        id: 45,
        name: "Professional Services",
        parent: 12,
        code: null,
        level: 2,
        depth: 1,
        children: [
          {
            id: 647,
            name: "Advanced Resource Management - Project-Based Services",
            parent: 45,
            code: "1KC",
            level: 3,
            depth: 2
          },
          {
            id: 648,
            name: "Basic Resource Management - Project-Based Services",
            parent: 45,
            code: "2MV",
            level: 3,
            depth: 2
          },
          { id: 649, name: "Customer Project Analysis", parent: 45, code: "BGI", level: 3, depth: 2 },
          {
            id: 650,
            name: "Customer Project Management - Project-Based Services",
            parent: 45,
            code: "J11",
            level: 3,
            depth: 2
          },
          {
            id: 654,
            name: "Intercompany Billing for Cross-Company Cost Accounting Postings",
            parent: 45,
            code: "4AN",
            level: 3,
            depth: 2
          },
          {
            id: 655,
            name: "Intercompany Processes - Project-Based Services",
            parent: 45,
            code: "16T",
            level: 3,
            depth: 2
          },
          {
            id: 656,
            name: "Internal Project Management - Project-Based Services",
            parent: 45,
            code: "1A8",
            level: 3,
            depth: 2
          },
          {
            id: 657,
            name: "Project Billing - Project-Based Services",
            parent: 45,
            code: "4.00E+09",
            level: 3,
            depth: 2
          },
          {
            id: 658,
            name: "Sales Order Processing - Project-Based Services",
            parent: 45,
            code: "J14",
            level: 3,
            depth: 2
          },
          {
            id: 659,
            name: "Service and Material Procurement - Project-Based Services",
            parent: 45,
            code: "J13",
            level: 3,
            depth: 2
          },
          { id: 660, name: "Utilization Analysis", parent: 45, code: "BGJ", level: 3, depth: 2 }
        ]
      },
      {
        id: 46,
        name: "Retail",
        parent: 12,
        code: null,
        level: 2,
        depth: 1,
        children: [
          { id: 661, name: "Assortments", parent: 46, code: "3I5", level: 3, depth: 2 },
          { id: 662, name: "In-Store Customer Order Management", parent: 46, code: "3HU", level: 3, depth: 2 },
          { id: 663, name: "In-Store Inventory Management", parent: 46, code: "3HR", level: 3, depth: 2 },
          { id: 664, name: "In-Store Merchandise Management", parent: 46, code: "3HQ", level: 3, depth: 2 },
          { id: 665, name: "In-Store Purchase Order Management", parent: 46, code: "5FY", level: 3, depth: 2 },
          {
            id: 666,
            name: "Inventory Management for Distribution Centers",
            parent: 46,
            code: "5FQ",
            level: 3,
            depth: 2
          },
          { id: 667, name: "Merchandise Allocation", parent: 46, code: "5FL", level: 3, depth: 2 },
          { id: 668, name: "My Store Management", parent: 46, code: "5FW", level: 3, depth: 2 },
          { id: 669, name: "Omnichannel Order Fulfillment for Retail", parent: 46, code: "5FZ", level: 3, depth: 2 },
          { id: 670, name: "Product Taxonomies for Retail", parent: 46, code: "5FJ", level: 3, depth: 2 },
          { id: 671, name: "Products for Retail", parent: 46, code: "3I1", level: 3, depth: 2 },
          {
            id: 672,
            name: "Replenishment Planning for Distribution Centers",
            parent: 46,
            code: "3I8",
            level: 3,
            depth: 2
          },
          { id: 673, name: "Replenishment Planning for Stores", parent: 46, code: "3I7", level: 3, depth: 2 },
          { id: 674, name: "Retail Promotions", parent: 46, code: "637", level: 3, depth: 2 },
          { id: 675, name: "Returns from Store", parent: 46, code: "5FR", level: 3, depth: 2 },
          { id: 676, name: "Sales Price Valuation for Retail", parent: 46, code: "5FT", level: 3, depth: 2 },
          { id: 677, name: "Sales Pricing for Retail", parent: 46, code: "3I4", level: 3, depth: 2 },
          { id: 678, name: "Sites for Retail", parent: 46, code: "3I3", level: 3, depth: 2 },
          { id: 679, name: "Store Connectivity - Inbound", parent: 46, code: "3HV", level: 3, depth: 2 },
          { id: 680, name: "Store Connectivity - Outbound", parent: 46, code: "3I2", level: 3, depth: 2 },
          { id: 681, name: "Store Ordering from Distribution Center", parent: 46, code: "5FX", level: 3, depth: 2 },
          { id: 755, name: "Returns from Store", parent: 46, code: "5FR", level: 3, depth: 2 }
        ]
      }
    ]
  },
  {
    id: 13,
    name: "Sourcing and Procurement",
    parent: "",
    code: null,
    level: 1,
    depth: 0,
    children: [
      {
        id: 47,
        name: "Central Procurement",
        parent: 13,
        code: null,
        level: 2,
        depth: 1,
        children: [
          {
            id: 682,
            name: "Automation of Central Procurement Quotes with Ariba Network",
            parent: 47,
            code: "5JT",
            level: 3,
            depth: 2
          },
          { id: 684, name: "Central Procurement with SAP Ariba Sourcing", parent: 47, code: "4QN", level: 3, depth: 2 },
          { id: 685, name: "Central Purchase Contracts", parent: 47, code: "2ME", level: 3, depth: 2 },
          { id: 686, name: "Central Purchase Requisition Processing", parent: 47, code: "47J", level: 3, depth: 2 },
          { id: 687, name: "Central Purchasing", parent: 47, code: "2XT", level: 3, depth: 2 },
          { id: 688, name: "Central Requisitioning", parent: 47, code: "1XI", level: 3, depth: 2 },
          { id: 689, name: "Central Sourcing", parent: 47, code: "3ZF", level: 3, depth: 2 },
          {
            id: 690,
            name: "Contract for Central Procurement with SAP Ariba Contracts",
            parent: 47,
            code: "4B0",
            level: 3,
            depth: 2
          },
          { id: 691, name: "Direct Material Sourcing", parent: 47, code: "4RD", level: 3, depth: 2 },
          {
            id: 693,
            name: "Guided Buying for Central Procurement with SAP Ariba Buying",
            parent: 47,
            code: "3EN",
            level: 3,
            depth: 2
          },
          {
            id: 694,
            name: "Procurement with and Without QM - Consumer Products",
            parent: 47,
            code: "5W3",
            level: 3,
            depth: 2
          },
          {
            id: 696,
            name: "Real-Time Reporting and Monitoring for Procurement",
            parent: 47,
            code: "1JI",
            level: 3,
            depth: 2
          },
          { id: 698, name: "Vendor Performance - Consumer Products", parent: 47, code: "6A6", level: 3, depth: 2 },
          {
            id: 716,
            name: "Procurement with and Without QM - Consumer Products",
            parent: 47,
            code: "5W3",
            level: 3,
            depth: 2
          },
          {
            id: 737,
            name: "Guided Buying for Central Procurement with SAP Ariba Buying",
            parent: 47,
            code: "3EN",
            level: 3,
            depth: 2
          },
          {
            id: 751,
            name: "Procurement with and Without QM - Consumer Products",
            parent: 47,
            code: "5W3",
            level: 3,
            depth: 2
          },
          {
            id: 767,
            name: "Procurement with and Without QM - Consumer Products",
            parent: 47,
            code: "5W3",
            level: 3,
            depth: 2
          },
          {
            id: 768,
            name: "Real-Time Reporting and Monitoring for Procurement",
            parent: 47,
            code: "1JI",
            level: 3,
            depth: 2
          },
          { id: 770, name: "Vendor Performance - Consumer Products", parent: 47, code: "6A6", level: 3, depth: 2 },
          { id: 772, name: "Direct Material Sourcing", parent: 47, code: "4RD", level: 3, depth: 2 },
          {
            id: 775,
            name: "Procurement with and Without QM - Consumer Products",
            parent: 47,
            code: "5W3",
            level: 3,
            depth: 2
          },
          { id: 786, name: "Vendor Performance - Consumer Products", parent: 47, code: "6A6", level: 3, depth: 2 }
        ]
      },
      {
        id: 48,
        name: "Invoice Management",
        parent: 13,
        code: null,
        level: 2,
        depth: 1,
        children: [
          { id: 699, name: "Advanced Intercompany Stock Transfer", parent: 48, code: "5HP", level: 3, depth: 2 },
          { id: 701, name: "Automated Invoice Settlement – Automotive", parent: 48, code: "5L6", level: 3, depth: 2 },
          {
            id: 702,
            name: "Automated Purchase-to-Pay with SAP Ariba Commerce Automation",
            parent: 48,
            code: "J82",
            level: 3,
            depth: 2
          },
          { id: 704, name: "Central Invoicing", parent: 48, code: "41G", level: 3, depth: 2 },
          { id: 705, name: "Complementary Procurement Functions", parent: 48, code: "2TW", level: 3, depth: 2 },
          { id: 706, name: "Consumable Purchasing", parent: 48, code: "BNX", level: 3, depth: 2 },
          { id: 707, name: "Direct Procurement with Inbound Delivery", parent: 48, code: "2TX", level: 3, depth: 2 },
          {
            id: 708,
            name: "External Workforce Procurement via SAP Fieldglass",
            parent: 48,
            code: "22K",
            level: 3,
            depth: 2
          },
          { id: 710, name: "Intercompany Stock Transfer Returns", parent: 48, code: "2TS", level: 3, depth: 2 },
          { id: 711, name: "Invoice Processing by OpenText", parent: 48, code: "1LE", level: 3, depth: 2 },
          { id: 712, name: "Invoice Verification for Retail", parent: 48, code: "5FN", level: 3, depth: 2 },
          { id: 714, name: "Procurement of Direct Materials", parent: 48, code: "J45", level: 3, depth: 2 },
          { id: 715, name: "Procurement of Services", parent: 48, code: "22Z", level: 3, depth: 2 },
          {
            id: 717,
            name: "Propose Resolution for Invoice Payment Block",
            parent: 48,
            code: "2XX",
            level: 3,
            depth: 2
          },
          { id: 719, name: "Requisitioning", parent: 48, code: "18J", level: 3, depth: 2 },
          {
            id: 720,
            name: "SAP Central Invoice Management Backend Enablement",
            parent: 48,
            code: "4N6",
            level: 3,
            depth: 2
          },
          {
            id: 721,
            name: "Service Procurement with Ariba Network and SAP Fieldglass",
            parent: 48,
            code: "4R2",
            level: 3,
            depth: 2
          },
          { id: 725, name: "Supplier Consignment", parent: 48, code: "2LG", level: 3, depth: 2 },
          { id: 726, name: "Advanced Intercompany Stock Transfer", parent: 48, code: "5HP", level: 3, depth: 2 },
          {
            id: 728,
            name: "Automated Purchase-to-Pay with SAP Ariba Commerce Automation",
            parent: 48,
            code: "J82",
            level: 3,
            depth: 2
          },
          { id: 731, name: "Complementary Procurement Functions", parent: 48, code: "2TW", level: 3, depth: 2 },
          { id: 732, name: "Consumable Purchasing", parent: 48, code: "BNX", level: 3, depth: 2 },
          { id: 735, name: "Direct Procurement with Inbound Delivery", parent: 48, code: "2TX", level: 3, depth: 2 },
          { id: 740, name: "Intercompany Stock Transfer Returns", parent: 48, code: "2TS", level: 3, depth: 2 },
          { id: 747, name: "Procurement of Direct Materials", parent: 48, code: "J45", level: 3, depth: 2 },
          { id: 750, name: "Procurement of Services", parent: 48, code: "22Z", level: 3, depth: 2 },
          { id: 754, name: "Requisitioning", parent: 48, code: "18J", level: 3, depth: 2 },
          {
            id: 761,
            name: "Service Procurement with Ariba Network and SAP Fieldglass",
            parent: 48,
            code: "4R2",
            level: 3,
            depth: 2
          },
          { id: 765, name: "Supplier Consignment", parent: 48, code: "2LG", level: 3, depth: 2 },
          {
            id: 769,
            name: "Service Procurement with Ariba Network and SAP Fieldglass",
            parent: 48,
            code: "4R2",
            level: 3,
            depth: 2
          },
          { id: 806, name: "Advanced Intercompany Stock Transfer", parent: 48, code: "5HP", level: 3, depth: 2 },
          {
            id: 808,
            name: "Automated Purchase-to-Pay with SAP Ariba Commerce Automation",
            parent: 48,
            code: "J82",
            level: 3,
            depth: 2
          },
          { id: 811, name: "Complementary Procurement Functions", parent: 48, code: "2TW", level: 3, depth: 2 },
          { id: 815, name: "Direct Procurement with Inbound Delivery", parent: 48, code: "2TX", level: 3, depth: 2 },
          { id: 826, name: "Procurement of Direct Materials", parent: 48, code: "J45", level: 3, depth: 2 },
          { id: 839, name: "Supplier Consignment", parent: 48, code: "2LG", level: 3, depth: 2 }
        ]
      },
      {
        id: 49,
        name: "Operational Procurement",
        parent: 13,
        code: null,
        level: 2,
        depth: 1,
        children: [
          {
            id: 729,
            name: "Automation of Source-to-Pay with Ariba Network",
            parent: 49,
            code: "42K",
            level: 3,
            depth: 2
          },
          { id: 733, name: "Cross-Company Purchasing Organization", parent: 49, code: "2R3", level: 3, depth: 2 },
          {
            id: 736,
            name: "Guided Buying Capability with SAP Ariba Buying",
            parent: 49,
            code: "2NV",
            level: 3,
            depth: 2
          },
          {
            id: 738,
            name: "Integration of Procurement with External Suppliers",
            parent: 49,
            code: "2EJ",
            level: 3,
            depth: 2
          },
          { id: 744, name: "Next-Generation Buying Experience", parent: 49, code: "4AI", level: 3, depth: 2 },
          {
            id: 745,
            name: "Predictive Analytics for Purchase Contract Quantity Consumption",
            parent: 49,
            code: "1QR",
            level: 3,
            depth: 2
          },
          { id: 746, name: "Procurement for Retail", parent: 49, code: "5FM", level: 3, depth: 2 },
          { id: 749, name: "Procurement of Pipeline Materials", parent: 49, code: "41F", level: 3, depth: 2 },
          { id: 753, name: "Purchasing Rebate Processing", parent: 49, code: "2R7", level: 3, depth: 2 },
          { id: 756, name: "Returns to Supplier for Retail", parent: 49, code: "5FO", level: 3, depth: 2 },
          {
            id: 757,
            name: "Scheduling Agreement for Consignment Procurement",
            parent: 49,
            code: "2NY",
            level: 3,
            depth: 2
          },
          {
            id: 758,
            name: "Scheduling Agreement for Subcontracting Procurement",
            parent: 49,
            code: "2NX",
            level: 3,
            depth: 2
          },
          { id: 759, name: "Scheduling Agreements in Procurement", parent: 49, code: "BMR", level: 3, depth: 2 }
        ]
      },
      { id: 50, name: "Procurement Analytics", parent: 13, code: null, level: 2, depth: 1 },
      {
        id: 51,
        name: "Sourcing and Contract Management",
        parent: 13,
        code: null,
        level: 2,
        depth: 1,
        children: [
          {
            id: 771,
            name: "Contract Management with SAP Ariba Contracts",
            parent: 51,
            code: "4AZ",
            level: 3,
            depth: 2
          },
          { id: 774, name: "Preferred Supplier List", parent: 51, code: "4RI", level: 3, depth: 2 },
          { id: 776, name: "Purchase Contract", parent: 51, code: "BMD", level: 3, depth: 2 },
          { id: 777, name: "Request for Price", parent: 51, code: "1XF", level: 3, depth: 2 },
          { id: 779, name: "Sourcing with SAP Ariba Sourcing", parent: 51, code: "4BL", level: 3, depth: 2 }
        ]
      },
      {
        id: 52,
        name: "Supplier Management",
        parent: 13,
        code: null,
        level: 2,
        depth: 1,
        children: [
          { id: 781, name: "Activity Management in Procurement", parent: 52, code: "19C", level: 3, depth: 2 },
          {
            id: 782,
            name: "Prediction of Delivery Date for Purchase Order Items",
            parent: 52,
            code: "3FY",
            level: 3,
            depth: 2
          },
          { id: 784, name: "Supplier Classification and Segmentation", parent: 52, code: "19E", level: 3, depth: 2 },
          {
            id: 785,
            name: "Supplier Evaluation and Performance Monitoring",
            parent: 52,
            code: "SL4",
            level: 3,
            depth: 2
          }
        ]
      }
    ]
  },
  {
    id: 14,
    name: "Supply Chain",
    parent: "",
    code: null,
    level: 1,
    depth: 0,
    children: [
      {
        id: 53,
        name: "Advanced Order Promising",
        parent: 14,
        code: null,
        level: 2,
        depth: 1,
        children: [
          { id: 787, name: "Advanced Available-to-Promise Processing", parent: 53, code: "1JW", level: 3, depth: 2 }
        ]
      },
      {
        id: 54,
        name: "Delivery and Transportation",
        parent: 14,
        code: null,
        level: 2,
        depth: 1,
        children: [
          {
            id: 789,
            name: "Determination of Geocoordinates, Distances and Durations",
            parent: 54,
            code: "46P",
            level: 3,
            depth: 2
          },
          { id: 791, name: "Intercompany Transportation - Outbound", parent: 54, code: "6BG", level: 3, depth: 2 },
          {
            id: 792,
            name: "Intercompany Transportation - Stock Transport Order",
            parent: 54,
            code: "6BH",
            level: 3,
            depth: 2
          },
          { id: 793, name: "Manual Transportation Planning – Inbound", parent: 54, code: "5OS", level: 3, depth: 2 },
          { id: 794, name: "Manual Transportation Planning – Outbound", parent: 54, code: "4MO", level: 3, depth: 2 },
          {
            id: 795,
            name: "Manual Transportation Planning - Stock Transport Order",
            parent: 54,
            code: "5XD",
            level: 3,
            depth: 2
          },
          {
            id: 796,
            name: "Manual Transportation Planning for Air Freight - Inbound",
            parent: 54,
            code: "6B3",
            level: 3,
            depth: 2
          },
          {
            id: 797,
            name: "Manual Transportation Planning for Air Freight - Outbound",
            parent: 54,
            code: "6B2",
            level: 3,
            depth: 2
          },
          {
            id: 798,
            name: "Manual Transportation Planning for Air Freight - STO",
            parent: 54,
            code: "6B4",
            level: 3,
            depth: 2
          },
          {
            id: 799,
            name: "Manual Transportation Planning for Ocean Freight - Inbound",
            parent: 54,
            code: "64B",
            level: 3,
            depth: 2
          },
          {
            id: 800,
            name: "Manual Transportation Planning for Ocean Freight - Outbound",
            parent: 54,
            code: "64A",
            level: 3,
            depth: 2
          },
          {
            id: 801,
            name: "Manual Transportation Planning for Ocean Freight - STO",
            parent: 54,
            code: "64C",
            level: 3,
            depth: 2
          },
          {
            id: 802,
            name: "Order-Based Transportation Consolidation - Inbound",
            parent: 54,
            code: "5VV",
            level: 3,
            depth: 2
          },
          {
            id: 803,
            name: "Order-Based Transportation Consolidation – Outbound",
            parent: 54,
            code: "3EP",
            level: 3,
            depth: 2
          },
          { id: 804, name: "Outbound Logistics for Distribution Centers", parent: 54, code: "5FV", level: 3, depth: 2 },
          {
            id: 805,
            name: "Stock Transport Order-Based Transportation Consolidation",
            parent: 54,
            code: "5XC",
            level: 3,
            depth: 2
          }
        ]
      },
      {
        id: 55,
        name: "Inventory",
        parent: 14,
        code: null,
        level: 2,
        depth: 1,
        children: [
          { id: 812, name: "Core Inventory Management", parent: 55, code: "BMC", level: 3, depth: 2 },
          { id: 813, name: "Cross-Plant Transfer Posting", parent: 55, code: "1P7", level: 3, depth: 2 },
          { id: 819, name: "Inbound Logistics for Distribution Centers", parent: 55, code: "5FU", level: 3, depth: 2 },
          { id: 820, name: "Intra-Company Returns Stock Transfers", parent: 55, code: "5WI", level: 3, depth: 2 },
          { id: 823, name: "Physical Inventory - Cycle Counting", parent: 55, code: "4LU", level: 3, depth: 2 },
          {
            id: 824,
            name: "Physical Inventory - Inventory Count and Adjustment",
            parent: 55,
            code: "BML",
            level: 3,
            depth: 2
          },
          { id: 825, name: "Physical Inventory for Distribution Centers", parent: 55, code: "5FS", level: 3, depth: 2 },
          { id: 834, name: "Return to Supplier", parent: 55, code: "BMK", level: 3, depth: 2 },
          {
            id: 835,
            name: "SAP Fiori Analytical Apps for Inventory and Warehouse Management",
            parent: 55,
            code: "BGG",
            level: 3,
            depth: 2
          },
          { id: 836, name: "Stock Transfer with Delivery", parent: 55, code: "BME", level: 3, depth: 2 },
          { id: 837, name: "Stock Transfer Without Delivery", parent: 55, code: "BMH", level: 3, depth: 2 }
        ]
      },
      {
        id: 56,
        name: "Logistics Material Identification",
        parent: 14,
        code: null,
        level: 2,
        depth: 1,
        children: [{ id: 841, name: "Handling Unit Management", parent: 56, code: "4MM", level: 3, depth: 2 }]
      },
      {
        id: 57,
        name: "Order Promising",
        parent: 14,
        code: null,
        level: 2,
        depth: 1,
        children: [
          { id: 844, name: "Basic Available-to-Promise Processing", parent: 57, code: "2LN", level: 3, depth: 2 }
        ]
      },
      {
        id: 58,
        name: "Warehousing",
        parent: 14,
        code: null,
        level: 2,
        depth: 1,
        children: [
          { id: 849, name: "Mobile RF Devices in Warehousing", parent: 58, code: "63V", level: 3, depth: 2 },
          {
            id: 850,
            name: "Mobile RF Devices in Warehousing with Production Integration",
            parent: 58,
            code: "63W",
            level: 3,
            depth: 2
          },
          {
            id: 851,
            name: "Mobile RF Devices in Warehousing with Quality Management",
            parent: 58,
            code: "63X",
            level: 3,
            depth: 2
          },
          { id: 853, name: "Quality Management in Warehousing", parent: 58, code: "3M0", level: 3, depth: 2 },
          { id: 854, name: "Replenishment in Warehousing", parent: 58, code: "4A2", level: 3, depth: 2 },
          { id: 855, name: "Resource Management in Warehousing", parent: 58, code: "3W0", level: 3, depth: 2 },
          {
            id: 856,
            name: "Retail Logistics with Embedded Warehouse Management",
            parent: 58,
            code: "69L",
            level: 3,
            depth: 2
          },
          {
            id: 857,
            name: "Retail Logistics with Third-Party Warehouse Management",
            parent: 58,
            code: "69M",
            level: 3,
            depth: 2
          },
          { id: 858, name: "SAP EWM Integration - Batch Management", parent: 58, code: "2VN", level: 3, depth: 2 },
          { id: 859, name: "SAP EWM Integration - Customer Returns", parent: 58, code: "2VO", level: 3, depth: 2 },
          {
            id: 860,
            name: "SAP EWM Integration - Delivery-Based Production Integration",
            parent: 58,
            code: "2VM",
            level: 3,
            depth: 2
          },
          {
            id: 861,
            name: "SAP EWM Integration - Inbound Processing from Supplier",
            parent: 58,
            code: "2VJ",
            level: 3,
            depth: 2
          },
          {
            id: 862,
            name: "SAP EWM Integration - Outbound Processing to Customer",
            parent: 58,
            code: "2VK",
            level: 3,
            depth: 2
          },
          {
            id: 863,
            name: "SAP EWM Integration - Stock Transport Orders",
            parent: 58,
            code: "2VL",
            level: 3,
            depth: 2
          },
          {
            id: 864,
            name: "SAP EWM Integration - Warehouse Stock Handling",
            parent: 58,
            code: "2YL",
            level: 3,
            depth: 2
          },
          { id: 865, name: "Warehouse Ad Hoc Goods Issue", parent: 58, code: "3BT", level: 3, depth: 2 },
          {
            id: 866,
            name: "Warehouse Goods Receipt from Repetitive Manufacturing",
            parent: 58,
            code: "41U",
            level: 3,
            depth: 2
          },
          { id: 867, name: "Warehouse Inbound Processing", parent: 58, code: "3BR", level: 3, depth: 2 },
          {
            id: 868,
            name: "Warehouse Inbound Processing with Synchronous Goods Receipt",
            parent: 58,
            code: "5HN",
            level: 3,
            depth: 2
          },
          { id: 869, name: "Warehouse Internal Inspections", parent: 58, code: "5OK", level: 3, depth: 2 },
          { id: 870, name: "Warehouse Internal Repacking", parent: 58, code: "3BW", level: 3, depth: 2 },
          { id: 871, name: "Warehouse Kanban Supply into Production", parent: 58, code: "3VT", level: 3, depth: 2 },
          { id: 872, name: "Warehouse Outbound Processing", parent: 58, code: "3BS", level: 3, depth: 2 },
          { id: 873, name: "Warehouse Physical Inventory", parent: 58, code: "3BX", level: 3, depth: 2 },
          { id: 874, name: "Warehouse Posting Changes", parent: 58, code: "3BU", level: 3, depth: 2 },
          { id: 875, name: "Warehouse Production Integration", parent: 58, code: "3DV", level: 3, depth: 2 },
          {
            id: 876,
            name: "Warehouse Production Integration with Synchronous Goods Receipt",
            parent: 58,
            code: "5HO",
            level: 3,
            depth: 2
          }
        ]
      }
    ]
  }
];
