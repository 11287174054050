import I18n from "../../../../../../../localization/I18n";
import { GridSideModalTitle } from "../../../../utils/GridModalUtils";
import { ActionImpactsSidebarModel } from "./ActionImpactsSidebar_model";
import { ActionImpactsSidebar } from "./ActionImpactsSidebar_view";

export const ACTION_IMPACTS_SIDEBAR_MODAL_CONFIG = (
  organisationId: number,
  projectId: number,
  action: FP.Entities.IAction
) => {
  return {
    showClose: true,
    title: <GridSideModalTitle name={action.name} field={I18n.t("phrases.associatedDetailedImpacts")} />,
    content: <ActionImpactsSidebar model={new ActionImpactsSidebarModel(action.id, organisationId, projectId)} />
  };
};
