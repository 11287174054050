import { HubEvents } from "../common/HubEventsEnum";
import { GridBaseModel, IGridBaseModel } from "../common/GridBaseModel";

export class ProjectJobRoleHub extends GridBaseModel implements IGridBaseModel {
  hubName: string = "project-job-roles";

  constructor() {
    super("project-job-roles");
  }

  onLoadData = callback => this.registerIncomingEvent(HubEvents.ON_LOAD_PROJECT_JOB_ROLE_DATA, callback);

  invokeLoadData = async (organisationId: number, projectId: number) => {
    if (!this.isConnectionStarted) return;
    await this.connection.invoke(HubEvents.INVOKE_LOAD_PROJECT_JOB_ROLE_GRID_DATA, {
      organisationId: organisationId,
      projectId: projectId
    });
  };
}

const instance = new ProjectJobRoleHub();
export default instance;
