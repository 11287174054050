import { BaseModel } from "../../../../../../core/util/BaseModel";
import { action, makeObservable, observable } from "mobx";
import { GridApi, GridReadyEvent } from "ag-grid-community";
import OrganisationReportsApi, {
  OrganisationReportsApi as IOrganisationReportsApi
} from "../../../../../../services/api/v2/organisationReports/OrganisationReports.api";

export class ChangeTeamCapacityModel extends BaseModel {
  @observable organisationId: number;
  isComponentMounted: boolean = false;
  @observable isLoading: boolean = true;
  authUser: FP.Entities.IUser;
  @observable changeTeamMetrics: FP.Entities.IChangeTeamMetric[];
  @observable stakeholderType: number;
  gridApi: GridApi;
  @observable searchText: string;
  organisationReportsProvider: IOrganisationReportsApi;

  constructor(organisationId: number, authUser: FP.Entities.IUser) {
    super();
    makeObservable(this);
    this.organisationId = organisationId;
    this.organisationReportsProvider = OrganisationReportsApi;
    this.authUser = authUser;
  }

  onMount = async () => {
    await this.loadChangeTeamMetrics();
  };

  onUnmount = () => {};

  loadChangeTeamMetrics = async () => {
    const res = await this.organisationReportsProvider.getChangeTeamMetricsByOrgId(this.organisationId);

    if (!res || res.isError) return false;
    this.setChangeTeamMetricsData(res.payload);
  };

  @action
  setChangeTeamMetricsData = data => {
    this.changeTeamMetrics = data;
    this.isLoading = false;
  };

  updateData = () => {};

  isRowSelectable = () => {
    return false;
  };

  @action
  onGridReady = (gridReadyEvent: GridReadyEvent): void => {
    this.gridApi = gridReadyEvent.api;
  };

  @action
  setSearchText = (ev: React.FormEvent<HTMLInputElement>) => {
    this.searchText = ev.currentTarget.value;

    if (this.gridApi !== undefined) {
      this.gridApi.setGridOption("quickFilterText", this.searchText);
    }
  };
}
