import { action, makeObservable, observable } from "mobx";
import { ButtonTypes } from "../../../../../../../../components/ui/Button";
import PermissionsContext from "../../../../../../../../contexts/permissions/PermissionsContext";
import { PermissionFields } from "../../../../../../../../contexts/permissions/PermissionsTypes";

import ProgressIndicatorModel, {
  ProgressIndicatorModel as IProgressIndicatorModel
} from "../../../../../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import I18n from "../../../../../../../../core/localization/I18n";
import { IUiAction, UiActionRenderers } from "../../../../../../../../core/uiAction/IUiAction";
import ActionsApi, { ActionsApi as IActionsApi } from "../../../../../../../../services/api/v2/actions/Actions.api";
import { SingleFormModel } from "../../../../../../../change/forms/singleFormModel/SingleForm_model";
import { getImpactActionFormFields } from "./ActionSidebar_fields";

export class ActionSidebarModel {
  @observable.ref formModel: SingleFormModel;
  @observable.ref actions: FP.Entities.IAction[] = [];
  @observable isLoading: boolean = true;

  organisationId: number;
  projectId: number;
  actionsProvider: IActionsApi;
  actionCardActions: IUiAction<FP.Entities.IAction>[] = [];
  httpProgress: IProgressIndicatorModel;
  projectStakeholderId: number;

  /**
   *
   */
  constructor(organisationId: number, projectId: number, projectStakeholderId: number) {
    makeObservable(this);
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.projectStakeholderId = projectStakeholderId;
    this.actionsProvider = ActionsApi;
    this.httpProgress = ProgressIndicatorModel;
    this.setActionCardActions();
    this.initForm();
  }

  onMount = async () => {
    await this.loadActions(this.projectStakeholderId);
  };

  initForm = () => {
    this.formModel = new SingleFormModel();
    this.formModel.formFields = getImpactActionFormFields(
      this.actionsProvider,
      this.organisationId,
      this.projectId,
      this.projectStakeholderId,
      this.actions,
      this.loadActions,
      this.httpProgress
    );
  };

  loadActions = async (projectStakeholderId: number) => {
    this.projectStakeholderId = projectStakeholderId;
    this.isLoading = true;
    let res = await this.actionsProvider.getActionsByStakeholderOwnerId(
      this.organisationId,
      this.projectId,
      projectStakeholderId
    );
    if (!res || res.isError) return;
    this.setActions(res.payload);
  };

  setActionCardActions = () => {
    const canEditActions = PermissionsContext.canEditField(
      PermissionFields.ACTIONS,
      this.organisationId,
      this.projectId
    );
    this.actionCardActions.push({
      id: "action1",
      label: I18n.t("phrases.view"),
      rendersIn: UiActionRenderers.HTML_ANCHOR,
      componentProps: {
        type: ButtonTypes.LINK
      },
      hrefFn: action => `/organisations/${this.organisationId}/projects/${action.projectId}/actions/${action.id}`
    });
    if (canEditActions) {
      this.actionCardActions.push({
        id: "action2",
        label: I18n.t("phrases.remove"),
        onAction: async (ev, action: FP.Entities.IAction) => {
          await this.removeAction(action.id);
          this.loadActions(this.projectStakeholderId);
        },
        componentProps: {
          type: ButtonTypes.LINK
        },
        rendersIn: UiActionRenderers.BUTTON
      });
    }
  };

  @action
  setActions = (actions: FP.Entities.IAction[]) => {
    this.actions = actions;
    this.isLoading = false;
  };

  removeAction = async (actionId: number) => {
    this.httpProgress.showTopProgressBarVisible();
    const res = await this.actionsProvider.removeActionStakeholderOwner(
      this.organisationId,
      this.projectId,
      actionId,
      this.projectStakeholderId
    );
    this.httpProgress.hideTopProgressBarVisible();

    if (!res || res.isError) return;

    return res.payload;
  };
}
