import React, { useEffect, useState } from "react";
import { Icon, IconSymbols } from "../../../../components/ui/Icon";
import { useCurrentOrganisationId } from "../../../../services/local/organisationContext/OrganisationContextModel";

const headerNames = ["Name", "Project", "Stakeholder", "Impact", "Mitigation", "Can Manage Permissions"];

const Headers: React.FC<any> = ({ names }) => {
  return (
    <tr>
      {names.map(e => {
        return <th className="text-left pr-3">{e}</th>;
      })}
    </tr>
  );
};

const Text = ({ view, edit }) => {
  let permissions = [];
  if (edit) permissions.push(<Icon symbol={IconSymbols.Pencil} className="mr-2" />);
  if (view) permissions.push(<Icon symbol={IconSymbols.Eye} className="mr-2" />);
  return (
    <div>
      {permissions.map(e => {
        return e;
      })}
    </div>
  );
};

const TableData: React.FC<any> = ({ projectArea }) => {
  return (
    <td className="pr-3">
      <Text view={projectArea.v} edit={projectArea.e} />
    </td>
  );
};

const NameData: React.FC<any> = ({ name, projectId }) => {
  let url = `${
    appConfig.baseUrl
  }/organisations/${useCurrentOrganisationId()}/projects/${projectId}/settings/permissions`;
  return (
    <td className="pr-2">
      <a href={url} target="_blank" rel="noopener noreferrer">
        {name}
      </a>
    </td>
  );
};

const CanManagePermissionsData: React.FC<any> = ({ projectArea }) => {
  let toReturn = null;
  if (projectArea) toReturn = <Icon symbol={IconSymbols.Check} className="mr-2" />;
  return <td className="pr-3 d-flex justify-content-center">{toReturn}</td>;
};

const TableRow: React.FC<any> = ({ project, isEven }) => {
  let backgroundColor = "";
  if (isEven) backgroundColor = "bg-light";
  return (
    <tr className={`p-2 ${backgroundColor}`}>
      <NameData name={project.n} projectId={project.id} />
      <TableData projectArea={project.p} />
      <TableData projectArea={project.s} />
      <TableData projectArea={project.i} />
      <TableData projectArea={project.a} />
      <CanManagePermissionsData projectArea={project.per} />
    </tr>
  );
};

const PermissionsTableRows: React.FC<any> = ({ payload }) => {
  return (
    <>
      {payload.map((e, i) => {
        let isEven = true;
        if (i % 2 !== 0) isEven = false;
        return <TableRow project={e} isEven={isEven} />;
      })}
    </>
  );
};

const OrganisationUserPermissionsList: React.FC<any> = ({ getPermissionsFunction, organisationId, systemUserId }) => {
  const [permissionsData, setPermissionsData] = useState([]);

  useEffect(() => {
    const loadPermissionsData = () => {
      getPermissionsFunction(organisationId, systemUserId).then(r => setPermissionsData(r));
    };

    loadPermissionsData();
  }, [getPermissionsFunction, organisationId, systemUserId]);

  return (
    <table>
      <Headers names={headerNames} />
      <PermissionsTableRows payload={permissionsData} />
    </table>
  );
};

export { OrganisationUserPermissionsList };
