import { AxiosInstance, AxiosRequestConfig } from "axios";
import http from "../Http";
import { BaseProjectApiModel } from "../BaseProjectApiModel";
import { IFlightPathApiResponse } from "../BaseApiModel";

export class ImpactReportingApi extends BaseProjectApiModel<FP.Entities.IImpact> {
  controller: string = "impact-reporting";

  constructor(http: AxiosInstance) {
    super(http, "impact-reporting");
  }

  getImpactsByTypeBreakdownReport = async (
    organisationId: number,
    projectId: number,
    data: number[],
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IStakeholderGroup[]>> => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/impactsByType`;
    let res = await this.http.post(url, data || {}, config);
    return res.data;
  };

  getImpactsByLocationBreakdownReport = async (
    organisationId: number,
    projectId: number,
    data: number[],
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IStakeholderGroup[]>> => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/impactsByLocation`;
    let res = await this.http.post(url, data, config);
    return res.data;
  };

  getImpactsByBusinessAreaBreakdownReport = async (
    organisationId: number,
    projectId: number,
    data: number[],
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IStakeholderGroup[]>> => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/impactsByBusinessArea`;
    let res = await this.http.post(url, data, config);
    return res.data;
  };

  getImpactsByOwnerBreakdownReport = async (
    organisationId: number,
    projectId: number,
    data: number[],
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IStakeholderGroup[]>> => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/impactsByOwner`;
    let res = await this.http.post(url, data, config);
    return res.data;
  };

  getImpactsByStakeholderSentimentBreakdownReport = async (
    organisationId: number,
    projectId: number,
    impactIds: number[],
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IStakeholderGroup[]>> => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/impactsByStakeholderSentiment`;
    let res = await this.http.post(url, { impactIds: impactIds }, config);
    return res.data;
  };

  getImpactsByStakeholderCommitmentBreakdownReport = async (
    organisationId: number,
    projectId: number,
    impactIds: number[],
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IStakeholderGroup[]>> => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/impactsByStakeholderCommitment`;
    let res = await this.http.post(url, { impactIds: impactIds }, config);
    return res.data;
  };

  getImpactsByStakeholderReceptivenessBreakdownReport = async (
    organisationId: number,
    projectId: number,
    impactIds: number[],
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IStakeholderGroup[]>> => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/impactsByStakeholderReceptiveness`;
    let res = await this.http.post(url, { impactIds: impactIds }, config);
    return res.data;
  };
}

const instance = new ImpactReportingApi(http);
export default instance;
