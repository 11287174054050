import { action, makeObservable, observable } from "mobx";
import { BarChartProps } from "../../../../../../core/charts/BarChart/BarChart";

export class ProgrammeChangeTeamCapacityChartModel {
  organisationId: number;
  @observable isLoading: boolean = true;
  @observable chartData: BarChartProps;
  @observable data: any;
  transformer: (data: any[]) => BarChartProps;

  /**
   *
   */
  constructor(organisationId: number, transformer: (data: any[]) => BarChartProps) {
    makeObservable(this);
    this.organisationId = organisationId;
    this.transformer = transformer;
  }

  onMount = () => {
    this.setChartData(this.data);
  };

  @action
  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };

  @action
  setData = (data: any) => {
    this.data = data;
  };

  @action
  setChartData = (data: any[]) => {
    this.chartData = this.transformer(data);
  };
}
