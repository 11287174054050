import { action, makeObservable, observable } from "mobx";
import { IModalContextModel } from "../../../../../core/modalZ/context/IModalContext";
import ModalContext from "../../../../../core/modalZ/context/ModalContext";
import { BaseModel } from "../../../../../core/util/BaseModel";
import ImpactGroupsApi, {
  ImpactGroupsApi as IImpactGroupsApi
} from "../../../../../services/api/v2/impactGroups/ImpactGroups.api";
import ImpactsApi, { ImpactsApi as IImpactsApi } from "../../../../../services/api/v2/impacts/Impacts.api";

export class ImpactGroupModalModel extends BaseModel {
  projectId: number;
  impactGroupId: number;
  modalService: IModalContextModel;
  impactService: IImpactsApi;
  impactGroupService: IImpactGroupsApi;
  @observable isLoading: boolean = true;
  @observable.ref impactGroup?: FP.Entities.IImpactGroup = null;
  @observable impactGroupImpacts;
  impactGroupsProvider: IImpactGroupsApi = ImpactGroupsApi;
  organisationId: number;

  constructor(projectId: number, impactGroupId: number, organisationId: number) {
    super();
    makeObservable(this);
    this.projectId = projectId;
    this.impactGroupId = impactGroupId;
    this.organisationId = organisationId;
    this.modalService = ModalContext;
    this.impactService = ImpactsApi;
    this.impactGroupService = ImpactGroupsApi;
  }

  onMount() {
    this.getImpactGroup();
  }

  @action
  async getImpactGroup() {
    this.isLoading = true;
    const res = await this.impactGroupsProvider.getDetailed(this.organisationId, this.projectId, this.impactGroupId);
    if (res) this.impactGroup = res.payload;
    this.isLoading = false;
  }

  @action
  loadImpactGroupImpacts = async () => {
    const res = await this.impactGroupsProvider.getImpactGroupImpacts(
      this.organisationId,
      this.projectId,
      this.impactGroupId
    );
    if (!res) return;
    this.impactGroupImpacts = res.payload;
  };

  onImpactCreated = async () => {
    await this.loadImpactGroupImpacts();
  };
}
