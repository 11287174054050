import { progressStatusTextMatcher } from "../../../../../../filters/TextMatcher";
import { ISdColDef, ISdFilterDef } from "../../../baseColumn/BaseColumnTypes";

export const PROGRESS_STATUS_COLUMN_CONFIG: (progressStatuses: any) => ISdColDef<any, any> = progressStatuses => {
  return {
    getQuickFilterText: params => {
      if (!params.data.progressStatus || !progressStatuses) return "";

      const progress = progressStatuses.find(e => +e.key === params.value);

      if (!progress) return "";

      return progress.label;
    },
    cellEditorParams: {
      field: "progressStatus",
      getValueLabel: (ee: any) => {
        const progress = progressStatuses.find(e => e.key === ee + "");
        if (!progress) return "";
        return progress.label;
      },
      options: progressStatuses ?? []
    }
  };
};
export const PROGRESS_STATUS_FILTER_CONFIG: ISdFilterDef = {
  filterParams: {
    textMatcher: progressStatusTextMatcher
  }
};
