import { observer } from "mobx-react";
import { ProgrammeLocationsModalContent } from "../view/ProgrammeLocationsModalContent";
import { useCurrentOrganisationId } from "../../../../services/local/organisationContext/OrganisationContextModel";
import { useEffect, useState } from "react";
import { ProgrammeLocationsModel } from "./ProgrammeLocations_model";
import { PositionedSpinner } from "@flightpath/coreui/dist/ui/PositionedSpinner";
import { useParams } from "react-router-dom";
import I18n from "../../../../core/localization/I18n";

export const ProgrammeLocationsView: React.FC<ReactProps> = observer(() => {
  const organisationId = useCurrentOrganisationId();
  const { programmeId } = useParams<{ programmeId: string }>();
  const [model] = useState(() => new ProgrammeLocationsModel(organisationId));

  useEffect(() => {
    model.onMount(+programmeId);
    return model.onUnmount;
  }, [model, organisationId, programmeId]);

  return model.isLoading ? (
    <PositionedSpinner />
  ) : (
    <div className="container-fluid p-3">
      <h1 className="py-2">{`${model.programmeDashboardData.programmeDetails.name} - ${I18n.t("phrases.locations")}`}</h1>
      <ProgrammeLocationsModalContent
        organisationId={organisationId}
        programmeLocations={model.programmeDashboardData.programmeLocations}
      />
    </div>
  );
});
