import I18n from "../../../../core/localization/I18n";
import { Enums } from "../../../../enums";

export const MapImpactProgressData = (impacts: any[]) => {
  return [
    {
      label: I18n.t("phrases.notStarted"),
      value: impacts.filter(e => e.progressStatus === Enums.ProgressStatus.NOT_STARTED).length,
      id: "notStarted",
      key: Enums.ProgressStatus.NOT_STARTED
    },
    {
      label: I18n.t("phrases.inProgress"),
      value: impacts.filter(e => e.progressStatus === Enums.ProgressStatus.IN_PROGRESS).length,
      id: "inProgress",
      key: Enums.ProgressStatus.IN_PROGRESS
    },
    {
      label: I18n.t("phrases.completed"),
      value: impacts.filter(e => e.progressStatus === Enums.ProgressStatus.COMPLETED).length,
      id: "completed",
      key: Enums.ProgressStatus.COMPLETED
    }
  ];
};

export const MapImpactLevelData = (impacts: any[]) => {
  return [
    {
      label: I18n.t("phrases.high"),
      value: impacts.filter(e => e.impactLevel <= 10 && e.impactLevel > 7).length,
      id: "high",
      key: 10
    },
    {
      label: I18n.t("phrases.medium"),
      value: impacts.filter(e => e.impactLevel > 4 && e.impactLevel <= 7).length,
      id: "medium",
      key: 7
    },
    {
      label: I18n.t("phrases.low"),
      value: impacts.filter(e => e.impactLevel > 0 && e.impactLevel <= 4).length,
      id: "low",
      key: 1
    },
    {
      label: I18n.t("phrases.unknown"),
      value: impacts.filter(e => e.impactLevel <= 0).length,
      id: "unknown",
      key: -1
    }
  ];
};
