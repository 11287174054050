import { ColDef } from "ag-grid-community";
import React from "react";
import { Enums, ImpactField, Translator } from "../../../../../../enums";
import AgGridAutocomplete from "../../../../../../core/grids/editors/AgGridAutocomplete";
import { baseColumn, baseDropdownColumn, baseTextColumn } from "../../../../../../core/grids/BaseColumns";
import { GridPills } from "../../../../../../components/widgets/gridPills/GridPills";
import { makeCellDeletable, openModalOnDelete } from "../../../../../../core/grids/GridHelpers";
import { multiValueTextMatcher, zeroToTenTextMatcher } from "../../../../../../core/grids/filters/TextMatcher";
import I18n from "../../../../../../core/localization/I18n";

export const colImpactTypes = (canEdit: boolean, editFn?) => {
  let res: ColDef = {
    ...baseTextColumn({ fieldName: "impactTypeNames", heading: I18n.t("grids.impactTypes"), clickEditable: true }),
    editable: false,
    autoHeight: true,
    width: 500,
    cellRenderer: e => <GridPills text={e.data.impactTypeNames} />
  };
  const isEditable = canEdit && editFn !== undefined;
  if (isEditable) {
    res.onCellDoubleClicked = e => {
      editFn(e.data);
    };
    res.suppressKeyboardEvent = openModalOnDelete(res, editFn);
  }
  return res;
};

export const colTotalPeopleImpacted = (canEdit: boolean, saveFn?: any): ColDef => {
  let res: ColDef = {
    ...baseTextColumn({
      fieldName: "totalPeopleImpacted",
      heading: I18n.t("grids.numberImpacted"),
      editable: canEdit,
      clickEditable: true
    }),
    valueSetter: params => {
      if (!params.newValue || !saveFn) return false;
      saveFn(ImpactField.numberImpacted, params.node.id, params.newValue);
      return true;
    }
  };

  res.type = makeCellDeletable(res.type);

  return res;
};

export const colImpactLevel = (canEdit: boolean, impactLevels?: FP.Generic.IKeyLabel[], saveFn?: any): ColDef => {
  let result: ColDef = {
    ...baseColumn({
      fieldName: "impactLevel",
      heading: I18n.t("grids.impactLevel"),
      width: 150,
      editable: canEdit,
      clickEditable: true
    }),
    cellEditorSelector: () => ({ component: AgGridAutocomplete, popup: true, popupPosition: "over" }),
    cellEditorPopup: true,
    valueSetter: params => {
      if (!params.newValue || !saveFn) return false;
      let newVal = Enums.Translator.ImpactLevelShortToLong(+params.newValue.key);
      saveFn(ImpactField.impactLevel, params.node.id, newVal);
      return true;
    },
    cellDataType: "any",
    filter: "agTextColumnFilter",
    filterParams: {
      textMatcher: zeroToTenTextMatcher
    },
    cellEditorParams: {
      field: "impactLevel",
      getValueLabel: (ee: number) => {
        const shortVal = Enums.Translator.ImpactLevelLongToShort(ee);
        return shortVal > 0 ? impactLevels.find(e => e.key === shortVal.toString()).label : "";
      },
      options: impactLevels ?? []
    },
    getQuickFilterText: params => {
      if (params.value === -1) return "";

      return Translator.ImpactLevel(params.value);
    }
  };
  result.type = makeCellDeletable(result.type);
  result.cellRenderer = params => {
    const impact: FP.Entities.IImpactSummary = params.data;
    return impact.impactLevel > 0 ? <span>{Enums.Translator.ImpactLevel(impact.impactLevel)}</span> : null;
  };

  return result;
};

export const colBusinessAreas = (canEdit: boolean, editFn?): ColDef => {
  let res = {
    ...baseDropdownColumn({
      fieldName: "businessAreaNames",
      heading: I18n.t("grids.businessAreas"),
      autoHeight: true,
      width: 500,
      clickEditable: true
    }),
    editable: false,
    filterParams: {
      textMatcher: multiValueTextMatcher
    }
  };
  res.cellRenderer = e => <GridPills text={e.data.businessAreaNames} />;
  const isEditable = canEdit && editFn !== undefined;
  if (isEditable) {
    res.onCellDoubleClicked = e => {
      editFn(e.data);
    };
    res.suppressKeyboardEvent = openModalOnDelete(res, editFn);
  }
  return res;
};

export const colLocations = (canEdit: boolean, editFn?): ColDef => {
  let res = {
    ...baseDropdownColumn({
      fieldName: "locationNames",
      heading: I18n.t("grids.locations"),
      autoHeight: true,
      width: 500,
      clickEditable: true
    }),
    editable: false,
    filterParams: {
      textMatcher: multiValueTextMatcher
    }
  };
  res.cellRenderer = e => <GridPills text={e.data.locationNames} />;
  const isEditable = canEdit && editFn !== undefined;
  if (isEditable) {
    res.onCellDoubleClicked = e => {
      editFn(e.data);
    };
    res.suppressKeyboardEvent = openModalOnDelete(res, editFn);
  }
  return res;
};

export const colImpactGroups = (canEdit: boolean, editFn?: any): ColDef => {
  let res: ColDef = {
    ...baseDropdownColumn({
      fieldName: "impactGroupNames",
      heading: I18n.t("grids.impactGroups"),
      autoHeight: true,
      width: 500,
      clickEditable: true
    }),
    cellRenderer: e => <GridPills text={e.data.impactGroupNames} />
  };

  const isEditable = canEdit && editFn !== undefined;
  if (isEditable) {
    res.onCellDoubleClicked = e => {
      editFn(e.data);
    };
    res.suppressKeyboardEvent = openModalOnDelete(res, editFn);
  }

  return res;
};

export const colActions = (canEdit: boolean, editFn?) => {
  let res: ColDef = {
    ...baseTextColumn({
      fieldName: "actionNames",
      heading: I18n.t("grids.actions"),
      editable: false,
      clickEditable: true,
      autoHeight: true,
      width: 500
    }),
    cellRenderer: e => {
      return <GridPills text={e.data.actionNames} />;
    }
  };

  if (editFn && canEdit) {
    res.onCellDoubleClicked = e => {
      editFn(e.data);
    };
    res.suppressKeyboardEvent = openModalOnDelete(res, editFn);
  }

  return res;
};

export const colProjectStakeholders = (canEdit: boolean, editFn?: any): ColDef => {
  let res: ColDef = {
    ...baseDropdownColumn({
      fieldName: "projectStakeholderNames",
      heading: I18n.t("grids.stakeholders"),
      clickEditable: true
    }),
    autoHeight: true,
    width: 500
  };
  res.cellRenderer = e => <GridPills text={e.data.projectStakeholderNames} />;
  const isEditable = canEdit && editFn !== undefined;
  if (isEditable) {
    res.onCellDoubleClicked = e => {
      editFn(e.data);
    };
    res.suppressKeyboardEvent = openModalOnDelete(res, editFn);
  }

  return res;
};

export const colAudiences = (canEdit: boolean, editFn?: any): ColDef => {
  let res: ColDef = {
    ...baseDropdownColumn({
      fieldName: "audienceNames",
      heading: I18n.t("grids.audiences"),
      autoHeight: true,
      width: 500,
      clickEditable: true
    })
  };
  res.cellRenderer = e => <GridPills text={e.data.audienceNames} />;
  const isEditable = canEdit && editFn !== undefined;
  if (isEditable) {
    res.onCellDoubleClicked = e => {
      editFn(e.data);
    };
    res.suppressKeyboardEvent = openModalOnDelete(res, editFn);
  }

  return res;
};

export const colMilestones = (canEdit: boolean, editFn?: any): ColDef => {
  let res: ColDef = {
    ...baseTextColumn({
      fieldName: "milestoneNames",
      heading: I18n.t("grids.milestones"),
      autoHeight: true,
      clickEditable: true,
      width: 500
    })
  };
  res.cellRenderer = e => <GridPills text={e.data.milestoneNames} />;
  const isEditable = canEdit && editFn !== undefined;
  if (isEditable) {
    res.onCellDoubleClicked = e => {
      editFn(e.data);
    };
    res.suppressKeyboardEvent = openModalOnDelete(res, editFn);
  }
  return res;
};
