import { AxiosInstance, AxiosRequestConfig } from "axios";
import { IFilteredOptions } from "../filteredApi/FilteredApiModel";
import { FilteredProjectApiModel } from "../filteredApi/FilteredProjectApiModel";
import http from "../Http";

export class ProjectIndividualUserPermissionsApi extends FilteredProjectApiModel<FP.Entities.IUserPermission> {
  controller: string = "project-user-authorisation";
  urlWithOrg: string;
  /**
   *
   */
  constructor(http: AxiosInstance) {
    super(http, "project-user-authorisation");
  }

  getUsersInProject = async (
    organisationId: number,
    projectId: number,
    filterOptions: Partial<IFilteredOptions>,
    config?: AxiosRequestConfig
  ) => {
    let query = this.getRequestQuery(filterOptions);
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}`;
    let res = await this.http.get(url + query, config);
    return res.data;
  };

  getAllUsersSimple = async (organisationId: number, projectId: number, config?: AxiosRequestConfig) => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/project-users-simple`;
    let res = await this.http.get(url, config);
    return res.data;
  };

  deleteUserPermissionCache = async (organisationId: number, userId: number, config?: AxiosRequestConfig) => {
    const url = `${this.url}/${organisationId}/${this.controller}/delete-cache/${userId}`;
    let res = await this.http.get(url, config);
    return res.data;
  };
}

const instance = new ProjectIndividualUserPermissionsApi(http);

export default instance;
