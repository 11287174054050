import { CheckboxSelectionCallbackParams } from "ag-grid-community";
import { ISdColDef } from "../../baseColumn/BaseColumnTypes";
import { BaseTextColumnBuilder } from "../../baseColumn/BaseTextColumn_builder";
import { SELECTION_COLUMN_CONFIG } from "./SelectionColumn_config";

interface ISelectionColumnBuilder {}

export class SelectionColumnBuilder extends BaseTextColumnBuilder implements ISelectionColumnBuilder {
  constructor(columnOptions?: ISdColDef<any, any>) {
    super({ ...SELECTION_COLUMN_CONFIG(), ...columnOptions });
  }

  withDefaultCheckedAndDisabled = (preSelectedItemIds: number[]) => {
    this.setColumnOptions({
      checkboxSelection: (params: CheckboxSelectionCallbackParams<any>) => {
        return preSelectedItemIds?.indexOf(params.data.id) >= 0;
      },
      showDisabledCheckboxes: true
    });
    return this;
  };
}
