import { action, makeObservable, observable } from "mobx";
import { IFilterModel } from "../../../../core/filter/Filter_model";
import UserReportsApi, {
  UserReportsApi as IUserReportsApi
} from "../../../../services/api/v2/userReports/UserReports.api";

export class ActionTypesModel {
  organisationId: number;
  userProvider: IUserReportsApi;
  @observable actions: any[] = [];
  @observable isLoading: boolean = true;
  actionsOverTimeFilter: IFilterModel<any>;
  /**
   *
   */
  constructor(organisationId: number) {
    makeObservable(this);
    this.organisationId = organisationId;
    this.userProvider = UserReportsApi;
  }

  onMount = async () => {
    await this.loadActions();
  };

  loadActions = async () => {
    let res = await this.userProvider.getActionsByType(this.organisationId, {});
    if (!res) return;

    this.setActions(res.payload);
  };

  @action
  setActions = (actions: any[]) => {
    this.actions = actions;
    this.isLoading = false;
  };
}
