export interface ICsvHelper {
  exportToCsv: (filename: string, data: any[]) => void;
}

export class CsvHelper implements ICsvHelper {
  exportToCsv(filename: string, data: any[]) {
    let dataString;
    var csvContent = "data:text/csv;charset=utf-8,";
    data.forEach(function (infoArray, index) {
      let safeArray = MakeArraySafe(infoArray);

      dataString = safeArray.join(",");
      csvContent += index < data.length ? dataString + "\n" : dataString;
    });
    var encodedUri = encodeURI(csvContent);
    //hashtags have to be replaced following encodeURI() or character conversion will not occur.
    var fixedEncodedUri = encodedUri.replaceAll("#", "%23");
    // window.open(encodedUri);
    var link = document.createElement("a");
    link.setAttribute("href", fixedEncodedUri);
    link.setAttribute("download", filename);
    document.body.appendChild(link); // Required for FF

    link.click();
  }
}

function MakeArraySafe(infoArray: any[]): any[] {
  let result = [];

  if (infoArray !== null && typeof infoArray !== "undefined") {
    for (let i = 0; i < infoArray.length; i++) {
      result.push(EscapeUnsafeStrings(infoArray[i]));
    }
  }

  return result;
}

function EscapeUnsafeStrings(info: string): string {
  info += "";
  let result = info;

  if (info !== null && typeof info !== "undefined") {
    let specialCharacters = ["=", "+", "-", "@"];

    for (let i = 0; i < specialCharacters.length; i++) {
      if (info.startsWith(specialCharacters[i])) {
        result = `'${info}`;
      }
    }

    if (info.startsWith("|")) {
      result = `\${x}`;
    }
  }

  return result;
}

export function PARSE_CSV_FILE<G>(file: File, onLoad: (rows: G[]) => void) {
  if (file) {
    const reader = new FileReader();

    reader.onload = (e: ProgressEvent<FileReader>) => {
      const csvData = e.target?.result as string;
      const results: G[] = parseCsv<G>(csvData);

      // Process the parsed CSV data
      onLoad(results);
    };

    reader.readAsText(file);
  }
}

function parseCsv<G>(csvData: string): G[] {
  const rows: G[] = [];
  const lines = csvData.split("\n").filter(e => e !== "");

  // Assuming the first line contains headers
  const headers = lines[0].split(",");

  for (let i = 1; i < lines.length; i++) {
    const values = lines[i].split(",");
    const row: G = {} as G;
    for (let j = 0; j < headers.length; j++) {
      row[headers[j].trim()] = values[j].trim();
    }

    rows.push(row);
  }

  return rows;
}

const instance = new CsvHelper();
export default instance;
