import { ISdColDef, ISdFilterDef } from "../../baseColumn/BaseColumnTypes";
import { BASE_COLUMN_CONFIG, BASE_FILTER_CONFIG } from "../../baseColumn/BaseColumn_config";

export const DESCRIPTION_COLUMN_CONFIG = (columnOptions?: ISdColDef<any, any>): ISdColDef<any, any> => ({
  ...BASE_COLUMN_CONFIG,
  field: "description",
  headerName: "Description",
  editable: false,
  cellRenderer: params => {
    return <div className="grid-cell" dangerouslySetInnerHTML={{ __html: params.data.description }}></div>;
  },
  autoHeight: false,
  ...columnOptions
});

export const DESCRIPTION_FILTER_CONFIG: ISdFilterDef = {
  ...BASE_FILTER_CONFIG,
  filter: "agTextColumnFilter",
  filterParams: {
    textFormatter: value => {
      const sanitizedContent = value
        .replace(/<a\s+href=['"]([^'"]*)['"][^>]*>(.*?)<\/a>/g, " $2 ($1) ") // Keeps anchor text and URL in parentheses
        .replace(/<[^>]+>/g, " ") // Removes all remaining tags
        .replace(/\s+/g, " ") // Removes extra spaces
        .trim();

      return sanitizedContent;
    },
    buttons: ["clear"]
  }
};
