import { useState } from "react";
import { Button, ButtonTypes } from "../../../../../components/ui/Button";
import { useIsOrganisationAdmin } from "../../../../../core/auth/authorise";
import { useCurrentOrganisationId } from "../../../../../services/local/organisationContext/OrganisationContextModel";
import { AdminSettingsModel } from "./AdminSettings_model";

export const AdminSettings: React.FC = () => {
  const organisationId = useCurrentOrganisationId();
  const isAdmin = useIsOrganisationAdmin(organisationId);
  const [model] = useState(() => new AdminSettingsModel());

  return (
    <div className="admin-settings mt-5">
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <h1>Admin Settings</h1>
          </div>
        </div>
        <div className="row">
          <div className="col">
            {isAdmin && (
              <Button onClick={() => model.updateActionRagStatus()} type={ButtonTypes.OUTLINE_PRIMARY}>
                Update Rag
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
