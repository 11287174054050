import _ from "lodash";
import { IconSymbols } from "../../../../../../../components/ui/Icon";
import { AutocompleteOption } from "../../../../../../../components/ui/_forms/Autocomplete/AutocompleteOption";
import { FORM_COL } from "../../../../../../../constants";
import { IMultiSelectorModel } from "../../../../../../../core/forms/controls/multiSelector/IMultiSelectorModel";
import { INIT_MULTISELECTOR } from "../../../../../../../core/forms/controls/multiSelector/MultiSelector_model";
import { generateFormFieldsFromJson } from "../../../../../../../core/forms/helpers/FormFieldMappers";
import I18n from "../../../../../../../core/localization/I18n";
import { ProjectJobRolesApi } from "../../../../../../../services/api/v2/projectJobRoles/ProjectJobRoles.api";

export const getProjectProcessRolesSidebarFormFields = (
  projectJobRolesProvider: ProjectJobRolesApi,
  canEdit: boolean,
  organisationId: number,
  projectId: number,
  processJobRole: FP.Entities.IProjectJobRoleSummary,
  jobRoleProcessRoles: FP.Entities.IProjectProcessRole[],
  loadProjectProcessJobRoleProcessRoles: Function
) => {
  const addProcessRole = async (processRoleId: number) => {
    let res = await projectJobRolesProvider.assignProjectProcessRoles(
      organisationId,
      projectId,
      [processJobRole.id],
      [processRoleId]
    );
    if (!res || res.isError) return null;
    loadProjectProcessJobRoleProcessRoles(processJobRole.id);
    return res.payload;
  };

  const removeProcessRole = async (processRoleId: number) => {
    let res = await projectJobRolesProvider.removeProjectProcessRole(
      organisationId,
      projectId,
      processRoleId,
      processJobRole.id
    );
    if (!res || res.isError) return null;
    return res.payload;
  };

  const projectProcessRoles: Partial<IMultiSelectorModel> = {
    ...INIT_MULTISELECTOR,
    key: "projectProcessRoles",
    placeholder: I18n.t("placeholders.searchProjectProcessRoles"),
    optionElement: (
      <AutocompleteOption
        key={"e"}
        className={"autocomplete__chip"}
        label={(e: FP.Entities.IProjectProcessRole) => {
          return e.name;
        }}
      />
    ),
    disabled: !canEdit,
    label: <label htmlFor="projectProcessRoles">{I18n.t("forms.projectProcessRoles")}</label>,
    onItemSelected: item => {
      addProcessRole(item.id);
    },
    onFocus: async function () {
      const self: IMultiSelectorModel = this;

      const res = await projectJobRolesProvider.getUnselectedByOrgIdAndProcessJobRoleId(
        organisationId,
        projectId,
        processJobRole.id
      );

      if (res?.payload) {
        const sortedProcessRoles = _.orderBy(res.payload, [processRole => processRole.name.toLowerCase()]);
        self.setOptions(sortedProcessRoles);
      }
    },
    componentProps: {
      icon: IconSymbols.Search
    },
    searchAttribute: "name",
    fieldClassName: FORM_COL.FULL_WIDTH,
    extractValue: function () {
      return this.selectedItems.map(e => e.id);
    },
    removeItemFn: item => removeProcessRole(item.processRoleId),
    value: jobRoleProcessRoles,
    valueLabelFn: e => e?.name
  };

  let fields = [];
  fields.push(projectProcessRoles);

  return generateFormFieldsFromJson(fields);
};
