import { GridApi } from "ag-grid-community";
import { IGridAddon } from "./IGridAddon";

interface ICellSelectionAddonProps {}

export class CellSelectionAddon implements IGridAddon<ICellSelectionAddonProps> {
  private removeAllClassNames = () => {
    const items: NodeListOf<Element> = document.querySelectorAll('div[class*="user-active-cell"]');

    items.forEach(el => {
      for (let i = el.classList.length - 1; i >= 0; i--) {
        const className = el.classList[i];
        if (className.startsWith("user-active-cell")) {
          el.classList.remove(className);
        }
      }
    });
  };

  styleSelectedCells = (users: any[]) => {
    this.removeAllClassNames();
    this.assignCellColourSelection(users);
  };

  private assignCellColourSelection = users => {
    users.forEach((e, i) => {
      const el = document.querySelector(`.ag-selectable-${e.cell}`);
      if (!el) return;
      el.classList.add("user-active-cell" + (e.isEditMode ? "--edit" : ""));
      el.classList.add("user-active-cell--" + (i + 1));
    });
  };

  onGridReady: (gridApi: GridApi<any>) => void;
  getProps = () => {
    return {};
  };
}
