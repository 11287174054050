import _ from "lodash";
import { action, makeObservable, observable } from "mobx";
import moment from "moment";
import { Tooltip } from "react-tippy";
import { ConditionFilterKeyType } from "../../../../FlightPathEntities";
import {
  ITimelineGroup,
  ITimelineItem,
  ITimelineMarker,
  TimeLineModel
} from "../../../../components/widgets/TimelineWidget/Timeline_model";
import I18n from "../../../../core/localization/I18n";
import { IModalContextModel } from "../../../../core/modalZ/context/IModalContext";
import ModalContext from "../../../../core/modalZ/context/ModalContext";
import { BaseModel } from "../../../../core/util/BaseModel";
import { Enums } from "../../../../enums";
import ProgrammesApi, { ProgrammesApi as IProgrammesApi } from "../../../../services/api/v2/programmes/Programmes.api";
import LocalStorageService from "../../../../services/local/localStorageService/LocalStorageService";
import { TimelineItem } from "../../../insight/organisations/dashboard/Timeline/components/TimelineItem";

export class ProgrammeViewModel extends BaseModel {
  programmeProvider: IProgrammesApi;
  innerModalService: IModalContextModel;
  timelineModel: TimeLineModel;
  orgId: number;
  @observable isLoading: boolean = true;
  @observable isInsightLoading: boolean = true;
  @observable.ref projects: FP.Entities.IProject[];
  @observable.ref programme: FP.Entities.IProgramme;
  @observable timelineAudiences: any = [];
  @observable selectedTimelineAudiences: any = [];
  @observable selectedContainsFilterKey: ConditionFilterKeyType = Enums.ConditionFilterKey.OR;
  @observable.ref organisation: FP.Entities.IOrganisation;
  @observable.ref selectedMilestone?: FP.Entities.IProgrammeMilestone;
  @observable.ref programmeDashboardData?: FP.Entities.IProgrammeDashboard;
  @observable.ref timelineData = [];
  @observable.ref initialTimelineData = [];

  constructor() {
    super();
    makeObservable(this);
    this.programmeProvider = ProgrammesApi;
    this.timelineModel = new TimeLineModel();
    this.innerModalService = ModalContext;
    let localStorageService = LocalStorageService;
    this.orgId = parseInt(localStorageService.get(Enums.LocalCookies.ORGANISATION_ID), 10);
  }

  onMount = async (programmeId: number) => {
    this.setIsLoading(true);
    await this.loadProgrammeDashboard(programmeId).then(async () => {
      await this.loadTimelineData(programmeId).then(() => {
        this.setIsLoading(false);
      });
    });
  };

  onUnmount = () => {};

  @action
  setTimelineData = (timelineData: FP.Entities.IProgramme[] | FP.Entities.IProject[]) => {
    this.timelineData = timelineData;
    this.setTimelineDefaultDates(this.timelineData);
    this.setTimeLineItems(this.timelineData);
  };

  @action
  setInitialTimelineData = (initialTimelineData: any) => {
    this.initialTimelineData = initialTimelineData;
  };

  @action
  setSelectedContainsFilterKey = (selectedContainsFilterKey: ConditionFilterKeyType) => {
    this.selectedContainsFilterKey = selectedContainsFilterKey;
    this.filterProjects(this.selectedTimelineAudiences);
  };

  loadProgrammeDashboard = async (id: number) => {
    let res = await this.programmeProvider.getProgrammeDashboard(this.orgId, id);
    if (!res || res.isError) return;
    this.setProgrammeDashboard(res.payload);
  };

  loadTimelineData = async (programmeId: number) => {
    const programmesResult = await this.programmeProvider.getTimelineReport(this.orgId, programmeId);
    const audienceList = _.uniqBy(programmesResult.payload.projects.map(e => e.audiences).flat(), "id");

    if (!programmesResult || programmesResult.isError) return;
    this.setInitialTimelineData([programmesResult.payload, ...programmesResult.payload.projects]);
    this.setTimelineData([programmesResult.payload, ...programmesResult.payload.projects]);
    this.setTimelineAudiences(audienceList);
  };

  @action
  filterProjects = (selectedAudiences: any) => {
    if (selectedAudiences.length === 0) {
      this.setTimelineData(this.initialTimelineData);
    } else {
      const filtered = this.initialTimelineData.filter(item => {
        return (
          !!item.projects ||
          (this.selectedContainsFilterKey === Enums.ConditionFilterKey.OR
            ? item.audiences.some(audience => selectedAudiences.map(aa => aa.id).includes(audience.id))
            : selectedAudiences
                .map(aa => aa.id)
                .every(selectedId => item.audiences.some(audience => selectedId === audience.id)))
        );
      });
      this.setTimelineData(filtered);
    }
  };

  @action
  setProjects = (projects: FP.Entities.IProject[]) => {
    this.projects = projects;
    this.isInsightLoading = false;
  };

  @action
  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };

  @action
  setTimelineAudiences = (timelineAudiences: any) => {
    this.timelineAudiences = timelineAudiences;
  };

  @action
  setSelectedTimelineAudiences = (selectedTimelineAudiences: any) => {
    this.selectedTimelineAudiences = selectedTimelineAudiences;
    this.filterProjects(selectedTimelineAudiences);
  };

  @action
  setProgrammeDashboard = (programmeDashboard: FP.Entities.IProgrammeDashboard) => {
    this.programmeDashboardData = programmeDashboard;
  };

  @action
  removeAudienceFilterById = (id: number) => {
    const audienceIndex = this.selectedTimelineAudiences.map(e => e.id).indexOf(id);
    let k = this.selectedTimelineAudiences.slice();
    k.splice(audienceIndex, 1);
    this.setSelectedTimelineAudiences(k);
  };

  handleMilestoneClick = (milestone: FP.Entities.IProgrammeMilestone) => {
    this.setMilestone(milestone);

    const foundMarker = this.timelineModel.markers.find(marker => marker.id === milestone.id);

    if (foundMarker) {
      this.timelineModel.setSelectedMarker(foundMarker);
      this.timelineModel.scrollToDate(foundMarker.date);
    }
  };

  @action
  setMilestone = (milestone: FP.Entities.IProgrammeMilestone) => {
    this.selectedMilestone = milestone;
  };

  handleTimelineMarkerSelect = (marker: ITimelineMarker) => {
    const foundMilestone = this.programmeDashboardData.programmeMilestones.find(
      milestone => milestone.id === marker.id
    );

    if (foundMilestone) {
      this.setMilestone(foundMilestone);
    }
  };

  @action
  showLocationsModalFn = event => {
    event.preventDefault();
  };

  setTimelineDefaultDates = (timelineData: FP.Entities.IProgramme[] | FP.Entities.IProject[]) => {
    const proj = timelineData.map(e => {
      return {
        ...e,
        mStartDate: moment(e.startDate),
        mEndDate: moment(e.endDate)
      };
    });
    const minDate = _.minBy(proj, e => e.mStartDate);
    const maxDate = _.maxBy(proj, e => e.mEndDate);
    const startDate = minDate.mStartDate;
    const endDate = maxDate.mEndDate;
    this.timelineModel.setDefaultDates(startDate, endDate);
    this.timelineModel.setVisibleDates(startDate.subtract(10, "days"), endDate.add(10, "days"));
  };

  setTimeLineItems = (timelineData: FP.Entities.IProgramme[] | FP.Entities.IProject[]) => {
    const items: ITimelineItem[] = timelineData.map(timelineItem => {
      const id = !timelineItem.projects
        ? `${this.programmeDashboardData.programmeDetails.id}_${timelineItem.id}`
        : `${timelineItem.id}`;
      return {
        id,
        group: id,
        title: timelineItem.name,
        start_time: moment(timelineItem.startDate),
        end_time: moment(timelineItem.endDate),
        data: timelineItem,
        isParent: id.toString().split("_").length === 1,
        itemProps: {
          style: {
            background: "#308096",
            border: `1px solid ${"#308096"}`
          }
        }
      };
    });

    let groups: ITimelineGroup[] = timelineData.map(timelineItem => {
      const id = !timelineItem.projects
        ? `${this.programmeDashboardData.programmeDetails.id}_${timelineItem.id}`
        : `${timelineItem.id}`;

      return {
        id,
        start_time_v: moment(timelineItem.startDate).valueOf(),
        isParent: id.toString().split("_").length === 1,
        title:
          timelineItem.name.length > 16 ? (
            <Tooltip theme="light" followCursor html={<small className="d-block">{timelineItem.name}</small>}>
              {timelineItem.projects ? (
                <div style={{ paddingLeft: "25px" }}>
                  <strong>{timelineItem.name}</strong>
                </div>
              ) : (
                <div style={{ paddingLeft: "35px" }}>{timelineItem.name}</div>
              )}
            </Tooltip>
          ) : timelineItem.projects ? (
            <div style={{ paddingLeft: "25px" }}>
              <strong>{timelineItem.name}</strong>
            </div>
          ) : (
            <div style={{ paddingLeft: "35px" }}>{timelineItem.name}</div>
          )
      };
    });

    groups = _.sortBy(groups, (e: any) => [e.id, e.start_time_v]);
    const sortedItems = _.sortBy(items, (e: any) => [e.group, e.start_time]);

    const markers: ITimelineMarker[] = sortedItems
      .map((project, index) => {
        return project.data.milestones.map(milestone => {
          const cs = milestone.milestoneType
            ? `timeline-marker__icon--${Enums.Translator.MilestoneTypeKey(milestone.milestoneType)}`
            : "";
          return {
            id: milestone.id,
            date: moment(milestone.deadline),
            project: (index) * 40 + 10,
            typeClass: cs,
            title: milestone.name,
            description: milestone.description
          };
        });
      })
      .flat();

    this.timelineModel.setItems(items);
    this.timelineModel.setGroups(groups);
    this.timelineModel.setSidebarWidth(350);
    this.timelineModel.setSideheaderTitle(<strong className="pl-3 pb-1">{I18n.t("phrases.programmeProjects")}</strong>);
    this.timelineModel.setMarkers(markers);
    // this.timelineModel.onMarkerSelect = this.handleTimelineMarkerSelect;
    this.timelineModel.itemRenderer = TimelineItem(this.orgId);
    this.timelineModel.groupRenderer = ({ group }) => {
      const className = !group.isParent ? "" : "highlighted";
      return <div className={className}>{group.title}</div>;
    };
    this.timelineModel.isLoading = false;
  };
}
