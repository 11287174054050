import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Tabs, Tab } from "../../../../components/ui/Tabs";
import I18n from "../../../../core/localization/I18n";
import { useCurrentOrganisationId } from "../../../../services/local/organisationContext/OrganisationContextModel";
import { ImpactVisualisationsViewModel } from "./ImpactVisualisationsView_model";
import { useNavigate, useParams } from "react-router-dom";
import { ActionBar } from "../../../../components/widgets/actionBar/ActionBar_view";
import { InnerNavContent } from "../../../../components/widgets/innerNavigation/InnerNavContent_view";
import { useIsVisualisationsEnabled } from "../../../../contexts/organisationSettings/OrganisationSettingsContext";

export interface ImpactVisualisationsViewProps {
  model?: ImpactVisualisationsViewModel;
}

const ImpactVisualisationsView: React.FunctionComponent<ImpactVisualisationsViewProps> = observer(({ model: m }) => {
  const navigate = useNavigate();
  const organisationId = useCurrentOrganisationId();
  const { projectId, page } = useParams<{ projectId: string; page: string }>();
  const [model, setModel] = useState(
    () => new ImpactVisualisationsViewModel(navigate, !!projectId ? +projectId : null, organisationId, page)
  );
  const modelProjectId = model.projectId;
  const modelPage = model.currentPage;

  const isVisualisationsEnabled = useIsVisualisationsEnabled();

  useEffect(() => {
    if (`${modelProjectId}` !== projectId) {
      setModel(new ImpactVisualisationsViewModel(navigate, !!projectId ? +projectId : null, organisationId, page));
    }

    model.onMount();
    return model.onUnmount;
  }, [projectId, modelProjectId, organisationId, model, navigate, page]);

  useEffect(() => {
    if (`${modelPage}` !== (page || "")) {
      setModel(new ImpactVisualisationsViewModel(navigate, !!projectId ? +projectId : null, organisationId, page));
    }

    model.onMount();
    return model.onUnmount;
  }, [modelPage, page, organisationId, model, navigate, projectId]);

  const tabHeadingWithCount = (title: string, count?: number) => {
    var countText = count != null ? `(${count})` : "";

    return <h2 className="mb-0">{`${title} ${countText}`}</h2>;
  };

  return (
    <div className="impacts-view" style={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      <Tabs
        key="first"
        className="tabs--primary"
        ulClassName="tabs__menu--primary"
        initialTab={2}
        liClassName="tabs__item--primary"
        onTabClicked={model.changeCurrentView}
      >
        <Tab
          title={
            <h2 className="mb-0">{tabHeadingWithCount(I18n.t("table.highLevelImpacts"), model.impactGroupCount)}</h2>
          }
        />
        <Tab
          title={<h2 className="mb-0">{tabHeadingWithCount(I18n.t("table.detailedImpacts"), model.impactCount)}</h2>}
        />
        <Tab
          isHidden={!isVisualisationsEnabled}
          title={<h2 className="mb-0">{I18n.t("phrases.visualisationTabHeading")}</h2>}
        />
        <Tab title={<h2 className="mb-0">{I18n.t("phrases.impactAssessment")}</h2>} />
      </Tabs>
      <ActionBar></ActionBar>
      <InnerNavContent model={model.innerNavContentModel}></InnerNavContent>
    </div>
  );
});

export { ImpactVisualisationsView };
