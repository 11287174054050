import * as React from "react";
import { ErrorMessage } from "../../../../components/ui/ErrorMessage";
import { FormTooltip } from "../../../../components/ui/_forms/FormTooltip";
import { FORM_COL } from "../../../../constants";
import { ISliderFieldModel } from "../../../../core/forms/controls/slider/ISliderFieldModel";
import { INIT_SLIDER_FIELD } from "../../../../core/forms/controls/slider/SliderField_init";
import { ITextFieldModel } from "../../../../core/forms/controls/textField/ITextFieldModel";
import { INIT_TEXT_FIELD } from "../../../../core/forms/controls/textField/TextField_init";
import { TextFieldModel } from "../../../../core/forms/controls/textField/TextField_model";
import { generateFormFieldsFromJson } from "../../../../core/forms/helpers/FormFieldMappers";
import { Validations } from "../../../../core/forms/helpers/Validations";
import I18n from "../../../../core/localization/I18n";
import { ImpactTypesApi } from "../../../../services/api/v2/impactTypes/ImpactTypes.api";

export const getImpactTypeFormFields = (
  organisationId: number,
  impactTypeProvider: ImpactTypesApi,
  impactType?: FP.Entities.IImpactType
) => {
  const baseSlider: Partial<ISliderFieldModel> = {
    ...INIT_SLIDER_FIELD,
    componentProps: {
      valueLabelDisplay: "auto",
      defaultValue: 0.5,
      step: 0.05,
      min: 0.1,
      max: 1.0,
      marks: true
    }
  };

  const org: Partial<ITextFieldModel> = {
    ...INIT_TEXT_FIELD,
    key: "organisationId",
    inputType: "hidden",
    value: organisationId + "",
    defaultValue: organisationId + ""
  };

  const name: Partial<ITextFieldModel> = {
    ...INIT_TEXT_FIELD,
    key: "name",
    placeholder: I18n.t("placeholders.impactTypeName"),
    label: <label htmlFor="name">{I18n.t("forms.impactTypeName")} *</label>,
    fieldClassName: FORM_COL.FULL_WIDTH,
    validate: async function () {
      const self: TextFieldModel = this;
      const trimmedImpactTypeName = impactType?.name.trim().replace(/\s+/g, " ") || null;
      const trimmedValue = self.value.trim().replace(/\s+/g, " ");
      let existingImpactTypesRes = await impactTypeProvider.getImpactTypesByName(organisationId, trimmedValue);

      let existingImpactTypes = existingImpactTypesRes.payload.map(e => e.name);

      if (Validations.IS_EMPTY(self.value)) {
        self.errorMessage = <ErrorMessage key="name">{I18n.t("validations.impactTypeName")}</ErrorMessage>;
        return false;
      }

      if (
        existingImpactTypes?.length > 0 &&
        existingImpactTypes.indexOf(trimmedValue) > -1 &&
        trimmedValue !== trimmedImpactTypeName
      ) {
        self.errorMessage = <ErrorMessage key="name">{I18n.t("validations.impactTypeNameExists")}</ErrorMessage>;
        return false;
      }
      return true;
    },
    value: impactType?.name
  };

  const impactTypeWeighting: Partial<ISliderFieldModel> = {
    ...baseSlider,
    key: "weighting",
    label: <label htmlFor="impactTypeWeighting">{I18n.t("forms.impactTypeWeighting")}</label>,
    fieldClassName: "col mb-lg-0",
    validate: function () {
      const self: ISliderFieldModel = this;
      let res = true;
      if (Validations.IS_EMPTY(self.extractValue())) {
        self.errorMessage = <ErrorMessage>{I18n.t("validations.impactTypeWeighting")}</ErrorMessage>;
        res = false;
      }
      return res;
    },
    value: impactType?.weighting,
    tooltipLabel: (
      <FormTooltip>
        <p className="mb-0">{I18n.t("tooltips.impactTypeWeighting")}</p>
      </FormTooltip>
    ),
    defaultValue: 0
  };

  const fields = [];

  fields.push(org);
  fields.push(name);
  fields.push(impactTypeWeighting);

  const models = generateFormFieldsFromJson(fields);

  return models;
};
