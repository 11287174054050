import React from "react";
import I18n from "../../../../../../core/localization/I18n";
import { ReportingCard } from "../../../../projects/Actions/ActionReportingView/TopActionsReportingBar/ReportingCard";
import { DashboardSunburstCard } from "./DashboardSunburstCard";

export const DashboardSunburstChart = ({ sunburst, organisationId }) => {
  return (
    <ReportingCard>
      <div className="container-fluid py-4">
        <div className="row">
          <div className="col">
            <div className="heatmap__main-title">
              <h2>{I18n.t("phrases.dashboardSunburstTitle").toUpperCase()}</h2>
            </div>
          </div>
        </div>

        <div className="row">
          <DashboardSunburstCard sunburst={sunburst} organisationId={organisationId} />
        </div>
      </div>
    </ReportingCard>
  );
};
