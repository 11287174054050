import { observer } from "mobx-react";
import { SingleForm } from "../../../forms/singleFormModel/SingleForm_view";
import { TimelineByAudienceFilterModalModel } from "./TimelineByAudienceFilter_model";
import { useEffect } from "react";
import { ConditionFilterKeyType } from "../../../../../FlightPathEntities";
import "./_timeline-audience-filter.scss";

export interface TimelineByAudienceFilterProps {
  programmeId: number;
  audienceList: any;
  selectedTimelineAudiences: number[];
  selectedContainsFilterKey: ConditionFilterKeyType;
  setSelectedTimelineAudiences: (selection: any) => void;
  setContainsFilterKey: (selection: any) => void;
}
export const TimelineByAudienceFilter: React.FC<TimelineByAudienceFilterProps> = observer(props => {
  const model = new TimelineByAudienceFilterModalModel(
    props.programmeId,
    props.audienceList,
    props.selectedTimelineAudiences,
    props.selectedContainsFilterKey,
    props.setSelectedTimelineAudiences,
    props.setContainsFilterKey
  );

  useEffect(() => {
    model.onMount();
  });

  return (
    <div className="row justify-content-end timeline-audience-filter">
      <SingleForm model={model.formModel} />
    </div>
  );
});
