import React, { Dispatch, useState } from "react";
import { ButtonTypes } from "../../../../../../../../components/ui/Button";
import I18n from "../../../../../../../../core/localization/I18n";
import { IUiAction, UiActionRenderers } from "../../../../../../../../core/uiAction/IUiAction";
import { SingleFormModel } from "../../../../../../../../pages/change/forms/singleFormModel/SingleForm_model";
import { SingleForm } from "../../../../../../../../pages/change/forms/singleFormModel/SingleForm_view";
import { ProjectProcessesApi } from "../../../../../../../../services/api/v2/projectProcesses/ProjectProcesses.api";
import { useParams } from "react-router-dom";
import { IAutocompleteModel } from "../../../../../../../forms/controls/autocomplete/IAutocompleteModel";
import { AutocompleteOption } from "../../../../../../../../components/ui/_forms/Autocomplete/AutocompleteOption";
import { INIT_AUTOCOMPLETE } from "../../../../../../../forms/controls/autocomplete/Autocomplete_init";
import { IconSymbols } from "../../../../../../../../components/ui/Icon";
import { Validations } from "../../../../../../../forms/helpers/Validations";
import { ErrorMessage } from "../../../../../../../../components/ui/ErrorMessage";
import { FORM_COL } from "../../../../../../../../constants";
import { IListingModel } from "../../../../../../../forms/controls/listing/IListingModel";
import { INIT_LISTING_FIELD } from "../../../../../../../forms/controls/listing/Listing_model";
import _ from "lodash";
import { generateFormFieldsFromJson } from "../../../../../../../forms/helpers/FormFieldMappers";

interface AddImpactFormProps {
  projectProcessesProvider: ProjectProcessesApi;
  organisationId: number;
  reloadFn: (id: number) => void;
  setFormIsShown?: Dispatch<boolean>;
  projectProcessId: number;
}

class AddImpactFormModel {
  formModel: SingleFormModel;

  constructor(
    projectProcessesProvider: ProjectProcessesApi,
    organisationId: number,
    projectId: number,
    projectProcessId: number,
    reloadFn,
    setFormIsShown
  ) {
    this.formModel = new SingleFormModel();

    let formFields = getProjectProcessImpactFormFields(
      projectProcessesProvider,
      organisationId,
      projectId,
      projectProcessId
    );
    let actions: IUiAction<any>[] = [
      {
        id: "action1",
        label: I18n.t("phrases.cancel"),
        onAction: ev => {
          setFormIsShown(false);
        },
        componentProps: {
          type: ButtonTypes.LINK,
          className: "ml-auto"
        },
        rendersIn: UiActionRenderers.BUTTON
      },
      {
        id: "action2",
        label: I18n.t("phrases.add"),
        onAction: async () => {
          const formRes = await this.formModel.submit();
          if (!formRes) return;
          const res = await projectProcessesProvider.addMultipleImpacts(
            organisationId,
            projectId,
            [projectProcessId],
            formRes.impacts
          );

          if (!res || res.isError) return;

          if (res.payload) {
            reloadFn(projectProcessId);
          }
        },
        componentProps: {
          className: "ml-2"
        },
        rendersIn: UiActionRenderers.BUTTON
      }
    ];
    this.formModel.formFields = formFields;
    this.formModel.actions = actions;
  }
}

export const AddImpactForm: React.FC<AddImpactFormProps> = props => {
  const { projectProcessesProvider, projectProcessId, organisationId, reloadFn, setFormIsShown } = props;
  const { projectId } = useParams();

  let [model] = useState(
    () =>
      new AddImpactFormModel(
        projectProcessesProvider,
        organisationId,
        +projectId,
        projectProcessId,
        reloadFn,
        setFormIsShown
      )
  );

  return (
    <div className="container-fluid">
      <SingleForm model={model.formModel} />
    </div>
  );
};

export const getProjectProcessImpactFormFields = (
  projectProcessProvier: ProjectProcessesApi,
  organisationId: number,
  projectId: number,
  projectProcessId: number
) => {
  let impactSearch: Partial<IAutocompleteModel> = {
    ...INIT_AUTOCOMPLETE,
    key: "impactSearch",
    label: <label htmlFor={"impactSearch"}>{I18n.t("forms.actionImpact")}</label>,
    placeholder: I18n.t("placeholders.searchDetailedImpact"),
    optionElement: (
      <AutocompleteOption
        key={"e"}
        className={"autocomplete__chip"}
        label={(e: FP.Entities.IImpact) => `${e.refNumber} - ${e.name}`}
      />
    ),
    componentProps: {
      className: "form-control",
      icon: IconSymbols.Search
    },
    validate: function () {
      const self: IAutocompleteModel = this;
      let res = true;
      if (Validations.IS_EMPTY(self.extractValue()) && !self.subscribers[0].value.length) {
        self.errorMessage = <ErrorMessage>{I18n.t("validations.noEmptyImpact")}</ErrorMessage>;
        res = false;
      }
      return res;
    },
    onFocus: async function () {
      const self: IAutocompleteModel = this;
      self.removeErrorMessage();
      const res = await projectProcessProvier.getUnassignedImpactsByProjectProcessId(
        organisationId,
        projectId,
        projectProcessId
      );

      if (res?.payload) {
        const sortedImpacts = _.orderBy(res.payload, [impact => impact.name.toLowerCase()]);
        self.setOptions(sortedImpacts);
      }
    },
    shouldClearOnBlur: true,
    filterFn: (items: FP.Entities.IImpact[], query) => {
      const lowerQuery = query.toLowerCase();
      return _.filter(items, item => {
        const lowerName = item.name.toLowerCase();
        const lowerRef = item.refNumber?.toLowerCase();
        return lowerName.indexOf(lowerQuery) > -1 || lowerRef.indexOf(lowerQuery) > -1;
      });
    },
    fieldClassName: FORM_COL.FULL_WIDTH,
    valueLabelFn: e => e.name
  };

  let listing: Partial<IListingModel> = {
    ...INIT_LISTING_FIELD,
    key: "impacts",
    label: <label htmlFor={"impacts"}>{I18n.t("forms.selectedDetailedImpacts")}</label>,
    placeholder: I18n.t("placeholders.selectDetailedImpact"),
    fieldClassName: FORM_COL.FULL_WIDTH,
    subscribeTo: ["impactSearch"],
    onChannelFieldChanged: function (field) {
      let val = field.value;
      if (val) {
        this.addItem(val);
        field.reset();
      }
    },
    extractValue: function () {
      return this.value && this.value.map(e => e.id);
    },
    selector: (e: FP.Entities.IImpact) => (
      <p className="mb-0 d-inline-block">
        {e.refNumber} {e.name}
      </p>
    )
  };

  const fields = [];

  fields.push(impactSearch);
  fields.push(listing);
  const models = generateFormFieldsFromJson(fields);
  return models;
};
