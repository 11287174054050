import _ from "lodash";
import { action, makeObservable, observable } from "mobx";
import * as React from "react";
import { ButtonIcon, ButtonTypes } from "../../../../../components/ui/Button";
import { Icon, IconSymbols } from "../../../../../components/ui/Icon";
import { Panel } from "../../../../../components/ui/Panel";
import { FilterModel, FilterOperator, IFilterAttribute, IFilterModel } from "../../../../../core/filter/Filter_model";
import { IInfiniteLoaderModel, InfiniteLoaderModel } from "../../../../../core/filter/InfiniteLoader_model";
import ProgressIndicatorModel, {
  ProgressIndicatorModel as IProgressIndicatorModel
} from "../../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import I18n from "../../../../../core/localization/I18n";
import { Animations } from "../../../../../core/util/Animations";
import { DisposableModel } from "../../../../../core/util/DisposableModel";
import { Enums } from "../../../../../enums";
import TagsApi, { TagsApi as ITagsApi } from "../../../../../services/api/v2/tags/Tags.api";
import { TagSettingsEditModalModel } from "./TagSettingsEditModal/TagSettingsEditModal_model";
import { TagSettingsEditModalView } from "./TagSettingsEditModal/TagSettingsEditModal_view";
import { TagSettingsOverviewModalModel } from "./TagSettingsOverviewModal/TagSettingsOverviewModal_model";
import { TagSettingsOverviewModalView } from "./TagSettingsOverviewModal/TagSettingsOverviewModal_view";
import { IModalContextModel } from "../../../../../core/modalZ/context/IModalContext";
import ModalContext from "../../../../../core/modalZ/context/ModalContext";

export class TagSettingsModel extends DisposableModel {
  filterModel: IFilterModel<FP.Entities.ITag>;
  projectId: number;
  httpProgress: IProgressIndicatorModel;
  modalService: IModalContextModel;
  organisationId: number;
  infiniteLoaderModel: IInfiniteLoaderModel;
  tagsProvider: ITagsApi;

  @observable.ref tags: FP.Entities.ITag[];
  @observable isLoading: boolean = true;
  @observable searchValue: string = "";

  constructor(organisationId: number) {
    super();
    makeObservable(this);
    this.tagsProvider = TagsApi;
    this.organisationId = organisationId;
    this.httpProgress = ProgressIndicatorModel;
    this.modalService = ModalContext;
    this.installInfiniteLoader();
    this.installFilter();
  }

  @action
  loadPageData = async () => {
    const data = await this.filterModel.loadData();
    if (data) {
      this.setTags(data);
    }
  };

  installInfiniteLoader = () => {
    this.infiniteLoaderModel = new InfiniteLoaderModel();
  };

  installFilter = () => {
    const config = {
      infiniteLoaderModel: this.infiniteLoaderModel,
      initOpts: {
        pageSize: 20,
        filterCb: async filterOptions => {
          return await this.tagsProvider.getFilteredWithUsageCount(this.organisationId, filterOptions);
        }
      },
      onInfiniteLoadReset: this.resetData
    };
    this.filterModel = new FilterModel(config);

    const nameFilter: IFilterAttribute = {
      key: "name",
      label: I18n.t("filters.name"),
      value: [],
      operator: FilterOperator.CONTAINS
    };
    const lifecycleFilter: IFilterAttribute = {
      key: "lifecycleStatus",
      value: [Enums.LifecycleStatus.Active + ""],
      isHidden: true,
      operator: FilterOperator.EQUALS
    };

    const organisationIdFilter: IFilterAttribute = {
      key: "organisationId",
      value: [this.organisationId.toString()],
      isHidden: true,
      operator: FilterOperator.EQUALS
    };

    this.filterModel.addFilter(nameFilter);
    this.filterModel.addFilter(lifecycleFilter);
    this.filterModel.addFilter(organisationIdFilter);
    this.filterModel.addSort({ key: "name", isAsc: true });

    this.filterModel.setConfig({
      formFields: s => [],
      onDataLoaded: d => this.setTags(d)
    });
  };

  onMount = async () => {
    const data = await this.loadTags();
    this.setTags(data);
  };

  onUnmount = () => {};

  loadTags = async () => {
    await this.filterModel.loadData();
    return this.filterModel.data;
  };

  @action
  resetData = () => {
    this.tags = [];
    this.setTags(this.tags);
  };

  @action
  setTags = async (tags: FP.Entities.ITag[]) => {
    this.tags = _.union(this.tags, tags);
    this.infiniteLoaderModel.setTotal(this.tags.length);
    this.isLoading = false;
  };

  showTagConfirmDeleteModal = (tag: FP.Entities.ITag) => {
    return new Promise(resolve => {
      this.modalService.showConfirmDialog(
        <h1 className="mt-4">{I18n.t("phrases.confirm")}</h1>,
        <div className="container-fluid">
          <div className="row mb-3">
            <div className="col-12">
              <Icon symbol={IconSymbols.AlertCircle} className="mr-2" />
              {I18n.t("warnings.removeTagFromOrg")}
            </div>
          </div>
          <div className="row">
            <div className="col">{I18n.t("phrases.confirmRemove", { name: tag.text })}</div>
          </div>
        </div>,
        I18n.t("phrases.yes"),
        I18n.t("phrases.no"),
        {
          wrapWidth: "small",
          spacing: "small",
          position: "middle",
          panelProps: {
            background: Panel.PanelBackgrounds.BG_WHITE
          }
        },
        async () => {
          await this.removeTag(tag.id);
          this.modalService.hide();
          resolve(true);
        },
        () => {
          this.modalService.hide();
        },
        ButtonTypes.DANGER
      );
    });
  };

  showTagModal = (tag: FP.Entities.ITag, modalType: string) => {
    this.modalService.show({
      showClose: false,
      title: (
        <div className="d-flex flex-row-reverse mt-6 mb-2">
          <ButtonIcon
            key={"1"}
            type={ButtonTypes.OUTLINE_PRIMARY}
            iconSize={Enums.UiSizes.SM}
            symbol={IconSymbols.Close}
            onClick={async () => {
              this.filterModel.resetFilterValues("");
              await this.loadPageData();
              return this.modalService.hide();
            }}
          />
        </div>
      ),
      content:
        modalType === "edit" ? (
          <TagSettingsEditModalView model={new TagSettingsEditModalModel(this, tag)} />
        ) : (
          <TagSettingsOverviewModalView model={new TagSettingsOverviewModalModel(this.organisationId, tag.id)} />
        ),
      componentProps: {
        wrapHeight: "full",
        wrapWidth: "small",
        position: "right",
        panelProps: {
          background: Panel.PanelBackgrounds.BG_LIGHT,
          className: "h-auto min-h-100",
          hasShadow: true
        }
      },
      animationOptions: {
        animateIn: Animations.SLIDE_IN_RIGHT,
        animateOut: Animations.SLIDE_OUT_RIGHT
      }
    });
  };

  removeTag = async (tagId: number) => {
    this.httpProgress.showOverlay();
    let res = await this.tagsProvider.remove(this.organisationId, tagId);
    this.httpProgress.hideOverlay();

    if (!res || res.isError) return;
    this.removeTagFromList(tagId);
    return res;
  };

  @action
  removeTagFromList = (tagId: number) => {
    const list = _.remove(this.tags, e => e.id !== tagId);
    this.tags = list;
    this.infiniteLoaderModel.setTotal(this.tags.length);
    this.infiniteLoaderModel.setConfig({ totalItems: this.tags.length });
  };

  @action
  updateSearchValue = (ev: React.SyntheticEvent) => {
    let e = ev.currentTarget as HTMLInputElement;
    this.filterModel.setFilterValue("name", e.value);
  };

  resetNameFilter = () => {
    this.filterModel.setFilterValue("name", "");
  };
}
