import { ActionBulkUpdateSidebarModel } from "../../../../../../../../pages/insight/projects/Actions/ActionListView/AcGridView/modals/ActionBulkUpdateSidebar/ActionBulkUpdateSidebar_model";
import I18n from "../../../../../../../localization/I18n";
import { GridSideModalTitle } from "../../../../utils/GridModalUtils";
import { BulkEditActionsSidebar } from "./ActionBulkEditSidebar_view";

export const ACTION_BULK_EDIT_MODAL_CONFIG = (
  projectId: number,
  organisationId: number,
  selectedItems: number[],
  closeFn: any
) => {
  return {
    showClose: true,
    title: (
      <GridSideModalTitle
        name={I18n.t("phrases.updatingInBulk")}
        field={`${I18n.t("phrases.noOfActionsSelected", {
          plural: selectedItems.length > 1 ? "s" : "",
          num: selectedItems.length
        })}`}
      />
    ),
    content: (
      <>
        <BulkEditActionsSidebar
          model={new ActionBulkUpdateSidebarModel(projectId, organisationId, selectedItems, closeFn)}
        ></BulkEditActionsSidebar>
      </>
    )
  };
};
