import { validateAlphaValue } from "./validators";
import { rgbaToHex } from "./helpers";

export default class Color {
  public r: number;
  public g: number;
  public b: number;
  public a: number;
  yiqContrastedThreshold = 150;

  constructor(r: number, g: number, b: number, a: number = 1) {
    validateAlphaValue(a);

    this.r = r;
    this.g = g;
    this.b = b;
    this.a = a;
  }

  toRGBString() {
    return `rgb(${Math.floor(this.r * this.a)},${Math.floor(this.g * this.a)},${Math.floor(this.b * this.a)})`;
  }

  toRGBAString() {
    return `rgba(${this.r},${this.g},${this.b},${this.a})`;
  }

  toHexString() {
    return rgbaToHex(this);
  }

  isDarkColor() {
    const yiq = (this.r * 299 + this.g * 587 + this.b * 114) / 1000;
    if (yiq >= this.yiqContrastedThreshold) {
      return false;
    } else {
      return true;
    }
  }
}
