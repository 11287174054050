import { action, makeObservable, observable } from "mobx";
import { BaseModel } from "../../../../../../../../core/util/BaseModel";
import TagsApi, { TagsApi as ITagsApi } from "../../../../../../../../services/api/v2/tags/Tags.api";
import ProjectStakeholderOwnersApi, {
  ProjectStakeholderOwnersApi as IProjectStakeholderOwnersApi
} from "../../../../../../../../services/api/v2/projectStakeholderOwners/ProjectStakeholderOwners.api";
import ProjectStakeholdersApi, {
  ProjectStakeholdersApi as IProjectStakeholdersApi
} from "../../../../../../../../services/api/v2/projectStakeholders/ProjectStakeholders.api";

export class StakeholderOwnersSidebarModel extends BaseModel {
  projectStakeholderOwnersProvider: IProjectStakeholderOwnersApi;
  projectStakeholdersProvider: IProjectStakeholdersApi;
  tagsProvider: ITagsApi;
  @observable isLoading: boolean = true;
  @observable.ref projectStakeholderOwners: FP.Entities.IUser[];
  @observable.ref projectStakeholder: FP.Entities.IProjectStakeholder;
  organisationId: number;
  projectId: number;
  projectStakeholderId: number;

  constructor(organisationId: number, projectId: number, projectStakeholderId: number) {
    super();
    makeObservable(this);
    this.projectStakeholderOwnersProvider = ProjectStakeholderOwnersApi;
    this.projectStakeholdersProvider = ProjectStakeholdersApi;
    this.tagsProvider = TagsApi;
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.projectStakeholderId = projectStakeholderId;
  }

  onMount = async () => {
    await this.loadProjectStakeholder(this.projectStakeholderId).then(async res => {
      await this.loadProjectStakeholderOwners(this.projectStakeholder.id);
    });
  };

  @action
  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };

  @action
  setProjectStakeholder = (projectStakeholder: FP.Entities.IProjectStakeholder) => {
    this.projectStakeholder = projectStakeholder;
    this.setIsLoading(false);
  };

  @action
  loadProjectStakeholder = async (projectStakeholderId: number) => {
    let res = await this.projectStakeholdersProvider.getById(this.organisationId, this.projectId, projectStakeholderId);
    if (!res || res.isError) return;
    this.setProjectStakeholder(res.payload);
  };

  @action
  setProjectStakeholderOwners = (projectStakeholderOwners: FP.Entities.IUser[]) => {
    this.projectStakeholderOwners = projectStakeholderOwners;
    this.setIsLoading(false);
  };

  @action
  loadProjectStakeholderOwners = async (projectStakeholderId: number) => {
    let res = await this.projectStakeholderOwnersProvider.getStakeholderOwnersByProjectStakeholderId(
      this.organisationId,
      this.projectId,
      projectStakeholderId
    );
    if (!res || res.isError) return;
    this.setProjectStakeholderOwners(res.payload);
  };

  @action
  removeOwner = async (ownerId: number, projectStakeholderId: number) => {
    let res = await this.projectStakeholderOwnersProvider
      .removeByOwnerIdAndStakeholderId(this.organisationId, this.projectId, ownerId, projectStakeholderId)
      .then(res => {
        this.onMount();
        return res;
      });
    if (!res || res.isError) return;
  };
}
