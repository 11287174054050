import { action, makeObservable, observable } from "mobx";
import I18n from "../../../../core/localization/I18n";
import { BaseModel } from "../../../../core/util/BaseModel";
import ImpactGroupsApi, {
  ImpactGroupsApi as IImpactGroupsApi
} from "../../../../services/api/v2/impactGroups/ImpactGroups.api";
import ProjectsApi, { ProjectsApi as IProjectsApi } from "../../../../services/api/v2/projects/Projects.api";
import { IQueryStringService } from "../../../../services/local/queryStringService/IQueryStringService";
import QueryStringService from "../../../../services/local/queryStringService/QueryStringService";
import { CommentListViewModel } from "../../comments/commentListView/CommentListView_model";
import { RevisionHistoryModel } from "../../revisionHistory/RevisionHistory_model";

interface IImpactGroupExtendedViewModelOptions {
  projectId: number;
  impactGroupId: number;
  organisationId: number;
  authUser: FP.Entities.IUser;
}
export class ImpactGroupExtendedViewModel extends BaseModel {
  projectProvider: IProjectsApi;
  impactGroupProvider: IImpactGroupsApi;
  projectId: number;
  impactGroupId: number;
  commentViewModel: CommentListViewModel;
  revisionHistoryModel: RevisionHistoryModel;
  @observable.ref impactGroup: FP.Entities.IImpactGroup;
  @observable impactGroupStakeholders;
  organisationId: number;
  queryStringService: IQueryStringService;

  constructor({ authUser, projectId, impactGroupId, organisationId }: IImpactGroupExtendedViewModelOptions) {
    super();
    makeObservable(this);
    this.projectProvider = ProjectsApi;
    this.impactGroupProvider = ImpactGroupsApi;
    this.projectId = projectId;
    this.impactGroupId = impactGroupId;
    this.organisationId = organisationId;
    this.commentViewModel = new CommentListViewModel(this.projectId, authUser, {
      placeholderText: I18n.t("placeholders.highLevelImpactNotePlaceholder"),
      searchAttribute: "impactGroupId",
      id: -1,
      title: null,
      projectId: this.projectId,
      organisationId
    });
    this.revisionHistoryModel = new RevisionHistoryModel({
      entityId: this.impactGroupId,
      projectId: this.projectId,
      historyType: "impact-groups",
      organisationId
    });

    this.queryStringService = QueryStringService;
  }

  @action.bound
  async loadImpactGroup() {
    const res = await this.impactGroupProvider.getDetailed(this.organisationId, this.projectId, this.impactGroupId);
    this.impactGroup = res.payload as FP.Entities.IImpactGroup;
    this.commentViewModel.setConfig({
      id: this.impactGroup.id,
      description: <p className="mb-0">{I18n.t("phrases.highLevelImpactCommentsDescription")}</p>
    });
  }
}
