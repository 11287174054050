import { SingleFormModel } from "../../../forms/singleFormModel/SingleForm_model";
import { getTimelineByAudienceFilterFields } from "./TimelineByAudienceFilter_fields";

export class TimelineByAudienceFilterModalModel {
  formModel: SingleFormModel;
  programmeId: number;
  audienceList: number;
  selectedTimelineAudiences: any;
  selectedContainsFilterKey: any;
  setSelectedTimelineAudiences: (s: any) => void;
  setContainsFilterKey: (s: any) => void;

  constructor(
    programmeId: number,
    audienceList: any,
    selectedTimelineAudiences: any,
    selectedContainsFilterKey: any,
    setSelectedTimelineAudiences: (s) => void,
    setContainsFilterKey: (s) => void
  ) {
    this.programmeId = programmeId;
    this.audienceList = audienceList;
    this.selectedTimelineAudiences = selectedTimelineAudiences;
    this.selectedContainsFilterKey = selectedContainsFilterKey;
    this.formModel = new SingleFormModel();
    this.setSelectedTimelineAudiences = setSelectedTimelineAudiences;
    this.setContainsFilterKey = setContainsFilterKey;
  }

  onMount = () => {
    this.formModel.formFields = getTimelineByAudienceFilterFields(
      this.audienceList,
      this.selectedTimelineAudiences,
      this.selectedContainsFilterKey,
      this.setSelectedTimelineAudiences,
      this.setContainsFilterKey
    );
  };
}
