import { action, makeObservable, observable } from "mobx";
import ProgressIndicatorModel, {
  ProgressIndicatorModel as IProgressIndicatorModel
} from "../../../../../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import I18n from "../../../../../../../localization/I18n";
import { IUiAction, UiActionRenderers } from "../../../../../../../uiAction/IUiAction";
import ImpactsApi, { ImpactsApi as IImpactsApi } from "../../../../../../../../services/api/v2/impacts/Impacts.api";
import { SingleFormModel } from "../../../../../../../../pages/change/forms/singleFormModel/SingleForm_model";
import ProjectProcessesApi, {
  ProjectProcessesApi as IProjectProcessesApi
} from "../../../../../../../../services/api/v2/projectProcesses/ProjectProcesses.api";
import { getImpactProcessFormFields } from "./ImpactProcessSidebar_fields";
import ModalContext from "../../../../../../../modalZ/context/ModalContext";
import { BaseModel } from "../../../../../../../util/BaseModel";

export class ImpactProcessSidebarModel extends BaseModel {
  @observable.ref formModel: SingleFormModel;
  @observable.ref projectProcesses: FP.Entities.IProjectProcess[] = [];
  @observable isLoading: boolean = true;
  @observable.ref impact: FP.Entities.IImpact;

  organisationId: number;
  projectId: number;
  projectProcessesProvider: IProjectProcessesApi;
  impactsProvider: IImpactsApi;
  processCardProcesses: IUiAction<FP.Entities.IProjectProcess>[] = [];
  httpProgress: IProgressIndicatorModel;
  closeModalFn: () => void;

  /**
   *
   */
  constructor(closeModalFn: () => void, organisationId: number, projectId: number, impactId: number) {
    super();
    makeObservable(this);
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.projectProcessesProvider = ProjectProcessesApi;
    this.impactsProvider = ImpactsApi;
    this.httpProgress = ProgressIndicatorModel;
    this.loadInitialData(impactId);
    this.closeModalFn = closeModalFn;
    this.initForm();
  }

  loadInitialData = async (impactId: number) => {
    await this.loadImpact(impactId);
    await this.loadProjectProcesses(impactId);
    this.setIsLoading(false);
  };

  initForm = () => {
    this.formModel = new SingleFormModel();
    this.formModel.setActions([
      {
        id: "action1",
        label: I18n.t("phrases.done"),
        onAction: ev => {
          ModalContext.hide();
        },
        componentProps: {
          className: "ml-auto"
        },
        rendersIn: UiActionRenderers.BUTTON
      }
    ]);
  };

  @action
  setFormModel = () => {
    this.formModel.formFields = getImpactProcessFormFields(
      this.projectProcessesProvider,
      this.impactsProvider,
      this.organisationId,
      this.projectId,
      this.impact,
      this.loadProjectProcesses,
      this.projectProcesses,
      this.httpProgress
    );
  };

  @action
  loadImpact = async (impactId: number) => {
    let res = await this.impactsProvider.getDetailedById(this.organisationId, this.projectId, impactId);
    if (!res || res.isError) return;

    this.setImpact(res.payload);
    this.setFormModel();
  };

  @action
  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };

  @action
  setImpact = (impact: FP.Entities.IImpact) => {
    this.impact = impact;
  };

  @action
  loadProjectProcesses = async (impactId: number) => {
    let res = await this.impactsProvider.getProjectImpactProcesses(this.organisationId, this.projectId, impactId);
    if (!res || res.isError) return;
    this.setProjectProcesses(res.payload);
    this.setFormModel();
  };

  @action
  setProjectProcesses = (projectProcesses: FP.Entities.IProjectProcess[]) => {
    this.projectProcesses = projectProcesses;
    this.isLoading = false;
  };

  removeProjectProcess = async (impactId: number, projectProcessId: number) => {
    this.httpProgress.showTopProgressBarVisible();
    const res = await this.impactsProvider.removeImpactProjectProcess(
      this.organisationId,
      this.projectId,
      impactId,
      projectProcessId
    );
    this.httpProgress.hideTopProgressBarVisible();

    if (!res || res.isError) return;

    return res.payload;
  };
}
