import { PanelTypes } from "@flightpath/coreui/dist/ui/Panel";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { Panel } from "../../../../../../../../components/ui/Panel";
import { PositionedSpinner } from "../../../../../../../../components/ui/PositionedSpinner";
import { Tag } from "../../../../../../../../components/ui/Tag";
import PermissionsContext from "../../../../../../../../contexts/permissions/PermissionsContext";
import { PermissionFields } from "../../../../../../../../contexts/permissions/PermissionsTypes";
import I18n from "../../../../../../../../core/localization/I18n";
import { useCurrentOrganisationId } from "../../../../../../../../services/local/organisationContext/OrganisationContextModel";
import { ManageStakeholderTagForm } from "./ManageStakeholderTagForm";
import { StakeholderTagsSidebarModel } from "./StakeholderTagsSidebar_model";

export interface StakeholderTagsSidebarProps {
  projectId: number;
  stakeholderId: number;
}

export const StakeholderTagsSidebar: React.FC<StakeholderTagsSidebarProps> = observer(props => {
  const { projectId, stakeholderId } = props;
  const organisationId = useCurrentOrganisationId();
  const [model, setModel] = useState(() => new StakeholderTagsSidebarModel(organisationId, projectId, stakeholderId));

  useEffect(() => {
    if (model.projectId !== projectId) {
      setModel(new StakeholderTagsSidebarModel(organisationId, projectId, stakeholderId));
    }
  }, [organisationId, projectId, stakeholderId, model.projectId]);

  useEffect(() => {
    model.onMount();
  }, [model]);

  if (!model || model.isLoading) return <PositionedSpinner />;

  const canEdit = PermissionsContext.canEditField(PermissionFields.STAKEHOLDERS, organisationId, projectId);
  const { projectStakeholder } = model;

  return (
    <div className="container-fluid">
      {canEdit ? (
        <div className="row mb-4">
          <div className="col">
            <ManageStakeholderTagForm
              projectId={projectId}
              organisationId={organisationId}
              projectStakeholder={model.projectStakeholder}
            />
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col">
            {projectStakeholder.tags.length > 0 ? (
              projectStakeholder.tags.map(t => (
                <Tag
                  tagText={t.text}
                  isEditable={false}
                  testId={t.id + ""}
                  key={t.id}
                  isNew={false}
                  onClick={e => {
                    model.removeTag(t.id);
                  }}
                />
              ))
            ) : (
              <div>
                <Panel.Panel hasBorderRadius={true} type={PanelTypes.OUTLINES} className="py-2 text-center">
                  <div>{I18n.t("phrases.noTags")}</div>
                </Panel.Panel>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
});
