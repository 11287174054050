import { ButtonTypes } from "@flightpath/coreui/dist/ui/Button";
import { UiActionRenderers } from "@flightpath/coreui/dist/uiAction/IUiAction";
import { action, makeObservable, observable } from "mobx";
import ProgressIndicatorModel, {
  ProgressIndicatorModel as IProgressIndicatorModel
} from "../../../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import I18n from "../../../../../../core/localization/I18n";
import ModalContext from "../../../../../../core/modalZ/context/ModalContext";
import ToasterService, { ToasterService as IToasterService } from "../../../../../../core/toaster/ToasterService";
import { TOASTER_TOAST_TIME } from "../../../../../../core/toaster/Toaster_model";
import { BaseModel } from "../../../../../../core/util/BaseModel";
import ImpactsApi, { ImpactsApi as IImpactsApi } from "../../../../../../services/api/v2/impacts/Impacts.api";
import ProjectTeamUserPermissionsApi, {
  ProjectTeamUserPermissionsApi as IProjectTeamUserPermissionsApi
} from "../../../../../../services/api/v2/projectTeamUserPermissions/ProjectTeamUserPermissions.api";
import { SingleFormModel } from "../../../../../change/forms/singleFormModel/SingleForm_model";
import { getBulkUpdateImpactsFormFields } from "./impactBulkUpdateSidebar_formFields";

export class ImpactBulkUpdateSidebarModel extends BaseModel {
  impactsProvider: IImpactsApi;
  @observable isLoading: boolean = true;
  @observable.ref projectTeamMembers: any[];
  projectTeamUserPermissionsProvider: IProjectTeamUserPermissionsApi;
  httpProgress: IProgressIndicatorModel;
  toasterService: IToasterService;
  projectId: number;
  organisationId: number;
  selectedImpacts: number[];
  closeModalFn: () => void;
  formModel: SingleFormModel;

  constructor(closeModalFn: () => void, projectId: number, organisationId: number, selectedImpacts: number[]) {
    super();
    makeObservable(this);

    this.organisationId = organisationId;
    this.projectId = projectId;
    this.selectedImpacts = selectedImpacts;
    this.impactsProvider = ImpactsApi;
    this.httpProgress = ProgressIndicatorModel;
    this.isLoading = true;
    this.toasterService = ToasterService;
    this.projectTeamUserPermissionsProvider = ProjectTeamUserPermissionsApi;
    this.closeModalFn = closeModalFn;
    this.initForm();
  }

  initForm = async () => {
    await this.loadProjectTeamMembers();
    this.formModel = new SingleFormModel();
    this.formModel.setActions([
      {
        id: "cancel",
        label: I18n.t("phrases.cancel"),
        onAction: ev => {
          ModalContext.hide();
        },
        componentProps: {
          className: "ml-auto"
        },
        rendersIn: UiActionRenderers.BUTTON
      },
      {
        id: "Save",
        label: I18n.t("phrases.save"),
        onAction: async () => {
          let formResult = await this.formModel.submit();

          let filteredFormRes = {};
          Object.entries(formResult).forEach(([key, val]) => {
            if (!!val && val !== "") {
              filteredFormRes[key] = val;
            } else {
              filteredFormRes[key] = null;
            }
          });

          if (formResult) {
            this.httpProgress.showOverlay();
            let res = await this.impactsProvider.bulkUpdateImpacts(
              this.organisationId,
              this.projectId,
              this.selectedImpacts,
              filteredFormRes as any
            );
            this.httpProgress.hideOverlay();

            if (!res || res.isError) {
              this.toasterService
                .showErrorToast(TOASTER_TOAST_TIME.SLOW)
                .setContent(<p>{I18n.t("errors.updateImpacts")}</p>);
              return;
            }

            ModalContext.hide();
            this.toasterService
              .showSuccessToast(TOASTER_TOAST_TIME.NORMAL)
              .setContent(<p>{`${I18n.t("phrases.impactsUpdatedSuccessfully")}`}</p>);
          }
        },
        rendersIn: UiActionRenderers.BUTTON,
        componentProps: {
          type: ButtonTypes.PRIMARY,
          className: "ml-2"
        }
      }
    ]);

    this.setFormModel();
    this.setIsLoading(false);
  };

  @action
  setProjectTeamMembers = projTeamMembers => {
    this.projectTeamMembers = projTeamMembers;
  };

  @action
  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };

  @action
  setFormModel = () => {
    this.formModel.formFields = getBulkUpdateImpactsFormFields(
      this.impactsProvider,
      this.projectId,
      this.projectTeamMembers
    );
  };

  @action
  loadProjectTeamMembers = async () => {
    const res = await this.projectTeamUserPermissionsProvider.getAllUsersSimple(this.organisationId, this.projectId);
    if (!res || res.isError) return;
    this.setProjectTeamMembers(res.payload);
  };
}
