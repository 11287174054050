import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import { useStores } from "../../../../../../../../apps/insight/stores/RootStore";
import { Button } from "../../../../../../../../components/ui/Button";
import I18n from "../../../../../../../localization/I18n";

export const StakeholderEmails: React.FC<{ itemIds: number[]; projectId: number }> = observer(({ itemIds }) => {
  const { projectStakeholderStore } = useStores();
  const emails = useMemo(() => {
    return projectStakeholderStore.data.filter(item => itemIds.includes(item.id)).map(e => e.email);
  }, [itemIds, projectStakeholderStore.data]);

  const emailList = emails.join(",");

  return (
    <div className="stakeholder-email container-fluid py-6">
      <div className="row">
        <div className="col">
          <h1 className="mb-1">Email Addresses</h1>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <ul className="my-0 mb-3">
            {emails?.length > 0
              ? emails.map((email, i) => {
                  return (
                    <li className="stakeholder-email__list" key={i}>
                      <p className="mb-0">{email}</p>
                    </li>
                  );
                })
              : I18n.t("phrases.actionStakeholders_noEmailAddresses")}
          </ul>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <Button
            onClick={() => {
              navigator.clipboard.writeText(emailList);
            }}
            className="mr-2"
          >
            {I18n.t("phrases.copyToClipboard")}
          </Button>
        </div>
      </div>
    </div>
  );
});
