import { observer } from "mobx-react";
import React from "react";
import { WrapperProjectStakeholderGridWithDataLoader } from "../../../../../core/grids/builder/directorBuilder/grids/ProjectStakeholdersGrid/WrapperProjectStakeholdersGridWithDataLoader_view";

export const StakeholdersView: React.FC = observer(() => {
  return (
    <div className="stakeholders-view insight-tab-content__space-filler">
      <WrapperProjectStakeholderGridWithDataLoader gridToolbarType="base" />
    </div>
  );
});
