import React from "react";
import { CircleStatus, CircleStatusColours } from "../../ui/CircleStatus";
import { Enums } from "../../../enums";

interface TargetLabelProps {
  current: number;
  text: "sentiment" | "commitment" | "receptiveness";
}

const TargetLabel: React.FunctionComponent<TargetLabelProps> = props => {
  const { current, text } = props;
  const getLabel = () => {
    switch (text) {
      case "sentiment":
        return Enums.Translator.SentimentLevel(current);
      case "commitment":
        return Enums.Translator.CommitmentLevel(current);
      case "receptiveness":
        return Enums.Translator.ReceptivenessLevel(current);
    }
  };
  const getColour = () => {
    const value = current;
    if (value <= 0 || typeof value === "undefined") {
      return CircleStatusColours.GREY;
    } else if (value > 0 && value <= 4) {
      return CircleStatusColours.RED;
    } else if (value <= 7) {
      return CircleStatusColours.BLUE;
    } else {
      return CircleStatusColours.GREEN;
    }
  };

  return (
    <>
      <CircleStatus colour={getColour()} className="mr-2" />
      {getLabel()}
    </>
  );
};

export { TargetLabel };
