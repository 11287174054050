import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { SingleForm } from "../../../../../../change/forms/singleFormModel/SingleForm_view";
import { ProcessImpactGroupsSidebarModel } from "./ProcessImpactGroupsSidebar_model";
import { PositionedSpinner } from "../../../../../../../components/ui/PositionedSpinner";
import { useParams } from "react-router-dom";

interface ProcessImpactGroupsSidebarProps {
  projectProcessId: number;
}

export const ProcessImpactGroupsSidebar: React.FC<ProcessImpactGroupsSidebarProps> = observer(
  ({ projectProcessId }) => {
    const { organisationId, projectId } = useParams();
    const [model] = useState(() => new ProcessImpactGroupsSidebarModel(+organisationId, +projectId, projectProcessId));

    useEffect(() => {
      model.onMount();
      return model.onUnmount;
    }, [model]);

    if (model.isLoading) return <PositionedSpinner />;

    return (
      <div className="container-fluid">
        <SingleForm model={model.formModel} />
      </div>
    );
  }
);
