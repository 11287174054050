import { isEmpty } from "lodash";
import { observer } from "mobx-react";
import React from "react";
import { HeadtailPercentageChange } from "../../headtailPercentageChange/headTailPercentageChange";
import { MetricCard, MetricCardProps } from "../MetricCard";

export interface IMetricCardWithPercentageChangeHeadtailProps {
  model?: MetricCardProps;
  tooltip?: string;
}

const arrayIsEmpty = (items: any[]): boolean => isEmpty(items) || items.length === 0;

const MetricCardWithPercentageChangeHeadtail: React.FunctionComponent<IMetricCardWithPercentageChangeHeadtailProps> =
  observer(props => {
    const { model, tooltip } = props;
    const pointInTimeValue = (props.model as any).pointInTimeValue

    if (arrayIsEmpty(model.items)) {
      return null;
    }
    model.headtail = <HeadtailPercentageChange newTotal={model.value} oldTotal={pointInTimeValue || 0} />;
    model.hint = tooltip;

    return <MetricCard {...model} />;
  });

export { MetricCardWithPercentageChangeHeadtail };
