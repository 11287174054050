import { GridBaseModel } from "../common/GridBaseModel";
import { HubEvents } from "../common/HubEventsEnum";

export class ProjectStakeholdersHub extends GridBaseModel {
  hubName: string = "project-stakeholders";

  constructor() {
    super("project-stakeholders");
  }

  onLoadData = callback => this.registerIncomingEvent(HubEvents.ON_LOAD_PROJECT_STAKEHOLDER_DATA, callback);

  invokeLoadData = async (organisationId: number, projectId: number) => {
    if (!this.isConnectionStarted) return;
    await this.connection.invoke(HubEvents.INVOKE_LOAD_PROJECT_STAKEHOLDER_DATA, {
      organisationId: organisationId,
      projectId: projectId
    });
  };
}

const instance = new ProjectStakeholdersHub();
export default instance;
