import {
  SkinnyDoughnutChart,
  SkinnyDoughnutChartProps as SDCP,
  SkinnyDoughnutChartDataItem as SDCDI,
  SkinnyDoughnutChartTotalItem as SDCTI
} from "@flightpath/coreui/dist/widgets/skinnyDoughnutChart";
import React from "react";
import { Panel } from "../../ui/Panel";
import I18n from "../../../core/localization/I18n";

export interface SkinnyDoughnutChartProps extends SDCP { }
export { SkinnyDoughnutChart };
export interface SkinnyDoughnutChartDataItem extends SDCDI { }
export interface SkinnyDoughnutChartTotalItem extends SDCTI { }

export const SkinnyDonutStakeholderTooltip = props => {
  const { activeEntry, activeIndex } = props;
  return <Panel.Panel
    background={Panel.PanelBackgrounds.BG_WHITE}
    hasShadow={true}
    style={{ width: "200px" }}
    hasBorderRadius={true}
    className={`p-3 z-index-high skinny-doughnut-chart__tooltip ${activeIndex === -1 ? 'skinny-doughnut-chart__tooltip-hidden' : ''} `}
  >
    <h3 className="mb-2">
      {activeEntry.name ? activeEntry.name : ""}
      <span className="float-right">
        {activeEntry.tooltip ? activeEntry.tooltip[0].value + activeEntry.tooltip[1].value : 0}
      </span>
    </h3>
    {activeEntry.tooltip ?
      activeEntry.tooltip.map(group => {
        return (
          <div key={`${group.key}-${group.value}`}>
            <small>{group.key}</small>
            <small className="float-right pt-1">{group.value}</small>
          </div>
        )
      }) : 0
    }
  </Panel.Panel >
}

export const SkinnyDonutActionProgressTooltip = props => {
  const { activeEntry, activeIndex } = props;

  return <Panel.Panel
    background={Panel.PanelBackgrounds.BG_WHITE}
    hasShadow={true}
    style={{ width: "200px" }}
    hasBorderRadius={true}
    className={`p-3 z-index-high skinny-doughnut-chart__tooltip ${activeIndex === -1 ? 'skinny-doughnut-chart__tooltip-hidden' : ''} `}

  >
    <h3 className="mb-0">{activeEntry.name ? activeEntry.name : ""}
      <small className="float-right pt-1">
        {activeEntry.tooltip ? activeEntry.tooltip[0].value : 0}
      </small></h3>
  </Panel.Panel>
}

export const SkinnyDonutImpactsToolTip: React.FC<any> = props => {
  const { activeEntry, activeIndex } = props;

  return (
    <Panel.Panel
      background={Panel.PanelBackgrounds.BG_WHITE}
      hasShadow={true}
      style={{ width: "200px" }}
      hasBorderRadius={true}
      className={`p-3 z-index-high skinny-doughnut-chart__tooltip ${activeIndex === -1 ? 'skinny-doughnut-chart__tooltip-hidden' : ''} `}
    >
      <h3 className="mb-1">{activeEntry.name ? activeEntry.name : ""}</h3>
      <div>
        <p className="mb-1">
          <span className="body-bold">{I18n.t("visualisations.impact_Total")}</span>
          <span className="float-right">{activeEntry.tooltip ? activeEntry.tooltip[0].value : 0}</span>
        </p>
      </div>
      {activeEntry.tooltip ? activeEntry.tooltip.map((e, i) => {
        if (i === 0) return null;
        return (
          <div key={e.key}>
            <small>{e.key}</small>
            <small className="float-right pt-1">{e.value}</small>
          </div>
        );
      }) : 0}
    </Panel.Panel>
  );
};