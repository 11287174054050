import { HubEvents } from "../common/HubEventsEnum";
import { GridBaseModel, IGridBaseModel } from "../common/GridBaseModel";

export class ActionsHub extends GridBaseModel implements IGridBaseModel {
  hubName: string = "actions";

  constructor() {
    super("actions");
  }

  onLoadData = callback => this.registerIncomingEvent(HubEvents.ON_LOAD_ACTION_DATA, callback);

  invokeLoadData = async (organisationId: number, projectId: number) => {
    if (!this.isConnectionStarted) return;
    await this.connection.invoke(HubEvents.INVOKE_LOAD_ACTION_DATA, {
      organisationId: organisationId,
      projectId: projectId
    });
  };
}

const instance = new ActionsHub();
export default instance;
