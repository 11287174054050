import { StakeholderType } from "../../../../../../../../enums";
import I18n from "../../../../../../../localization/I18n";
import ModalContext from "../../../../../../../modalZ/context/ModalContext";
import { GridSideModalTitle } from "../../../../utils/GridModalUtils";
import { ImpactStakeholdersSidebarModel } from "./ImpactStakeholdersSidebar_model";
import { ImpactStakeholdersSidebar } from "./impactStakeholdersSidebar_view";

export const IMPACT_STAKEHOLDERS_SIDEBAR_MODAL_CONFIG = (
  organisationId: number,
  projectId: number,
  impact: FP.Entities.IImpact
) => {
  return {
    showClose: true,
    title: <GridSideModalTitle name={impact.name} field={I18n.t("phrases.stakeholders")} />,
    content: (
      <ImpactStakeholdersSidebar
        model={
          new ImpactStakeholdersSidebarModel(
            ModalContext.hide,
            organisationId,
            projectId,
            impact.id,
            StakeholderType.INDIVIDUAL
          )
        }
      />
    )
  };
};
