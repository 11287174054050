import { FORM_COL } from "../../../../../constants";
import {
  ISearchTreePickerModel,
  ITreeDataItem
} from "../../../../forms/controls/searchTreePicker/ISearchTreePickerModel";
import { INIT_SEARCHTREEPICKER } from "../../../../forms/controls/searchTreePicker/SearchTreePicker_model";
import { generateFormFieldsFromJson } from "../../../../forms/helpers/FormFieldMappers";
import I18n from "../../../../localization/I18n";

export const getImpactTypesSidebarFormFields = (impactTypesData: ITreeDataItem[], currentIds: number[]) => {
  if (currentIds) {
    impactTypesData.forEach(e => {
      if (currentIds.indexOf(e.id) >= 0) {
        e.isChecked = true;
      }
    });
  } else {
    impactTypesData.forEach(e => {
      if (e.parent === 0) {
        e.isChecked = true;
      }
    });
  }
  const impactTypes: Partial<ISearchTreePickerModel> = {
    ...INIT_SEARCHTREEPICKER,
    key: "impactTypes",
    value: impactTypesData.filter(e => e.isChecked === true),
    options: impactTypesData,
    isCascading: false,
    placeholder: I18n.t("placeholders.searchImpactTypes"),
    fieldClassName: FORM_COL.FULL_WIDTH
  };

  let fields = [];
  fields.push(impactTypes);

  return generateFormFieldsFromJson(fields);
};
