import React, { useEffect, useState } from "react";
import { UnassignedStakeholdersModel } from "./UnassignedStakeholders_model";
import { useCurrentOrganisationId } from "../../../../../services/local/organisationContext/OrganisationContextModel";
import { IF } from "../../../../../components/hoc/If";
import { GridView } from "../../../../../core/grids/GridView_view";
import { UnassignedStakeholdersGridModel } from "./UnassignedStakeholdersGrid_model";
import { observer } from "mobx-react";
import { Button, ButtonTypes, LinkButton } from "../../../../../components/ui/Button";
import { Enums, StakeholderType, UiSizes } from "../../../../../enums";
import { UnassignedAudiencesGridModel } from "./UnassignedAudiencesGrid_model";
import Pages from "../../../../../routes/InsightRoutes";
import ModalContext from "../../../../../core/modalZ/context/ModalContext";
import { PositionedSpinner } from "@flightpath/coreui/dist/ui/PositionedSpinner";
import { ButtonIcon, ButtonIconShapes } from "@flightpath/coreui/dist/ui/Button";
import { IconSymbols } from "@flightpath/coreui/dist/generated/IconSymbols";
import { Input } from "@flightpath/coreui/dist/ui/_forms/Input";
import I18n from "../../../../../core/localization/I18n";

export const UnassignedStakeholdersGrid: React.FC<{
  projectId: number;
  stakeholderType: StakeholderType;
  onAssignSuccess: () => void;
}> = observer(({ projectId, onAssignSuccess, stakeholderType }) => {
  const organisationId = useCurrentOrganisationId();

  const [model] = useState(() => new UnassignedStakeholdersModel(organisationId, +projectId, stakeholderType));
  const [gridModel] = useState(() =>
    stakeholderType === StakeholderType.INDIVIDUAL
      ? new UnassignedStakeholdersGridModel(organisationId)
      : new UnassignedAudiencesGridModel(organisationId)
  );

  useEffect(() => {
    model.onMount();
    return model.onUnmount;
  }, [model]);

  if (model.isLoading) return <PositionedSpinner />;
  return (
    <div className="unassigned-stakeholder-grid">
      <div className="container-fluid py-3">
        <div className="row">
          <div className="col-5">
            <Button
              type={ButtonTypes.OUTLINE_PRIMARY}
              size={UiSizes.SM}
              isDisabled={model.selectedStakeholders.length === 0}
              className="mt-2 mr-2 align-self-start"
              onClick={async () => {
                let res = await model.assignStakeholders();
                if (res) {
                  onAssignSuccess();
                }
              }}
            >
              Assign
            </Button>
          </div>
          <div className="col-7 d-flex justify-content-end pr-2">
            <LinkButton
              type={ButtonTypes.OUTLINE_PRIMARY}
              size={UiSizes.SM}
              className="mt-2 mr-2 align-self-start"
              onClick={() => ModalContext.hide()}
              href={Pages.organisations.coreData.stakeholders.generateLink(organisationId)}
            >
              {I18n.t("phrases.viewPeople")}
            </LinkButton>
            <Input
              autoFocus={true}
              className="mt-2"
              size={UiSizes.XS}
              onChange={model.setSearchText}
              placeholder="Search"
              icon={IconSymbols.Search}
              iconSize={Enums.UiSizes.SM}
              iconClass="mt-1"
              style={{ height: 32 }}
            />
            <ButtonIcon
              size={UiSizes.SM}
              className="mt-2 ml-2"
              onClick={ModalContext.hide}
              type={ButtonTypes.OUTLINE_PRIMARY}
              shape={ButtonIconShapes.RECTANGLE}
              symbol={IconSymbols.Close}
            />
          </div>
        </div>
      </div>
      <IF condition={!model.isLoading}>
        <GridView
          data={model.stakeholders}
          onSelectionChanged={model.updateSelectedStakeholders}
          model={gridModel}
          isLoading={gridModel.isLoading}
          onCellClicked={function (cell: string, isEditMode?: boolean): void {}}
          onCellEditModeChange={function (cell: string, isEditMode: boolean): void {}}
          users={[]}
          overlayNoRowsTemplate={null}
          onGridReady={model.onGridReady}
        />
      </IF>
    </div>
  );
});
