import I18n from "../../../../../../../localization/I18n";
import { GridSideModalTitle } from "../../../../utils/GridModalUtils";
import { ProjectStakeholderTagsSidebar } from "./ProjectStakeholderTagsSidebar_view";

export const PROJECT_STAKEHOLDER_TAGS_SIDEBAR_MODAL_CONFIG = (
  organisationId: number,
  projectId: number,
  item: FP.Entities.IProjectStakeholderSummary
) => {
  return {
    showClose: true,
    title: <GridSideModalTitle name={`${item.name}`} field={I18n.t("phrases.tags")} />,
    content: <ProjectStakeholderTagsSidebar projectId={projectId} itemId={item.id} />
  };
};
