import { ButtonTypes } from "../../../../../components/ui/Button";
import I18n from "../../../../../core/localization/I18n";
import { IModalContextModel } from "../../../../../core/modalZ/context/IModalContext";
import ModalContext from "../../../../../core/modalZ/context/ModalContext";
import { UiActionRenderers } from "../../../../../core/uiAction/IUiAction";
import { BaseModel } from "../../../../../core/util/BaseModel";
import { IFlightPathApiResponse } from "../../../../../services/api/v2/BaseApiModel";
import MilestonesApi, {
  MilestonesApi as IMilestonesApi
} from "../../../../../services/api/v2/milestones/Milestones.api";
import { getFormFields } from "../../../forms/milestone/MilestoneFormSection_data";
import { SingleFormModel } from "../../../forms/singleFormModel/SingleForm_model";

export class MilestoneModalModel extends BaseModel {
  formModel: SingleFormModel;
  modalService: IModalContextModel;
  milestoneProvider: IMilestonesApi;
  milestone: FP.Entities.IMilestone;
  programmeId: number;
  orgId: number;

  constructor(
    orgId: number,
    programmeId: number,
    loadData: (id: number) => void,
    milestone?: FP.Entities.IMilestone,
    closeFn?: Function
  ) {
    super();
    this.orgId = orgId;
    this.programmeId = programmeId;
    this.modalService = ModalContext;
    this.milestoneProvider = MilestonesApi;
    this.milestone = milestone;

    this.formModel = new SingleFormModel();
    this.formModel.formFields = getFormFields(this.milestone);
    this.formModel.actions = [
      {
        id: "no",
        label: I18n.t("phrases.cancel"),
        onAction: () => {
          this.modalService.hide();
        },
        rendersIn: UiActionRenderers.BUTTON,
        componentProps: {
          type: ButtonTypes.LINK,
          className: "ml-auto"
        }
      },
      {
        id: "CreateMilestoneButton",
        label: I18n.t("phrases.save"),
        onAction: async () => {
          let milestoneFormEl = await this.formModel.submit();
          if (milestoneFormEl) {
            let formResult = {
              ...milestoneFormEl
            };

            formResult.programmeId = this.programmeId;

            let res: IFlightPathApiResponse<FP.Entities.IMilestone>;
            if (this.milestone) {
              res = await this.milestoneProvider.updateProgrammeMilestone(
                this.orgId,
                this.programmeId,
                this.milestone.id,
                formResult as FP.Entities.IMilestone
              );
            } else {
              res = await this.milestoneProvider.createProgrammeMilestone(
                this.orgId,
                this.programmeId,
                formResult as FP.Entities.IMilestone
              );
            }

            if (res) {
              closeFn && closeFn();
              this.modalService.hide();
              loadData(this.programmeId);
            }
          }
        },
        rendersIn: UiActionRenderers.BUTTON,
        componentProps: {
          type: ButtonTypes.PRIMARY,
          className: "ml-2"
        }
      }
    ];
  }

  onMount = () => {};

  onUnmount = () => {};
}
