import * as React from "react";
import { ITextFieldModel } from "../../../../core/forms/controls/textField/ITextFieldModel";
import { INIT_TEXT_FIELD } from "../../../../core/forms/controls/textField/TextField_init";
import { generateFormFieldsFromJson } from "../../../../core/forms/helpers/FormFieldMappers";
import { TextFieldModel } from "../../../../core/forms/controls/textField/TextField_model";
import { ErrorMessage } from "../../../../components/ui/ErrorMessage";
import { Validations } from "../../../../core/forms/helpers/Validations";
import { IAutocompleteModel } from "../../../../core/forms/controls/autocomplete/IAutocompleteModel";
import { INIT_AUTOCOMPLETE } from "../../../../core/forms/controls/autocomplete/Autocomplete_init";
import { AutocompleteOption } from "../../../../components/ui/_forms/Autocomplete/AutocompleteOption";
import I18n from "../../../../core/localization/I18n";
import _ from "lodash";
import { FORM_COL } from "../../../../constants";
import { IconSymbols } from "../../../../components/ui/Icon";
import { OrganisationsApi } from "../../../../services/api/v2/organisations/Organisations.api";

export const getRoleFormFields = (
  org: FP.Entities.IOrganisation,
  orgProvider: OrganisationsApi,
  role?: FP.Entities.IRole
) => {
  let orgId: Partial<ITextFieldModel> = {
    ...INIT_TEXT_FIELD,
    key: "organisationId",
    inputType: "hidden",
    value: org && org.id + "",
    defaultValue: org && org.id + ""
  };

  const name: Partial<ITextFieldModel> = {
    ...INIT_TEXT_FIELD,
    key: "name",
    placeholder: I18n.t("placeholders.roleName"),
    label: <label htmlFor="name">{I18n.t("forms.roleName")} *</label>,
    fieldClassName: FORM_COL.FULL_WIDTH,
    validate: function () {
      let self: TextFieldModel = this;
      let res = true;
      if (Validations.IS_EMPTY(self.value)) {
        self.errorMessage = <ErrorMessage key="3">{I18n.t("validations.roleName")}</ErrorMessage>;
        res = false;
      }
      return res;
    },
    value: role && role.name
  };

  let businessAreas: Partial<IAutocompleteModel> = {
    ...INIT_AUTOCOMPLETE,
    key: "businessAreaId",
    label: <label htmlFor={"businessArea"}>{I18n.t("forms.businessArea")} *</label>,
    placeholder: I18n.t("placeholders.searchBusinessArea"),
    optionElement: (
      <AutocompleteOption
        key={"e"}
        className={"autocomplete__chip"}
        label={e => {
          return e.nameWithParentName;
        }}
      />
    ),
    validate: function () {
      let self: IAutocompleteModel = this;
      let res = true;
      if (Validations.IS_EMPTY(self.value as any)) {
        self.errorMessage = <ErrorMessage key="3">{I18n.t("validations.businessArea")}</ErrorMessage>;
        res = false;
      }
      return res;
    },
    onFocus: async function () {
      let self: IAutocompleteModel = this;
      this.componentProps.disabled = false;
      const res = await orgProvider.getBusinessAreas(org.id);

      if (res?.payload) {
        const sortedBusinessAreas = _.orderBy(res.payload, [businessArea => businessArea.name.toLowerCase()]);
        self.setOptions(sortedBusinessAreas);
      }
    },
    componentProps: {
      className: "form-control",
      icon: IconSymbols.Search
    },
    searchAttribute: "name",
    fieldClassName: FORM_COL.FULL_WIDTH,
    extractValue: function () {
      return this.value && this.value.id;
    },
    value: role && (role.businessArea as any)
  };

  const fields = [];

  if (role) {
    let roleId = {
      ...INIT_TEXT_FIELD,
      key: "id",
      inputType: "hidden",
      value: role && role.id + "",
      defaultValue: role && role.id + ""
    };
    fields.push(roleId);
  }

  fields.push(orgId);
  fields.push(name);
  fields.push(businessAreas);

  const models = generateFormFieldsFromJson(fields);

  return models;
};
