import { action, makeObservable, observable } from "mobx";

import PermissionsContext from "../../../../../../../contexts/permissions/PermissionsContext";
import { PermissionFields } from "../../../../../../../contexts/permissions/PermissionsTypes";
import I18n from "../../../../../../../core/localization/I18n";
import { UiActionRenderers } from "../../../../../../../core/uiAction/IUiAction";
import { BaseModel } from "../../../../../../../core/util/BaseModel";
import { SingleFormModel } from "../../../../../../change/forms/singleFormModel/SingleForm_model";
import { getProjectProcessRolesSidebarFormFields } from "./ProjectProcessRolesSidebar_formFields";
import ProjectProcessRolesApi, {
  ProjectProcessRolesApi as IProjectProcessRolesApi
} from "../../../../../../../services/api/v2/projectProcessRoles/ProjectProcessReoles.api";
import ProjectJobRolesApi, {
  ProjectJobRolesApi as IProjectJobRolesApi
} from "../../../../../../../services/api/v2/projectJobRoles/ProjectJobRoles.api";

export class ProjectProcessRolesSidebarModel extends BaseModel {
  projectProcessRoleProvider: IProjectProcessRolesApi;
  projectJobRolesProvider: IProjectJobRolesApi;
  @observable isLoading: boolean = true;
  @observable.ref projectProcessJobRoleProcessRoles: FP.Entities.IProjectProcessRole[];
  @observable.ref projectProcessJobRole: FP.Entities.IProjectJobRoleSummary;
  organisationId: number;
  formModel: SingleFormModel;
  projectId: number;
  closeModalFn: () => void;

  constructor(
    closeModalFn: () => void,
    organisationId: number,
    projectId: number,
    processJobRole: FP.Entities.IProjectJobRoleSummary
  ) {
    super();
    makeObservable(this);
    this.projectJobRolesProvider = ProjectJobRolesApi;
    this.projectProcessRoleProvider = ProjectProcessRolesApi;
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.projectProcessJobRole = processJobRole;
    this.loadProjectProcessJobRoleProcessRoles(processJobRole.id);
    this.closeModalFn = closeModalFn;
  }

  initForm = () => {
    this.formModel = new SingleFormModel();
    this.formModel.setActions([
      {
        id: "action1",
        label: I18n.t("phrases.done"),
        onAction: ev => this.closeModalFn(),
        componentProps: {
          className: "ml-auto"
        },
        rendersIn: UiActionRenderers.BUTTON
      }
    ]);
  };

  @action
  setProjectProcessJobRoleProcessRoles = (projectProcessJobRoleProcessRoles: FP.Entities.IProjectProcessRole[]) => {
    this.projectProcessJobRoleProcessRoles = projectProcessJobRoleProcessRoles;
    this.isLoading = false;
  };

  @action
  loadProjectProcessJobRoleProcessRoles = async (processJobRoleId: number) => {
    this.isLoading = true;
    let res = await this.projectJobRolesProvider.getProjectProcessRoles(
      this.organisationId,
      this.projectId,
      processJobRoleId
    );

    if (!res || res.isError) return;
    this.setProjectProcessJobRoleProcessRoles(res.payload);
    this.setFormModel();
  };

  @action
  setFormModel = () => {
    const canEdit = PermissionsContext.canEditField(PermissionFields.PROJECTS, this.organisationId, this.projectId);
    this.initForm();
    this.formModel.formFields = getProjectProcessRolesSidebarFormFields(
      this.projectJobRolesProvider,
      canEdit,
      this.organisationId,
      this.projectId,
      this.projectProcessJobRole,
      this.projectProcessJobRoleProcessRoles,
      this.loadProjectProcessJobRoleProcessRoles
    );
  };
}
