import { PositionedSpinner } from "@flightpath/coreui/dist/ui/PositionedSpinner";
import { observer } from "mobx-react";
import React from "react";
import { ProjectStakeholderBulkUpdateSidebarModel } from "./ProjectStakeholderBulkUpdateSidebar_model";
import { SingleForm } from "../../../../../../../../pages/change/forms/singleFormModel/SingleForm_view";

interface ProjectStakeholderBulkUpdateSidebarProps {
  model: ProjectStakeholderBulkUpdateSidebarModel;
}

export const BulkUpdateProjectStakeholderSidebar: React.FC<ProjectStakeholderBulkUpdateSidebarProps> = observer(
  ({ model }) => {
    if (model.isLoading) return <PositionedSpinner />;

    return (
      <div className="container-fluid p-3">
        <div className="col">
          <SingleForm model={model.formModel} />
        </div>
      </div>
    );
  }
);
