import { action, makeObservable, observable } from "mobx";
import UserReportsApi, {
  UserReportsApi as IUserReportsApi
} from "../../../../services/api/v2/userReports/UserReports.api";
import { ActionGridModel } from "./ActionGrid_model";
import { MapActionsToProgressPie, MapActionsToRagPie } from "./Actions_mapper";

export class ActionsModel {
  @observable isLoading: boolean = true;
  @observable.ref gridActions = [];
  organisationId: number;
  userProvider: IUserReportsApi;
  gridModel: ActionGridModel;
  pieData: any;
  progressData: any[];

  /**
   *
   */
  constructor(organisationId: number, projectId: number, authUser: FP.Entities.IUser) {
    makeObservable(this);
    this.organisationId = organisationId;
    this.userProvider = UserReportsApi;
    this.gridModel = new ActionGridModel(organisationId, projectId, authUser);
  }

  onMount = async () => {
    await this.loadActions();
  };

  onUnmount = () => {};

  onGridReady = () => {};

  loadActions = async () => {
    let res = await this.userProvider.getUserActions(this.organisationId);
    if (!res) return;

    this.setActions(res.payload);
  };

  @action
  setActions = (actions: any[]) => {
    this.pieData = MapActionsToRagPie(actions);
    this.progressData = MapActionsToProgressPie(actions);
    this.gridActions = actions;
    this.isLoading = false;
  };
}
