import { IconSymbols } from "@flightpath/coreui/dist/generated/IconSymbols";
import { Icon } from "@flightpath/coreui/dist/ui/Icon";
import { PanelTypes } from "@flightpath/coreui/dist/ui/Panel";
import { Pill } from "@flightpath/coreui/dist/ui/Pill";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { Panel } from "../../../../../../../../components/ui/Panel";
import { PositionedSpinner } from "../../../../../../../../components/ui/PositionedSpinner";
import { Tag } from "../../../../../../../../components/ui/Tag";
import PermissionsContext from "../../../../../../../../contexts/permissions/PermissionsContext";
import { PermissionFields } from "../../../../../../../../contexts/permissions/PermissionsTypes";
import I18n from "../../../../../../../../core/localization/I18n";
import { useCurrentOrganisationId } from "../../../../../../../../services/local/organisationContext/OrganisationContextModel";
import { ManageStakeholderOwnerForm } from "./ManageStakeholderOwnerForm";
import { StakeholderOwnersSidebarModel } from "./StakeholderOwnersSidebar_model";

export interface StakeholderOwnersSidebarProps {
  projectId: number;
  stakeholderId: number;
  projectStakeholderId: number;
}

export const StakeholderOwnersSidebar: React.FC<StakeholderOwnersSidebarProps> = observer(props => {
  const { projectId, stakeholderId } = props;
  const organisationId = useCurrentOrganisationId();
  const [model, setModel] = useState(() => new StakeholderOwnersSidebarModel(organisationId, projectId, stakeholderId));

  useEffect(() => {
    if (model.projectId !== projectId) {
      setModel(new StakeholderOwnersSidebarModel(organisationId, projectId, stakeholderId));
    }
  }, [organisationId, projectId, stakeholderId, model.projectId]);

  useEffect(() => {
    model.onMount();
  }, [model]);

  if (!model || model.isLoading) return <PositionedSpinner />;

  const canEdit = PermissionsContext.canEditField(PermissionFields.STAKEHOLDERS, organisationId, projectId);
  const { projectStakeholderOwners, projectStakeholder } = model;

  return (
    <div className="container-fluid">
      {canEdit ? (
        <div className="row mb-4">
          <div className="col">
            <ManageStakeholderOwnerForm
              projectId={projectId}
              organisationId={organisationId}
              projectStakeholder={model.projectStakeholder}
              onAddOwner={model.onMount}
              pills={
                projectStakeholderOwners?.length > 0 &&
                projectStakeholderOwners.map((pso: any) => (
                  <Pill className="m-1" key={pso.id}>
                    <p className="mb-0 d-inline-block">{`${pso.firstName} ${pso.lastName}`}</p>
                    <span
                      onClick={e => {
                        model.removeOwner(pso.id, projectStakeholder.id);
                      }}
                    >
                      <Icon symbol={IconSymbols.Close} className="ml-2" />
                    </span>
                  </Pill>
                ))
              }
            />
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col">
            {projectStakeholderOwners.length > 0 ? (
              projectStakeholderOwners.map((pso: any) => (
                <Tag
                  tagText={pso.firstName}
                  isEditable={false}
                  testId={pso.id + ""}
                  key={pso.id}
                  isNew={false}
                  onClick={e => {
                    model.removeOwner(pso.id, projectStakeholder.id);
                  }}
                />
              ))
            ) : (
              <div>
                <Panel.Panel hasBorderRadius={true} type={PanelTypes.OUTLINES} className="py-2 text-center">
                  <div>{I18n.t("phrases.noRelationshipOwners")}</div>
                </Panel.Panel>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
});
