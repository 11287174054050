import { UiSizes } from "@flightpath/coreui/dist/enums";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { Button, ButtonTypes } from "../../../../../../components/ui/Button";
import { GridView } from "../../../../../../core/grids/GridView_view";
import I18n from "../../../../../../core/localization/I18n";
import { UploadGridModel } from "./UploadGrid_model";
import NoRespondents from "../assets/NoRespondents.svg";
import { UploadGrid_GridModel } from "./grid/UploadGrid_Grid_model";
import React from "react";
import { useFlightPathUser } from "../../../../../../core/auth_insight";

export interface IUploadGridProps {
  organisationId: number;
  fileId: number;
}

export const UploadGrid: React.FC<IUploadGridProps> = observer((props: IUploadGridProps) => {
  const authUser = useFlightPathUser();
  const [model] = useState(() => new UploadGridModel(props.organisationId, props.fileId, authUser));
  const [gridModel] = useState(() => new UploadGrid_GridModel(props.organisationId));
  useEffect(() => {
    model.onMount();
    return () => {
      if (model.isComponentMounted) {
        model.onUnmount();
      }
    };
  }, [model]);

  return (
    <div className="stakeholders-upload-grid">
      <div className="row pt-2 pb-2">
        <div className="col">
          <Button
            onClick={model.showConfirmImportModal}
            size={UiSizes.XS}
            className="ml-2"
            isDisabled={model.importButtonIsDisabled}
          >
            {I18n.t("pulse.organistationRespondents_importRespondentsButton")}
          </Button>
          <Button
            onClick={model.showConfirmDeleteModal}
            size={UiSizes.XS}
            type={ButtonTypes.OUTLINE_PRIMARY}
            className="ml-2"
            isDisabled={model.deleteButtonIsDisabled}
          >
            {I18n.t("pulse.organistationRespondents_deleteRespondentButton")}
          </Button>
        </div>
        <div className="col text-right pt-1">
          <input
            id="showUploadedRows"
            checked={model.showUploadedRows}
            type="checkbox"
            onChange={() => model.setShowUploadedRows()}
          />
          <label htmlFor="showUploadedRows" className="mr-3">
            Show Uploaded Rows
          </label>
        </div>
      </div>
      <div>
        <GridView
          data={model.respondentUploads}
          model={gridModel}
          isLoading={gridModel.isLoading}
          onCellClicked={function (cell: string, isEditMode?: boolean): void {
            throw new Error("Function not implemented.");
          }}
          onCellEditModeChange={function (cell: string, isEditMode: boolean): void {
            throw new Error("Function not implemented.");
          }}
          onSelectionChanged={model.updateSelectedStakeholderUploads}
          users={[]}
          overlayNoRowsTemplate={`<div><img src=${NoRespondents} alt="No Iterations" /><h3>${I18n.t(
            "phrases.organisationStakeholders_upload_noFileRows_heading"
          )}</h3><p>${I18n.t("phrases.organisationStakeholders_upload_noFileRows_p1")}</p></div>`}
          isRowSelectableFn={model.isRowSelectable}
        />
      </div>
    </div>
  );
});
