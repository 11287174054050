import {
    DivTable,
    DivTableCol,
    DivTableRow,
    DivTableBody,
    DivTableHeaderGroup,
    DivTableHeader,
    DivTableFooter,
    DivTableProps as DTP,
    DivTableHeaderGroupProps as DTHGP,
    DivTableRowProps as DTRP
} from "@flightpath/coreui/dist/ui/Table/DivTable"

export interface DivTableProps extends DTP { }

export interface DivTableHeaderGroupProps extends DTHGP { }

export interface DivTableRowProps extends DTRP { }


let s = {
    Table: DivTable,
    Col: DivTableCol,
    Row: DivTableRow,
    Body: DivTableBody,
    Header: DivTableHeaderGroup,
    HeaderCol: DivTableHeader,
    Footer: DivTableFooter
}

export default s;