import { action, observable } from "mobx";
import { BaseModel } from "../../../../core/util/BaseModel";
import ActionsApi, { ActionsApi as IActionsApi } from "../../../../services/api/v2/actions/Actions.api";
import { IModalContextModel } from "../../../../core/modalZ/context/IModalContext";
import ModalContext from "../../../../core/modalZ/context/ModalContext";

export class ActionCompactViewModel extends BaseModel {
  modalService: IModalContextModel;
  @observable.ref actionObject: FP.Entities.IAction;
  actionsProvider: IActionsApi;
  @observable isLoading = true;
  actionId: number;
  projectId: number;
  organisationId: number;
  /**
   *
   */
  constructor(projectId: number, organisationId: number, actionId?: number) {
    super();
    this.modalService = ModalContext;
    this.actionsProvider = ActionsApi;
    this.actionId = actionId;
    this.projectId = projectId;
    this.organisationId = organisationId;
  }

  @action
  setActionObject = (action: FP.Entities.IAction) => {
    this.actionId = action.id;
    this.actionObject = action;
    this.isLoading = false;
  };

  @action
  loadAction = async (actionId: number) => {
    let res = await this.actionsProvider.getDetailedByIdAsync(this.organisationId, this.projectId, actionId);
    if (!res || res.isError) return;

    this.setActionObject(res.payload);
  };

  onUnmount = () => {};

  onMount = () => {
    if (this.actionId) {
      this.getAction();
    }
  };

  @action
  async getAction() {
    let res = await this.actionsProvider.getDetailedByIdAsync(this.organisationId, this.projectId, this.actionId);
    if (res) this.setActionObject(res.payload);
  }
}
