import { BASE_ROUTE, RouteParams } from "../constants";

export const ORGANISATION_ROUTES = {
  coreData: {
    index: {
      path: `${BASE_ROUTE}/core-data/business-areas`,
      generateLink: (organisationId: string | number) =>
        `${BASE_ROUTE.replace(RouteParams.organisationId, organisationId + "")}/core-data/business-areas`
    },
    locations: {
      path: `${BASE_ROUTE}/core-data/locations`,
      generateLink: (organisationId: string | number) =>
        `${BASE_ROUTE.replace(RouteParams.organisationId, organisationId + "")}/core-data/locations`
    },
    processes: {
      path: `${BASE_ROUTE}/core-data/processes`,
      generateLink: (organisationId: string | number) =>
        `${BASE_ROUTE.replace(RouteParams.organisationId, organisationId + "")}/core-data/processes`
    },
    roles: {
      path: `${BASE_ROUTE}/core-data/roles`,
      generateLink: (organisationId: string | number) =>
        `${BASE_ROUTE.replace(RouteParams.organisationId, organisationId + "")}/core-data/roles`
    },
    actionTypes: {
      path: `${BASE_ROUTE}/core-data/action-types`,
      generateLink: (organisationId: string | number) =>
        `${BASE_ROUTE.replace(RouteParams.organisationId, organisationId + "")}/core-data/action-types`
    },
    impactTypes: {
      path: `${BASE_ROUTE}/core-data/impact-types`,
      generateLink: (organisationId: string | number) =>
        `${BASE_ROUTE.replace(RouteParams.organisationId, organisationId + "")}/core-data/impact-types`
    },
    labels: {
      path: `${BASE_ROUTE}/core-data/labels`,
      generateLink: (organisationId: string | number) =>
        `${BASE_ROUTE.replace(RouteParams.organisationId, organisationId + "")}/core-data/labels`
    },
    stakeholders: {
      path: `${BASE_ROUTE}/core-data/stakeholders`,
      generateLink: (organisationId: string | number) =>
        `${BASE_ROUTE.replace(RouteParams.organisationId, organisationId + "")}/core-data/stakeholders`
    }
  },
  settings: {
    index: {
      path: `${BASE_ROUTE}/settings/organisation`,
      generateLink: (organisationId: string | number) =>
        `${BASE_ROUTE.replace(RouteParams.organisationId, organisationId + "")}/settings/organisation`
    },
    users: {
      path: `${BASE_ROUTE}/settings/users`,
      generateLink: (organisationId: string | number) =>
        `${BASE_ROUTE.replace(RouteParams.organisationId, organisationId + "")}/settings/users`
    },
    admin: {
      path: `${BASE_ROUTE}/settings/admin`,
      generateLink: (organisationId: string | number) =>
        `${BASE_ROUTE.replace(RouteParams.organisationId, organisationId + "")}/settings/admin`
    }
  },
  createProject: {
    path: `${BASE_ROUTE}/create/project`,
    generateLink: (organisationId: string | number) =>
      `${BASE_ROUTE.replace(RouteParams.organisationId, organisationId + "")}/create/project`
  },
  createProgramme: {
    path: `${BASE_ROUTE}/create/programme`,
    generateLink: (organisationId: string | number) =>
      `${BASE_ROUTE.replace(RouteParams.organisationId, organisationId + "")}/create/programme`
  },
  users: {
    path: `${BASE_ROUTE}/users/${RouteParams.userId}`,
    generateLink: (organisationId: string | number, userId: number | string) =>
      `${BASE_ROUTE.replace(RouteParams.organisationId, organisationId + "")}/users/${userId}`
  },
  stakeholders: {
    path: `${BASE_ROUTE}/stakeholders/${RouteParams.stakeholderId}`,
    generateLink: (organisationId: string | number, stakeholderId: number | string) =>
      `${BASE_ROUTE.replace(RouteParams.organisationId, organisationId + "")}/stakeholders/${stakeholderId}`
  },
  visualisations: {
    heatmap: {
      path: `${BASE_ROUTE}/visualisations/heatmap/${RouteParams.section}`,
      generateLink: (organisationId: string | number, section: string) =>
        `${BASE_ROUTE.replace(RouteParams.organisationId, organisationId + "")}/visualisations/heatmap/${section}`
    },
    sunburst: {
      path: `${BASE_ROUTE}/visualisations/sunburst`,
      generateLink: (organisationId: string | number) =>
        `${BASE_ROUTE.replace(RouteParams.organisationId, organisationId + "")}/visualisations/sunburst`
    },
    timeline: {
      path: `${BASE_ROUTE}/visualisations/timeline`,
      generateLink: (organisationId: string | number) =>
        `${BASE_ROUTE.replace(RouteParams.organisationId, organisationId + "")}/visualisations/timeline`
    },
    capacity: {
      path: `${BASE_ROUTE}/visualisations/capacity`,
      generateLink: (organisationId: string | number) =>
        `${BASE_ROUTE.replace(RouteParams.organisationId, organisationId + "")}/visualisations/capacity`
    }
  }
};
