import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { TimelineView } from "../../../../../components/widgets/TimelineWidget/Timeline_view";
import I18n from "../../../../../core/localization/I18n";
import { useCurrentOrganisationId } from "../../../../../services/local/organisationContext/OrganisationContextModel";
import { DashboardTimelineModel } from "./DashboardTimeline_model";
import { TimelineMilestoneLegend } from "./components/TimelineMilestoneLegend";

export interface Props {}

export const DashboardTimeline: React.FC<Props> = observer(() => {
  const organisationId = useCurrentOrganisationId();
  const [model] = useState(() => new DashboardTimelineModel(organisationId));

  useEffect(() => {
    model.onMount();
  }, [model]);

  return (
    <div className="container-fluid org-timeline action-timeline">
      <div className="row mt-5">
        <div className="col">
          <TimelineMilestoneLegend />
        </div>
      </div>
      <div className="row" style={{ marginBottom: "90px" }}>
        <div className="col">
          <TimelineView
            model={model.timelineModel}
            timelineProps={{ lineHeight: 40 } as any}
            labels={{
              noItemsText: I18n.t("phrases.noProgrammeProjectsOrMilestones"),
              today: I18n.t("phrases.today"),
              scrollLeft: I18n.t("phrases.scrollLeft"),
              scrollRight: I18n.t("phrases.scrollRight"),
              zoomOut: I18n.t("phrases.zoomOut"),
              zoomIn: I18n.t("phrases.zoomIn"),
              resetZoom: I18n.t("phrases.resetZoom")
            }}
          />
        </div>
      </div>
    </div>
  );
});
