import React, { useMemo } from "react";
import { PositionedSpinner } from "../../../../../../../components/ui/PositionedSpinner";
import I18n from "../../../../../../../core/localization/I18n";
import { ReportingCard } from "../../../../Actions/ActionReportingView/TopActionsReportingBar/ReportingCard";
import { ReportingPie } from "../../../../Actions/ActionReportingView/TopActionsReportingBar/ReportingPie";
import { ReportingTitle } from "../../../../Actions/ActionReportingView/TopActionsReportingBar/ReportingTitle";

interface TotalStakeholderProps {
  data: FP.Entities.IProjectStakeholderSummary[];
  isLoading: boolean;
  profilingType: "0" | "commitment" | "sentiment" | "receptiveness";
}

export const TotalStakeholder: React.FC<TotalStakeholderProps> = ({ isLoading, data, profilingType }) => {
  const colors = ["#F91E1E", "#2AE1EE", "#1AC541", "#DEDEDE"];
  const concerned = useMemo(
    () =>
      data.filter(e => {
        if (profilingType === "0") {
          return true;
        }
        return e[profilingType] <= 4 && e[profilingType] > 0;
      }).length,
    [data, profilingType]
  );
  const neutral = useMemo(
    () =>
      data.filter(e => {
        if (profilingType === "0") {
          return true;
        }
        return e[profilingType] <= 7 && e[profilingType] > 4;
      }).length,
    [data, profilingType]
  );
  const advocate = useMemo(
    () =>
      data.filter(e => {
        if (profilingType === "0") {
          return true;
        }
        return e[profilingType] <= 10 && e[profilingType] > 7;
      }).length,
    [data, profilingType]
  );
  const unknown = useMemo(
    () =>
      data.filter(e => {
        if (profilingType === "0") {
          return true;
        }
        return e[profilingType] <= 0;
      }).length,
    [data, profilingType]
  );
  const pieData = [
    {
      label: I18n.t("visualisations.concerned"),
      value: concerned,
      id: "concerned"
    },
    {
      label: I18n.t("visualisations.neutral"),
      value: neutral,
      id: "neutral"
    },
    {
      label: I18n.t("visualisations.advocate"),
      value: advocate,
      id: "advocate"
    },
    {
      label: I18n.t("visualisations.Unknown"),
      value: unknown,
      id: "Unknown"
    }
  ];

  if (isLoading) {
    return <PositionedSpinner />;
  }

  return (
    <div className="total-stakeholder">
      {profilingType === "0" ? (
        <ReportingCard className="px-3">
          <div style={{ height: "200px" }}>
            <div className="d-flex action_reporting__Card-info mb-1">
              <ReportingTitle title={I18n.t("visualisations.totalStakeholders")} className="pt-3" />
            </div>
            <div className="action-reporting__metric-pie pb-2 mt-5">
              <p className="heatmap-support__total__value text-center">{data.length}</p>
            </div>
          </div>
        </ReportingCard>
      ) : (
        <ReportingPie
          data={pieData}
          metric={data.length}
          colourArray={colors}
          title={I18n.t("visualisations.totalStakeholders")}
        />
      )}
    </div>
  );
};
