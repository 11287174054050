import { action, computed, makeObservable, observable } from "mobx";
import { IPaginationModelConfig } from "../../components/widgets/pagination/Pagination_model";

export interface IInfiniteLoaderModel {
  isLoading: boolean;
  hasNextPage: boolean;
  setConfig: (config: Partial<IPaginationModelConfig>) => void;
  setIsLoading: (isLoading: boolean) => void;
  setTotal: (total: number) => void;
  getTotal: () => number;
}

export class InfiniteLoaderModel implements IInfiniteLoaderModel {
  @observable isLoading: boolean = false;
  @computed get hasNextPage() {
    return this.total < this.config.totalItems;
  }
  config: Partial<IPaginationModelConfig> = {
    totalItems: 0
  };
  @observable total: number = -1;

  /**
   *
   */
  constructor() {
    makeObservable(this);
  }

  @action
  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };

  setConfig = (config: Partial<IPaginationModelConfig>) => {
    this.config = { ...this.config, ...config };
  };

  @action
  setTotal = (total: number) => {
    this.total = total;
  };

  getTotal = () => this.total;
}
