import { CircleStatus, CircleStatusColours } from "@flightpath/coreui/dist/ui/CircleStatus";
import { ICellRendererParams } from "ag-grid-community";
import React from "react";
import { Enums } from "../../../../../../enums";
import I18n from "../../../../../../core/localization/I18n";

export const StakeholderProfilingCell: React.FC<ICellRendererParams> = props => {
  const getLabel = () => {
    switch (props.colDef.field) {
      case "sentiment":
        return Enums.Translator.SentimentLevel(props.data.sentiment);
      case "commitment":
        return Enums.Translator.CommitmentLevel(props.data.commitment);
      case "receptiveness":
        return Enums.Translator.ReceptivenessLevel(props.data.receptiveness);
    }
  };

  const getColour = () => {
    const value = props.data[props.colDef.field];
    if (value <= 0) {
      return CircleStatusColours.GREY;
    } else if (value > 0 && value <= 4) {
      return CircleStatusColours.RED;
    } else if (value <= 7) {
      return CircleStatusColours.BLUE;
    } else {
      return CircleStatusColours.GREEN;
    }
  };

  return (
    <div className="stakeholder-profiling-cell">
      <CircleStatus colour={getColour()} className="mr-2" />
      {getLabel()}
    </div>
  );
};

export const StakeholderPauseCell: React.FC<ICellRendererParams> = props => {
  const val = props.data[props.colDef.field];
  // if (!val) return null;
  const getLabel = () => {
    switch (val) {
      case 1:
        return `1 - ${I18n.t("phrases.none")}`;
      case 2:
        return `2 - ${I18n.t("phrases.poor")}`;
      case 3:
        return `3 - ${I18n.t("phrases.fair")}`;
      case 4:
        return `4 - ${I18n.t("phrases.good")}`;
      case 5:
        return `5 - ${I18n.t("phrases.great")}`;
      default:
        return I18n.t("phrases.unknown");
    }
  };

  const getColour = () => {
    switch (val) {
      case 1:
      case 2:
        return CircleStatusColours.RED;
      case 3:
        return CircleStatusColours.AMBER;
      case 4:
        return CircleStatusColours.YELLOW_GREEN;
      case 5:
        return CircleStatusColours.GREEN;
      default:
        return CircleStatusColours.GREY;
    }
  };

  return (
    <div className="stakeholder-profiling-cell">
      <CircleStatus colour={getColour()} className="mr-2" />
      {getLabel()}
    </div>
  );
};
