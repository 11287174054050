import { IFlightPathApiResponse } from "../BaseApiModel";
import http from "../Http";
import { IFilteredOptions } from "../filteredApi/FilteredApiModel";
import { FilteredOrganisationApiModel } from "../filteredApi/FilteredOrganisationApiModel";
import { AxiosInstance, AxiosRequestConfig } from "axios";

export class ProgrammesApi extends FilteredOrganisationApiModel<FP.Entities.IProgramme> {
  controller: string = "programmes";

  constructor(http: AxiosInstance) {
    super(http, "programmes");
  }
  //#region CRUD

  getFilteredAsync = async (
    filterOptions: Partial<IFilteredOptions>,
    config?: AxiosRequestConfig,
    organisationId?: number
  ) => {
    const query = this.getRequestQuery(filterOptions);
    const res = await this.http.get(`${this.url}/${organisationId}/${this.controller}${query}`, config);
    return res.data;
  };

  async getDetailedByIdAsync(organisationId: number, programmeId: number, config?: AxiosRequestConfig) {
    const res = await this.http.get(`${this.url}/${organisationId}/programmes/${programmeId}/detailed`, config);
    return res.data;
  }

  async getTimelineReport(organisationId: number, programmeId: number, config?: AxiosRequestConfig) {
    const res = await this.http.get(`${this.url}/${organisationId}/programmes/${programmeId}/timeline-report`, config);
    return res.data;
  }
  //#endregion

  //#region Projects
  getProjectsWithInsights = async (organisationId: number, programmeId: number, config?: AxiosRequestConfig) => {
    let res = await this.http.get(`${this.url}/${organisationId}/programmes/${programmeId}/projectsInsights`, config);
    return res.data;
  };

  //#endregion

  //#region permissions

  addPermission = async (
    organisationId: number,
    programmeId: number,
    permissions: FP.Entities.IPermission,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IPermission>> => {
    const url = `${this.url}/${organisationId}/programmes/${programmeId}/permissions`;
    let res = await this.http.post(url, permissions, config);
    return res.data;
  };

  getPermissionUsers = async (
    organisationId: number,
    programmeId: number,
    filterOptions?: Partial<IFilteredOptions>,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IUser[]>> => {
    const url = `${this.url}/${organisationId}/programmes/${programmeId}/permissions`;
    let res = await this.http.get(url + ((filterOptions && this.getRequestQuery(filterOptions)) || ""), config);
    return res.data;
  };

  removeUserPermission = async (
    organisationId: number,
    programmeId: number,
    userId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<boolean>> => {
    const url = `${this.url}/${organisationId}/programmes/${programmeId}/permissions/${userId}`;
    let res = await this.http.delete(url, config);
    return res.data;
  };

  getInheritUsers = async (
    organisationId: number,
    programmeId: number,
    filterOptions?: Partial<IFilteredOptions>,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IUser[]>> => {
    const url = `${this.url}/${organisationId}/programmes/${programmeId}/inherited-users`;
    let res = await this.http.get(url + ((filterOptions && this.getRequestQuery(filterOptions)) || ""), config);
    return res.data;
  };

  //#endregion

  getProgrammeDashboard = async (organisationId: number, programmeId: number, config?: AxiosRequestConfig) => {
    let res = await this.http.get(`${this.url}/${organisationId}/programmes/${programmeId}/dashboard`, config);
    return res.data;
  };

  getChangeTeamCapacityChartData = async (organisationId: number, programmeId: number, config?: AxiosRequestConfig) => {
    let res = await this.http.get(
      `${this.url}/${organisationId}/programmes/${programmeId}/change-team-capacity`,
      config
    );
    return res.data;
  };

  getStakeholderCapacityChartData = async (
    organisationId: number,
    programmeId: number,
    stakeholderType: number,
    config?: AxiosRequestConfig
  ) => {
    let res = await this.http.get(
      `${this.url}/${organisationId}/programmes/${programmeId}/stakeholder-capacity/${stakeholderType}`,
      config
    );
    return res.data;
  };

  getAudienceCapacityChartData = async (
    organisationId: number,
    programmeId: number,
    config?: AxiosRequestConfig
  ) => {
    let res = await this.http.get(`${this.url}/${organisationId}/programmes/${programmeId}/audience-capacity`, config);
    return res.data;
  };
}

const instance = new ProgrammesApi(http);
export default instance;
