import React from "react";
import { Panel } from "../../../../../../components/ui/Panel";
import { Icon, IconSymbols } from "../../../../../../components/ui/Icon";
import { UiSizes } from "../../../../../../enums";

export interface IUploadFileWarningProps {
    message: string;
}

export const UploadFileWarning: React.FC<IUploadFileWarningProps> = (props: IUploadFileWarningProps) => {
    return <div>
        <Panel.Panel type={Panel.PanelTypes.BORDER_TOP_WARNING} hasShadow={true} className="text-left m-4 p-2 d-flex">
            <Icon symbol={IconSymbols.AlertTriangle} size={UiSizes.SM} className="mr-2" style={{color: "#f9ab1e"}} />
            <span>{props.message}</span>
        </Panel.Panel>
    </div>
}