import { action, makeObservable, observable } from "mobx";
import I18n from "../../../core/localization/I18n";
import ToasterService, { IToasterService } from "../../../core/toaster/ToasterService";
import { TOASTER_TOAST_TIME } from "../../../core/toaster/Toaster_model";
import { BaseModel } from "../../../core/util/BaseModel";
import ProjectsApi, { ProjectsApi as IProjectsApi } from "../../../services/api/v2/projects/Projects.api";

export interface IRevisionHistoryModelConfig {
  projectId: number;
  entityId: number;
  organisationId: number;
  historyType: string;
}

export class RevisionHistoryModel extends BaseModel {
  projectProvider: IProjectsApi;
  toastService: IToasterService;

  @observable config: IRevisionHistoryModelConfig = null;
  @observable isLoading: boolean = true;
  @observable errorMessage: string = "";
  @observable revisions: FP.Entities.IRevisionHistory[] = [];

  constructor(initOpts: IRevisionHistoryModelConfig) {
    super();
    makeObservable(this);
    this.setConfig(initOpts);

    this.toastService = ToasterService;
    this.projectProvider = ProjectsApi;
  }

  @action.bound
  onMount() {
    this.getRevisions();
  }

  @action
  setConfig = (config: Partial<IRevisionHistoryModelConfig>) => {
    this.config = { ...this.config, ...config };
  };

  @action.bound
  async getRevisions() {
    this.isLoading = true;

    if (!this.config.entityId) {
      this.toastService
        .showErrorToast(TOASTER_TOAST_TIME.SLOW)
        .setContent(<p>No Stakeholder ID was specified</p>)
        .startTimer(TOASTER_TOAST_TIME.NORMAL);
      return;
    }

    try {
      const res = await this.projectProvider.getHistory(
        this.config.organisationId,
        this.config.projectId,
        this.config.entityId,
        this.config.historyType
      );

      if (!res || res.isError) {
        this.errorMessage = I18n.t("errors.loadRevisionHistory");
        return;
      }

      this.setRevisions(res.payload);
    } catch (error) {
      this.errorMessage = I18n.t("errors.loadRevisionHistory");
    } finally {
      this.isLoading = false;
    }
  }

  @action
  setRevisions = (revisions: FP.Entities.IRevisionHistory[]) => {
    this.revisions = revisions;
  };
}
