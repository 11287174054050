import { ProjectProcessesGridModalView } from "./modals/ProjectProcessesGridModal/ProjectProcessesGridModal_view";
import { ProjectProcessesTreeSidebar } from "./modals/ProjectProcessesTreeModal/ProjectProcessesTreeSidebar_view";

export const SHOW_PROCESS_TREE_FILTER_MODAL = (
  onAssignSuccess: (itemIds: number[]) => void,
  selectedItemIds: number[]
) => {
  return {
    showClose: true,
    title: (
      <div className="mt-6">
        <h3 className="mb-5">Project Processes</h3>
      </div>
    ),
    content: <ProjectProcessesTreeSidebar onAssignSuccess={onAssignSuccess} selectedItemIds={selectedItemIds} />
  };
};

export const SHOW_PROCESS_GRID_FILTER_MODAL = (
  onAssignSuccess: (itemIds: number[]) => void,
  selectedItemIds: number[]
) => {
  return {
    showClose: false,
    title: null,
    className: "d-flex flex-column",
    panelProps: {
      className: "d-flex flex-column h-100"
    },
    content: (
      <ProjectProcessesGridModalView
        gridToolbarType="link-modal"
        onAssignSuccess={onAssignSuccess}
        selectedItemIds={selectedItemIds}
      />
    )
  };
};
