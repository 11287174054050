import React from "react";

export const CreateSuccessContent: React.FC<{ locale: string }> = ({
  locale,
}) => {
  return (
    <>
      <span className="toaster__success-review-text">
        <span>{locale}</span>
      </span>
    </>
  );
};
