import { ButtonTypes } from "../../../../components/ui/Button";
import { Icon, IconSymbols } from "../../../../components/ui/Icon";
import { Panel } from "../../../../components/ui/Panel";
import ProgressIndicatorModel, {
  ProgressIndicatorModel as IProgressIndicatorModel
} from "../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import I18n from "../../../../core/localization/I18n";
import { IModalContextModel } from "../../../../core/modalZ/context/IModalContext";
import ModalContext from "../../../../core/modalZ/context/ModalContext";
import ToasterService, { IToasterService } from "../../../../core/toaster/ToasterService";
import { TOASTER_TOAST_TIME } from "../../../../core/toaster/Toaster_model";
import { UiActionRenderers } from "../../../../core/uiAction/IUiAction";
import { BaseModel } from "../../../../core/util/BaseModel";
import { Enums } from "../../../../enums";
import OrganisationsApi, {
  OrganisationsApi as IOrganisationsApi
} from "../../../../services/api/v2/organisations/Organisations.api";
import ProgrammesApi, { ProgrammesApi as IProgrammesApi } from "../../../../services/api/v2/programmes/Programmes.api";
import { getProgrammeFormFields } from "../../forms/programme/ProgrammeFormSection_data";
import { SingleFormModel } from "../../forms/singleFormModel/SingleForm_model";

export class CreateProgrammeViewModel extends BaseModel {
  orgId: number;
  organisationProvider: IOrganisationsApi;
  formModel: SingleFormModel;
  programmeProvider: IProgrammesApi;
  toasterService: IToasterService;
  httpProgress: IProgressIndicatorModel;
  modalService: IModalContextModel;
  user: FP.Entities.IUser;
  refreshClaims: () => void;
  navigate: any;
  constructor(user: FP.Entities.IUser, refreshClaims: () => void, navigate, organisationId: number) {
    super();
    this.user = user;
    this.refreshClaims = refreshClaims;
    this.navigate = navigate;
    this.toasterService = ToasterService;
    this.orgId = organisationId;
    this.httpProgress = ProgressIndicatorModel;
    this.programmeProvider = ProgrammesApi;
    this.organisationProvider = OrganisationsApi;
    this.modalService = ModalContext;
    this.formModel = new SingleFormModel();
    this.setForm();
  }

  setForm = () => {
    this.formModel.formFields = getProgrammeFormFields(this.orgId, this.organisationProvider);
    this.formModel.actions = [
      {
        id: "createAnother",
        label: I18n.t("phrases.createAnother"),
        onAction: async () => {
          let res = await this.createProgramme();
          if (res) {
            this.toasterService
              .showSuccessToast()
              .setContent(
                <span>{I18n.t("phrases.itemCreatedSuccessfully", { item: I18n.t("entities.programme") })}</span>
              )
              .startTimer(TOASTER_TOAST_TIME.NORMAL);
            this.formModel.resetFields();
          }
        },
        rendersIn: UiActionRenderers.BUTTON,
        componentProps: {
          type: ButtonTypes.OUTLINE_PRIMARY,
          className: "ml-auto"
        }
      },
      {
        id: "createProgrammeButton",
        label: I18n.t("phrases.createProgramme"),
        onAction: async () => {
          let res = await this.createProgramme();
          if (res) {
            let url = res.isPrivate
              ? `/organisations/${this.orgId}/programmes/${res.id}/settings/permissions`
              : `/organisations/${this.orgId}/programmes/${res.id}`;
            this.navigate(url);
          }
        },
        rendersIn: UiActionRenderers.BUTTON,
        componentProps: {
          type: ButtonTypes.PRIMARY,
          className: "ml-2"
        }
      }
    ];
  };

  createProgramme = async (): Promise<FP.Entities.IProgramme> => {
    let res = await this.formModel.submit();
    if (!res) return;

    let programmeExists = await this.programmeProvider.getFiltered(this.orgId, {
      filters: `name==${res.name},lifecycleStatus==${Enums.LifecycleStatus.Active},organisationId==${this.orgId}`
    });
    if (programmeExists && !programmeExists.isError && programmeExists.payload.length) {
      let confirmCreateProgramme = await this.confirmCreateProgramme(res.name);
      if (!confirmCreateProgramme) return;
    }

    this.httpProgress.showOverlay();
    res = await this.programmeProvider.create(this.orgId, res as FP.Entities.IProgramme);
    this.refreshClaims();
    this.httpProgress.hideOverlay();
    if (!res || res.isError) return;
    return res.payload;
  };

  confirmCreateProgramme = async (name: string): Promise<boolean> => {
    return new Promise(async resolve => {
      await this.modalService.showConfirmDialog(
        <h1 className="mt-4">{I18n.t("phrases.confirm")}</h1>,
        <div className="container-fluid">
          <div className="row mb-3">
            <div className="col-12">
              <Icon symbol={IconSymbols.AlertCircle} className="mr-2" />
              {I18n.t("warnings.createSameNameProgramme")}
            </div>
          </div>
          <div className="row">
            <div className="col">{I18n.t("warnings.confirmSameName", { name: name })}</div>
          </div>
        </div>,
        I18n.t("phrases.yes"),
        I18n.t("phrases.no"),
        {
          wrapWidth: "small",
          spacing: "small",
          position: "middle",
          panelProps: {
            background: Panel.PanelBackgrounds.BG_WHITE
          }
        },
        async () => {
          this.modalService.hide();
          resolve(true);
        },
        () => {
          this.modalService.hide();
          resolve(false);
        }
      );
    });
  };

  onMount = () => {};

  onUnmount = () => {};
}
