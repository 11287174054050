import { ColDef } from "ag-grid-community";
import {
  colFirstName,
  colLastName,
  colEmailAddress,
  colUploadedOn,
  colUploadedBy,
  colExistingStakeholder,
  colSelection,
  colBusinessArea,
  colRole
} from "./UploadGrid_Grid_config";

export const GetUploadGridViewColumns = (saveFn: any): ColDef[] => {
  let result: ColDef[] = [];

  result.push(colSelection());
  result.push(colExistingStakeholder());
  result.push(colFirstName(saveFn));
  result.push(colLastName(saveFn));
  result.push(colEmailAddress());
  result.push(colBusinessArea(saveFn));
  result.push(colRole(saveFn));
  result.push(colUploadedBy());
  result.push(colUploadedOn());
  
  return result;
};
