import React from "react";
import { Panel } from "../../../../components/ui/Panel";
import { AvatarContent } from "../../../../components/ui/AvatarContent";
import { USER_IMAGE_PLACEHOLDER } from "../../../../constants";
import { Enums } from "../../../../enums";

export interface IProgrammeSponsorProps {
  organisationId: number;
  contacts?: any[];
}

export const ProgrammeContacts: React.FunctionComponent<IProgrammeSponsorProps> = (props: IProgrammeSponsorProps) => {
  return (
    <Panel.Panel hasShadow={true} hasBorderRadius={true} className="p-3">
      {props.contacts.map((e, i) => {
        return (
          <AvatarContent
            key={i}
            type="primary"
            className={i < props.contacts.length - 1 ? "mb-4" : ""}
            avatarProps={{
              size: Enums.UiSizes.MD,
              imgSrc: e.profileImageUrl || USER_IMAGE_PLACEHOLDER(e.firstName, e.lastName)
            }}
          >
            <h4 className="mb-0">
              {e.firstName} {e.lastName}
            </h4>
          </AvatarContent>
        );
      })}
    </Panel.Panel>
  );
};
