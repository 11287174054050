import { IconSymbols } from "@flightpath/coreui/dist/generated/IconSymbols";
import { RagStatus } from "@flightpath/coreui/dist/ui/RagStatus";
import { FormTooltip } from "@flightpath/coreui/dist/ui/_forms/FormTooltip";
import _ from "lodash";
import moment from "moment";
import { AutocompletePerson } from "../../../../../../../../components/ui/AutocompletePersonOption";
import { AutocompleteOption } from "../../../../../../../../components/ui/_forms/Autocomplete/AutocompleteOption";
import { FORM_COL, PROGRESS_STATUS_OPTIONS, RAG_STATUS_OPTIONS } from "../../../../../../../../constants";
import { INIT_AUTOCOMPLETE } from "../../../../../../../../core/forms/controls/autocomplete/Autocomplete_init";
import { IAutocompleteModel } from "../../../../../../../../core/forms/controls/autocomplete/IAutocompleteModel";
import { INIT_DATEPICKER } from "../../../../../../../../core/forms/controls/datePicker/DatePicker_model";
import { IDatePickerModel } from "../../../../../../../../core/forms/controls/datePicker/IDatePickerModel";
import { generateFormFieldsFromJson } from "../../../../../../../../core/forms/helpers/FormFieldMappers";
import I18n from "../../../../../../../../core/localization/I18n";
import ActionTypesApi from "../../../../../../../../services/api/v2/actionTypes/ActionTypes.api";

export const getBulkUpdateActionsFormFields = (organisationId: number, projectTeamMembers: any[]) => {
  const progressStatus: Partial<IAutocompleteModel> = {
    ...INIT_AUTOCOMPLETE,
    key: "progressStatus",
    label: <label htmlFor={"progressStatus"}>{I18n.t("forms.progressStatus")}</label>,
    placeholder: I18n.t("placeholders.progressStatus"),
    options: PROGRESS_STATUS_OPTIONS.map(e => {
      return {
        label: I18n.t(e.label),
        key: e.key + ""
      } as FP.Generic.IKeyLabel;
    }),
    isLoading: false,
    valueLabelFn: obj => obj.label,
    componentProps: {
      className: "form-control"
    },
    fieldClassName: FORM_COL.FULL_WIDTH,
    extractValue: function () {
      return this.value?.key;
    }
  };

  const ragStatus: Partial<IAutocompleteModel> = {
    ...INIT_AUTOCOMPLETE,
    key: "ragStatus",
    label: <label htmlFor={"ragStatus"}>{I18n.t("forms.ragStatus")}</label>,
    placeholder: I18n.t("placeholders.selectRAGStatus"),
    subscribeTo: ["progressStatus"],
    options: RAG_STATUS_OPTIONS.map(o => ({ ...o, label: I18n.t(o.label) })),
    isLoading: false,
    valueLabelFn: obj => obj.label,
    optionElement: (
      <AutocompleteOption
        key={"e"}
        className={"autocomplete__chip"}
        label={option => (
          <span>
            <RagStatus state={option.key} />
            <span className="ml-1">{option.label}</span>
          </span>
        )}
      />
    ),
    componentProps: {
      className: "form-control"
    },
    fieldClassName: FORM_COL.FULL_WIDTH,
    extractValue: function () {
      return this.value?.key;
    },
    tooltipLabel: (
      <FormTooltip>
        <p className="mb-0">{I18n.t("phrases.ragStatusDesc")}</p>
      </FormTooltip>
    )
  };

  const actionType: Partial<IAutocompleteModel> = {
    ...INIT_AUTOCOMPLETE,
    key: "actionTypeId",
    label: <label htmlFor={"actionTypeId"}>{I18n.t("forms.actionType")}</label>,
    placeholder: I18n.t("placeholders.actionType"),
    optionElement: <AutocompleteOption key={"e"} className={"autocomplete__chip"} label={e => e.name} />,
    componentProps: {
      className: "form-control",
      icon: IconSymbols.Search
    },
    onFocus: async function () {
      let self: IAutocompleteModel = this;
      this.componentProps.disabled = false;
      const res = await ActionTypesApi.getFilteredAsync(
        { filters: `organisationId==${organisationId}` },
        null,
        organisationId
      );

      if (res) {
        const actionTypes = _.orderBy(res.payload, [a => a.name.toLowerCase()]);
        self.setOptions(actionTypes);
      }
    },
    extractValue: function () {
      return this.value?.id;
    },
    searchAttribute: "name",
    fieldClassName: FORM_COL.FULL_WIDTH,
    tooltipLabel: (
      <FormTooltip>
        <p className="mb-0">{I18n.t("tooltips.actionType")}</p>
      </FormTooltip>
    )
  };

  const assignedTo: Partial<IAutocompleteModel> = {
    ...INIT_AUTOCOMPLETE,
    key: "assigneeId",
    label: <label htmlFor="assigneeId">{I18n.t("forms.assignedUser")}</label>,
    placeholder: I18n.t("placeholders.searchUser"),
    optionElement: (
      <AutocompleteOption key="e" className="autocomplete__chip" label={e => <AutocompletePerson {...e} />} />
    ),
    componentProps: {
      className: "form-control",
      icon: IconSymbols.User
    },
    charInputNumber: 0,
    onFocus: async function (model: IAutocompleteModel, event) {
      if (model.options.length === 0) {
        if (projectTeamMembers) {
          const sortedUsers = _.orderBy(projectTeamMembers, [
            user => user.firstName.toLowerCase(),
            user => user.lastName.toLowerCase()
          ]);
          model.setOptions(sortedUsers);
        }
      }
    },
    extractValue: function () {
      return this.value?.id;
    },
    valueLabelFn: (e: FP.Entities.IUser) => `${e.firstName} ${e.lastName} (${e.email})`,
    fieldClassName: FORM_COL.FULL_WIDTH + " col-12",
    noResultsFoundLabel: I18n.get("phrases.noResultsFound")
  };

  const startDate: Partial<IDatePickerModel> = {
    ...INIT_DATEPICKER,
    key: "startDate",
    label: <label htmlFor="startDate">{I18n.t("forms.startDate")}</label>,
    placeholder: I18n.t("placeholders.selectStartDate"),
    fieldClassName: FORM_COL.FULL_WIDTH,
    componentProps: {
      icon: IconSymbols.Calendar,
      datePickerProps: {
        isOutsideRange: day => false
      }
    }
  };

  const endDate: Partial<IDatePickerModel> = {
    ...INIT_DATEPICKER,
    key: "actualEndDate",
    label: <label htmlFor="actualEndDate">{I18n.t("forms.endDate")}</label>,
    placeholder: I18n.t("placeholders.selectEndDate"),
    fieldClassName: FORM_COL.FULL_WIDTH,
    subscribeTo: ["startDate", "progressStatus"],
    onChannelFieldChanged: async function (field) {
      const self: IDatePickerModel = this;
      if (field.key === "startDate") {
        const sd = field.value;
        if (sd) {
          self.setDatepickerProps({
            isOutsideRange: day => {
              return day < sd;
            },
            initialVisibleMonth: () => (sd != null ? sd : moment())
          });
        }
      }
    },
    componentProps: {
      ...INIT_DATEPICKER.componentProps,
      icon: IconSymbols.Calendar
    }
  };

  const owner: Partial<IAutocompleteModel> = {
    ...INIT_AUTOCOMPLETE,
    key: "ownerId",
    label: <label htmlFor={"owner"}>{I18n.t("forms.actionOwner")}</label>,
    placeholder: I18n.t("placeholders.searchUser"),
    optionElement: (
      <AutocompleteOption key={"e"} className={"autocomplete__chip"} label={e => <AutocompletePerson {...e} />} />
    ),
    charInputNumber: 0,
    onFocus: async function (model: IAutocompleteModel, event) {
      if (model.options.length === 0) {
        if (projectTeamMembers) {
          const sortedUsers = _.orderBy(projectTeamMembers, [
            user => user.firstName.toLowerCase(),
            user => user.lastName.toLowerCase()
          ]);
          model.setOptions(sortedUsers);
        }
      }
    },
    componentProps: {
      className: "form-control",
      icon: IconSymbols.User
    },
    extractValue: function () {
      return this.value?.id;
    },
    valueLabelFn: (e: FP.Entities.IUser) => `${e.firstName} ${e.lastName} (${e.email})`,
    fieldClassName: FORM_COL.FULL_WIDTH + " col-12",
    noResultsFoundLabel: I18n.get("phrases.noResultsFound")
  };

  const fields = [];
  fields.push(progressStatus);
  fields.push(ragStatus);
  fields.push(assignedTo);
  fields.push(actionType);
  fields.push(startDate);
  fields.push(endDate);
  fields.push(owner);

  return generateFormFieldsFromJson(fields);
};
