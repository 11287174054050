import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import {
  InsightTabContent,
  InsightTabContentItem
} from "../../../../components/widgets/innerNavigation/InnerNavContent_view";
import I18n from "../../../../core/localization/I18n";
import Pages from "../../../../routes/InsightRoutes";
import { useCurrentOrganisationId } from "../../../../services/local/organisationContext/OrganisationContextModel";
import { useIsVisualisationsEnabled } from "../../../../contexts/organisationSettings/OrganisationSettingsContext";

export const ProcessTabContent: React.FC = observer(() => {
  const organisationId = useCurrentOrganisationId();
  const isVisualisationsEnabled = useIsVisualisationsEnabled();
  const { projectId } = useParams<{ projectId: string }>();
  const items: InsightTabContentItem[] = [
    {
      label: <h2 className="mb-0">{I18n.t("phrases.processes")}</h2>,
      url: Pages.projects.processes.listView.generateLink(organisationId, +projectId)
    },
    {
      label: <h2 className="mb-0">{I18n.t("phrases.processRoleMapping")}</h2>,
      url: Pages.projects.processes.processRoleMapping.generateLink(organisationId, +projectId)
    }
  ];

  if (isVisualisationsEnabled) {
    items.push({
      label: <h2 className="mb-0">{I18n.t("phrases.processReports")}</h2>,
      url: Pages.projects.processes.reports.generateLink(organisationId, +projectId)
    });
  }

  const location = useLocation();
  const currentPath = location.pathname;
  const [currIndex, setCurrIndex] = useState(currentPath.endsWith("processes") ? 0 : 1);

  useEffect(() => {
    if (location.pathname.endsWith("/processes")) {
      setCurrIndex(0);
    } else if (location.pathname.endsWith("/processes-reports")) {
      setCurrIndex(2);
    } else {
      setCurrIndex(1);
    }
  }, [location]);

  return <InsightTabContent items={items} currentIndex={currIndex} />;
});
