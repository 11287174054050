import * as React from 'react'
import { ErrorMessage } from '../../../../../../components/ui/ErrorMessage';
import { FormRequiredFieldIndicator } from '../../../../../../components/ui/_forms/FormRequiredFieldIndicator/FormRequiredFieldIndicator';
import { FORM_COL, REMOVE_UNSAFE_CHARACTERS } from '../../../../../../constants';
import { ITextFieldModel } from '../../../../../../core/forms/controls/textField/ITextFieldModel';
import { INIT_TEXT_FIELD } from '../../../../../../core/forms/controls/textField/TextField_init';
import { generateFormFieldsFromJson } from '../../../../../../core/forms/helpers/FormFieldMappers';
import { Validations } from '../../../../../../core/forms/helpers/Validations';
import I18n from '../../../../../../core/localization/I18n';

export const getTagMiniFormFields = (
    tags: FP.Entities.ITag[],
    tag?: FP.Entities.ITag
) => {
    const tagNames = tags.map(e => e.text)

    const tagIdHidden = {
        ...INIT_TEXT_FIELD,
        key: "tagId",
        inputType: "hidden",
        value: tag.id,
    }

    const text: Partial<ITextFieldModel> = {
        ...INIT_TEXT_FIELD,
        key: "text",
        placeholder: I18n.t("placeholders.tagName"),
        fieldClassName: FORM_COL.FULL_WIDTH,
        label: (
            <label htmlFor="name">
                {I18n.t("forms.tagName")} <FormRequiredFieldIndicator />
            </label>
        ),
        validate: function () {
            const self: ITextFieldModel = this;
            let res = true;
            if (Validations.IS_EMPTY(self.extractValue())) {
                self.errorMessage = <ErrorMessage>{I18n.t("validations.enterTagName")}</ErrorMessage>;
                res = false;
            }
            if (Validations.IS_DUPLICATE(tagNames, this.value)) {
                self.errorMessage = <ErrorMessage> {I18n.t("validations.isDuplicate", { entityName: "label name" })}</ErrorMessage>
                res = false;
            }
            return res;
        },
        extractValue: function () {
            return REMOVE_UNSAFE_CHARACTERS(this.value)
        },
        value: tag?.text
    }

    const fields = [];
    fields.push(text);
    fields.push(tagIdHidden);

    const models = generateFormFieldsFromJson(fields);
    return models;
}