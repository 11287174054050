import { observer } from "mobx-react";
import React from "react";
import { Tab, Tabs } from "../../../../../components/ui/Tabs";
import I18n from "../../../../../core/localization/I18n";
import { Enums } from "../../../../../enums";
import { ChangeTeamCapacity } from "./ChangeTeamCapacity/ChangeTeamCapacity_view";
import { StakeholderCapacity } from "./StakeholderCapacity/StakeholderCapacity_view";

export interface Props {}

export const DashboardCapacity: React.FC<Props> = observer(props => {
  return (
    <Tabs
      key="first"
      className="tabs--primary"
      ulClassName="tabs__menu--primary"
      initialTab={0}
      liClassName="tabs__item--primary"
      //onTabClicked={model.changeCurrentView}
    >
      <Tab title={I18n.t("phrases.capacityStakeholders")}>
        <StakeholderCapacity key="2" stakeholderType={Enums.StakeholderType.INDIVIDUAL} />
      </Tab>

      <Tab title={I18n.t("phrases.capacityAudiences")}>
        <StakeholderCapacity key="1" stakeholderType={Enums.StakeholderType.AUDIENCE} />
      </Tab>
      <Tab title={I18n.t("phrases.capacityChangeTeam")}>
        <ChangeTeamCapacity />
      </Tab>
    </Tabs>
  );
});
