import { ValueSetterParams } from "ag-grid-community";
import { showEditRichTextModal } from "../../../modals/EditRichTextSidebar/EditRichTextModal_caller";
import { ISdColDef } from "../../baseColumn/BaseColumnTypes";
import { BaseTextColumnBuilder } from "../../baseColumn/BaseTextColumn_builder";
import { DYNAMIC_COLUMN_CONFIG } from "./DynamicColumn_config";

interface IDynamicColumnBuilder {}

export class DynamicColumnBuilder extends BaseTextColumnBuilder implements IDynamicColumnBuilder {
  constructor(columnOptions?: ISdColDef<any, any>) {
    super({ ...DYNAMIC_COLUMN_CONFIG(), ...columnOptions });
  }

  setEditableOnDoubleClick = (
    callback: (entityId: number, text: string) => Promise<any>,
    field: string,
    header: string
  ) => {
    this.setColumnOptions({
      onCellDoubleClicked: e => {
        return showEditRichTextModal(e.data, callback, field, header);
      }
    });
    return this;
  };

  setValueSetter = (callback: (params: ValueSetterParams) => any) => {
    return this.setColumnOptions({
      valueSetter: callback
    });
  };
}
