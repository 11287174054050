import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IF } from "../../../../../components/hoc/If";
import { Panel } from "../../../../../components/ui/Panel";
import { PositionedSpinner } from "../../../../../components/ui/PositionedSpinner";
import { BarChart } from "../../../../../core/charts/BarChart/BarChart";
import { ImpactColDefFieldNamesEnum } from "../../../../../core/grids/builder/enums/AgGridColDefFieldNameEnum";
import I18n from "../../../../../core/localization/I18n";
import Pages from "../../../../../routes/InsightRoutes";
import { useCurrentOrganisationId } from "../../../../../services/local/organisationContext/OrganisationContextModel";
import { ImpactGroupsReportingModel } from "./ImpactGroupsReporting_model";

export const ImpactGroupsReporting: React.FC = observer(() => {
  const navigate = useNavigate();
  const organisationId = useCurrentOrganisationId();
  const { projectId } = useParams();
  const [model] = useState(() => new ImpactGroupsReportingModel(organisationId, +projectId));

  useEffect(() => {
    model.onMount();
    // eslint-disable-next-line
  }, []);

  if (model.isLoading) {
    return <PositionedSpinner />;
  }

  return (
    <div className="impact-group-reporting container-fluid py-5">
      <div className="row">
        <div className="col-md-12">
          <Panel.Panel hasBorder hasBorderRadius background={Panel.PanelBackgrounds.BG_LIGHT} className="p-3 mt-4">
            <div className="flex-row" style={{ flexGrow: 1 }}>
              <h3 style={{ textTransform: "uppercase" }}>{I18n.t("phrases.byImpactCount")}</h3>
            </div>
            <IF condition={model.isEmpty}>
              <p>No high level impacts for this project or the count of linked impacts is 0 for all of them.</p>
            </IF>
            <IF condition={!model.isEmpty}>
              <div
                className="impact-group-reporting__panel-height"
                style={{ maxHeight: model.barData.length > 8 ? 800 : 400, overflow: "auto" }}
              >
                <div style={{ height: model.barData.length > 8 ? model.barData.length * 50 : 400 }}>
                  <BarChart
                    data={model.barData}
                    keys={["impactCount"]}
                    indexBy={"index"}
                    layout="horizontal"
                    axisTop={{
                      tickSize: 5,
                      tickPadding: 2,
                      tickRotation: 0,
                      legend: "",
                      legendOffset: 36,
                      format: e => (Math.floor(e) === e ? e : ""),
                      truncateTickAt: 0
                    }}
                    enableGridX={true}
                    enableGridY={false}
                    leftAxisProps={{
                      renderTick: ({ opacity, textAnchor, textBaseline, textX, textY, value, x, y }) => {
                        let formattedValue = value.split("::")[1];

                        return (
                          <g
                            transform={`translate(${x},${y})`}
                            style={{ opacity, cursor: `pointer` }}
                            onClick={item => {
                              const url = `${Pages.projects.impacts.listView.generateLink(organisationId, projectId)}?${
                                ImpactColDefFieldNamesEnum.ImpactGroups
                              }=@${encodeURIComponent(formattedValue)}`;
                              navigate(url);
                            }}
                          >
                            <text
                              textAnchor={textAnchor}
                              transform={`translate(${textX},${textY + 3})`}
                              style={{
                                fontFamily: "sans-serif",
                                fontSize: "14px"
                              }}
                            >
                              {getTspanGroups(formattedValue, 100, 3, 50)}
                            </text>
                          </g>
                        );
                      }
                    }}
                    onClick={item => {
                      const url = `${Pages.projects.impacts.listView.generateLink(organisationId, projectId)}?${
                        ImpactColDefFieldNamesEnum.ImpactGroups
                      }=@${encodeURIComponent(item.data.name)}`;
                      navigate(url);
                    }}
                    colors={["#58C8D1"]}
                    bottomAxisProps={{
                      tickRotation: 0,
                      renderTick: undefined,
                      format: e => (Math.floor(e) === e ? e : "")
                    }}
                    chartMargin={{ top: 30, left: 300, right: 30, bottom: 0 }}
                    bottomAxisValueFormat={(val: string) => {
                      return {
                        value: val + "",
                        maxLineLength: 18,
                        maxLines: 3,
                        truncateAtChar: 15
                      };
                    }}
                    tooltipLabelFn={function (e: any): string {
                      return `${e.data.name} - impacts`;
                    }}
                  />
                </div>
              </div>
            </IF>
          </Panel.Panel>
        </div>
      </div>
    </div>
  );
});

const getTspanGroups = (value: string, maxLineLength: number, maxLines: number = 2, truncateAtChar?: number) => {
  const truncatedValue =
    truncateAtChar && value.length > truncateAtChar ? value.slice(0, truncateAtChar) + "..." : value;
  const words = truncatedValue.replace("/", " / ").split(" ");

  type linesAcc = {
    lines: string[];
    currLine: string;
  };

  //reduces the words into lines of maxLineLength
  const assembleLines: linesAcc = words.reduce(
    (acc: linesAcc, word: string) => {
      //if the current line isn't empty and the word + current line is larger than the allowed line size, create a new line and update current line
      if ((word + acc.currLine).length > maxLineLength && acc.currLine !== "") {
        return {
          lines: acc.lines.concat([acc.currLine]),
          currLine: word
        };
      }
      //otherwise add the word to the current line
      return {
        ...acc,
        currLine: acc.currLine + " " + word
      };
    },
    { lines: [], currLine: "" }
  );

  //add the ending state of current line (the last line) to lines
  const allLines = assembleLines.lines.concat([assembleLines.currLine]);

  const lines = allLines.slice(0, maxLines);
  let children: JSX.Element[] = [];
  let dy = 0;

  lines.forEach((lineText, i) => {
    children.push(
      <tspan x={0} dy={dy} key={i}>
        {lineText}
      </tspan>
    );
    //increment dy to render next line text below
    dy = 11;
  });

  return children;
};
