import { FilterChangedEvent, GridApi, IRowNode } from "ag-grid-community";
import { action, makeObservable, observable } from "mobx";
import { IGridAddon } from "../IGridAddon";
import LocalStorageService, {
  LocalStorageService as ILocalStorageService
} from "../../../../../../../../services/local/localStorageService/LocalStorageService";
import { EntityTypes, GridTypes } from "../../../../../../../../enums";
import { ExternalFilterStore } from "./ExternalFilterStore";
import { getFilterModelFromSearchParams } from "./FilterAddonHelpers";

export interface IFilterStoreAddonReturnProps {
  isExternalFilterPresent: () => boolean;
  doesExternalFilterPass: (row: IRowNode<any>) => boolean;
}

export interface IFilterStoreAddonProps {
  projectId: number;
  gridType: EntityTypes | GridTypes;
  filterHasChangedFn: (value: boolean) => void;
  urlSearchParams?: URLSearchParams;
}

export class FilterStoreAddon implements IGridAddon<IFilterStoreAddonReturnProps> {
  @observable hasFilters: boolean = false;
  @observable isExternalFilterPresent: () => boolean;
  @observable doesExternalFilterPass: (row: IRowNode<any>) => boolean;
  storageService: ILocalStorageService;
  gridType: EntityTypes | GridTypes;
  filterStorageKey: string;
  filterHasChangedFn: (value: boolean) => void;
  projectId: number;
  gridApi: GridApi<any>;
  urlSearchParams: URLSearchParams;
  @observable externalFilter: string;
  @observable externalFilterObj: any;
  @observable externalFilterStore: ExternalFilterStore;

  /**
   *
   */
  constructor({ projectId, gridType, filterHasChangedFn, urlSearchParams }: IFilterStoreAddonProps) {
    makeObservable(this);
    this.storageService = LocalStorageService;
    this.projectId = projectId;
    this.gridType = gridType;
    this.urlSearchParams = urlSearchParams;
    this.filterHasChangedFn = filterHasChangedFn;
    this.filterStorageKey = `projects-${this.projectId}-${this.gridType}-filters`;
    this.externalFilterStore = new ExternalFilterStore();
  }

  @action
  onGridReady = (gridApi: GridApi<any>) => {
    this.gridApi = gridApi;
    this.externalFilterStore.initialise(gridApi, this.urlSearchParams);
    this.initialiseAddon();
  };

  private initialiseAddon = () => {
    let filterModel = this.storageService.get(this.filterStorageKey);
    if (this.externalFilterStore.checkExternalFilterPresentFromUrlSearchParams()) {
      this.externalFilterStore.externalFilterChanged(this.externalFilter);
    }
    if ((this.urlSearchParams as any)?.size > 0) {
      this.gridApi.setFilterModel(getFilterModelFromSearchParams(this.urlSearchParams));
    } else if (filterModel) {
      this.gridApi.setFilterModel(JSON.parse(filterModel));
    }
    this.checkFilters();
  };

  private storeFilterChanges = (params: FilterChangedEvent) => {
    let filterModel = params.api.getFilterModel();
    if (filterModel) this.storageService.set(this.filterStorageKey, JSON.stringify(filterModel));
    this.checkFilters();
  };

  private checkFilters = () => {
    let hasFilters = this.storageService.get(this.filterStorageKey);
    if (
      (hasFilters?.length > 2 && hasFilters !== "null") ||
      this.externalFilterStore.checkExternalFilterPresentFromUrlSearchParams()
    ) {
      return this.filterHasChangedFn(true);
    }
    return this.filterHasChangedFn(false);
  };

  clearFilters = () => {
    this.storageService.remove(this.filterStorageKey);
    this.urlSearchParams = new URLSearchParams([]);
    this.gridApi.setFilterModel(null);
    this.externalFilterStore.initialise(this.gridApi, this.urlSearchParams);
    this.externalFilterStore.externalFilterChanged(null);
    this.checkFilters();
  };

  getProps = () => {
    return {
      isExternalFilterPresent: this.externalFilterStore.checkExternalFilterPresentFromUrlSearchParams,
      doesExternalFilterPass: this.externalFilterStore.externalFilterPassChecker,
      onFilterChanged: this.storeFilterChanges
    };
  };
}
