import { observer } from "mobx-react";
import * as React from "react";
import { Async } from "react-async";
import { Button } from "../../../../../../../../components/ui/Button";
import { Pill, PillTypes } from "../../../../../../../../components/ui/Pill";
import { PositionedSpinner } from "../../../../../../../../components/ui/PositionedSpinner";
import { CanEdit } from "../../../../../../../../contexts/permissions/PermissionHelpers";
import { PermissionFields } from "../../../../../../../../contexts/permissions/PermissionsTypes";
import { useFlightPathUser } from "../../../../../../../auth_insight";
import I18n from "../../../../../../../localization/I18n";
import { useModalContext } from "../../../../../../../modalZ/context/ModalContext";
import { ProgressStatus, Translator } from "../../../../../../../../enums";
import ImpactsApi from "../../../../../../../../services/api/v2/impacts/Impacts.api";
import { CommentListViewModel } from "../../../../../../../../pages/change/comments/commentListView/CommentListView_model";
import { CommentListView } from "../../../../../../../../pages/change/comments/commentListView/CommentListView_view";

export interface ImpactNotesSidebarModel {
  projectId: number;
  impactId: number;
  organisationId: number;
}

export const ImpactNotesSidebar: React.FunctionComponent<ImpactNotesSidebarModel> = observer(p => {
  let [impact, setImpact] = React.useState(null);
  const authUser = useFlightPathUser();
  const projectId = p.projectId;
  const impactId = p.impactId;
  const organisationId = p.organisationId;
  const modalService = useModalContext();
  const impactsProvider = ImpactsApi;

  const listViewConfig = React.useMemo(() => {
    return {
      searchAttribute: "impactId",
      id: -1,
      title: null,
      projectId: projectId,
      organisationId: organisationId,
      placeholderText: I18n.t("placeholders.impactNotePlaceholder")
    };
  }, [projectId, organisationId]);

  const commentViewModel = React.useMemo(
    () => new CommentListViewModel(projectId, authUser, listViewConfig),
    [projectId, authUser, listViewConfig]
  );

  const load = React.useCallback(async () => {
    const res = await impactsProvider.getDetailedById(organisationId, projectId, impactId);

    if (!res || res.isError) {
      return;
    }

    setImpact(res.payload);
    commentViewModel.setConfig({
      id: res.payload.id
    });
  }, [impactId, projectId, organisationId, commentViewModel, impactsProvider]);
  let pillType = impact?.progressStatus === ProgressStatus.NOT_STARTED ? PillTypes.LIGHT_GRAY : PillTypes.GRAY;
  return (
    <Async promiseFn={load}>
      <Async.Loading>
        <PositionedSpinner />
      </Async.Loading>
      <Async.Resolved>
        {() => (
          <div className="container-fluid impact-compact-view">
            <div className="row mb-6">
              <div className="col">
                <div className="d-flex align-items-center flex-row-reverse">
                  <Pill type={pillType}>{Translator.ImpactProgressStatus(impact.progressStatus)}</Pill>
                </div>
              </div>
            </div>
            <CanEdit projectId={projectId} field={PermissionFields.IMPACTS}>
              <div className="row mb-0">
                <div className="col">
                  <h3 className="mb-0 pl-0 pb-0">{I18n.t("phrases.addANote")}</h3>
                </div>
              </div>
            </CanEdit>
            <CommentListView model={commentViewModel} field={PermissionFields.IMPACTS} />
            <div className="row mb-5">
              <div className="col">
                <div className="d-flex">
                  <Button
                    className="ml-auto"
                    onClick={() => {
                      modalService.hide();
                    }}
                  >
                    {I18n.t("phrases.done")}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
      </Async.Resolved>
    </Async>
  );
});
