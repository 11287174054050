import { action, makeObservable, observable } from "mobx";
import { SHOW_PROCESS_GRID_FILTER_MODAL, SHOW_PROCESS_TREE_FILTER_MODAL } from "./ProcessReportingView_modals";
import { GridModalBuilder } from "../../../../../core/grids/builder/directorBuilder/modals/GridModal_builder";
import ModalContext from "../../../../../core/modalZ/context/ModalContext";
import LocalStorageService from "../../../../../services/local/localStorageService/LocalStorageService";
import { ILocalStorageService } from "../../../../../services/local/localStorageService/ILocalStorageService";
import { GridTypes } from "../../../../../enums";

export class ProcessReportingViewModel {
  organisationId: number;
  projectId: number;
  gridModalBuilder: GridModalBuilder;
  @observable isLoading: boolean = true;
  @observable filterSelectedItemIds: number[];
  localStorageService: ILocalStorageService;
  gridType: GridTypes;

  /**
   *
   */
  constructor(organisationId: number, projectId: number, gridType: GridTypes) {
    makeObservable(this);
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.gridModalBuilder = new GridModalBuilder();
    this.localStorageService = LocalStorageService;
    this.gridType = gridType;
    this.initFilters();
  }

  onMount = async () => {};

  onUnmount = () => {};

  initFilters = () => {
    let selectedItemsFromLocalStorage = this.localStorageService.get(
      this.getProjectProcessesReportingFiltersLocalStorageKey()
    );

    if (!selectedItemsFromLocalStorage) return;
    let items = selectedItemsFromLocalStorage.split(",").map(e => parseInt(e));
    this.setFilterSelectedItemIds(items);
  };

  getProjectProcessesReportingFiltersLocalStorageKey = () => {
    return `${this.gridType}-filter-selected-items-${this.projectId}`;
  };

  handleBasicFilterClick = async () => {
    this.gridModalBuilder
      .constructSideModal()
      .setModalOptions(SHOW_PROCESS_TREE_FILTER_MODAL(this.setFilterSelectedItemIds, this.filterSelectedItemIds))
      .generateModal();
  };

  handleAdvancedFilterClick = async () => {
    this.gridModalBuilder
      .constructPopupModal()
      .setModalOptions(SHOW_PROCESS_GRID_FILTER_MODAL(this.setFilterSelectedItemIds, this.filterSelectedItemIds))
      .generateModal();
  };

  @action
  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };

  @action
  setFilterSelectedItemIds = (filterSelectedItemIds: any[]) => {
    this.filterSelectedItemIds = filterSelectedItemIds;
    if (filterSelectedItemIds) {
      this.localStorageService.set(
        this.getProjectProcessesReportingFiltersLocalStorageKey(),
        filterSelectedItemIds?.join(",")
      );
    } else {
      this.localStorageService.remove(this.getProjectProcessesReportingFiltersLocalStorageKey());
    }
    ModalContext.hide();
  };

  clearBasicFilterSelectedItemIds = () => {
    this.setFilterSelectedItemIds(null);
    this.localStorageService.remove(this.getProjectProcessesReportingFiltersLocalStorageKey());
  };

  clearFilters = () => {
    this.setFilterSelectedItemIds(null);
    this.setFilterSelectedItemIds(null);
    this.localStorageService.remove(this.getProjectProcessesReportingFiltersLocalStorageKey());
  };
}
