import * as React from "react";
import { IAutocompleteModel } from "../../../../core/forms/controls/autocomplete/IAutocompleteModel";
import { INIT_AUTOCOMPLETE } from "../../../../core/forms/controls/autocomplete/Autocomplete_init";
import I18n from "../../../../core/localization/I18n";
import { AutocompleteOption } from "../../../../components/ui/_forms/Autocomplete/AutocompleteOption";
import { generateFormFieldsFromJson } from "../../../../core/forms/helpers/FormFieldMappers";
import { INIT_TEXT_FIELD } from "../../../../core/forms/controls/textField/TextField_init";
import _ from "lodash";
import { convertStakeholderToName } from "../../../../core/util/Helpers";
import { IconSymbols } from "../../../../components/ui/Icon";
import { OrganisationsApi } from "../../../../services/api/v2/organisations/Organisations.api";
import { BusinessAreasApi } from "../../../../services/api/v2/businessAreas/BusinessAreas.api";

export const GetStakeholderBulkBusinessAreaFields = (
  stakeholders: FP.Entities.IStakeholder[],
  businessAreaProvider: BusinessAreasApi,
  organisationProvider: OrganisationsApi,
  organisationId: number,
  deletedBusinessArea: FP.Entities.IBusinessArea
) => {
  let fields = stakeholders.map(stakeholder => {
    let stakeholderFields = [];

    let label = {
      ...INIT_TEXT_FIELD,
      key: "label-" + stakeholder.id,
      inputType: "hidden",
      label: <label htmlFor={"industry"}>{convertStakeholderToName(stakeholder)}</label>,
      fieldClassName: "col-4 d-flex align-items-center",
      value: stakeholder.id
    };

    const businessArea: Partial<IAutocompleteModel> = {
      ...INIT_AUTOCOMPLETE,
      key: "BusinessAreaId-" + stakeholder.id,
      placeholder: I18n.t("placeholders.searchBusinessArea"),
      optionElement: <AutocompleteOption key={"e"} className={"autocomplete__chip"} label={e => e.name} />,
      onFocus: async function () {
        let self: IAutocompleteModel = this;
        this.componentProps.disabled = false;
        const res = await organisationProvider.getBusinessAreas(organisationId);

        if (res?.payload) {
          const filteredBusinessAreas = res.payload.filter(r => {
            let isDeletedChild = Boolean(
              (deletedBusinessArea as any).children &&
                ((deletedBusinessArea as any).children as FP.Entities.IBusinessArea[]).find(child => r.id === child.id)
            );
            return r.id !== deletedBusinessArea.id && !isDeletedChild;
          });
          const sortedBusinessAreas = _.orderBy(filteredBusinessAreas, [
            businessArea => businessArea.name.toLowerCase()
          ]);
          self.setOptions(sortedBusinessAreas);
        }
      },
      componentProps: {
        className: "form-control",
        icon: IconSymbols.Search
      },
      extractValue: function () {
        return this.value?.id;
      },
      searchAttribute: "name",
      fieldClassName: "col-4",
      value: null
    };

    const stakeholderRole: Partial<IAutocompleteModel> = {
      ...INIT_AUTOCOMPLETE,
      key: "roleId-" + stakeholder.id,
      placeholder: "None assigned",
      // placeholder: I18n.t("placeholders.searchRole"),
      optionElement: <AutocompleteOption key={"e"} className={"autocomplete__chip"} label={e => e.name} />,
      subscribeTo: ["BusinessAreaId-" + stakeholder.id],
      onFocus: async function () {
        let self: IAutocompleteModel = this;
        let field = self.channels["BusinessAreaId-" + stakeholder.id];
        let businessAreaId: number = field.extractValue();
        if (businessAreaId) {
          const res = await businessAreaProvider.getRoles(organisationId, businessAreaId);

          if (res?.payload) {
            this.componentProps.disabled = false;
            const sortedRoles = _.orderBy(res.payload, [role => role.name.toLowerCase()]);
            this.setOptions(sortedRoles);
          }
        } else {
          self.setOptions([]);
          self.disabled = true;
        }
      },
      onChannelFieldChanged: async function (field: IAutocompleteModel) {
        let businessArea: FP.Entities.IBusinessArea = field.value as FP.Entities.IBusinessArea;
        let self: IAutocompleteModel = this;
        let res = await businessAreaProvider.getRoles(organisationId, businessArea.id);
        if (!res || res.isError) {
          return;
        }
        this.componentProps.disabled = false;
        let roles = res.payload;
        self.setOptions(roles);
        this.placeholder = "Search for job role";
      },
      extractValue: function () {
        return this.value?.id;
      },
      componentProps: {
        className: "form-control",
        // disabled: (stakeholder?.businessArea) ? false : true,
        icon: IconSymbols.Search
      },
      searchAttribute: "name",
      fieldClassName: "col-4",
      value: null
    };

    stakeholderFields.push(label);
    stakeholderFields.push(businessArea);
    stakeholderFields.push(stakeholderRole);

    return stakeholderFields;
  });

  const models = generateFormFieldsFromJson(_.flatten(fields));
  return models;
};
