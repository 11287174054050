import { IAutocompleteModel } from "../../../../../core/forms/controls/autocomplete/IAutocompleteModel";
import { INIT_AUTOCOMPLETE } from "../../../../../core/forms/controls/autocomplete/Autocomplete_init";
import I18n from "../../../../../core/localization/I18n";
import { AutocompleteOption } from "../../../../../components/ui/_forms/Autocomplete/AutocompleteOption";
import { Validations } from "../../../../../core/forms/helpers/Validations";
import { ErrorMessage } from "../../../../../components/ui/ErrorMessage";
import * as _ from "lodash";
import { generateFormFieldsFromJson } from "../../../../../core/forms/helpers/FormFieldMappers";
import { FORM_COL, PERMISSION_SCOPE_OPTIONS } from "../../../../../constants";
import { INIT_TEXT_FIELD } from "../../../../../core/forms/controls/textField/TextField_init";
import { AutocompletePerson } from "../../../../../components/ui/AutocompletePersonOption";
import { IconSymbols } from "../../../../../components/ui/Icon";
import { OrganisationsApi } from "../../../../../services/api/v2/organisations/Organisations.api";

export const GetProgrammePermissionFields = (organisationId: number, progId: number, orgProvider: OrganisationsApi) => {
  const programmeId = {
    ...INIT_TEXT_FIELD,
    key: "programmeId",
    inputType: "hidden",
    value: progId,
    defaultValue: progId
  };

  const user: Partial<IAutocompleteModel> = {
    ...INIT_AUTOCOMPLETE,
    key: "systemUserId",
    label: <label htmlFor="systemUserId">{I18n.t("forms.user")} *</label>,
    placeholder: I18n.t("placeholders.searchUser"),
    optionElement: (
      <AutocompleteOption key="e" className="autocomplete__chip" label={e => <AutocompletePerson {...e} />} />
    ),
    componentProps: {
      className: "form-control",
      icon: IconSymbols.User
    },
    charInputNumber: 1,
    validate: function () {
      const self: IAutocompleteModel = this;
      let res = true;
      if (Validations.IS_EMPTY(self.extractValue())) {
        self.errorMessage = <ErrorMessage>{I18n.t("validations.searchUsers")}</ErrorMessage>;
        res = false;
      }
      return res;
    },
    fieldClassName: FORM_COL.FULL_WIDTH,
    onFocus: async function () {
      // TODO: in future assignees will be loaded through
      // the project base on their permission level
      const self: IAutocompleteModel = this;
      if (progId && self.options.length === 0) {
        const res = await orgProvider.getUsers(organisationId);

        if (res?.payload) {
          const sortedUsers = _.orderBy(res.payload, [
            user => user.firstName.toLowerCase(),
            user => user.lastName.toLowerCase()
          ]);
          self.setOptions(sortedUsers);
        }
      }
    },
    extractValue: function () {
      return this.value && this.value.id;
    },
    valueLabelFn: e => e.firstName + " " + e.lastName,
    filterFn: (items, query) => {
      const lowerQuery = query.toLowerCase();
      return _.filter(items, (item: FP.Entities.IUser) => {
        const lowerName = `${item.firstName} ${item.lastName}`.toLowerCase();
        const lowerEmail = item.email.toLowerCase();
        return lowerName.indexOf(lowerQuery) > -1 || lowerEmail.indexOf(lowerQuery) > -1;
      });
    }
    // tooltipLabel: <FormTooltip>
    //     <p className="mb-0">{I18n.t("phrases.actionAssigneeDesc")}</p>
    // </FormTooltip>
  };

  const permissionScope: Partial<IAutocompleteModel> = {
    ...INIT_AUTOCOMPLETE,
    key: "permissionScope",
    label: <label htmlFor="permissionScope">{I18n.t("forms.permissionScope")} *</label>,
    placeholder: I18n.t("placeholders.permissionScope"),
    fieldClassName: FORM_COL.FULL_WIDTH,
    componentProps: {
      className: "form-control"
    },
    options: PERMISSION_SCOPE_OPTIONS.filter(e => e.isAssignable).map(option => ({
      ...option,
      label: I18n.t(option.label)
    })),
    isLoading: false,
    valueLabelFn: obj => obj.label,
    validate: function () {
      const self: IAutocompleteModel = this;
      if (Validations.IS_EMPTY(self.extractValue())) {
        self.errorMessage = <ErrorMessage>{I18n.t("validations.permissionScope")}</ErrorMessage>;
        return false;
      }
      return true;
    },
    extractValue: function () {
      return this.value?.key;
    }
  };

  const fields = [];

  fields.push(programmeId);
  fields.push(user);
  fields.push(permissionScope);

  const models = generateFormFieldsFromJson(fields);

  return models;
};
