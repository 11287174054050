import { isEmpty } from "lodash";
import { IFilterAttribute } from "../../../core/filter/Filter_model";
import { ILocalStorageService } from "../localStorageService/ILocalStorageService";
import { ILocalStorageFilterService } from "./ILocalStorageFilterService";
import LocalStorageService from "../localStorageService/LocalStorageService";

class LocalStorageFilterService implements ILocalStorageFilterService {
  localStorageService: ILocalStorageService;
  localStorageName: string;
  hasStoredFilters: boolean;

  constructor() {
    this.localStorageService = LocalStorageService;
  }

  setLocalStorageName = (name: string) => {
    this.localStorageName = name;
    this.hasStoredFilters = this.checkHasStoredFilters();
  };

  getFromLocalStorage = (): any => {
    if (isEmpty(this.localStorageName)) return;

    var existingFilters = this.getLocallyStoredFilters();
    if (!existingFilters) return;

    // Remove stored filters if they're empty
    if (this.locallyStoredFiltersHaveValues(existingFilters) === false) {
      this.removeLocallyStoredFilters();
      return;
    }

    this.hasStoredFilters = true;
    return existingFilters;
  };

  addToLocalStorage = (filterObj: IFilterAttribute): boolean => {
    if (isEmpty(this.localStorageName)) return false;

    if (!isEmpty(filterObj)) {
      let values = [];

      if (filterObj.localStorageValue !== undefined) {
        values = filterObj.localStorageValue(filterObj.value);
      } else {
        values = filterObj.value;
      }

      var existingFilters = this.getLocallyStoredFilters();

      if (existingFilters !== null) {
        delete existingFilters[filterObj.key];
      } else {
        existingFilters = {};
      }

      existingFilters[filterObj.key] = values;

      // Remove stored filters if they're empty
      if (this.locallyStoredFiltersHaveValues(existingFilters) === false) {
        this.removeLocallyStoredFilters();
        return;
      }

      let jsonPropertyData = JSON.stringify(existingFilters);

      this.localStorageService.set(this.localStorageName, jsonPropertyData);
      this.hasStoredFilters = true;
    }

    return this.hasStoredFilters;
  };

  removeLocallyStoredFilters = (): boolean => {
    this.localStorageService.remove(this.localStorageName);
    return false;
  };

  checkHasStoredFilters = (): boolean => {
    if (isEmpty(this.localStorageName)) return false;
    return this.locallyStoredFiltersHaveValues(this.getLocallyStoredFilters());
  };

  getLocallyStoredFilters = () => {
    if (isEmpty(this.localStorageName)) return null;

    const localStorageSavedFilters = this.localStorageService.get(this.localStorageName);
    if (isEmpty(localStorageSavedFilters)) return null;

    const existingFilters = JSON.parse(localStorageSavedFilters);
    return existingFilters;
  };

  locallyStoredFiltersHaveValues = (storedObject: any): boolean => {
    var result = false;

    if (!isEmpty(storedObject) && Object.keys(storedObject).length > 0) {
      for (var prop in storedObject) {
        if (storedObject[prop].length > 0) {
          result = true;
        }
      }
    }

    return result;
  };
}
const instance = new LocalStorageFilterService();
export default instance;
