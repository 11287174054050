import * as React from "react";
import { Panel } from "../../../../components/ui/Panel";
import { ButtonTypes, LinkButton } from "../../../../components/ui/Button";
import { Enums } from "../../../../enums";
import I18n from "../../../../core/localization/I18n";
import { Icon, IconSymbols } from "../../../../components/ui/Icon";
import { Animations } from "../../../../core/util/Animations";
import { useState } from "react";

interface IHelpNavigationProps {
  currentOrganisationName: string;
  currentOrganisationId: number;
  className?: string;
}

const HelpNavigation: React.FunctionComponent<IHelpNavigationProps> = props => {
  const [isTooltipContentShown, setIsTooltipContentShown] = useState(false);

  return (
    <div
      className={`navigation-view ${props.className ? props.className : ""} ${
        isTooltipContentShown ? "navigation-view--display-tooltip" : ""
      }`}
      tabIndex={0}
      onBlur={() => setTimeout(() => setIsTooltipContentShown(false), 300)}
    >
      <div className="navigation-view__label-tooltip">
        <Panel.Panel
          hasBorderRadius={true}
          background={Panel.PanelBackgrounds.BG_WHITE}
          hasShadow={true}
          className={`p-2 ${Animations.FADE_IN} speed-3`}
        >
          <p className="mb-0 text-dark">{I18n.t("phrases.help")}</p>
        </Panel.Panel>
      </div>

      <div
        className={`btn d-flex btn--link btn--sm btn btn--icon btn--shape-rectangle navigation__action navigation-view__action ${Animations.ZOOM_IN} speed-3`}
        onClick={() => setIsTooltipContentShown(!isTooltipContentShown)}
      >
        <Icon symbol={IconSymbols.Help} size={Enums.UiSizes.MD} />
      </div>

      <div>
        {isTooltipContentShown && (
          <Panel.Panel
            hasBorderRadius={true}
            hasShadow={true}
            background={Panel.PanelBackgrounds.BG_WHITE}
            className={`p-2 ml-3 ${Animations.FADE_IN} speed-3 d-flex flex-column navigation-view__tooltip-content`}
            style={{ width: "160px" }}
          >
            <LinkButton
              externalUrl="https://help.flightpath.it/"
              id="IntercomChatTrigger"
              type={ButtonTypes.LINK}
              size={Enums.UiSizes.XS}
              target="_blank"
            >
              {I18n.t("phrases.helpCentre")}
            </LinkButton>
          </Panel.Panel>
        )}
      </div>
    </div>
  );
};

export default HelpNavigation;
