import * as React from 'react';
import { generateFormFieldsFromJson } from '../../../../core/forms/helpers/FormFieldMappers';
import { Validations } from '../../../../core/forms/helpers/Validations';
import { ErrorMessage } from '../../../../components/ui/ErrorMessage';
import { IRTEditorModel } from '../../../../core/forms/controls/rteditor/IRTEditorModel';
import { INIT_RTEDITOR } from '../../../../core/forms/controls/rteditor/RTEditor_model';
import { IDropdownModel } from '../../../../core/forms/controls/dropdown/IDropdownModel';
import I18n from '../../../../core/localization/I18n';
import { IAutocompleteModel } from '../../../../core/forms/controls/autocomplete/IAutocompleteModel';
import { INIT_AUTOCOMPLETE } from '../../../../core/forms/controls/autocomplete/Autocomplete_init';
import { FORM_COL, KEY_CONCERN_STATUS_OPTIONS } from '../../../../constants';

export const getConcernFormFields = (concern?: FP.Entities.IConcern) => {
    const summary: Partial<IRTEditorModel> = {
        ...INIT_RTEDITOR,
        key: "summary",
        label: <label htmlFor="summary">{I18n.t("forms.keyConcernSummary")}</label>,
        validate: function () {
            const self: IRTEditorModel = this;
            let res = true;
            if (Validations.IS_EMPTY(self.value)) {
                self.errorMessage = <ErrorMessage>{I18n.t("validations.keyConcernSummary")}</ErrorMessage>
                res = false;
            }
            return res;
        },
        fieldClassName: FORM_COL.FULL_WIDTH,
        value: concern?.summary
    }

    const status: Partial<IAutocompleteModel> = {
        ...INIT_AUTOCOMPLETE,
        key: "status",
        label: (<label htmlFor={"status"}>{I18n.t("forms.keyConcernStatus")}</label>),
        placeholder: I18n.t("placeholders.keyConcernStatus"),
        options: KEY_CONCERN_STATUS_OPTIONS.map(o => ({ ...o, label: I18n.t(o.label) })),
        isLoading: false,
        valueLabelFn: (obj) => obj.label,
        validate: function () {
            const self: IDropdownModel = this;
            let res = true;
            if (Validations.IS_EMPTY(self.extractValue())) {
                self.errorMessage = <ErrorMessage>{I18n.t("validations.keyConcernStatus")}</ErrorMessage>
                res = false;
            }
            return res;
        },
        componentProps: {
            className: "form-control"
        },
        fieldClassName: FORM_COL.FULL_WIDTH,
        extractValue: function () {
            return this.value?.key
        },
        value: KEY_CONCERN_STATUS_OPTIONS.map(o => ({ ...o, label: I18n.t(o.label) })).find(e => e.key === concern?.status)
    }

    const fields = [
        summary,
        status
    ];

    const models = generateFormFieldsFromJson(fields);

    return models;
}