import { action } from "mobx";
import { Icon, IconSymbols } from "../../../../../components/ui/Icon";
import { Panel } from "../../../../../components/ui/Panel";
import ProgressIndicatorModel, {
  ProgressIndicatorModel as IProgressIndicatorModel
} from "../../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import I18n from "../../../../../core/localization/I18n";
import { Enums } from "../../../../../enums";
import ImpactsApi, { ImpactsApi as IImpactsApi } from "../../../../../services/api/v2/impacts/Impacts.api";
import { SingleFormModel } from "../../../forms/singleFormModel/SingleForm_model";
import { getImpactMicroFormFields } from "./MicroGroupImpactsForm_fields";
import { IModalContextModel } from "../../../../../core/modalZ/context/IModalContext";
import ModalContext from "../../../../../core/modalZ/context/ModalContext";

export class MicroCreateImpactViewModel {
  impactGroupId: number;
  httpProgress: IProgressIndicatorModel;
  impactProvider: IImpactsApi;
  orgId: number;
  projectId: number;
  impactForm: SingleFormModel;
  modalService: IModalContextModel;
  impact: FP.Entities.IImpact = null;

  /**
   *
   */
  constructor(organisationId: number, impactGroupId: number, projectId: number, impact?: FP.Entities.IImpact) {
    this.impactGroupId = impactGroupId;
    this.httpProgress = ProgressIndicatorModel;
    this.impactProvider = ImpactsApi;
    this.modalService = ModalContext;
    this.orgId = organisationId;
    this.impactGroupId = impactGroupId;
    this.projectId = projectId;
    this.setForms(projectId);
    this.impact = impact || this.impact;
  }

  @action
  setForms = (projectId: number) => {
    this.projectId = projectId;

    this.impactForm = new SingleFormModel();
    this.impactForm.setShouldHideToast(true);
    this.impactForm.formFields = getImpactMicroFormFields(this.orgId);
  };

  onMount = () => {};

  onUnmount = () => {};

  createImpact = async () => {
    let impactFormRes = await this.impactForm.submit();
    this.impactForm.isSaving = true;
    if (!impactFormRes) return;
    impactFormRes = { ...impactFormRes, projectId: this.projectId, tags: [], impactGroups: [this.impactGroupId] };
    this.httpProgress.showOverlay();
    let impactExists = await this.impactProvider.getFiltered(this.orgId, this.projectId, {
      filters: `name==${impactFormRes.name},lifecycleStatus==${Enums.LifecycleStatus.Active},projectId==${this.projectId}`
    });
    if (impactExists && !impactExists.isError && impactExists.payload.length) {
      this.httpProgress.hideOverlay();
      let confirmCreateImpact = await this.confirmCreateImpact(impactFormRes.name);
      if (!confirmCreateImpact) return;
      this.httpProgress.showOverlay();
    }

    const res = {
      ...impactFormRes
    };
    this.httpProgress.showOverlay();
    const result = await this.impactProvider.create(this.orgId, this.projectId, res as FP.Entities.IImpact);
    this.httpProgress.hideOverlay();

    if (!result || result.isError) return;

    const impact = result.payload;
    this.impactForm.resetFields();
    return impact;
  };

  confirmCreateImpact = async (name: string): Promise<boolean> => {
    return new Promise(async resolve => {
      await this.modalService.showConfirmDialog(
        <h1 className="mt-4">{I18n.t("phrases.confirm")}</h1>,
        <div className="container-fluid">
          <div className="row mb-3">
            <div className="col-12">
              <Icon symbol={IconSymbols.AlertCircle} className="mr-2" />
              {I18n.t("warnings.createSameNameImpact")}
            </div>
          </div>
          <div className="row">
            <div className="col">{I18n.t("warnings.confirmSameName", { name: name })}</div>
          </div>
        </div>,
        I18n.t("phrases.yes"),
        I18n.t("phrases.no"),
        {
          wrapWidth: "small",
          spacing: "small",
          position: "middle",
          panelProps: {
            background: Panel.PanelBackgrounds.BG_WHITE
          }
        },
        async () => {
          this.modalService.hide();
          resolve(true);
        },
        () => {
          this.modalService.hide();
          resolve(false);
        }
      );
    });
  };
}
