import { action, makeObservable, observable } from "mobx";

import { BaseModel } from "../../../../../../../core/util/BaseModel";
import UsersApi, { UsersApi as IUsersApi } from "../../../../../../../services/api/v2/users/Users.api";
import { PieDataObject } from "../../../Heatmap/Heatmap_utils";

export class ProjectTeamCountSidebarModel extends BaseModel {
  userProvider: IUsersApi;
  @observable isLoading = true;
  @observable.ref drillDownPieData: PieDataObject[];
  userId: number;
  organisationId: number;
  pieColors: string[];

  constructor(organisationId: number, userId: number) {
    super();
    makeObservable(this);
    this.userProvider = UsersApi;
    this.userId = userId;
    this.organisationId = organisationId;
    this.pieColors = [
      "#fdd886",
      "#ffc036",
      "#feae95",
      "#f49d4e",
      "#dc9700",
      "#f7820d",
      "#cc660a",
      "#966700",
      "#ed7650",
      "#ac5337",
      "#8a4100",
      "#452015"
    ];
  }

  onMount = async () => {
    await this.loadPieData();
  };

  loadPieData = async () => {
    const stakeholderProjects = await this.userProvider.getTeamProjectsByUserId(this.organisationId, this.userId);

    const res = stakeholderProjects.payload.map(stkProj => {
      return {
        id: stkProj.id,
        label: stkProj.label,
        value: stkProj.value,
        color: ""
      };
    });

    this.setModalPieData(res);
    return true;
  };

  getPieColors = () => {
    const result = [...this.pieColors];
    const zeroIndexHasValue = this.drillDownPieData[0].value > 0;
    const zeroIndexIsNoAccessProject = this.drillDownPieData[0].label === "Projects with no access";
    if (zeroIndexHasValue && zeroIndexIsNoAccessProject) result.unshift("#E5E5E5");
    return result;
  };

  @action
  setModalPieData = (data: PieDataObject[]) => {
    this.drillDownPieData = data;
    this.isLoading = false;
  };

  getNavigateUrl = () => {};
}
