import { gridSideModalAnimationOpts, gridSideModalComponentProps } from "../../../grids/GridSideModals";
import ModalContext from "../../context/ModalContext";
import { LocationsSidebar } from "./locationsSidebar/LocationsSidebar_view";

export const SHOW_LOCATIONS_TREE = (updateLocations: (businessAreas: number[]) => void, currentIds: number[]) => {
  ModalContext.show({
    showClose: true,
    title: (
      <div className="mt-6">
        <h3 className="mb-5">Select Locations</h3>
      </div>
    ),
    content: <LocationsSidebar onSubmit={updateLocations} currentIds={currentIds} />,
    componentProps: gridSideModalComponentProps,
    animationOptions: gridSideModalAnimationOpts
  });
};
