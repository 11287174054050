import { ColDef } from "ag-grid-community";
import { Enums } from "../../../../../../../enums";
import {
  colActionCount,
  colBusinessArea,
  colFirstName,
  colImpactCount,
  colLastName,
  colName,
  colRole
} from "./StakeholderCapacityGrid_Grid_Config";

export const GetStakeholderCapacityGridViewColumns = (stakeholderType: number): ColDef[] => {
  let result: ColDef[] = [];
  if (stakeholderType === Enums.StakeholderType.INDIVIDUAL) {
    result.push(colFirstName());
    result.push(colLastName());
    result.push(colBusinessArea());
    result.push(colRole());
  } else {
    result.push(colName());
  }
  result.push(colImpactCount(null, stakeholderType));
  result.push(colActionCount(null, stakeholderType));

  return result;
};
