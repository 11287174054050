import { ISmartTableHeaderItem } from '@flightpath/coreui/dist/widgets/smartTable/ISmartTable';
import React from 'react';
import { useIsOrganisationAdmin } from '../../../../../contexts/permissions/PermissionHooks';
import I18n from '../../../../../core/localization/I18n';
import { UiSizes } from '../../../../../enums';
import { AddSmartTableColumnSort } from '../../../../../services/local/smartTableViewManager/utils';
import { TagSettingsModel } from './TagSettings_model';
import { GetTagTableActions } from './TagTableActions';

export const GetTagsSmartTableConfig = (parentModel: TagSettingsModel) => {
    const isAdmin = useIsOrganisationAdmin(parentModel.organisationId);

    let tableConfig = {
        actionsFn: GetTagTableActions(parentModel),
        colHeaders: [
            {
                id: "name",
                content: <h5 className="mb-0">{I18n.t("table.name")}</h5>,
                action: function (model: ISmartTableHeaderItem) {
                    AddSmartTableColumnSort(model, parentModel.filterModel);
                },
                selector: obj => {
                    return (
                        <>
                            <p className="mb-0">{obj.text}</p>
                        </>
                    );
                },
                showSortIcons: true,
                columnWidthSize: UiSizes.MD
            },
            {
                id: "count",
                content: <h5 className="mb-0">{I18n.t("table.timesUsed")}</h5>,
                action: function (model: ISmartTableHeaderItem) {
                    AddSmartTableColumnSort(model, parentModel.filterModel, "usageCount");
                },
                selector: obj => {
                    return (
                        <>
                            <p className="mb-0">{obj.count}</p>
                        </>
                    );
                },
                showSortIcons: true,
                columnWidthSize: UiSizes.MD
            },
        ] as any[],
        initData: null,
        tableProps: {
            isSticky: true,
            headerProps: {
                style: {
                    top: "58px"
                }
            }
        },
        onRowClick: row => isAdmin ? parentModel.showTagModal(row.content, "view") : null
    };


    return tableConfig;
};
