import { Navigate, useParams } from "react-router-dom";
import Pages from "./InsightRoutes";
import { useCurrentOrganisationId } from "../services/local/organisationContext/OrganisationContextModel";

export const NavigateToCurrentOrg: React.FC<any> = () => {
  const organisationId = useCurrentOrganisationId();
  return <Navigate to={Pages.baseRoute.generateLink(organisationId)} />;
};

export const NavigateToCurrentHeatmap: React.FC<any> = () => {
  const organisationId = useCurrentOrganisationId();
  return <Navigate to={Pages.organisations.visualisations.heatmap.generateLink(organisationId, "business-areas")} />;
};

export const NavigateToCurrentMyDashboard: React.FC<any> = () => {
  let { organisationId } = useParams();
  return <Navigate to={Pages.dashboards.index.generateLink(organisationId)} />;
};
