import _ from "lodash";
import React from "react";
import { ReactCheckbox } from "../../../../../../components/ui/_forms/Checkbox";
import { TIMELINE_PROGRESS_STATUS_OPTIONS } from "../../../../../../constants";
import I18n from "../../../../../../core/localization/I18n";
import { ProgressStatus } from "../../../../../../enums";
import { ReportingTitle } from "../TopActionsReportingBar/ReportingTitle";

interface ActionTimelineHeaderProps {
  actionTypes: FP.Generic.IIdName[];
  toggleStatusFilterFn: (e) => void;
  setTypeFilterFn: (e: FP.Generic.IIdName) => void;
  typeFilter: FP.Generic.IIdName;
}

export const ActionTimelineHeader: React.FC<ActionTimelineHeaderProps> = ({
  actionTypes,
  toggleStatusFilterFn,
  setTypeFilterFn,
  typeFilter
}) => {
  return (
    <div className="container-fluid mb-3 pt-2">
      <ReportingTitle title={I18n.t("visualisations.actionPlan")} />
      <div className="d-flex justify-content-between">
        <div>
          {actionTypes.map(e => {
            return (
              <div
                key={e.name}
                onClick={() => setTypeFilterFn(e)}
                className={`action-timeline__action-type d-inline-block mr-2 ${
                  e === _.last(actionTypes) ? "" : "border-right"
                }`}
              >
                <span className={`pr-2 ${e.id === typeFilter?.id ? "action-timeline__active-type" : ""}`}>
                  {e.name}
                </span>
              </div>
            );
          })}
        </div>
        <div className="d-flex">
          {TIMELINE_PROGRESS_STATUS_OPTIONS.map(e => (
            <div key={e.key} className="d-flex ml-2">
              <ReactCheckbox
                className={`d-inline action-reporting-checkbox__${getCheckboxClassname(e.key)}`}
                defaultChecked
                onClick={() => toggleStatusFilterFn(e.key)}
              />
              <h4 className="mb-0 pl-1 d-inline">{I18n.t(e.label)}</h4>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const getCheckboxClassname = (status: ProgressStatus): string => {
  switch (status) {
    case ProgressStatus.COMPLETED:
      return "complete";
    case ProgressStatus.IN_PROGRESS:
      return "in-progress";
    case ProgressStatus.NOT_STARTED:
      return "not-started";
    default:
      return "overdue";
  }
};
