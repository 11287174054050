import I18n from "../../../../../../../localization/I18n";
import ModalContext from "../../../../../../../modalZ/context/ModalContext";
import { GridSideModalTitle } from "../../../../utils/GridModalUtils";
import { ImpactTagsSidebarModel } from "./ImpactTagsSidebar_model";
import { ImpactTagsSidebar } from "./ImpactTagsSidebar_view";

export const IMPACT_TAGS_SIDEBAR_MODAL_CONFIG = (
  organisationId: number,
  projectId: number,
  impact: FP.Entities.IImpact
) => {
  return {
    showClose: true,
    title: <GridSideModalTitle name={impact.name} field={I18n.t("phrases.tags")} />,
    content: (
      <ImpactTagsSidebar model={new ImpactTagsSidebarModel(ModalContext.hide, organisationId, projectId, impact.id)} />
    )
  };
};
