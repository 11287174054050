import React from "react";
import { PositionedSpinner } from "../../../../../../components/ui/PositionedSpinner";
import I18n from "../../../../../../core/localization/I18n";
import { ReportingCard } from "../../../../projects/Actions/ActionReportingView/TopActionsReportingBar/ReportingCard";
import { ReportingTitle } from "../../../../projects/Actions/ActionReportingView/TopActionsReportingBar/ReportingTitle";

export const TotalsCard = ({ programmeCount, isLoading }) => {
  return (
    <div className="col-4">
      {isLoading && <PositionedSpinner />}
      {!isLoading && (
        <ReportingCard className="px-3" style={{ minHeight: "200px" }}>
          <div className="d-flex action_reporting__Card-info mb-1 flex-column">
            <ReportingTitle title={I18n.t("phrases.totalProgrammes")} className="pt-3" />
            <div className="heatmap-support__total__value-container">
              <div>
                <p className="heatmap-support__total__value">{programmeCount}</p>
              </div>
            </div>
          </div>
        </ReportingCard>
      )}
    </div>
  );
};
