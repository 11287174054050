import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { Button } from "../../../../../../components/ui/Button";
import { PositionedSpinner } from "../../../../../../components/ui/PositionedSpinner";
import I18n from "../../../../../../core/localization/I18n";
import { SingleForm } from "../../../../forms/singleFormModel/SingleForm_view";
import { TagSettingsEditModalModel } from "./TagSettingsEditModal_model";

interface TagSettingsModalViewProps {
  model: TagSettingsEditModalModel;
}

export const TagSettingsEditModalView: React.FC<TagSettingsModalViewProps> = observer(({ model }) => {
  const tag = model.tag;

  useEffect(() => {
    model.onMount();
  }, [model]);

  if (model.isLoading) {
    return <PositionedSpinner />;
  }

  return (
    <div className="container-fluid impact-compact-view mt-4">
      <div className="row mb-5">
        <div className="col">
          <h1 className="mb-0">
            {I18n.t("phrases.manageTag")} | {tag.text}
          </h1>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <SingleForm model={model.tagForm} />
        </div>
      </div>

      <div className="row">
        <div className="col">
          <Button
            children={I18n.t("phrases.saveChanges")}
            className="mb-4 w-100 d-block"
            onClick={async () => {
              let updatedTag = await model.updateTag();
              if (updatedTag) {
                model.parentModel.filterModel.resetFilterValues("");
                await model.parentModel.loadPageData();
                model.parentModel.modalService.hide();
              }
            }}
          />
        </div>
      </div>
    </div>
  );
});
