import { UiSizes } from "@flightpath/coreui/dist/enums";
import { ButtonIcon } from "@flightpath/coreui/dist/ui/Button";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { CanEditProgramme } from "../../../../components/hoc/CanEdit";
import { IF } from "../../../../components/hoc/If";
import { ButtonTypes, LinkButton } from "../../../../components/ui/Button";
import { Icon, IconSymbols } from "../../../../components/ui/Icon";
import { Pill, PillTypes } from "../../../../components/ui/Pill";
import { PositionedSpinner } from "../../../../components/ui/PositionedSpinner";
import { TimelineView } from "../../../../components/widgets/TimelineWidget/Timeline_view";
import { ProjectInfoCardView } from "../../../../components/widgets/projects/ProjectInfoCard/ProjectInfoCard_view";
import { useIsOrganisationOwnerOrAdmin } from "../../../../contexts/permissions/PermissionHooks";
import I18n from "../../../../core/localization/I18n";
import { useModalContext } from "../../../../core/modalZ/context/ModalContext";
import { Animations } from "../../../../core/util/Animations";
import Pages from "../../../../routes/InsightRoutes";
import { useCurrentOrganisationId } from "../../../../services/local/organisationContext/OrganisationContextModel";
import { INFO_MODAL_CONFIG } from "./Programme_modals";
import { ProgrammeViewModel } from "./View_model";
import { TimelineMilestoneLegend } from "../../../insight/organisations/dashboard/Timeline/components/TimelineMilestoneLegend";
import { TimelineFilterPills } from "../components/TimelineFilterPills/TimelineFilterPills";
import { TimelineByAudienceFilter } from "../forms/TimelineByAudienceFilter/TimelineByAudienceFilter_view";
import { ProgrammeDashboardSunburstContent } from "../components/Sunburst/ProgrammeSunburst_view";
import { ProgrammeStakeholderCapacity } from "../components/StakeholderCapacityChart/ProgrammeStakeholderCapacity_view";
import { Panel } from "../../../../components/ui/Panel";
import { ProgrammeChangeTeamCapacity } from "../components/ChangeTeamCapacityChart/ProgrammeChangeTeamCapacity_view";

export interface IProgrammeView {}

export const ProgrammeView: React.FunctionComponent<IProgrammeView> = observer(() => {
  const organisationId = useCurrentOrganisationId();
  const canEditProgramme = useIsOrganisationOwnerOrAdmin(organisationId);
  const params = useParams();
  const { programmeId } = params;
  const modalService = useModalContext();

  const [model] = React.useState(() => new ProgrammeViewModel());

  useEffect(() => {
    model.onMount(+programmeId);
    return model.onUnmount;
  }, [model, organisationId, programmeId]);

  if (model.isLoading) {
    return <PositionedSpinner />;
  }

  return (
    <div className={`${Animations.FP_ZOOM_IN} programme-overview mb-8 pb-8`}>
      <div className={`container-fluid pt-6 ${Animations.FP_ZOOM_IN} speed-4 mb-4`}>
        <div className="row mb-4">
          <div className="col-12">
            <div className="d-flex align-items-center">
              <h1 className="mb-0">{model.programmeDashboardData.programmeDetails.name}</h1>

              <Pill type={PillTypes.OUTLINE_LIGHT_GRAY} className="ml-4 align-self-center">
                {I18n.t("phrases.programme")}
              </Pill>

              <LinkButton
                id="changeVisionUrlButton"
                className="ml-4"
                target="_blank"
                type={ButtonTypes.OUTLINE_INFO}
                size={UiSizes.SM}
                isDisabled={!model.programmeDashboardData?.programmeDetails?.changeVisionUrl}
                href={model.programmeDashboardData?.programmeDetails?.changeVisionUrl || ``}
              >
                {I18n.t("phrases.changeVisionUrl")}
              </LinkButton>
              <IF condition={canEditProgramme}>
                <LinkButton
                  id="manageProgrammeButton"
                  className="ml-auto"
                  type={ButtonTypes.PRIMARY}
                  size={UiSizes.SM}
                  href={`/organisations/${organisationId}/programmes/${model.programmeDashboardData.programmeDetails.id}/settings`}
                >
                  {I18n.t("phrases.manageProgramme")}
                </LinkButton>
              </IF>
              <ButtonIcon
                symbol={IconSymbols.MenuArrowRight}
                onClick={() => modalService.show(INFO_MODAL_CONFIG(model.programmeDashboardData))}
                type={ButtonTypes.LINK}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <Panel.Panel
            className={`py-4 px-4 col-12 `}
            hasBorder={true}
            background={Panel.PanelBackgrounds.BG_LIGHT}
            hasBorderRadius={true}
          >
            <div className="d-flex align-items-center">
              <h2 className="mb-2">{I18n.t("phrases.programmeProjects")}</h2>
              <CanEditProgramme programmeId={programmeId}>
                <LinkButton
                  type={ButtonTypes.OUTLINE_PRIMARY}
                  className="ml-auto"
                  href={Pages.organisations.createProject.generateLink(organisationId)}
                >
                  <Icon symbol={IconSymbols.Plus} className="mr-2" />
                  {I18n.t("phrases.createProject")}
                </LinkButton>
              </CanEditProgramme>
            </div>

            {model.programmeDashboardData.hasProgrammeProjects &&
              model.programmeDashboardData.programmeProjects.map(x => (
                <div className="w-100" key={x.id}>
                  <ProjectInfoCardView projectData={x} />
                </div>
              ))}
            {model.programmeDashboardData.hasProgrammeProjects === false && (
              <div className="col-12">{I18n.t("phrases.noProgrammeProjects")}</div>
            )}
          </Panel.Panel>
        </div>
        <Panel.Panel
          className={`pt-4 pb-8 px-4 my-4 `}
          hasBorder={true}
          background={Panel.PanelBackgrounds.BG_LIGHT}
          hasBorderRadius={true}
        >
          <div className="row pb-4 bg-light">
            <div className="col-6">
              <h1 className="mt-2">
                {model.programmeDashboardData.programmeDetails.name} | {I18n.t("phrases.projectsAndMilestones")}
              </h1>
            </div>
            <div className="col-6">
              <TimelineByAudienceFilter
                programmeId={+programmeId}
                audienceList={model.timelineAudiences}
                selectedTimelineAudiences={model.selectedTimelineAudiences}
                selectedContainsFilterKey={model.selectedContainsFilterKey}
                setSelectedTimelineAudiences={model.setSelectedTimelineAudiences}
                setContainsFilterKey={model.setSelectedContainsFilterKey}
              ></TimelineByAudienceFilter>
            </div>
          </div>
          <div className="row justify-content-end">
            <TimelineFilterPills
              selectedAudiences={model.selectedTimelineAudiences as any[]}
              removeFilter={model.removeAudienceFilterById}
            />
          </div>
          <div className="row">
            <div className="col">
              <TimelineMilestoneLegend />
            </div>
          </div>
          <div className="mb-4 action-timeline">
            <TimelineView
              model={model.timelineModel}
              timelineProps={{ lineHeight: 40 } as any}
              labels={{
                noItemsText: I18n.t("phrases.noProgrammeProjectsOrMilestones"),
                today: I18n.t("phrases.today"),
                scrollLeft: I18n.t("phrases.scrollLeft"),
                scrollRight: I18n.t("phrases.scrollRight"),
                zoomOut: I18n.t("phrases.zoomOut"),
                zoomIn: I18n.t("phrases.zoomIn"),
                resetZoom: I18n.t("phrases.resetZoom")
              }}
            />
          </div>
        </Panel.Panel>
        <ProgrammeStakeholderCapacity programmeId={+programmeId} />
        <ProgrammeChangeTeamCapacity programmeId={+programmeId} />
        <ProgrammeDashboardSunburstContent programmeId={+programmeId} />
      </div>
    </div>
  );
});
