import React from "react";
import { ModalProps } from "../../../../../components/ui/Modal";
import { Panel } from "../../../../../components/ui/Panel";
import { Animations } from "../../../../util/Animations";
import { CellClickedEvent } from "ag-grid-community";

export const GridSideModalTitle = ({ name, field }) => {
  return (
    <div className="mt-6">
      <h3 className="mb-5">
        {name} | {field}
      </h3>
    </div>
  );
};

export const GridSideModalComponentProps: Partial<ModalProps> = {
  wrapHeight: "full",
  wrapWidth: "small",
  position: "right",
  panelProps: {
    background: Panel.PanelBackgrounds.BG_LIGHT,
    className: "h-auto min-h-100",
    hasShadow: true
  }
};

export const GridSideModalAnimationOpts = {
  animateIn: Animations.SLIDE_IN_RIGHT,
  animateOut: Animations.SLIDE_OUT_RIGHT
};

export const GetCellCode = (event: CellClickedEvent) => `${event.colDef.field}-${event.data.id}`;
