import { AxiosInstance, AxiosRequestConfig } from "axios";
import { FilteredApiModel, IFilteredOptions } from "../filteredApi/FilteredApiModel";
import Http from "../Http";
import { IFlightPathApiResponse } from "../BaseApiModel";

export class UserReportsApi extends FilteredApiModel<any> {
  controller: string = "userReports";

  constructor(http: AxiosInstance) {
    super(http, "userReports");
  }

  getOverdueActions = async (
    organisationId: number,
    filteredOptions: Partial<IFilteredOptions>,
    config?: AxiosRequestConfig
  ) => {
    let query = this.getRequestQuery(filteredOptions);
    let res = await this.http.get(this.url + "organisations/" + organisationId + "/overdueActions" + query, config);
    return res.data;
  };

  getUpcomingActions = async (
    organisationId: number,
    filteredOptions: Partial<IFilteredOptions>,
    config?: AxiosRequestConfig
  ) => {
    let query = this.getRequestQuery(filteredOptions);
    let res = await this.http.get(this.url + "organisations/" + organisationId + "/upcomingActions" + query, config);
    return res.data;
  };

  getUserStakeholder = async (organisationId: number, config?: AxiosRequestConfig) => {
    let res = await this.http.get(this.url + "organisations/" + organisationId + "/stakeholders", config);
    return res.data;
  };

  getActionsOverTime = async (
    organisationId: number,
    filteredOptions: Partial<IFilteredOptions>,
    config?: AxiosRequestConfig
  ) => {
    let query = this.getRequestQuery(filteredOptions);
    let res = await this.http.get(this.url + "organisations/" + organisationId + "/actionsOverTime" + query, config);
    return res.data;
  };

  getActionsByType = async (
    organisationId: number,
    filteredOptions: Partial<IFilteredOptions>,
    config?: AxiosRequestConfig
  ) => {
    let query = this.getRequestQuery(filteredOptions);
    let res = await this.http.get(this.url + "organisations/" + organisationId + "/actionsByType" + query, config);
    return res.data;
  };

  getUserImpacts = async (
    organisationId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IAction[]>> => {
    let res = await this.http.get(this.url + "organisations/" + organisationId + "/get-user-impacts", config);
    return res.data;
  };

  getUserActions = async (organisationId: number, config?: AxiosRequestConfig) => {
    let res = await this.http.get(this.url + "organisations/" + organisationId + "/get-user-actions", config);
    return res.data;
  };
}

const instance = new UserReportsApi(Http);
export default instance;
