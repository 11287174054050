import { AxiosInstance, AxiosRequestConfig } from "axios";
import { FilteredApiModel } from "../filteredApi/FilteredApiModel";
import Http from "../Http";

export class OrganisationReportsApi extends FilteredApiModel<any> {
  controller: string = "organisationReports";

  constructor(http: AxiosInstance) {
    super(http, "organisationReports");
  }

  getStakeholderCapacityMetricsByOrgId = async (organisationId: number, config?: AxiosRequestConfig) => {
    let res = await this.http.get(`${this.url}organisations/${organisationId}/stakeholder-capacity-metrics`, config);
    return res.data;
  };
  getAudienceCapacityMetricsByOrgId = async (organisationId: number, config?: AxiosRequestConfig) => {
    let res = await this.http.get(`${this.url}organisations/${organisationId}/audience-capacity-metrics`, config);
    return res.data;
  };

  getChangeTeamMetricsByOrgId = async (organisationId: number, config?: AxiosRequestConfig) => {
    let res = await this.http.get(`${this.url}organisations/${organisationId}/change-team-metrics`, config);
    return res.data;
  };
}

const instance = new OrganisationReportsApi(Http);
export default instance;
