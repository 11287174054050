import { action, computed, makeObservable, observable } from "mobx";
import { IFilteredPagination } from "../../../services/api/v2/filteredApi/FilteredApiModel";

export interface IPaginationModel {
  config: IPaginationModelConfig;
  currentPage: number;
  setConfig: (config: Partial<IPaginationModelConfig>) => void;
}

export interface IPaginationModelConfig extends IFilteredPagination {
  onPageClick: (index: number) => void;
}

export class PaginationModel implements IPaginationModel {
  @observable.ref config: IPaginationModelConfig = {} as any;
  @computed get currentPage() {
    return (this.config && this.config.currentPage) || 1;
  }

  /**
   *
   */
  constructor() {
    makeObservable(this);
  }

  @action
  setConfig = (config: Partial<IPaginationModelConfig>) => {
    this.config = { ...this.config, ...config };
  };
}
