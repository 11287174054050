import { PanelTypes } from "@flightpath/coreui/dist/ui/Panel";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Button, ButtonIcon, ButtonTypes } from "../../../../../../../../components/ui/Button";
import { Panel } from "../../../../../../../../components/ui/Panel";
import { PositionedSpinner } from "../../../../../../../../components/ui/PositionedSpinner";
import { CanEdit, CanView } from "../../../../../../../../contexts/permissions/PermissionHelpers";
import { PermissionErrorMessage } from "../../../../../../../../contexts/permissions/PermissionMessages";
import { PermissionFields } from "../../../../../../../../contexts/permissions/PermissionsTypes";
import I18n from "../../../../../../../../core/localization/I18n";
import { useModalContext } from "../../../../../../../../core/modalZ/context/ModalContext";
import { Enums, UiSizes } from "../../../../../../../../enums";
import { useCurrentOrganisationId } from "../../../../../../../../services/local/organisationContext/OrganisationContextModel";
import { AddStakeholderForm } from "./AddStakeholderForm";
import { StakeholderOwnersSidebarModel } from "./StakeholdersSidebar_model";
import { QUERY_STRING_PARAMS } from "../../../../../../../../services/local/queryStringService/QueryStringService";
import { convertStakeholderToName } from "../../../../../../../../core/util/Helpers";
import { IconSymbols } from "../../../../../../../../components/ui/Icon";

interface StakeholderOnwersSidebarProps {
  actionId: number;
}

export const StakeholderOnwersSidebar: React.FC<StakeholderOnwersSidebarProps> = observer(({ actionId }) => {
  const { projectId } = useParams<{ projectId: string }>();
  const organisationId = useCurrentOrganisationId();
  const modalService = useModalContext();
  const [formIsActive, setFormIsActive] = useState(false);
  const [model] = useState(
    () => new StakeholderOwnersSidebarModel(modalService.hide, organisationId, +projectId, actionId)
  );

  useEffect(() => {
    model.onMount();
    // eslint-disable-next-line
  }, []);
  if (model.isLoading) return <PositionedSpinner />;

  return (
    <div className="impact-stakeholders pt-5">
      <CanView
        field={PermissionFields.STAKEHOLDERS}
        projectId={model.projectId}
        errorMessage={
          <div className="row mb-4">
            <div className="col">
              <PermissionErrorMessage>
                <p className="mb-0">{I18n.t("phrases.noActionAccess")}</p>
              </PermissionErrorMessage>
            </div>
          </div>
        }
      ></CanView>
      <div className="container-fluid">
        <div className="row mb-5">
          <div className="col">
            <CanEdit field={PermissionFields.STAKEHOLDERS} projectId={model.projectId}>
              <Button
                onClick={() => {
                  setFormIsActive(true);
                }}
              >
                {I18n.t("phrases.addStakeholder")}
              </Button>
              {formIsActive && (
                <AddStakeholderForm
                  setIsActive={setFormIsActive}
                  organisationId={model.organisationId}
                  projectId={model.projectId}
                  actionId={actionId}
                  reloadImpactFn={model.loadProjectStakeholders}
                  stakeholderType={Enums.StakeholderType.INDIVIDUAL}
                />
              )}
            </CanEdit>
          </div>
        </div>
        <div className="row">
          <div className="col">
            {model.projectStakeholders?.length ? (
              model.projectStakeholders.map(e => {
                let stakeholder = e.stakeholder;
                return (
                  <ImpactStakeholderCard
                    stakeholder={stakeholder}
                    pStakeholderId={e.id}
                    onLinkClickFn={model.closeModalFn}
                    projectId={+projectId}
                    deleteFn={model.removeProjectStakeholder}
                  />
                );
              })
            ) : (
              <Panel.Panel hasBorderRadius={true} type={PanelTypes.OUTLINES} className="py-2 mb-3 text-center">
                <div>{I18n.t("phrases.noActionStakeholderOwners")}</div>
              </Panel.Panel>
            )}
          </div>
        </div>
        <div className="row mb-5">
          <div className="col">
            <div className="d-flex">
              <Button
                className="ml-auto"
                onClick={() => {
                  modalService.hide();
                }}
              >
                {I18n.t("phrases.done")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export interface ImpactStakeholderCardProps {
  stakeholder: FP.Entities.IStakeholder;
  deleteFn?: (id: any) => void;
  projectId: number;
  pStakeholderId: number;
  onLinkClickFn?: () => void;
}

const ImpactStakeholderCard: React.FunctionComponent<ImpactStakeholderCardProps> = observer(
  ({ projectId, pStakeholderId, stakeholder, deleteFn, onLinkClickFn }) => {
    const organisationId = useCurrentOrganisationId();
    const mainUrl = `/organisations/${organisationId}/projects/${projectId}`;

    return (
      <Panel.Panel
        className="stakeholder-card p-4 mb-2"
        background={Panel.PanelBackgrounds.BG_WHITE}
        type={Panel.PanelTypes.BORDER_LEFT_PRIMARY}
      >
        <div className="d-flex flex-column justify-content-between">
          <Link
            key={stakeholder.id}
            to={`${mainUrl}/stakeholders/${stakeholder.id}?${QUERY_STRING_PARAMS.RETURN_URL}=${encodeURIComponent(
              `${mainUrl}/impacts`
            )}`}
            onClick={() => onLinkClickFn()}
          >
            <div className="strong mb-4 flex-grow-1">{convertStakeholderToName(stakeholder)}</div>
          </Link>

          {deleteFn && (
            <ButtonIcon
              style={{ position: "absolute", right: "40px" }}
              symbol={IconSymbols.Close}
              onClick={() => deleteFn(pStakeholderId)}
              size={UiSizes.SM}
              type={ButtonTypes.OUTLINE_PRIMARY}
            />
          )}
        </div>
      </Panel.Panel>
    );
  }
);
