import { ISdColDef } from "../../../../columns/baseColumn/BaseColumnTypes";
import { BaseTextColumnBuilder } from "../../../../columns/baseColumn/BaseTextColumn_builder";
import { TOTAL_PEOPLE_IMPACTED_COLUMN_CONFIG } from "./TotalPeopleImpactedColumn_config";

interface ITotalPeopleImpactedColumnBuilder {}

export class TotalPeopleImpactedColumnBuilder
  extends BaseTextColumnBuilder
  implements ITotalPeopleImpactedColumnBuilder
{
  constructor(columnOptions?: ISdColDef<any, any>) {
    super({ ...TOTAL_PEOPLE_IMPACTED_COLUMN_CONFIG(columnOptions) });
  }
}
