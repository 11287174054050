import { ColDef } from "ag-grid-community";
import { baseTextColumn } from "../../../../../../../core/grids/BaseColumns";
import { colName, colSelected } from "../../../../../../../core/grids/CommonGridColumns";
import I18n from "../../../../../../../core/localization/I18n";
import { JobRoleField } from "../../../../../../../enums";
import { GridPills } from "../../../../../../../components/widgets/gridPills/GridPills";

export const GetProjectProcessRoleGridColumns = () => {
  let result: ColDef[] = [];
  result.push(colSelected());
  result.push(
    colName(false, null, JobRoleField.name, "", {
      width: 300,
      autoHeight: true
    })
  );
  result.push(colProcesses());

  return result;
};

const colProcesses = (): ColDef => {
  return {
    ...baseTextColumn({
      fieldName: "processes",
      heading: I18n.t("entities.processess"),
      editable: false,
      clickEditable: false,
      autoHeight: true,
      width: 400
    }),
    cellRenderer: e => {
      return <GridPills text={e.data.processes} />;
    }
  };
};
