import { useLocation, useParams } from "react-router-dom";
import I18n from "../../../../core/localization/I18n";
import Pages from "../../../../routes/InsightRoutes";
import { useEffect, useState } from "react";
import {
  InsightTabContent,
  InsightTabContentItem
} from "../../../../components/widgets/innerNavigation/InnerNavContent_view";
import { useIsVisualisationsEnabled } from "../../../../contexts/organisationSettings/OrganisationSettingsContext";
import { useCurrentOrganisationId } from "../../../../services/local/organisationContext/OrganisationContextModel";
import { observer } from "mobx-react-lite";

export const StakeholderTabContent: React.FC = observer(() => {
  const organisationId = useCurrentOrganisationId();
  const { projectId } = useParams<{ projectId: string }>();

  const tabHeadingWithCount = (title: string, count?: number) => {
    var countText = count != null ? `(${count})` : "";
    return <h2 className="mb-0">{`${title} ${countText}`}</h2>;
  };
  const isVisualisationsEnabled = useIsVisualisationsEnabled();
  const items: InsightTabContentItem[] = [
    {
      label: tabHeadingWithCount(I18n.t("entities.stakeholders")),
      url: Pages.projects.stakeholders.listView.generateLink(organisationId, +projectId)
    }
  ];
  if (isVisualisationsEnabled) {
    items.push({
      label: tabHeadingWithCount(I18n.t("phrases.stakeholderMaps")),
      url: Pages.projects.stakeholders.visualisations.parameters.generateLink(organisationId, +projectId, 1, 0)
    });
  }
  const location = useLocation();
  const currentPath = location.pathname;
  const [currIndex, setCurrIndex] = useState(getCurrentIndex(currentPath));

  useEffect(() => {
    setCurrIndex(getCurrentIndex(location.pathname));
  }, [location]);

  return <InsightTabContent items={items} currentIndex={currIndex} />;
});

const getCurrentIndex = path => {
  if (path.endsWith("stakeholders")) {
    return 0;
  } else if (
    path.endsWith("stakeholders/visualisations/1/0") ||
    path.endsWith("stakeholders/visualisations/1/1") ||
    path.endsWith("stakeholders/visualisations/1/2") ||
    path.endsWith("stakeholders/visualisations/1/3")
  ) {
    return 1;
  }
};
