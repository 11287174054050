import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
import { useCurrentOrganisationId } from "../../../../../../../services/local/organisationContext/OrganisationContextModel";
import { ProjectProcessesGrid } from "../../../../../../../core/grids/builder/directorBuilder/grids/ProjectProcessesGrid/ProjectProcessesGridView_view";
import { useEffect, useState } from "react";
import { ProjectProcessesGridModalModel } from "./ProjectProcessesGridModal_model";
import { PositionedSpinner } from "@flightpath/coreui/dist/ui/PositionedSpinner";
import { GridTypes } from "../../../../../../../enums";

const ProjectProcessesGridModalView: React.FunctionComponent<any> = observer(({ onAssignSuccess, selectedItemIds }) => {
  const { projectId } = useParams<{ projectId: string }>();
  const organisationId = useCurrentOrganisationId();
  const [model] = useState(() => new ProjectProcessesGridModalModel(+organisationId, +projectId));

  useEffect(() => {
    model.onMount();
    return () => model.onUnmount();
    //eslint-disable-next-line
  }, []);

  if (model.isLoading) return <PositionedSpinner />;
  return (
    <div className="processes-modal-view insight-tab-content__space-filler" style={{ height: "100%" }}>
      <ProjectProcessesGrid
        data={model.projectProcesses}
        gridToolbarType="link-modal"
        onAssignSuccess={onAssignSuccess}
        preSelectedItemIds={selectedItemIds}
        gridType={GridTypes.PROCESS_REPORTS_LINK_MODAL_GRID}
      />
    </div>
  );
});

export { ProjectProcessesGridModalView };
