import * as React from "react";
import { ITextFieldModel } from "../../../../core/forms/controls/textField/ITextFieldModel";
import { INIT_TEXT_FIELD } from "../../../../core/forms/controls/textField/TextField_init";
import { IAutocompleteModel } from "../../../../core/forms/controls/autocomplete/IAutocompleteModel";
import { INIT_AUTOCOMPLETE } from "../../../../core/forms/controls/autocomplete/Autocomplete_init";
import { AutocompleteOption } from "../../../../components/ui/_forms/Autocomplete/AutocompleteOption";
import { generateFormFieldsFromJson } from "../../../../core/forms/helpers/FormFieldMappers";
import { AutocompleteModel } from "../../../../core/forms/controls/autocomplete/Autocomplete_model";
import { ListingModel, INIT_LISTING_FIELD } from "../../../../core/forms/controls/listing/Listing_model";
import { IListingModel } from "../../../../core/forms/controls/listing/IListingModel";
import { IRTEditorModel } from "../../../../core/forms/controls/rteditor/IRTEditorModel";
import { INIT_RTEDITOR } from "../../../../core/forms/controls/rteditor/RTEditor_model";
import { Validations } from "../../../../core/forms/helpers/Validations";
import { ErrorMessage } from "../../../../components/ui/ErrorMessage";
import I18n from "../../../../core/localization/I18n";
import _ from "lodash";
import { FORM_COL } from "../../../../constants";
import { IconSymbols } from "../../../../components/ui/Icon";
import { FormRequiredFieldIndicator } from "../../../../components/ui/_forms/FormRequiredFieldIndicator/FormRequiredFieldIndicator";
import ImpactGroupsApi from "../../../../services/api/v2/impactGroups/ImpactGroups.api";
import { ImpactsApi } from "../../../../services/api/v2/impacts/Impacts.api";

export const getImpactGroupForm = (
  impactProvider: ImpactsApi,
  projId: number,
  organisationId: number,
  impactGroup?: Partial<FP.Entities.IImpactGroup>
) => {
  const impactGroupProvider = ImpactGroupsApi;

  const projectId = {
    ...INIT_TEXT_FIELD,
    key: "projectId",
    inputType: "hidden",
    value: projId,
    defaultValue: projId
  };

  const name: Partial<ITextFieldModel> = {
    ...INIT_TEXT_FIELD,
    key: "name",
    placeholder: I18n.t("placeholders.highLevelImpactName"),
    label: (
      <label htmlFor="name">
        {I18n.t("forms.highLevelImpactName")} <FormRequiredFieldIndicator />
      </label>
    ),
    fieldClassName: FORM_COL.FULL_WIDTH,
    validate: function () {
      const self: ITextFieldModel = this;
      let res = true;
      if (Validations.IS_EMPTY(self.value)) {
        self.errorMessage = <ErrorMessage>{I18n.t("validations.highLevelImpactName")}</ErrorMessage>;
        res = false;
      }

      return res;
    },
    value: impactGroup?.name
  };

  const benefit: Partial<IRTEditorModel> = {
    ...INIT_RTEDITOR,
    key: "changeBenefitText",
    label: (
      <label htmlFor="changeBenefitText">
        {I18n.t("forms.changeBenefit")} <FormRequiredFieldIndicator />
      </label>
    ),
    fieldClassName: FORM_COL.FULL_WIDTH,
    validate: function () {
      const self: IRTEditorModel = this;
      let res = true;
      if (Validations.IS_EMPTY(self.value)) {
        self.errorMessage = <ErrorMessage>{I18n.t("validations.changeBenefit")}</ErrorMessage>;
        res = false;
      }

      return res;
    },
    value: impactGroup?.changeBenefitText
  };

  const impactSearch: Partial<IAutocompleteModel> = {
    ...INIT_AUTOCOMPLETE,
    key: "impactSearch",
    label: <label htmlFor={"impactSearch"}>{I18n.t("forms.searchImpactsForHighLevelImpacts")}</label>,
    placeholder: I18n.t("placeholders.searchDetailedImpact"),
    optionElement: (
      <AutocompleteOption
        key={"e"}
        className={"autocomplete__chip"}
        label={(e: FP.Entities.IImpact) => `${e.refNumber} - ${e.name}`}
      />
    ),
    componentProps: {
      className: "form-control",
      icon: IconSymbols.Search
    },
    onFocus: async function (model, val) {
      this.componentProps.disabled = false;
      const res = await impactGroupProvider.getImpactsUnassignedToImpactGroup(organisationId, projId);

      if (res?.payload) {
        const sortedImpacts = _.orderBy(res.payload, [impact => impact.name.toLowerCase()]);
        model.setOptions(sortedImpacts);
      }
    },
    subscribeTo: ["impacts"],
    onItemSelected: async function () {
      const self: AutocompleteModel = this;
      const listingModel: ListingModel = self.channels.impacts as ListingModel;
      const val = self.value;
      listingModel.addItem(val);
      self.searchQuery = "";
    },
    shouldClearOnBlur: true,
    filterFn: (items: FP.Entities.IImpact[], query) => {
      const lowerQuery = query.toLowerCase();
      return _.filter(items, item => {
        const lowerName = item.name.toLowerCase();
        const lowerRef = item.refNumber?.toLowerCase();
        return lowerName.indexOf(lowerQuery) > -1 || lowerRef.indexOf(lowerQuery) > -1;
      });
    },
    fieldClassName: FORM_COL.FULL_WIDTH
  };

  const impacts: Partial<IListingModel> = {
    ...INIT_LISTING_FIELD,
    key: "impacts",
    label: <label htmlFor={"impacts"}>{I18n.t("forms.selectedDetailedImpacts")}</label>,
    placeholder: I18n.t("placeholders.selectDetailedImpact"),
    fieldClassName: FORM_COL.FULL_WIDTH,
    selector: (e: FP.Entities.IImpact) => (
      <p className="mb-0 d-inline-block">
        {e.refNumber} {e.name}
      </p>
    ),
    value: impactGroup?.impacts,
    extractValue: function () {
      return (this.value && this.value.map(e => e.id)) || [];
    }
  };

  const fields = [];

  fields.push(projectId);
  fields.push(name);
  fields.push(benefit);
  fields.push(impactSearch);
  fields.push(impacts);

  const models = generateFormFieldsFromJson(fields);
  return models;
};
