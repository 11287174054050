import { action, makeObservable, observable, reaction } from "mobx";
import { ButtonTypes } from "../../../../components/ui/Button";
import {
  ReviewModalComponentProps,
  ReviewModalContent,
  ReviewModalTitle,
  ReviewToastContent
} from "../../../../components/ui/ReviewModal";
import ProgressIndicatorModel, {
  ProgressIndicatorModel as IProgressIndicatorModel
} from "../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import I18n from "../../../../core/localization/I18n";
import { IModalContextModel } from "../../../../core/modalZ/context/IModalContext";
import ModalContext from "../../../../core/modalZ/context/ModalContext";
import ToasterService, { IToasterService } from "../../../../core/toaster/ToasterService";
import { TOASTER_TOAST_TIME } from "../../../../core/toaster/Toaster_model";
import { DisposableModel } from "../../../../core/util/DisposableModel";
import CommentsApi, { CommentsApi as ICommentsApi } from "../../../../services/api/v2/comments/Comments.api";
import ImpactsApi, { ImpactsApi as IImpactsApi } from "../../../../services/api/v2/impacts/Impacts.api";
import ProjectsApi, { ProjectsApi as IProjectsApi } from "../../../../services/api/v2/projects/Projects.api";
import { IQueryStringService } from "../../../../services/local/queryStringService/IQueryStringService";
import QueryStringService from "../../../../services/local/queryStringService/QueryStringService";
import { CommentListViewModel } from "../../comments/commentListView/CommentListView_model";
import { RevisionHistoryModel } from "../../revisionHistory/RevisionHistory_model";
import { ImpactStakeholdersModel } from "../ImpactStakeholders/ImpactStakeholders_model";
import { ImpactActionsModel } from "../impactActions/ImpactActions_model";

interface IImpactExtendedViewModelOptions {
  projectId: number;
  impactId: number;
  organisationId: number;
  authUser: FP.Entities.IUser;
}

export class ImpactExtendedViewModel extends DisposableModel {
  projectProvider: IProjectsApi;
  impactProvider: IImpactsApi;
  projectId: number;
  commentsProvider: ICommentsApi;
  toasterService: IToasterService;
  impactId: number;
  confirmationService: IModalContextModel;
  impactStakeholderModel: ImpactStakeholdersModel;
  impactActionsModel: ImpactActionsModel;
  httpProgress: IProgressIndicatorModel;
  commentViewModel: CommentListViewModel;
  revisionHistoryModel: RevisionHistoryModel;
  @observable isLoading: boolean = true;
  @observable.ref impact: FP.Entities.IImpact;
  organisationId: number;
  queryStringService: IQueryStringService;
  @observable hasBeenReviewed: boolean;
  @observable reviewCommentInput: string;

  constructor({ projectId, impactId, authUser, organisationId }: IImpactExtendedViewModelOptions) {
    super();
    makeObservable(this);
    this.toasterService = ToasterService;
    this.projectProvider = ProjectsApi;
    this.impactProvider = ImpactsApi;
    this.httpProgress = ProgressIndicatorModel;
    this.commentsProvider = CommentsApi;
    this.confirmationService = ModalContext;
    this.projectId = projectId;
    this.impactId = impactId;
    this.hasBeenReviewed = false;
    this.reviewCommentInput = "";
    this.organisationId = organisationId;
    this.impactStakeholderModel = new ImpactStakeholdersModel(projectId, impactId, organisationId, false);
    this.commentViewModel = new CommentListViewModel(projectId, authUser, {
      placeholderText: I18n.t("placeholders.impactNotePlaceholder"),
      searchAttribute: "impactId",
      id: -1,
      title: null,
      projectId: this.projectId,
      organisationId
    });

    this.revisionHistoryModel = new RevisionHistoryModel({
      entityId: this.impactId,
      projectId: this.projectId,
      historyType: "impacts",
      organisationId
    });

    this.queryStringService = QueryStringService;

    this.installReactions();
  }

  @action.bound
  async load() {
    await this.loadImpact();
    this.impactStakeholderModel.setImpact(this.impact);
    this.commentViewModel.setConfig({
      id: this.impact.id,
      description: <h4 className="mb-0">{I18n.t("phrases.addANote")}</h4>
    });
  }

  @action
  loadImpact = async () => {
    this.isLoading = true;
    const res = await this.impactProvider.getDetailedById(this.organisationId, this.projectId, this.impactId);

    if (!res || res.isError) {
      return;
    }

    this.setImpact(res.payload);
    this.impactActionsModel = new ImpactActionsModel(
      this.organisationId,
      this.projectId,
      this.impactId,
      this.impact,
      false
    );
    this.isLoading = false;
  };

  @action
  setImpact = (impact: FP.Entities.IImpact) => {
    this.impact = impact;
  };

  onUnmount = () => {
    this.dispose();
  };

  installReactions = () => {
    var d = reaction(
      () => {
        return this.impact;
      },
      (e, reaction) => {
        this.impactStakeholderModel.setImpact(e);
        // this.impactActionsModel.setImpact(e);
      }
    );

    this.addDisposer(d);
  };

  reviewImpact = async (impactId: number, comment) => {
    let data: FP.Entities.IComment = {
      content: comment,
      projectId: this.projectId,
      impactId: impactId,
      owner: null
    };
    let res = await this.commentsProvider.createAndReview(this.organisationId, this.projectId, data);

    if (res) {
      this.toasterService
        .showReviewToast()
        .setContent(<ReviewToastContent itemName={this.impact.name} />)
        .startTimer(TOASTER_TOAST_TIME.NORMAL);
    }

    if (!res || res.isError) return;

    this.hasBeenReviewed = true;
    this.reviewCommentInput = "";
    this.loadImpact();
    this.confirmationService.hide();
  };

  @action
  handleInputChange = (value: string) => {
    this.reviewCommentInput = value;
  };

  showMarkReviewedModal = () => {
    return new Promise(resolve => {
      this.confirmationService.showConfirmDialog(
        <ReviewModalTitle />,
        <ReviewModalContent reviewCommentInput={this.reviewCommentInput} handler={this.handleInputChange} />,
        I18n.t("phrases.confirmReview"),
        I18n.t("phrases.cancelReview"),
        ReviewModalComponentProps,
        async () => {
          await this.reviewImpact(this.impactId, this.reviewCommentInput);
          resolve(true);
        },
        () => {
          this.confirmationService.hide();
        },
        ButtonTypes.PRIMARY,
        ButtonTypes.OUTLINE_PRIMARY
      );
    });
  };
}
