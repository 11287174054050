import { PanelTypes } from "@flightpath/coreui/dist/ui/Panel";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { Button } from "../../../../../../../../components/ui/Button";
import { Hr } from "../../../../../../../../components/ui/Hr";
import { Panel } from "../../../../../../../../components/ui/Panel";
import { PositionedSpinner } from "../../../../../../../../components/ui/PositionedSpinner";
import { CanEdit } from "../../../../../../../../contexts/permissions/PermissionHelpers";
import { PermissionFields } from "../../../../../../../../contexts/permissions/PermissionsTypes";
import I18n from "../../../../../../../../core/localization/I18n";
import { useModalContext } from "../../../../../../../../core/modalZ/context/ModalContext";
import { useCurrentOrganisationId } from "../../../../../../../../services/local/organisationContext/OrganisationContextModel";
import { ImpactActionSidebarModel } from "./ImpactActionSidebar_model";
import { ActionCard } from "../../../../../../../../pages/change/actions/actionCard/ActionCard_view";
import { SingleForm } from "../../../../../../../../pages/change/forms/singleFormModel/SingleForm_view";

interface ImpactActionSidebarProps {
  projectId: number;
  impactId: number;
  impact?: FP.Entities.IImpact;
}

export const ImpactActionSidebar: React.FC<ImpactActionSidebarProps> = observer(props => {
  const { projectId, impact } = props;
  const organisationId = useCurrentOrganisationId();
  const [model, setModel] = useState(() => new ImpactActionSidebarModel(organisationId, projectId, impact));
  const modalService = useModalContext();
  useEffect(() => {
    model.onMount();
  }, [model]);

  useEffect(() => {
    setModel(new ImpactActionSidebarModel(organisationId, projectId, impact));
  }, [organisationId, projectId, impact]);

  const initUrl = `/organisations/${organisationId}/projects/${model.projectId}`;

  const actions = model.actions
    // .filter(action => {
    //   return model.toggle === "closed"
    //     ? action.progressStatus === Enums.ProgressStatus.COMPLETED
    //     : action.progressStatus !== Enums.ProgressStatus.COMPLETED;
    // })
    .map(action => (
      <ActionCard
        key={action.id}
        item={action}
        returnUrl={`${initUrl}/actions/${action.id}`}
        actions={model.actionCardActions}
        className="mb-2"
      />
    ));

  return (
    <div className="impact-action-sidebar container-fluid pt-3">
      <CanEdit field={PermissionFields.IMPACTS} projectId={props.projectId}>
        <SingleForm model={model.formModel} />
        <Hr />
      </CanEdit>
      {model.isLoading && <PositionedSpinner />}

      {!model.isLoading && (
        <div className="row mb-4">
          <div className="col">
            {actions.length ? (
              actions
            ) : (
              <Panel.Panel hasBorderRadius={true} type={PanelTypes.OUTLINES} className="py-2 text-center">
                <div>{I18n.t("phrases.noImpactActions")}</div>
              </Panel.Panel>
            )}
          </div>
        </div>
      )}

      <div className="row mb-5">
        <div className="col">
          <div className="d-flex">
            <Button
              className="ml-auto"
              onClick={() => {
                modalService.hide();
              }}
            >
              {I18n.t("phrases.done")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
});
