import { action, makeObservable, observable } from "mobx";
import * as React from "react";
import { Animations } from "../../util/Animations";
import { ModalProps } from "../ModalComponent";

import { ButtonTypes } from "../../../components/ui/Button";
import { IModalModel } from "../interfaces/IModalModel";
import { IModalConfig } from "../interfaces/IModalService";
import { IUiAction, UiActionRenderers } from "../../uiAction/IUiAction";
import { ModalModel } from "../Modal_model";
import { IModalContextModel } from "./IModalContext";

export class ModalContextModel implements IModalContextModel {
  modalWrapper: HTMLDivElement;
  @observable isVisible: boolean;
  rootEl: HTMLDivElement;
  currentConfig: IModalConfig;
  model: IModalModel;
  stack: IModalConfig[] = [];

  constructor() {
    makeObservable(this);
    this.model = new ModalModel(this);
  }

  @action
  show = (config: IModalConfig): void => {
    this.currentConfig = config;
    this.model.set(config);
    this.showModalElement();
  };

  showStacked = (config: IModalConfig) => {
    this.show(config);
    this.stack.push(config);
  };

  showAsync = (
    cb: (resolve: (val: any) => void, reject: (a: any) => void, model: IModalModel) => IModalConfig
  ): Promise<any> => {
    return new Promise((res, rej) => {
      this.showModalElement();
      this.currentConfig = cb(res, rej, this.model);
      this.model.set(this.currentConfig, res, rej);
    });
  };

  showConfirmDialog = (
    title: React.ReactNode,
    content: React.ReactNode,
    yes: string = "yes",
    no: string = "no",
    componentProps: ModalProps,
    onPositive?: () => void,
    onNegative?: () => void,
    yesBtnType?: ButtonTypes,
    noBtnType?: ButtonTypes
  ): Promise<boolean> => {
    return new Promise((res, rej) => {
      this.showWithReturn({
        title,
        content,
        showClose: false,
        componentProps,
        animationOptions: {
          animateIn: Animations.FADE_IN,
          animateOut: Animations.FADE_OUT,
          speed: 5
        },
        actions: [
          {
            id: "yes",
            label: yes,
            onAction: () => {
              onPositive ? onPositive() : this.hide();
              res(true);
            },
            rendersIn: UiActionRenderers.BUTTON,
            componentProps: {
              type: yesBtnType || ButtonTypes.PRIMARY,
              className: "float-right ml-2"
            }
          },
          {
            id: "no",
            label: no,
            onAction: () => {
              onNegative ? onNegative() : this.hide();
              res(false);
            },
            rendersIn: UiActionRenderers.BUTTON,
            componentProps: {
              type: noBtnType || ButtonTypes.LINK,
              className: "float-right ml-2"
            }
          }
        ]
      });
    });
  };

  showWithReturn = (config: IModalConfig): void => {
    if (this.currentConfig) {
      this.stack.push(this.currentConfig);
    }
    this.show(config);
  };

  setActions = (actions: IUiAction<any>[]): void => {
    this.model.actions = actions;
  };

  @action
  setTitle = (title: React.ReactNode): void => {
    this.model.title = title;
    this.currentConfig.title = title;
  };

  @action
  setContent = (content: React.ReactNode): void => {
    this.model.content = content;
    this.currentConfig.content = content;
  };

  @action
  hide = (): void => {
    this.currentConfig = this.stack.pop();
    if (this.currentConfig) {
      this.show(this.currentConfig);
    } else {
      this.hideModalElement();
    }
  };

  @action
  hideAll = () => {
    while (this.currentConfig) {
      this.currentConfig = this.stack.pop();
    }
    this.hideModalElement();
  };

  /**
   * Hides the modal wrapper that is injected to
   * 'body' in the init function
   */
  @action
  private hideModalElement = () => {
    this.model.animateOutFn();
    setTimeout(
      action(() => {
        this.model.visible = false;
        this.model.content = null;
        this.setIsVisible(false);
      }),
      500
    );
  };

  /**
   * Shows the modal wrapper that is injected to
   * 'body' in the init function
   */
  @action
  private showModalElement = () => {
    this.model.animateInFn();
    setTimeout(() => {
      this.setIsVisible(true);
    });
  };

  @action
  setIsVisible = (isVisible: boolean) => {
    this.isVisible = isVisible;
  };
}
