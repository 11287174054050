import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, ButtonTypes } from "../../../../../components/ui/Button";
import { Icon, IconSymbols } from "../../../../../components/ui/Icon";
import { PositionedSpinner } from "../../../../../components/ui/PositionedSpinner";
import I18n from "../../../../../core/localization/I18n";
import { useCurrentOrganisationId } from "../../../../../services/local/organisationContext/OrganisationContextModel";
import { HeatmapGrid } from "./HeatmapGrid/HeatmapGrid_view";
import { HeatmapModel } from "./Heatmap_model";
import { SupportingHeatmapData } from "./SupportingHeatmapData/SupportingHeatmap_view";
import { StartDateMonth, StartDateYear } from "./components/HeatmapDatesSelectors";
import { HeatmapFilterMenu } from "./components/HeatmapFilterMenu_view";
import { HeatmapFilterPills } from "./components/HeatmapFilterPills";

export const Heatmap: React.FC = observer(() => {
  const organisationId = useCurrentOrganisationId();
  const { section } = useParams<{ section: string; projectId: string }>();

  const [model, setModel] = useState(() => new HeatmapModel(organisationId, section));

  useEffect(() => {
    const abortController = new AbortController();
    model.onMount(abortController.signal);
    return () => {
      abortController.abort();
    };
    //eslint-disable-next-line
  }, [model]);

  useEffect(() => {
    setModel(new HeatmapModel(organisationId, section));
    //eslint-disable-next-line
  }, [section, organisationId]);

  return (
    <>
      <div className="organisation-dashboard__controls my-2 justify-content-end col-7 offset-5">
        <HeatmapFilterMenu />
        <div className="organisation-dashboard__divider">
          <p className="mb-0"> | </p>
        </div>
        <p className="organisation-dashboard__start-date-text mb-0">{I18n.t("phrases.startDate")}:</p>
        <StartDateMonth startMonth={model.currentMonth} setStartMonth={model.setCurrentMonth} />
        <StartDateYear startYear={model.currentYear} setStartYear={model.setCurrentYear} />

        <Button type={ButtonTypes.LINK} onClick={() => model.showFilterModal()}>
          <Icon symbol={IconSymbols.FilterLines} className="pr-2" />
          {I18n.t("phrases.filters")}
        </Button>
      </div>

      <div className="container-fluid">
        <HeatmapFilterPills tags={model.tagFilters as any[]} removeFilter={model.removeTagFilterById} />
        <SupportingHeatmapData
          getTagFilterIdsAsString={model.getTagFilterIdsAsString}
          getSupportingData={model.config.getSupportingData}
          tags={model.tagFilters}
        />
        {model.isLoading ? (
          <PositionedSpinner />
        ) : (
          <HeatmapGrid
            heatmapData={model.heatmapData}
            setParentId={model.setParentId}
            getDrillThroughPieData={model.config.getDrillThroughPieData}
            getTagFilterIdsAsString={model.getTagFilterIdsAsString}
            getUrlForPieChart={model.config.getUrlForPieChart}
          />
        )}
      </div>
    </>
  );
});
