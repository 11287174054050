import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { IFormFieldModel } from "../formField/IFormField";
import { FormField } from "../formField/FormField_view";

export interface IFormModel {
  formFields: IFormFieldModel<any, any>[];
}

export interface IFormProps {
  model: IFormModel;
  className?: string;
  onSubmit?: (formResult: Dictionary<any>) => void;
}

export interface IFormViewModelOptions {
  fields?: IFormFieldModel<any, any>[];
  toasterPosition?: "left" | "right";
  onSubmit?: (dataDictionary: Dictionary<any>) => Promise<void>;
}

export const Form: React.FC<IFormProps> = observer(props => {
  useEffect(() => {
    return () => {
      let { formFields } = props.model;
      if (formFields && formFields.length) {
        formFields.forEach((e: IFormFieldModel<any, any>) => {
          if (e && e.destroyModel) {
            e.destroyModel();
          }
        });
      }
    };
  });

  let { model, className } = props;
  return (
    <div className={`form ${className || ""}`}>
      {model.formFields &&
        model.formFields.map(e => {
          return <FormField model={e} key={e.key} />;
        })}
    </div>
  );
});
