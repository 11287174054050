import { ISdColDef, ISdFilterDef } from "./BaseColumnTypes";

export const BASE_COLUMN_CONFIG: ISdColDef<any, any> = {
  width: 200,
  resizable: true,
  autoHeight: true
};

export const BASE_FILTER_CONFIG: ISdFilterDef = {
  filter: "agTextColumnFilter",
  filterParams: {
    buttons: ["clear"]
  }
};
