import React from "react";
import { Hr } from "../../../../../../components/ui/Hr";
import I18n from "../../../../../../core/localization/I18n";

export const ProgrammeDashboardSunburstLegend = ({ hasMoreThanOneProjects }) => {
  return (
    <div className="flex-column organisation-dashboard__sunburst-legend">
      <h3 className="mb-2">{I18n.t("phrases.dashboardSunburstLegendTitle")}</h3>

      {hasMoreThanOneProjects && (
        <div className="mb-2">
          <LegendTitle title={I18n.t("phrases.sunburstProjectLegendTitle")} />
          <ColourScale largeHex="#23006e" smallHex="#bfa1ff" />
        </div>
      )}

      <Hr className="my-2" />

      <div className="mb-2">
        <LegendTitle title={I18n.t("phrases.changeEntities")} />
        <SunburstLegendItem colour={"#0197A1"} title={I18n.t("entities.impacts")} />
        <SunburstLegendItem colour={"#ED7650"} title={I18n.t("entities.actions")} />
        <SunburstLegendItem colour={"#FFC036"} title={I18n.t("entities.stakeholders")} />
        <SunburstLegendItem colour={"#FDD886"} title={I18n.t("entities.audiences")} />
      </div>

      <Hr className="my-2" />

      <div className="mb-2">
        <LegendTitle title={I18n.t("phrases.impactLevel")} />
        <SunburstLegendItem colour={"#093D4C"} title={I18n.t("phrases.high")} />
        <SunburstLegendItem colour={"#095971"} title={I18n.t("phrases.medium")} />
        <SunburstLegendItem colour={"#308096"} title={I18n.t("phrases.low")} />
        <SunburstLegendItem colour={"#BEBEBE"} title={I18n.t("phrases.unknown")} />
      </div>

      <Hr className="my-2" />

      <div className="mb-2">
        <LegendTitle title={I18n.t("forms.ragStatus")} />
        <SunburstLegendItem colour={"#58c8d1"} title={I18n.t("visualisations.ragStatus_completed")} />
        <SunburstLegendItem colour={"#F91E1E"} title={I18n.t("visualisations.ragStatus_red")} />
        <SunburstLegendItem colour={"#F7820D"} title={I18n.t("visualisations.ragStatus_amber")} />
        <SunburstLegendItem colour={"#1AC541"} title={I18n.t("visualisations.ragStatus_green")} />
        <SunburstLegendItem colour={"#BEBEBE"} title={I18n.t("phrases.unknown")} />
      </div>
    </div>
  );
};

const ColourScale = ({ largeHex, smallHex }) => {
  return (
    <div>
      <div
        className="colour-scale"
        style={{
          backgroundImage: `linear-gradient(to right, ${largeHex}, ${smallHex})`
        }}
      ></div>
      <div className="d-flex justify-content-between">
        <h5 className="mb-0 d-inline-block">Large</h5>
        <h5 className="mb-0 d-inline-block">Small</h5>
      </div>
    </div>
  );
};

type LegendTitle = {
  title: string;
};

const LegendTitle: React.FC<LegendTitle> = props => {
  return <h4 className="mb-1">{props.title}</h4>;
};

type SunburstLegendItem = {
  colour: string;
  title: string;
};

const SunburstLegendItem: React.FC<SunburstLegendItem> = props => {
  return (
    <div className="d-flex align-items-center mb-1 text-nowrap">
      <div className="mr-1 sunburst__legend-circle" style={{ backgroundColor: props.colour }}></div>
      <span className="mb-0 display">{props.title}</span>
    </div>
  );
};
