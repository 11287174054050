import { useParams } from "react-router-dom";
import { InsightInnerNavContent } from "../../../../components/widgets/innerNavigation/InnerNavContent_view";
import I18n from "../../../../core/localization/I18n";
import Pages from "../../../../routes/InsightRoutes";
import { useCurrentOrganisationId } from "../../../../services/local/organisationContext/OrganisationContextModel";

export const ProgrammeSettingsWrapper: React.FC = () => {
  const { programmeId } = useParams();
  const organisationId = useCurrentOrganisationId();

  const items = [
    {
      label: I18n.t("entities.programme"),
      url: Pages.programmes.settings.index.generateLink(organisationId, +programmeId)
    },
    {
      label: I18n.t("entities.milestones"),
      url: Pages.programmes.settings.milestones.generateLink(organisationId, +programmeId)
    }
  ];

  return <InsightInnerNavContent title={I18n.t("phrases.settings")} items={items} />;
};
