import { observer } from "mobx-react-lite";
import { Link, useParams } from "react-router-dom";
import I18n from "../../../../../core/localization/I18n";
import Pages from "../../../../../routes/InsightRoutes";
import { useCurrentOrganisationId } from "../../../../../services/local/organisationContext/OrganisationContextModel";

export const AudienceMapTabs: React.FC<ReactProps> = observer(({ children }) => {
  const organisationId = useCurrentOrganisationId();
  const { projectId } = useParams<{ projectId: string }>();
  const { section } = useParams();

  const items: any[] = [
    {
      key: "all",
      label: I18n.t("phrases.all"),
      url: Pages.projects.audiences.visualisations.index.generateLink(organisationId, +projectId)
    },
    {
      key: "awareness",
      label: I18n.t("phrases.awareness"),
      url: Pages.projects.audiences.visualisations.params.generateLink(organisationId, +projectId, "awareness")
    },
    {
      key: "understanding",
      label: I18n.t("phrases.understanding"),
      url: Pages.projects.audiences.visualisations.params.generateLink(organisationId, +projectId, "understanding")
    },
    {
      key: "commitment",
      label: I18n.t("phrases.commitment"),
      url: Pages.projects.audiences.visualisations.params.generateLink(organisationId, +projectId, "commitment")
    },
    {
      key: "capability",
      label: I18n.t("phrases.capability"),
      url: Pages.projects.audiences.visualisations.params.generateLink(organisationId, +projectId, "capability")
    },
    {
      key: "adoption",
      label: I18n.t("phrases.adoption"),
      url: Pages.projects.audiences.visualisations.params.generateLink(organisationId, +projectId, "adoption")
    }
  ];

  return (
    <>
      <div className="audience-tab-content">
        {items.map((e, i) => {
          return (
            <h4 key={i} className="d-inline-block ml-3">
              <Link
                to={e.url}
                className={
                  section === e.key || (typeof section === "undefined" && e.key === "all")
                    ? "stakeholder-reporting__toggle--active"
                    : ""
                }
              >
                {e.label}
              </Link>
            </h4>
          );
        })}
      </div>
      {children}
    </>
  );
});
