import React from "react"

interface ReportingMetricProps {
    metric: React.ReactNode;
    className?: string;
}

export const ReportingMetric: React.FC<ReportingMetricProps> = ({ metric, className }) => {
    return <div className={`mb-0 action-reporting__reporting-metric strong display-l ${className || ""}`}>{metric}</div>

}
