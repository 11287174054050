import _ from "lodash";
import { ErrorMessage } from "../../../../components/ui/ErrorMessage";
import { AutocompleteOption } from "../../../../components/ui/_forms/Autocomplete/AutocompleteOption";
import { FormRequiredFieldIndicator } from "../../../../components/ui/_forms/FormRequiredFieldIndicator/FormRequiredFieldIndicator";
import { FORM_COL } from "../../../../constants";
import { INIT_AUTOCOMPLETE } from "../../../../core/forms/controls/autocomplete/Autocomplete_init";
import { IAutocompleteModel } from "../../../../core/forms/controls/autocomplete/IAutocompleteModel";
import { INIT_TEXT_FIELD } from "../../../../core/forms/controls/textField/TextField_init";
import { generateFormFieldsFromJson } from "../../../../core/forms/helpers/FormFieldMappers";
import { Validations } from "../../../../core/forms/helpers/Validations";
import I18n from "../../../../core/localization/I18n";
import { OrganisationsApi as IOrganisationsApi } from "../../../../services/api/v2/organisations/Organisations.api";

export const getProjectFormField = (
  orgProvider: IOrganisationsApi,
  organisationId: number,
  onChange: (val) => void,
  projectId?: number
) => {
  const projectIdHidden = projectId
    ? {
        ...INIT_TEXT_FIELD,
        key: "projectId",
        inputType: "hidden",
        value: projectId,
        defaultValue: projectId
      }
    : {
        ...INIT_AUTOCOMPLETE,
        key: "projectId",
        label: (
          <label htmlFor={"projectId"}>
            {I18n.t("forms.chooseProject")} <FormRequiredFieldIndicator />
          </label>
        ),
        placeholder: I18n.t("placeholders.searchProject"),
        optionElement: (
          <AutocompleteOption
            key={"e"}
            className={"autocomplete__chip"}
            label={e => {
              return e.name;
            }}
          />
        ),
        onFocus: async function (model: IAutocompleteModel, event) {
          if (model.options.length === 0) {
            const res = await orgProvider.getProjects(organisationId);

            if (res?.payload) {
              const sortedProjects = _.orderBy(res.payload, [project => project.name.toLowerCase()]);
              model.setOptions(sortedProjects);
            }
          }
        },
        componentProps: {
          className: "form-control",
          icon: "search"
        },
        onItemSelected: function () {
          onChange(this.extractValue());
        },
        searchAttribute: "name",
        validate: function () {
          const self: IAutocompleteModel = this;
          let res = true;
          if (Validations.IS_EMPTY(self.extractValue())) {
            self.errorMessage = <ErrorMessage>{I18n.t("validations.impactProject")}</ErrorMessage>;
            res = false;
          }
          return res;
        },
        fieldClassName: `${FORM_COL.FULL_WIDTH} mb-0`,
        extractValue: function () {
          return this.value?.id;
        }
      };

  const fields = [];
  fields.push(projectIdHidden);
  const models = generateFormFieldsFromJson(fields);
  return models;
};
