import { SelectionChangedEvent } from "ag-grid-community";
import _ from "lodash";
import { action, makeObservable, observable } from "mobx";
import ProgressIndicatorModel, {
  ProgressIndicatorModel as IProgressIndicatorModel
} from "../../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import I18n from "../../../../../core/localization/I18n";
import ToasterService, { IToasterService } from "../../../../../core/toaster/ToasterService";
import { TOASTER_TOAST_TIME } from "../../../../../core/toaster/Toaster_model";
import BenefitApi, { BenefitApi as IBenefitApi } from "../../../../../services/api/v2/benefits/Benefits.api";
import BenefitsHub, { BenefitsHub as IBenefitsHub } from "../../../../../services/hubs/BenefitsHub/Benefits_hub";
import { SingleFormModel } from "../../../../change/forms/singleFormModel/SingleForm_model";
import { SHOW_BENEFIT_DELETE_CONFIRM_MODAL } from "./BenefitsGrid/modals/BenefitGrid_modals";
import { GetFormFields } from "./BenefitsSettings_fields";
import { IModalContextModel } from "../../../../../core/modalZ/context/IModalContext";
import ModalContext from "../../../../../core/modalZ/context/ModalContext";

export class BenefitSettingsModel {
  @observable.ref gridData: any[];
  @observable isLoading: boolean = true;
  @observable connectedUsers: FP.Entities.IUser[] = [];
  organisationId: number;
  projectId: number;
  authUser: FP.Entities.IUser;
  benefitsHub: IBenefitsHub;
  formModel: SingleFormModel;
  toasterService: IToasterService;
  httpProgress: IProgressIndicatorModel;
  benefitsProvider: IBenefitApi;
  gridApi: any;
  @observable.ref selectedBenefits: any[] = [];
  modalService: IModalContextModel;

  constructor(organisationId: number, projectId: number, authUser: FP.Entities.IUser) {
    makeObservable(this);
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.authUser = authUser;
    this.toasterService = ToasterService;
    this.benefitsHub = BenefitsHub;
    this.httpProgress = ProgressIndicatorModel;
    this.benefitsProvider = BenefitApi;
    this.modalService = ModalContext;

    this.initFormModel();
  }

  onMount = async () => {
    await this.registerSocketEvents();
  };

  onUnmount = () => {
    this.benefitsHub.stopConnection();
  };

  registerSocketEvents = async () => {
    if (this.benefitsHub.isConnectionStarted === true) {
      await this.benefitsHub.stopConnection();
    }
    await this.benefitsHub.startConnection();
    this.benefitsHub.onUserJoined(d => {
      this.setConnectedUsers(d);
    });

    this.benefitsHub.onLoadData(d => {
      this.setBenefitRowData(d);
    });

    this.benefitsHub.onUserCellSelected(d => {
      this.setConnectedUsers(d);
    });

    await this.benefitsHub.invokeUserJoined(this.organisationId, this.projectId, this.authUser.sub);
    await this.benefitsHub.invokeLoadData(this.organisationId, this.projectId);
  };

  @action
  setBenefitRowData = data => {
    this.gridData = data;
    this.isLoading = false;
  };

  @action
  setConnectedUsers = users => {
    this.connectedUsers = [...users];
  };

  initFormModel = () => {
    this.formModel = new SingleFormModel();
    this.formModel.formFields = GetFormFields(
      this.createBenefit,
      I18n.t("placeholders.myNewName", { entity: I18n.t("entities.benefit") })
    );
  };

  createBenefit = async () => {
    const formResult = await this.formModel.submit();

    this.formModel.isSaving = true;
    if (!formResult) return;
    formResult.projectId = this.projectId;

    // this.httpProgress.showOverlay();
    // let benefitExists = await this.benefitsProvider.getFiltered(this.organisationId, this.projectId, {
    //   filters: `name==${formResult.name},lifecycleStatus==${Enums.LifecycleStatus.Active},projectId=${this.projectId}`
    // });

    // if (benefitExists && !benefitExists.isError && benefitExists.payload.length) {
    //   this.httpProgress.hideOverlay();
    //   // todo: show message saying benefit already exists

    //   return;
    // }

    this.httpProgress.showTopProgressBarVisible();

    const result = await this.benefitsProvider.create(this.organisationId, this.projectId, formResult as any);
    this.httpProgress.hideTopProgressBarVisible();

    if (!result || result.isError) return;

    const benefit = result.payload;
    this.formModel.resetFields();

    this.toasterService
      .showSuccessToast()
      .setContent(<span>{I18n.t("phrases.itemCreatedSuccessfully", { item: I18n.t("entities.benefit") })}</span>)
      .startTimer(TOASTER_TOAST_TIME.NORMAL);

    return benefit;
  };

  updateUserSelectedCell = async (cell: string, isEditMode?: boolean) => {
    await this.benefitsHub.invokeUserClickedCell(this.organisationId, this.projectId, cell, isEditMode);
  };

  @action
  updateSelectedBenefits = (event: SelectionChangedEvent) => {
    this.gridApi = event.api;
    this.selectedBenefits = _.map(event.api.getSelectedNodes(), e => {
      return e.data.id;
    });
  };

  removeBenefit = async (benefitIds: number[]) => {
    this.httpProgress.showTopProgressBarVisible();
    let res = await this.benefitsProvider.deleteRange(this.organisationId, this.projectId, benefitIds);
    this.httpProgress.hideTopProgressBarVisible();
    if (!res || res.isError) return;

    return res.payload;
  };

  showBenefitConfirmDeleteModal = () => {
    return SHOW_BENEFIT_DELETE_CONFIRM_MODAL(this.modalService, this.selectedBenefits, this.removeBenefit);
  };
}
