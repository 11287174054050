import { IconSymbols } from "@flightpath/coreui/dist/generated/IconSymbols";
import _ from "lodash";
import { ConditionFilterKeyType } from "../../../../../FlightPathEntities";
import { AutocompleteOption } from "../../../../../components/ui/_forms/Autocomplete/AutocompleteOption";
import { FORM_COL, containsFilterOptions } from "../../../../../constants";
import { INIT_AUTOCOMPLETE } from "../../../../../core/forms/controls/autocomplete/Autocomplete_init";
import { IAutocompleteModel } from "../../../../../core/forms/controls/autocomplete/IAutocompleteModel";
import { IMultiSelectorModel } from "../../../../../core/forms/controls/multiSelector/IMultiSelectorModel";
import { INIT_MULTISELECTOR } from "../../../../../core/forms/controls/multiSelector/MultiSelector_model";
import { IFormFieldModel } from "../../../../../core/forms/formField/IFormField";
import { generateFormFieldsFromJson } from "../../../../../core/forms/helpers/FormFieldMappers";
import I18n from "../../../../../core/localization/I18n";

export const getTimelineByAudienceFilterFields = (
  audienceList: any,
  selectedTimelineAudiences: any,
  selectedContainsFilterKey: ConditionFilterKeyType,
  setSelectedTimelineAudiences: (s) => void,
  setContainsFilterKey: (s) => void
): IFormFieldModel<any, any>[] => {
  const audiences: Partial<IMultiSelectorModel> = {
    ...INIT_MULTISELECTOR,
    key: "audiences",
    placeholder: I18n.t("placeholders.filterProjectsByAudience"),
    optionElement: <AutocompleteOption key={"e"} className={"autocomplete__chip"} label={(e: any) => `${e.name}`} />,
    onFocus: function () {
      const self: IMultiSelectorModel = this;

      if (audienceList) {
        const selectedAudiencesIds = selectedTimelineAudiences.map(e => e.id);
        const sortedAudiences = _.orderBy(
          audienceList.filter(e => !selectedAudiencesIds.includes(e.id)),
          [audience => audience.name.toLowerCase()]
        );
        self.setOptions(sortedAudiences);
      }
    },
    componentProps: {
      icon: IconSymbols.Stakeholders
    },
    searchAttribute: "text",
    fieldClassName: FORM_COL.HALF_WIDTH,
    onItemSelected: e => {
      setSelectedTimelineAudiences([...selectedTimelineAudiences, e]);
    },
    isHidden: false,
    allowFreeText: false,
    isTagSelector: true,
    noResultsFoundLabel: I18n.t("phrases.projectsByAudienceFilterResultsNotFound")
  };

  const containsFilter: Partial<IAutocompleteModel> = {
    ...INIT_AUTOCOMPLETE,
    key: "containsFilter",
    placeholder: I18n.t("placeholders.containsFilter"),
    options: containsFilterOptions.map(o => ({ ...o, label: o.name })),
    isLoading: false,
    valueLabelFn: obj => obj.name,
    onItemSelected: e => {
      return setContainsFilterKey(e.id);
    },
    optionElement: (
      <AutocompleteOption
        key={"e"}
        className={"autocomplete__chip"}
        label={option => (
          <span>
            <span className="ml-1">{option.name}</span>
          </span>
        )}
      />
    ),
    componentProps: {
      className: "form-control",
      icon: IconSymbols.ArrowDown
    },
    fieldClassName: FORM_COL.HALF_WIDTH,
    extractValue: function () {
      return this.value?.id;
    },
    defaultValue: containsFilterOptions
      .map(o => ({ ...o, label: o.name }))
      .find(e => {
        return e.id === selectedContainsFilterKey;
      }),
    value: containsFilterOptions
      .map(o => ({ ...o, label: o.name }))
      .find(e => {
        return e.id === selectedContainsFilterKey;
      })
  };

  const fields = [];

  fields.push(audiences);
  fields.push(containsFilter);

  const models = generateFormFieldsFromJson(fields);
  return models;
};
