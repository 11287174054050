import I18n from "../../../../../../../localization/I18n";
import ModalContext from "../../../../../../../modalZ/context/ModalContext";
import { GridSideModalTitle } from "../../../../utils/GridModalUtils";
import { ImpactBusinessAreasSidebarModel } from "./ImpactBusinessAreasSidebar_model";
import { ImpactBusinessAreasSidebar } from "./ImpactBusinessAreasSidebar_view";

export const IMPACT_BUSINESS_AREAS_SIDEBAR_MODAL_CONFIG = (
  organisationId: number,
  projectId: number,
  impact: FP.Entities.IImpact
) => {
  return {
    showClose: true,
    title: <GridSideModalTitle name={impact.name} field={I18n.t("phrases.businessAreas")} />,
    content: (
      <ImpactBusinessAreasSidebar
        model={new ImpactBusinessAreasSidebarModel(ModalContext.hide, organisationId, projectId, impact.id)}
      />
    )
  };
};
