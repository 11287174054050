import { useLocation, useParams } from "react-router-dom";
import {
  InsightTabContent,
  InsightTabContentItem
} from "../../../../components/widgets/innerNavigation/InnerNavContent_view";
import { useIsVisualisationsEnabled } from "../../../../contexts/organisationSettings/OrganisationSettingsContext";
import I18n from "../../../../core/localization/I18n";
import Pages from "../../../../routes/InsightRoutes";
import { useCurrentOrganisationId } from "../../../../services/local/organisationContext/OrganisationContextModel";
import { observer } from "mobx-react-lite";
import { useEffect, useMemo, useState } from "react";

export const ImpactTabContent: React.FC = observer(() => {
  const organisationId = useCurrentOrganisationId();
  const { projectId } = useParams<{ projectId: string }>();
  const isVisualisationsEnabled = useIsVisualisationsEnabled();
  const location = useLocation();
  const currentPath = location.pathname;
  const [currIndex, setCurrIndex] = useState(
    currentPath.endsWith("visualisations/breakdown") ? 1 : currentPath.endsWith("visualisations") ? 2 : 0
  );

  const items: InsightTabContentItem[] = useMemo(() => {
    let items = [
      {
        label: <h2 className="mb-0">{I18n.t("table.highLevelImpacts")}</h2>,
        url: Pages.projects.impactGroups.listView.generateLink(organisationId, +projectId)
      }
    ];
    if (isVisualisationsEnabled) {
      items.push({
        label: <h2 className="mb-0">{I18n.t("phrases.impactGroupsSummary")}</h2>,
        url: Pages.projects.impactGroups.visualisations.generateLink(organisationId, +projectId)
      });
    }
    items.push({
      label: <h2 className="mb-0">{I18n.t("table.detailedImpacts")}</h2>,
      url: Pages.projects.impacts.listView.generateLink(organisationId, +projectId)
    });
    if (isVisualisationsEnabled) {
      items.push({
        label: <h2 className="mb-0">{I18n.t("phrases.impactAssessment")}</h2>,
        url: Pages.projects.impacts.visualisations.impactAssesment.generateLink(organisationId, +projectId)
      });
    }
    return items;
  }, [isVisualisationsEnabled, organisationId, projectId]);

  useEffect(() => {
    const pathnameParts = location.pathname.split("/").filter(e => e !== "");
    const lastPart = pathnameParts[pathnameParts.length - 1];

    if (lastPart === "impact-groups") {
      setCurrIndex(0);
    }
    if (lastPart === "summary") {
      setCurrIndex(1);
    }
    if (lastPart === "impacts") {
      setCurrIndex(2);
    }
    if (lastPart === "impact-assessment") {
      setCurrIndex(3);
    }
  }, [location]);

  return <InsightTabContent items={items} currentIndex={currIndex} />;
});
