import { GridApi, GridReadyEvent, SelectionChangedEvent } from "ag-grid-community";
import _ from "lodash";
import { action, makeObservable, observable } from "mobx";
import TagsApi, { TagsApi as ITagsApi } from "../../../../services/api/v2/tags/Tags.api";

export class LabelGridModel {
  projectId: number;
  organisationId: number;
  tagsProvider: ITagsApi;
  @observable isLoading: boolean = true;
  @observable gridLabels: FP.Entities.IImpactSummary[];
  authUser: FP.Entities.IUser;
  @observable.ref selectedLabels: number[] = [];
  gridApi: GridApi;
  searchText: string;

  /**
   *
   */
  constructor(projectId: number, organisationId: number, authUser: FP.Entities.IUser) {
    makeObservable(this);
    this.projectId = projectId;
    this.organisationId = organisationId;
    this.tagsProvider = TagsApi;
    this.authUser = authUser;
  }

  onMount = async () => {
    await this.loadData();
  };

  onUnmount = () => {};

  loadData = async () => {
    const res = await this.tagsProvider.getAllAsync(this.organisationId);

    this.setLabelRowData(res.payload);
  };

  @action
  setLabelRowData = labels => {
    this.setGridLabels(labels);
    this.isLoading = false;
  };

  @action
  setGridLabels = labels => {
    this.gridLabels = labels;
  };

  @action
  onGridReady = (gridReadyEvent: GridReadyEvent) => {
    this.gridApi = gridReadyEvent.api;
  };

  @action
  setSearchText = (ev: React.FormEvent<HTMLInputElement>) => {
    this.searchText = ev.currentTarget.value;

    if (this.gridApi !== undefined) {
      this.gridApi.setGridOption("quickFilterText", this.searchText);
    }
  };

  @action
  updateSelectedLabels = (event: SelectionChangedEvent) => {
    this.gridApi = event.api;
    this.selectedLabels = _.map(event.api.getSelectedNodes(), e => {
      return e.data.id;
    });
  };
}
