import I18n from "../../../../../../../localization/I18n";
import { GridSideModalTitle } from "../../../../utils/GridModalUtils";
import { ProjectStakeholderOwnersSidebar } from "./ProjectStakeholderOwnersSidebar_view";

export const PROJECT_STAKEHOLDER_OWNER_ACTIONS_MODAL_CONFIG = (
  organisationId: number,
  projectId: number,
  projectStakeholder: any
) => {
  return {
    showClose: true,
    title: <GridSideModalTitle name={projectStakeholder.name} field={I18n.t("phrases.ownerNames")} />,
    content: (
      <ProjectStakeholderOwnersSidebar
        projectId={projectId}
        stakeholderId={projectStakeholder.stakeholderId}
        projectStakeholderId={projectStakeholder.id}
      />
    )
  };
};
