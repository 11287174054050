import { action, makeObservable, observable } from "mobx";
import { ButtonTypes } from "../../../../../../../../components/ui/Button";
import PermissionsContext from "../../../../../../../../contexts/permissions/PermissionsContext";
import { PermissionFields } from "../../../../../../../../contexts/permissions/PermissionsTypes";

import ProgressIndicatorModel, {
  ProgressIndicatorModel as IProgressIndicatorModel
} from "../../../../../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import I18n from "../../../../../../../../core/localization/I18n";
import { IUiAction, UiActionRenderers } from "../../../../../../../../core/uiAction/IUiAction";
import ActionsApi, { ActionsApi as IActionsApi } from "../../../../../../../../services/api/v2/actions/Actions.api";
import ImpactsApi, { ImpactsApi as IImpactsApi } from "../../../../../../../../services/api/v2/impacts/Impacts.api";
import { getImpactActionFormFields } from "./ImpactActionSidebar_fields";
import { SingleFormModel } from "../../../../../../../../pages/change/forms/singleFormModel/SingleForm_model";

export class ImpactActionSidebarModel {
  @observable.ref formModel: SingleFormModel;
  @observable.ref actions: FP.Entities.IAction[] = [];
  @observable isLoading: boolean = true;

  organisationId: number;
  projectId: number;
  actionsProvider: IActionsApi;
  impactsProvider: IImpactsApi;
  impact: FP.Entities.IImpact;
  actionCardActions: IUiAction<FP.Entities.IAction>[] = [];
  impactId: number;
  httpProgress: IProgressIndicatorModel;

  /**
   *
   */
  constructor(organisationId: number, projectId: number, impact: FP.Entities.IImpact) {
    makeObservable(this);
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.actionsProvider = ActionsApi;
    this.impactsProvider = ImpactsApi;
    this.httpProgress = ProgressIndicatorModel;
    this.impact = impact;
    this.setActionCardActions();
    this.initForm();
  }

  onMount = async () => {
    await this.loadActions(this.impact.id);
  };

  initForm = () => {
    this.formModel = new SingleFormModel();
    this.formModel.formFields = getImpactActionFormFields(
      this.actionsProvider,
      this.impactsProvider,
      this.organisationId,
      this.projectId,
      this.impact,
      this.loadActions,
      this.httpProgress
    );
  };

  loadActions = async (impactId: number) => {
    this.impactId = impactId;
    this.isLoading = true;
    let res = await this.impactsProvider.getActions(this.organisationId, this.projectId, impactId);
    if (!res || res.isError) return;
    this.setActions(res.payload);
  };

  setActionCardActions = () => {
    const canEditActions = PermissionsContext.canEditField(
      PermissionFields.ACTIONS,
      this.organisationId,
      this.projectId
    );
    this.actionCardActions.push({
      id: "action1",
      label: I18n.t("phrases.view"),
      rendersIn: UiActionRenderers.HTML_ANCHOR,
      componentProps: {
        type: ButtonTypes.LINK
      },
      hrefFn: action => `/organisations/${this.organisationId}/projects/${action.projectId}/actions/${action.id}`
    });
    if (canEditActions) {
      this.actionCardActions.push({
        id: "action2",
        label: I18n.t("phrases.remove"),
        onAction: async (ev, action: FP.Entities.IAction) => {
          await this.removeAction(this.impactId, action.id);
          this.loadActions(this.impactId);
        },
        componentProps: {
          type: ButtonTypes.LINK
        },
        rendersIn: UiActionRenderers.BUTTON
      });
    }
  };

  @action
  setActions = (actions: FP.Entities.IAction[]) => {
    this.actions = actions;
    this.isLoading = false;
  };

  removeAction = async (impactId: number, actionId: number) => {
    this.httpProgress.showTopProgressBarVisible();
    const res = await this.impactsProvider.removeAction(this.organisationId, this.projectId, impactId, actionId);
    this.httpProgress.hideTopProgressBarVisible();

    if (!res || res.isError) return;

    return res.payload;
  };
}
