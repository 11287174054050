import { AxiosInstance, AxiosRequestConfig } from "axios";
import { ProjectStakeholderField } from "../../../../enums";
import { IFlightPathApiResponse } from "../BaseApiModel";
import http from "../Http";
import { ExportableProjectApi } from "../exportableApi/ExportableProjectApiModel";
import { IFilteredOptions } from "../filteredApi/FilteredApiModel";

export class ProjectStakeholderOwnersApi extends ExportableProjectApi<FP.Entities.IProjectStakeholder> {
  controller: string = "stakeholder-owners";

  constructor(http: AxiosInstance) {
    super(http, "stakeholder-owners");
  }

  //#region CRUD

  async getStakeholderOwners(
    organisationId: number,
    projectId: number
  ): Promise<IFlightPathApiResponse<FP.Entities.IProjectStakeholder[]>> {
    const url = `${this.url}/${organisationId}/projects/${projectId}/stakeholder-owners`;
    let res = await this.http.get(url);
    return res.data;
  }

  async getStakeholderOwnersByProjectStakeholderId(
    organisationId: number,
    projectId: number,
    projectStakeholderId: number
  ): Promise<IFlightPathApiResponse<FP.Entities.IUser[]>> {
    const url = `${this.url}/${organisationId}/projects/${projectId}/stakeholder-owners/${projectStakeholderId}`;
    let res = await this.http.get(url);
    return res.data;
  }

  getFilteredAsync = async (
    filterOptions: Partial<IFilteredOptions>,
    config?: AxiosRequestConfig,
    organisationId?: number,
    projectId?: number
  ) => {
    const query = this.getRequestQuery(filterOptions);
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}${query}`;
    const res = await this.http.get(url, config);
    return res.data;
  };

  async addStakeholderOwner(
    organisationId: number,
    projectId: number,
    data: FP.Entities.IProjectStakeholderOwner
  ): Promise<IFlightPathApiResponse<FP.Entities.IProjectStakeholderOwner>> {
    const url = `${this.url}/${organisationId}/projects/${projectId}/stakeholder-owners`;
    let res = await this.http.post(url, data);
    return res.data;
  }

  removeByOwnerIdAndStakeholderId = async (
    organisationId: number,
    projectId: number,
    ownerId: number,
    projectStakeholderId: number,
    config?: AxiosRequestConfig
  ) => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${ownerId}/${projectStakeholderId}`;
    let res = await this.http.delete(url, config);
    if (res && res.status === 200) {
      return res.data;
    }
  };

  updateField = async (
    organisationId: number,
    projectId: number,
    projectStakeholderOwnerId: number,
    textField: string,
    idField: number,
    dateField: Date,
    numericField: number,
    projectStakeholderField: ProjectStakeholderField,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IProjectStakeholder>> => {
    let data = {
      TextField: textField,
      IdField: idField,
      DateField: dateField,
      NumericField: numericField
    };
    let res = await this.http.put(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${projectStakeholderOwnerId}/${projectStakeholderField}`,
      data,
      config
    );
    return res.data;
  };

  //#endregion CRUD
}

const instance = new ProjectStakeholderOwnersApi(http);
export default instance;
