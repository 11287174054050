import { IconSymbols } from "@flightpath/coreui/dist/generated/IconSymbols";
import { Icon } from "@flightpath/coreui/dist/ui/Icon";
import { Pill } from "@flightpath/coreui/dist/ui/Pill";
import { observer } from "mobx-react";
import React from "react";

interface TimelineFilterPillsProps {
  selectedAudiences: any;
  removeFilter: (id) => void;
}

export const TimelineFilterPills: React.FC<TimelineFilterPillsProps> = observer(
  ({ selectedAudiences, removeFilter }) => {
    return (
      <div className="timeline-filter-pills row">
        <div className="col">
          {selectedAudiences.map(e => {
            return (
              <Pill className="m-1" key={e.id}>
                <span
                  onClick={ee => {
                    removeFilter(e.id);
                  }}
                >
                  <Icon symbol={IconSymbols.Close} className="mr-2" />
                </span>
                <p className="mb-0 d-inline-block">{e.name}</p>
              </Pill>
            );
          })}
        </div>
      </div>
    );
  }
);
