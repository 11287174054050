import { action, makeObservable, observable } from "mobx";
import { BaseModel } from "../../../../../../../core/util/BaseModel";
import TagsApi, { TagsApi as ITagsApi } from "../../../../../../../services/api/v2/tags/Tags.api";
import ProjectProcessesApi, {
  ProjectProcessesApi as IProjectProcessesApi
} from "../../../../../../../services/api/v2/projectProcesses/ProjectProcesses.api";

export class ProjectProcessTagsSidebarModel extends BaseModel {
  projectProcesssProvider: IProjectProcessesApi;
  tagsProvider: ITagsApi;
  @observable isLoading: boolean = true;
  @observable.ref tags: FP.Entities.ITag[];
  organisationId: number;
  projectId: number;
  closeModalFn: () => void;
  projectProcessId: number;

  constructor(closeModalFn: () => void, organisationId: number, projectId: number, projectProcessId: number) {
    super();
    makeObservable(this);
    this.projectProcesssProvider = ProjectProcessesApi;
    this.tagsProvider = TagsApi;
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.projectProcessId = projectProcessId;
    this.loadProjectProcess();
    this.closeModalFn = closeModalFn;
  }

  @action
  setProjectProcess = (projectProcess: FP.Entities.ITag[]) => {
    this.tags = projectProcess;
    this.isLoading = false;
  };

  @action
  loadProjectProcess = async () => {
    let res = await this.projectProcesssProvider.getTagsById(
      this.organisationId,
      this.projectId,
      this.projectProcessId
    );
    if (!res || res.isError) return;
    this.setProjectProcess(res.payload);
  };

  @action
  removeTag = async (tagId: number) => {
    let res = await this.tagsProvider.removeProjectProcessTagAssoc(
      this.organisationId,
      this.projectId,
      tagId,
      this.projectProcessId
    );
    if (!res || res.isError) return;
  };
}
