import { AxiosInstance, AxiosRequestConfig } from "axios";
import Http from "../Http";
import { CompanySize, PermissionScope } from "../../../../enums";
import { BaseApiModel, IFlightPathApiResponse } from "../BaseApiModel";
import { IFilteredOptions, IFilteredResponse } from "../filteredApi/FilteredApiModel";

export interface IProjectImpactOverTimeDto {
  projectId: number;
  projectName: string;
  impacts: IProjectImpactDto[];
}

export interface IProjectImpactDto {
  actualEndDate: string;
  impactId: number;
  initialEndDate: string;
  name: string;
  startDate: string;
}

export interface ICreateOrganisation {
  name: string;
  ownerId: number;
  industryId?: number;
  companySize: CompanySize;
  description: string;
  isActive: boolean;
  isImageChanged?: boolean;
  profileImage?: void;
  locationId: number;
}

export class OrganisationsApi extends BaseApiModel<FP.Entities.IOrganisation> {
  controller: string = "organisations";
  constructor(http: AxiosInstance) {
    super(http, "organisations");
  }

  getAllForUser = async (config?: AxiosRequestConfig): Promise<FP.Entities.IOrganisation[]> => {
    const url = `${this.url}`;
    let res = await this.http.get(url, config);
    return res.data;
  };

  private _getStakeholders = async (
    orgId: number,
    filterOptions?: IFilteredOptions,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IStakeholder[]>> => {
    let url = this.url + orgId + "/stakeholders" + ((filterOptions && this.getRequestQuery(filterOptions)) || "");
    let res = await this.http.get(url, config);
    return res.data;
  };
  public get getStakeholders() {
    return this._getStakeholders;
  }
  public set getStakeholders(value) {
    this._getStakeholders = value;
  }

  getBusinessAreas = async (
    orgId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IBusinessArea[]>> => {
    let res = await this.http.get(this.url + orgId + "/business-areas", config);
    return res.data;
  };

  getProgrammes = async (
    orgId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IProgramme[]>> => {
    let res = await this.http.get(this.url + orgId + "/programmes", config);
    return res.data;
  };

  getProjects = async (
    orgId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IProject[]>> => {
    let res = await this.http.get(this.url + orgId + "/projects", config);
    return res.data;
  };

  getOrphanProjects = async (
    orgId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IProject[]>> => {
    let res = await this.http.get(this.url + orgId + "/orphan-projects", config);
    return res.data;
  };

  getLocations = async (
    orgId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.ILocation[]>> => {
    let res = await this.http.get(this.url + orgId + "/locations", config);
    return res.data;
  };
  //#endregion

  //#region Users

  getUsers = async (
    orgId: number,
    config?: AxiosRequestConfig,
    filterOptions?: IFilteredOptions
  ): Promise<IFlightPathApiResponse<FP.Entities.IUser[]>> => {
    let res = await this.http.get(
      this.url + orgId + "/users" + ((filterOptions && this.getRequestQuery(filterOptions)) || ""),
      config
    );
    return res.data;
  };

  getUsersAssignableToProject = async (orgId: number, projectId: number, config?: AxiosRequestConfig) => {
    let res = await this.http.get(this.url + orgId + "/users/projects/" + projectId, config);
    return res.data;
  };

  addUser = async (
    orgId: number,
    user: FP.Entities.IUser,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IUser[]>> => {
    let res = await this.http.post(this.url + orgId + "/users", user, config);
    return res.data;
  };

  editUserPermission = async (
    orgId: number,
    userId: string,
    permissionScope: PermissionScope,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IUser[]>> => {
    let data = { organisationId: orgId, userId, permissionScope };
    let res = await this.http.put(this.url + orgId + "/users/" + userId + "/update-permissions", data, config);
    return res.data;
  };

  removeUser = async (
    orgId: number,
    userId: string,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<boolean>> => {
    let res = await this.http.delete(this.url + orgId + "/users/" + userId, config);
    return res.data;
  };

  getAssociateUsers = async (
    orgId: number,
    filterOptions: Partial<IFilteredOptions>,
    config?: AxiosRequestConfig
  ): Promise<IFilteredResponse<FP.Entities.IUser[]>> => {
    let query = this.getRequestQuery(filterOptions);
    let res = await this.http.get(this.url + orgId + "/associates/" + query, config);
    return res.data;
  };

  exportUsers = async (
    columns: string[],
    filterOptions: Partial<IFilteredOptions>,
    config?: AxiosRequestConfig,
    organisationId?: number
  ) => {
    let query = this.getRequestQuery(filterOptions);
    const url = `${this.url}${organisationId}/export-users${query} &columns=${columns.join(",")}`;
    let res = await this.http.get(url, config);
    return res.data;
  };

  //#endregion

  //#region Roles
  getRoles = async (
    orgId: number,
    config?: AxiosRequestConfig,
    filterOptions?: IFilteredOptions
  ): Promise<IFlightPathApiResponse<FP.Entities.IRole[]>> => {
    let res = await this.http.get(
      this.url + orgId + "/roles" + ((filterOptions && this.getRequestQuery(filterOptions)) || ""),
      config
    );
    return res.data;
  };

  //#endregion

  //#region industries
  getIndustries = async (
    orgId: number,
    config?: AxiosRequestConfig,
    filterOptions?: IFilteredOptions
  ): Promise<IFlightPathApiResponse<FP.Entities.IIndustry[]>> => {
    let res = await this.http.get(
      this.url + "industries" + ((filterOptions && this.getRequestQuery(filterOptions)) || ""),
      config
    );
    return res.data;
  };
  //#endregion

  //#region impact reports

  getImpactReportByBusinessArea = async (
    organisationId: number,
    filterOptions: Partial<IFilteredOptions>,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<any[]>> => {
    let res = await this.http.get(
      `${this.url}${organisationId}/impact-reports-by-business-area${
        (filterOptions && this.getRequestQuery(filterOptions)) || ""
      }`,
      config
    );
    return res.data;
  };

  getImpactReportByBusinessAreaDetails = async (
    organisationId: number,
    projectId: number,
    businessAreaId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IImpact[]>> => {
    let res = await this.http.get(
      `${this.url}${organisationId}/impact-reports-by-business-area/${projectId}/${businessAreaId}`,
      config
    );
    return res.data;
  };

  getImpactReportByLocation = async (
    organisationId: number,
    filterOptions: Partial<IFilteredOptions>,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<any[]>> => {
    let res = await this.http.get(
      `${this.url}${organisationId}/impact-reports-by-location${
        (filterOptions && this.getRequestQuery(filterOptions)) || ""
      }`,
      config
    );
    return res.data;
  };

  getImpactReportByLocationDetails = async (
    organisationId: number,
    projectId: number,
    locationId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IImpact[]>> => {
    let res = await this.http.get(
      `${this.url}${organisationId}/impact-reports-by-location/${projectId}/${locationId}`,
      config
    );
    return res.data;
  };

  getImpactReportByStakeholder = async (
    organisationId: number,
    filterOptions: Partial<IFilteredOptions>,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<any[]>> => {
    let res = await this.http.get(
      `${this.url}${organisationId}/impact-reports-by-stakeholder${
        (filterOptions && this.getRequestQuery(filterOptions)) || ""
      }`,
      config
    );
    return res.data;
  };

  getImpactReportByStakeholderDetails = async (
    organisationId: number,
    projectId: number,
    projectStakeholderId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IImpact[]>> => {
    let res = await this.http.get(
      `${this.url}${organisationId}/impact-reports-by-stakeholder/${projectId}/${projectStakeholderId}`,
      config
    );
    return res.data;
  };

  getImpactReportOverTimeByBusinessArea = async (
    organisationId: number,
    filterOptions: Partial<IFilteredOptions>,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<IProjectImpactOverTimeDto[]>> => {
    const res = await this.http.get(
      `${this.url}${organisationId}/impact-reports-over-time${
        (filterOptions && this.getRequestQuery(filterOptions)) || ""
      }`,
      config
    );
    return res.data;
  };

  getImpactReportOverTimeByBusinessAreaDetails = async (
    organisationId: number,
    resourceId: number,
    year: number,
    month: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IImpact[]>> => {
    const res = await this.http.get(
      `${this.url}${organisationId}/business-area-impacts/${resourceId}/year/${year}/month/${month}`,
      config
    );
    return res.data;
  };

  getImpactReportOverTimeByLocation = async (
    organisationId: number,
    filterOptions: Partial<IFilteredOptions>,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<IProjectImpactOverTimeDto[]>> => {
    const url = `${this.url}${organisationId}/impact-over-time-by-location-report${
      (filterOptions && this.getRequestQuery(filterOptions)) || ""
    }`;
    const res = await this.http.get(url, config);
    return res.data;
  };

  getImpactReportOverTimeByLocationDetails = async (
    organisationId: number,
    resourceId: number,
    year: number,
    month: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IImpact[]>> => {
    const url = `${this.url}${organisationId}/location-impacts/${resourceId}/year/${year}/month/${month}`;
    const res = await this.http.get(url, config);
    return res.data;
  };

  getImpactReportOverTimeByStakeholder = async (
    organisationId: number,
    filterOptions: Partial<IFilteredOptions>,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<IProjectImpactOverTimeDto[]>> => {
    const url = `${this.url}${organisationId}/impact-over-time-by-stakeholder-report${
      (filterOptions && this.getRequestQuery(filterOptions)) || ""
    }`;
    const res = await this.http.get(url, config);
    return res.data;
  };

  getImpactReportOverTimeByStakeholderDetails = async (
    organisationId: number,
    resourceId: number,
    year: number,
    month: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IImpact[]>> => {
    const url = `${this.url}${organisationId}/stakeholder-impacts/${resourceId}/year/${year}/month/${month}`;
    const res = await this.http.get(url, config);
    return res.data;
  };
  //#endregion

  //#region Organisations

  getOrganisationSunburst = async (
    organisationId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<any>> => {
    let res = await this.http.get(this.url + organisationId + "/sunburst", config);
    return res.data;
  };

  getAllOrganisationImpacts = async (
    organisationId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<any>> => {
    let res = await this.http.get(this.url + organisationId + "/impacts", config);
    return res.data;
  };

  getAllOrganisationActions = async (
    organisationId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<any>> => {
    let res = await this.http.get(this.url + organisationId + "/actions", config);
    return res.data;
  };

  getOrganisationProgrammeSunburst = async (
    organisationId: number,
    programmeId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<any>> => {
    let res = await this.http.get(this.url + organisationId + `/sunburst?programmeId=${programmeId}`, config);
    return res.data;
  };

  getAllOrganisationProgrammeImpacts = async (
    organisationId: number,
    programmeId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<any>> => {
    let res = await this.http.get(this.url + organisationId + `/impacts?programmeId=${programmeId}`, config);
    return res.data;
  };

  getAllOrganisationProgrammeActions = async (
    organisationId: number,
    programmeId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<any>> => {
    let res = await this.http.get(this.url + organisationId + `/actions?programmeId=${programmeId}`, config);
    return res.data;
  };

  //#endregion

  deleteUserPermissionCache = async (organisationId: number, userId: string, config?: AxiosRequestConfig) => {
    const url = `${this.url}${organisationId}/users/delete-cache/${userId}`;
    let res = await this.http.delete(url, config);
    return res.data;
  };
}

let instance = new OrganisationsApi(Http);

export default instance;
