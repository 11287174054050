import ToasterService, { IToasterService } from "../../../core/toaster/ToasterService";
import { TOASTER_TOAST_TIME } from "../../../core/toaster/Toaster_model";
import { IGridToastService } from "./IGridToastService";
import I18n from "../../../core/localization/I18n";

class GridToastService implements IGridToastService {
  toasterService: IToasterService;

  constructor() {
    this.toasterService = ToasterService;
  }

  showToast = (code: number, message: string) => {
    switch (code) {
      case 200:
        this.showSaveSuccess();
        break;
      case 400:
        this.showValidationWarning(message);
        break;
      case 500:
        this.showErrorMessage(message);
        break;
      default:
        break;
    }
  };

  showSaveSuccess = () => {
    this.toasterService
      .showSuccessToast(TOASTER_TOAST_TIME.SLOW, "right", true)
      .setActionAlignedTitle(<h3 className="toaster__success-review-title">{I18n.t("grids.toastSavedHeading")}</h3>)
      .setContent(<span>{I18n.t("grids.toastSavedMessage")}</span>)
      .startTimer(TOASTER_TOAST_TIME.SLOW);
  };

  showValidationWarning = (message: string) => {
    this.toasterService
      .showErrorToast(TOASTER_TOAST_TIME.SLOW, "right")
      .setActionAlignedTitle(
        <h3 className="toaster__success-review-title">{I18n.t("grids.toastValidationHeading")}</h3>
      )
      .setContent(<span>{message}</span>)
      .startTimer(TOASTER_TOAST_TIME.SLOW);
  };

  showErrorMessage = (message: string) => {
    this.toasterService
      .showErrorToast(TOASTER_TOAST_TIME.SLOW, "right")
      .setActionAlignedTitle(<h3 className="toaster__success-review-title">{I18n.t("grids.toastErrorHeading")}</h3>)
      .setContent(<span>{message}</span>)
      .startTimer(TOASTER_TOAST_TIME.SLOW);
  };
}

const instance = new GridToastService();

export default instance;
