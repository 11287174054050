import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { PositionedSpinner } from "../../../../../../../components/ui/PositionedSpinner";
import { useCurrentOrganisationId } from "../../../../../../../services/local/organisationContext/OrganisationContextModel";
import { ProjectTeamCountSidebarModel } from "./ProjectTeamCountSidebar_model";
import { Link } from "react-router-dom";
import Pages from "../../../../../../../routes/InsightRoutes";
import ModalContext from "../../../../../../../core/modalZ/context/ModalContext";
import { Pill, PillTypes } from "@flightpath/coreui/dist/ui/Pill";
import { UiSizes } from "@flightpath/coreui/dist/enums";

export interface ProjectTeamCountProps {
  userId: number;
}

export const ProjectTeamCountSidebar: React.FC<ProjectTeamCountProps> = observer(props => {
  const { userId } = props;
  const organisationId = useCurrentOrganisationId();
  const [model, setModel] = useState(() => new ProjectTeamCountSidebarModel(organisationId, userId));

  useEffect(() => {
    setModel(new ProjectTeamCountSidebarModel(organisationId, userId));
    // eslint-disable-next-line
  }, [userId]);

  useEffect(() => {
    model.onMount();
  }, [model]);

  if (!model || model.isLoading) return <PositionedSpinner />;

  return (
    <div style={{ width: "100%", height: "70vh" }}>
      <div className="w-100 h-100 p-5">
        {model.drillDownPieData.map(e => (
          <Link
            className="mb-0"
            to={Pages.projects.index.generateLink(organisationId, e.id)}
            onClick={ModalContext.hide}
          >
            <Pill className="m-1 px-2" key={e.label + e} type={PillTypes.PRIMARY} size={UiSizes.LG}>
              <span className="body-small">{e.label}</span>
            </Pill>
          </Link>
        ))}
      </div>
    </div>
  );
});
