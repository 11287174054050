import React, { Dispatch, useState } from "react";
import { ButtonTypes } from "../../../../../../../../components/ui/Button";
import I18n from "../../../../../../../localization/I18n";
import { IUiAction, UiActionRenderers } from "../../../../../../../uiAction/IUiAction";
import { SingleFormModel } from "../../../../../../../../pages/change/forms/singleFormModel/SingleForm_model";
import { SingleForm } from "../../../../../../../../pages/change/forms/singleFormModel/SingleForm_view";
import { ProjectStakeholdersApi } from "../../../../../../../../services/api/v2/projectStakeholders/ProjectStakeholders.api";
import { getProjectStakeholderImpactFormFields } from "./ProjectStakeholderImpactsSidebar_formFields";

interface AddImpactFormProps {
  projectStakeholderProvider: ProjectStakeholdersApi;
  organisationId: number;
  projectId: number;
  projectStakeholderId: number;
  reloadFn: (id: number) => void;
  setFormIsShown?: Dispatch<boolean>;
}

class AddImpactFormModel {
  formModel: SingleFormModel;

  constructor(
    projectStakeholderProvider: ProjectStakeholdersApi,
    organisationId: number,
    projectId: number,
    projectStakeholderId: number,
    reloadFn: (id: number) => void,
    setFormIsShown: Dispatch<boolean>
  ) {
    this.formModel = new SingleFormModel();

    let formFields = getProjectStakeholderImpactFormFields(
      projectStakeholderProvider,
      organisationId,
      projectId,
      projectStakeholderId
    );
    let actions: IUiAction<any>[] = [
      {
        id: "action1",
        label: I18n.t("phrases.cancel"),
        onAction: ev => {
          setFormIsShown(false);
        },
        componentProps: {
          type: ButtonTypes.LINK,
          className: "ml-auto"
        },
        rendersIn: UiActionRenderers.BUTTON
      },
      {
        id: "action2",
        label: I18n.t("phrases.add"),
        onAction: async () => {
          const formRes = await this.formModel.submit();
          if (!formRes) return;
          const res = await projectStakeholderProvider.addImpacts(
            organisationId,
            projectId,
            projectStakeholderId,
            formRes.impacts
          );

          if (!res || res.isError) return;

          if (res.payload) {
            reloadFn(projectStakeholderId);
          }
        },
        componentProps: {
          className: "ml-2"
        },
        rendersIn: UiActionRenderers.BUTTON
      }
    ];
    this.formModel.formFields = formFields;
    this.formModel.actions = actions;
  }
}

export const AddImpactForm: React.FC<AddImpactFormProps> = props => {
  const { projectStakeholderProvider, projectStakeholderId, organisationId, projectId, reloadFn, setFormIsShown } =
    props;

  let [model] = useState(
    () =>
      new AddImpactFormModel(
        projectStakeholderProvider,
        organisationId,
        projectId,
        projectStakeholderId,
        reloadFn,
        setFormIsShown
      )
  );

  return (
    <div className="container-fluid">
      <SingleForm model={model.formModel} />
    </div>
  );
};
