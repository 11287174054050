import { ColDef } from "ag-grid-community";
import { colSelected } from "../../../../../core/grids/CommonGridColumns";
import { colBusinessArea, colEmail, colName, colOwnerName, colRole } from "./UnassignedStakeholdersGrid_baseColumns";
import { StakeholderType } from "../../../../../enums";

export const GetStakeholderGridViewColumns = (modelProps: any): ColDef[] => {
  let result: ColDef[] = [];
  result.push(colSelected());
  result.push(colName({ width: 150, pinned: modelProps.pinned }));
  if (modelProps.stakeholderType !== StakeholderType.AUDIENCE) {
    result.push(colEmail({ pinned: modelProps.pinned }));
  }
  result.push(colRole());
  result.push(colBusinessArea());
  result.push(colOwnerName());

  return result;
};
