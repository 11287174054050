import React, { useState } from "react";
import { Button } from "../../../../../../../../components/ui/Button";
import I18n from "../../../../../../../../core/localization/I18n";
import { useModalContext } from "../../../../../../../../core/modalZ/context/ModalContext";
import TagsApi from "../../../../../../../../services/api/v2/tags/Tags.api";
import { SingleFormModel } from "../../../../../../../change/forms/singleFormModel/SingleForm_model";
import { getStakeholderTagFormFields } from "./StakeholderTagsSidebar_formFields";
import { SingleForm } from "../../../../../../../change/forms/singleFormModel/SingleForm_view";

interface ManageStakeholderTagFormProps {
  organisationId: number;
  projectId: number;
  projectStakeholder: FP.Entities.IProjectStakeholder;
}

class ManageStakeholderTagFormModel {
  formModel: SingleFormModel;

  constructor(organisationId: number, projectId: number, projectStakeholder: FP.Entities.IProjectStakeholder) {
    this.formModel = new SingleFormModel();

    const tagsProvider = TagsApi;
    let formFields = getStakeholderTagFormFields(tagsProvider, organisationId, projectId, projectStakeholder);
    this.formModel.formFields = formFields;
  }
}

export const ManageStakeholderTagForm: React.FC<ManageStakeholderTagFormProps> = props => {
  const { projectStakeholder, projectId, organisationId } = props;
  const modalService = useModalContext();

  let [model] = useState(() => new ManageStakeholderTagFormModel(organisationId, projectId, projectStakeholder));

  return (
    <div>
      <SingleForm model={model.formModel} />
      <div className="row mb-5">
        <div className="col">
          <div className="d-flex">
            <Button
              className="ml-auto"
              onClick={() => {
                modalService.hide();
              }}
            >
              {I18n.t("phrases.done")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
