import { ISdColDef, ISdFilterDef } from "../../../baseColumn/BaseColumnTypes";

export const OWNER_NAME_COLUMN_CONFIG: (projectTeamMembers: any) => ISdColDef<any, any> = projectTeamMembers => {
  return {
    cellEditorParams: {
      field: "owner",
      getValueLabel: (ee: any) => {
        if (!projectTeamMembers) return "";

        const user = projectTeamMembers.find(e => e.id === ee.id);
        if (!user) return "";
        return user.firstName + " " + user.lastName;
      },
      options: !!projectTeamMembers ? projectTeamMembers : [],
      others: projectTeamMembers,
      allowFreeText: true,
      isPerson: true,
      filterFn: (items, query) => {
        const lowerQuery = query.toLowerCase();
        return items.filter((item: FP.Entities.IUser) => {
          const lowerName = `${item.firstName} ${item.lastName}`.toLowerCase();
          const lowerEmail = item.email.toLowerCase();
          return lowerName.indexOf(lowerQuery) > -1 || lowerEmail.indexOf(lowerQuery) > -1;
        });
      }
    }
  };
};
export const OWNER_NAME_FILTER_CONFIG: ISdFilterDef = {};
