import { PanelTypes } from "@flightpath/coreui/dist/ui/Panel";
import { observer } from "mobx-react";
import React from "react";
import { Panel } from "../../../../../../../../components/ui/Panel";
import { PositionedSpinner } from "../../../../../../../../components/ui/PositionedSpinner";
import { Tag } from "../../../../../../../../components/ui/Tag";
import PermissionsContext from "../../../../../../../../contexts/permissions/PermissionsContext";
import { PermissionFields } from "../../../../../../../../contexts/permissions/PermissionsTypes";
import I18n from "../../../../../../../../core/localization/I18n";
import { ActionTagsSidebarModel } from "./ActionTagsSidebar_model";
import { ManageActionTagForm } from "./ManageActionTagForm";

export interface ActionTagsSidebarProps {
  model: ActionTagsSidebarModel;
}

export const ActionTagsSidebar: React.FC<ActionTagsSidebarProps> = observer(({ model }) => {
  const { action } = model;
  const canEdit = PermissionsContext.canEditField(PermissionFields.ACTIONS, model.organisationId, model.projectId);

  if (model.isLoading) return <PositionedSpinner />;

  return (
    <div className="container-fluid">
      {canEdit ? (
        <div className="row mb-4">
          <div className="col">
            <ManageActionTagForm
              closeFn={model.closeModalFn}
              projectId={model.projectId}
              organisationId={model.organisationId}
              action={model.action}
            />
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col">
            {action.tags.length > 0 ? (
              action.tags.map(t => (
                <Tag tagText={t.text} isEditable={false} testId={t.id + ""} key={t.id} isNew={false} />
              ))
            ) : (
              <div>
                <Panel.Panel hasBorderRadius={true} type={PanelTypes.OUTLINES} className="py-2 text-center">
                  <div>{I18n.t("phrases.noTags")}</div>
                </Panel.Panel>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
});
