import {
  AccessToken,
  IDToken,
  OktaAuth,
  OktaAuthOptions,
} from "@okta/okta-auth-js";

export const AuthService = (config: OktaAuthOptions) =>
  new OktaAuth({
    ...config,
    tokenManager: {
      storage: "sessionStorage",
    },
  });

export async function getAppToken(authService: OktaAuth) {
  const idToken = (await authService.tokenManager.get("idToken")) as IDToken;
  const accessToken = (await authService.tokenManager.get(
    "accessToken"
  )) as AccessToken;
  return idToken
    ? idToken.idToken
    : accessToken
    ? accessToken.accessToken
    : null;
}
