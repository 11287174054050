import { makeObservable, observable } from "mobx";
import { IGridModel } from "../../../../../core/grids/IGridModel";
import { PROGRESS_STATUS_OPTIONS } from "../../../../../constants";
import { EntityTypes } from "../../../../../enums";
import I18n from "../../../../../core/localization/I18n";
import { GetImpactGridViewColumns } from "./GridColumns";

export class ImpactGridModel implements IGridModel {
  authUser: FP.Entities.IUser;
  @observable isLoading: boolean = true;
  progressStatuses: FP.Generic.IKeyLabel[];
  impactLevels: FP.Generic.IKeyLabel[];
  type: EntityTypes = EntityTypes.IMPACTS;
  organisationId: number;
  projectId: number;

  constructor(organisationId: number, projectId: number, authUser: FP.Entities.IUser) {
    makeObservable(this);
    this.authUser = authUser;
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.mapProgressStatuses();
  }

  onMount = async () => {
    this.isLoading = false;
  };

  onUnmount = () => {};

  getColumnConfig = () => {
    return GetImpactGridViewColumns({
      canEdit: false,
      organisationId: this.organisationId,
      projectId: this.projectId,
      projectTeamMembers: [],
      progressStatuses: this.progressStatuses
    });
  };

  mapProgressStatuses = () => {
    this.progressStatuses = PROGRESS_STATUS_OPTIONS.map(e => {
      return {
        label: I18n.t(e.label),
        key: e.key + ""
      } as FP.Generic.IKeyLabel;
    });
  };
}
