import { ModalProps } from "../../../../../../../components/ui/Modal";
import { Panel } from "../../../../../../../components/ui/Panel";
import I18n from "../../../../../../../core/localization/I18n";
import { IModalContextModel } from "../../../../../../../core/modalZ/context/IModalContext";
import { Animations } from "../../../../../../../core/util/Animations";
import { SingleForm } from "../../../../../../change/forms/singleFormModel/SingleForm_view";
import { HeatmapFilterModalModel } from "./HeatmapFilterModel";

export const SHOW_FILTER_MODAL = (modalService: IModalContextModel, organisationId, currentTags, setCurrentTags) => {
  const model = new HeatmapFilterModalModel(organisationId, currentTags, setCurrentTags);
  modalService.show({
    showClose: true,
    title: (
      <div className="mt-6">
        <h3 className="mb-5">{I18n.t("phrases.applyFilters")}</h3>
      </div>
    ),
    content: (
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <SingleForm model={model.formModel} />
          </div>
        </div>
      </div>
    ),
    componentProps: modalComponentProps,
    animationOptions: modalAnimationOpts
  });
};

const modalComponentProps: Partial<ModalProps> = {
  wrapHeight: "full",
  wrapWidth: "small",
  position: "right",
  panelProps: {
    background: Panel.PanelBackgrounds.BG_LIGHT,
    className: "h-auto min-h-100",
    hasShadow: true
  }
};

const modalAnimationOpts = {
  animateIn: Animations.SLIDE_IN_RIGHT,
  animateOut: Animations.SLIDE_OUT_RIGHT
};
