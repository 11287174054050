import AuthApi from "../../services/api/v2/auth/Auth.api";

export async function loadFlightPathUserClaims(organisationId: number, oktaUserId?: string) {
  let result = await AuthApi.getUserClaims(organisationId);

  if (!result.payload.length || !oktaUserId || oktaUserId.length === 0) {
    // await AuthApi.createSystemUser();
    result = await AuthApi.getUserClaims(organisationId);
  }
  return result.payload;
}
