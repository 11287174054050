import {
  TargetBarChart, TargetBarChartProps as TBCP
} from "@flightpath/coreui/dist/widgets/targetBarChart";
import React from "react";
import I18n from "../../../core/localization/I18n";
import { Panel } from "../../ui/Panel";

export interface TargetBarChartProps extends TBCP { }
export { TargetBarChart };


export const StakeholderTargetBarChartTooltip: React.FC<any> = (props) => {
  const { label, payload } = props;
  if (!payload[0]) return null;
  const item = payload[0].payload;

  const LabelValue = ({ label, current, target, className }) => {
    return <>
      <p className={`strong mb-1 ${className || ""}`}>{label}</p>
      <div>
        <small>{I18n.t("phrases.current")}</small><small className="float-right">{current}</small>
      </div>
      <div>
        <small>{I18n.t("phrases.target")}</small><small className="float-right">{target}</small>
      </div>
    </>
  }

  return <Panel.Panel
    background={Panel.PanelBackgrounds.BG_WHITE}
    hasShadow={true}
    style={{ width: "200px" }}
    hasBorderRadius={true}
    className="p-3"
  >
    <h3 className="mb-2">{label}</h3>
    <LabelValue
      label="Stakeholder"
      className=""
      current={item.entityOneValue}
      target={item.entityOneTarget} />
    <LabelValue
      label="Audience"
      className="mt-3"
      current={item.entityTwoValue}
      target={item.entityTwoTarget} />
  </Panel.Panel>
}
