import { AxiosInstance } from "axios";
import { BaseApiModel } from "../BaseApiModel";
import Http from "../Http";

export class AuthApi extends BaseApiModel<any> {
  constructor(http: AxiosInstance, controller: string) {
    super(http, "auth");
  }

  controller: string = "auth";
  getUserClaims = async (organisationId: number) => {
    let url = `${this.url}claims/organisations/${organisationId || 0}`;
    const res = await this.http.get(url);
    return res.data;
  };

  createSystemUser = async () => {
    let url = `${this.url}add`;
    const res = await this.http.get(url);
    return res.data;
  };

  logout = async () => {
    let url = `${this.url}logout`;
    const res = await this.http.post(url);
    return res.data;
  };
}

const instance = new AuthApi(Http, "auth");

export default instance;
