import { CircleStatusColours } from "@flightpath/coreui/dist/ui/CircleStatus";
import { TargetBarChartDataItem } from "@flightpath/coreui/dist/widgets/targetBarChart";
import I18n from "../localization/I18n";

export const tooltipFormatter = (value, name, props) => {
  if (name.toLowerCase() === "value") name = "Total";
  name = toTitleCase(name);
  return [value, name, props];
};

const toTitleCase = str => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

const UNASSIGNED_COLOR = "#999999";

export const VISUALISATION_COLORS = {
  PURPLE: ["#bfa1ff", "#834ff1", "#3d00c1", UNASSIGNED_COLOR],
  ORANGE: ["#f49d4e", "#f7820d", "#cc660a", UNASSIGNED_COLOR],
  TEAL: ["#b0faff", "#2ae1ee", "#0197a1", UNASSIGNED_COLOR],
  CORAL: ["#feae95", "#ed7650", "#ac5337", UNASSIGNED_COLOR],
  YELLOW: ["#fdd886", "#ffc036", "#dc9700", UNASSIGNED_COLOR],
  GREEN: ["#308096", "#095971", "#093D4C", UNASSIGNED_COLOR],
  INDICATOR: ["#308096", "#095971", "#093d4c", UNASSIGNED_COLOR],
  RAG: ["#f91e1e", "#f7820d", "#1ac541", "#58c8d1"]
};

export function getCircleStatusColor(color: string, index: number): CircleStatusColours {
  switch (index) {
    case 0:
      return CircleStatusColours[`${color}_3`];
    case 1:
      return CircleStatusColours[`${color}_2`];
    case 2:
      return CircleStatusColours[`${color}_1`];
    default:
      return CircleStatusColours.GREY;
  }
}

export function getRAGCircleStatusColor(color: string, index: number): CircleStatusColours {
  switch (index) {
    case 0:
      return CircleStatusColours[`RED`];
    case 1:
      return CircleStatusColours[`AMBER`];
    case 2:
      return CircleStatusColours[`GREEN`];
    case 3:
      return CircleStatusColours[`BLUE`];
    default:
      return CircleStatusColours.GREY;
  }
}

export function mapSkinnyDoughnutChartDataAndColor(data: any[], color: string): any[] {
  data.forEach((entry, index) => {
    entry.name = I18n.t(entry.name);
    entry.color = color === "RAG" ? getRAGCircleStatusColor(color, index) : getCircleStatusColor(color, index);
    entry.tooltip &&
      entry.tooltip.forEach((tooltipContent: any) => {
        tooltipContent.key = I18n.t(tooltipContent.key);
      });
  });
  return data;
}

export function mapTargetBarChartData(data: TargetBarChartDataItem[]) {
  data.map(e => (e.name = I18n.t(e.name)));
  return data;
}

export const TargetBarChartFieldNames: string[] = ["Target", "Stakeholders", "Audiences"];
export const HorizontalBarChartFieldNames: string[] = ["Stakeholders", "Audiences"];
