import { BASE_ROUTE, RouteParams } from "../constants";

export const DASHBOARD_ROUTES = {
  index: {
    path: `${BASE_ROUTE}/dashboard`,
    generateLink: (organisationId: number | string) =>
      `${BASE_ROUTE.replace(RouteParams.organisationId, organisationId + "")}/dashboard`
  },
  impacts: {
    path: `${BASE_ROUTE}/dashboard/impacts`,
    generateLink: (organisationId: number | string) =>
      `${BASE_ROUTE.replace(RouteParams.organisationId, organisationId + "")}/dashboard/impacts`
  },
  actions: {
    path: `${BASE_ROUTE}/dashboard/actions`,
    generateLink: (organisationId: number | string) =>
      `${BASE_ROUTE.replace(RouteParams.organisationId, organisationId + "")}/dashboard/actions`
  },
  userStakeholders: {
    path: `${BASE_ROUTE}/dashboard/userStakeholders`,
    generateLink: (organisationId: number | string) =>
      `${BASE_ROUTE.replace(RouteParams.organisationId, organisationId + "")}/dashboard/userStakeholders`
  },
  overdueActions: {
    path: `${BASE_ROUTE}/dashboard/overdueActions/${RouteParams.type}`,
    generateLink: (organisationId: number | string, type: string) =>
      `${BASE_ROUTE.replace(RouteParams.organisationId, organisationId + "")}/dashboard/overdueActions/${type}`
  },
  upcomingActions: {
    path: `${BASE_ROUTE}/dashboard/upcomingActions/${RouteParams.type}`,
    generateLink: (organisationId: number | string, type: string) =>
      `${BASE_ROUTE.replace(RouteParams.organisationId, organisationId + "")}/dashboard/upcomingActions/${type}`
  }
};
