import { action, makeObservable, observable } from "mobx";
import { GetGridFns } from "../../../../../../core/grids/GridFunctions";
import { IGridModel } from "../../../../../../core/grids/IGridModel";
import { IModalContextModel } from "../../../../../../core/modalZ/context/IModalContext";
import { EntityTypes } from "../../../../../../enums";
import ProjectJobRolesApi, {
  ProjectJobRolesApi as IProjectJobRolesApi
} from "../../../../../../services/api/v2/projectJobRoles/ProjectJobRoles.api";
import ProjectJobRoleHub, {
  ProjectJobRoleHub as IProjectJobRoleHub
} from "../../../../../../services/hubs/ProjectJobRoleHub/ProjectJobRole_hub";
import GridToastService from "../../../../../../services/local/gridToastService/GridToastService";
import { IGridToastService } from "../../../../../../services/local/gridToastService/IGridToastService";
import { GetProjectJobRoleGridViewColumns } from "./ProjectJobRoleGridView_columns";
import { getProjectJobRoleGridModals } from "./ProjectJobRoleGridView_modals";

export class ProjectJobRoleGridViewModel implements IGridModel {
  modalService: IModalContextModel;
  organisationId: number;
  projectId: number;
  @observable isLoading: boolean = true;
  authUser: FP.Entities.IUser;
  projectJobRolesHub: IProjectJobRoleHub;
  type: EntityTypes;
  gridToastService: IGridToastService;
  colDefs: FP.Entities.IColumnDef[];
  projectJobRolesProvider: IProjectJobRolesApi;

  constructor(
    organisationId: number,
    projectId: number,
    authUser: FP.Entities.IUser,
    colDefs: FP.Entities.IColumnDef[]
  ) {
    makeObservable(this);
    this.colDefs = colDefs;
    this.projectJobRolesProvider = ProjectJobRolesApi;
    this.projectJobRolesHub = ProjectJobRoleHub;
    this.gridToastService = GridToastService;
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.authUser = authUser;
    this.type = EntityTypes.PROJECT_JOB_ROLES;
  }

  onMount = async () => {
    this.setIsLoading(false);
  };

  onUnmount = () => {};

  @action
  setIsLoading = isLoading => {
    this.isLoading = isLoading;
  };

  getColumnConfig = () => {
    return GetProjectJobRoleGridViewColumns({
      canEdit: true,
      organisationId: this.organisationId,
      projectId: this.projectId,
      gridFns: GetGridFns(this.projectJobRolesProvider as any, this.organisationId, this.projectId),
      modals: getProjectJobRoleGridModals(this.organisationId, this.projectId)
    });
  };
}
