import { action, makeObservable, observable } from "mobx";
import { IGridModel } from "../../IGridModel";
import { BaseModel } from "../../../util/BaseModel";
import { IModalContextModel } from "../../../modalZ/context/IModalContext";
import ModalContext from "../../../modalZ/context/ModalContext";
import { GetLabelGridViewColumns } from "./LabelGrid_columns";

export class LabelGridViewModel extends BaseModel implements IGridModel {
  authUser: FP.Entities.IUser;
  @observable isLoading: boolean = true;
  organisationId: number;
  projectId: number;
  modalService: IModalContextModel;

  /**
   *
   */
  constructor(organisationId: number, projectId: number, authUser: FP.Entities.IUser) {
    super();
    makeObservable(this);
    this.authUser = authUser;
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.modalService = ModalContext;
  }

  getColumnConfig = () => {
    return GetLabelGridViewColumns({
      canEdit: false
    });
  };

  @action
  onMount = () => {
    this.isLoading = false;
  };

  onUnmount = () => {};
}
