import React from "react";
import { ErrorMessage } from "../../../../../components/ui/ErrorMessage";
import { FORM_COL, ORGANISATION_PERMISSION_SCOPE_OPTIONS } from "../../../../../constants";
import { INIT_AUTOCOMPLETE } from "../../../../../core/forms/controls/autocomplete/Autocomplete_init";
import { IAutocompleteModel } from "../../../../../core/forms/controls/autocomplete/IAutocompleteModel";
import { ITextFieldModel } from "../../../../../core/forms/controls/textField/ITextFieldModel";
import { INIT_TEXT_FIELD } from "../../../../../core/forms/controls/textField/TextField_init";
import { generateFormFieldsFromJson } from "../../../../../core/forms/helpers/FormFieldMappers";
import { Validations } from "../../../../../core/forms/helpers/Validations";
import I18n from "../../../../../core/localization/I18n";

export const getUserCompactFields = (organisationId: number, user: FP.Entities.IUser) => {
  let orgId: Partial<ITextFieldModel> = {
    ...INIT_TEXT_FIELD,
    key: "organisationId",
    inputType: "hidden",
    value: organisationId + "",
    defaultValue: organisationId + ""
  };

  let userId = {
    ...INIT_TEXT_FIELD,
    key: "id",
    inputType: "hidden",
    value: user && user.sub + "",
    defaultValue: user && user.sub + ""
  };

  let permissions = ORGANISATION_PERMISSION_SCOPE_OPTIONS.map(option => ({ ...option, label: I18n.t(option.label) }));
  let permissionValue = permissions.filter(e => e.key === (user as any).permissionScope)[0];

  const permissionScope: Partial<IAutocompleteModel> = {
    ...INIT_AUTOCOMPLETE,
    key: "permissionScope",
    label: <label htmlFor="permissionScope">{I18n.t("forms.permissionScope")} *</label>,
    placeholder: I18n.t("placeholders.permissionScope"),
    fieldClassName: FORM_COL.FULL_WIDTH,
    componentProps: {
      className: "form-control"
    },
    options: permissions,
    isLoading: false,
    valueLabelFn: obj => obj.label,
    validate: function () {
      const self: IAutocompleteModel = this;
      if (Validations.IS_EMPTY(self.extractValue())) {
        self.errorMessage = <ErrorMessage>{I18n.t("validations.permissionScope")}</ErrorMessage>;
        return false;
      }
      return true;
    },
    extractValue: function () {
      return this.value?.key;
    },
    value: permissionValue
  };

  const fields = [];

  fields.push(userId);
  fields.push(orgId);
  fields.push(permissionScope);

  const models = generateFormFieldsFromJson(fields);

  return models;
};
