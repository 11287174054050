import React from "react";
import { ErrorMessage } from "../../../../components/ui/ErrorMessage";
import { FORM_COL, REMOVE_UNSAFE_CHARACTERS } from "../../../../constants";
import { ITextFieldModel } from "../../../../core/forms/controls/textField/ITextFieldModel";
import { INIT_TEXT_FIELD } from "../../../../core/forms/controls/textField/TextField_init";
import { generateFormFieldsFromJson } from "../../../../core/forms/helpers/FormFieldMappers";
import { Validations } from "../../../../core/forms/helpers/Validations";
import I18n from "../../../../core/localization/I18n";

export const getEntityNameMicroFormFields = (submitFunction, inputPlaceholder?: string) => {
    const submitOnEnter = (e: React.KeyboardEvent<any>) => {
        if (e.key === "Enter")
            submitFunction();
    }

    const name: Partial<ITextFieldModel> = {
        ...INIT_TEXT_FIELD,
        key: "name",
        placeholder: inputPlaceholder || I18n.t("placeholders.name"),
        fieldClassName: `${FORM_COL.FULL_WIDTH} form-control__name-input`,
        autoFocus: true,
        onKeyUp: (e) => submitOnEnter(e),
        validate: function () {
            const self: ITextFieldModel = this;
            let res = true;
            if (Validations.IS_EMPTY(self.extractValue())) {
                self.errorMessage = <ErrorMessage>{I18n.t("validations.name")}</ErrorMessage>;
                res = false;
            }
            return res;
        },
        extractValue: function () {
            return REMOVE_UNSAFE_CHARACTERS(this.value.toString());
        }
    };

    const fields = [];
    fields.push(name);

    const models = generateFormFieldsFromJson(fields);
    return models;
};
