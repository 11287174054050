import { ColDef } from "ag-grid-community";
import { colNoteCount, colSelected, colTags } from "../../../../../core/grids/CommonGridColumns";
import {
  colActionNames,
  colImpactGroups,
  colImpactNames,
  colJobRoles,
  colProcessApps,
  colProcessL1,
  colProcessL2,
  colProcessL3,
  colProcessRoles,
  colScopeItems,
  colUniqueIdentifier
} from "./ProcessesGridView_baseColumns";
import { IProcessGridModals } from "./ProcessesGridView_modals";

export interface IGetProcessGridViewColumns {
  canEdit: boolean;
  modals: IProcessGridModals;
  columns: string[];
}

export const GetProcessGridViewColumns = (modelProps: IGetProcessGridViewColumns): ColDef[] => {
  let result: ColDef[] = [];
  let processColumns = {
    selector: colSelected(),
    uniqueIdentifier: colUniqueIdentifier(),
    processL1: colProcessL1(),
    processL2: colProcessL2(),
    processL3: colProcessL3(),
    scopeItemNames: colScopeItems(),
    impactNames: colImpactNames(),
    actionNames: colActionNames(),
    impactGroupNames: colImpactGroups(
      modelProps.canEdit,
      modelProps.canEdit && modelProps.modals.showProcessImpactGroupsModalFn
    ),
    processRoles: colProcessRoles(modelProps.canEdit, modelProps.canEdit && modelProps.modals.showProcessRoleModalFn),
    processApps: colProcessApps(modelProps.canEdit, modelProps.canEdit && modelProps.modals.showProcessAppModalFn),
    processJobRoles: colJobRoles(),
    noteCount: colNoteCount(modelProps.canEdit, modelProps.modals.showNotesModalFN),
    tags: colTags(modelProps.canEdit, modelProps.modals.showTagsModalFn)
  };

  modelProps.columns.forEach(e => {
    result.push(processColumns[e]);
  });

  return result;
};

export const GenerateColumnDefinitions = (modelProps: IGetProcessGridViewColumns): ColDef[] => {
  return GetProcessGridViewColumns(modelProps);
};
