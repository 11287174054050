import { action, observable } from "mobx";
import { GetGridFns, IGridFns } from "../../../../../../../core/grids/GridFunctions";
import { IGridModel } from "../../../../../../../core/grids/IGridModel";
import { GetStakeholderCapacityGridViewColumns } from "./StakeholderCapacityGrid_Grid_Columns";
import { Enums } from "../../../../../../../enums";

export class StakeholderCapacityGrid_GridModel implements IGridModel {
  @observable isLoading: boolean = true;
  organisationId: number;
  projectId: number;
  gridFns: IGridFns;

  /**
   *
   */
  constructor(organisationId: number) {
    this.organisationId = organisationId;
    this.gridFns = GetGridFns(null, this.organisationId, 0);
  }

  getColumnConfig = () => {
    return GetStakeholderCapacityGridViewColumns(Enums.StakeholderType.INDIVIDUAL);
  };

  @action
  onMount = () => {
    this.isLoading = false;
  };

  onUnmount = () => {};
}

export class AudienceCapacityGrid_GridModel implements IGridModel {
  @observable isLoading: boolean = true;
  organisationId: number;
  projectId: number;
  gridFns: IGridFns;

  /**
   *
   */
  constructor(organisationId: number) {
    this.organisationId = organisationId;
    this.gridFns = GetGridFns(null, this.organisationId, 0);
  }

  getColumnConfig = () => {
    return GetStakeholderCapacityGridViewColumns(Enums.StakeholderType.AUDIENCE);
  };

  @action
  onMount = () => {
    this.isLoading = false;
  };

  onUnmount = () => {};
}
