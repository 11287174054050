import { ColDef } from "ag-grid-community";
import { colDynamicSimpleText, colSelected } from "../../CommonGridColumns";
import I18n from "../../../localization/I18n";

export interface IGetLabelGridViewColumns {
  canEdit: boolean;
}

export const GetLabelGridViewColumns = (modelProps: IGetLabelGridViewColumns): ColDef[] => {
  let result: ColDef[] = [];
  result.push(colSelected());
  result.push(colDynamicSimpleText("text", I18n.get("phrases.tags"), modelProps.canEdit));

  return result;
};
