import React, { useCallback } from "react";
import SortableTree from "@flightpath/react-sortable-tree";
import { TreeEditorModel, TreeNodeInputModel } from "./TreeEditor_model";
import { observer } from "mobx-react";
import { Input } from "../../ui/_forms/Input";
import { ButtonIcon, ButtonTypes } from "../../ui/Button";
import { Spinner } from "../../ui/Spinner";
import { Enums } from "../../../enums";
import I18n from "../../../core/localization/I18n";
import { IconSymbols } from "../../ui/Icon";

export interface TreeEditorProps {
  model: TreeEditorModel;
}

export const TreeEditor: React.FC<TreeEditorProps> = observer(({ model }) => {
  const generateNodeProps = useCallback(
    ({ node, path }) => {
      return model.generateNodeProps(node as any, path);
    },
    [model]
  );

  return (
    <SortableTree
      onMoveNode={model.hierarchyChanged}
      searchQuery={model.searchValue}
      searchMethod={s => {
        return s.searchQuery !== "" && s.node.title.toString().toLowerCase().indexOf(s.searchQuery.toLowerCase()) >= 0;
      }}
      generateNodeProps={generateNodeProps}
      treeData={model.treeData}
      onChange={model.onTreeDataChanged}
    />
  );
});

export interface TreeNodeInputProps {
  model: TreeNodeInputModel;
}

const TreeNodeInput: React.FunctionComponent<TreeNodeInputProps> = observer(({ model }) => {
  const mode = model.getMode();

  switch (mode) {
    case "display":
      return (
        <div className="d-flex align-items-center">
          <p className="mb-0">{model.value}</p>
        </div>
      );
    case "loading":
      return (
        <div className="row m-0 p-0 align-items-center" style={{ width: "210px" }}>
          <div className="col-10 p-0 m-0">
            <p className="mb-0">{model.value}</p>
          </div>
          <div className="col-2 p-0 m-0 ">
            <Spinner size={Enums.UiSizes.XXS} />
          </div>
        </div>
      );
    default:
      return (
        <div className="d-flex align-items-center">
          <Input
            value={model.value}
            id={`${model.id}CreateInput`}
            className="input--sm"
            onChange={e => {
              model.setValue(e);
            }}
            borderStyle="underline"
            autoFocus={true}
          />
          {model.onConfirm ? (
            <ButtonIcon
              id={`Create${model.id}Button`}
              className="float-right"
              onClick={() => model.onConfirm(model.value)}
              isDisabled={model.value === ""}
              symbol={IconSymbols.Check}
              title={I18n.t("phrases.confirm")}
              type={ButtonTypes.LINK}
            />
          ) : null}
          {model.onCancel ? (
            <ButtonIcon
              id={`Cancel${model.id}Button`}
              className="float-right"
              onClick={() => model.onCancel()}
              symbol={IconSymbols.Close}
              title={I18n.t("phrases.cancel")}
              type={ButtonTypes.LINK}
            />
          ) : null}
        </div>
      );
  }
});

export { TreeNodeInput };
