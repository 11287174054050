import {
  GridSideModalTitle,
  gridSideModalAnimationOpts,
  gridSideModalComponentProps
} from "../../../../../core/grids/GridSideModals";
import I18n from "../../../../../core/localization/I18n";
import ModalContext from "../../../../../core/modalZ/context/ModalContext";
import { ActionCountSidebar } from "./modals/ActionCountSidebar/ActionCountSidebar_view";
import { AudienceCountSidebar } from "./modals/AudienceCountSidebar/AudienceCountSidebar_view";
import { ChangeTeamActionCountSidebar } from "./modals/ChangeTeamActionCountSidebar/ChangeTeamActionCountSidebar_view";
import { ChangeTeamImpactCountSidebar } from "./modals/ChangeTeamImpactCountSidebar/ChangeTeamImpactCountSidebar_view";
import { ImpactCountSidebar } from "./modals/ImpactCountSidebar/ImpactCountSidebar_view";
import { ProjectTeamCountSidebar } from "./modals/ProjectTeamCountSidebar/ProjectTeamCountSidebar_view";
import { StakeholderCountSidebar } from "./modals/StakeholderCountSidebar/StakeholderCountSidebar_view";

export const SHOW_IMPACT_COUNT_MODAL = data => {
  ModalContext.show({
    title: <GridSideModalTitle name={`${data.name}`} field={I18n.t("phrases.capacityGrid_impactCount")} />,
    showClose: true,
    content: (
      <ImpactCountSidebar
        stakeholderId={data.id}
        stakeholderName={`${data.name}`}
        stakeholderType={data.stakeholderType}
      ></ImpactCountSidebar>
    ),
    componentProps: gridSideModalComponentProps,
    animationOptions: gridSideModalAnimationOpts
  });
};
export const SHOW_ACTION_COUNT_MODAL = data => {
  ModalContext.show({
    title: <GridSideModalTitle name={`${data.name}`} field={I18n.t("phrases.capacityGrid_actionCount")} />,
    showClose: true,
    content: (
      <ActionCountSidebar
        stakeholderId={data.id}
        stakeholderName={`${data.name}`}
        stakeholderType={data.stakeholderType}
      ></ActionCountSidebar>
    ),
    componentProps: gridSideModalComponentProps,
    animationOptions: gridSideModalAnimationOpts
  });
};
export const SHOW_USER_ACTION_COUNT_MODAL = data => {
  ModalContext.show({
    title: <GridSideModalTitle name={`${data.name}`} field={I18n.t("phrases.changeTeamCapacity_actionCount")} />,
    showClose: true,
    content: (
      <ChangeTeamActionCountSidebar
        userId={data.id}
        userName={`${data.firstName} ${data.lastName}`}
      ></ChangeTeamActionCountSidebar>
    ),
    componentProps: gridSideModalComponentProps,
    animationOptions: gridSideModalAnimationOpts
  });
};
export const SHOW_USER_IMPACT_COUNT_MODAL = data => {
  ModalContext.show({
    title: (
      <GridSideModalTitle
        name={`${data.firstName} ${data.lastName}`}
        field={I18n.t("phrases.changeTeamCapacity_impactCount")}
      />
    ),
    showClose: true,
    content: (
      <ChangeTeamImpactCountSidebar
        userId={data.id}
        userName={`${data.firstName} ${data.lastName}`}
      ></ChangeTeamImpactCountSidebar>
    ),
    componentProps: gridSideModalComponentProps,
    animationOptions: gridSideModalAnimationOpts
  });
};
export const SHOW_STAKEHOLDER_COUNT_MODAL = data => {
  ModalContext.show({
    title: (
      <GridSideModalTitle
        name={`${data.firstName} ${data.lastName}`}
        field={I18n.t("phrases.capacityGrid_stakeholderCount")}
      />
    ),
    showClose: true,
    content: (
      <StakeholderCountSidebar
        userId={data.id}
        userName={`${data.firstName} ${data.lastName}`}
      ></StakeholderCountSidebar>
    ),
    componentProps: gridSideModalComponentProps,
    animationOptions: gridSideModalAnimationOpts
  });
};
export const SHOW_AUDIENCE_COUNT_MODAL = data => {
  ModalContext.show({
    title: <GridSideModalTitle name={`${data.name}`} field={I18n.t("phrases.capacityGrid_audienceCount")} />,
    showClose: true,
    content: <AudienceCountSidebar userId={data.id} userName={`${data.name}`}></AudienceCountSidebar>,
    componentProps: gridSideModalComponentProps,
    animationOptions: gridSideModalAnimationOpts
  });
};
export const SHOW_PROJECT_TEAM_COUNT_MODAL = data => {
  ModalContext.show({
    title: (
      <GridSideModalTitle
        name={`${data.firstName} ${data.lastName}`}
        field={I18n.t("phrases.capacityGrid_projectCount")}
      />
    ),
    showClose: true,
    content: <ProjectTeamCountSidebar userId={data.id}></ProjectTeamCountSidebar>,
    componentProps: gridSideModalComponentProps,
    animationOptions: gridSideModalAnimationOpts
  });
};
