import Http from "../Http";
import { IFilteredOptions } from "../filteredApi/FilteredApiModel";
import { FilteredOrganisationApiModel } from "../filteredApi/FilteredOrganisationApiModel";
import { AxiosInstance, AxiosRequestConfig } from "axios";

export class RolesApi extends FilteredOrganisationApiModel<FP.Entities.IRole> {
  controller: string = "roles";

  constructor(http: AxiosInstance) {
    super(http, "roles");
    // this.url = `${this.http.url}/${this.version}/organisations`;
  }

  getFilteredAsync = async (
    filterOptions: Partial<IFilteredOptions>,
    config?: AxiosRequestConfig,
    organisationId?: number
  ) => {
    const query = this.getRequestQuery(filterOptions);
    const url = `${this.url}/${organisationId}/${this.controller}${query}`;
    const res = await this.http.get(url, config);
    if (res && res.status === 200) {
      return res.data;
    }
  };
}

const instance = new RolesApi(Http);
export default instance;
