import { useState } from "react";
import { FileDrop } from "react-file-drop";
import { useCurrentOrganisationId } from "../../../../../services/local/organisationContext/OrganisationContextModel";
import { useParams } from "react-router-dom";
import { ActionsUploaderModel, ActionsUploaderViews } from "./ActionsUploader_model";
import "./_actions-uploader.scss";
import { IF } from "../../../../../components/hoc/If";
import { PositionedSpinner } from "../../../../../components/ui/PositionedSpinner";
import { observer } from "mobx-react-lite";
import { Button, ButtonTypes } from "../../../../../components/ui/Button";
import { Icon, IconSymbols } from "../../../../../components/ui/Icon";
import { Tooltip } from "../../../../../components/ui/Tooltip";
import { TooltipPositions } from "@flightpath/coreui/dist/ui/Tooltip";
import { UiSizes } from "@flightpath/coreui/dist/enums";
import { Panel } from "@flightpath/coreui";
import I18n from "../../../../../core/localization/I18n";

export const ActionsUploader: React.FC = observer(() => {
  const organisationId = useCurrentOrganisationId();
  const { projectId } = useParams();
  const [model] = useState(() => new ActionsUploaderModel(organisationId, +projectId));
  return (
    <div className="actions-uploader pb-5 px-7">
      <div className="mb-3 d-flex align-items-center">
        <Button onClick={model.downloadTemplate} type={ButtonTypes.OUTLINE_PRIMARY}>
          <Icon symbol={IconSymbols.Download} /> <span className="ml-3">Download Template</span>
        </Button>
        <Tooltip
          position={TooltipPositions.RIGHT}
          shownElement={<Icon symbol={IconSymbols.Info} size={UiSizes.MD} style={{ marginLeft: "8px" }} />}
          triggeredOn="hover"
        >
          <Panel.Panel
            style={{ width: "400px" }}
            className="py-1 px-3"
            background={Panel.PanelBackgrounds.BG_PRIMARY_LIGHT}
            hasShadow={true}
            hasBorderRadius={true}
          >
            <ul style={{ listStyleType: "number" }}>
              <li className="p-1">{I18n.t("phrases.uploadTemplateInstruction_1")}</li>
              <li className="p-1">{I18n.t("phrases.uploadTemplateInstruction_2")}</li>
              <li className="p-1">{I18n.t("phrases.uploadTemplateInstruction_3")}</li>
              <li className="p-1">{I18n.t("phrases.uploadTemplateInstruction_4")}</li>
              <li className="p-1">{I18n.t("phrases.uploadTemplateInstruction_5")}</li>
              <li className="p-1">{I18n.t("phrases.uploadTemplateInstruction_6")}</li>
            </ul>
            <p className="p-1 mt-1">
              NOTE: <em>{I18n.t("phrases.uploadTemplateInstruction_7")}</em>
            </p>
          </Panel.Panel>
        </Tooltip>
        ´
      </div>
      <FileDrop onDrop={(files, event) => model.processFiles(files, event)}>
        <IF condition={model.viewState === ActionsUploaderViews.IsUploading}>
          <div className="actions-uploader__progress">
            <PositionedSpinner />
          </div>
        </IF>

        <IF condition={model.viewState === ActionsUploaderViews.Initial}>
          <div className="actions-uploader__drop-area">
            <h2>Upload actions</h2>
            <p>Drop your csv file here to upload the actions</p>
          </div>
        </IF>

        <IF condition={model.viewState === ActionsUploaderViews.IsNotOneFileError}>
          <div className="actions-uploader__drop-area">
            <h2>Upload Error</h2>
            <p>Please select only one csv file.</p>
            <Button onClick={() => model.setViewState(ActionsUploaderViews.Initial)} type={ButtonTypes.OUTLINE_PRIMARY}>
              Try again
            </Button>
          </div>
        </IF>
      </FileDrop>
    </div>
  );
});
