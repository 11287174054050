import { action, makeObservable, observable } from "mobx";
import * as React from "react";
import { ButtonTypes } from "../../../../components/ui/Button";
import { Panel } from "../../../../components/ui/Panel";
import PermissionsContext from "../../../../contexts/permissions/PermissionsContext";
import { PermissionFields } from "../../../../contexts/permissions/PermissionsTypes";
import ProgressIndicatorModel, {
  ProgressIndicatorModel as IProgressIndicatorModel
} from "../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import I18n from "../../../../core/localization/I18n";
import { IUiAction, UiActionRenderers } from "../../../../core/uiAction/IUiAction";
import { Animations } from "../../../../core/util/Animations";
import { BaseModel } from "../../../../core/util/BaseModel";
import { Enums } from "../../../../enums";
import ConcernsApi, { ConcernsApi as IConcernsApi } from "../../../../services/api/v2/concerns/Concerns.api";
import ProjectStakeholdersApi, {
  ProjectStakeholdersApi as IProjectStakeholdersApi
} from "../../../../services/api/v2/projectStakeholders/ProjectStakeholders.api";
import ProjectsApi, { ProjectsApi as IProjectsApi } from "../../../../services/api/v2/projects/Projects.api";
import { ILocalStorageService } from "../../../../services/local/localStorageService/ILocalStorageService";
import LocalStorageService from "../../../../services/local/localStorageService/LocalStorageService";
import { getConcernFormFields } from "../../forms/concern/ConcernFormFields_data";
import { SingleFormModel } from "../../forms/singleFormModel/SingleForm_model";
import { IFlightPathApiResponse } from "../../../../services/api/v2/BaseApiModel";
import { IModalContextModel } from "../../../../core/modalZ/context/IModalContext";
import ModalContext from "../../../../core/modalZ/context/ModalContext";

export class StakeholderConcernsViewModel extends BaseModel {
  modalService: IModalContextModel;
  projectProvider: IProjectsApi;
  concernsProvider: IConcernsApi;
  httpProgress: IProgressIndicatorModel;
  stakeholderId: number;
  projectId: number;
  isRouteView: boolean;
  @observable isLoading: boolean = true;
  @observable isSearchMode: boolean = false;
  @observable searchValue: string = "";
  @observable.ref projectStakeholder: FP.Entities.IProjectStakeholder;
  @observable.ref concerns: FP.Entities.IConcern[] = [];
  @observable.ref filteredConcerns: FP.Entities.IConcern[] = [];

  concernCardActions: IUiAction<FP.Entities.IConcern>[] = [];
  projectStakeholderProvider: IProjectStakeholdersApi;
  organisationId: number;
  localStorageService: ILocalStorageService;

  /**
   *
   */
  constructor(stakeholderId: number, projectId: number, isRouteView: boolean = true) {
    super();
    makeObservable(this);
    this.isRouteView = isRouteView;
    this.projectProvider = ProjectsApi;
    this.projectStakeholderProvider = ProjectStakeholdersApi;
    this.modalService = ModalContext;
    this.localStorageService = LocalStorageService;
    this.organisationId = parseInt(this.localStorageService.get(Enums.LocalCookies.ORGANISATION_ID));
    this.concernsProvider = ConcernsApi;
    this.httpProgress = ProgressIndicatorModel;
    this.stakeholderId = stakeholderId;
    this.projectId = projectId;
    this.setConcernCardActions();
  }

  onMount = () => {
    if (this.isRouteView) {
      this.loadProjectStakeholder(this.projectId, this.stakeholderId);
    }
  };

  setConcernCardActions = () => {
    const canEditStakeholder = PermissionsContext.canEditField(
      PermissionFields.STAKEHOLDERS,
      this.organisationId,
      this.projectId
    );
    if (canEditStakeholder) {
      this.concernCardActions.push(
        {
          id: "action1",
          label: I18n.t("phrases.edit"),
          onAction: (ev, concern: FP.Entities.IConcern) => {
            this.showAddConcernModal(concern);
          },
          componentProps: {
            type: ButtonTypes.LINK
          },
          rendersIn: UiActionRenderers.BUTTON
        },
        {
          id: "action2",
          label: I18n.t("phrases.remove"),
          onAction: (ev, concern: FP.Entities.IConcern) => {
            this.showConcernConfirmDeleteModal(concern);
          },
          componentProps: {
            type: ButtonTypes.LINK
          },
          rendersIn: UiActionRenderers.BUTTON
        }
      );
    }
  };

  onUnmount = () => {
    this.resetSearch();
  };

  @action
  loadProjectStakeholder = async (projectId: number, stakeholderId: number) => {
    this.isLoading = true;
    const res = await this.projectStakeholderProvider.getStakeholderById(this.organisationId, projectId, stakeholderId);
    if (!res || res.isError) return;
    this.setProjectStakeholder(res.payload as FP.Entities.IProjectStakeholder);
    this.isLoading = false;
  };

  @action
  setProjectStakeholder = (proStakeholder: FP.Entities.IProjectStakeholder) => {
    if (!this.projectId || !this.stakeholderId) {
      this.projectId = proStakeholder.project.id;
      this.stakeholderId = proStakeholder.stakeholder.id;
    }
    this.projectStakeholder = proStakeholder;
    this.concerns = proStakeholder.concerns;
    this.filteredConcerns = proStakeholder.concerns;
    this.isLoading = false;
  };

  @action
  updateSearchValue = (e: React.FormEvent<HTMLInputElement>) => {
    this.searchValue = e.currentTarget.value;
    this.filterConcerns();
  };

  @action
  showSearchMode = () => {
    this.isSearchMode = true;
  };

  @action
  hideSearchMode = () => {
    this.isSearchMode = false;
  };

  @action
  resetSearch = () => {
    this.searchValue = "";
    this.filterConcerns();
    this.hideSearchMode();
  };

  @action
  filterConcerns = () => {
    if (this.searchValue) {
      const lowerSearch = this.searchValue.toLowerCase();
      this.filteredConcerns = this.concerns.filter(concern => {
        const lowerOwner = `${concern.owner.firstName} ${concern.owner.lastName}`.toLowerCase();
        const lowerSummary = concern.summary.toLowerCase();

        return lowerOwner.includes(lowerSearch) || lowerSummary.includes(lowerSearch);
      });
    } else {
      this.filteredConcerns = this.concerns;
    }
  };

  showConcernConfirmDeleteModal = (concern: FP.Entities.IConcern) => {
    return new Promise(resolve => {
      this.modalService.showConfirmDialog(
        <h1 className="mt-4">{I18n.t("phrases.confirm")}</h1>,
        <div className="container-fluid">
          <div className="row">
            <div className="col">{I18n.t("phrases.confirmDelete", { name: I18n.t("phrases.thisKeyConcern") })}</div>
          </div>
        </div>,
        I18n.t("phrases.yes"),
        I18n.t("phrases.no"),
        {
          wrapWidth: "small",
          spacing: "small",
          position: "middle",
          panelProps: {
            background: Panel.PanelBackgrounds.BG_WHITE
          }
        },
        async () => {
          this.modalService.hide();
          this.httpProgress.showOverlay();
          const res = await this.concernsProvider.remove(this.organisationId, this.projectId, concern.id);
          this.httpProgress.hideOverlay();

          if (!res || res.isError) {
            return;
          }

          this.loadProjectStakeholder(this.projectId, this.stakeholderId);
          resolve(true);
        },
        () => {
          this.modalService.hide();
        },
        ButtonTypes.DANGER
      );
    });
  };

  showAddConcernModal = (concern?: FP.Entities.IConcern) => {
    let formFields = getConcernFormFields(concern);
    let formModel = new SingleFormModel();
    let actions: IUiAction<any>[] = [
      {
        id: "action1",
        label: I18n.t("phrases.cancel"),
        onAction: ev => {
          this.modalService.hide();
        },
        componentProps: {
          type: ButtonTypes.LINK,
          className: "ml-auto"
        },
        rendersIn: UiActionRenderers.BUTTON
      },
      {
        id: "action2",
        label: I18n.t(concern ? "phrases.update" : "phrases.add"),
        onAction: async ev => {
          const formRes = await formModel.submit();
          if (!formRes) return;
          this.modalService.hide();
          formRes.projectStakeholderId = this.projectStakeholder.id;

          let res: IFlightPathApiResponse<FP.Entities.IConcern>;
          this.httpProgress.showOverlay();
          if (concern) {
            res = await this.concernsProvider.update(
              this.organisationId,
              this.projectId,
              concern.id,
              formRes as FP.Entities.IConcern
            );
          } else {
            res = await this.concernsProvider.create(
              this.organisationId,
              this.projectId,
              formRes as FP.Entities.IConcern
            );
          }
          this.httpProgress.hideOverlay();

          if (!res || res.isError) {
            return;
          }

          this.loadProjectStakeholder(this.projectId, this.stakeholderId);
        },
        componentProps: {
          className: "ml-2"
        },
        rendersIn: UiActionRenderers.BUTTON
      }
    ];
    formModel.formFields = formFields;
    formModel.actions = actions;

    return new Promise(resolve => {
      this.modalService.show({
        showClose: true,
        title: <h1 className="mt-6">{I18n.t(concern ? "phrases.editKeyConcern" : "phrases.addKeyConcern")}</h1>,
        content: <div className="container-fluid">{formModel.renderComponent()}</div>,
        componentProps: {
          wrapHeight: "full",
          wrapWidth: "small",
          position: "right",
          panelProps: {
            background: Panel.PanelBackgrounds.BG_LIGHT
          }
        },
        animationOptions: {
          animateIn: Animations.SLIDE_IN_RIGHT,
          animateOut: Animations.SLIDE_OUT_RIGHT,
          speed: 5
        }
      });
    });
  };
}
