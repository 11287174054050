import * as React from "react";
import { IModel } from "../../../../core/util/BaseModel";
import { IUiAction } from "../../../../core/uiAction/IUiAction";
import { IFormFieldModel } from "../../../../core/forms/formField/IFormField";
import { SingleForm } from "./SingleForm_view";
import { observable, computed, makeObservable, action } from "mobx";
import { FormViewModel } from "../../../../core/forms/baseForm/FormViewModel";

export interface ISingleFormModel extends IModel {
  formFields: IFormFieldModel<any, any>[];
  hasChanges: boolean;
  isSaving: boolean;
  className: string;
  shouldHideToast?: boolean;
  resetFields: () => void;
  actions: IUiAction<any>[];
  setActions: (actions: IUiAction<any>[]) => void;
  onMount: () => void;
}

export class SingleFormModel extends FormViewModel implements ISingleFormModel {
  @observable.ref actions: IUiAction<any>[] = [];
  formFields: IFormFieldModel<any, any>[] = [];
  className: string;
  shouldHideToast?: boolean = false;

  @observable isSaving: boolean = false;

  @computed get hasChanges(): boolean {
    if (this.isSaving) return false;
    for (const field of this.formFields) {
      if (field.hasChanges) {
        return true;
      }
    }
    return false;
  }

  constructor() {
    super();
    makeObservable(this);
  }

  setShouldHideToast = (val: boolean) => {
    this.shouldHideToast = val;
  };

  onMount = () => {};

  resetFields = () => {
    this.formFields.forEach(e => {
      if (e.key === "progressStatus") {
        e.value = 0;
      } else {
        e.reset();
      }
    });
  };

  @action
  setActions = (actions: IUiAction<any>[]) => {
    this.actions = actions;
  };

  resetFieldByKey = (key: string) => {
    const foundField = this.formFields.find(field => field.key === key);

    if (foundField) {
      foundField.reset();
    }
  };

  renderComponent = (): React.ReactNode => {
    return <SingleForm model={this} shouldHideToast={this.shouldHideToast} />;
  };
}
