import * as React from "react";
import { UsersSettingsModel } from "./UsersSettings_model";
import { IconSymbols } from "../../../../../components/ui/Icon";
import I18n from "../../../../../core/localization/I18n";
import { Enums, UiSizes } from "../../../../../enums";
import { Link } from "react-router-dom";
import { UiActionRenderers } from "../../../../../core/uiAction/IUiAction";
import { AddSmartTableColumnSort } from "../../../../../services/local/smartTableViewManager/utils";
import { ISmartTableHeaderItem } from "@flightpath/coreui/dist/widgets/smartTable/ISmartTable";
import { UserAvatarContent } from "../../../../../components/widgets/UserAvatarContent/UserAvatarContent_view";
import PermissionsContext from "../../../../../contexts/permissions/PermissionsContext";

export const GetUserTableConfig = (parentModel: UsersSettingsModel, organisationId: number) => {
  let actions = [];
  const canEditOrg = PermissionsContext.canEditOrganisation(parentModel.organisationId);
  if (canEditOrg) {
    actions = [
      {
        id: "action1",
        hrefFn: (obj: any) => `/organisations/${organisationId}/users/${obj.sub}`,
        label: I18n.t("phrases.view"),
        rendersIn: UiActionRenderers.LINK_BUTTON_ICON,
        componentProps: {
          className: "px-2",
          type: "link",
          symbol: IconSymbols.Review,
          linkElement: Link
        }
      },
      {
        id: "action1",
        label: I18n.t("phrases.edit"),
        rendersIn: UiActionRenderers.BUTTON_ICON,
        componentProps: {
          className: "px-2",
          type: "link",
          symbol: IconSymbols.Pencil,
          linkElement: Link
        },
        onAction: (ev, row) => parentModel.showUserEditForm(row)
      },
      {
        id: "action2",
        label: I18n.t("phrases.remove"),
        onAction: (ev, row: any) => {
          parentModel.showUserConfirmDeleteModal(row);
        },
        componentProps: {
          className: "px-2",
          type: "link",
          symbol: IconSymbols.Trash
        },
        rendersIn: UiActionRenderers.BUTTON_ICON
      }
    ];
  }
  return {
    actions,
    colHeaders: [
      {
        id: "systemUser.firstName",
        content: <h5 className="mb-0">{I18n.t("table.user")}</h5>,
        selector: (obj: FP.Entities.IUser) => {
          return <p className="mb-0">{obj.firstName + " " + obj.lastName}</p>;
        },
        columnWidthSize: UiSizes.XS,
        action: function (header: ISmartTableHeaderItem) {
          AddSmartTableColumnSort(header, parentModel.filterModel);
        },
        showSortIcons: true
      },
      {
        id: "systemUser.email",
        content: <h5 className="mb-0">{I18n.t("table.email")}</h5>,
        selector: (obj: FP.Entities.IUser) => {
          return <p className="mb-0 text-break">{obj.email}</p>;
        },
        action: function (header: ISmartTableHeaderItem) {
          AddSmartTableColumnSort(header, parentModel.filterModel);
        },
        columnWidthSize: UiSizes.XS,
        showSortIcons: true
      },
      {
        id: "permissionScope",
        content: <h5 className="mb-0">{I18n.t("table.permissionScope")}</h5>,
        selector: (obj: FP.Entities.IOrganisation) => {
          return <p className="mb-0">{I18n.t(Enums.Translator.PermissionScope(obj.permissionScope))}</p>;
        },
        action: function (header: ISmartTableHeaderItem) {
          AddSmartTableColumnSort(header, parentModel.filterModel);
        },
        showSortIcons: true
      },

      {
        id: "invitedBy",
        content: <h5 className="mb-0">{I18n.t("table.invitedBy")}</h5>,
        selector: (obj: FP.Entities.IUser) => {
          return <UserAvatarContent user={obj} />;
        }
      },
      {
        id: "systemUser.isActive",
        content: <h5 className="mb-0">{I18n.t("table.status")}</h5>,
        selector: (obj: FP.Entities.IUser) => {
          return <p className="mb-0">{I18n.t(obj.isActive ? "phrases.active" : "phrases.notActive")}</p>;
        },
        action: function (header: ISmartTableHeaderItem) {
          AddSmartTableColumnSort(header, parentModel.filterModel);
        },
        columnWidthSize: UiSizes.XXS,
        showSortIcons: true
      }
    ] as any,
    onRowClick: row => {},
    tableProps: { id: "UsersTable" }
  };
};
