import OktaAuth, { OktaAuthOptions } from "@okta/okta-auth-js";
import { IsDev, IsStaging } from "../../../core/util/EnvironmentHelper";

const CLIENT_ID = window.appConfig.oauth_client_id;
export const CALLBACK_PATH = "/login_complete";

const ISSUER = window.appConfig.oauth_authority; // `https://${OKTA_DOMAIN}/oauth2/default`;
const REDIRECT_URI = `${window.appConfig.baseUrl}${CALLBACK_PATH}`;
const SCOPES = "openid profile email groups offline_access";

export const OKTA_CONFIG: OktaAuthOptions = {
  issuer: ISSUER,
  clientId: CLIENT_ID,
  redirectUri: REDIRECT_URI,
  scopes: SCOPES.split(/\s+/),
  devMode: false, //IsDev() || IsStaging(),
  tokenManager: {
    storage: "sessionStorage",
    autoRenew: true,
    expireEarlySeconds: 1 * 60,
    autoRemove: false
  },
  storageManager: {
    token: {
      storageType: "sessionStorage",
      storageTypes: ["localStorage", "sessionStorage"]
    }
  }
};

const authService = new OktaAuth(OKTA_CONFIG);

if (IsDev() || IsStaging()) {
  authService.tokenManager.on("error", (error: any) => {
    console.error("Token Error", error);
  });

  authService.tokenManager.on("expired", (event: any) => {
    console.info(`Token expired ${new Date()}`);
  });
}

export default authService;
