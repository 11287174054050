import { observer } from "mobx-react";
import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Accordion } from "../../../../../components/ui/Accordion";
import { ButtonTypes, LinkButton } from "../../../../../components/ui/Button";
import { Icon, IconSymbols } from "../../../../../components/ui/Icon";
import { Panel } from "../../../../../components/ui/Panel";
import { PositionedSpinner } from "../../../../../components/ui/PositionedSpinner";
import { TargetLabel } from "../../../../../components/widgets/TargetLabel";
import { PaginationModel } from "../../../../../components/widgets/pagination/Pagination_model";
import { Pagination } from "../../../../../components/widgets/pagination/Pagination_view";
import { FilterModel, IFilterModel } from "../../../../../core/filter/Filter_model";
import I18n from "../../../../../core/localization/I18n";
import { Animations } from "../../../../../core/util/Animations";
import { Enums } from "../../../../../enums";
import UserReportsApi from "../../../../../services/api/v2/userReports/UserReports.api";
import { useCurrentOrganisationId } from "../../../../../services/local/organisationContext/OrganisationContextModel";
import { StakeholderProgress } from "./UserStakeholders_config";
import { UserStakeholdersModel } from "./UserStakeholders_model";

export interface UserStakeholdersProps {
  model: UserStakeholdersModel;
}

const UserStakeholders: React.FunctionComponent<UserStakeholdersProps> = observer(({ model }) => {
  React.useEffect(() => {
    model.onMount();
    return model.onUnmount;
  }, [model]);

  if (model.isLoading) {
    return <PositionedSpinner />;
  }

  return (
    <div className={`container-fluid pt-6 ${Animations.FP_ZOOM_IN} speed-4`}>
      <div className="row mb-5">
        <div className="col">
          <LinkButton type={ButtonTypes.LINK_BIG} size={Enums.UiSizes.MD} href="/" className="p-0">
            <Icon className="mr-2" symbol={IconSymbols.ChevronLeft} />
            {I18n.t("phrases.goBackDashboard")}
          </LinkButton>
        </div>
      </div>
      <div className="row mb-5">
        <div className="col">
          <h1 className="d-inline-block mb-0 mt-1">{I18n.t("phrases.myStakeholders")}</h1>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <Panel.Panel type={Panel.PanelTypes.BORDER_LINES_Y} className="row mx-0">
            <div className="col-3">
              <h4 className="mb-0 strong my-3">{I18n.t("table.stakeholders")}</h4>
            </div>
            <div className="col-2 p-0">
              <h4 className="mb-0 strong my-3">{I18n.t("table.project")}</h4>
            </div>
            <div className="col-2 p-0">
              <h4 className="mb-0 strong my-3">{I18n.t("table.impact")}</h4>
            </div>
            <div className="col-2 p-0">
              <h4 className="mb-0 strong my-3">{I18n.t("table.influence")}</h4>
            </div>
            <div className="col-1 p-0">
              <h4 className="mb-0 strong my-3">{I18n.t("table.sentiment")}</h4>
            </div>
            <div className="col-1 p-0">
              <h4 className="mb-0 strong my-3">{I18n.t("table.commitment")}</h4>
            </div>
            <div className="col-1 p-0">
              <h4 className="mb-0 strong my-3">{I18n.t("table.receptiveness")}</h4>
            </div>
          </Panel.Panel>
          {model.stakeholders.map((stakeholders: any[]) => {
            let projectNo = stakeholders.length;
            return (
              <Accordion
                key={stakeholders[0].stakeholderId}
                side="right"
                hideArrow={projectNo <= 1}
                header={
                  <Panel.Panel type={Panel.PanelTypes.BORDER_LINES_Y} className="p-0 row mx-0">
                    <div className="col-3 my-3">
                      <StakeholderProgress projectStakeholder={stakeholders[0]} isProject={false} />
                    </div>
                    <div className="col-2 p-0 my-3">
                      <p className="mb-0">{projectNo > 1 ? projectNo : stakeholders[0].projectName}</p>
                    </div>
                    <div className="col-2 p-0 my-3">
                      <p className="mb-0">
                        {projectNo > 1 ? "" : Enums.Translator.ImpactLevel(stakeholders[0].impact)}
                      </p>
                    </div>
                    <div className="col-2 p-0 my-3">
                      <p className="mb-0">
                        {projectNo > 1 ? "" : Enums.Translator.InfluenceLevel(stakeholders[0].influence)}
                      </p>
                    </div>
                    <div className="col-1 p-0 my-3">
                      {projectNo > 1 ? "" : <TargetLabel current={stakeholders[0].sentiment} text={"sentiment"} />}
                    </div>
                    <div className="col-1 p-0 my-3">
                      {projectNo > 1 ? "" : <TargetLabel current={stakeholders[0].commitment} text={"commitment"} />}
                    </div>
                    <div className="col-1 p-0 my-3">
                      {projectNo > 1 ? (
                        ""
                      ) : (
                        <TargetLabel current={stakeholders[0].receptiveness} text={"receptiveness"} />
                      )}
                    </div>
                  </Panel.Panel>
                }
              >
                {projectNo > 1 &&
                  stakeholders?.map((pStakeholder: any) => {
                    return (
                      <Panel.Panel
                        type={Panel.PanelTypes.BORDER_LINES_Y}
                        key={pStakeholder.stakeholderId + "-s" + pStakeholder.projectId}
                        className="p-0 row mx-0"
                      >
                        <div className="col-3 my-3">
                          <Link
                            to={`/organisations/${pStakeholder.organisationId}/projects/${pStakeholder.projectId}/stakeholders/${pStakeholder.stakeholderId}`}
                          >
                            <StakeholderProgress projectStakeholder={pStakeholder} isProject={true} />
                          </Link>
                        </div>
                        <div className="col-2 p-0 my-3">
                          <p className="mb-0">{pStakeholder.projectName}</p>
                        </div>
                        <div className="col-2 p-0 my-3">
                          <p className="mb-0">{Enums.Translator.ImpactLevel(pStakeholder.impact)}</p>
                        </div>
                        <div className="col-2 p-0 my-3">
                          <p className="mb-0">{Enums.Translator.InfluenceLevel(pStakeholder.influence)}</p>
                        </div>
                        <div className="col-1 p-0 my-3">
                          <TargetLabel current={pStakeholder.sentiment} text={"sentiment"} />
                        </div>
                        <div className="col-1 p-0 my-3">
                          <TargetLabel current={pStakeholder.commitment} text={"commitment"} />
                        </div>
                        <div className="col-1 p-0 my-3">
                          <TargetLabel current={pStakeholder.receptiveness} text={"receptiveness"} />
                        </div>
                      </Panel.Panel>
                    );
                  })}
              </Accordion>
            );
          })}
          <Pagination model={model.paginationModel} />
        </div>
      </div>
    </div>
  );
});

export const UserStakeholdersPage: React.FC = () => {
  let userReportProvider = UserReportsApi;
  const organisationId = useCurrentOrganisationId();
  const navigate = useNavigate();
  let paginationModel = new PaginationModel();
  const config = {
    paginationModel,
    initOpts: {
      filterCb: async filterOptions => await userReportProvider.getUserStakeholder(organisationId, filterOptions)
    }
  };
  let filterModel: IFilterModel<FP.Entities.IProjectStakeholder[]> = new FilterModel(config);

  return (
    <>
      <UserStakeholders model={new UserStakeholdersModel(navigate, filterModel, paginationModel)} />
    </>
  );
};
