import ModalContext from "../../../../modalZ/context/ModalContext";
import { BASE_GRID_POPUP_MODAL, BASE_GRID_SIDE_MODAL } from "./GridModal_config";

export class GridModalBuilder {
  gridModalOptions: any;
  constructor() {
    this.gridModalOptions = {};
  }

  constructSideModal = () => {
    this.gridModalOptions = BASE_GRID_SIDE_MODAL;
    return this;
  };

  constructPopupModal = () => {
    this.gridModalOptions = BASE_GRID_POPUP_MODAL;
    return this;
  };

  setModalOptions = (opts: any) => {
    this.gridModalOptions = { ...this.gridModalOptions, ...opts };
    return this;
  };

  setComponentProps = (componentProps: any) => {
    this.gridModalOptions = {
      ...this.gridModalOptions,
      componentProps: { ...this.gridModalOptions.componentProps, ...componentProps }
    };

    return this;
  };

  generateModal = () => {
    ModalContext.show({
      ...this.gridModalOptions
    });
  };
}
