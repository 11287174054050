import { observer } from "mobx-react";
import React, { useState } from "react";
import { useCurrentOrganisationId } from "../../../../../services/local/organisationContext/OrganisationContextModel";
import { ProgrammeSunburstModel } from "./ProgrammeSunburst_model";
import I18n from "../../../../../core/localization/I18n";
import { ReportingCard } from "../../../../insight/projects/view/components/ReportingPie";
import { ProgrammeDashboardSunburstCard } from "./dashboardSunburst/ProgrammeDashboardSunburstCard";

export const ProgrammeDashboardSunburstContent: React.FC<any> = observer(({ programmeId }) => {
  const organisationId = useCurrentOrganisationId();
  const [model] = useState(() => new ProgrammeSunburstModel(organisationId, programmeId));

  return (
    <ReportingCard>
      <div className="container-fluid py-4">
        <div className="row">
          <div className="col">
            <div className="heatmap__main-title">
              <h2>{I18n.t("phrases.dashboardSunburstTitle").toUpperCase()}</h2>
            </div>
          </div>
        </div>

        <div className="row">
          <ProgrammeDashboardSunburstCard sunburst={model.sunburst} organisationId={organisationId} />
        </div>
      </div>
    </ReportingCard>
  );
});
