import { InsightInnerNavContent } from "../../../../components/widgets/innerNavigation/InnerNavContent_view";
import I18n from "../../../../core/localization/I18n";
import Pages from "../../../../routes/InsightRoutes";
import { useCurrentOrganisationId } from "../../../../services/local/organisationContext/OrganisationContextModel";

export const CoreData: React.FC = () => {
  const organisationId = useCurrentOrganisationId();

  const items = [
    {
      label: I18n.t("entities.businessAreas"),
      url: Pages.organisations.coreData.index.generateLink(organisationId)
    },
    {
      label: I18n.t("entities.locations"),
      url: Pages.organisations.coreData.locations.generateLink(organisationId)
    },
    {
      label: I18n.t("entities.roles"),
      url: Pages.organisations.coreData.roles.generateLink(organisationId)
    },
    {
      label: I18n.t("entities.processes"),
      url: Pages.organisations.coreData.processes.generateLink(organisationId)
    },
    {
      label: I18n.t("entities.actionTypes"),
      url: Pages.organisations.coreData.actionTypes.generateLink(organisationId)
    },
    {
      label: I18n.t("entities.impactTypes"),
      url: Pages.organisations.coreData.impactTypes.generateLink(organisationId)
    },
    {
      label: I18n.t("entities.tags"),
      url: Pages.organisations.coreData.labels.generateLink(organisationId)
    },
    {
      label: I18n.t("entities.people"),
      url: Pages.organisations.coreData.stakeholders.generateLink(organisationId)
    }
  ];
  return <InsightInnerNavContent title="Core Data" items={items} />;
};
