import { LicenseManager } from "ag-grid-enterprise";

type AgGridLicenseType =
  | {
      licenseKey: string;
      valid: boolean;
      missing: boolean;
      currentLicenseType: string;
      incorrectLicenseType?: undefined;
      suppliedLicenseType?: undefined;
      expiry?: undefined;
      expired?: undefined;
      version?: undefined;
      isTrial?: undefined;
      trialExpired?: undefined;
    }
  | {
      licenseKey: string;
      valid: false;
      incorrectLicenseType: boolean;
      currentLicenseType: string;
      suppliedLicenseType: string | undefined;
      missing?: undefined;
      expiry?: undefined;
      expired?: undefined;
      version?: undefined;
      isTrial?: undefined;
      trialExpired?: undefined;
    }
  | {
      licenseKey: string;
      valid: true;
      expiry: string;
      expired: boolean | undefined;
      version: string | null;
      isTrial: boolean | null;
      trialExpired: undefined;
      incorrectLicenseType: boolean;
      currentLicenseType: string;
      suppliedLicenseType: string | undefined;
      missing?: undefined;
    };

export interface IAgGridContextModel {
  loadEnterprise: (key: string) => void;
  getLicenseDetails: () => AgGridLicenseType;
}

export class AgGridContextModel implements IAgGridContextModel {
  currentLicense: AgGridLicenseType;
  licenseKey: string;
  constructor(licenseKey: string) {
    this.licenseKey = licenseKey;
    this.loadEnterprise(this.licenseKey);
  }

  loadEnterprise = (licenseKey: string) => {
    LicenseManager.setLicenseKey(licenseKey);
  };

  getLicenseDetails = () => {
    return LicenseManager.getLicenseDetails(this.licenseKey);
  };
}
