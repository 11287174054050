import { action, makeObservable, observable } from "mobx";
import * as React from "react";
import { ButtonIcon, ButtonTypes, LinkButton } from "../../../../../components/ui/Button";
import { Icon, IconSymbols } from "../../../../../components/ui/Icon";
import { Panel } from "../../../../../components/ui/Panel";
import { IPaginationModel, PaginationModel } from "../../../../../components/widgets/pagination/Pagination_model";
import { IFilterModel } from "../../../../../core/filter/Filter_model";
import I18n from "../../../../../core/localization/I18n";
import { ITableModel } from "../../../../../core/table/ITableModel";
import { ITableRowModel } from "../../../../../core/table/ITableRowModel";
import { TableModel } from "../../../../../core/table/Table_model";
import { Animations } from "../../../../../core/util/Animations";
import { BaseModel } from "../../../../../core/util/BaseModel";
import { UiSizes } from "../../../../../enums";
import ActionsApi, { ActionsApi as IActionsApi } from "../../../../../services/api/v2/actions/Actions.api";
import { ActionCompactView, ActionCompactViewModel } from "../../../actions/actionCompactView";
import { GetUserActionsTableConfig } from "./UserActions_config";
import { IModalContextModel } from "../../../../../core/modalZ/context/IModalContext";
import ModalContext from "../../../../../core/modalZ/context/ModalContext";

export class UserActionsModel extends BaseModel {
  filterModel: IFilterModel<FP.Entities.IAction>;
  modalService: IModalContextModel;
  @observable.ref actions: FP.Entities.IAction[];
  // @observable isLoading: boolean = true;
  paginationModel: IPaginationModel;
  tableModel: ITableModel<FP.Entities.IAction>;
  title: React.ReactNode;
  actionProvider: IActionsApi;
  organisationId: number;

  /**
   *
   */
  constructor(filterModel: IFilterModel<FP.Entities.IAction>, organisationId: number) {
    super();
    makeObservable(this);
    this.filterModel = filterModel;
    this.modalService = ModalContext;
    this.actionProvider = ActionsApi;
    this.organisationId = organisationId;

    this.installPagination();
    this.tableModel = new TableModel();
    let tableConfig = GetUserActionsTableConfig(
      this.filterModel,
      this.tableModel,
      this.showActionModal,
      true,
      this.showActionConfirmDeleteModal
    );

    this.tableModel.set(tableConfig);
  }

  installPagination = () => {
    this.paginationModel = new PaginationModel();
    this.paginationModel.setConfig({ onPageClick: this.loadPageData });
  };

  loadData = async () => {
    await this.filterModel.loadData();
    this.setActions(this.filterModel.data);
  };

  loadPageData = (index: number) => {
    this.filterModel.setConfig({ page: index });
    this.filterModel.loadData();
  };

  onSearchChange = (ev: React.SyntheticEvent) => {
    let e = ev.currentTarget as HTMLInputElement;
    this.filterModel.setFilterValue("name", e.value);
  };

  @action
  resetNameFilter = () => {
    this.filterModel.setFilterValue("name", "");
  };

  @action
  setActions = (actions: FP.Entities.IAction[]) => {
    this.actions = actions;
    this.tableModel.setData(this.actions);
    // this.isLoading = false;
  };

  showActionModal = (row: ITableRowModel) => {
    let action: FP.Entities.IAction = row.rowObject;
    let actionCompactModel = new ActionCompactViewModel(action.projectId, this.organisationId, action.id);
    this.modalService.show({
      showClose: false,
      title: (
        <div className="d-flex mt-6 mb-5">
          <LinkButton
            className="ml-auto mr-1"
            id="ViewActionDetailsButton"
            href={`/organisations/${action.project.organisationId}/projects/${action.projectId}/actions/${action.id}`}
            onClick={this.modalService.hide}
          >
            {I18n.t("phrases.viewDetails")}
          </LinkButton>
          <ButtonIcon
            key="2"
            type={ButtonTypes.OUTLINE_PRIMARY}
            iconSize={UiSizes.SM}
            symbol={IconSymbols.Close}
            onClick={this.modalService.hide}
          />
        </div>
      ),
      content: <ActionCompactView model={actionCompactModel} />,
      componentProps: {
        wrapHeight: "full",
        wrapWidth: "small",
        position: "right",
        panelProps: {
          background: Panel.PanelBackgrounds.BG_LIGHT,
          className: "h-auto min-h-100",
          hasShadow: true
        }
      },
      animationOptions: {
        animateIn: Animations.SLIDE_IN_RIGHT,
        animateOut: Animations.SLIDE_OUT_RIGHT
      }
    });
  };

  showActionConfirmDeleteModal = (action: FP.Entities.IAction) => {
    return new Promise(resolve => {
      this.modalService.showConfirmDialog(
        <h1 className="mt-4">{I18n.t("phrases.confirm")}</h1>,
        <div className="container-fluid">
          <div className="row mb-3">
            <div className="col-12">
              <Icon symbol={IconSymbols.AlertCircle} className="mr-2" />
              {I18n.t("warnings.removeActionFromProject")}
            </div>
          </div>
          <div className="row">
            <div className="col">{I18n.t("phrases.confirmRemove", { name: action.name })}</div>
          </div>
        </div>,
        I18n.t("phrases.yes"),
        I18n.t("phrases.no"),
        {
          wrapWidth: "small",
          spacing: "small",
          position: "middle",
          panelProps: {
            background: Panel.PanelBackgrounds.BG_WHITE
          }
        },
        async () => {
          this.modalService.hide();
          await this.removeAction(action.projectId, action.id);
          this.loadData();
          resolve(true);
        },
        () => {
          this.modalService.hide();
        },
        ButtonTypes.DANGER
      );
    });
  };

  removeAction = async (projectId: number, actionId: number) => {
    let res = await this.actionProvider.remove(this.organisationId, projectId, actionId);
    if (!res || res.isError) return;
    return res.payload;
  };
}
