import { IUiAction } from "../../../../../../uiAction/IUiAction";
import { UiActionComponent } from "../../../../../../uiAction/UiAction";
import { BaseColumnBuilder } from "../../baseColumn/BaseColumn_builder";
import { ISdColDef } from "../../baseColumn/BaseColumnTypes";
import { UI_ACTION_COLUMN_CONFIG } from "./UiActionColumn_config";

export class UiActionColumnBuilder extends BaseColumnBuilder {
  constructor(columnOptions?: ISdColDef<any, any>) {
    super({ ...UI_ACTION_COLUMN_CONFIG(), ...columnOptions });
  }

  setActions = (actions: IUiAction<ISdColDef<any, any>>[]) => {
    this.setColumnOptions({ cellRenderer: ActionsComponent, cellRendererParams: { actions } });
    return this;
  };
}

const ActionsComponent: React.FC = (params: any) => {
  return (
    <div className="action-component">
      {params.actions.map(e => {
        return <UiActionComponent contextModel={params} action={e} key={e.id} />;
      })}
    </div>
  );
};
