import { INIT_FORM_FIELD } from "../../formField/FormField_init";
import { ITextFieldModel } from "./ITextFieldModel";
import { FormFieldType } from "../../formField/FormFieldTypes";

export const INIT_TEXT_FIELD: Partial<ITextFieldModel> = {
  ...INIT_FORM_FIELD,
  placeholder: "Input Placeholder",
  value: "",
  type: FormFieldType.Text,
  borderStyle: "underline",
  validate: () => {
    return true;
  },
  inputType: "text",
  autoFocus: false,
  onBlur: () => { }
};
