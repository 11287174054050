import { BaseModel } from "../../../../core/util/BaseModel";

import { action, makeObservable, observable } from "mobx";
import { ButtonTypes } from "../../../../components/ui/Button";
import {
  ReviewModalComponentProps,
  ReviewModalContent,
  ReviewModalTitle,
  ReviewToastContent
} from "../../../../components/ui/ReviewModal";

import ProgressIndicatorModel, {
  ProgressIndicatorModel as IProgressIndicatorModel
} from "../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import I18n from "../../../../core/localization/I18n";
import { IModalContextModel } from "../../../../core/modalZ/context/IModalContext";
import ModalContext from "../../../../core/modalZ/context/ModalContext";
import ToasterService, { IToasterService } from "../../../../core/toaster/ToasterService";
import { TOASTER_TOAST_TIME } from "../../../../core/toaster/Toaster_model";
import { convertStakeholderToName } from "../../../../core/util/Helpers";
import { Enums } from "../../../../enums";
import CommentsApi, { CommentsApi as ICommentsApi } from "../../../../services/api/v2/comments/Comments.api";
import ProjectStakeholdersApi, {
  ProjectStakeholdersApi as IProjectStakeholdersApi
} from "../../../../services/api/v2/projectStakeholders/ProjectStakeholders.api";
import ProjectsApi, { ProjectsApi as IProjectsApi } from "../../../../services/api/v2/projects/Projects.api";
import StakeholdersApi, {
  StakeholdersApi as IStakeholdersApi
} from "../../../../services/api/v2/stakeholders/Stakeholders.api";
import TagsApi, { TagsApi as ITagsApi } from "../../../../services/api/v2/tags/Tags.api";
import { ILocalStorageService } from "../../../../services/local/localStorageService/ILocalStorageService";
import LocalStorageService from "../../../../services/local/localStorageService/LocalStorageService";
import { IQueryStringService } from "../../../../services/local/queryStringService/IQueryStringService";
import QueryStringService from "../../../../services/local/queryStringService/QueryStringService";
import { CommentListViewModel } from "../../comments/commentListView/CommentListView_model";
import { RevisionHistoryModel } from "../../revisionHistory/RevisionHistory_model";
import { StakeholderConcernsViewModel } from "../stakeholderConcernsView/StakeholderConcernsView_model";

interface IStakeholderExtendedViewModelOptions {
  authUser: FP.Entities.IUser;
  projectId: number;
  stakeholderId: number;
}
export class StakeholderExtendedViewModel extends BaseModel {
  toasterService: IToasterService;
  projectProvider: IProjectsApi;
  stakeholderProvider: IStakeholdersApi;
  projectId: number;
  stakeholderId: number;
  stakeholderConcernsModel: StakeholderConcernsViewModel;
  commentViewModel: CommentListViewModel;
  revisionHistoryModel: RevisionHistoryModel;
  httpProgress: IProgressIndicatorModel;
  @observable isLoading: boolean = true;
  @observable.ref stakeholder: FP.Entities.IStakeholder = null;
  @observable.ref projectStakeholder: FP.Entities.IProjectStakeholder = null;
  projectStakeholderProvider: IProjectStakeholdersApi;
  localStorageService: ILocalStorageService;
  confirmationService: IModalContextModel;
  commentsProvider: ICommentsApi;
  organisationId: number;
  queryStringService: IQueryStringService;
  tagsProvider: ITagsApi;
  @observable hasBeenReviewed: boolean;
  @observable reviewCommentInput: string;

  constructor({ authUser, projectId, stakeholderId }: IStakeholderExtendedViewModelOptions) {
    super();
    makeObservable(this);
    this.projectProvider = ProjectsApi;
    this.projectStakeholderProvider = ProjectStakeholdersApi;
    this.toasterService = ToasterService;
    this.confirmationService = ModalContext;
    this.commentsProvider = CommentsApi;
    this.stakeholderProvider = StakeholdersApi;
    this.httpProgress = ProgressIndicatorModel;

    this.projectId = projectId;
    this.stakeholderId = stakeholderId;
    this.localStorageService = LocalStorageService;
    this.tagsProvider = TagsApi;
    this.organisationId = parseInt(this.localStorageService.get(Enums.LocalCookies.ORGANISATION_ID));
    this.hasBeenReviewed = false;
    this.reviewCommentInput = "";
    this.stakeholderConcernsModel = new StakeholderConcernsViewModel(this.stakeholderId, this.projectId, false);
    this.commentViewModel = new CommentListViewModel(this.projectId, authUser, {
      placeholderText: I18n.t("placeholders.stakeholderNotePlaceholder"),
      searchAttribute: "projectStakeholderId",
      id: -1,
      title: null,
      projectId: this.projectId,
      organisationId: this.organisationId
    });

    this.revisionHistoryModel = new RevisionHistoryModel({
      entityId: this.stakeholderId,
      projectId: this.projectId,
      historyType: "stakeholders",
      organisationId: this.organisationId
    });

    this.queryStringService = QueryStringService;
  }

  onMount = async () => {
    await this.loadProjectStakeholder(this.projectId, this.stakeholderId);
  };

  onUnmount = () => {};

  loadProjectStakeholder = async (projectId: number, stakeholderId: number) => {
    let res = await this.projectStakeholderProvider.getStakeholderById(this.organisationId, projectId, stakeholderId);

    if (!res || res.isError) return;
    this.setProjectStakeholder(res.payload);
  };

  @action
  setProjectStakeholder = (projectStakeholder: FP.Entities.IProjectStakeholder) => {
    this.projectStakeholder = projectStakeholder;
    this.stakeholder = projectStakeholder.stakeholder;

    this.stakeholderConcernsModel.setProjectStakeholder(projectStakeholder);
    this.commentViewModel.setConfig({
      id: projectStakeholder.id,
      description: <h4 className="mb-0">{I18n.t("phrases.addANote")}</h4>
    });
    this.isLoading = false;
  };

  @action
  handleInputChange = (value: string) => {
    this.reviewCommentInput = value;
  };

  showMarkReviewedModal = () => {
    return new Promise(resolve => {
      this.confirmationService.showConfirmDialog(
        <ReviewModalTitle />,
        <ReviewModalContent reviewCommentInput={this.reviewCommentInput} handler={this.handleInputChange} />,
        I18n.t("phrases.confirmReview"),
        I18n.t("phrases.cancelReview"),
        ReviewModalComponentProps,
        async () => {
          await this.reviewStakeholder(this.projectStakeholder.id, this.reviewCommentInput);
          resolve(true);
        },
        () => {
          this.confirmationService.hide();
        },
        ButtonTypes.PRIMARY,
        ButtonTypes.OUTLINE_PRIMARY
      );
    });
  };

  reviewStakeholder = async (projectStakeholderId: number, comment) => {
    let data: FP.Entities.IComment = {
      content: comment,
      projectId: this.projectId,
      projectStakeholderId: projectStakeholderId,
      owner: null
    };
    let res = await this.commentsProvider.createAndReview(this.organisationId, this.projectId, data);
    if (res) {
      this.toasterService
        .showReviewToast()
        .setContent(<ReviewToastContent itemName={convertStakeholderToName(this.stakeholder)} />)
        .startTimer(TOASTER_TOAST_TIME.NORMAL);
    }
    if (!res || res.isError) return;

    this.hasBeenReviewed = true;
    this.reviewCommentInput = "";
    this.loadProjectStakeholder(this.projectId, this.stakeholderId);
    this.confirmationService.hide();
  };
}
