import { GridBaseModel } from "../common/GridBaseModel";
import { HubEvents } from "../common/HubEventsEnum";

export class ProcessesHub extends GridBaseModel {
  hubName: string = "processes";

  constructor() {
    super("processes");
  }
  onLoadData = callback => this.registerIncomingEvent(HubEvents.ON_LOAD_PROCESSES_DATA, callback);

  invokeLoadData = async (organisationId: number, projectId: number) => {
    if (!this.isConnectionStarted) return;
    await this.connection.invoke(HubEvents.INVOKE_LOAD_PROCESSES_DATA, {
      organisationId: organisationId,
      projectId: projectId
    });
  };
}
const instance = new ProcessesHub();
export default instance;
