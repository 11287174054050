import { observer } from "mobx-react";
import React from "react";
import { ActionBarModel } from "./ActionBar_model";

export interface IActionBarProps {
  model?: ActionBarModel;
}

const ActionBar: React.FunctionComponent<IActionBarProps & ReactProps> = observer(props => {
  return <div className="action-bar">{props.children}</div>;
});

export { ActionBar };
