import { action, makeObservable, observable } from "mobx";
import ProjectsApi, { ProjectsApi as IProjectsApi } from "../../../../../services/api/v2/projects/Projects.api";

export class MilestoneSettingsModel {
  organisationId: number;
  projectId: number;
  @observable isLoading: boolean = true;
  @observable milestones: FP.Entities.IMilestone[] = [];
  projectsProvider: IProjectsApi;

  /**
   *
   */
  constructor(organisationId: number, projectId: number) {
    makeObservable(this);
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.projectsProvider = ProjectsApi;
  }

  onMount = async () => {
    await this.loadMilestones();
  };

  loadMilestones = async () => {
    const res = await this.projectsProvider.getById(this.organisationId, this.projectId);
    if (res.isError) return;
    this.setMilestones(res.payload.milestones);
    this.setIsLoading(false);
  };

  @action
  setMilestones = (milestones: FP.Entities.IMilestone[]) => (this.milestones = milestones);

  @action
  setIsLoading = (isLoading: boolean) => (this.isLoading = isLoading);
}
