import React, { useContext } from "react";
import { AgGridContextModel, IAgGridContextModel } from "./AgGridContextModel";

const trialLicenseKey = window.appConfig.ag_grid_enterprise_key;
let AgGridContext = new AgGridContextModel(trialLicenseKey);

const AgGridReactContext = React.createContext<AgGridContextModel>(AgGridContext);

export const AgGridContextProvider: React.FC<ReactProps> = ({ children }) => {
  return <AgGridReactContext.Provider value={AgGridContext}>{children}</AgGridReactContext.Provider>;
};

export function useAgGridContext() {
  return useContext(AgGridReactContext);
}
export default AgGridContext as IAgGridContextModel;
