import * as React from "react";
import { IntercomProvider } from "react-use-intercom";
import { Panel } from "../../../components/ui/Panel";
import I18n from "../../../core/localization/I18n";
import { Animations } from "../../../core/util/Animations";
import IntercomLiveChatButton from "./IntercomLiveChatButton";

export interface IIntercomLiveChatProps {
  currentOrganisationName: string;
  currentOrganisationId: number;
  showAsButton?: "button" | "link" | "icon" | "icon-label";
  className?: string;
}

const IntercomLiveChat: React.FunctionComponent<IIntercomLiveChatProps> = props => {
  const INTERCOM_APP_ID = window.appConfig.intercom_App_Id;

  return (
    <div className={`navigation-view ${props.className ? props.className : ""} `} tabIndex={0}>
      <div className="navigation-view__label-tooltip navigation-view__chat">
        <Panel.Panel
          hasBorderRadius={true}
          background={Panel.PanelBackgrounds.BG_WHITE}
          hasShadow={true}
          className={`p-2 ${Animations.FADE_IN} speed-3`}
        >
          <p className="mb-0 text-dark">{I18n.t("phrases.chat")}</p>
        </Panel.Panel>
      </div>

      <IntercomProvider appId={INTERCOM_APP_ID}>
        <IntercomLiveChatButton {...props} />
      </IntercomProvider>
    </div>
  );
};

export default IntercomLiveChat;
