import * as _ from "lodash";
import { AutocompletePerson } from "../../../../../../components/ui/AutocompletePersonOption";
import { IconSymbols } from "../../../../../../components/ui/Icon";
import { AutocompleteOption } from "../../../../../../components/ui/_forms/Autocomplete/AutocompleteOption";
import { FORM_COL, GetI18nParsedList, PROJECT_PERMISSION_SCOPE_OPTIONS } from "../../../../../../constants";
import { INIT_AUTOCOMPLETE } from "../../../../../../core/forms/controls/autocomplete/Autocomplete_init";
import { IAutocompleteModel } from "../../../../../../core/forms/controls/autocomplete/IAutocompleteModel";
import { IListingModel } from "../../../../../../core/forms/controls/listing/IListingModel";
import { INIT_LISTING_FIELD, ListingModel } from "../../../../../../core/forms/controls/listing/Listing_model";
import { IMultiSelectorModel } from "../../../../../../core/forms/controls/multiSelector/IMultiSelectorModel";
import { INIT_MULTISELECTOR } from "../../../../../../core/forms/controls/multiSelector/MultiSelector_model";
import { generateFormFieldsFromJson } from "../../../../../../core/forms/helpers/FormFieldMappers";
import I18n from "../../../../../../core/localization/I18n";
import { OrganisationsApi } from "../../../../../../services/api/v2/organisations/Organisations.api";
import ProjectIndividualUserPermissionsApi from "../../../../../../services/api/v2/projectIndividualUserPermissions/ProjectIndividualUserPermisssions.api";
import { AutocompleteModel } from "../../../../../../core/forms/controls/autocomplete/Autocomplete_model";

export const GetIndividualUserPermissionsFields = (
  organisationsProvider: OrganisationsApi,
  organisationId: number,
  projectId: number,
  setFormVisibility: (val: boolean) => void
) => {
  let userSearch: Partial<IAutocompleteModel> = {
    ...INIT_AUTOCOMPLETE,
    key: "userSearch",
    label: <label htmlFor={"userSearch"}>{I18n.t("forms.searchUsers")}</label>,
    placeholder: I18n.t("placeholders.searchUsers"),
    optionElement: (
      <AutocompleteOption key="e" className="autocomplete__chip" label={e => <AutocompletePerson {...e} />} />
    ),
    componentProps: {
      className: "form-control",
      icon: IconSymbols.Search
    },
    onFocus: async function () {
      const self: IAutocompleteModel = this;
      const res = await organisationsProvider.getUsers(organisationId);

      if (res?.payload) {
        const sortedUsers = _.orderBy(res.payload, [
          user => user.firstName.toLowerCase(),
          user => user.lastName.toLowerCase()
        ]);
        self.setOptions(sortedUsers);
      }
    },
    subscribeTo: ["userIds"],
    onItemSelected: async function () {
      const self: AutocompleteModel = this;
      const listingModel: ListingModel = self.channels.userIds as ListingModel;
      const val = self.value;
      listingModel.addItem(val);
      self.searchQuery = "";
    },
    shouldClearOnBlur: true,
    filterFn: (items: FP.Entities.IImpact[], query) => {
      const lowerQuery = query.toLowerCase();
      return _.filter(items, (item: FP.Entities.IUser) => {
        const lowerName = `${item.firstName} ${item.lastName}`.toLowerCase();
        const lowerEmail = item.email.toLowerCase();
        return lowerName.indexOf(lowerQuery) > -1 || lowerEmail.indexOf(lowerQuery) > -1;
      });
    },
    fieldClassName: FORM_COL.FULL_WIDTH,
    valueLabelFn: e => e.name
  };

  let listing: Partial<IListingModel> = {
    ...INIT_LISTING_FIELD,
    key: "userIds",
    placeholder: I18n.t("placeholders.selectUsers"),
    fieldClassName: FORM_COL.FULL_WIDTH,
    onValueChange: values => {
      setFormVisibility(values.length > 0);
    },
    onChannelFieldChanged: function (field) {
      let val = field.value;
      if (val) {
        this.addItem(val);
        field.reset();
      }
    },
    extractValue: function () {
      return this.value && this.value.map(e => e.id);
    },
    selector: (e: FP.Entities.IUser) => (
      <p className="mb-0 d-inline-block">
        {e.firstName} {e.lastName} ({e.email})
      </p>
    )
  };

  const fields = [];

  fields.push(userSearch);
  fields.push(listing);
  const models = generateFormFieldsFromJson(fields);
  return models;
};

export const GetProjectIndividualUserFilterFields = (organisationId: number, projectId: number) => filterModel => {
  let fields = [];
  const userIdFilter = filterModel.getFilter("userId");
  const permissionLevelFilter = filterModel.getFilter("permissionLevel");

  const projectTeamUserPermissionsProviders = ProjectIndividualUserPermissionsApi;

  const userId: Partial<IMultiSelectorModel> = {
    ...INIT_MULTISELECTOR,
    key: userIdFilter.key,
    label: <label htmlFor={userIdFilter.key}>{I18n.t("forms.user")}</label>,
    optionElement: (
      <AutocompleteOption key={"e"} className={"autocomplete__chip"} label={e => <AutocompletePerson {...e} />} />
    ),
    componentProps: {
      icon: IconSymbols.UserFilled
    },
    placeholder: I18n.t("placeholders.searchUser"),
    onFocus: async function (model: IMultiSelectorModel) {
      if (model.options.length === 0) {
        let res = await projectTeamUserPermissionsProviders.getAllUsersSimple(organisationId, projectId);
        if (!res || res.isError) return;
        model.setOptions(res.payload);
      }
    },
    valueLabelFn: obj => {
      if (typeof obj.firstName === "undefined" || typeof obj.lastName === "undefined") {
        return "";
      }
      return obj ? obj?.firstName + " " + obj?.lastName : "";
    },
    filterFn: (items, query) => {
      const lowerQuery = query.toLowerCase();
      return _.filter(items, (item: FP.Entities.IUser) => {
        const lowerName = `${item.firstName} ${item.lastName}`.toLowerCase();
        const lowerEmail = item.email.toLowerCase();
        return lowerName.indexOf(lowerQuery) > -1 || lowerEmail.indexOf(lowerQuery) > -1;
      });
    },
    onValueChange: (value: any[]) => {
      filterModel.setFilterValueList(userIdFilter.key, value);
    },
    fieldClassName: FORM_COL.FULL_WIDTH,
    value: userIdFilter.value
  };

  const permissionLevel: Partial<IMultiSelectorModel> = {
    ...INIT_MULTISELECTOR,
    key: permissionLevelFilter.key,
    optionElement: <AutocompleteOption key={"e"} className={"autocomplete__chip"} label={e => e.label} />,
    label: <label htmlFor={permissionLevelFilter.key}>{I18n.t("forms.permissionLevel")}</label>,
    placeholder: I18n.t("placeholders.permissionLevel"),
    onFocus: async function (model: IMultiSelectorModel) {
      if (model.extractValue() !== null) {
        model.searchQuery = "";
      }
      if (model.options.length === 0) {
        const options = GetI18nParsedList(I18n, PROJECT_PERMISSION_SCOPE_OPTIONS);
        model.setOptions(options);
      }
    },
    valueLabelFn: obj => {
      return obj?.label;
    },
    componentProps: {
      className: "form-control"
    },
    fieldClassName: FORM_COL.FULL_WIDTH,
    onValueChange: (value: any[]) => {
      filterModel.setFilterValueList(permissionLevelFilter.key, value);
    }
  };

  fields.push(userId);
  fields.push(permissionLevel);
  const models = generateFormFieldsFromJson(fields);
  return models;
};
