import { action, makeObservable, observable } from "mobx";
import CsvHelper, { PARSE_CSV_FILE } from "../../../../../services/local/csvHelper/CsvHelper";
import ActionsApi, { ActionsApi as IActionsApi } from "../../../../../services/api/v2/actions/Actions.api";
import ModalContext from "../../../../../core/modalZ/context/ModalContext";
import { IModalContextModel } from "../../../../../core/modalZ/context/IModalContext";

export enum ActionsUploaderViews {
  Initial,
  IsUploading,
  IsNotOneFileError,
  IsUploadSuccessfull
}

export class ActionsUploaderModel {
  organisationId: number;
  projectId: number;
  @observable viewState: ActionsUploaderViews = ActionsUploaderViews.Initial;
  @observable isUploading: boolean = false;
  @observable isOneFile: boolean = true;
  actionsProvider: IActionsApi;
  modalService: IModalContextModel;

  constructor(organisationId: number, projectId: number) {
    makeObservable(this);
    this.organisationId = organisationId;
    this.actionsProvider = ActionsApi;
    this.modalService = ModalContext;
    this.projectId = projectId;
  }

  @action
  processFiles = (files: FileList, event: React.DragEvent<HTMLDivElement>) => {
    if (files.length > 1) {
      this.viewState = ActionsUploaderViews.IsNotOneFileError;
      this.isOneFile = false;
      return;
    }
    this.viewState = ActionsUploaderViews.IsUploading;

    PARSE_CSV_FILE<FP.Entities.IAction>(files[0], this.uploadActions);
  };

  uploadActions = async (Actions: FP.Entities.IAction[]) => {
    await this.actionsProvider.addMultiple(this.organisationId, this.projectId, Actions);
    this.modalService.hide();
  };

  @action
  setViewState = (viewState: ActionsUploaderViews) => (this.viewState = viewState);

  downloadTemplate = () => {
    CsvHelper.exportToCsv("action-upload-template", [
      ["name", "description", "progressStatus", "startDate", "endDate", "ragStatus"],
      [
        "Example",
        "Delete this row before uploading",
        "Not Started | Completed | In Progress",
        "2024-12-31",
        "2024-01-01",
        "Red | Orange | Green"
      ]
    ]);
  };
}
