import { action, makeObservable, observable } from "mobx";
import { GridApi, GridReadyEvent } from "ag-grid-community";
import { BaseModel } from "../../../../../core/util/BaseModel";
import ProgrammesApi, {
  ProgrammesApi as IProgrammesApi
} from "../../../../../services/api/v2/programmes/Programmes.api";

export class ProgrammeChangeTeamCapacityModel extends BaseModel {
  @observable organisationId: number;
  isComponentMounted: boolean = false;
  @observable connectedUsers: any[] = [];
  @observable isLoading: boolean = true;
  authUser: FP.Entities.IUser;
  @observable changeTeamMetrics: FP.Entities.IChangeTeamMetric[];
  gridApi: GridApi;
  @observable searchText: string;
  @observable programmeId: number;
  programmeProvider: IProgrammesApi;

  constructor(organisationId: number, authUser: FP.Entities.IUser, programmeId: number) {
    super();
    makeObservable(this);
    this.organisationId = organisationId;
    this.authUser = authUser;
    this.programmeProvider = ProgrammesApi;
    this.programmeId = programmeId;
  }

  onMount = async () => {
    await this.loadData();
  };

  onUnmount = () => {};

  loadData = async () => {
    await this.programmeProvider.getChangeTeamCapacityChartData(this.organisationId, this.programmeId).then(res => {
      this.setChangeTeamMetricsData(res.payload);
    });

    this.isComponentMounted = true;
  };

  @action
  setChangeTeamMetricsData = data => {
    this.changeTeamMetrics = data;
    this.isLoading = false;
  };

  updateData = () => {};

  isRowSelectable = () => {
    return false;
  };

  @action
  onGridReady = (gridReadyEvent: GridReadyEvent): void => {
    this.gridApi = gridReadyEvent.api;
  };

  @action
  setSearchText = (ev: React.FormEvent<HTMLInputElement>) => {
    this.searchText = ev.currentTarget.value;

    if (this.gridApi !== undefined) {
      this.gridApi.setGridOption("quickFilterText", this.searchText);
    }
  };
}
