import React from "react";
import { PanelBackgrounds } from "@flightpath/coreui/dist/ui/Panel";
import { Panel } from "../../../../../../../components/ui/Panel";
import { PieChart } from "../../../../../../../components/widgets/pie/pie";

interface ReportingPieProps {
  data: any[];
  metric: string | React.ReactNode;
  colourArray: string[];
  onClick?: (dataObject: any) => void;
}

export const PieTooltip = props => {
  return (
    <Panel.Panel background={PanelBackgrounds.BG_WHITE} hasBorderRadius={true} className="p-2">
      <span className="mb-0 body">
        {props.label}: {props.value}
      </span>
    </Panel.Panel>
  );
};

export const HeatMapReportingPie: React.FC<ReportingPieProps> = ({ data, metric, colourArray, onClick }) => {
  return (
    <div style={{ width: "50px", height: "50px", position: "relative" }}>
      <div style={{ width: "100%", height: "100%", position: "relative" }}>
        <PieChart
          data={data}
          colors={colourArray}
          hasLegend={false}
          translateLegendX={100}
          translateLegendY={0}
          tooltipFn={e => <PieTooltip {...e} />}
          margin={{ top: 5, right: 5, bottom: 5, left: 5 }}
          onClickFn={onClick}
        />
        <span
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            fontWeight: "bold"
          }}
        >
          {metric}
        </span>
      </div>
    </div>
  );
};
