import {
  gridSideModalAnimationOpts,
  gridSideModalComponentProps,
  GridSideModalTitle
} from "../../../../../../core/grids/GridSideModals";
import I18n from "../../../../../../core/localization/I18n";
import ModalContext from "../../../../../../core/modalZ/context/ModalContext";
import { ProjectProcessRolesSidebarModel } from "../modals/ProjectProcessRolesSidebar/ProjectProcessRolesSidebar_model";
import { ProjectProcessRolesSidebar } from "../modals/ProjectProcessRolesSidebar/ProjectProcessRolesSidebar_view";

export interface IProjectJobRoleGridModals {
  showProcessRolesModalFn: (processRole: FP.Entities.IProjectProcessRole) => void;
}

export const getProjectJobRoleGridModals = (organisationId: number, projectId: number): IProjectJobRoleGridModals => {
  const modalService = ModalContext;

  const processRolesModal = (processRole: FP.Entities.IProjectProcessRole) => {
    modalService.show({
      showClose: true,
      title: <GridSideModalTitle name={processRole.name} field={I18n.t("phrases.processRoles")} />,
      content: (
        <ProjectProcessRolesSidebar
          model={new ProjectProcessRolesSidebarModel(modalService.hide, organisationId, projectId, processRole)}
        />
      ),
      componentProps: gridSideModalComponentProps,
      animationOptions: gridSideModalAnimationOpts
    });
  };

  const ProjectJobRoleGridModals: IProjectJobRoleGridModals = {
    showProcessRolesModalFn: processRolesModal
  };

  return ProjectJobRoleGridModals;
};
