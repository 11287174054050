import { action, makeObservable, observable } from "mobx";
import { GetI18nParsed, PROJECT_PERMISSION_SCOPE_OPTIONS } from "../../../../../../constants";
import ProgressIndicatorModel, {
  ProgressIndicatorModel as IProgressIndicatorModel
} from "../../../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import I18n from "../../../../../../core/localization/I18n";
import ProjectGroupPermissionsApi, {
  ProjectGroupPermissionsApi as IProjectGroupPermissionsApi
} from "../../../../../../services/api/v2/projectGroupPermissions/ProjectGroupPermissions.api";
import { GetGroupUserPermissionsTableConfig } from "./GroupUsersPermissions_columns";

export class GroupUsersPermissionsModel {
  @observable isLoading: boolean = false;
  organisationId: number;
  projectId: number;
  smartTableConfig: any;
  @observable data: FP.Entities.IPermissions[];
  @observable tableContent: any[];
  projectGroupPermissionsProvider: IProjectGroupPermissionsApi;
  httpProgress: IProgressIndicatorModel;

  constructor(organisationId: number, projectId: number) {
    makeObservable(this);
    this.organisationId = organisationId;
    this.projectGroupPermissionsProvider = ProjectGroupPermissionsApi;
    this.httpProgress = ProgressIndicatorModel;
    this.projectId = projectId;
    this.smartTableConfig = GetGroupUserPermissionsTableConfig(this, this.projectId);
  }

  onMount = async () => {
    await this.loadData();
  };

  onUnmount = () => {};

  @action
  loadData = async () => {
    this.isLoading = true;
    let res = await this.projectGroupPermissionsProvider.getFiltered(this.organisationId, this.projectId, {
      page: 0,
      pageSize: 0,
      sorts: "",
      filters: ""
    });
    this.isLoading = false;
    if (!res) return;
    this.setData(res.payload as FP.Entities.IPermissions[]);
  };

  updatePermissions = async (item: FP.Entities.IPermissions) => {
    this.httpProgress.showTopProgressBarVisible();
    let res = await this.projectGroupPermissionsProvider.update(
      this.organisationId,
      this.projectId,
      this.projectId,
      item
    );
    if (!res) return;
    this.setData(res.payload);
    this.httpProgress.hideTopProgressBarVisible();
  };

  @action
  setData = (data: FP.Entities.IPermissions[]) => {
    this.data = data;
    this.setTableContent(data);
  };

  setTableContent = (data: FP.Entities.IPermissions[]) => {
    this.tableContent = data.map(e => this.mapToTableItem(e));
  };

  mapToTableItem = (item: FP.Entities.IPermissions) => {
    let permission = PROJECT_PERMISSION_SCOPE_OPTIONS.filter(x => x.key === item.permissionLevel);
    if (!permission || permission.length === 0) return;
    let permissionItem = permission[0];
    permissionItem = GetI18nParsed(I18n, permissionItem);
    permissionItem = GetI18nParsed(I18n, permissionItem, "permissionActivity");
    return {
      ...item,
      permissionLabel: permissionItem.label,
      activities: permissionItem.permissionActivity
    };
  };
}
