import { IModalConfig } from "../../../../core/modal/IModalService";
import { Animations } from "../../../../core/util/Animations";
import { ProgrammeSidePanel } from "../panels/ProgrammeSidePanel";

export const INFO_MODAL_CONFIG = (programmeDashboardData: FP.Entities.IProgrammeDashboard): IModalConfig => {
  return {
    showClose: true,
    title: <h1 className="mt-6">{programmeDashboardData.programmeDetails.name}</h1>,
    content: (
      <div className="container-fluid mt-2">
        <ProgrammeSidePanel programmeDashboardData={programmeDashboardData} />
      </div>
    ),
    componentProps: {
      wrapWidth: "medium",
      wrapHeight: "full",
      spacing: "small",
      position: "right",
      hasBorderRadius: true,
      panelProps: {}
    },
    animationOptions: {
      animateIn: Animations.SLIDE_IN_RIGHT,
      animateOut: Animations.SLIDE_OUT_RIGHT
    }
  };
};
