import React from "react";
import { Panel } from "../../../../../../components/ui/Panel";
import { Icon, IconSymbols } from "../../../../../../components/ui/Icon";
import { UiSizes } from "../../../../../../enums";
import I18n from "../../../../../../core/localization/I18n";

export const UploadFileSuccess: React.FC = () => {
    return <div>
        <Panel.Panel type={Panel.PanelTypes.BORDER_TOP_SUCCESS} hasShadow={true} className="text-left m-4 p-2 d-flex">
            <Icon symbol={IconSymbols.CheckCircle} size={UiSizes.SM} className="mr-2" style={{color: "#1ac541"}} />
            <span>{I18n.t("phrases.organisationStakeholders_fileUploadedSuccessfully")}</span>
        </Panel.Panel>
    </div>
}