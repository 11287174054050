import { SideBarLayout } from "../../../components/layouts/SideBarLayout";
import { SideNavigationProvider } from "./SideNavigationProvider";
import { TopNavigationProvider } from "./TopNavigationsProvider";

export const Navigation: React.FC<any> = props => {
  return (
    <SideBarLayout>
      <SideNavigationProvider />
      <SideBarLayout direction="horizontal">
        <TopNavigationProvider />
        <div className="navigation__content">{props.children}</div>
      </SideBarLayout>
    </SideBarLayout>
  );
};
