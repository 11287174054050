import { action, makeObservable, observable } from "mobx";
import LocalStorageService, {
  LocalStorageService as ILocalStorageService
} from "../../../../../services/local/localStorageService/LocalStorageService";

export class ActionSummaryModel {
  projectId: number;
  organisationId: number;
  @observable progressBusinessAreaIds: number[];
  @observable.ref ragBusinessAreaIds: number[];
  localStorageService: ILocalStorageService;

  constructor(organisationId: number, projectId: number) {
    makeObservable(this);
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.localStorageService = LocalStorageService;
    this.initCurrentProgressAreaIds();
    this.initCurrentRagAreaIds();
  }

  initCurrentProgressAreaIds = () => {
    let res = this.localStorageService.get(this.getProgressBALocalStorageKey());
    if (!res) return;
    let items = res.split(",").map(e => parseInt(e));
    this.setProgressBusinessAreaIds(items);
  };

  initCurrentRagAreaIds = () => {
    let res = this.localStorageService.get(this.getRagBALocalStorageKey());
    if (!res) return;
    let items = res.split(",").map(e => parseInt(e));
    this.setRagBusinessAreaIds(items);
  };

  clearProgressBusinessAreas = () => {
    this.setProgressBusinessAreaIds(null);
    this.localStorageService.remove(this.getProgressBALocalStorageKey());
  };

  clearRagBusinessAreas = () => {
    this.setRagBusinessAreaIds(null);
    this.localStorageService.remove(this.getRagBALocalStorageKey());
  };

  updateProgressBusinessAreas = (businessAreas: number[]) => {
    this.setProgressBusinessAreaIds(businessAreas);
    this.localStorageService.set(this.getProgressBALocalStorageKey(), businessAreas.join(","));
  };

  updateRagBusinessAreas = (businessAreas: number[]) => {
    this.setRagBusinessAreaIds(businessAreas);
    this.localStorageService.set(this.getRagBALocalStorageKey(), businessAreas.join(","));
  };

  @action
  setProgressBusinessAreaIds = (ids: number[]) => {
    this.progressBusinessAreaIds = ids;
  };

  @action
  setRagBusinessAreaIds = (ids: number[]) => {
    this.ragBusinessAreaIds = ids;
  };

  getProgressBALocalStorageKey = () => {
    return `progressBusinessAreasId-${this.projectId}`;
  };

  getRagBALocalStorageKey = () => {
    return `ragBusinessAreasId-${this.projectId}`;
  };
}
