import I18n from "../../../../../../../localization/I18n";
import { GridSideModalTitle } from "../../../../utils/GridModalUtils";
import { ImpactNotesSidebar } from "./ImpactNotesSidebar";

export const IMPACT_NOTES_SIDEBAR_MODAL_CONFIG = (
  organisationId: number,
  projectId: number,
  impact: FP.Entities.IImpact
) => {
  return {
    showClose: true,
    title: <GridSideModalTitle name={impact.name} field={I18n.t("phrases.tags")} />,
    content: <ImpactNotesSidebar projectId={projectId} impactId={impact.id} organisationId={organisationId} />
  };
};
