export enum FormFieldType {
  Text = 0x01,
  Autocomplete = 0x02,
  Checkbox = 0x03,
  Dropdown = 0x04,
  ImageCropper = 0x05,
  CheckboxList = 0x06,
  RadioButtonList = 0x07,
  DatePicker = 0x08,
  Listing = 0x09,
  Slider = 0x10,
  RTEditor = 0x0a,
  Multiselect = 0x0b,
  Multiselector = 0x0c,
  MultiInput = 0x0d,
  CheckboxSlider = 0x0e,
  SearchTreePicker = 0x0f
}
