import { action, makeObservable, observable } from "mobx";
import { BaseModel } from "../../../../../../../util/BaseModel";
import ProjectStakeholdersApi, {
  ProjectStakeholdersApi as IProjectStakeholdersApi
} from "../../../../../../../../services/api/v2/projectStakeholders/ProjectStakeholders.api";
import TagsApi, { TagsApi as ITagsApi } from "../../../../../../../../services/api/v2/tags/Tags.api";

export class ProjectStakeholderTagsSidebarModel extends BaseModel {
  projectStakeholdersProvider: IProjectStakeholdersApi;
  tagsProvider: ITagsApi;
  @observable isLoading: boolean = true;
  @observable.ref projectStakeholder: FP.Entities.IProjectStakeholder;
  organisationId: number;
  projectId: number;
  projectStakeholderId: number;

  constructor(organisationId: number, projectId: number, projectStakeholderId: number) {
    super();
    makeObservable(this);
    this.projectStakeholdersProvider = ProjectStakeholdersApi;
    this.tagsProvider = TagsApi;
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.projectStakeholderId = projectStakeholderId;
  }

  onMount = async () => {
    await this.loadProjectStakeholder(this.projectStakeholderId);
  };

  @action
  setProjectStakeholder = (projectStakeholder: FP.Entities.IProjectStakeholder) => {
    this.projectStakeholder = projectStakeholder;
    this.isLoading = false;
  };

  @action
  loadProjectStakeholder = async (projectStakeholderId: number) => {
    let res = await this.projectStakeholdersProvider.getDetailedById(
      this.organisationId,
      this.projectId,
      projectStakeholderId
    );
    if (!res || res.isError) return;
    this.setProjectStakeholder(res.payload);
  };

  @action
  removeTag = async (tagId: number) => {
    // let res = await this.tagsProvider.removeImpactTagAssoc(this.organisationId, this.projectId, tagId, this.impact.id);
    // if (!res || res.isError) return;
  };
}
