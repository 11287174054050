import { AxiosInstance, AxiosRequestConfig } from "axios";
import { IFlightPathApiResponse } from "../BaseApiModel";
import { ExportableProjectApi } from "../exportableApi/ExportableProjectApiModel";
import http from "../Http";
import { ImpactGroupField } from "../../../../core/grids/builder/directorBuilder/utils/GridFields";

export class ImpactGroupsApi extends ExportableProjectApi<FP.Entities.IImpactGroup> {
  controller: string = "impact-groups";

  constructor(http: AxiosInstance) {
    super(http, "impact-groups");
  }

  getDetailedById = async (organisationId: number, projectId: number, id: number, config?: AxiosRequestConfig) => {
    let res = await this.http.get(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${id}/detailed`,
      config
    );
    return res.data;
  };

  getImpactsUnassignedToImpactGroup = async (
    organisationId: number,
    projectId: number,
    impactGroupId: number = 0,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IImpact[]>> => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${impactGroupId}/impacts/unassigned`;

    let res = await this.http.get(url);
    return res.data;
  };

  async getImpactGroupStakeholders(
    organisationId: number,
    projectId: number,
    impactGroupId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IStakeholder[]>> {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${impactGroupId}/project-stakeholders`;

    let res = await this.http.get(url);
    return res.data;
  }

  async getImpactGroupImpacts(
    organisationId: number,
    projectId: number,
    impactGroupId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IImpact[]>> {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${impactGroupId}/impacts`;

    let res = await this.http.get(url);
    return res.data;
  }

  async getDetailed(
    organisationId: number,
    projectId: number,
    impactGroupId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IImpactGroup>> {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${impactGroupId}`;
    let res = await this.http.get(url);
    return res.data;
  }

  addImpactsAsync = async (
    organisationId: number,
    projectId: number,
    impactGroupId: number,
    impacts: number[],
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IImpact[]>> => {
    let res = await this.http.post(
      `${this.url}/${organisationId}/projects/${projectId}/impact-groups/${impactGroupId}/impacts`,
      impacts,
      config
    );
    return res.data;
  };

  addBulkImpacts = async (
    organisationId: number,
    projectId: number,
    impactGroupIds: number[],
    impactIds: number[],
    config?: AxiosRequestConfig
  ) => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/add-bulk-impacts-to-impact-groups`;
    let res = await this.http.post(url, { impactIds, impactGroupIds }, config);
    return res.data;
  };

  getComments = async (
    organisationId: number,
    impactGroupId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IComment[]>> => {
    let res = await this.http.get(this.url + impactGroupId + "/comments", config);

    return res.data;
  };

  getProjectGridData = async (
    organisationId: number,
    projectId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IComment[]>> => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/grid-data`;
    let res = await this.http.get(url, config);

    return res.data;
  };

  addComment = async (
    organisationId: number,
    impactGroupId: number,
    comment: FP.Entities.IComment,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IComment>> => {
    let res = await this.http.post(this.url + impactGroupId + "/comments", comment, config);

    return res.data;
  };

  removeImpact = async (
    organisationId: number,
    projectId: number,
    impactGroupId: number,
    impactId: number,
    config?: AxiosRequestConfig
  ): Promise<boolean> => {
    let res = await this.http.delete(
      `${this.url}/${organisationId}/projects/${projectId}/impact-groups/${impactGroupId}/impacts/${impactId}`,
      config
    );

    return res.data;
  };

  updateField = async (
    organisationId: number,
    projectId: number,
    impactGroupId: number,
    textField: string,
    idField: number,
    dateField: Date,
    numericField: number,
    impactGroupField: ImpactGroupField,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IAction>> => {
    let data = {
      TextField: textField,
      IdField: idField,
      DateField: dateField,
      NumericField: numericField
    };
    let res = await this.http.put(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${impactGroupId}/${impactGroupField}`,
      data,
      config
    );
    return res.data;
  };

  alreadyExists = async (
    organisationId: number,
    projectId: number,
    name: string,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<boolean>> => {
    let res = await this.http.post(
      `${this.url}/${organisationId}/projects/${projectId}/impact-groups/already-exists/`,
      { name: name },
      config
    );

    return res.data;
  };

  async getFilterList(
    organisationId: number,
    projectId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IImpactGroup[]>> {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/filter-list`;
    let res = await this.http.get(url, config);

    return res.data;
  }

  reviewRange = async (
    organisationId: number,
    projectId: number,
    ids: number[],
    comment: string,
    config?: AxiosRequestConfig
  ) => {
    let res = await this.http.post(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/review-range`,
      { impactGroupIds: ids, comment },
      config
    );
    return res.data;
  };

  deleteRange = async (organisationId: number, projectId: number, ids: number[], config?: AxiosRequestConfig) => {
    let res = await this.http.post(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/delete-range`,
      ids,
      config
    );
    return res.data;
  };

  duplicateImpactGroups = async (
    organisationId: number,
    projectId: number,
    impactGroupIds: number[],
    config?: AxiosRequestConfig
  ) => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/duplicate`;
    let res = await this.http.post(url, { impactGroupIds: impactGroupIds }, config);
    return res.data;
  };
}

const instance = new ImpactGroupsApi(http);
export default instance;
