import { action, makeObservable, observable } from "mobx";
import * as React from "react";
import ProgressIndicatorModel, {
  ProgressIndicatorModel as IProgressIndicatorModel
} from "../../../../../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import { IModalContextModel } from "../../../../../../../../core/modalZ/context/IModalContext";
import ModalContext from "../../../../../../../../core/modalZ/context/ModalContext";
import { BaseModel } from "../../../../../../../../core/util/BaseModel";
import ProjectStakeholdersApi, {
  ProjectStakeholdersApi as IProjectStakeholdersApi
} from "../../../../../../../../services/api/v2/projectStakeholders/ProjectStakeholders.api";

export class StakeholderImpactsSidebarModel extends BaseModel {
  projectStakeholderProvider: IProjectStakeholdersApi;
  modalService: IModalContextModel;
  httpProgress: IProgressIndicatorModel;
  projectId: number;
  @observable isLoading: boolean = true;
  @observable isSearchMode: boolean = false;
  @observable searchValue: string = "";
  @observable stakeholder: FP.Entities.IProjectStakeholder;
  @observable.ref stakeholderImpacts: FP.Entities.IImpact[] = [];
  @observable.ref filteredStakeholderImpacts: FP.Entities.IImpact[] = [];
  organisationId: number;
  stakeholderId: number;

  constructor(stakeholderId: number, organisationId: number, projectId: number) {
    super();
    makeObservable(this);
    this.projectId = projectId;
    this.projectStakeholderProvider = ProjectStakeholdersApi;
    this.modalService = ModalContext;
    this.httpProgress = ProgressIndicatorModel;
    this.organisationId = organisationId;
    this.stakeholderId = stakeholderId;
  }

  onMount = async () => {
    await this.loadStakeholder(this.stakeholderId);
  };

  onUnmount = () => {
    this.resetSearch();
  };

  @action
  setStakeholder = (stakeholder: FP.Entities.IProjectStakeholder) => {
    this.stakeholder = stakeholder;
  };

  @action
  setStakeholderImpacts = (impacts: FP.Entities.IImpact[]) => {
    this.stakeholderImpacts = impacts as FP.Entities.IImpact[];
    this.filteredStakeholderImpacts = impacts as FP.Entities.IImpact[];
    this.isLoading = false;
  };

  @action
  loadStakeholder = async (stakeholderId: number) => {
    this.isLoading = true;
    let res = await this.projectStakeholderProvider.getStakeholderById(
      this.organisationId,
      this.projectId,
      stakeholderId
    );
    if (!res || res.isError) return;
    let impactsRes = await this.projectStakeholderProvider.getProjectStakeholderImpacts(
      this.organisationId,
      this.projectId,
      res.payload.id
    );
    if (!impactsRes || impactsRes.isError) return;
    this.setStakeholder(res.payload);
    this.setStakeholderImpacts(impactsRes.payload);
  };

  @action
  updateSearchValue = (e: React.FormEvent<HTMLInputElement>) => {
    this.searchValue = e.currentTarget.value;
    this.filterStakeholderImpacts();
  };

  @action
  showSearchMode = () => {
    this.isSearchMode = true;
  };

  @action
  hideSearchMode = () => {
    this.isSearchMode = false;
  };

  @action
  resetSearch = () => {
    this.searchValue = "";
    this.filterStakeholderImpacts();
    this.hideSearchMode();
  };

  @action
  filterStakeholderImpacts = () => {
    if (this.searchValue) {
      const lowerSearch = this.searchValue.toLowerCase();
      this.filteredStakeholderImpacts = this.stakeholderImpacts.filter(impact => {
        const lowerName = impact.name.toLowerCase();
        const lowerRefNo = impact.refNumber.toLowerCase();

        return lowerName.includes(lowerSearch) || lowerRefNo.includes(lowerSearch);
      });
    } else {
      this.filteredStakeholderImpacts = this.stakeholderImpacts;
    }
  };
}
