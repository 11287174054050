import { GridBaseModel } from "../common/GridBaseModel";
import { HubEvents } from "../common/HubEventsEnum";

export class StakeholderUploadHub extends GridBaseModel {
  hubName: string = "stakeholders-upload";

  constructor() {
    super("stakeholder-upload");
  }

  onLoadData = callback => this.registerIncomingEvent(HubEvents.ON_STAKEHOLDER_UPLOAD_GRID_DATA, callback);

  invokeLoadData: (organisationId: number, projectId?: number) => Promise<void>;
  invokeUploadLoadData = async (organisationId: number, fileId: number, includeUploadedData: boolean) => {
    if (!this.isConnectionStarted) return;
    await this.connection.invoke(HubEvents.INVOKE_LOAD_STAKEHOLDER_UPLOAD_GRID_DATA, {
      organisationId: organisationId,
      fileId: fileId,
      includeUploadedData: includeUploadedData
    });
  };
}
const instance = new StakeholderUploadHub();
export default instance;
