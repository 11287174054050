import { observer } from "mobx-react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { GridHubStoreWrapper } from "../../../../../apps/insight/stores/GridHubStoreWrapper";
import { useStores } from "../../../../../apps/insight/stores/RootStore";
import { useFlightPathUser } from "../../../../../core/auth_insight";
import { ActionsGrid } from "../../../../../core/grids/builder/directorBuilder/grids/ActionsGrid/ActionsGridView_view";
import { useCurrentOrganisationId } from "../../../../../services/local/organisationContext/OrganisationContextModel";

export interface ActionListViewProps {}

const ActionListView: React.FunctionComponent<ActionListViewProps> = observer(() => {
  const { projectId } = useParams<{ projectId: string }>();
  const organisationId = useCurrentOrganisationId();
  const authUser = useFlightPathUser();
  const { actionStore } = useStores();

  useEffect(() => {
    actionStore.canInvoke() && actionStore.invokeDataLoad(organisationId, +projectId, authUser);
  }, [actionStore, organisationId, projectId, authUser]);

  return (
    <GridHubStoreWrapper store={actionStore} projectId={+projectId} organisationId={organisationId} authUser={authUser}>
      <div className="action-list-view insight-tab-content__space-filler">
        <ActionsGrid
          isLoading={actionStore.isLoading}
          data={actionStore.data}
          connectedUsers={actionStore.connectedUsers}
          onCellStateChange={actionStore.updateUserSelectedCell}
        />
      </div>
    </GridHubStoreWrapper>
  );
});

export { ActionListView };
