import { action, makeObservable, observable } from "mobx";
import ProgressIndicatorModel, {
  ProgressIndicatorModel as IProgressIndicatorModel
} from "../../../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import I18n from "../../../../../../core/localization/I18n";
import ToasterService, { IToasterService } from "../../../../../../core/toaster/ToasterService";
import { TOASTER_TOAST_TIME } from "../../../../../../core/toaster/Toaster_model";
import { BaseModel } from "../../../../../../core/util/BaseModel";
import TagsApi, { TagsApi as ITagsApi } from "../../../../../../services/api/v2/tags/Tags.api";
import { SingleFormModel } from "../../../../forms/singleFormModel/SingleForm_model";
import { TagSettingsModel } from "../TagSettings_model";
import { getTagMiniFormFields } from "./TagSettingsEditModal_fields";

export class TagSettingsEditModalModel extends BaseModel {
  organisationId: number;
  httpProgress: IProgressIndicatorModel;
  @observable isLoading: boolean = true;
  tagForm: SingleFormModel;
  tagProvider: ITagsApi;
  tag: FP.Entities.ITag;
  parentModel: TagSettingsModel;
  toasterService: IToasterService;

  constructor(parentModel: TagSettingsModel, tag: FP.Entities.ITag) {
    super();
    makeObservable(this);
    this.parentModel = parentModel;
    this.organisationId = parentModel.organisationId;
    this.tagProvider = TagsApi;
    this.httpProgress = ProgressIndicatorModel;
    this.toasterService = ToasterService;
    this.tag = tag;
    this.setForms();
  }

  onMount() {
    this.isLoading = false;
  }

  @action
  setForms = () => {
    this.tagForm = new SingleFormModel();
    this.tagForm.formFields = getTagMiniFormFields(this.parentModel.tags, this.tag);
  };

  @action
  updateTag = async () => {
    let tagFormRes = await this.tagForm.submit();
    this.tagForm.isSaving = true;
    if (!tagFormRes) return;

    tagFormRes = { ...tagFormRes, organisationId: this.organisationId };
    const res = {
      ...tagFormRes
    };
    this.httpProgress.showOverlay();
    const result = await this.tagProvider.update(this.organisationId, this.tag.id, res as FP.Entities.ITag);
    this.httpProgress.hideOverlay();

    if (!result || result.isError) {
      this.toasterService.showErrorToast(TOASTER_TOAST_TIME.SLOW).setContent(I18n.t("errors.updateTag"));
      return;
    }

    const tag = result.payload;
    this.parentModel.tags = this.parentModel.tags.map(t => (t.id === tag.id ? tag : t));
    return tag;
  };
}
