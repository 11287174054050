import { HubEvents } from "../common/HubEventsEnum";
import { GridBaseModel, IGridBaseModel } from "../common/GridBaseModel";

export class ImpactsHub extends GridBaseModel implements IGridBaseModel {
  hubName: string = "impacts";

  constructor() {
    super("impacts");
  }

  onLoadData = callback => this.registerIncomingEvent(HubEvents.ON_LOAD_IMPACT_DATA, callback);

  invokeLoadData = async (organisationId: number, projectId: number) => {
    if (!this.isConnectionStarted) return;
    await this.connection.invoke(HubEvents.INVOKE_LOAD_IMPACT_DATA, {
      organisationId: organisationId,
      projectId: projectId
    });
  };
}
const instance = new ImpactsHub();
export default instance;
