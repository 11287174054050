import { action, makeObservable, observable } from "mobx";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { IF } from "../../../../../../components/hoc/If";
import { Hr } from "../../../../../../components/ui/Hr";
import { GetI18nParsedList, PROJECT_PERMISSION_SCOPE_OPTIONS } from "../../../../../../constants";
import { Dropdown, DropdownOption } from "../../../../../../components/ui/_forms/Dropdown";
import { useCurrentOrganisationId } from "../../../../../../services/local/organisationContext/OrganisationContextModel";
import I18n from "../../../../../../core/localization/I18n";
import { SingleFormModel } from "../../../../forms/singleFormModel/SingleForm_model";
import { SingleForm } from "../../../../forms/singleFormModel/SingleForm_view";
import { GetIndividualUserPermissionsFields } from "./IndividualUserPermission_fields";
import { ToggleSwitch } from "../../../../../../components/ui/_forms/ToggleSwitch";
import { FormRequiredFieldIndicator } from "../../../../../../components/ui/_forms/FormRequiredFieldIndicator/FormRequiredFieldIndicator";
import { Button, ButtonTypes } from "../../../../../../components/ui/Button";
import { ErrorMessage } from "../../../../../../components/ui/ErrorMessage";
import { Enums } from "../../../../../../enums";
import OrganisationsApi, {
  OrganisationsApi as IOrganisationsApi
} from "../../../../../../services/api/v2/organisations/Organisations.api";

class IndividualUserPermissionFormModel {
  formModel: SingleFormModel;
  organisationsProvider: IOrganisationsApi;
  @observable shouldShowPermissions: boolean = false;

  constructor(organisationId: number, projectId: number) {
    makeObservable(this);
    this.formModel = new SingleFormModel();
    this.organisationsProvider = OrganisationsApi;
    this.formModel.formFields = GetIndividualUserPermissionsFields(
      this.organisationsProvider,
      organisationId,
      projectId,
      this.setShowPermissions
    );
  }

  @action
  setShowPermissions = (val: boolean) => {
    this.shouldShowPermissions = val;
  };
}

interface IndividualUserPermissionFormProps {
  hideModal: () => void;
  addUserToProject: (user: any) => void;
  projectId: number;
}
export const IndividualUserPermissionForm: React.FC<IndividualUserPermissionFormProps> = observer(
  ({ hideModal, addUserToProject, projectId }) => {
    const organisationId = useCurrentOrganisationId();
    const [permissionValue, setPermissionValue] = useState(null);
    const [model] = useState(() => new IndividualUserPermissionFormModel(organisationId, projectId));
    const [hasProjectAccess, setHasProjectAccess] = useState(true);
    const [hasStakeholderAccess, setHasStakeholderAccess] = useState(true);
    const [hasImpactAccess, setHasImpactAccess] = useState(true);
    const [hasActionAccess, setHasActionAccess] = useState(true);
    const [incompleteFormSubmitted, setIncompleteFormSubmitted] = useState(false);

    const isFormValid = () => {
      if (permissionValue) return true;
    };

    const permissionsScopeOptions = () => {
      return PROJECT_PERMISSION_SCOPE_OPTIONS.filter(x => x.key !== Enums.PermissionScope.OWNER);
    };

    const changeAllAccessIfFalse = (projectAccessAllowed: boolean): boolean => {
      if (!projectAccessAllowed) {
        setHasProjectAccess(false);
        setHasStakeholderAccess(false);
        setHasImpactAccess(false);
        setHasActionAccess(false);
        return false;
      }
      setHasProjectAccess(true);
      return true;
    };

    return (
      <div className="individual-user-form mt-8">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <h1>{I18n.t("phrases.addIndividualUser")}</h1>
            </div>
            <div className="col-12">
              <p>{I18n.t("phrases.addIndividualUserDescription")}</p>
            </div>
          </div>
          <SingleForm model={model.formModel} />
          <IF condition={model.shouldShowPermissions}>
            <Hr />
            <div className="row">
              <div className="col">
                <h4>
                  {I18n.t("forms.permissionLevel")} <FormRequiredFieldIndicator />
                </h4>
                <Dropdown
                  items={GetI18nParsedList(I18n, permissionsScopeOptions())}
                  value={permissionValue}
                  placeholder={I18n.t("placeholders.selectPermissionLevel")}
                  onItemSelected={e => {
                    setPermissionValue(e);
                    setIncompleteFormSubmitted(false);
                  }}
                  selector="label"
                >
                  <DropdownOption label="" />
                </Dropdown>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-12">
                <h4>{I18n.t("phrases.accessPermissions")}</h4>
              </div>
              <div className="col-12 mb-2 align-items-center d-flex">
                <ToggleSwitch
                  isChecked={hasProjectAccess}
                  onChange={e => {
                    changeAllAccessIfFalse(e.target.checked);
                  }}
                />
                <p className="mb-0 mt-1 ml-4 d-inline-block">{I18n.t("forms.hasProjectAccess")}</p>
              </div>
              <div className="col-12 mb-2 align-items-center d-flex">
                <ToggleSwitch
                  isChecked={hasStakeholderAccess}
                  onChange={e => {
                    hasProjectAccess ? setHasStakeholderAccess(e.target.checked) : setHasStakeholderAccess(false);
                  }}
                  disabled={!hasProjectAccess}
                />
                <p className="mb-0 mt-1 ml-4 d-inline-block">{I18n.t("forms.hasStakeholderAccess")}</p>
              </div>
              <div className="col-12 mb-2 align-items-center d-flex">
                <ToggleSwitch
                  isChecked={hasImpactAccess}
                  onChange={e => {
                    hasProjectAccess ? setHasImpactAccess(e.target.checked) : setHasImpactAccess(false);
                  }}
                  disabled={!hasProjectAccess}
                />
                <p className="mb-0 mt-1 ml-4 d-inline-block">{I18n.t("forms.hasImpactAccess")}</p>
              </div>
              <div className="col-12 mb-2 align-items-center d-flex">
                <ToggleSwitch
                  isChecked={hasActionAccess}
                  onChange={e => {
                    hasProjectAccess ? setHasActionAccess(e.target.checked) : setHasActionAccess(false);
                  }}
                  disabled={!hasProjectAccess}
                />
                <p className="mb-0 mt-1 ml-4 d-inline-block">{I18n.t("forms.hasActionAccess")}</p>
              </div>
              <div className="col-12 mt-4">
                <IF condition={incompleteFormSubmitted}>
                  <ErrorMessage>{I18n.t("phrases.selectPermissionLevel")}</ErrorMessage>
                </IF>
                <Button
                  className="float-right mr-2"
                  type={ButtonTypes.PRIMARY}
                  onClick={async () => {
                    if (!isFormValid()) {
                      setIncompleteFormSubmitted(true);
                      return;
                    }
                    let res: any = await model.formModel.submit();
                    res = {
                      ...res,
                      permissionLevel: permissionValue.key,
                      hasProjectAccess,
                      hasStakeholderAccess,
                      hasImpactAccess,
                      hasActionAccess
                    };
                    addUserToProject(res);
                  }}
                >
                  {I18n.t("phrases.add")}
                </Button>
                <Button
                  className="float-right mr-2"
                  type={ButtonTypes.OUTLINE_PRIMARY}
                  onClick={() => {
                    hideModal();
                  }}
                >
                  {I18n.t("phrases.cancel")}
                </Button>
              </div>
            </div>
          </IF>
        </div>
      </div>
    );
  }
);
