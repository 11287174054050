import React from "react";
import { StakeholderCollapsableListView } from "../../../../change/projects/view/Stakeholders";

interface StakeholderListProps {
  projectId: number;
  stakeholders: FP.Entities.IStakeholder[];
  className?: string;
  label: string;
}

export const StakeholderList: React.FC<StakeholderListProps> = props => {
  const { projectId, stakeholders, className } = props;

  return (
    <>
      <div className={`row mt-4 ${className ? className : ""}`}>
        <div className="col">
          <h2 className="mb-0">{props.label}</h2>
        </div>
      </div>
      <div className="row">
        <div className="col mt-2">
          <StakeholderCollapsableListView projectId={projectId} users={stakeholders} />
        </div>
      </div>
    </>
  );
};
