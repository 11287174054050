import { QueryStringParts } from "../../enums";

const decodeParameter = (parameter: string) => {
  return parameter === null ? null : decodeURI(parameter);
};

export const getParameter = (query: QueryStringParts) => {
  const param = GetFilterParameterFromUrl(query);
  return decodeParameter(param);
};

const getQueryString = () => {
  return decodeURI(window.location.search);
};

const getQuery = (queryString: string, parameter: string) => {
  const params = new URLSearchParams(queryString);
  let result = params.get(parameter);
  return result === "null" ? null : result;
};

export const GetFilterParameterFromUrl = (parameterName: string) => {
  const queryString = getQueryString();
  return getQuery(queryString, parameterName);
};

export const getLocationParameter = () => {
  const parameter = GetFilterParameterFromUrl(QueryStringParts.Location);
  return decodeParameter(parameter);
};

export const getBusinessAreaParameter = () => {
  const parameter = GetFilterParameterFromUrl(QueryStringParts.BusinessArea);
  return decodeParameter(parameter);
};
export const getBusinessAreasParameter = () => {
  const parameter = GetFilterParameterFromUrl(QueryStringParts.BusinessAreas);
  return decodeParameter(parameter);
};

export const getWithinMonthParameter = () => {
  const parameter = GetFilterParameterFromUrl(QueryStringParts.WithinMonth);
  return +parameter;
};

export const getWithinYearParameter = () => {
  const parameter = GetFilterParameterFromUrl(QueryStringParts.WithinYear);
  return +parameter;
};

export const getGapAnalysisParameter = () => {
  const parameter = GetFilterParameterFromUrl(QueryStringParts.GapAnalysis);
  return parameter;
};

export const getValueParameter = () => {
  const parameter = GetFilterParameterFromUrl(QueryStringParts.Value);
  return parameter;
};

export const getLevelParameter = () => {
  const parameter = GetFilterParameterFromUrl(QueryStringParts.Level);
  return parameter;
};

export const getTypeParameter = () => {
  const parameter = GetFilterParameterFromUrl(QueryStringParts.Type);
  return decodeParameter(parameter);
};

export const getOwnerParameter = () => {
  const parameter = GetFilterParameterFromUrl(QueryStringParts.Owner);
  return decodeParameter(parameter);
};

export const getProgressStatusParameter = () => {
  const parameter = GetFilterParameterFromUrl(QueryStringParts.ProgressStatus);
  return decodeParameter(parameter);
};
export const getActionStakeholders = () => {
  const parameter = GetFilterParameterFromUrl(QueryStringParts.ActionStakeholders);
  return decodeParameter(parameter);
};

export const getAssignedToParameter = () => {
  const parameter = GetFilterParameterFromUrl(QueryStringParts.AssignedTo);
  return decodeParameter(parameter);
};

export const getCreatedByParameter = () => {
  const parameter = GetFilterParameterFromUrl(QueryStringParts.CreatedBy);
  return decodeParameter(parameter);
};

export const getReviewedByParameter = () => {
  const parameter = GetFilterParameterFromUrl(QueryStringParts.ReviewedBy);
  return decodeParameter(parameter);
};
export const getProjectStakeholderNames = () => {
  const parameter = GetFilterParameterFromUrl(QueryStringParts.ProjectStakeholderNames);
  return decodeParameter(parameter);
};

export const getAudienceNames = () => {
  const parameter = GetFilterParameterFromUrl(QueryStringParts.AudienceNames);
  return decodeParameter(parameter);
};
export const getActionAudiencesNames = () => {
  const parameter = GetFilterParameterFromUrl(QueryStringParts.ActionAudiences);
  return decodeParameter(parameter);
};
