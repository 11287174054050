import * as React from "react";

import { observer } from "mobx-react";
import { InsightInnerNavContent } from "../../../../components/widgets/innerNavigation/InnerNavContent_view";
import I18nObj from "../../../../core/localization/I18n";
import Pages from "../../../../routes/InsightRoutes";
import { useCurrentOrganisationId } from "../../../../services/local/organisationContext/OrganisationContextModel";

export interface SettingsViewProps {}

export const SettingsView: React.FunctionComponent<SettingsViewProps> = observer(() => {
  const organisationId = useCurrentOrganisationId();

  const items = [
    {
      label: I18nObj.t("phrases.generalSettings"),
      url: Pages.users.settings.index.generateLink(organisationId)
    },
    {
      label: I18nObj.t("phrases.securitySettings"),
      url: Pages.users.settings.password.generateLink(organisationId)
    },
    {
      label: I18nObj.t("phrases.organisationsSettings"),
      url: Pages.users.settings.organisations.generateLink(organisationId)
    }
  ];
  return (
    <div className="settings-view">
      <InsightInnerNavContent title={I18nObj.get("phrases.settings")} items={items} />
    </div>
  );
});
