import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
import { useCurrentOrganisationId } from "../../../../../../../../services/local/organisationContext/OrganisationContextModel";
import { useEffect, useState } from "react";
import { AudiencesGridModalModel } from "./AudiencesGridModal_model";
import { PositionedSpinner } from "@flightpath/coreui/dist/ui/PositionedSpinner";
import { GridTypes } from "../../../../../../../../enums";
import { AudiencesGrid } from "../../AudiencesGridView_view";

interface AudiencesGridModalViewProps {
  itemIds: number[];
  onAssignSuccess: (selectedItemIds: number[]) => void;
  selectedLinkItemIds?: number[];
}
const AudiencesGridModalView: React.FunctionComponent<AudiencesGridModalViewProps> = observer(
  ({ itemIds, onAssignSuccess, selectedLinkItemIds }) => {
    const { projectId } = useParams<{ projectId: string }>();
    const organisationId = useCurrentOrganisationId();
    const [model] = useState(
      () => new AudiencesGridModalModel(+organisationId, +projectId, itemIds, selectedLinkItemIds)
    );

    useEffect(() => {
      model.onMount();
      return () => model.onUnmount();
      //eslint-disable-next-line
    }, []);

    if (model.isLoading) return <PositionedSpinner />;
    return (
      <div className="processes-modal-view insight-tab-content__space-filler" style={{ height: "100%" }}>
        <AudiencesGrid
          isLoading={model.isLoading}
          data={model.audiences}
          gridToolbarType="link-modal"
          onAssignSuccess={onAssignSuccess}
          preSelectedItemIds={model.selectedItemIds || []}
          gridType={GridTypes.AUDIENCE_MODAL_GRID}
        />
      </div>
    );
  }
);

export { AudiencesGridModalView };
