import { IMPACT_LEVEL_OPTIONS, MILESTONE_TYPE_OPTIONS, PROGRESS_STATUS_OPTIONS } from "../../../../../../../constants";
import { ImpactLevel } from "../../../../../../../enums";
import I18n from "../../../../../../localization/I18n";
import { ISdColDef, ISdFilterDef } from "../../baseColumn/BaseColumnTypes";
import { BASE_COLUMN_CONFIG, BASE_FILTER_CONFIG } from "../../baseColumn/BaseColumn_config";

export const AUTOCOMPLETE_COLUMN_CONFIG = (columnOptions?: ISdColDef<any, any>): ISdColDef<any, any> => ({
  ...BASE_COLUMN_CONFIG,
  field: "autocomplete",
  headerName: "header Name",
  editable: false,
  cellDataType: "any",
  ...columnOptions
});

export const AUTOCOMPLETE_FILTER_CONFIG: ISdFilterDef = {
  ...BASE_FILTER_CONFIG
};

export const mapProgressStatuses = () => {
  const progressStatuses = PROGRESS_STATUS_OPTIONS.map(e => {
    return {
      label: I18n.t(e.label),
      key: e.key + ""
    } as FP.Generic.IKeyLabel;
  });

  return progressStatuses;
};

export const mapMilestoneTypes = () => {
  const progressStatuses = MILESTONE_TYPE_OPTIONS.map(e => {
    return {
      label: I18n.t(e.label),
      key: e.key + ""
    } as FP.Generic.IKeyLabel;
  });

  return progressStatuses;
};

export const mapImpactLevels = () => {
  const impactLevels = IMPACT_LEVEL_OPTIONS.filter(e => e.key !== ImpactLevel.UNKNOWN).map(e => {
    return {
      label: I18n.t(e.label),
      key: e.key + ""
    } as FP.Generic.IKeyLabel;
  });

  return impactLevels;
};
