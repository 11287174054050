import { makeObservable, observable } from "mobx";
import { ITableModel } from "../../../../../core/table/ITableModel";
import { TableModel } from "../../../../../core/table/Table_model";
import { BaseModel } from "../../../../../core/util/BaseModel";
import UsersApi, { UsersApi as IUsersApi } from "../../../../../services/api/v2/users/Users.api";
import { GetOrgSettingsTableConfig } from "./OrganisationsTable_config";

export class OrganisationsSettingsModel extends BaseModel {
  @observable.ref orgs: FP.Entities.IOrganisation[];
  @observable isLoading: boolean = true;
  tableModel: ITableModel<FP.Entities.IOrganisation>;
  usersProvider: IUsersApi;
  organisationId: number;
  /**
   *
   */
  constructor(organisationId: number) {
    super();
    makeObservable(this);
    this.usersProvider = UsersApi;
    this.tableModel = new TableModel();

    let tableConfig = GetOrgSettingsTableConfig(this, this.tableModel);

    this.tableModel.set(tableConfig);
    this.organisationId = organisationId;
  }

  loadOrgs = async () => {
    let res = await this.usersProvider.getOrgs(this.organisationId);
    if (!res || res.isError) return;
    this.setOrgs(res.payload);
  };

  setOrgs = (orgs: FP.Entities.IOrganisation[]) => {
    this.orgs = orgs;
    this.tableModel.setData(this.orgs);
    this.isLoading = false;
  };

  onMount = () => {
    this.loadOrgs();
  };

  onUnmount = () => {};
}
