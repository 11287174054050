import { IFlightPathApiResponse } from "../BaseApiModel";
import http from "../Http";
import { IFilteredOptions } from "../filteredApi/FilteredApiModel";
import { FilteredOrganisationApiModel } from "../filteredApi/FilteredOrganisationApiModel";
import { AxiosInstance, AxiosRequestConfig } from "axios";

export class BusinessAreasApi extends FilteredOrganisationApiModel<FP.Entities.IBusinessArea> {
  controller: string = "business-areas";

  constructor(http: AxiosInstance) {
    super(http, "business-areas");
  }

  getFilteredAsync = async (
    filterOptions: Partial<IFilteredOptions>,
    config?: AxiosRequestConfig,
    organisationId?: number
  ) => {
    const query = this.getRequestQuery(filterOptions);
    const res = await this.http.get(`${this.url}/${organisationId}/${this.controller}${query}`, config);
    return res.data;
  };

  getRoles = async (
    organisationId: number,
    businessAreaId: number,
    filterOptions?: IFilteredOptions,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IRole[]>> => {
    const url = `${this.url}/${organisationId}/${this.controller}/${businessAreaId}/roles${
      (filterOptions && this.getRequestQuery(filterOptions)) || ""
    }`;
    let res = await this.http.get(url, config);
    return res.data;
  };

  getHierarchyStakeholders = async (
    organisationId: number,
    businessAreaId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IStakeholder[]>> => {
    const url = `${this.url}/${organisationId}/${this.controller}/${businessAreaId}/stakeholders-tree`;
    let res = await this.http.get(url, config);
    return res.data;
  };
}

const instance = new BusinessAreasApi(http);

export default instance;
