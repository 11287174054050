import { action, makeObservable, observable } from "mobx";
import * as React from "react";

import ProgressIndicatorModel, {
  ProgressIndicatorModel as IProgressIndicatorModel
} from "../../../../../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import { BaseModel } from "../../../../../../../../core/util/BaseModel";
import ActionsApi, { ActionsApi as IActionsApi } from "../../../../../../../../services/api/v2/actions/Actions.api";
import { IModalContextModel } from "../../../../../../../../core/modalZ/context/IModalContext";
import ModalContext from "../../../../../../../../core/modalZ/context/ModalContext";

export class ActionImpactsSidebarModel extends BaseModel {
  actionsProvider: IActionsApi;
  modalService: IModalContextModel;
  httpProgress: IProgressIndicatorModel;
  projectId: number;
  @observable isLoading: boolean = true;
  @observable isSearchMode: boolean = false;
  @observable searchValue: string = "";
  @observable.ref action: FP.Entities.IAction;
  @observable.ref actionImpacts: FP.Entities.IImpact[] = [];
  @observable.ref filteredActionImpacts: FP.Entities.IImpact[] = [];
  organisationId: number;

  constructor(actionId: number, organisationId: number, projectId: number) {
    super();
    makeObservable(this);
    this.projectId = projectId;
    this.actionsProvider = ActionsApi;
    this.modalService = ModalContext;
    this.httpProgress = ProgressIndicatorModel;
    this.organisationId = organisationId;
    this.loadAction(actionId);
  }

  onMount = () => {};

  onUnmount = () => {
    this.resetSearch();
  };

  @action
  setAction = (action: FP.Entities.IAction) => {
    this.action = action;
    this.actionImpacts = action.impacts as FP.Entities.IImpact[];
    this.filteredActionImpacts = action.impacts as FP.Entities.IImpact[];
    this.isLoading = false;
  };

  @action
  loadAction = async (actionId: number) => {
    this.isLoading = true;
    let res = await this.actionsProvider.getDetailedByIdAsync(this.organisationId, this.projectId, actionId);
    if (!res || res.isError) return;

    this.setAction(res.payload);
  };

  @action
  updateSearchValue = (e: React.FormEvent<HTMLInputElement>) => {
    this.searchValue = e.currentTarget.value;
    this.filterActionImpacts();
  };

  @action
  showSearchMode = () => {
    this.isSearchMode = true;
  };

  @action
  hideSearchMode = () => {
    this.isSearchMode = false;
  };

  @action
  resetSearch = () => {
    this.searchValue = "";
    this.filterActionImpacts();
    this.hideSearchMode();
  };

  @action
  filterActionImpacts = () => {
    if (this.searchValue) {
      const lowerSearch = this.searchValue.toLowerCase();
      this.filteredActionImpacts = this.actionImpacts.filter(impact => {
        const lowerName = impact.name.toLowerCase();
        const lowerRefNo = impact.refNumber.toLowerCase();

        return lowerName.includes(lowerSearch) || lowerRefNo.includes(lowerSearch);
      });
    } else {
      this.filteredActionImpacts = this.actionImpacts;
    }
  };
}
