import * as React from "react";
import Table from "../../components/ui/Table";
import DivTable from "../../components/ui/Table/DivTable";
import { ITableHeaderModel } from "./ITableHeaderModel";
import { observer } from "mobx-react";
import { Icon, IconSymbols } from "../../components/ui/Icon";
import { Enums } from "../../enums";
import { ITableModel } from "./ITableModel";
import { IFilterModel } from "../filter/Filter_model";
import * as _ from "lodash";

export interface TableHeaderViewProps {
  model: ITableHeaderModel;
  onClick?: (selector: string, isAsc: boolean) => void;
  className?: string;
}

@observer
export class TableHeaderView extends React.Component<TableHeaderViewProps, any> {
  render() {
    let { model } = this.props;
    let T = model.isDivTable ? DivTable : Table;
    return (
      <T.HeaderCol
        className={`${this.props.className || ""} divTableHead--${_.kebabCase(model.config.key)}`}
        data-clickable={typeof model.action === "function"}
        onClick={() => {
          model.action && model.action(model);
        }}
      >
        <div className="table-header">
          <div className="table-header__content">{model.config.content}</div>
          <div className="table-header__icon">
            {model.config.showSortIcons ? (
              model.isSortAsc === null ? (
                <Icon size={Enums.UiSizes.SM} symbol={IconSymbols.SortNone} />
              ) : (
                <Icon size={Enums.UiSizes.SM} symbol={model.isSortAsc ? IconSymbols.SortUp : IconSymbols.SortDown} />
              )
            ) : null}
          </div>
        </div>
      </T.HeaderCol>
    );
  }
}

export const ResetTableHeaderIcon = (tableHeaders: ITableHeaderModel[], currentSortKey?: string) => {
  tableHeaders.forEach(header => {
    if (header.config.key !== currentSortKey) {
      header.resetSort();
    }
  });
};

export const AddTableColumnSort = (
  model: ITableHeaderModel,
  tableModel: ITableModel<any>,
  filterModel: IFilterModel<any>,
  sortKey?: string
) => {
  const key = sortKey || model.config.key;
  ResetTableHeaderIcon(tableModel.colHeaders, key);
  model.toggleSort();
  if (filterModel.isSortEqualTo(key)) {
    filterModel.toggleSort();
  } else {
    filterModel.addSort({ key: key, isAsc: true });
  }

  filterModel.loadData();
};
