import { AxiosInstance, AxiosRequestConfig } from "axios";
import { IFlightPathApiResponse } from "../BaseApiModel";
import { FilteredOrganisationApiModel } from "../filteredApi/FilteredOrganisationApiModel";
import http from "../Http";

export class ChangeRolesApi extends FilteredOrganisationApiModel<FP.Entities.IChangeRole> {
  controller: string = "change-roles";

  constructor(http: AxiosInstance) {
    super(http, "change-roles");
  }

  addImpactChangeRoleAssoc = async (
    organisationId: number,
    projectId: number,
    impactId: number,
    projectStakeholderId: number,
    change: FP.Entities.IChangeRole,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<boolean>> => {
    let res = await this.http.post(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/impacts/${impactId}`,
      { ...change, projectStakeholderId },
      config
    );

    return res.data;
  };

  getAllAsync = async (
    organisationId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IChangeRole[]>> => {
    let res = await this.http.get(`${this.url}/${organisationId}/${this.controller}`, config);
    return res.data;
  };

  removeImpactChangeRoleAssoc = async (
    organisationId: number,
    projectId: number,
    changeRoleId: number,
    impactId: number,
    projectStakeholderId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<boolean>> => {
    let url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${changeRoleId}/impacts-project-stakeholders/${projectStakeholderId}`;
    let res = await this.http.delete(url, config);
    return res.data;
  };
}

const instance = new ChangeRolesApi(http);
export default instance;
