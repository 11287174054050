import { GridBaseModel } from "../common/GridBaseModel";
import { HubEvents } from "../common/HubEventsEnum";

export class ProjectProcessesHub extends GridBaseModel {
  hubName: string = "project-processes";

  constructor() {
    super("project-processes");
  }
  onLoadData = callback => this.registerIncomingEvent(HubEvents.ON_LOAD_PROJECT_PROCESSES_DATA, callback);

  invokeLoadData = async (organisationId: number, projectId: number) => {
    if (!this.isConnectionStarted) return;
    await this.connection.invoke(HubEvents.INVOKE_LOAD_PROJECT_PROCESSES_DATA, {
      organisationId: organisationId,
      projectId: projectId
    });
  };
}
const instance = new ProjectProcessesHub();
export default instance;
