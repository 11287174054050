import React, { useEffect, useState } from "react";
import { ToggleSwitch } from "../../../../../components/ui/_forms/ToggleSwitch";

export interface IndividualUserPermissionsToggleProps {
  disabled?: boolean;
  isChecked: boolean;
  onChange: (isChecked: boolean) => void;
}

export const UserPermissionsToggle: React.FC<IndividualUserPermissionsToggleProps> = props => {
  const [isChecked, setIsChecked] = useState(props.isChecked);
  useEffect(() => {
    setIsChecked(props.isChecked);
  }, [props.isChecked]);
  return (
    <ToggleSwitch
      disabled={props.disabled}
      isChecked={isChecked}
      onChange={e => {
        setIsChecked(!isChecked);
        props.onChange(!isChecked);
      }}
    />
  );
};
