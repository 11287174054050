import { PieChart } from "@flightpath/coreui/dist/widgets/pie";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { PositionedSpinner } from "../../../../../../../components/ui/PositionedSpinner";
import { StakeholderType } from "../../../../../../../enums";
import { useCurrentOrganisationId } from "../../../../../../../services/local/organisationContext/OrganisationContextModel";
import { PieTooltip } from "../../../../../projects/view/components/ReportingPie";
import { ActionCountSidebarModel } from "./ActionCountSidebar_model";
import { useHref } from "react-router-dom";

export interface ActionCountProps {
  stakeholderId: number;
  stakeholderName: string;
  stakeholderType: StakeholderType;
}

export const ActionCountSidebar: React.FC<ActionCountProps> = observer(props => {
  const { stakeholderId, stakeholderName, stakeholderType } = props;
  const organisationId = useCurrentOrganisationId();
  const basename = useHref("/");
  const [model, setModel] = useState(
    () => new ActionCountSidebarModel(organisationId, stakeholderId, stakeholderName, stakeholderType, basename)
  );

  useEffect(() => {
    setModel(new ActionCountSidebarModel(organisationId, stakeholderId, stakeholderName, stakeholderType, basename));
    // eslint-disable-next-line
  }, [stakeholderId]);

  useEffect(() => {
    model.onMount();
  }, [model]);

  if (!model || model.isLoading) return <PositionedSpinner />;

  return (
    <div style={{ width: "100%", height: "70vh" }}>
      <div className="w-100 h-100 d-flex  justify-content-center align-items-center">
        <PieChart
          data={model.drillDownPieData}
          tooltipFn={PieTooltip}
          colors={model.getPieColors()}
          hasLinkLabels={true}
          margin={{
            top: 120,
            right: 100,
            bottom: 100,
            left: 100
          }}
          onClickFn={model.onPieClick}
        />
      </div>
    </div>
  );
});
