import { ColDef } from "ag-grid-community";
import { IBaseColumnProps, baseTextColumn } from "../../../../../../../../core/grids/BaseColumns";
import I18n from "../../../../../../../../core/localization/I18n";

export const colEmail = (props?: IBaseColumnProps): ColDef => {
  return {
    ...baseTextColumn({
      fieldName: "email",
      heading: I18n.t("grids.email"),
      width: 220,
      pinned: props.pinned,
      editable: false,
      ...props
    })
  };
};

export const colRole = (props?: IBaseColumnProps): ColDef => {
  return {
    ...baseTextColumn({
      fieldName: "role",
      heading: I18n.t("grids.role"),
      width: 150,
      editable: false,
      ...props
    })
  };
};

export const colBusinessArea = (props?: IBaseColumnProps): ColDef => {
  return {
    ...baseTextColumn({
      fieldName: "businessArea",
      heading: I18n.t("grids.businessArea"),
      width: 150,
      editable: false,
      ...props
    })
  };
};

export const colOwnerName = (props?: IBaseColumnProps): ColDef => {
  return {
    ...baseTextColumn({
      fieldName: "ownerName",
      heading: I18n.t("grids.relationshipOwner"),
      width: 150,
      editable: false,
      ...props
    })
  };
};

export const colName = (props?: IBaseColumnProps): ColDef => {
  let res: ColDef = {
    ...baseTextColumn({
      fieldName: "name",
      heading: I18n.t("grids.name"),
      width: 150,
      editable: false,
      ...props
    })
  };

  return res;
};
