import React, { useContext } from "react";
import { SettingType } from "../../enums";
import {
  IOrganisationSettingsContextModel,
  OrganisationSettingsContextModel
} from "./OrganisationSettingsContextModel";

let OrganisationSettingsContext = new OrganisationSettingsContextModel();

const OrganisationSettingsReactContext =
  React.createContext<IOrganisationSettingsContextModel>(OrganisationSettingsContext);

export const OrganisationSettingsContextProvider: React.FC<ReactProps> = ({ children }) => (
  <OrganisationSettingsReactContext.Provider value={OrganisationSettingsContext}>
    {children}
  </OrganisationSettingsReactContext.Provider>
);

export function useOrganisationSettingsContext() {
  return useContext(OrganisationSettingsReactContext);
}

export function useGetOrganisationSetting(setting: SettingType) {
  return useOrganisationSettingsContext().getSetting(setting);
}

export function useGetOrganisationSettingValue(setting: SettingType) {
  return useOrganisationSettingsContext().getSettingValue(setting);
}

export function useIsVisualisationsEnabled() {
  return useOrganisationSettingsContext().isVisualisationsEnabled();
}
export default OrganisationSettingsContext as IOrganisationSettingsContextModel;
