import { JoinOperator } from "ag-grid-community";
import { ISimpleFilterModelType } from "ag-grid-community/dist/types/core/filter/provided/simpleFilter";

const textConditionObject = (value: string, type: string) => {
  return {
    filterType: "text",
    type: type,
    filter: value
  };
};

const operativeTextFilter = (operatorName: string, conditions) => {
  return {
    filterType: "text",
    operator: operatorName,
    ...conditions
  };
};

export const notBlank_Filter = () => {
  const filter = {
    type: "notBlank"
  };
  return filter;
};

export const blank_Filter = () => {
  const filter = {
    type: "blank"
  };
  return filter;
};

export const textEquals_Filter = (value: string) => {
  const filter = {
    filterType: "text",
    type: "equals",
    filter: value
  };
  return filter;
};

export const textContains_Filter = (value: string, operator?: JoinOperator) => {
  const filter = {
    filterType: "text",
    type: "contains",
    filter: value
  };
  if (!!operator) {
    filter["operator"] = operator;
  }
  return filter;
};

export const getMultiValueCondition_Filter = (
  items: string[],
  filterType: ISimpleFilterModelType,
  condition: JoinOperator
) => {
  const GetFilterConditions = (items: string[]) => {
    return items.map(e => ({
      filterType: "text",
      type: filterType,
      filter: e
    }));
  };
  let res = {
    filterType: "text",
    operator: condition,
    conditions: GetFilterConditions(items)
  };

  return res;
};

export const getMultipleFilters_Filter = (conditions: any, operator: JoinOperator) => {
  return {
    operator,
    conditions: conditions
  };
};

export const textIncludes_Filter = (value: string) => {
  const filter = {
    filterType: "text",
    type: "includes",
    filter: value
  };
  return filter;
};

export const dateGreaterThan_Filter = (date: string) => {
  const filter = {
    type: "greaterThan",
    dateFrom: date,
    dateTo: null
  };
  return filter;
};

export const dateLessThan_Filter = (date: string) => {
  const filter = {
    type: "lessThan",
    dateFrom: date,
    dateTo: null
  };
  return filter;
};

export const textEqualsOr_Filter = (values: string[]) => {
  const conditions = {};

  values.forEach((e, i) => {
    conditions[`condition${i + 1}`] = textConditionObject(e, "equals");
  });
  const result = operativeTextFilter("OR", conditions);
  return result;
};
