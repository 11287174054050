import * as React from "react";
import { ITextFieldModel } from "../../../../core/forms/controls/textField/ITextFieldModel";
import { INIT_TEXT_FIELD } from "../../../../core/forms/controls/textField/TextField_init";
import { generateFormFieldsFromJson } from "../../../../core/forms/helpers/FormFieldMappers";
import { Validations } from "../../../../core/forms/helpers/Validations";
import { ErrorMessage } from "../../../../components/ui/ErrorMessage";
import { INIT_RTEDITOR } from "../../../../core/forms/controls/rteditor/RTEditor_model";
import { IRTEditorModel } from "../../../../core/forms/controls/rteditor/IRTEditorModel";
import { IDatePickerModel } from "../../../../core/forms/controls/datePicker/IDatePickerModel";
import { INIT_DATEPICKER } from "../../../../core/forms/controls/datePicker/DatePicker_model";
import moment from "moment";
import I18n from "../../../../core/localization/I18n";
import { FORM_COL, MILESTONE_TYPE_OPTIONS } from "../../../../constants";
import { IconSymbols } from "@flightpath/coreui/dist/generated/IconSymbols";
import { IAutocompleteModel } from "../../../../core/forms/controls/autocomplete/IAutocompleteModel";
import { INIT_AUTOCOMPLETE } from "../../../../core/forms/controls/autocomplete/Autocomplete_init";
import { AutocompleteOption } from "../../../../components/ui/_forms/Autocomplete/AutocompleteOption";
import { FormTooltip } from "../../../../components/ui/_forms/FormTooltip";

export const getFormFields = (milestone?: FP.Entities.IMilestone) => {
  const name: Partial<ITextFieldModel> = {
    ...INIT_TEXT_FIELD,
    key: "name",
    placeholder: I18n.t("placeholders.milestoneName"),
    label: <label htmlFor="name">{I18n.t("forms.milestoneName")} *</label>,
    validate: function () {
      let self: ITextFieldModel = this;
      let res = true;
      if (Validations.IS_EMPTY(self.value)) {
        self.errorMessage = <ErrorMessage>{I18n.t("validations.milestoneName")}</ErrorMessage>;
        res = false;
      }

      return res;
    },
    fieldClassName: FORM_COL.FULL_WIDTH,
    value: milestone?.name
  };

  const description: Partial<IRTEditorModel> = {
    ...INIT_RTEDITOR,
    key: "description",
    label: <label htmlFor="description">{I18n.t("forms.milestoneDescription")}</label>,
    fieldClassName: FORM_COL.FULL_WIDTH,
    value: milestone?.description
  };

  const deadline: Partial<IDatePickerModel> = {
    ...INIT_DATEPICKER,
    key: "deadline",
    label: <label htmlFor="deadline">{I18n.t("forms.milestoneDate")} *</label>,
    placeholder: I18n.t("placeholders.date"),
    fieldClassName: FORM_COL.FULL_WIDTH,
    validate: function () {
      const self: IDatePickerModel = this;
      let res = true;
      if (Validations.IS_NULL(self.value)) {
        self.errorMessage = (
          <ErrorMessage>
            {I18n.t("validations.milestoneDate", { value: I18n.t("forms.milestoneDate"), entity: "Milestone" })}
          </ErrorMessage>
        );
        res = false;
      }

      return res;
    },
    componentProps: {
      icon: IconSymbols.Calendar,
      datePickerProps: {
        isOutsideRange: day => false
      }
    },
    value: milestone && moment(milestone.deadline)
  };

  const milestoneType: Partial<IAutocompleteModel> = {
    ...INIT_AUTOCOMPLETE,
    key: "milestoneType",
    label: <label htmlFor={"milestoneType"}>{I18n.t("forms.milestoneType")}</label>,
    placeholder: I18n.t("placeholders.selectMilestoneType"),
    options: MILESTONE_TYPE_OPTIONS.map(o => ({ ...o, label: I18n.t(o.label) })),
    isLoading: false,
    valueLabelFn: obj => obj.label,
    optionElement: (
      <AutocompleteOption
        key={"e"}
        className={"autocomplete__chip"}
        label={option => (
          <span>
            <span className="ml-1">{option.label}</span>
          </span>
        )}
      />
    ),
    validate: function () {
      let res = true;
      return res;
    },
    componentProps: {
      className: "form-control"
    },
    fieldClassName: FORM_COL.FULL_WIDTH,
    extractValue: function () {
      return this.value?.key;
    },
    value: MILESTONE_TYPE_OPTIONS.map(o => ({ ...o, label: I18n.t(o.label) })).find(
      e => e.key === milestone?.milestoneType
    ),
    tooltipLabel: (
      <FormTooltip>
        <p className="mb-0">{I18n.t("phrases.milestoneTypeDesc")}</p>
      </FormTooltip>
    )
  };

  const fields = [];

  fields.push(name);
  fields.push(deadline);
  fields.push(milestoneType);
  fields.push(description);

  const models = generateFormFieldsFromJson(fields);
  return models;
};
