import { isEmpty } from "lodash";
import moment from "moment";
import * as React from "react";
import { Link, useParams } from "react-router-dom";
import { ReviewBar } from "../../../../components/layouts/ReviewBar";
import { Hr } from "../../../../components/ui/Hr";
import { List } from "../../../../components/ui/List";
import { Pill } from "../../../../components/ui/Pill";
import { Tag } from "../../../../components/ui/Tag";
import { RenderXssSafeString } from "../../../../core/AntiXss/AntiXssHelper";
import I18n from "../../../../core/localization/I18n";
import { isItemComplete } from "../../../../core/util/ReviewHelpers";
import { Enums } from "../../../../enums";
import Pages from "../../../../routes/InsightRoutes";
import { useCurrentOrganisationId } from "../../../../services/local/organisationContext/OrganisationContextModel";
import { QUERY_STRING_PARAMS } from "../../../../services/local/queryStringService/QueryStringService";

export interface ImpactDisplayProps {
  item: FP.Entities.IImpact;
  isCompact?: boolean;
}

export const getMitigationConfidencePhrase = (value: number) => {
  if (value < 0) return I18n.t("forms.checkBoxSliderUnknownNote");
  if (value <= 4) return I18n.t("phrases.youHaveLowConfidence");
  if (value <= 7) return I18n.t("phrases.youHaveMediumConfidence");
  return I18n.t("phrases.youHaveHighConfidence");
};

const ImpactDisplay: React.FunctionComponent<ImpactDisplayProps> = props => {
  const { item } = props;
  const organisationId = useCurrentOrganisationId();
  const params: any = useParams();
  const impactTypes = item => {
    if (isEmpty(item.impactTypes) || item.impactTypes.length === 0) {
      return I18n.t("table.noInput");
    }

    return item.impactTypes.map((x, i) => x.name).join(", ");
  };
  return (
    <>
      <div className="row mb-3">
        <div className="col">
          <h5 className="mb-1">{I18n.t("table.description")}</h5>
          {item.description ? (
            <div dangerouslySetInnerHTML={{ __html: RenderXssSafeString(item.description) }}></div>
          ) : (
            <p>{I18n.t("table.noInput")}</p>
          )}
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <Hr className="mb-0 mt-0" />
        </div>
      </div>

      <div className="row mb-4 mt-5">
        <div className="col-lg-6">
          <h5 className="mb-1">{I18n.t("table.impactLevel")}</h5>
          <Pill type={Enums.Translator.ImpactLevelToPill(item.impactLevel)}>
            {Enums.Translator.ImpactLevel(item.impactLevel)}
          </Pill>
        </div>
        <div className="col-lg-6">
          <h5 className="mb-1">{I18n.t("table.mitigationConfidence")}</h5>
          <Pill type={Enums.Translator.ImpactLevelToPill(item.mitigationConfidence)}>
            {Enums.Translator.ImpactLevel(item.mitigationConfidence)}
          </Pill>
        </div>
      </div>

      <div className="row mb-4 mt-5">
        <div className="col-lg-6">
          <h5 className="mb-1">{I18n.t("table.impactType")}</h5>
          <p className="mb-0">{impactTypes(item)}</p>
        </div>

        <div className="col-lg-6">
          <h5 className="mb-1">{I18n.t("table.impactOwner")}</h5>
          {(item.impactOwner && item.impactOwner?.sub !== "") || typeof item.impactOwner?.sub !== "undefined" ? (
            <Link
              className="text-link-style body-bold"
              to={`/organisations/${organisationId}/users/${item.impactOwner.sub}?${
                QUERY_STRING_PARAMS.RETURN_URL
              }=${encodeURIComponent(window.location.href.replace(appConfig.baseUrl, ""))}`}
            >
              {item.impactOwner.firstName} {item.impactOwner.lastName}
            </Link>
          ) : (
            <p className="mb-0">{I18n.t("table.noInput")}</p>
          )}
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-lg-6">
          <h5 className="mb-1">{I18n.t("table.startDate")}</h5>
          <p className="mb-0">
            {" "}
            {item.startDate === null ? I18n.t("table.noInput") : moment(item.startDate).format("L")}
          </p>
        </div>
        <div className="col-lg-6">
          <h5 className="mb-1">{I18n.t("table.endDate")}</h5>
          <p className="mb-0">
            {item.actualEndDate === null ? I18n.t("table.noInput") : moment(item.actualEndDate).format("L")}
          </p>
        </div>
      </div>

      <div className="row pb-5">
        <div className="col-lg-6">
          <h5 className="mb-1">{I18n.t("table.locations")}</h5>
          {item.locations?.length ? (
            <List
              showMoreLabel={I18n.t("phrases.showMore", { num: item.locations.length - 2 })}
              showLessLabel={I18n.t("phrases.showLess")}
              className="pl-0"
              defaultVisible={item.locations.length === 3 ? 3 : 2}
            >
              {item.locations.map(e => (
                <li key={e.id}>
                  <p className="mb-0">{e.name}</p>
                </li>
              ))}
            </List>
          ) : (
            I18n.t("table.noInput")
          )}
        </div>
        <div className="col-lg-6">
          <h5 className="mb-1">{I18n.t("table.businessAreas")}</h5>
          {item.businessAreas?.length ? (
            <List
              showMoreLabel={I18n.t("phrases.showMore", { num: item.businessAreas.length - 2 })}
              showLessLabel={I18n.t("phrases.showLess")}
              className="pl-0"
              defaultVisible={item.businessAreas.length === 3 ? 3 : 2}
            >
              {item.businessAreas.map(e => (
                <li key={e.id}>
                  <p className="mb-0">{e.name}</p>
                </li>
              ))}
            </List>
          ) : (
            I18n.t("table.noInput")
          )}
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-lg-6">
          <h5 className="mb-1">{I18n.t("table.numOfIndividualsImpacted")}</h5>
          <p className="mb-0">
            {item.totalPeopleImpacted >= 0 && item.totalPeopleImpacted !== null
              ? item.totalPeopleImpacted
              : I18n.t("table.noInput")}
          </p>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <Hr className="mb-0 mt-0" />
        </div>
      </div>

      {item.reviewedBy && (
        <ReviewBar
          onwardLink={`/organisations/${organisationId}/users/${item.reviewedBy.sub}?${
            QUERY_STRING_PARAMS.RETURN_URL
          }=${encodeURIComponent(window.location.href.replace(appConfig.baseUrl, ""))}`}
          item={item}
          isOutOfAction={isItemComplete(item.progressStatus)}
        />
      )}

      <div className="row">
        <div className="col-12">
          <Hr className="mb-0 mt-0" />
        </div>
      </div>

      <div className="row pb-4 mt-5">
        <div className="col">
          <h5 className="mb-1">{I18n.t("phrases.tags")}</h5>
          {item.tags.length === 0 && <p className="mb-0">{I18n.t("table.noInput")}</p>}
          {item.tags.map(e => (
            <Tag tagText={e.text} isEditable={false} testId={e.id + ""} isNew={false} />
          ))}
        </div>
      </div>

      {!props.isCompact ? (
        <>
          <div className="row">
            <div className="col-12">
              <Hr className="mb-0 mt-0" />
            </div>
            <div className="row pb-4 mt-5">
              <div className="col">
                <h5 className="mb-1">{I18n.t("phrases.highLevelImpacts")}</h5>
                {item.impactGroups?.length ? (
                  <List
                    showMoreLabel={I18n.t("phrases.showMore", { num: item.locations.length - 2 })}
                    showLessLabel={I18n.t("phrases.showLess")}
                    defaultVisible={10}
                    className="pl-0"
                  >
                    {item.impactGroups.map(e => (
                      <li key={e.id}>
                        <p className="mb-0">
                          <Link
                            to={`${Pages.projects.impactGroups.extendedView.generateLink(
                              organisationId,
                              params.projectId,
                              e.id
                            )}`}
                            className="text-link-style"
                          >
                            {e.refNumber} - {e.name}
                          </Link>
                        </p>
                      </li>
                    ))}
                  </List>
                ) : (
                  I18n.t("table.noInput")
                )}
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export { ImpactDisplay };
