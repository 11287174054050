import { action, makeObservable, observable } from "mobx";

import ProgressIndicatorModel, {
  ProgressIndicatorModel as IProgressIndicatorModel
} from "../../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import OrganisationsApi, {
  OrganisationsApi as IOrganisationsApi
} from "../../../../../services/api/v2/organisations/Organisations.api";
import StakeholdersApi, {
  StakeholdersApi as IStakeholdersApi
} from "../../../../../services/api/v2/stakeholders/Stakeholders.api";
import { PieDataObject } from "../../../../insight/organisations/dashboard/Heatmap/Heatmap_utils";

export class StakeholderProjectsModel {
  @observable.ref projectDetails: FP.Entities.IProjectStakeholder[] = [];
  @observable isLoading: boolean = true;
  organisationsProvider: IOrganisationsApi;
  orgId: number;
  httpProgress: IProgressIndicatorModel;
  stakeholdersProvider: IStakeholdersApi;
  stakeholderId: number;
  pieData: PieDataObject[];

  constructor(organisationId: number, stakeholderId: number) {
    makeObservable(this);
    this.stakeholderId = stakeholderId;
    this.organisationsProvider = OrganisationsApi;
    this.orgId = organisationId;
    this.httpProgress = ProgressIndicatorModel;
    this.stakeholdersProvider = StakeholdersApi;
  }

  onMount = async () => {
    await this.loadProjectDetails();
  };

  loadProjectDetails = async () => {
    const res = await this.stakeholdersProvider.getDetailedProjectsById(this.orgId, this.stakeholderId);
    if (!res || res.isError) return;

    this.setProjectDetails(res.payload);
  };

  @action
  setProjectDetails = (projects: FP.Entities.IProjectStakeholder[]) => {
    this.projectDetails = projects;
    this.isLoading = false;
  };
}
