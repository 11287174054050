import React, { useEffect, useState } from "react";
import I18n from "../../../../core/localization/I18n";
import { ResponsiveContainer, Tooltip, TooltipProps, PieChart, Pie, Cell } from "recharts";
import {
  REPORT_HEIGHT,
  CHART_MARGIN,
  CHART_COLOUR_PRIMARY,
  CHART_COLOUR_ARRAY
} from "../../../change/projects/reportsView/ReportsView_model";
import { Panel } from "../../../../components/ui/Panel";
import { ActionTypesModel } from "./ActionTypes_model";
import { useCurrentOrganisationId } from "../../../../services/local/organisationContext/OrganisationContextModel";
import { observer } from "mobx-react";
import { IF } from "../../../../components/hoc/If";
import { PositionedSpinner } from "../../../../components/ui/PositionedSpinner";

export const ActionTypes: React.FC = observer(() => {
  const organisationId = useCurrentOrganisationId();
  const [model] = useState(() => new ActionTypesModel(organisationId));

  useEffect(() => {
    model.onMount();
  }, [model]);

  let data = model.actions;

  return (
    <div className={`report-card report-card--md pb-5`}>
      <Panel.Panel
        className={`p-4 `}
        hasBorder={true}
        background={Panel.PanelBackgrounds.BG_LIGHT}
        hasBorderRadius={true}
      >
        <h3>{I18n.t("phrases.myActionsByType")}</h3>
        <IF condition={model.isLoading}>
          <PositionedSpinner />
        </IF>

        <IF condition={!model.isLoading && (!data || data.length === 0)}>
          <div>{I18n.t("phrases.noActionsByTypeReportData")}</div>
        </IF>

        <IF condition={!model.isLoading && data.length > 0}>
          <ResponsiveContainer height={REPORT_HEIGHT} width="100%">
            <PieChart margin={CHART_MARGIN}>
              <Pie
                data={data}
                dataKey="count"
                nameKey="name"
                outerRadius="75%"
                fill={CHART_COLOUR_PRIMARY}
                label={props => {
                  return props.name.length > 14 ? props.name.slice(0, 11).concat("...") : props.name;
                }}
              >
                {data.map((entry, index) => (
                  <Cell key={index} fill={CHART_COLOUR_ARRAY[index % data.length]} />
                ))}
              </Pie>
              <Tooltip
                cursor={false}
                content={(props: TooltipProps) => {
                  const item = props.payload[0]?.payload;
                  if (item) {
                    return (
                      <Panel.Panel hasShadow background={Panel.PanelBackgrounds.BG_WHITE} className="p-3">
                        <small className="d-block">
                          <strong>{item.name}</strong>
                        </small>
                        <hr />
                        <small className="d-block">
                          {I18n.t("phrases.notStarted")}: {item.notStarted}
                        </small>
                        <small className="d-block">
                          {I18n.t("phrases.inProgress")}: {item.inProgress}
                        </small>
                        <small className="d-block">
                          {I18n.t("phrases.completed")}: {item.completed}
                        </small>
                        <small className="d-block">
                          {I18n.t("phrases.total")}: {item.count}
                        </small>
                      </Panel.Panel>
                    );
                  }
                }}
              />
            </PieChart>
          </ResponsiveContainer>
        </IF>
      </Panel.Panel>
    </div>
  );
});
