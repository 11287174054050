import React, { useCallback, useState } from "react";
import { UserViewModel } from "./View_model";
import { PositionedSpinner } from "../../../../components/ui/PositionedSpinner";
import { Button, ButtonTypes, LinkButton } from "../../../../components/ui/Button";
import { UiSizes } from "../../../../enums";
import { Icon, IconSymbols } from "../../../../components/ui/Icon";
import I18n from "../../../../core/localization/I18n";
import { observer } from "mobx-react";
import { UserDetails } from "../userDetails";
import { useParams } from "react-router-dom";
import { Async } from "react-async";
import { Animations } from "../../../../core/util/Animations";
import { useIsOrganisationAdmin } from "../../../../contexts/permissions/PermissionHooks";
import { OrganisationUserPermissionsList } from "./OrganisationUserPermissionList";

export interface UserViewProps {
  model?: UserViewModel;
}

export const UserView: React.FunctionComponent<UserViewProps> = observer(({ model: m }) => {
  const { userId, organisationId } = useParams<{ userId: string; organisationId: string }>();
  const [model] = useState(() => m || new UserViewModel(+organisationId));
  var isAdmin: boolean = useIsOrganisationAdmin(model.organisationId);

  const loadUser = useCallback(async () => {
    return model.loadUser(userId);
  }, [model, userId]);

  return (
    <Async promiseFn={loadUser}>
      <Async.Loading>
        <PositionedSpinner />;
      </Async.Loading>
      <Async.Resolved>
        {() => {
          const { user } = model;

          if (!user) {
            return false;
          }
          return (
            <div className="data-extended-view pt-6">
              <div className="container-fluid data-extended-view__block data-extended-view__block--header mb-5">
                <div className="row">
                  <div className="col p-2">
                    <LinkButton size={UiSizes.MD} type={ButtonTypes.LINK} className="p-0" href={model.returnUrl}>
                      <Icon className="mr-2" symbol={IconSymbols.ChevronLeft} />
                      {I18n.t("phrases.back")}
                    </LinkButton>
                  </div>
                </div>
              </div>
              <div className="data-extended-view__block data-extended-view__block--wrapper">
                <div className={`data-extended-view__col container-fluid ${Animations.FP_ZOOM_IN} speed-5`}>
                  <div className="row mb-4">
                    <div className="col">
                      <h2 className="mb-0">
                        {user?.firstName} {user?.lastName} | {I18n.t("phrases.userDetails")}
                      </h2>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <UserDetails item={user} />
                    </div>
                  </div>
                  {isAdmin && (
                    <>
                      <div className="row mt-6">
                        <div className="col-lg-6">
                          <h2>{I18n.t("phrases.userPermissionCacheTitle")}</h2>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-3">{I18n.t("phrases.userPermissionCacheDescription")}</div>
                        <div className="col-lg-3">
                          <Button onClick={ev => model.deleteUserCache(user.sub)} type={ButtonTypes.DANGER}>
                            <Icon symbol={IconSymbols.Trash} className="mr-2" />
                            {I18n.t("phrases.userPermissionCacheDeleteButtonText")}
                          </Button>
                        </div>
                      </div>

                      <div className="row mt-6">
                        <div className="col lg-6">
                          <h2>{I18n.t("phrases.adminPermissionsTool")}</h2>
                        </div>
                      </div>

                      <div className="row pl-5">
                        <OrganisationUserPermissionsList
                          getPermissionsFunction={model.getOrganisationUserProjectPermissions}
                          organisationId={+organisationId}
                          systemUserId={user.sub}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          );
        }}
      </Async.Resolved>
      <Async.Rejected>{err => false}</Async.Rejected>
    </Async>
  );
});
