import _ from "lodash";
import { ErrorMessage } from "../../../../../components/ui/ErrorMessage";
import { IconSymbols } from "../../../../../components/ui/Icon";
import { AutocompleteOption } from "../../../../../components/ui/_forms/Autocomplete/AutocompleteOption";
import { FormTooltip } from "../../../../../components/ui/_forms/FormTooltip";
import { FORM_COL, IMPACT_LEVELS_DROPDOWN, REMOVE_UNSAFE_CHARACTERS } from "../../../../../constants";
import { INIT_AUTOCOMPLETE } from "../../../../../core/forms/controls/autocomplete/Autocomplete_init";
import { IAutocompleteModel } from "../../../../../core/forms/controls/autocomplete/IAutocompleteModel";
import { IMultiSelectorModel } from "../../../../../core/forms/controls/multiSelector/IMultiSelectorModel";
import { INIT_MULTISELECTOR } from "../../../../../core/forms/controls/multiSelector/MultiSelector_model";
import { ITextFieldModel } from "../../../../../core/forms/controls/textField/ITextFieldModel";
import { INIT_TEXT_FIELD } from "../../../../../core/forms/controls/textField/TextField_init";
import { generateFormFieldsFromJson } from "../../../../../core/forms/helpers/FormFieldMappers";
import { Validations } from "../../../../../core/forms/helpers/Validations";
import I18n from "../../../../../core/localization/I18n";
import { Enums } from "../../../../../enums";
import ImpactTypesApi from "../../../../../services/api/v2/impactTypes/ImpactTypes.api";

export const getProjectMicroFormField = (projectId?: number) => {
  return {
    key: "projectId",
    value: projectId,
    defaultValue: projectId
  };
};

export const getImpactMicroFormFields = (organisationId: number, impact?: FP.Entities.IImpact) => {
  const impactTypeProvider = ImpactTypesApi;

  const name: Partial<ITextFieldModel> = {
    ...INIT_TEXT_FIELD,
    key: "name",
    placeholder: I18n.t("placeholders.impactName"),
    fieldClassName: FORM_COL.FULL_WIDTH,
    validate: function () {
      const self: ITextFieldModel = this;
      let res = true;
      if (Validations.IS_EMPTY(self.extractValue())) {
        self.errorMessage = <ErrorMessage>{I18n.t("validations.impactName")}</ErrorMessage>;
        res = false;
      }
      return res;
    },
    extractValue: function () {
      return REMOVE_UNSAFE_CHARACTERS(this.value.toString());
    }
  };

  const impactTypes: Partial<IMultiSelectorModel> = {
    ...INIT_MULTISELECTOR,
    key: "impactTypes",
    placeholder: I18n.t("placeholders.searchImpactTypes"),
    optionElement: (
      <AutocompleteOption
        key={"e"}
        className={"autocomplete__chip"}
        label={e => {
          return e.name;
        }}
      />
    ),
    manageLink: `/organisations/${organisationId}/settings/impactTypes`,
    //label: <label htmlFor="impactTypes">{I18n.t("forms.impactTypes")}</label>,
    onFocus: async function () {
      const self: IMultiSelectorModel = this;

      const res = await impactTypeProvider.getFiltered(organisationId, {
        page: 1,
        pageSize: 10000,
        filters: `lifecycleStatus==0,organisationId==${organisationId}`
      });

      if (res?.payload) {
        const sortedImpactTypes = _.orderBy(res.payload, [impactType => impactType.name.toLowerCase()]);
        self.setOptions(sortedImpactTypes);
      }
    },
    componentProps: {
      icon: IconSymbols.Search
    },
    searchAttribute: "name",
    fieldClassName: FORM_COL.HALF_WIDTH,
    extractValue: function () {
      return this.selectedItems.map(e => e.id);
    },
    value: impact?.impactTypes,
    valueLabelFn: e => e?.name
  };

  const impactLevel: Partial<IAutocompleteModel> = {
    ...INIT_AUTOCOMPLETE,
    key: "impactLevel",
    fieldClassName: FORM_COL.HALF_WIDTH,
    placeholder: I18n.t("placeholders.detailedImpactLevel_modal"),
    onFocus: async function (model: IAutocompleteModel) {
      model.setOptions(IMPACT_LEVELS_DROPDOWN);
    },
    optionElement: (
      <AutocompleteOption
        key={"e"}
        className={"autocomplete__chip"}
        label={e => {
          return e;
        }}
      />
    ),
    componentProps: {
      className: "form-control"
    },
    extractValue: function () {
      if (!this.value) return -1;
      return Enums.ImpactLevelDropdownValues[this.value];
    },
    valueLabelFn: e => e,
    value: impact?.impactLevel,
    tooltipLabel: (
      <FormTooltip>
        <p className="mb-0">{I18n.t("tooltips.detailedImpactLevel")}</p>
      </FormTooltip>
    )
  };

  const fields = [];
  fields.push(name);
  fields.push(impactTypes);
  fields.push(impactLevel);

  const models = generateFormFieldsFromJson(fields);
  return models;
};
