import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { IF } from "../../../../../../../components/hoc/If";
import { PositionedSpinner } from "../../../../../../../components/ui/PositionedSpinner";
import { useCurrentOrganisationId } from "../../../../../../../services/local/organisationContext/OrganisationContextModel";
import { ActionProgressStatusRagStatusChartModel } from "./ActionProgressStatusRagStatusChart_model";
import { BarChart, BarChartProps } from "../../../../../../../core/charts/BarChart/BarChart";

export type ActionProgressStatusRagStatusChartProps = {
  loadDataFn: (organisationId: number, projectId: number, selectedItemIds: number[]) => Promise<any>;
  title: string;
  transformer: (data: any[]) => BarChartProps;
  clearFilterFN?: () => any;
  filterModalFn?: any;
  selectedItemIds: number[];
};

export const ActionProgressStatusRagStatusChart: React.FC<ActionProgressStatusRagStatusChartProps> = observer(
  ({ title, loadDataFn, transformer, filterModalFn, clearFilterFN, selectedItemIds }) => {
    const organisationId = useCurrentOrganisationId();
    const { projectId } = useParams();

    const [model, setModel] = useState(
      () =>
        new ActionProgressStatusRagStatusChartModel(
          organisationId,
          +projectId,
          loadDataFn,
          transformer,
          selectedItemIds
        )
    );

    useEffect(() => {
      model.onMount();
      // eslint-disable-next-line
    }, [model]);

    useEffect(() => {
      setModel(
        new ActionProgressStatusRagStatusChartModel(
          organisationId,
          +projectId,
          loadDataFn,
          transformer,
          selectedItemIds
        )
      );
      // eslint-disable-next-line
    }, [selectedItemIds]);

    return (
      <>
        <div className="col">
          <h2 className="mt-0">{title}</h2>
        </div>
        <IF condition={model.isLoading}>
          <PositionedSpinner />
        </IF>

        <div className="action-summary-chart__wrapper">
          <IF condition={!model.isLoading && model.chartData.data.length === 0}>
            <div className="col">
              <p>There was no data found for this parameters</p>
            </div>
          </IF>
          <IF condition={!model.isLoading && model.chartData?.data?.length > 0}>
            <BarChart {...model.chartData} />
          </IF>
        </div>
      </>
    );
  }
);
