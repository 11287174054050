import { observable } from "mobx";

import { SingleFormModel } from "../../../forms/singleFormModel/SingleForm_model";
import { getChangeRoleFormFields } from "./ImpactStakeholderChangeRole_fields";
import ChangeRolesApi, {
  ChangeRolesApi as IChangeRolesApi
} from "../../../../../services/api/v2/changeRoles/ChangeRoles.api";

export class ImpactStakeholderChangeRoleModel {
  @observable formModel: SingleFormModel;
  organisationId: number;
  projectStakeholder: FP.Entities.IProjectStakeholder;
  stakeholderRoleProvider: IChangeRolesApi;
  projectId: number;
  impactId: number;
  onRoleAdded: any;

  constructor(
    organisationId: number,
    projectId: number,
    impactId: number,
    projectStakeholder: FP.Entities.IProjectStakeholder,
    onRoleAdded
  ) {
    this.organisationId = organisationId;
    this.impactId = impactId;
    this.projectId = projectId;
    this.projectStakeholder = projectStakeholder;
    this.onRoleAdded = onRoleAdded;
    this.stakeholderRoleProvider = ChangeRolesApi;
    this.initForm();
  }

  onMount = () => {};

  onUnmount = () => {};

  initForm = () => {
    this.formModel = new SingleFormModel();
    this.formModel.formFields = getChangeRoleFormFields(
      this.stakeholderRoleProvider,
      this.organisationId,
      this.projectId,
      this.impactId,
      this.projectStakeholder,
      this.onRoleAdded
    );
  };
}
