import * as React from "react";
import progressIndicatorModel from "./ProgressIndicator_model";
import { PositionedSpinner } from "../../../components/ui/PositionedSpinner";
import { observer } from "mobx-react";
import { TopProgressBar } from "../../../components/ui/TopProgressBar";

export interface ProgressProps {}

const ProgressIndicator: React.FunctionComponent<ProgressProps> = observer(() => {
  let model = progressIndicatorModel;
  return (
    <div className="http-progress-wrapper">
      {model.isTopProgressBarVisible && <TopProgressBar />}
      {model.isProgressVisible ? <div className="http-progress-wrapper__progress" /> : null}
      {model.isOverlayVisible ? (
        <div className="http-progress-wrapper__overlay">
          <PositionedSpinner />
        </div>
      ) : null}
    </div>
  );
});

export { ProgressIndicator };
