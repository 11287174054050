import * as React from "react";
import { Link, LinkProps } from "react-router-dom";

export function RLink(props: React.PropsWithoutRef<LinkProps> & React.RefAttributes<HTMLAnchorElement>) {
  let cls = window.location.hash.replace("#", "") === props.to ? "disabled-link" : "";

  return (
    <Link className={`${cls} ${props.className || ""}`} {...props}>
      {props.children}
    </Link>
  );
}
