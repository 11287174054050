import _ from "lodash";
import { IconSymbols } from "../../../../../../../../components/ui/Icon";
import { AutocompleteOption } from "../../../../../../../../components/ui/_forms/Autocomplete/AutocompleteOption";
import { ProgressIndicatorModel as IProgressIndicatorModel } from "../../../../../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import { FORM_COL } from "../../../../../../../../constants";
import { IMultiSelectorModel } from "../../../../../../../forms/controls/multiSelector/IMultiSelectorModel";
import { INIT_MULTISELECTOR } from "../../../../../../../forms/controls/multiSelector/MultiSelector_model";
import { generateFormFieldsFromJson } from "../../../../../../../forms/helpers/FormFieldMappers";
import I18n from "../../../../../../../localization/I18n";
import { ImpactsApi } from "../../../../../../../../services/api/v2/impacts/Impacts.api";
import { ProjectProcessesApi } from "../../../../../../../../services/api/v2/projectProcesses/ProjectProcesses.api";

export const getImpactProcessFormFields = (
  projectProcessesProvider: ProjectProcessesApi,
  impactsProvider: ImpactsApi,
  organisationId: number,
  projectId: number,
  impact: FP.Entities.IImpact,
  loadProjectProcesses: (impactId) => Promise<any>,
  projectProcesses: FP.Entities.IProjectProcess[],
  httpProgress: IProgressIndicatorModel
) => {
  const addProjectProcess = async (projectProcess: FP.Entities.IProjectProcess) => {
    httpProgress.showTopProgressBarVisible();
    let res = await impactsProvider.addProcesses(organisationId, projectId, impact.id, [projectProcess.id]);
    httpProgress.hideTopProgressBarVisible();
    if (!res) return;
    await loadProjectProcesses(impact.id);
  };

  const removeProjectProcess = async (projectProcessId: number) => {
    const res = await impactsProvider.removeImpactProjectProcess(
      organisationId,
      projectId,
      impact.id,
      projectProcessId
    );
    if (!res) return;

    return res;
  };

  const projectProcess: Partial<IMultiSelectorModel> = {
    ...INIT_MULTISELECTOR,
    key: "projectProcesses",
    label: <label htmlFor={"projectProcesses"}>{I18n.t("forms.projectProcesses")}</label>,
    placeholder: I18n.t("placeholders.searchProjectProcess"),
    optionElement: (
      <AutocompleteOption
        key={"e"}
        className={"autocomplete__chip"}
        label={(e: FP.Entities.IProjectProcess) => `Level ${e.level} - ${e.name}`}
      />
    ),
    manageLink: `/organisations/${organisationId}/projects/${projectId}/processes`,
    onItemSelected: ip => addProjectProcess(ip),
    onFocus: async function () {
      const self: IMultiSelectorModel = this;
      const res = await impactsProvider.getUnassignedProcesses(organisationId, projectId, impact.id);

      if (res?.payload) {
        let sortedProjectProcesses = _.orderBy(res.payload, projectProcess => [
          projectProcess.level,
          projectProcess.name.toLowerCase()
        ]);
        self.setOptions(sortedProjectProcesses);
      }
    },
    componentProps: {
      icon: IconSymbols.Search
    },
    searchAttribute: "name",
    fieldClassName: FORM_COL.FULL_WIDTH,
    extractValue: function () {
      return this.isHidden ? null : this.selectedItems.map(e => e.id);
    },
    value: projectProcesses,
    removeItemFn: (ip, idx) => removeProjectProcess(ip.id)
  };

  const fields = [];

  fields.push(projectProcess);
  const models = generateFormFieldsFromJson(fields);
  return models;
};
