import { action, makeObservable, observable } from "mobx";
import ImpactsApi from "../../../../../services/api/v2/impacts/Impacts.api";

export class ImpactsGridSidebarModel {
  organisationId: number;
  projectId: number;
  @observable isLoading: boolean = true;
  @observable data: FP.Entities.IImpactSummary[] = [];
  impactsProvider = ImpactsApi;
  @observable selectedItemIds: number[] = [];
  /**
   *
   */
  constructor(organisationId: number, projectId: number, selectedItemIds: number[]) {
    makeObservable(this);
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.selectedItemIds = selectedItemIds;
  }

  onMount = async () => {
    await this.loadImpacts();
  };

  loadImpacts = async () => {
    let res = await this.impactsProvider.getGridData(this.organisationId, this.projectId);

    if (res.isError) return;

    this.setData(res.payload.data);
    this.setIsLoading(false);
  };

  @action
  setData = (data: FP.Entities.IImpactSummary[]) => {
    this.data = data.map(e => {
      return {
        ...e,
        audiences: JSON.parse(e.audiences)
      };
    });
  };

  @action
  setIsLoading = (isLoading: boolean) => (this.isLoading = isLoading);
}
