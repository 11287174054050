import _ from "lodash";
import React from "react";
import { AutocompletePerson } from "../../../../../../../../components/ui/AutocompletePersonOption";
import { IconSymbols } from "../../../../../../../../components/ui/Icon";
import { AutocompleteOption } from "../../../../../../../../components/ui/_forms/Autocomplete/AutocompleteOption";
import { FORM_COL } from "../../../../../../../../constants";
import { INIT_AUTOCOMPLETE } from "../../../../../../../../core/forms/controls/autocomplete/Autocomplete_init";
import { AutocompleteModel } from "../../../../../../../../core/forms/controls/autocomplete/Autocomplete_model";
import { IAutocompleteModel } from "../../../../../../../../core/forms/controls/autocomplete/IAutocompleteModel";
import { IListingModel } from "../../../../../../../../core/forms/controls/listing/IListingModel";
import { INIT_LISTING_FIELD, ListingModel } from "../../../../../../../../core/forms/controls/listing/Listing_model";
import { generateFormFieldsFromJson } from "../../../../../../../../core/forms/helpers/FormFieldMappers";
import I18n from "../../../../../../../../core/localization/I18n";
import { Enums, StakeholderType } from "../../../../../../../../enums";
import { ProjectProcessesApi } from "../../../../../../../../services/api/v2/projectProcesses/ProjectProcesses.api";

export const getStakeholderFormFields = (
  projectProcessProvider: ProjectProcessesApi,
  projectId: number,
  projectProcessId: number,
  orgId: number,
  stakeholderType: StakeholderType
) => {
  const isIndivStakeholders = stakeholderType === StakeholderType.INDIVIDUAL;

  let stakeholdersSearch: Partial<IAutocompleteModel> = {
    ...INIT_AUTOCOMPLETE,
    key: "stakeholdersSearch",
    label: (
      <label htmlFor={"stakeholdersSearch"}>
        {isIndivStakeholders ? I18n.t("forms.impactStakeholders") : I18n.t("forms.audiences")}
      </label>
    ),
    placeholder: isIndivStakeholders ? I18n.t("placeholders.searchStakeholder") : I18n.t("placeholders.searchAudience"),
    optionElement: (
      <AutocompleteOption
        key={"e"}
        className={"autocomplete__chip"}
        label={e => {
          return <AutocompletePerson {...e} />;
        }}
      />
    ),
    subscribeTo: ["stakeholders"],
    onItemSelected: async function () {
      let self: AutocompleteModel = this;
      let listingModel: ListingModel = self.channels.stakeholders as ListingModel;
      let val = self.value;
      listingModel.addItem(val);
      self.searchQuery = "";
    },
    componentProps: {
      className: "form-control",
      icon: IconSymbols.Search
    },
    onFocus: async function () {
      let self: IAutocompleteModel = this;
      this.componentProps.disabled = false;
      const res = await projectProcessProvider.getUnassignedStakeholderOwners(orgId, projectId, projectProcessId);

      if (res?.payload) {
        let sortedStakeholders = _.orderBy(res.payload, [
          projectStakeholder => projectStakeholder.stakeholder.firstName.toLowerCase(),
          projectStakeholder => projectStakeholder.stakeholder.lastName.toLowerCase()
        ]);
        sortedStakeholders = sortedStakeholders.filter(x => x.stakeholder.stakeholderType === stakeholderType);
        self.setOptions(
          sortedStakeholders.map(e => {
            return {
              ...e.stakeholder,
              projectStakeholderId: e.id
            };
          })
        );
      }
    },
    shouldClearOnBlur: true,
    fieldClassName: FORM_COL.FULL_WIDTH,
    valueLabelFn: e => e.firstName + " " + e.lastName,
    filterFn: (items, query) => {
      const lowerQuery = query.toLowerCase();
      return _.filter(items, (item: FP.Entities.IStakeholder) => {
        const lowerName =
          item.stakeholderType === Enums.StakeholderType.AUDIENCE
            ? item.firstName.toLowerCase()
            : `${item.firstName} ${item.lastName}`.toLowerCase();
        const lowerEmail = item.email.toLowerCase();
        return lowerName.indexOf(lowerQuery) > -1 || lowerEmail.indexOf(lowerQuery) > -1;
      });
    }
  };

  let listing: Partial<IListingModel> = {
    ...INIT_LISTING_FIELD,
    key: "stakeholders",
    placeholder: isIndivStakeholders ? I18n.t("placeholders.selectStakeholder") : I18n.t("placeholders.selectAudience"),
    label: (
      <label htmlFor={"stakeholders"}>
        {" "}
        {isIndivStakeholders ? I18n.t("forms.impactStakeholders") : I18n.t("forms.audiences")}
      </label>
    ),
    fieldClassName: FORM_COL.FULL_WIDTH,
    extractValue: function () {
      return this.value && this.value.map(e => e.projectStakeholderId);
    },
    selector: (pst: FP.Entities.IStakeholder) => {
      let e = pst;
      return (
        <p className="mb-0 d-inline-block">
          {e.stakeholderType === Enums.StakeholderType.AUDIENCE
            ? e.firstName
            : `${e.firstName} ${e.lastName} (${e.email})`}
        </p>
      );
    }
  };

  const fields = [];

  fields.push(stakeholdersSearch);
  fields.push(listing);
  const models = generateFormFieldsFromJson(fields);
  return models;
};
