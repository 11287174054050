import { action, makeObservable, observable } from "mobx";
import { IBaseReportFilterAddon, ReportFilterAddons, ReportFilterAddonsKeys } from "./ReportFilterAddon";

export class ReportFilterModel {
  @observable isLoading = true;
  addons: IBaseReportFilterAddon[];
  reloadData: (json: any) => Promise<any>;

  constructor(addonKeys: ReportFilterAddonsKeys[], reloadData: (json: any) => Promise<any>, filterState) {
    makeObservable(this);
    this.reloadData = reloadData;

    this.initiliseAddons(addonKeys, filterState);
  }

  initiliseAddons = (addonKeys: ReportFilterAddonsKeys[], filterState: any) => {
    this.addons = addonKeys.map(e => {
      let currentFilter = filterState[e];
      return new ReportFilterAddons[e](this, currentFilter);
    });
    this.setIsLoading(false);
  };

  generateQueryJson = () => {
    let json = this.addons.map(e => {
      return {
        key: e.key,
        ids: e.selectedIds
      };
    });
    this.reloadData(json);
  };

  clearFilters = async () => {
    let json = this.addons.map(e => {
      e.clearSelectedIds();
      return {
        key: e.key,
        ids: []
      };
    });
    this.reloadData(json);
  };

  @action
  setIsLoading = (isLoading: boolean) => (this.isLoading = isLoading);
}
