import { AxiosInstance, AxiosRequestConfig } from "axios";
import { BaseProjectApiModel } from "../BaseProjectApiModel";
import http from "../Http";

export class ImpactVisualisationsApi extends BaseProjectApiModel<any> {
  controller: string = "impact-visualisations";

  /**
   *
   */
  constructor(http: AxiosInstance) {
    super(http, "impact-visualisations");
  }

  getImpactSummary = async (organisationId: number, projectId: number, config?: AxiosRequestConfig) => {
    const url = `${this.url}${organisationId}/projects/${projectId}/impact-visualisations/summary`;

    const res = await this.http.get(url, config);
    return res.data;
  };

  getMitigatedImpactSummary = async (organisationId: number, projectId: number, config?: AxiosRequestConfig) => {
    const url = `${this.url}${organisationId}/projects/${projectId}/impact-visualisations/mitigated-impacts`;

    const res = await this.http.get(url, config);
    return res.data;
  };

  getImpactWithoutActionsSummary = async (organisationId: number, projectId: number, config?: AxiosRequestConfig) => {
    const url = `${this.url}${organisationId}/projects/${projectId}/impact-visualisations/mitigations`;

    const res = await this.http.get(url, config);
    return res.data;
  };

  getImpactMissingDataSummary = async (organisationId: number, projectId: number, config?: AxiosRequestConfig) => {
    const url = `${this.url}${organisationId}/projects/${projectId}/impact-visualisations/missing-data`;

    const res = await this.http.get(url, config);
    return res.data;
  };

  getImpactMissingStaksholdersSummary = async (
    organisationId: number,
    projectId: number,
    config?: AxiosRequestConfig
  ) => {
    const url = `${this.url}${organisationId}/projects/${projectId}/impact-visualisations/missing-stakeholders`;

    const res = await this.http.get(url, config);
    return res.data;
  };

  getImpactStatusTotals = async (organisationId: number, projectId: number, config?: AxiosRequestConfig) => {
    const url = `${this.url}${organisationId}/projects/${projectId}/impact-visualisations/impact-status-totals`;

    const res = await this.http.get(url, config);
    return res.data;
  };

  getImpactLevelTotals = async (organisationId: number, projectId: number, config?: AxiosRequestConfig) => {
    const url = `${this.url}${organisationId}/projects/${projectId}/impact-visualisations/impact-level-totals`;

    const res = await this.http.get(url, config);
    return res.data;
  };

  getAbsoluteImpactStatusTotals = async (organisationId: number, projectId: number, config?: AxiosRequestConfig) => {
    const url = `${this.url}${organisationId}/projects/${projectId}/impact-visualisations/absolute-impact-status-totals`;

    const res = await this.http.get(url, config);
    return res.data;
  };

  getImpactAndImpactGroupImpactLevelTotals = async (
    organisationId: number,
    projectId: number,
    config?: AxiosRequestConfig
  ) => {
    const url = `${this.url}${organisationId}/projects/${projectId}/impact-visualisations/impact-group-impacts-impact-level-totals`;

    const res = await this.http.get(url, config);
    return res.data;
  };
}

const instance = new ImpactVisualisationsApi(http);
export default instance;
